import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const dashboard = createApi({
  reducerPath: "dashboard",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
   
    liveOrdersList: builder.query({
      query: (token) => ({
        url: `/live-order-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["dashboard"],
    }),

 
    monthlyOrdersList: builder.mutation({
      query: (data) => ({
        url: `/monthly-order-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags:["dashboard"]
    }),

    examinerOrdersStatus: builder.mutation({
      query: (data) => ({
        url: `/examiner-order-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags:["dashboard"]
    }),

    customerOrdersStatus: builder.mutation({
      query: (data) => ({
        url: `/customer-order-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags:["dashboard"]
    }),

  }),
});

export const {

  useLiveOrdersListQuery,
  useMonthlyOrdersListMutation,
  useExaminerOrdersStatusMutation,
  useCustomerOrdersStatusMutation,
} = dashboard;
