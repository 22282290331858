import React, { memo, useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useParcelHoaListMutation } from "../../../../../Redux/Services/Hoa";
import { useSelector } from "react-redux";
import HoaData from "./HoaData";
import { Collapse } from "antd";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./hoa.css";
import {
  useHoaDisplayOnCertListQuery,
  useHoaReadOnlyListQuery,
} from "../../../../../Redux/Services/HaoMgmt";
import { useGetHoaCommentsMutation } from "../../../../../Redux/Services/Parcels";
const { Panel } = Collapse;
export default memo(function MyHoa({
  parcelMemoData,
  orderMemoData,
  validateConflicts,
  autoStatusUpdateResult
}) {
  const [hoaTabs, setHoaTabs] = useState([]);
  const [dispaceCertData, setDispaceCertData] = useState([]);
  const [readCertData, setReadCertData] = useState([]);
  const [isHoaFound, setIsHoaFound] = useState("");

  const [mode, setMode] = useState("view");
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  //redux store
  const { token, user } = useSelector((state) => state.auth);
  const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

  const [parcelHoaList, parcelHoaListResult] = useParcelHoaListMutation();

  //comments
  const DisplayOnCertList = useHoaDisplayOnCertListQuery(token);
  const ReadOnlyList = useHoaReadOnlyListQuery(token);

  //add hoa to parcel
  const [hoaNotFoundCmments, hoaNotFoundCmmentsResult] =
    useGetHoaCommentsMutation();

  //after not found comments
  useEffect(() => {
    if (hoaNotFoundCmmentsResult.isSuccess) {
      const { hoa_not_found_comments, is_hoa } =
        hoaNotFoundCmmentsResult.data.data[0];
      // setIsHoaFound({
      //   hoa_name: "HOA NOT FOUND COMMENTS",
      //   id: "1",
      //   is_hoa,
      //   hoa_not_found_comments,
      //   isHoaFound: true,
      // });
      setSelected(0);
      setHoaTabs([
        {
          hoa_name: "HOA NOT FOUND COMMENTS",
          is_hoa,
          hoa_not_found_comments,
          showIsHoaNot: true,
        },
      ]);
    }
    if (hoaNotFoundCmmentsResult.isError) {
      setIsHoaFound(false);
    }
    //global spinner
  }, [hoaNotFoundCmmentsResult.isLoading]);

  function hoanotFoundCall() {
    hoaNotFoundCmments({
      id: parcelMemoData.id,
      token,
    });
  }

  useEffect(() => {
    if (ReadOnlyList.isSuccess) {
      setReadCertData(ReadOnlyList.data.data);
    }
    if (ReadOnlyList.isError) {
      setReadCertData([]);
    }
  }, [ReadOnlyList.isFetching]);

  useEffect(() => {
    if (DisplayOnCertList.isSuccess) {
      setDispaceCertData(DisplayOnCertList.data.data);
    }
    if (DisplayOnCertList.isError) {
      setDispaceCertData([]);
    }
  }, [DisplayOnCertList.isFetching]);

  useEffect(() => {
    if (parcelHoaListResult.isSuccess) {
      setHoaTabs(parcelHoaListResult.data.data);
      const id = String(parcelHoaListResult.data.data[0].id);
      setSelected(0);
      // setHoaId(id);
      // setIsHoaFound(false);
    }
    if (parcelHoaListResult.error) {
      // setGetParcelData({ ...getParcelData, error: "server unreachable" });
      setHoaTabs([]);

      //fecthing hoa not found comments
      hoanotFoundCall();
    }
  }, [parcelHoaListResult.isLoading]);

  function parcelHoaListFetch() {
    parcelHoaList({ parcel_id: parcelMemoData.id, token });
  }
  //after parcel change
  useEffect(() => {
    if (parcelMemoData.id) {
      //getting parcel information
      // parcelHOAInfo({
      //   id: parcelMemoData.id,
      //   token,
      // });
      DisplayOnCertList.refetch();
      ReadOnlyList.refetch();
      parcelHoaListFetch();
    }
  }, [parcelMemoData.id]);

  return (
    <div className="mt-2">
      <Collapse
        className="rounded-3 border-0 text-white"
        expandIconPosition="end"
        expandIcon={() => (
          <div onClick={(e) => e.stopPropagation()}>
            {mode !== "add" ? (
              parcelEditInOrder.map((item,index) => {
                if (item.hoa_add_button) {
                  return (
                    <div
                      key={index}
                      className="add_icon text-white border-white mt-1"
                      onClick={() => {
                        setMode("add");
                      }}
                    >
                      <FaPlus />
                    </div>
                  );
                }
              })
            ) : (
              <div
                className="add_icon text-white border-white mt-1"
                onClick={() => {
                  setMode("view");
                }}
              >
                <MdClose size={20} />
              </div>
            )}
          </div>
        )}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3 "
          header={
            <div
              className=" text-white d-flex justify-content-between "
              style={{ fontSize: 18, marginBottom: 0 }}
            >
              HOA Information
            </div>
          }
          style={{ fontSize: 18 }}
          key="1"
        >
          {/* //if mode is view  */}
          {hoaTabs.length > 0 && mode !== "add" && (
            <div className="demo-wrapper">
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                // scrollable={true}
                style={{
                  width: "100%",
                  userSelect: "none",
                }}
              >
                {hoaTabs.map((tab, index) => {
                  if (
                    !parcelHoaListResult.isLoading &&
                    !hoaNotFoundCmmentsResult.isLoading
                  ) {
                    return (
                      <TabStripTab
                        title={tab.hoa_name}
                        key={index}
                        className="w-100"
                      >
                        <div className="wrapper">
                          <div className="main ">
                            <HoaData
                              showIsHoa={tab?.hoa_not_found_comments}
                              hoaEditData={
                                tab?.hoa_not_found_comments ? false : true
                              }
                              hoanotFoundCall={hoanotFoundCall}
                              orderMemoData={orderMemoData}
                              validateConflicts={validateConflicts}
                              autoStatusUpdateResult={autoStatusUpdateResult}
                              dispaceCertData={dispaceCertData}
                              readCertData={readCertData}
                              mode={mode}
                              setMode={setMode}
                              parcelHoaList={hoaTabs}
                              editId={tab.id}
                              refetchHoaList={parcelHoaListFetch}
                            />
                          </div>
                        </div>
                      </TabStripTab>
                    );
                  }
                })}
              </TabStrip>
            </div>
          )}
          {mode === "add" && (
            <HoaData
              hoaEditData={isHoaFound}
              orderMemoData={orderMemoData}
              dispaceCertData={dispaceCertData}
              readCertData={readCertData}
              mode={mode}
              setMode={setMode}
              parcelHoaList={hoaTabs}
              editId={null}
              validateConflicts={validateConflicts}
              autoStatusUpdateResult={autoStatusUpdateResult}
              refetchHoaList={parcelHoaListFetch}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
});
