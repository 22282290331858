import React, { useState, useEffect, createContext, useContext } from "react";
import { Tabs, Tooltip, notification, Popconfirm, Spin } from "antd";

import Order from "../Order";
import NewOrder from "./Summary/newOrder";

import "./orderEditor.css";
import { FaPlus, FaRegClone } from "react-icons/fa";
import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  AiOutlineAudit,
  AiOutlineCheck,
  AiOutlineEye,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import ViewParcel from "./ParcelViewListDrawer/ViewParcel";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useSelector } from "react-redux";
import { useOrderStatusQuery } from "../../../Redux/Services/status";
import {
  useCloneParcelFromOrderMutation,
  useParcelStatusListMutation,
} from "../../../Redux/Services/Parcels";
import { FiDownload, FiEdit } from "react-icons/fi";
import { savePDF } from "@progress/kendo-react-pdf";
import {
  useAuditLogsListMutation,
  useAutoConflictsStatusUpdateMutation,
  useCertificateDownloadLogsMutation,
  useConflictsMutation,
  useDeleteOrderMutation,
  useGetCloserEmailMutation,
  useOrderCertificateMutation,
  useOrderStatusUpdateMutation,
  useReviewConflictsMutation,
  useSendEmailCertificateMutation,
  useUploadCertificateMutation,
  useValidateConflictsMutation,
} from "../../../Redux/Services/Orders";
import NewParcel from "./NewParcel/NewParcel";
import ParcelCard from "./ParcelCards/ParcelCard";
import ParcelsMain from "./Parcels/ParcelsMain";

import { useCallback } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { GlobalContext } from "../../../App";
import {
  MdDeleteOutline,
  MdOutlinePreview,
  MdOutlineSettings,
} from "react-icons/md";
import AppModal from "../../../Components/AppModal/AppModal";

import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import OrderInfoCopyModal from "./OrderInfoCopyModal/OrderInfoCopyModal";
import { dateFun, scrollComp } from "../../../Controllers/Functions";
import axios from "axios";
import Conflicts from "./Conflicts/Conflicts";
import { CgViewSplit } from "react-icons/cg";
import CertificateContent from "./PDF/CertificateContent";
import { VscRunErrors } from "react-icons/vsc";
import OrderList from "../Component/OrderList";
import AuditLogs from "../AuditLogs/AuditLogs";
import { BsFilePdf } from "react-icons/bs";
import EmailModal from "./Summary/Email/EmailModal";
export const parcelContext = createContext();
const { TabPane } = Tabs;
const randomPass = require("secure-random-password");
const config = require("../../../config.json");

const OrderEditor = () => {
  //context api
  const spinner = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = spinner.state;

  const [key, setKey] = useState("summary");
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [newParcelTab, setNewParcelTab] = useState(false);
  const [ediStatusShow, setEdiStatusShow] = useState(false);
  const [parcelSwitchFlag, setParcelSwitchFlag] = useState(false);
  const [parcelAddFlag, setParcelAddFlag] = useState(false);
  const [isStoreInDB, setIsStoreInDB] = useState(true);

  const [viewParcelData, setViewParcelData] = useState(false);
  const [showToollBar, setShowToollBar] = useState(false);
  const [automationProcess, setAutomationProcess] = useState(false);
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [statusOptionsData, setStatusOptionsData] = useState([]);
  const [parcelList, setParcelList] = useState([]);
  const [orderConflicts, setOrderConflicts] = useState([]);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [auditLogData, setAuditLogData] = useState([]);
  //context api
  const parcelState = {
    toolbar: true,
    mode: "view",
    key: "summary",
    parcelId: null,
    parcelList: [],
    refetch: false,
    orderData: {},
    year: "",
    orderTwoView: false,
    parcelTwoView: false,
    parcelPdfDoc: false,
    parcelConflicts: false,
    supportingTwoView: false,
    supportigDocList: false,
    supportigDocListShowFromPdfBtn: false,
    orderRefecth: false,
    storeCertificateInDbFun: function () {},
  };
  const [parcelController, setParcelController] = useState(parcelState);

  const [orderContorller, setOrderController] = useState({
    pdfDoc: "",
    orderConflicts: false,
  });
  // console.log(parcelController?.orderData?.branch_name);
  const { state } = useLocation();
  const editId = state?.id;
  const orderNumber = state?.orderNumber;
  const orderEditableFlag = state?.orderEditableFlag;
  const orderEvent = state?.orderEvent;

  //redux
  const { internalOrder } = useSelector((state) => state.flagRoles);

  const navigate = useNavigate();
  //new parcel tab flags
  const [newParcelTabFlag, setNewParcelTabFlag] = useState(false);

  //order info for copy details
  const [orderInfoCopyModal, setOrderInfoCopyModal] = useState(false);
  //responsice
  const [res, setRes] = useState(false);
  //drawer
  const [open, setOpen] = useState(null);
  const [certJson, setCertJson] = useState(false);

  //audit logs
  const [auditLogsListCall, auditLogsListResult] = useAuditLogsListMutation();
  const [addCertifciateDownloadLogs] = useCertificateDownloadLogsMutation();

  const [editable, setEditable] = useState(false);
  const [conflictsNotification, setConflictsNotification] = useState(false);
  const [conflictsProcess, setConflictsProcess] = useState(false);

  const { token, user } = useSelector((state) => state.auth);

  //review conflicts
  const [reviewConflicts, reviewConflictsResult] = useReviewConflictsMutation();

  //send email certificate
  const [sendEmailCertificate, sendEmailCertificateResult] =
    useSendEmailCertificateMutation();

  //Get email certificate
  const [getCloserEmail, getCloserEmailResult] = useGetCloserEmailMutation();

  //api
  const [addCertificateDocuments, addCertificateDocumentsResult] =
    useUploadCertificateMutation();

  //certificate
  const [certificate, certificateResult] = useOrderCertificateMutation();
  //parcels api
  const [parcelStatusList, parcelStatusListResult] =
    useParcelStatusListMutation();

  //order conflicts m
  const [conflicts, conflictsResult] = useConflictsMutation();

  function confilctsRefecth() {
    conflicts({ data: { order_id: editId }, token });
  }

  //auditlist
  useEffect(() => {
    if (auditLogsListResult.isSuccess) {
      setAuditLogData(auditLogsListResult.data?.data);
      setShowAuditLogModal(true);
    } else {
      setAuditLogData([]);
      setShowAuditLogModal(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: auditLogsListResult.isLoading });
  }, [auditLogsListResult.isLoading]);

  //useEffect for certifcate
  useEffect(() => {
    if (certificateResult.isSuccess) {
      setCertJson(certificateResult?.data?.data);
      setTimeout(() => {
        exportPDFWithMethod();
      }, 1000);
    }
    if (certificateResult.isError) {
      setCertJson(false);
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: certificateResult.data?.message || "Something went wrong!",
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: certificateResult.isLoading });
  }, [certificateResult.isLoading]);

  useEffect(() => {
    if (reviewConflictsResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: reviewConflictsResult.data?.message,
      });
      // //call api for conflicts
      validateConflicts({
        data: { order_id: editId },
        token,
      });
    }

    // //global spinner
    // setGlobalVal({ ...globalVal, loader: reviewConflictsResult.isLoading });
  }, [reviewConflictsResult.isLoading]);

  //certifcate send on emal
  useEffect(() => {
    if (sendEmailCertificateResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: sendEmailCertificateResult.data?.Message,
      });
    }
    if (sendEmailCertificateResult.isError) {
      setCertJson(false);
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          sendEmailCertificateResult.error.data?.Message ||
          "Something went wrong!",
      });
    }
    // //global spinner
    setGlobalVal({
      ...globalVal,
      loader: sendEmailCertificateResult.isLoading,
    });
  }, [sendEmailCertificateResult.isLoading]);

  //certifcate send on emal
  useEffect(() => {
    if (getCloserEmailResult.isSuccess) {
      setShowEmailModal(getCloserEmailResult.data.data);
    }
    if (getCloserEmailResult.isError) {
      setShowEmailModal(true);
    }
    // //global spinner
    setGlobalVal({
      ...globalVal,
      loader: getCloserEmailResult.isLoading,
    });
  }, [getCloserEmailResult.isLoading]);

  useEffect(() => {
    setNewParcelTab(newParcelTab);
    setEditable(false);
    setParcelAddFlag(parcelAddFlag);
    if (newParcelTab && !parcelAddFlag) setKey("newparcel");
    else setKey("summary");
  }, [window.location.pathname]);

  useEffect(() => {
    //making empty parcel list
    if (orderEvent === "add") {
      setParcelList([]);
      setOrderController({ orderConflicts: false, pdfDoc: false });
      setParcelController({
        ...parcelState,
        orderData: parcelController.orderData,
      });
    }
  }, [orderEvent]);
  //getting status api
  const orderStatusApi = useOrderStatusQuery(token, { skip: !editId });
  //update conflicts info
  const [validateConflicts, validateConflictsResult] =
    useValidateConflictsMutation();
  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoUpdateStatusResult] =
    useAutoConflictsStatusUpdateMutation();

  //getting Delete order api
  const [orderDelete, orderDeleteResult] = useDeleteOrderMutation();

  //order parcel clone  m
  const [parcelClone, parcelCloneResult] = useCloneParcelFromOrderMutation();

  function storeCertificateInDb() {
    setIsStoreInDB(true);
    //store certificate in db
    certificate({ id: editId, token });
  }

  //useEffect for cloning parcel from order
  useEffect(() => {
    if (parcelCloneResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: parcelCloneResult.data.message,
      });
      //fecthing again parcelstatus list
      parcelStatusList({ id: editId, token });
    }
    if (parcelCloneResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: parcelCloneResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelCloneResult.isLoading });
  }, [parcelCloneResult.isLoading]);

  //getting status api
  const [orderStatusUpdate, orderStatusUpdateResult] =
    useOrderStatusUpdateMutation();

  useEffect(() => {
    if (editId) {
      if (orderEvent === "clone") {
        setEditable(true);
      } else if (orderEvent === "edit") {
        setEditable(orderEditableFlag);
      } else if (orderEvent === "afterAddOrder") {
        setEditable(orderEditableFlag);
        //after add order event
        validateConflicts({
          data: { order_id: editId },
          token,
        });
      }
      orderStatusApi.refetch();
      setEdiStatusShow(false);
      //getting pacels list
      parcelStatusList({ id: editId, token });

      setAutomationProcess(false);
      //geting conflicts
      conflicts({ data: { order_id: editId }, token });
      setOrderController({ orderConflicts: false, pdfDoc: false });
    }
  }, [editId]);

  useEffect(() => {
    setStatus(orderStatus);
  }, [orderStatus]);

  useEffect(() => {
    if (orderStatusApi.isSuccess) {
      setStatusOptionsData(orderStatusApi.data.data);
      setParcelController({
        ...parcelController,
        orderTwoView: false,
        parcelTwoView: false,
      });
    } else {
      setStatusOptionsData([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderStatusApi.isFetching });
  }, [orderStatusApi.isFetching]);

  useEffect(() => {
    if (autoUpdateStatusResult.isSuccess) {
      parcelController.orderRefecth && parcelController.orderRefecth.refetch();

      if (parcelList.length) {
        //save certificate in db
        storeCertificateInDb();
      }
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: autoUpdateStatusResult.isLoading });
  }, [autoUpdateStatusResult.isLoading]);

  ///order status
  useEffect(() => {
    if (orderStatusUpdateResult.isSuccess) {
      //refrcth Order api

      validateConflicts({
        data: { order_id: editId },
        token,
      });

      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderStatusUpdateResult.data.message,
      });
      setEdiStatusShow(false);
    } else if (orderStatusUpdateResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: orderStatusUpdateResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderStatusUpdateResult.isLoading });
  }, [orderStatusUpdateResult.isLoading]);

  ///conflicts status
  useEffect(() => {
    if (conflictsResult.isSuccess) {
      const newData = conflictsResult.data.data;
      setOrderConflicts(newData);
      let isError = newData.filter(({ is_fixed }) => {
        return !is_fixed;
      });

      if (isError.length) {
        setConflictsNotification(true);
      } else {
        setConflictsNotification(false);
      }
    }
    if (conflictsResult.isError) {
      setOrderConflicts([]);
      setConflictsNotification(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: conflictsResult.isLoading });
  }, [conflictsResult.isLoading]);

  ///order delete
  useEffect(() => {
    if (orderDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderDeleteResult.data.message,
      });
      //navigate to dashboard
      navigate("/order");
    } else if (orderDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: orderDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderDeleteResult.isLoading });
  }, [orderDeleteResult.isLoading]);

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (validateConflictsResult.isSuccess) {
      //geting conflicts
      conflicts({ data: { order_id: editId }, token });
      //auto update status
      autoStatusUpdate({ data: { order_id: editId }, token });
    }

    if (validateConflictsResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          validateConflictsResult.error?.data?.message ||
          validateConflictsResult.error.error ||
          validateConflictsResult.error.data?.msg.sqlMessage,
      });
    }
  }, [validateConflictsResult.isLoading]);

  const apiCalljuriAutomationDocsUpdate = async (array) => {
    await axios.all(
      array.map(
        async (ele) =>
          await axios.post(
            `${config.url}/add-parcel-documents`,
            {
              document_list: ele.supporting_files,
              parcel_id: ele.parcel_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
      )
    );
  };

  const apiCalljuriAutomationUpdate = async (array) => {
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.post(
              `${config.url}/update-parcel-jurisdiction`,
              { taxes: ele?.result?.taxes, parcel_id: ele.parcel_id },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
        )
      )
      .then((data) => {
        //fecthing again parcelstatus list
        parcelStatusList({ id: editId, token });
        reviewConflicts({
          data: { user_id: user?.user_id },
          id: editId,
          token,
        });
        setAutomationProcess(false);
      });
  };

  const apiCallAutomationStatus = async (array, refecth) => {
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.post(
              `${config.url}/update-parcel-automation/${ele.parcel_id}`,
              { automation_status_id: ele.OrderStatus },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
        )
      )
      .then((data) => {
        if (refecth) {
          //fecthing again parcelstatus list
          parcelStatusList({ id: editId, token });
          reviewConflicts({
            data: { user_id: user?.user_id },
            id: editId,
            token,
          });
          setAutomationProcess(false);
        }
      });
  };
  var testCalls = 0;
  const apiCall = async (array) => {
    setAutomationProcess(true);

    //axios request
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.get(`${config.url}/get-order-status/${ele}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
        )
      )
      .then((data) => {
        testCalls = testCalls + 1;
        const refData = data.map((ele) => {
          const orderId = ele.config.url.split("/");
          const { result, supporting_files, OrderStatus, parcel_id } =
            ele.data.data;

          let docs = supporting_files?.map(({ file, file_name }) => {
            return {
              document_classification_id: 2,
              document: `data:application/pdf;base64,${file}`,
              name: file_name + "_" + dateFun("datetime"),
              type: "application/pdf",
            };
          });

          return {
            refno: Number(orderId[orderId.length - 1]),
            supporting_files: docs?.length ? docs : null,
            result,
            OrderStatus,
            parcel_id: parcel_id,
          };
        });

        //make req for success
        const filterDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "3";
        });

        if (filterDatareq.length) {
          apiCallAutomationStatus(filterDatareq);
          apiCalljuriAutomationUpdate(filterDatareq);
          apiCalljuriAutomationDocsUpdate(filterDatareq);
        }

        //make req for Inprogress
        const filterProgressDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "2";
        });

        if (filterProgressDatareq.length && testCalls < 3) {
          setTimeout(() => {
            apiCall(
              filterProgressDatareq.map((ele) => {
                return ele.refno;
              })
            );
          }, 20000);
        } else if (filterProgressDatareq.length) {
          apiCallAutomationStatus(
            filterProgressDatareq.map(({ OrderStatus, ...other }) => {
              return { OrderStatus: 4, ...other };
            }),
            true
          );
        }
        //make req for failed
        const filterFailDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "4";
        });

        if (filterFailDatareq.length) {
          setTimeout(() => {
            apiCallAutomationStatus(filterFailDatareq, true);
          }, 20000);
        }

        // apiCallAutomationData;

        // automationStatusUpdate({
        //   data: { automation_status_id: 4 },
        //   token,
        //   id: taxOrderRefNumber.taxId,
        // });
        //getting pacels list
        // setTimeout(() => {
        //   parcelStatusList({ id: editId, token });
        // }, 10000);
      });
    // .catch((e) => {
    //   // console.log(e.response);
    // });
  };

  useEffect(() => {
    if (parcelStatusListResult.isSuccess) {
      const dataList = parcelStatusListResult.data.data;
      setParcelList(dataList);
      //global spinner
      //global spinner

      //request for multiple api
      const apiData = [];
      dataList?.map(({ automation_status_id, automation_ref_no }) => {
        if (automation_status_id === 2) {
          apiData.push(automation_ref_no);
        }
      });

      if (apiData.length > 0 && apiData.length <= 15) {
        setTimeout(() => {
          apiCall(apiData);
        }, 3000);
      }
    } else if (parcelStatusListResult.isError) {
      setParcelList([]);
    }

    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: parcelStatusListResult.isLoading,
      orderCertificate: !globalVal.orderCertificate,
    });
  }, [parcelStatusListResult.isLoading]);

  const addNewParcel = () => {
    setNewParcelTabFlag(true);
    setKey("new_parcel");
  };

  //const tab change handler
  const tabChangeHandler = (value) => {
    setKey(value);
  };

  //deleteorder
  const deleteOrderHandler = () => {
    //calling delete api
    orderDelete({ id: editId, token });
  };

  useEffect(() => {
    if (key === "summary") {
      setNewParcelTabFlag(false);

      setViewParcelData(false);
      //getting pacels list
      if (editId) {
        parcelStatusList({ id: editId, token });

        //geting conflicts
        conflicts({ data: { order_id: editId }, token });
      }
    }
    ///default mode pr parcel controller
    setParcelController({
      ...parcelController,
      mode: "view",
      key: key,
      refetch: false,
    });
    if (key !== "summary" && parcelList.length <= 5) {
      const data = parcelList.filter(({ cad_property_id }) => {
        return cad_property_id === key;
      });
      setViewParcelData(data[0]);
      setParcelController({
        ...parcelController,
        storeCertificateInDbFun: storeCertificateInDb,
      });
      // setParcelController({ ...parcelController, year: data[0]?.parcel_year });
    }
  }, [key]);

  //fetch history auditlogs
  function fecthingAuditHistoryLogs() {
    auditLogsListCall({ id: editId, token });
  }

  //download certificate
  function downloadCertificate() {
    setIsStoreInDB(false);
    certificate({ id: editId, token });
  }
  //call back to function
  const parcleListComp = useCallback(() => {
    return (
      <div
        className={`row mt-3 mb-2 mt-2${
          parcelController.orderTwoView ? "bg-white border" : null
        }`}
      >
        {/* <div className="col-12">
          {inputs.map((input) => {
            return (
              <AppSwitch key={input.id} onChange={appSwitchHanler} {...input} />
            );
          })}
        </div> */}
        {parcelList.map((ele, index) => {
          return (
            <div key={ele.id} className="col-12 col-md-6 my-2">
              <ParcelCard
                hoaData={ele}
                number={ele.cad_property_id}
                parcel={index + 1}
                status={ele.status}
                automation={ele.automation_status_id}
              />
            </div>
          );
        })}
      </div>
    );
  }, [parcelStatusListResult.isLoading, parcelList, parcelSwitchFlag]);

  useEffect(() => {
    if (orderContorller.pdfDoc) {
      scrollComp("embed");
    }
  }, [orderContorller.pdfDoc]);

  const conflictsComp = useCallback(() => {
    return (
      <Conflicts
        onchek={(value) => {
          reviewConflicts({
            data: { user_id: user?.user_id },
            id: value,
            token,
          });
        }}
        onclose={() => {
          setOrderController({
            ...orderContorller,
            pdfDoc: false,
          });
          setParcelController({
            ...parcelController,
            orderTwoView: orderContorller.orderConflicts ? true : false,
          });
        }}
        isconflicts={orderContorller.orderConflicts}
        conflicts={orderConflicts}
        orderContorller={orderContorller}
      />
    );
  }, [
    orderContorller,
    orderConflicts,
    !conflictsResult.isLoading,
    !parcelStatusListResult.isLoading,
  ]);

  //start initialization
  const startAutmation = async () => {
    console.log("test");
    setAutomationProcess(true);
    const list = parcelList.filter(
      ({ automation_ref_no, automation_status_id }) => {
        return !automation_ref_no || automation_status_id === 4;
      }
    );
    if (list.length) {
      await axios
        .all(
          list.map(
            async (ele) =>
              await axios.post(
                `${config.url}/initiate-automation`,
                { order_id: editId, parcel_id: ele.id },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
          )
        )
        .then(() => {
          //fecthing again parcelstatus list
          setTimeout(() => {
            parcelStatusList({ id: editId, token });
          }, 3000);
        });
    } else {
      setAutomationProcess(false);
    }
  };

  const PageTemplate = (props) => {
    // console.log(props)

    return (
      <>
        <div
          style={{
            top: 10,
            right: 0,
            left: "1px",
            position: "absolute",
            width: "98%",
          }}
          className="d-flex align-items-center justify-content-between px-2"
        >
          <div className="log" style={{ width: "110px" }}>
            <img
              width={"100%"}
              src="../../Assets/img/CertSimpleLogo.svg"
              alt=""
            />
          </div>

          <div className="page d-inline-block ">
            <h4>
              Page {props.pageNum} of {props.totalPages}
            </h4>
          </div>
        </div>
        <div
          className={`${
            String(status) === "6" ||
            !parcelController?.orderData?.is_prelimnary
              ? "d-none"
              : null
          }`}
          style={{
            position: "absolute",
            top: "50%",
            left: "15%",
            textAlign: "center",
            transform: "rotate(-35deg)",
            zIndex: -1,
          }}
        >
          <h1
            style={{
              fontSize: 60,
              color: "#F55050",
              opacity: 0.5,
              fontWeight: "bold",
            }}
          >
            PRELIMINARY
          </h1>
        </div>
      </>
    );
  };

  const content = React.useRef(null);
  const templateObj = {
    pageTemplate: PageTemplate,
    keepTogether: ".tax-bill-summary",
    repeatHeaders: true,
    forcePageBreak: ".page-break",
    paperSize: "A4",
    fileName: `${
      parcelController?.orderData?.file_name || "certsimple"
    }-${dateFun("datetime")}.pdf`,
    margin: {
      top: "1cm",
      left: "0.8cm",
      right: "0.8cm",
      bottom: "0.8cm",
    },
  };

  //function for download certificate
  function saveLocalCertificate() {
    savePDF(content.current, { ...templateObj });
    setGlobalVal({ ...globalVal, loader: false });
    notification.open({
      type: "success",
      style: { color: "green", marginTop: "50px" },
      description: "Certificate Download Successfully",
    });
    //adding logs for certifcate download
    addCertifciateDownloadLogs({ order_id: editId, token });
  }
  const exportPDFWithMethod = () => {
    setGlobalVal({ ...globalVal, loader: true });

    let element = content.current;
    if (element !== null) {
      drawDOM(element, {
        template: ({ pageNum, totalPages }) => {
          let z = document.createElement("div");
          z.classList.add("dbheader");
          z.innerHTML = ` <header>
          <div className="log" style="width: 110px; margin-bottom:5px;">
            <img
             style="object-fit: cover; "
              width="100%"
              src="../../Assets/img/CertSimpleLogo.svg"
              alt=""
            />
          </div>
          
          <div className="page d-inline-block ">
            <h4>
              Page ${pageNum} of ${totalPages}
            </h4>
          </div>
        </header> 
        <div
        style="position: absolute; top: 50%; text-align: center; transform: rotate(-35deg); z-index: -1; height:700px; width:100%;  justify-content: center;display: flex;   align-items: center;"
          class=${
            String(status) === "6" ||
            !parcelController?.orderData?.is_prelimnary
              ? "d-none"
              : ""
          }
          >
          <h1
           style="font-size:60px; color:#F55050;opacity:0.5; font-weight:bold;"
          >
            PRELIMINARY
          </h1>
        </div>
        `;

          return z;
        },
        repeatHeaders: true,
        keepTogether: ".tax-bill-summary",
        forcePageBreak: ".page-break",
        paperSize: "A4",
        fileName: `${
          parcelController?.orderData?.file_name || "certsimple"
        }-${dateFun("datetime")}.pdf`,
        margin: {
          top: "1cm",
          left: "0.8cm",
          right: "0.8cm",
          bottom: "0.8cm",
        },
      })
        .then((group) => {
          return exportPDF(group);
        })
        .then((dataUri) => {
          ///data schema defined
          const data = {
            documents: dataUri,
            document_name: `${
              parcelController?.orderData?.file_name || "certsimple"
            }-${dateFun("datetime")}.pdf`,
            is_final_certificate: String(status) === "6",
          };

          if (isStoreInDB || String(status) === "6") {
            //adding certifciate in db
            addCertificateDocuments({
              data,
              token,
              order_id: editId,
            });
          } else {
            //saving certificate only in local machine
            saveLocalCertificate();
          }
        });
    }
  };
  //useEffects for certificate upload in db
  useEffect(() => {
    if (addCertificateDocumentsResult.isSuccess) {
      setGlobalVal({ ...globalVal, loader: false });
      if (!isStoreInDB) {
        saveLocalCertificate();
      }
    }
    if (addCertificateDocumentsResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          addCertificateDocumentsResult.error?.data?.error ||
          addCertificateDocumentsResult.error?.data?.message ||
          addCertificateDocumentsResult.error.error ||
          addCertificateDocumentsResult.error.data?.msg.sqlMessage,
      });

      setGlobalVal({ ...globalVal, loader: false });
    }
  }, [addCertificateDocumentsResult.isLoading]);

  const SendEmailComp = () => {
    let disable = parcelController?.orderData?.order_doc_list?.filter(
      ({ document_classification_id }) => document_classification_id === 3
    );

    return (
      <Tooltip
        overlayInnerStyle={{
          fontSize: 10,
        }}
        placement="bottom"
        title={"Email"}
      >
        <div
          className={`add_icon ${!disable?.length ? "border-secondary" : null}`}
          onClick={() => {
            if (disable?.length) {
              // sendEmailCertificate({
              //   order_id: editId,
              //   token,
              // });
              getCloserEmail({ order_id: editId, token });
            }
          }}
        >
          <HiOutlineMail color={!disable?.length ? "#808080" : "#0d6efd"} />
        </div>
      </Tooltip>
    );
  };

  return (
    <parcelContext.Provider
      value={{
        controller: [parcelController, setParcelController],
        orderContorllerState: [orderContorller, setOrderController],
        confilctsDataRefecth: confilctsRefecth,
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "-1500px",
          top: 0,
        }}
      >
        <div
          style={{
            width: "547px",
          }}
          ref={content}
        >
          <CertificateContent values={certJson} />
        </div>
      </div>
      {/* //certificate pdf  */}

      <div className="order">
        <ViewParcel
          onselect={(values) => console.log(values)}
          open={open}
          onChange={(value) => {
            const { cad_property_id, ...data } = value;
            setViewParcelData({ cad_property_id, ...data });

            setKey(key === "parcel_1" ? "parcel_2" : "parcel_1");
          }}
          setOpen={setOpen}
          list={parcelList}
          parcelStatusListRefetch={parcelStatusList}
        />

        {/* //order info  */}
        <AppModal
          width={"80%"}
          showModal={orderInfoCopyModal}
          onClose={setOrderInfoCopyModal}
          element={<OrderInfoCopyModal />}
        />

        {/* //Email Modal  */}
        <AppModal
          width={"50%"}
          showModal={showEmailModal}
          onClose={setShowEmailModal}
          element={
            <EmailModal
              afterSubmit={setShowEmailModal}
              email={showEmailModal?.closer_email || []}
              options={showEmailModal?.user_closer_list || []}
              random={randomPass.randomPassword({
                length: 9,
                characters: randomPass.digits,
              })}
              editId={editId}
            />
          }
        />

        {/* //history  */}
        <AppModal
          width={"85%"}
          onClose={(value) => {
            setShowAuditLogModal(value);
          }}
          showModal={showAuditLogModal}
          // afterClose={() => {
          //   setShowAuditLogModal(!showAuditLogModal);
          // }}
          element={
            <div className="p-3">
              <AuditLogs title="Order" data={auditLogData} />
            </div>
          }
        />
        {/* //loader  */}

        {window.location.pathname.split("/")[1] === "order-editor" &&
          !window.location.pathname.split("/")[2] && (
            <Tabs
              activeKey={key}
              className="title-container app-tab"
              defaultActiveKey="summary"
              onChange={tabChangeHandler}
              tabBarExtraContent={
                editId && (
                  <div className="ant-tabs-nav-wrap py-3 d-flex align-items-center">
                    <span className=" text-success fw-bold fs-12 h-100">
                      {orderNumber}
                    </span>
                    <Tooltip
                      overlayInnerStyle={{
                        fontSize: 10,
                      }}
                      placement="top"
                      title={"Order Summary"}
                    >
                      <div
                        onClick={() => setOrderInfoCopyModal(true)}
                        className="add_icon ms-2"
                      >
                        <MdOutlinePreview color="#0d6efd" />
                      </div>
                    </Tooltip>
                  </div>
                )
              }
            >
              <TabPane tab="Summary" key={"summary"}>
                <>
                  {/* //showing import order record  */}

                  {((showToollBar && orderEditableFlag) ||
                    orderEvent === "afterAddOrder") && (
                    <div className="row mb-2 bg-white p-2">
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="text-center ms-2 fs-11 text-dark d-flex gap-2 align-items-center">
                            <label
                              htmlFor="status"
                              className="text-dark fw-bold fs-6 m-0"
                            >
                              Tax Status :
                            </label>
                            <div>
                              {orderStatus && (
                                <AppSelect
                                  // isEdit={editId}
                                  classStyle={`${
                                    editable || ediStatusShow
                                      ? "border px-2 py-1  "
                                      : "form-control border-0 bg-white"
                                  }`}
                                  //   defaultSet={afterClose}

                                  name="status"
                                  disabled={editable ? false : !ediStatusShow}
                                  onChange={(e, value) => {
                                    setOrderStatus(value);
                                  }}
                                  value={orderStatus || ""}
                                  options={
                                    <>
                                      {statusOptionsData?.map((ele) => {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  }
                                />
                              )}
                            </div>
                            {ediStatusShow && !editable ? (
                              <>
                                <button
                                  onClick={() => {
                                    orderStatusUpdate({
                                      status_id: status,
                                      id: editId,
                                      token,
                                    });
                                  }}
                                  className=" border bg-primary  fs-12 text-white"
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => {
                                    setEdiStatusShow(false);
                                    setStatus(orderStatus);
                                  }}
                                  className=" border fs-12 text-dark"
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              !editable && (
                                <FiEdit
                                  onClick={() => {
                                    setEdiStatusShow(true);
                                  }}
                                  size={12}
                                  className="mt-1"
                                  cursor="pointer"
                                />
                              )
                            )}
                            {conflictsNotification && orderEvent !== "add" && (
                              <div>
                                <h6 className="conflicts-pop fs-10 mb-0 d-inline-block">
                                  Please Review the conflicts
                                </h6>
                              </div>
                            )}
                            {/* //order status I am using as edit values are loaded
                            or not */}
                            {orderStatus &&
                              !conflictsNotification &&
                              orderEvent !== "add" && (
                                <div>
                                  <h6 className="fs-10 mb-0 d-inline-block fw-bold text-success">
                                    No conflicts found
                                  </h6>
                                </div>
                              )}
                          </div>
                          <div className="action-buttons d-flex align-items-center gap-2 fw-bold">
                            {/* //order list for cloning  */}
                            {internalOrder.map((item, index) => {
                              if (item.clone_order) {
                                return (
                                  <OrderList
                                    key={index}
                                    title={"Clone Parcel From Order"}
                                    icon={<FaRegClone color="#0d6efd" />}
                                    onChange={(cloneOrderId) => {
                                      //will get ordder id from cliked list
                                      parcelClone({
                                        cloneOrderId,
                                        currentOrderId: editId,
                                        token,
                                      });
                                    }}
                                  />
                                );
                              } else if (item.run_validation) {
                                return (
                                  !conflictsProcess && (
                                    <Tooltip
                                      key={index}
                                      overlayInnerStyle={{
                                        fontSize: 10,
                                      }}
                                      placement="bottom"
                                      title={"Run Validation"}
                                    >
                                      <div
                                        onClick={() => {
                                          //validatio start
                                          validateConflicts({
                                            data: { order_id: editId },
                                            token,
                                          });
                                        }}
                                        className="add_icon"
                                      >
                                        <VscRunErrors color="#0d6efd" />
                                      </div>
                                    </Tooltip>
                                  )
                                );
                              } else if (item.conflicts_action_required) {
                                return conflictsProcess ? (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Conflicts Inprogress"}
                                  >
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 24,
                                          }}
                                          spin
                                        />
                                      }
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    className="test-svg"
                                    title={"Conflicts Action Required"}
                                  >
                                    <div
                                      onClick={async () => {
                                        setParcelController({
                                          ...parcelController,
                                          orderTwoView: orderContorller.pdfDoc
                                            ? true
                                            : !parcelController.orderTwoView,
                                        });
                                        setOrderController({
                                          ...orderContorller,
                                          orderConflicts:
                                            !orderContorller.orderConflicts,
                                        });
                                      }}
                                      className="add_icon border-danger"
                                    >
                                      <CgViewSplit color="red" />
                                    </div>
                                  </Tooltip>
                                );
                              } else if (item.delete_order) {
                                return (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Delete Order"}
                                  >
                                    <Popconfirm
                                      getPopupContainer={(trigger) =>
                                        trigger.parentElement
                                      }
                                      title="Are you sure you want to delete？"
                                      onConfirm={deleteOrderHandler}
                                      placement="bottom"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{
                                            color: "red",
                                          }}
                                        />
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <div className="add_icon ">
                                        <MdDeleteOutline color="#0d6efd" />
                                      </div>
                                    </Popconfirm>
                                  </Tooltip>
                                );
                              } else if (item.download_pdf) {
                                let disable = parcelList?.length !== 0;
                                return (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Download Certificate"}
                                  >
                                    <div
                                      onClick={async () => {
                                        if (disable) {
                                          !ediStatusShow &&
                                            downloadCertificate();
                                        }
                                      }}
                                      className={`add_icon ${
                                        !disable ? "border-secondary" : null
                                      }`}
                                    >
                                      <FiDownload
                                        color={!disable ? "#808080" : "#0d6efd"}
                                      />
                                    </div>
                                  </Tooltip>
                                );
                              } else if (item.initialize_automation) {
                                let disable =
                                  parcelList?.length === 0 ? true : false;
                                return (
                                  <>
                                    {" "}
                                    {automationProcess ? (
                                      <Tooltip
                                        key={index}
                                        overlayInnerStyle={{
                                          fontSize: 10,
                                        }}
                                        placement="bottom"
                                        title={"Automation Inprogress"}
                                      >
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              style={{
                                                fontSize: 24,
                                              }}
                                              spin
                                            />
                                          }
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        key={index}
                                        overlayInnerStyle={{
                                          fontSize: 10,
                                        }}
                                        placement="bottom"
                                        title={"Initialize Automation"}
                                      >
                                        <div
                                          onClick={
                                            disable ? undefined : startAutmation
                                          }
                                          // className="add_icon"
                                          className={`${
                                            disable ? "border-secondary" : null
                                          } add_icon`}
                                        >
                                          <MdOutlineSettings
                                            color={
                                              disable ? "#808080" : "#0d6efd"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </>
                                );
                              } else if (item.email_pdf) {
                                return <SendEmailComp key={index} />;
                              } else if (item.view_documents) {
                                let disable =
                                  parcelController?.orderData?.order_doc_list
                                    ?.length !== 0;
                                return (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"View Document"}
                                  >
                                    <div
                                      onClick={() => {
                                        if (disable) {
                                          const certificate =
                                            parcelController?.orderData?.order_doc_list.filter(
                                              ({
                                                document_classification_id,
                                              }) =>
                                                document_classification_id === 3
                                            );
                                          const notcertificate =
                                            parcelController?.orderData?.order_doc_list.filter(
                                              ({
                                                document_classification_id,
                                              }) =>
                                                document_classification_id !== 3
                                            );

                                          let document = {};
                                          if (certificate?.length) {
                                            document = certificate[0];
                                          } else {
                                            document = notcertificate[0];
                                          }
                                          const { documents, type } = document;

                                          //validatio start
                                          setOrderController({
                                            ...orderContorller,
                                            pdfDoc: {
                                              base64: atob(documents),
                                              type,
                                            },
                                          });
                                          setParcelController({
                                            ...parcelController,
                                            orderTwoView: true,
                                          });
                                        }
                                      }}
                                      className={`${
                                        !disable ? "border-secondary" : null
                                      } add_icon`}
                                    >
                                      <BsFilePdf
                                        color={!disable ? "#808080" : "#0d6efd"}
                                      />
                                    </div>
                                  </Tooltip>
                                );
                              } else if (item.edit_order) {
                                return !editable ? (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Edit"}
                                  >
                                    <div
                                      className="add_icon "
                                      onClick={() => setEditable(true)}
                                    >
                                      <EditOutlined color="#0d6efd" />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Close"}
                                  >
                                    <div
                                      className="add_icon "
                                      onClick={() => setEditable(false)}
                                    >
                                      <CloseOutlined color="#0d6efd" />
                                    </div>
                                  </Tooltip>
                                );
                              } else if (item.view_parcel) {
                                return (
                                  parcelList.length > 5 && (
                                    <Tooltip
                                      key={index}
                                      overlayInnerStyle={{
                                        fontSize: 10,
                                      }}
                                      placement="bottom"
                                      title={"View Parcel"}
                                    >
                                      <div className="add_icon">
                                        <AiOutlineEye
                                          onClick={() => setOpen(true)}
                                          color="#0d6efd"
                                        />
                                      </div>
                                    </Tooltip>
                                  )
                                );
                              } else if (item.history) {
                                return (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"History"}
                                  >
                                    <div
                                      className="add_icon"
                                      onClick={() => {
                                        fecthingAuditHistoryLogs();
                                      }}
                                    >
                                      <AiOutlineAudit
                                        color="#3388FF"
                                        cursor="pointer"
                                      />
                                    </div>
                                  </Tooltip>
                                );
                              } else if (item.add_parcel) {
                                return (
                                  <Tooltip
                                    key={index}
                                    overlayInnerStyle={{
                                      fontSize: 10,
                                    }}
                                    placement="bottom"
                                    title={"Add Parcel"}
                                  >
                                    <div
                                      className="add_icon"
                                      onClick={addNewParcel}
                                    >
                                      <FaPlus />
                                    </div>
                                  </Tooltip>
                                );
                              }
                            })}

                            {/* <Tooltip
                              overlayInnerStyle={{
                                fontSize: 10,
                              }}
                              placement="bottom"
                              title={"View Certificate"}
                            >
                              <div
                                onClick={async () => {
                                  // localStorage.setItem(
                                  //   "pdf",
                                  //   btoa(
                                  //     await certificateData.output(
                                  //       "datauristring"
                                  //     )
                                  //   )
                                  // );
                                  // window.open(
                                  //   "/certificate-view",
                                  //   "_blank",
                                  //   "width=1300,height=700"
                                  // );
                                  setParcelController({
                                    ...parcelController,
                                    orderTwoView:
                                      !parcelController.orderTwoView,
                                  });
                                  setOrderController({
                                    ...orderContorller,
                                    pdfDoc:
                                      certificateData.output("datauristring"),
                                  });
                                }}
                                className="add_icon  "
                              >
                                <AiOutlineFilePdf color="#0d6efd" />
                              </div>
                            </Tooltip> */}

                            {/* <Tooltip
                            overlayInnerStyle={{
                              fontSize: 10,
                            }}
                            placement="bottom"
                            title={"Email"}
                          >
                            <div className="add_icon ">
                              <HiOutlineMail color="#0d6efd" />
                            </div>
                          </Tooltip>
                          <Tooltip
                            overlayInnerStyle={{
                              fontSize: 10,
                            }}
                            placement="bottom"
                            title={"Download"}
                          >
                            <div className="add_icon ">
                              <MdOutlineCloudDownload color="#0d6efd" />
                            </div>
                          </Tooltip> */}
                            {/* <Tooltip
                                  overlayInnerStyle={{
              fontSize: 10,
            }}
                          placement="bottom" title={"Assign"}>
                            <div className="add_icon ">
                              <MdOutlineAssignment color="#0d6efd" />
                            </div>
                          </Tooltip> */}

                            {/* {
                            <div
                              className="add_icon"
                              onClick={() => setRes(true)}
                            >
                              <AiOutlineFilePdf color="#0d6efd" />
                            </div>
                          } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row align-items-stretch" id="embed">
                    {parcelController?.orderTwoView && (
                      <div className="col-12 col-lg-6 ">
                        {/* <div className="w-25 ms-auto d-flex justify-content-end">
                          <button
                            onClick={() => {
                              setParcelController({
                                ...parcelController,
                                orderTwoView: false,
                              });
                            }}
                            className="btn btn-outline-pirmary border-primary btn-sm mb-2   px-4"
                          >
                            X
                          </button>
                        </div> */}

                        {conflictsComp()}
                      </div>
                    )}

                    <div
                      className={`col-12 col-lg-${
                        parcelController?.orderTwoView ? 6 : null
                      }`}
                    >
                      <NewOrder
                        extraData={{ status_id: orderStatus }}
                        setOrderStatus={setOrderStatus}
                        res={res}
                        editId={editId}
                        refresh={key}
                        setShowToollBar={setShowToollBar}
                        orderEvent={orderEvent}
                        editable={editable}
                        parcelList={parcelList}
                        setEditable={setEditable}
                        setLoading={setLoading}
                        orderEditableFlag={orderEditableFlag}
                        addCertificateDocumentsResult={
                          addCertificateDocumentsResult
                        }
                        validateConflicts={validateConflicts}
                      />
                    </div>
                  </div>
                </>

                {/* //parcel cards  */}
                {parcleListComp()}
              </TabPane>
              {newParcelTabFlag && (
                <TabPane tab="New Parcel" key="new_parcel">
                  <NewParcel tabKey={setKey} />
                </TabPane>
              )}
              {parcelList.length <= 5 &&
                parcelList?.map((ele, index) => {
                  if (ele.cad_property_id !== key) {
                    return (
                      <TabPane
                        tab={ele.cad_property_id}
                        key={ele.cad_property_id}
                      ></TabPane>
                    );
                  }
                })}
              {/* //parcelList if more than 5 parcels */}
              {viewParcelData && key !== "summary" && key !== "new_parcel" && (
                <TabPane tab={viewParcelData.cad_property_id} key={key}>
                  <ParcelsMain
                    SendEmailComp={SendEmailComp}
                    fecthingAuditHistoryLogs={fecthingAuditHistoryLogs}
                    certficateDownload={downloadCertificate}
                    parcelList={parcelList}
                    refresh={key}
                    setOpen={setOpen}
                    setNewParcelTabFlag={setNewParcelTabFlag}
                    setKey={setKey}
                    info={viewParcelData}
                    //refetch api for parcel list
                    parcelStatusList={parcelStatusList}
                    validateConflicts={validateConflicts}
                    validateConflictsResult={validateConflictsResult}
                  />
                </TabPane>
              )}
            </Tabs>
          )}
        {window.location.pathname === "/order" && <Order />}
      </div>
    </parcelContext.Provider>
  );
};

export default OrderEditor;
