import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import { AiOutlineEye } from "react-icons/ai";
import { EditOutlined } from "@ant-design/icons";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import {Input, Button, Tooltip } from "antd";
import { FaPlus} from "react-icons/fa";
import SearchColumns from "../../../Components/SearchColumns";
import { useEffect } from "react";
import {
  useJuriFilterTableListCountyBaseMutation,
  useJuriFilterTableListMutation,
  useJuriFilterTableListTaxBaseMutation,
  useJurisdictionListQuery,
} from "../../../Redux/Services/Jurisdiction";
import { JurisdictionModal } from "./JurisdictionModal";
import { useTaxCollectorTableFilterListMutation } from "../../../Redux/Services/TaxCollector";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import AppFilterSelect from "../../../Components/AppFilterSelect/AppFilterSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
export const Jurisdiction = ({ refresh }) => {
  const [showHide, setShowHide] = useState(false);
  const [initialStateVal, setInitialStateVal] = useState(false);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [search, setSearch] = useState("");
  const [countyOptions, setCountyOptions] = useState([]);
  const [ setTaxCollectorOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [filetrVal, setFiletrVal] = useState({
    state: "",
    county: "",
    "tax Collector": "",
  });
  const [jurisdictionValues] = useState({
    tax_collector_id: "",
    state_id: "",
    selDisplay: "",
    selReadOnly: "",
    county_id: "",
    display_name: "",
    jurisdictions_type_id: "",
    cad_office_code: "",
    tax_office_code: "",
    cad_code: "",
    payee: "",
    is_third_party: false,
    is_jurisdiction_multiple_cities: false,
    bill_year: "",
    hms: "",
    verified_date: "",
    over65: "",
    disabled_person: "",
    exemption_other: "",
    first_responder: "",
    solar: "",
    is_early_pay_discount: false,
    alias_names: [],
    jurisdiction_discount: [],
    jurisdiction_taxrates: [],
    jurisdiction_county: [],
  });

  //show county
  const [showCounty, setShowCounty] = useState(false);
  //redux data store
  const { token } = useSelector((state) => state.auth);
  const { jurisdictionModule } = useSelector((state) => state.flagRoles);

  //set state for ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");

  //calling rtk query hook for geting jurisdiction list
  const Jurisdiction = useJurisdictionListQuery(token);

  //edit vendor module
  //edit vendor module
  const editHandler = (e) => {
    setShowHide(true);
    // //sending id to edit vendor modal
    setEditId(e.id);
  };

  //addVendor Handler function with dependency
  const AddVendorHandler = () => {
    setShowHide(true);
    setEditId(null);
  };
  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  function closeModal(data) {
    setShowHide(data);
  }
  //create flag for initail state
  const afterClose = () => {
    setInitialStateVal(!initialStateVal);
    // setPreDefualtStateValues(preDefualtStateValues)
  };

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };
  const columns = [
    {
      title: (
        <span>
          State{" "}
          {getsortfield === "state_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "state_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "state_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "state_name",
      key: "state_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
      ...SearchColumns("state_name", "State Name"),
    },
    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      align: "center",
      dataIndex: "county_name",
      key: "county_name",
      width: 200,
      sorter: (a, b) => a.county_name.localeCompare(b.county_name),
      ...SearchColumns("county_name", "County Name"),
      render: (value, record) => {
        return (
          <>
            {value.split(",")[0]}{" "}
            {record.county_list.length > 0 && (
              <Tooltip
                placement="right"
                title="Others County List"
                color={"#2db7f5"}
              >
                <AiOutlineEye
                  color="green"
                  onClick={() => {
                    setShowCounty(record.county_list);
                  }}
                  cursor={"pointer"}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Tax Collector{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 200,
      ...SearchColumns("name", "Tax Collector Name"),
    },
    {
      title: (
        <span>
          Jurisdiction{" "}
          {getsortfield === "display_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "display_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "display_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "display_name",
      key: "display_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      ...SearchColumns("display_name", "Jurisdiction"),
    },
    {
      title: (
        <span>
          Jurisdiction Code{" "}
          {getsortfield === "cad_office_code" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "cad_office_code" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "cad_office_code" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "cad_office_code",
      key: "cad_office_code",
      align: "center",
      width: 200,
      sorter: (a, b) => a.cad_office_code.localeCompare(b.cad_office_code),
      ...SearchColumns("cad_office_code", " Jurisdiction Code"),
    },
    {
      title: (
        <span>
          Tax Rate Year{" "}
          {getsortfield === "tax_year" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "tax_year" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "tax_year" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "tax_year",
      className: `${jurisdictionModule[1].action ? "" : "border-endstyle"}`,
      key: "tax_year",
      align: "center",
      width: 200,
      sorter: (a, b) => a.tax_year.localeCompare(b.tax_year),
      ...SearchColumns("tax_year", "Tax Rate Year"),
    },

    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      className: `${jurisdictionModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
      render: (value, row) => {
        const disabled =
          Number(filetrVal.county) !== row.county_id &&
          filetrVal.state &&
          filetrVal.county &&
          open;
        return (
          <>
            <EditOutlined
              size={50}
              style={{
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.3 : 1,
              }}
              onClick={() => {
                if (filetrVal.county) {
                  if (Number(filetrVal.county) === row.county_id) {
                    editHandler(row);
                  }
                } else if (!filetrVal.county && filetrVal.state) {
                  editHandler(row);
                } else if (!filetrVal.county && !filetrVal.state) {
                  editHandler(row);
                }
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newData = Jurisdiction.data?.data?.map(
      ({
        cad_office_code,
        tax_year,
        county_list,
        county_name,
        name,
        ...ele
      }) => {
        return {
          ...ele,
          cad_office_code: cad_office_code || "NA",
          tax_year: tax_year || "NA",
          name: name || "NA",
          county_list,
          county_name:
            county_name +
            "," +
            county_list?.map((ele) => {
              return ele;
            }),
        };
      }
    );
    setData(newData);
  }, [Jurisdiction]);

  const [taxCollectorTableFilterList, taxCollectorTableFilterListResult] =
    useTaxCollectorTableFilterListMutation();
  //baee on state id
  const [juriFilterTableList, juriFilterTableListResult] =
    useJuriFilterTableListMutation();

  //baee on county  id
  const [juriFilterTableListCountyBase, juriFilterTableListCountyBaseResult] =
    useJuriFilterTableListCountyBaseMutation();

  //baee on tax id
  const [juriFilterTableListTaxBase, juriFilterTableListTaxBaseResult] =
    useJuriFilterTableListTaxBaseMutation();

  useEffect(() => {
    if (juriFilterTableListResult.isSuccess) {
      const newData = juriFilterTableListResult.data?.data?.map(
        ({ cad_office_code, tax_year, county_list, county_name, ...ele }) => {
          return {
            ...ele,
            cad_office_code: cad_office_code || "NA",
            tax_year: tax_year || "NA",
            county_list,
            county_name:
              county_name +
              "," +
              county_list?.map((ele) => {
                return ele;
              }),
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [juriFilterTableListResult.isLoading]);

  useEffect(() => {
    if (juriFilterTableListCountyBaseResult.isSuccess) {
      const newData = juriFilterTableListCountyBaseResult.data?.data?.map(
        ({ cad_office_code, tax_year, county_list, county_name, ...ele }) => {
          return {
            ...ele,
            cad_office_code: cad_office_code || "NA",
            tax_year: tax_year || "NA",
            county_list,
            county_name:
              county_name +
              "," +
              county_list?.map((ele) => {
                return ele;
              }),
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [juriFilterTableListCountyBaseResult.isLoading]);

  useEffect(() => {
    if (juriFilterTableListTaxBaseResult.isSuccess) {
      const newData = juriFilterTableListTaxBaseResult.data?.data?.map(
        ({
          cad_office_code,
          tax_year,
          county_list,
          county_name,
          name,
          ...ele
        }) => {
          return {
            ...ele,
            cad_office_code: cad_office_code || "NA",
            tax_year: tax_year || "NA",
            name: name || "NA",
            county_list,
            county_name:
              county_name +
              "," +
              county_list?.map((ele) => {
                return ele;
              }),
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [juriFilterTableListTaxBaseResult.isLoading]);

  //state list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    } else {
      setCountyOptions([]);
    }
  }, [countyList2[1].isLoading]);

  useEffect(() => {
    if (taxCollectorTableFilterListResult.isSuccess) {
      const newData = taxCollectorTableFilterListResult.data?.data?.map(
        ({ name, id }) => {
          return {
            name: name || "NA",
            id,
          };
        }
      );
      setTaxCollectorOption(newData);
    } else {
      setTaxCollectorOption([]);
    }
  }, [taxCollectorTableFilterListResult.isLoading]);

  //options for showing filters
  const options = [
    {
      name: "state",
      value: filetrVal.state,
      data: stateList?.map(({ state_name, id }) => {
        return { name: state_name, id };
      }),
    },
    {
      name: "county",
      value: filetrVal.county,
      data: countyOptions?.map(({ county_name, id }) => {
        return { name: county_name, id };
      }),
    },
    // {
    //   name: "tax Collector",
    //   value: filetrVal["tax Collector"],
    //   data: taxCollectorOption,
    // },
  ];

  //function
  function clearState() {
    setCountyOptions([]);
    setTaxCollectorOption([]);
    //reset
    if (filetrVal.state) {
      Jurisdiction.refetch();
    }

    setFiletrVal({
      ...filetrVal,
      state: "",
      county: "",
      "tax Collector": "",
    });
  }
  //function
  function clearCounty() {
    //reset
    if (filetrVal.state && filetrVal.county) {
      taxCollectorTableFilterList({
        data: { state_id: filetrVal.state },
        token,
      });
      //base on sstate id table data
      juriFilterTableList({ id: filetrVal.state, token });
      setTaxCollectorOption([]);
    }
    setFiletrVal({
      ...filetrVal,
      county: "",
      "tax Collector": "",
    });
  }
  //function
  function clearTax() {
    //reset
    if (filetrVal.state && filetrVal.county && filetrVal["tax Collector"]) {
      //base on county id table data
      juriFilterTableListCountyBase({
        id: filetrVal.county,
        token,
      });
    } else if (
      filetrVal.state &&
      !filetrVal.county &&
      filetrVal["tax Collector"]
    ) {
      juriFilterTableList({ id: filetrVal.state, token });
    }
    setFiletrVal({
      ...filetrVal,
      "tax Collector": "",
    });
  }
  function clearFilter() {
    setOpen(false);
    clearState();
  }

  useEffect(() => {
    //request
    if (refresh === "jurisdiction") {
      Jurisdiction.refetch();
    }
    clearFilter();
  }, [refresh]);

  return (
    <div className="seprate">
      {Jurisdiction.isLoading ? (
        <AppSpinner />
      ) : (
        <>
          {/* //showing county  */}
          <AppModal
            width={400}
            onClose={setShowCounty}
            showModal={showCounty}
            element={
              <div className="p-3">
                <div style={{ maxHeight: 300, overflow: "auto" }}>
                  <table className="table border">
                    <thead>
                      <tr>
                        <th scope="col">Sr No</th>
                        <th scope="col">County Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showCounty &&
                        showCounty?.map((ele, index) => {
                          return (
                            <tr key={ele}>
                              <th scope="row">{index + 1}</th>
                              <td>{ele}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          />
          <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
            <AppModal
              confirmProp
              showModal={showHide}
              onClose={closeModal}
              width={"90%"}
              height={500}
              afterClose={afterClose}
              // passing props for default values set
              element={
                <JurisdictionModal
                  AddVendorHandler={AddVendorHandler}
                  editId={editId}
                  setOpen={clearFilter}
                  afterClose={initialStateVal}
                  setShowHide={setShowHide}
                  initialValues={jurisdictionValues}
                />
              }
            />
            <>
              <div className=" me-1 w-25 ">
                <Input
                  placeholder="Search here"
                  className="global-search "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<FaSearch color="lightgray" />}
                />
              </div>
              <div className="filter-button ">
                <Button
                  className="filter-btn"
                  onClick={() => {
                    setOpen(!open);
                    setFiletrVal({
                      state: "",
                      county: "",
                      "tax Collector": "",
                    });
                    if (open) {
                      Jurisdiction.refetch();
                    }
                  }}
                  type={open ? "primary" : "default"}
                >
                  <FiFilter color={open ? "#fff" : ""} />{" "}
                  <span className="ms-1">Filter</span>
                </Button>
              </div>
              {jurisdictionModule.map((item,index) => {
							if (item.add_button) {
                
								return (
                  <div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
                }
                return null;
						})}
            </>
          </div>
          <div style={{ clear: "both" }}></div>
          {open && (
            <div className="my-2 p-2 bg-white border shadow-sm d-flex align-items-center ">
              <div className="row w-100">
                {options?.map(({ value, name, data }, index) => {
                  return (
                    <div className="col-12 col-lg-3">
                      <AppFilterSelect
                        name={name}
                        value={value}
                        options={data}
                        onClear={() => {
                          if (name === "state") {
                            clearState();
                          } else if (name === "county") {
                            clearCounty();
                          } else if (name === "tax Collector") {
                            clearTax();
                          }
                        }}
                        onChange={(e) => {
                          setFiletrVal({
                            ...filetrVal,
                            [name]: e.target.value,
                          });

                          if (name === "state" && e.target.value) {
                            //fetching county list
                            getCountyList({
                              state_id: { state_id: e.target.value },
                              token,
                            });
                            // base on state show tax select list
                            taxCollectorTableFilterList({
                              data: { state_id: e.target.value },
                              token,
                            });
                            //base on sstate id table data
                            juriFilterTableList({ id: e.target.value, token });
                          }
                          if (name === "state" && !e.target.value) {
                            clearState();
                          }

                          if (name === "county" && e.target.value) {
                            taxCollectorTableFilterList({
                              data: { county_id: e.target.value },
                              token,
                            });

                            //base on county id table data
                            juriFilterTableListCountyBase({
                              id: e.target.value,
                              token,
                            });
                          }

                          if (name === "county" && !e.target.value) {
                            clearCounty();
                          }
                          if (name === "tax Collector" && e.target.value) {
                            //base on county id table data
                            juriFilterTableListTaxBase({
                              id: e.target.value,
                              token,
                            });
                          }
                          if (name === "tax Collector" && !e.target.value) {
                            clearTax();
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="d-flex justify-centent-end ms-md-2">
                <Button
                  className="reset-btn"
                  onClick={() => {
                    clearState();
                  }}
                  type="default"
                >
                  <FiRotateCw /> <span className="ms-1"> Reset</span>
                </Button>
              </div>
            </div>
          )}
          <AppTable
            sort={sort}
            loading={
              juriFilterTableListCountyBaseResult.isLoading ||
              juriFilterTableListTaxBaseResult.isLoading ||
              juriFilterTableListResult.isLoading ||
              Jurisdiction.isFetching
            }
            search={search}
            dataSource={data}
            columns={columns}
          />
        </>
      )}
    </div>
  );
};
