import { Badge } from "antd";
import React, {  useState } from "react";
import {
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AppTable from "../../../../Components/AppTable/AppTable";
import SearchColumns from "../../../../Components/SearchColumns";

export default function HoaDashboard({
  data = [],
  parcelHoaStatusOptions = [],
  loading = false,
  search = "",
}) {
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  //navigate order
  const navigate = useNavigate();

  let columns = [
    {
      title: (
        <span>
          File #{" "}
          {getsortfield === "file_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "file_number",
      key: "file_number",
      sorter: (a, b) => a.file_number.localeCompare(b.file_number),
      width: 200,
      align: "start",
      ...SearchColumns("file_number", "File #"),
      render: (value, record) => {
        return (
          <>
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("/order-editor", {
                  state: {
                    id: record.id,
                    orderData: record,
                    orderStatus: record?.status_id,
                    orderNumber: record?.file_number,
                    orderEditableFlag: true,
                    orderEvent: "view",
                  },
                });
              }}
            >
              {record?.parcel_count !== 0 && (
                <Badge
                  title={`${record?.parcel_count} Parcels`}
                  size="small"
                  offset={[15, 0]}
                  overflowCount={10}
                  count={record?.parcel_count}
                >
                  <span className="text-start">{value}</span>
                </Badge>
              )}
              {record?.parcel_count === 0 && <span>{value}</span>}
            </span>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Customer{" "}
          {getsortfield === "customer_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      width: 150,
      align: "center",
      ...SearchColumns("customer_name", "Customer"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "county_name",
      key: "county_name",
      sorter: (a, b) => a?.county_name.localeCompare(b?.county_name),
      width: 150,
      align: "center",
      ...SearchColumns("county_name", "County Name"),
    },
    {
      title: (
        <span>
          Address{" "}
          {getsortfield === "situs_address" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "situs_address" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "situs_address" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "situs_address",
      key: "situs_address",
      sorter: (a, b) => a.situs_address.localeCompare(b.situs_address),

      width: 200,
      align: "center",
      ...SearchColumns("situs_address", "Address"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                  <p className="d-flex justify-content-center align-items-center ">
                    {value}
                  </p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Legal{" "}
          {getsortfield === "legal_description" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "legal_description" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "legal_description" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "legal_description",
      key: "legal_description",
      sorter: (a, b) => a.legal_description.localeCompare(b.legal_description),
      width: 100,
      align: "center",
      ...SearchColumns("legal_description", "Legal"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          HOA{" "}
          {getsortfield === "hoa_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "hoa_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "hoa_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "hoa_name",
      key: "hoa_name",
      sorter: (a, b) => a.hoa_name.localeCompare(b.hoa_name),
      width: 100,
      align: "center",
      ...SearchColumns("hoa_name", "HOA"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Verify Date{" "}
          {getsortfield === "hoa_date_verified" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "hoa_date_verified" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "hoa_date_verified" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "hoa_date_verified",
      key: "hoa_date_verified",
      sorter: (a, b) => a.hoa_date_verified.localeCompare(b.hoa_date_verified),
      width: 120,
      align: "center",
      ...SearchColumns("hoa_date_verified", "Verify Date"),
    },
    {
      title: (
        <span>
          Collector{" "}
          {getsortfield === "hoa_collector_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "hoa_collector_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "hoa_collector_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "hoa_collector_name",
      key: "hoa_collector_name",
      sorter: (a, b) =>
        a.hoa_collector_name.localeCompare(b.hoa_collector_name),
      width: 120,
      align: "center",
      ...SearchColumns("hoa_collector_name", "Collector"),
    },
    {
      title: (
        <span>
          Status{" "}
          {getsortfield === "parcel_hoa_status_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "parcel_hoa_status_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "parcel_hoa_status_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "parcel_hoa_status_name",
      key: "parcel_hoa_status_name",
      sorter: (a, b) =>
        a.parcel_hoa_status_name.localeCompare(b.parcel_hoa_status_name),
      width: 150,
      align: "center",

      render: (value, record) => {
        return (
          <>
            <button
              className="text-white rounded-pill fw-bold"
              style={{
                background: record?.colour_code,
                border: `1px solid ${record?.colour_code}`,
                width: 100,
              }}
            >
              {value}
            </button>
          </>
        );
      },
      filters: parcelHoaStatusOptions?.map(({ name }) => {
        return { text: name, value: name };
      }),
      onFilter: (value, record) =>
        record?.parcel_hoa_status_name.startsWith(value),
      filterMode: "tree",
    },
    {
      title: (
        <span>
          Created{" "}
          {getsortfield === "created" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "created_at" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "created_at" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      width: 120,
      align: "center",
      ...SearchColumns("created_at", "Created Date"),
    },
  ];

  return (
    <div>
      <AppTable
        loading={loading}
        sort={sort}
        search={search}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
}
