import { createSlice } from "@reduxjs/toolkit";

const hoaCollectorList = createSlice({
  name: "hoaCollectorList",
  initialState: {
    hoaCollectorList: ["kn"],
  },
  reducers: {
    hoaCollectorListAction: (state, action) => {
      state.hoaCollectorList = action.payload;
    },
  },
});

export const { hoaCollectorListAction } = hoaCollectorList.actions;

export default hoaCollectorList.reducer;
