import AppTable from "../../../../Components/AppTable/AppTable";
import AppModal from "../../../../Components/AppModal/AppModal";
import SearchColumns from "../../../../Components/SearchColumns";
import {
	FaPlus,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import { CustomerTypeModal } from "./CustomerTypeModal";
import { useEffect, useState } from "react";
import { Form, Input, notification, Typography } from "antd";
import {
	useUpdateCustomerTypeMutation,
	useCustomerTypeListQuery,
} from "../../../../Redux/Services/Customers";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
export const CustomerType = ({ refresh, childTab }) => {
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState();
	const [editingKey, setEditingKey] = useState("");
	const isEditing = (record) => record.id === editingKey;
	const [form] = Form.useForm();
	const [search, setSearch] = useState("");
	const [editId, setEditId] = useState(null);
	const [initialState, setInitialState] = useState(false);

	function afterClose() {
		setInitialState(!initialState);
	}
	//use redux stor for auth
	const { token } = useSelector((state) => state.auth);
	const {  typeModule } = useSelector(
		(state) => state.flagRoles
	);
	const customerTypeListFetch = useCustomerTypeListQuery(token);
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	//create function for customer fields
	function matchColumn(value) {
		
	}

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//calling rtk query for update to customertype
	const updateCustomerTypeData = useUpdateCustomerTypeMutation();
	const [updateCustomerType] = updateCustomerTypeData;

	const columns = [
		{
			dataIndex: "id",
			key: "id",
			sorter: true,
			render: (value) => <span data-idfe={value}>{""}</span>,
			width: 1,
		},
		{
			title: (
				<span>
					Customer Type Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
			editable: true,
			width: 1150,
			className: `${typeModule[1].action ? "": "border-endstyle"}`,
			...SearchColumns("name", "Customer Type"),
			// align:"start"
		},

		{
      title: <span>Actions</span>,
      className: `${typeModule[1].action ? "" : "d-none"}`,
      width: matchColumn(" ") && 50,
			dataIndex: "operation",
			key: "operation",
			align: "center",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => save(record.id)}
							style={{
								marginRight: 8,
							}}
						>
							<button className="btn btn-primary  btn-sm edit-buttons">
								{" "}
								Save
							</button>
						</Typography.Link>
						<Typography.Link>
							<button
								onClick={cancel}
								className="btn btn-sm btn-danger edit-buttons"
							>
								Cancel
							</button>
						</Typography.Link>
					</span>
				) : (
					<>
						<span disabled={editingKey !== ""} onClick={() => edit(record)}>
							<EditOutlined
								style={{ cursor: "pointer" }}
								onClick={editHandler}
								size={50}
							/>
						</span>
					</>
				);
			},
		},
	];

	const edit = (record) => {
		form.setFieldsValue({
			name: "",
			id: "",
			...record,
		});

		setEditingKey(record.id);
	};

	const cancel = () => {
		setEditingKey("");
	};

	//here saving to the row
	const save = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...data];
			const index = newData.findIndex((item) => key === item.id);

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				setData(newData);
				setEditingKey("");
				console.log({ ...row, editId });
				//here updating CustomerType
				updateCustomerType({
					data: { name: row.name },
					id: editId,
					token: token,
				});
			} else {
				newData.push(row);
				setData(newData);
				setEditingKey("");
			}
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	};

	//useEfffect for creating for when userTypeList is loaded
	useEffect(() => {
		if (customerTypeListFetch.isSuccess) {
			setData(customerTypeListFetch.data?.data);
		}
	}, [customerTypeListFetch, search]);
	//close modal
	function closeModal(data) {
		setShowHide(data);
	}

	//update branch details
	useEffect(() => {
		if (updateCustomerTypeData[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateCustomerTypeData[1].data?.message,
			});
		}
		if (updateCustomerTypeData[1].error) {
			/// erros details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateCustomerTypeData[1].error.data.message ||
					updateCustomerTypeData[1].error.error ||
					updateCustomerTypeData[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateCustomerTypeData[1].isLoading]);

	//edit customertype module
	const editHandler = (e) => {
		const id = e.target
			.closest("tr")
			.querySelector("td:first-child>span")
			.getAttribute("data-idfe");

		setEditId(id);
	};

	useEffect(() => {
		setSearch("");
		if (childTab === "Customer Type") {
			customerTypeListFetch.refetch();
		}
	}, [childTab]);

	return (
		<div>
			<div className="d-flex align-items-center float-end w-25 mb-1 ">
				<AppModal
					showModal={showHide}
					onClose={closeModal}
					afterClose={afterClose}
					width={500}
					height={500}
					element={
						<CustomerTypeModal
							setShowHide={setShowHide}
							afterClose={initialState}
						/>
					}
				/>

				{/* changing input values globaly  */}
				<div className=" me-1 w-100 mt-2">
					<Input
						placeholder="Search here"
						className="global-search "
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						prefix={<FaSearch color="lightgray" />}
					/>
				</div>
				{typeModule.map((item,index) => {
					if (item.add_button) {
						return (
							<div key={index} className="add_icon">
								<FaPlus onClick={() => setShowHide(true)} />
							</div>
						);
					}
					return null;
				})}
			</div>
			<div style={{ clear: "both" }}></div>

			<AppTable
				sort={sort}
				loading={
					updateCustomerTypeData[1].isLoading || customerTypeListFetch.isLoading
				}
				search={search}
				isEditing={isEditing}
				form={form}
				dataSource={data}
				columns={columns}
			/>
		</div>
	);
};
