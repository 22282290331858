import { Alert, notification } from "antd";
import {  Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppInput from "../../../../Components/AppInput/AppInput";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import AppButton from "../../../../Components/AppButton/AppButton";
import {
  useEditCadQuery,
  useUpdateCadListMutation,
  useAddSingleCadMutation,
} from "../../../../Redux/Services/Cad";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { scrollComp } from "../../../../Controllers/Functions";

export default function ListModal({
  editId,
  initialValues,
  setShowHide,
  afterClose,
  setOpen,
}) {
  //   values assign
  const [values, setValues] = useState(initialValues);
  const onChange = (e) => {
    setGetError(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "tax_year") {
      setValues({ ...values, [name]: value.replace(/[^0-9]/g, "") });
    } else if (name === "situs_zip") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, situs_zip: foo });
    } else if (name === "mail_address_zip") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, mail_address_zip: foo });
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editCadListApi.refetch();
    }
    clearStatus();
  };

  //status
  const [getError, setGetError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  //function for modal close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }

  // calling redux store
  const { token, user } = useSelector((state) => state.auth);

  //calling rtk query hook for adding  single tax cad
  const addNewCadAPI = useAddSingleCadMutation();
  const [addCad] = addNewCadAPI;
  // useEffect(() => {
  // 	if (addCad[1].isSuccess) {
  // 		closeModal();
  // 		setOpen(false);
  // 		///after submit details
  // 		notification.open({
  // 			type: "success",
  // 			style: { color: "green", marginTop: "50px" },
  // 			description: addCad[1].data?.message,
  // 		});
  // 	}
  // 	if (addCad[1].error) {
  // 		//here created for in loop for catch server error
  // 		setGetError(
  // 			addCad[1].error?.data?.error ||
  // 				addCad[1].error?.data?.message ||
  // 				addCad[1].error.error ||
  // 				addCad[1].error.data?.msg.sqlMessage
  // 		);
  // 	}
  // }, [addCad[1].isLoading])

  //update user details
  useEffect(() => {
    if (addNewCadAPI[1].isSuccess) {
      closeModal();
      setOpen(false);
      setShowHide(false);
      clearStatus();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewCadAPI[1].data?.message,
      });
    }
    if (addNewCadAPI[1].error) {
      setGetError(
        addNewCadAPI[1].error.data?.error ||
          addNewCadAPI[1].error?.data?.message ||
          (addNewCadAPI[1].error.error && "server unreachable!") ||
          addNewCadAPI[1].error.data?.message?.sqlMessage
      );
    }
  }, [addNewCadAPI[1].isLoading]);

  //calling rtk query hook for update
  const updateCadListApi = useUpdateCadListMutation();
  const [updateCadList] = updateCadListApi;
  //calling rtk query hook for geting status
  const editCadListApi = useEditCadQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //useEffect for setting default values
  useEffect(() => {
    setValues(initialValues);
  }, [afterClose]);

  //useEffet for  add labels

  // //useEffect for editCustomer
  // useEffect(() => {
  //   if (editCadListApi.isSuccess) {
  //     //filter values from user branchList and customer list for initials values
  //     setValues(editCadListApi.data?.data[0]);
  //     clearStatus();
  //   }
  //   if (editCadListApi.isError) {
  //     var errorData = [];
  //     for (const prop in editCadListApi[1].error.data?.error) {
  //       errorData.push(editCadListApi[1].error.data?.error[prop]);
  //     }
  //     setGetError(
  //       errorData[0] ||
  //       editCadListApi.error.data.message ||
  //       editCadListApi.error.data ||
  //       editCadListApi.error.error ||
  //       editCadListApi.error.data?.msg.sqlMessage
  //     );
  //   }
  // }, [editCadListApi]);

  //useEffect for editaxcad
  useEffect(() => {
    if (editCadListApi.isSuccess) {
      setValues(editCadListApi.data?.data[0]);
    }
    if (editCadListApi.isError) {
      setGetError(
        editCadListApi.error.data?.message.sqlMessage ||
          editCadListApi.error?.data?.message ||
          editCadListApi.error.data?.error ||
          (editCadListApi.error.error && "server unreachable!")
      );
    }
  }, [editCadListApi.isFetching]);

  //update user details
  useEffect(() => {
    if (updateCadListApi[1].isSuccess) {
      closeModal();
      setOpen(false);
      setShowHide(false);
      clearStatus();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateCadListApi[1].data?.message,
      });
    }
    if (updateCadListApi[1].error) {
      setGetError(
        updateCadListApi[1].error.data?.error ||
          updateCadListApi[1].error.data?.message?.sqlMessage ||
          (updateCadListApi[1].error.error && "server unreachable!") ||
          updateCadListApi[1].error?.data?.message
      );
    }
  }, [updateCadListApi[1].isLoading]);

  //adding new color status with name and color
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editId) {
      updateCadList({
        data: {
          ...values,
          updated_by: user?.user_id,
        },
        id: editId,
        token: token,
      });
    }
    if (!editId) {
      addCad({
        data: {
          ...values,
          updated_by: user?.user_id,
        },
        // id: editId,
        token: token,
      });
    }
  };

  //useEffect for editcad refecth
  useEffect(() => {
    if (editId) {
      editCadListApi.refetch();
    }
    clearStatus();
  }, [afterClose]);

  //destructure some props
  const inputs = [
    // {
    // 	id: 1,
    // 	name: "name",
    // 	value: values.name || "",
    // 	label: "Name",
    // 	type: "text",
    // 	placeholder: "Enter Name",
    // 	errorMessage: "Please enter name!",
    // 	column: "3",
    // },
    {
      id: 2,
      name: "state",
      column: "3",
      value: values.state || "",
      placeholder: "Enter State",
      errorMessage: "Please Enter State Name!",
      label: "State",
      type: "text",
      disabled: editId && "disabled",
      required: true,
    },
    {
      id: 3,
      name: "county",
      column: "3",
      value: values.county || "",
      placeholder: "Enter County",
      errorMessage: "Please Enter county Name!",
      disabled: editId && "disabled",
      label: "County",
      type: "text",
      required: true,
    },
    {
      id: 4,
      name: "tax_year",
      column: "3",
      value: values.tax_year || "",
      placeholder: "Enter Tax Year",
      errorMessage: "Please enter valid taxt year!",
      label: "Tax Year",
      maxLength: 4,
      minLength: 4,
      type: "text",
      required: true,
    },
    {
      id: 305,
      name: "parcel_type",
      column: "3",
      value: values.parcel_type || "",
      placeholder: "Enter Parcel Type",
      errorMessage: "Please Enter Parcel Type!",
      label: "Parcel Type",
      type: "text",
    },
    {
      id: 7,
      name: "cad_property_id",
      column: "3",
      value: values.cad_property_id || "",
      placeholder: "Enter Cad Property Id",
      errorMessage: "Please Enter cad property id!",
      label: "Cad Property Id",
      type: "text",
      required: true,
    },
    {
      id: 8,
      name: "cad_geo_id",
      column: "3",
      value: values.cad_geo_id || "",
      placeholder: "Enter Cad Geo Id",
      errorMessage: "Please Enter cad geo id!",
      label: "Cad Geo Id",
      type: "text",
    },
    {
      id: 306,
      name: "tax_assessor_id",
      column: "3",
      value: values.tax_assessor_id || "",
      placeholder: "Enter Tax Assessor Id",
      errorMessage: "Please Enter Tax Assessor Id!",
      label: "Tax Assessor Id",
      type: "text",
    },
    {
      id: 307,
      name: "cross_tax_id",
      column: "3",
      value: values.cross_tax_id || "",
      placeholder: "Enter Cross Tax Id",
      errorMessage: "Please Enter Tax Id!",
      label: "Cross Tax Id",
      type: "text",
    },
    {
      id: 207,
      name: "subdivision",
      column: "3",
      value: values.subdivision || "",
      placeholder: "Enter Subdivision",
      errorMessage: "Please Enter subdivision!",
      label: "Subdivision",
      type: "text",
    },
    {
      id: 9,
      name: "situs_address",
      column: "3",
      value: values.situs_address || "",
      placeholder: "Enter Situs Address",
      errorMessage: "Please Enter situs address!",
      label: "Situs Address",
      type: "textarea",
    },
    {
      id: 10,
      name: "situs_number",
      column: "3",
      value: values.situs_number || "",
      placeholder: "Enter Situs Number",
      errorMessage: "Please Enter situs number!",
      label: "Situs Number",
      type: "text",
    },
    {
      id: 11,
      name: "situs_pre_dir",
      column: "3",
      value: values.situs_pre_dir || "",
      placeholder: "Enter Situs Pre Dir",
      errorMessage: "Please Enter situs pre dir!",
      label: "Situs Pre Dir",
      type: "text",
    },
    {
      id: 12,
      name: "situs_name",
      column: "3",
      value: values.situs_name || "",
      placeholder: "Enter Situs Name",
      errorMessage: "Please Enter situs name!",
      label: "Situs Name",
      type: "text",
    },
    {
      id: 13,
      name: "situs_suffix",
      column: "3",
      value: values.situs_suffix || "",
      placeholder: "Enter Situs Suffix",
      errorMessage: "Please Enter situs suffix!",
      label: "Situs Suffix",
      type: "text",
    },
    {
      id: 14,
      name: "situs_post_dir",
      column: "3",
      value: values.situs_post_dir || "",
      placeholder: "Enter Situs Post Dir",
      errorMessage: "Please Enter situs post dir!",
      label: "Situs Post",
      type: "text",
    },
    {
      id: 15,
      name: "situs_unit",
      column: "3",
      value: values.situs_unit || "",
      placeholder: "Enter Situs Unit",
      errorMessage: "Please Enter situs unit!",
      label: "Situs Unit",
      type: "text",
    },
    {
      id: 16,
      name: "situs_city",
      column: "3",
      value: values.situs_city || "",
      placeholder: "Enter Situs City",
      errorMessage: "Please Enter situs city!",
      label: "Situs City",
      type: "text",
    },
    {
      id: 17,
      name: "situs_state",
      column: "3",
      value: values.situs_state || "",
      placeholder: "Enter Situs State",
      errorMessage: "Please Enter situs state!",
      label: "Situs State",
      type: "text",
    },
    {
      id: 18,
      name: "situs_zip",
      column: "3",
      value: values.situs_zip || "",
      placeholder: "Enter Situs Zip",
      errorMessage: "Please Enter situs zip!",
      label: "Situs Zip",
      type: "text",
    },
    {
      id: 38,
      name: "owner_name",
      column: "3",
      value: values.owner_name || "",
      placeholder: "Enter Owner Name",
      errorMessage: "Please Enter Owner Name!",
      label: "Owner Name",
      type: "text",
    },
    {
      id: 39,
      name: "owner_dba_name",
      column: "3",
      value: values.owner_dba_name || "",
      placeholder: "Enter Owner DBA Name",
      errorMessage: "Please Enter Owner DBA Name!",
      label: "Owner DBA Name",
      type: "text",
    },
    {
      id: 395,
      name: "exemptions",
      column: "3",
      value: values.exemptions || "",
      placeholder: "Enter Exemptions",
      errorMessage: "Please Enter exemptions!",
      label: "Exemptions",
      type: "text",
    },
    // {
    // 	id: 391,
    // 	name: "address_one",
    // 	column: "3",
    // 	value: values.address_one || "",
    // 	placeholder: "Enter address one",
    // 	errorMessage: "Please Enter address one!",
    // 	label: "Address One",
    // 	type: "text",
    // },
    // {
    // 	id: 392,
    // 	name: "address_two",
    // 	column: "3",
    // 	value: values.address_two || "",
    // 	placeholder: "Enter address two",
    // 	errorMessage: "Please Enter address two!",
    // 	label: "Address Two",
    // 	type: "text",
    // },
    // {
    // 	id: 393,
    // 	name: "address_three",
    // 	column: "3",
    // 	value: values.address_three || "",
    // 	placeholder: "Enter address three",
    // 	errorMessage: "Please Enter address three!",
    // 	label: "Address Three",
    // 	type: "text",
    // },
    {
      id: 384,
      name: "ownership",
      column: "3",
      value: values.ownership || "",
      placeholder: "Enter Ownership",
      errorMessage: "Please Enter ownership!",
      label: "Ownership",
      type: "text",
    },
    {
      id: 40,
      name: "mail_address_one",
      column: "3",
      value: values.mail_address_one || "",
      placeholder: "Enter Mail Address One",
      errorMessage: "Please Enter mail address one!",
      label: "Mail Address One",
      type: "text",
    },
    {
      id: 41,
      name: "mail_address_two",
      column: "3",
      value: values.mail_address_two || "",
      placeholder: "Enter Mail Address Two",
      errorMessage: "Please Enter mail address two!",
      label: "Mail Address Two",
      type: "text",
    },
    {
      id: 41,
      name: "mail_address_city",
      column: "3",
      value: values.mail_address_city || "",
      placeholder: "Enter Mail Address City",
      errorMessage: "Please Enter mail address city!",
      label: "Mail Address City",
      type: "text",
    },
    {
      id: 42,
      name: "mail_address_state",
      column: "3",
      value: values.mail_address_state || "",
      placeholder: "Enter Mail Address State",
      errorMessage: "Please Enter mail address state!",
      label: "Mail Address State",
      type: "text",
    },
    {
      id: 43,
      name: "mail_address_zip",
      column: "3",
      value: values.mail_address_zip || "",
      placeholder: "Enter Mail Address Zip",
      errorMessage: "Please Enter mail address zip!",
      label: "Mail Address Zip",
      type: "text",
    },

    {
      id: 19,
      name: "legal",
      column: "3",
      value: values.legal || "",
      placeholder: "Enter Legal",
      errorMessage: "Please Enter legal!",
      label: "Legal",
      type: "text",
    },
    {
      id: 28,
      name: "acreage",
      column: "3",
      value: values.acreage || "",
      placeholder: "Enter Acreage",
      errorMessage: "Please Enter acreage!",
      label: "Acreage",
      type: "number",
    },
    {
      id: 29,
      name: "deed_reference",
      column: "3",
      value: values.deed_reference || "",
      placeholder: "Enter Deed Reference",
      errorMessage: "Please Enter deed reference!",
      label: "Deed Reference",
      type: "text",
    },
    {
      id: 20,
      name: "taxing_unit_list",
      column: "3",
      value: values.taxing_unit_list || "",
      placeholder: "Enter Taxing Unit List",
      errorMessage: "Please Enter taxing unit list!",
      label: "Taxing Unit List",
      type: "text",
    },
    {
      id: 23,
      name: "land",
      column: "3",
      value: values.land || "",
      placeholder: "Enter Land",
      errorMessage: "Please Enter land!",
      label: "Land",
      type: "text",
    },

    {
      id: 24,
      name: "improvement",
      column: "3",
      value: values.improvement || "",
      placeholder: "Enter Improvement",
      errorMessage: "Please Enter improvement!",
      label: "Improvement",
      type: "text",
    },
    {
      id: 21,
      name: "appraised",
      column: "3",
      value: values.appraised || "",
      placeholder: "Enter Appraised",
      errorMessage: "Please Enter appraised!",
      label: "Appraised",
      type: "text",
    },
    {
      id: 22,
      name: "assessed",
      column: "3",
      value: values.assessed || "",
      placeholder: "Enter Assessed",
      errorMessage: "Please Enter assessed!",
      label: "Assessed",
      type: "text",
    },
    {
      id: 30,
      name: "cap_loss",
      column: "3",
      value: values.cap_loss || "",
      placeholder: "Enter Cap Loss",
      errorMessage: "Please Enter Cap Loss!",
      label: "Cap Loss",
      type: "text",
    },
    {
      id: 25,
      name: "agricultural",
      column: "3",
      value: values.agricultural || "",
      placeholder: "Enter Agricultural",
      errorMessage: "Please Enter agricultural!",
      label: "Agricultural",
      type: "text",
    },

    {
      id: 26,
      name: "last_ag_value",
      column: "3",
      value: values.last_ag_value || "",
      placeholder: "Enter Last AG Value",
      errorMessage: "Please Enter last ag value!",
      label: "Last AG Value",
      type: "text",
    },
    {
      id: 44,
      name: "ag_cleared",
      column: "3",
      value: values.ag_cleared || "",
      placeholder: "Enter AG Cleared",
      errorMessage: "Please Enter ag_cleared!",
      label: "AG Cleared",
      type: "text",
    },
    {
      id: 45,
      name: "parcel_comments",
      column: "3",
      value: values.parcel_comments || "",
      placeholder: "Enter Parcel Comments",
      errorMessage: "Please Enter Parcel Comments!",
      label: "Parcel Comments",
      type: "text",
    },
    {
      id: 46,
      name: "tax_suit_indicator",
      column: "3",
      value: values.tax_suit_indicator || "",
      placeholder: "Enter Tax Suit Indicator",
      errorMessage: "Please Enter Tax Suit Indicator!",
      label: "Tax Suit Indicator",
      type: "text",
    },
    {
      id: 47,
      name: "tax_suite_fee_comments",
      column: "3",
      value: values.tax_suite_fee_comments || "",
      placeholder: "Enter Tax Suite Fee Comments",
      errorMessage: "Please Enter Tax Suite Fee Comments!",
      label: "Tax Suite Fee Comments",
      type: "text",
    },

    {
      id: 48,
      name: "feature_review",
      column: "3",
      value: values.feature_review || "",
      placeholder: "Enter Feature Review",
      errorMessage: "Please Enter Feature Review!",
      label: "Feature Review",
      type: "text",
    },
    {
      id: 49,
      name: "linked_parcels",
      column: "3",
      value: values.linked_parcels || "",
      placeholder: "Enter Linked Parcels",
      errorMessage: "Please Enter Linked Parcels!",
      label: "Linked Parcels",
      type: "text",
    },

    // {
    //   id: 5,
    //   name: "batch_id",
    //   column: "3",
    //   value: values.batch_id || "",
    //   placeholder: "Enter Tax year",
    //   errorMessage: "Please Enter Taxt year!",
    //   label: "Batch Id",
    //   type: "text",
    // },
    {
      id: 6,
      name: "run_date",
      column: "3",
      value: values.run_date || "",
      placeholder: "Enter Run Date",
      errorMessage: "Please Enter Run Date!",
      label: "Run Date",
      type: "date",
    },
    {
      id: 50,
      name: "parcel_is_same_record",
      column: "3",
      margin: "5",
      checked: values.parcel_is_same_record || "",
      errorMessage: "Please select plarcel same record!",
      type: "switch",
      label: "Parcel_is_same_record",
    },
  ];

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    clearStatus();
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("listmodal");
    }
  }, [getError]);
  return (
    <div className="position-relative mytab">
      <div className=" border-bottom border-2 " id="listmodal">
        <h5>{editId ? "Edit CAD" : "Add CAD"}</h5>
      </div>
      <div className="mt-3">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>

      <form onSubmit={handleSubmit} action="">
        {editCadListApi.isFetching && <AppSpinner />}
        <div className={`${editCadListApi.isFetching ? "invisible" : ""} `}>
          <Row>
            {inputs.map((input) => (
              <>
                {(input.type === "text" ||
                  input.type === "email" ||
                  input.type === "date") &&
                  input.type !== "number" &&
                  !input.tab && (
                    <AppInput
                      key={input.id}
                      {...input}
                      defaultSet={afterClose}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  )}

                {input.type === "switch" && (
                  <AppSwitch
                    key={input.id}
                    onChange={appSwitchHanler}
                    {...input}
                  />
                )}
              </>
            ))}
          </Row>

          <div className="d-flex justify-content-center pb-3 pt-2">
            <div className="me-md-2 ">
              <AppButton
                onClick={handleReset}
                title={"CLEAR"}
                variant={"secondary-md"}
              />
            </div>

            <AppButton title={"SUBMIT"} variant={"primary-md"} />
          </div>
        </div>
      </form>
    </div>
  );
}
