import React from 'react';
import { Pie } from '@ant-design/plots';

const NormalPie = (props) => {

    const{
        appendPadding,
        data=[],
        angleField,
        colorField=[],
        radius,
        legend,
        content,
    }=props;

  const config = {
    appendPadding: appendPadding,
    data:data,
    angleField:  angleField,
    colorField: colorField,
    radius:  radius,
    color: ['#1E90FF','#73c2fb','#dc3545','#ffc107','#fd7e14', "#FF597B", "#439A97","#3B3486"],
    legend: legend,
    width: 300,
    height: 300,
    label: {
        type: "inner",
        offset: "-30%",
        content: content,
        style: {
          textAlign: "center",
          fontSize: 12,
        },
      },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default NormalPie;