import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const annotationtype = createApi({
    reducerPath: "annotationtype",
    tagTypes: ["annotationtype"],
    baseQuery: fetchBaseQuery({ baseUrl: config.url }),
    endpoints: (builder) => ({
        annotationTypeList: builder.query({
            query: (token) => ({
                url: `/annotation-type-list`,
                method: "GET",
                mode: "cors",
                headers: { Authorization: `Bearer ${token}` },
            }),
            providesTags: ["annotationtype"],
        }),
        addAnnotationType: builder.mutation({
            query: (data) => ({
                url: `/add-annotation-type`,
                mode: "cors",
                method: "POST",
                body: data.data,
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["annotationtype"],
        }),
        editAnnotationType: builder.query({
            query: (data) => ({
                url: `/edit-annotation-type/${data.id}`,
                method: "GET",
                mode: "cors",
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["annotationtype"],
        }),
        updateAnnotationType: builder.mutation({
            query: (data) => ({
                url: `/update-annotation-type/${data.id}`,
                method: "POST",
                mode: "cors",
                body: data.data,
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["annotationtype"],
        }),
    }),
});

export const {
    useAnnotationTypeListQuery,
    useAddAnnotationTypeMutation,
    useEditAnnotationTypeQuery,
    useUpdateAnnotationTypeMutation,
} = annotationtype;
