import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const notice = createApi({
  reducerPath: "notice",
  baseQuery: fetchBaseQuery({ baseUrl:config.url }),
  endpoints: (builder) => ({
    addnotice: builder.mutation({
      query: (data) => ({
        url: `/add-notice-to-purchaser`,
        method: "POST",
        mode: "cors",
        body:data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    noticeList: builder.query({
    query: (token) => ({
      url: `/notice-to-purchaser-list`,
      method: "GET",
      mode: "cors",
      headers: { Authorization: `Bearer ${token}` },
    }),
  }),

    
  }),
});

export const {
  useAddnoticeMutation,
  useNoticeListQuery
} = notice;
