import { createSlice } from "@reduxjs/toolkit";

 const inputHandler = createSlice({
    name: "inputValue",
    initialState: {
        inputValue:""
    },
    reducers: {
      inputChange: (state, action) => {
        state.inputValue= action.payload
      },
    },
  });
  
  export const { inputChange } = inputHandler.actions;
  
  export default inputHandler.reducer;