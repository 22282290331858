import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux/es/exports";
import { Col, Row } from "react-bootstrap";
import { Alert, notification, Popconfirm } from "antd";
import { useState, useEffect } from "react";
import AppSelect from "../../../../Components/AppSelect/AppSelect";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import {
  useAddUserMutation,
  useEditUserQuery,
  useUpdateUserMutation,
} from "../../../../Redux/Services/User";
import { useCustomerOptionsMutation } from "../../../../Redux/Services/Customers";
import { useBranchOptionsMutation } from "../../../../Redux/Services/Branch";
import AppCheckbox from "../../../../Components/AppCheckbox/AppCheckbox";
import { AppFile } from "../../../../Components/AppFile/AppFile";
import {
  asendingList,
  removeBlank,
  scrollComp,
} from "../../../../Controllers/Functions";
import "./userModal.css";

import Input from "react-phone-number-input/input";
const randomPass = require("secure-random-password");

export const UserModal = ({
  editId,
  initialValues,
  afterClose,
  setShowHide,
}) => {
  const [vendorData, setVendorData] = useState([]);
  const [customertypeList, setCustomerTypeList] = useState([]);
  const [usertypeListData, setUserTypeListData] = useState([]);
  const [editInitialVal, setEditInitialVal] = useState({
    branch: null,
    customer: null,
  });

  const [getError, setGetError] = useState(false);

  const [branchListData, setBranchListData] = useState([]);

  //   values assign
  const [values, setValues] = useState(initialValues);

  //calling redux stor for vendors list
  const { vendorsList } = useSelector((state) => state.vendorsList);

  //calling redux stor for vendors list
  const { token, user } = useSelector((state) => state.auth);
  const { useTypeListFlag, tableColumn } = useSelector(
    (state) => state.flagRoles
  );

  //calling redux stor for vendors list
  const { userTypeList } = useSelector((state) => state.userTypeList);



  const [filename, setFilename] = useState("");
  //calling rtk query hook for geting customerType list
  const editUserFetch = useEditUserQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for adding customer
  const addNewUser = useAddUserMutation();
  const [addUser] = addNewUser;

  //calling rtk query hook for update
  const updateUserDetails = useUpdateUserMutation();
  const [updateUser] = updateUserDetails;

  //calling rtk query hook for customer-list options
  const customerListOptions = useCustomerOptionsMutation();
  const [customerOptions] = customerListOptions;

  //calling rtk query hook for branch-list options
  const branchListOptions = useBranchOptionsMutation();
  const [branchOptions] = branchListOptions;

  //clear error function
  function clearStatus() {
    setGetError(null);
  }
  //function for modal close
  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }


  //filtering to userType List base on users role
  const userFIlter = [];
  useTypeListFlag.forEach((element) => {
    const filterUsers = userTypeList.filter((ele) => {
      return ele.name.match(element);
    });
    filterUsers.map((ele) => {
      userFIlter.push(ele);
    });
  });
console.log("userFIlter",userFIlter)
  //useEffect for setting default values
  useEffect(() => {
    setVendorData(asendingList(vendorsList, "vendor_name"));
    setUserTypeListData(userFIlter);
    setValues(initialValues);
    setBranchListData([]);
    setCustomerTypeList([]);
    //when we editing
    if (user.user_type_id !== 1 && user.user_type_id !== 4 && !editId) {
      //customer options
      customerOptions({ id: user.vendor_id, token: token });
    }
  }, [afterClose]);

  //update user details
  useEffect(() => {
    if (updateUserDetails[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateUserDetails[1].data?.message,
      });
    }
    if (updateUserDetails[1].error) {
      setGetError(
        updateUserDetails[1].error?.data?.error ||
          updateUserDetails[1].error?.data?.message ||
          (updateUserDetails[1].error.error && "server unreachable!") ||
          updateUserDetails[1].err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
  }, [updateUserDetails[1].isLoading]);

  //useEffet for  add user
  useEffect(() => {
    if (addNewUser[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewUser[1].data.message,
      });
    }
    if (addNewUser[1].error) {
      //here created for in loop for catch server errors

      setGetError(
        addNewUser[1].error?.data?.error ||
          addNewUser[1].error?.data?.message ||
          (addNewUser[1].error.error && "server unreachable!") ||
          addNewUser[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewUser[1].isLoading]);

  //useEffect for editCustomer
  useEffect(() => {
    if (editUserFetch.isSuccess) {
      //make a request for get customer list
      const { branch_list, customer_list } = editUserFetch.data;
      //filter values from user branchList and customer list for initials values
      const { profile_pic, branch_id, vendor_id, customer_id, ...othersData } =
        editUserFetch.data?.data[0];
      var newArr = {
        ...othersData,
        profile_pic: atob(profile_pic),
        customer_id: customer_id,
        branch_id: branch_id,
        vendor_id: vendor_id,
      };
      setEditInitialVal({
        ...editInitialVal,
        branch: branch_id,
        customer: customer_id,
      });
      setValues(newArr);
      //state county
      setBranchListData(branch_list);
      setCustomerTypeList(customer_list);
    }
    if (editUserFetch.isError) {
      setGetError(
        editUserFetch.error?.data?.message ||
          editUserFetch.error.data ||
          (editUserFetch.error.error && "server unreachable!") ||
          editUserFetch.error.data?.msg.sqlMessage
      );
    }
  }, [editUserFetch.isFetching]);

  

  //useEffect for customerList options
  useEffect(() => {
    if (customerListOptions[1].isSuccess) {
      setCustomerTypeList(customerListOptions[1].data?.data);
    }
    if (customerListOptions[1].isError) {
      setBranchListData([]);
      setCustomerTypeList([]);
      //when customer not avail
      setValues({
        ...values,
        customer_id: "",
        branch_id: "",
      });
    }
  }, [customerListOptions]);

  //useEffect for branchList options
  useEffect(() => {
    if (branchListOptions[1].isSuccess) {
      setBranchListData(branchListOptions[1].data?.data);
    }
    if (branchListOptions[1].isError && customerListOptions[1].isSuccess) {
      setBranchListData([]);
    }
    if (branchListOptions[1].isError) {
      setValues({
        ...values,
        branch_id: "",
      });
    }
  }, [branchListOptions[1].isLoading]);

  const inputs = [
    {
      id: 1,
      name: "vendor_id",
      type: "select",
      value: values.vendor_id || "",
      placeholder: "Select Vendor",
      errorMessage: "Please select vendor!",
      label: "Vendor",
      options: vendorData,
      required: true,
      column: "3",
    },
    {
      id: 2,
      name: "customer_id",
      type: "select",
      value: values.customer_id || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer!",
      label: "Customer",
      options: customertypeList,
      column: "3",
      required:
        values.user_type_id === 4 ||
        values.user_type_id === 5 ||
        values.user_type_id === 10,
    },
    {
      id: 3,
      name: "branch_id",
      type: "select",
      value: values.branch_id || "",
      placeholder: "Select Vendor Name",
      errorMessage: "Please select branch!",
      label: "Branch",

      options: branchListData,
      column: "3",
      required:
        values.user_type_id === 6 ||
        values.user_type_id === 7 ||
        values.user_type_id === 11,
    },
    {
      id: 4,
      name: "first_name",
      type: "text",
      value: values?.first_name || "",
      placeholder: "Enter First Name ",
      errorMessage: "Please enter first name!",
      label: "First Name",
      required: true,
      column: "3",
    },
    {
      id: 5,
      name: "middle_name",
      type: "text",
      value: values?.middle_name || "",
      placeholder: "Enter Middle Name ",
      errorMessage: "Please enter middle name!",
      label: "Middle Name",
      column: "3",
    },

    {
      id: 6,
      name: "last_name",
      type: "text",
      value: values?.last_name || "",
      placeholder: "Enter Last Name",
      errorMessage: "Please enter last name!",
      label: "Last Name",
      column: "3",
    },
    {
      id: 7,
      name: "email",
      type: "email",
      value: values?.email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      //when we edit to user details
      // disabled: editId,
      required: true,
    },

    {
      id: 9,
      name: "office_number",
      type: "number",
      column: "3",
      value: values?.office_number || "",
      placeholder: "Enter Office Number",
      maxLength: "10",
      minLength: "10",
      label: "Office Number",
    },
    {
      id: 1002,
      name: "phone_number",
      value: values?.phone_number || "",
      type: "number",
      column: "3",
      placeholder: "Enter Office Number",
      maxLength: "10",
      minLength: "10",
      label: "Mobile Number",
    },
    {
      id: 10028452,
      name: "position",
      value: values?.position || "",
      type: "text",
      column: "3",
      placeholder: "Enter Position",
      errorMessage: "Please enter position",
      label: "Position",
    },

    {
      id: 20,
      name: "user_type_id",
      type: "select",
      value: values.user_type_id || "",
      column: "3",
      label: "User Type",
      required: true,
      options: asendingList(usertypeListData, "name"),
      errorMessage: "Please select user type!",
    },
    {
      id: 24,
      type: "file",
      preview: values.profile_pic,
      filename: filename,
      errorMessage: "Please add profile_pic!",
      label: "Avatar",
    },

    {
      id: 10024545,
      name: "password",
      value: values?.password || "",
      type: "password",
      column: `3 `,
      placeholder: "Enter Password",
      errorMessage: "Please enter password!",
      label: "Password",
      required: true,
    },
    {
      id: 12,
      name: "generate_password_check",
      type: "checkbox",
      checked: values.generate_password_check || "",
      label: "Generate Password",
      column: `3 `,
      margin: "3",
    },
    {
      id: 21,
      name: "is_active",
      checked: values.is_active || "",
      type: "switch",
      column: `2  `,
      label: "Active",
      required: true,
      margin: "3",
    },
  ];
  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    clearStatus();
  };

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();

    setValues({
      ...values,
      [property]: Number(value),
    });

    if (property === "vendor_id") {
      if (Number(value) !== 0) {
        customerOptions({ id: Number(value), token: token });
        setValues({
          ...values,
          [property]: Number(value),
        });
      } else {
        setValues({
          ...values,
          [property]: null,
          branch_id: "",
          customer_id: null,
        });
        setBranchListData([]);
        setCustomerTypeList([]);
      }
    }
    if (property === "customer_id") {
      if (Number(value) !== 0) {
        branchOptions({ id: Number(value), token: token });

        setValues({
          ...values,
          [property]: Number(value),
        });
      } else {
        setValues({
          ...values,
          [property]: null,
        });
        setBranchListData([]);
      }
    }

    if (property === "branch_id") {
      setValues({
        ...values,
        [property]: Number(value),
        product_list_values: [],
      });
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editUserFetch.refetch();
    }
    clearStatus();
  };

  const submitFunc = async (e) => {
    e.preventDefault();
    if (values.phone_number?.length !== 12 && values.phone_number?.length > 0) {
      setGetError("Please enter valid phone number");
    } else {
      removeBlank(values);
      //nested products loop
      if (!editId)
      {
        console.log("data", values.vendor_id);
        addUser({
          data: {
            ...values,
            office_number: values.office_number,
            created_by: user?.user_id,
            vendor_id: !values.vendor_id ? user?.vendor_id : values.vendor_id,
            branch_id:!values.branch_id ?user?.branch_id:values.branch_id
          },
          token: token,
        });
      }

      if (editId) {
        updateUser({
          data: {
            ...values,
            office_number: values.office_number,
            updated_by: user?.user_id,
            vendor_id: !values.vendor_id ? user?.vendor_id : values.vendor_id,
            branch_id:!values.branch_id ?user?.branch_id:values.branch_id
          },
          id: editId,
          token: token,
        });
      }
    }
  };
  const confirm = (e) => {
    submitFunc(e);
  };
  const handleSubmit = (e) => {
    submitFunc(e);
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    //clear Status
    clearStatus();
  };

  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
    if (property === "generate_password_check") {
      if (value) {
        setValues({
          ...values,
          password: randomPass.randomPassword(),
          [property]: value,
        });
      } else {
        setValues({
          ...values,
          [property]: value,
          password: "",
        });
      }
    }
  };
  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editUserFetch.refetch();
    }
    clearStatus();
    setFilename("");
  }, [afterClose, editId]);

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("userError");
    }
  }, [getError]);

  return (
    <div className="position-relative">
      <div className=" border-bottom border-2 " id="userError">
        <h5>{editId ? "Edit User" : "Add User"}</h5>
      </div>
      {getError && (
        <div className="mt-3">
          <Alert message={getError} type="error" showIcon />{" "}
        </div>
      )}

      <form onSubmit={handleSubmit} className={"user-modal user"}>
        {(updateUserDetails[1].isLoading ||
          addNewUser[1].isLoading ||
          customerListOptions[1].isLoading ||
          branchListOptions[1].isLoading ||
          editUserFetch.isFetching) && <AppSpinner />}

        <Row className={`${editUserFetch.isFetching ? "invisible" : ""} `}>
          {inputs.map((input) => (
            <>
              {
                //auto genrate password
                input.type === "checkbox" && (
                  <AppCheckbox {...input} handleChange={appCheckboxHandler} />
                )
              }
              {/* //input file  */}
              {input.type === "file" && (
                <AppFile
                  fileVal={(e) =>
                    setValues({
                      ...values,
                      profile_pic: e.logo,
                      profile_pic_name: e.name,
                    })
                  }
                  size={2000000}
                  label={"Avatar"}
                  afterClose={afterClose}
                  initailVal={
                    editId
                      ? {
                          fileName: values?.profile_pic_name,
                          imgPreview: values?.profile_pic,
                        }
                      : ""
                  }
                  column={3}
                />
              )}
              {(input.type === "text" || input.type === "email") &&
                input.name !== "phone_number" && (
                  <FormInput
                    key={input.id}
                    {...input}
                    defaultSet={afterClose}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                )}
              {input.type === "password" &&
                input.name !== "phone_number" &&
                (!editId || (editId && values.generate_password_check)) && (
                  <FormInput
                    key={input.id}
                    {...input}
                    defaultSet={afterClose}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                )}
              {input.type === "number" && (
                <div className="col-12 col-md-3">
                  <Row className="mt-3">
                    <label className="ms-1 heading-5 text-dark ">
                      {input.label}
                    </label>
                  </Row>
                  <Input
                    className="input-field mt-0 pb-2 w-100 heading-5"
                    maxLength={14}
                    minLength={14}
                    placeholder={input.placeholder}
                    defaultCountry="US"
                    value={values[input.name]}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onChange={(value) => {
                      setValues({ ...values, [input.name]: value });
                      setGetError(false);
                    }}
                  />
                </div>
              )}

              {/* //appSelcet for vendors name  */}
              {(input.type === "select" && input.name === "vendor_id")   && tableColumn.map(item=> item.vendor ? (
                <>
                  {
                   
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select Vendor</option>
                            {input.options?.map((ele) => {
                              return (
                                <option
                                  key={ele.vendor_id}
                                  value={ele.vendor_id}
                                >
                                  {ele.vendor_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                  }
                </>
            ):'')}
              {/* //appSelcet for customer type name  */}
              {(input.type === "select" &&
                input.name === "customer_id" ) &&
                //base on user field flags
               tableColumn.map(item=> item.customer ? (
                  <AppSelect
                    key={input.id}
                    {...input}
                    onChange={selectHandler}
                    defaultSet={afterClose}
                    options={
                      <>
                        <option value={""}> Select Customer</option>
                        {input?.options?.map((ele) => {
                          return (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          );
                        })}
                      </>
                    }
                  />
                ):'')}
              {/* //appSelcet for customer type name  */}
              {(input.type === "select" &&
                input.name === "branch_id") &&
                //base on user field flags
                tableColumn.map(item=>item.branch ?(
                  <AppSelect
                    key={input.id}
                    {...input}
                    onChange={selectHandler}
                    defaultSet={afterClose}
                    options={
                      <>
                        <option value={""}>Select Branch</option>
                        {input?.options?.map((ele) => {
                          return (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          );
                        })}
                      </>
                    }
                  />
                ):'')}
              {/* //appSelcet for user_type  */}
              {input.type === "select" && input.name === "user_type_id" && (
                <AppSelect
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  defaultSet={afterClose}
                  options={
                    <>
                      <option value={""}>Select User Type</option>
                      {input?.options?.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}

              {input.type === "switch" ? (
                <AppSwitch
                  key={input.id}
                  onChange={appSwitchHanler}
                  {...input}
                />
              ) : (
                ""
              )}
            </>
          ))}
        </Row>

        <Row className={`${editUserFetch.isFetching ? "invisible" : ""} `}>
          <Col className="my-3 mt-5">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <MyButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              {editId && values.generate_password_check ? (
                <Popconfirm
                  getPopupContainer={(trigger) => trigger.parentElement}
                  title="Are you sure you want to update the password?"
                  onConfirm={confirm}
                  okText="Yes"
                  cancelText="No"
                >
                  <MyButton title={"SUBMIT"} variant={"primary-md"} />
                </Popconfirm>
              ) : (
                <MyButton title={"SUBMIT"} variant={"primary-md"} />
              )}
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
