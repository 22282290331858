import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppTable from "../../../../Components/AppTable/AppTable";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import SearchColumns from "../../../../Components/SearchColumns";
import { Input, Switch, Form, notification } from "antd";

import {
	useSupportBranchListQuery,
	useUpdateSupportBranchMutation,
	useUpdateSupportBranchStatusMutation,
} from "../../../../Redux/Services/supportBranch";

export default function Branch({ refresh, childTab }) {
	const [data, setData] = useState();
	const [editingKey] = useState("");
	const isEditing = (record) => record.id === editingKey;
	const [form] = Form.useForm();
	const [search, setSearch] = useState("");

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//use redux store for auth
	const { token } = useSelector((state) => state.auth);
	const { authColumn } = useSelector((state) => state.flagRoles);

	//calling rtk query hook for geting customerType list
	const supportBranchList = useSupportBranchListQuery(token);

	//calling rtk query for update labels
	const updatesupportbranchData = useUpdateSupportBranchMutation();
	// const [updateSupportBranch] = updatesupportbranchData;

	//calling rtk query hook for geting Support Branch Status
	const updateSupportBranchStatusApi = useUpdateSupportBranchStatusMutation();
	const [updateSupportBranchStatus] = updateSupportBranchStatusApi;

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//create function for customer fields
	function matchColumn(value) {
		if (authColumn.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}

	const columns = [
		{
			title: (
				<span>
					Vendor Name{" "}
					{getsortfield === "vendor_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "vendor_name",
			key: "vendor_name",
			align: "center",
			sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
			width: 200,
			...SearchColumns("vendor_name", "Vendor Name"),
		},
		{
			title: (
				<span>
					Customer Name{" "}
					{getsortfield === "customer_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "customer_name",
			key: "customer_name",
			align: "center",
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			width: 200,
			...SearchColumns("customer_name", "Customer Name"),
		},
		{
			title: (
				<span>
					Branch Name{" "}
					{getsortfield === "branch_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "branch_name" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "branch_name" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "branch_name",
			key: "branch_name",
			align: "center",
			sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
			responsive: ["sm"],
			width: 200,
			...SearchColumns("branch_name", "Branch Name"),
		},
		{
			title: (
				<span>
					State Name{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			width: 200,
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: (
				<span>
					County Name{" "}
					{getsortfield === "county_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "county_name",
			key: "county_name",
			align: "center",
			sorter: (a, b) => a.county_name.localeCompare(b.county_name),
			width: 200,
			...SearchColumns("county_name", "County Name"),
		},
		{
			title: <span>Active Status</span>,
			className: `${matchColumn("is_active") ? "" : "d-none"}`,
			dataIndex: "is_active",
			key: "is_active",
			width: matchColumn("is_active") && 100,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
		// {
		//   title: "Action",
		//   dataIndex: "operation",
		//   key: "operation",
		//   className: `${matchColumn("edit") ? "" : "d-none"}`,
		//   width: matchColumn("edit") && 130,
		//   align: "center",
		//   render: (_, record) => {
		//     const editable = isEditing(record);
		//     return editable ? (
		//       <span>
		//         <Typography.Link
		//           onClick={() => save(record.id)}
		//           style={{
		//             marginRight: 8,
		//           }}
		//         >
		//           <button className="btn btn-primary btn-sm edit-buttons">
		//             Save
		//           </button>
		//         </Typography.Link>
		//         <Typography.Link>
		//           <button
		//             onClick={cancel}
		//             className="btn btn-sm btn-danger edit-buttons"
		//           >
		//             Cancel
		//           </button>
		//         </Typography.Link>
		//       </span>
		//     ) : (
		//       <>
		//         <span disabled={editingKey !== ""} onClick={() => edit(record)}>
		//           <EditOutlined
		//             style={{ cursor: "pointer" }}
		//             onClick={editHandler}
		//             size={50}
		//           />
		//         </span>
		//       </>
		//     );
		//   },
		// },
	];

	//   const edit = (record) => {
	//     form.setFieldsValue({
	//       vendor_id: "",
	//       customer_id: "",
	//       state_id: "",
	//       county_id: "",
	//       is_active: false,

	//       ...record,
	//     });

	//     setEditingKey(record.id);
	//   };

	//   const cancel = () => {
	//     setEditingKey("");
	//   };

	//here saving to the row
	//   const save = async (key) => {
	//     try {
	//       const row = await form.validateFields();
	//       const newData = [...data];
	//       const index = newData.findIndex((item) => key === item.id);

	//       if (index > -1) {
	//         const item = newData[index];
	//         newData.splice(index, 1, { ...item, ...row });
	//         setData(newData);
	//         setEditingKey("");
	//         //here updating branch
	//         updateSupportBranch({
	//           data: row,
	//           id: editId,
	//           token: token,
	//         });
	//         setEditingKey("");
	//       } else {
	//         newData.push(row);
	//         setData(newData);
	//         setEditingKey("");
	//       }
	//     } catch (errInfo) {
	//       console.log("Validate Failed:", errInfo);
	//     }
	//   };

	//edit vendor module
	//   const editHandler = (e) => {
	//     const id = e.target
	//       .closest("tr")
	//       .querySelector("td:first-child>span")
	//       .getAttribute("data-idfe");
	//     setShowHide(true);
	//     // //sending id to edit branch modal
	//     setEditId(id);
	//   };

	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value,
		};
		updateSupportBranchStatus({
			data: data,
			id: row.id,
			token: token,
		});
	}

	//useEfffect for creating for when support branch list is loaded
	useEffect(() => {
		if (supportBranchList.isSuccess) {
			setData(supportBranchList.data?.data);
		}
	}, [supportBranchList, search]);

	//update support branch list details
	//   useEffect(() => {
	//     if (updatesupportbranchData[1].isSuccess) {
	//       ///after update details
	//       notification.open({
	//         type: "success",
	//         style: { color: "green", marginTop: "50px" },
	//         description: updatesupportbranchData[1].data?.message,
	//       });
	//     }
	//     if (updatesupportbranchData[1].error) {
	//       /// erros details
	//       notification.open({
	//         type: "error",
	//         style: { color: "red", marginTop: "50px" },
	//         description:
	//           updatesupportbranchData[1].error.data.message ||
	//           updatesupportbranchData[1].error.error ||
	//           updatesupportbranchData[1].error.data?.msg.sqlMessage,
	//       });
	//     }
	//   }, [updatesupportbranchData[1].isLoading]);

	//update support branch status
	useEffect(() => {
		if (updateSupportBranchStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateSupportBranchStatusApi[1].data?.message,
			});
		}
		if (updateSupportBranchStatusApi[1].error) {
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateSupportBranchStatusApi[1].error.data.message ||
					updateSupportBranchStatusApi[1].error.error ||
					updateSupportBranchStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateSupportBranchStatusApi[1].isLoading]);

	useEffect(() => {
		setSearch("");
		if (childTab === "Branch") {
			supportBranchList.refetch();
		}
	}, [childTab]);

	return (
		<>
			{supportBranchList.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
					</div>
					<div style={{ clear: "both" }}></div>
					<AppTable
						sort={sort}
						loading={updateSupportBranchStatusApi[1].isLoading}
						search={search}
						isEditing={isEditing}
						form={form}
						dataSource={data}
						columns={columns}
					/>
				</>
			)}
		</>
	);
}
