import { createSlice } from "@reduxjs/toolkit";
const noticeFlagList=createSlice({
    name:"noticeFlagList",
    initialState:{
        noticeFlagList:[],
    },
    reducers:{
    noticeFlagAction:(state,action)=>{
        state.noticeFlagList=action.payload;
    },
    },
});

export const {noticeFlagAction} = noticeFlagList.actions;

export default noticeFlagList.reducer;



