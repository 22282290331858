import { Alert, notification, Tabs } from "antd";
import { Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppCheckbox from "../../../Components/AppCheckbox/AppCheckbox";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import AppButton from "../../../Components/AppButton/AppButton";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import {
  useAddHoaMgmtMutation,
  useHoaDisplayOnCertListQuery,
  useHoaMgmtEditQuery,
  useHoaReadOnlyListQuery,
  useUpdateHoaMgmtMutation,
} from "../../../Redux/Services/HaoMgmt";
import {
  asendingList,
  removeBlank,
  scrollComp,
} from "../../../Controllers/Functions";
import MultiBranches from "../../../Components/MultiBranches/MultiBranches";
import MultiManagement from "../../../Components/MultiManagement/MultiManagement";

import Input from "react-phone-number-input/input";
import { useThirdPartyListQuery } from "../../../Redux/Services/ThirdParty";
import MultiCheckList from "../../../Components/MultiCheckList/MultiCheckList";
import { useActiveHoaListMutation } from "../../../Redux/Services/Hoa";
import AppTextEditor from "../../../Components/AppTextEditor/AppTextEditor";
const { TabPane } = Tabs;
export default function HoaMgmtModal({
  editId,
  initialValues,
  setShowHide,
  setOpen,
  afterClose,
}) {
  const [getError, setGetError] = useState(false);

  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  const [thirdPartyData, setThirdPartyData] = useState([{ id: "", name: "" }]);
  const [activeHoaListData, setActiveHoaListData] = useState([]);
  const [read_only_comment, setRead_only_comment] = useState("");
  const [display_oncert_comment, setDisplay_oncert_comment] = useState("");
  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);

  const thirdPartyResult = useThirdPartyListQuery(token);
  //changing tab of address
  const [addressTabKey, setAddressTabKey] = useState("1");

  //   values assign
  const [values, setValues] = useState(initialValues);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
    //also hoa is checked set hao collector data in localstorage
    if (values?.save_hoa_local) {
      localStorage.setItem("hoa_mgmt", JSON.stringify(values));
    }
  }

  //calling rtk query hook for geting edit Hoa data
  const editHomMgmtApi = useHoaMgmtEditQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for geting  hoa list
  const [activeHoaList, activeHoaListResult] = useActiveHoaListMutation();

  //calling rtk query hook for geting edit Jurisdiction data
  const updateHoaMgmtApi = useUpdateHoaMgmtMutation();
  const [updateHoaMgmt] = updateHoaMgmtApi;

  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for adding hoa mgmt
  const addHoaMgmtApi = useAddHoaMgmtMutation();
  const [addHoaMgmt] = addHoaMgmtApi;

  const DisplayOnCertList = useHoaDisplayOnCertListQuery(token);
  const ReadOnlyList = useHoaReadOnlyListQuery(token);

  function refecthApi() {
    thirdPartyResult.refetch();
    DisplayOnCertList.refetch();
    ReadOnlyList.refetch();
  }
  //useEffect for setting default values
  useEffect(() => {
    setStateData(stateList);
    setValues(initialValues);

    if (!editId) {
      setAddressTabKey("1");
      activeHoaList(token);
    } else {
      setActiveHoaListData([]);
    }
    setRead_only_comment("");
    setDisplay_oncert_comment("");
    refecthApi();
    // if edit id is exist
  }, [afterClose]);
  //useEffect for updateing Hoa

  //useEffect for setting default values
  useEffect(() => {
    if (activeHoaListResult.isSuccess && !editId) {
      setActiveHoaListData(activeHoaListResult.data.data);
    } else {
      setActiveHoaListData([]);
    }
    // if edit id is exist
  }, [activeHoaListResult.isLoading]);
  //useEffect for updateing Hoa

  //useEffect for thrd party
  useEffect(() => {
    if (thirdPartyResult.isSuccess) {
      setThirdPartyData(asendingList(thirdPartyResult.data.data, "name"));
    }
    // if edit id is exist
  }, [thirdPartyResult.isFetching]);

  useEffect(() => {
    if (updateHoaMgmtApi[1].isSuccess) {
      closeModal();
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateHoaMgmtApi[1].data?.message,
      });
      setOpen(false);
    }
    if (updateHoaMgmtApi[1].error) {
      setGetError(
        updateHoaMgmtApi[1].error?.data?.error ||
          updateHoaMgmtApi[1].error?.data?.message ||
          (updateHoaMgmtApi[1].error.error && "server unreachable!") ||
          updateHoaMgmtApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateHoaMgmtApi[1].isLoading]);

  //useEffect for edit TaxCollector
  useEffect(() => {
    if (editHomMgmtApi.isSuccess) {
      const {
        mailing_zip_five,
        mailing_zip_four,
        physical_zip_five,
        physical_zip_four,
        read_only_comment,
        display_oncert_comment,
        county_id,
        ...othersData
      } = editHomMgmtApi.data?.data[0];
      var newArr = {
        ...othersData,
        mailing_zip_five: `${mailing_zip_five}${
          mailing_zip_four ? "-" + mailing_zip_four : ""
        }`,
        physical_zip_five: `${physical_zip_five}${
          physical_zip_four ? "-" + physical_zip_four : ""
        }`,
        county_id: county_id,
        add_hoa_collectors_comments: [],
        add_hoa_collectors_fees: [],
        add_hoa_collectors_branches: [],
        edit_hoa_collectors_comments: [],
        edit_hoa_collectors_fees: [],
        edit_hoa_collectors_branches: [],
        delete_hoa_collectors_comments: [],
        delete_hoa_collectors_fees: [],
        delete_hoa_collectors_branches: [],
      };
      setValues(newArr);
      activeHoaList(token);
      setRead_only_comment(read_only_comment);
      setDisplay_oncert_comment(display_oncert_comment);
    }
    if (editHomMgmtApi.isError) {
      setGetError(
        editHomMgmtApi.error?.data?.error ||
          editHomMgmtApi.error?.data?.message ||
          editHomMgmtApi.error?.data ||
          (editHomMgmtApi.error.error && "server unreachable!") ||
          editHomMgmtApi.error.data?.msg.sqlMessage
      );
    }
  }, [editHomMgmtApi.isFetching]);

  // useEffect for add hoa mgmt
  useEffect(() => {
    if (addHoaMgmtApi[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addHoaMgmtApi[1].data?.message,
      });
    }
    if (addHoaMgmtApi[1].error) {
      setGetError(
        addHoaMgmtApi[1].error?.data?.error ||
          addHoaMgmtApi[1].error?.data?.message ||
          (addHoaMgmtApi[1].error.error && "server unreachable!") ||
          addHoaMgmtApi[1].err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
  }, [addHoaMgmtApi[1].isLoading]);

  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editHomMgmtApi.refetch();
    }
    clearStatus();
  }, [afterClose]);

  const inputs = [
    {
      id: 1,
      name: "state_id",
      type: "select",
      value: values.state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      required: true,
      column: "3",
    },

    {
      id: 3,
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      errorMessage: "Please enter name!",
      label: "Name",
      required: true,
      column: "3",
    },

    {
      id: 5,
      name: "website",
      type: "text",
      value: values?.website || "",
      placeholder: "Enter Website Name",
      errorMessage: "Please enter website!",
      label: "Website",
      column: "3",
    },

    {
      id: 12,
      name: "fax",
      type: "text",
      _id: "hoa_mgmt_fax",
      column: "3",
      placeholder: "Enter Fax Number",
      errorMessage: "Please enter valid fax number!",
      label: "Fax",
      maxLength: "10",
      minLength: "10",
    },

    {
      id: 1540,
      name: "phone_number",
      type: "number",
      column: "3",
      _id: "hoa_mgmt_phone_number",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
    },
    {
      id: 459,
      name: "email",
      _id: "hoa_mgmt_email",
      type: "email",
      value: values.email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
    },
    {
      id: 4541,
      name: "third_patry_document_service_id",
      type: "select",
      value: values.third_patry_document_service_id || "",
      placeholder: "Select State",
      errorMessage: "Please select Third Party!",
      label: "3rd Party Document Services",
      options: thirdPartyData || [{ id: "1", name: "testing" }],
      column: "3",
    },

    {
      id: 514546,
      name: "is_direct_contacts",
      _id: "hoa_mgmt_is_direct_contacts",
      checked: values.is_direct_contacts || "",
      type: "checkbox",
      column: "3",
      label: "Collector Direct Contact",
      margin: "5",
    },
    {
      id: 51445245546,
      name: "is_collector_multiple_cities",
      _id: "hoa_mgmt_is_collector_multiple_cities",
      checked: values.is_collector_multiple_cities || "",
      type: "switch",
      column: "3",
      label: "Collector In Multiple Cities",
      margin: "3",
    },
    {
      id: 4658,
      name: "is_mailing_address",
      checked: values.is_mailing_address || "",
      type: "switch",
      column: "4",
      label: "Display Physical Address On Cert",
      margin: "3",
      tooltip:
        "By Default mailing address will be displayed on the Certificate",
    },

    {
      id: 10,
      name: "mailing_first_name",
      value: values?.mailing_first_name || "",
      type: "text",
      placeholder: "Enter First Name",
      label: "First Name",
      errorMessage: "Please enter first name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 105656,
      name: "mailing_middle_name",
      value: values?.mailing_middle_name || "",
      type: "text",
      placeholder: "Enter Middle Name",
      label: "Middle Name",
      errorMessage: "Please enter middle name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 105656,
      name: "mailing_last_name",
      value: values?.mailing_last_name || "",
      type: "text",
      placeholder: "Enter Last Name",
      label: "Last Name",
      errorMessage: "Please enter last name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 100,
      name: "mailing_contact_position",
      value: values?.mailing_contact_position || "",
      type: "text",
      placeholder: "Enter Contact Position",
      errorMessage: "Please enter contact position",
      label: "Contact Position",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 13,
      name: "mailing_address_one",
      value: values?.mailing_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 14,
      name: "mailing_address_two",
      value: values?.mailing_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 15,
      name: "mailing_city",
      value: values?.mailing_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 16,
      name: "mailing_zip_five",
      value: values?.mailing_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 15651,
      name: "mailing_phone_number",
      type: "number",
      value: values.mailing_phone_number,
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 12,
      name: "mailing_fax",
      type: "text",
      value: values.mailing_fax,
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "mailing",
      tab: true,
    },
    {
      id: 1561,
      name: "mailing_email",
      type: "email",
      placeholder: "Enter Email",
      value: values.mailing_email || "",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 4545452,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Same As Physical Address ",
      margin: "3",
      address_type: "mailing",
      tab: true,
    },
    //physical
    //physical
    {
      id: 454510,
      name: "physical_first_name",
      value: values?.physical_first_name || "",
      errorMessage: "Please enter first name!",
      type: "text",
      placeholder: "Enter First Name",
      label: "First Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 450,
      name: "physical_middle_name",
      value: values?.physical_middle_name || "",
      errorMessage: "Please enter middle name!",
      type: "text",
      placeholder: "Enter Middle Name",
      label: "Middle Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 450545,
      name: "physical_last_name",
      value: values?.physical_last_name || "",
      errorMessage: "Please enter last name!",
      type: "text",
      placeholder: "Enter Last Name",
      label: "Last Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 110,
      name: "physical_contact_position",
      value: values?.physical_contact_position || "",
      type: "text",
      placeholder: "Enter Contact Position",
      errorMessage: "Please enter contact position",
      label: "Contact Position",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 13,
      name: "physical_address_one",
      value: values?.physical_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 14,
      name: "physical_address_two",
      value: values?.physical_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "physical",
      tab: true,
    },
    {
      id: 15,
      name: "physical_city",
      value: values?.physical_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 16,
      name: "physical_zip_five",
      value: values?.physical_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 114545,
      name: "physical_phone_number",
      type: "number",
      value: values.physical_phone_number || "",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 12,
      name: "physical_fax",
      type: "text",
      value: values?.physical_fax || "",
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 1451,
      name: "physical_email",
      type: "email",
      value: values.physical_email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 454454556,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Same As Physical Address ",
      margin: "3",
      address_type: "physical",
      tab: true,
    },
  ];

  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();

    const {
      mailing_fax,
      mailing_contact_position,
      mailing_first_name,
      mailing_last_name,
      mailing_middle_name,
      mailing_phone_number,
      mailing_email,
      mailing_address_one,
      mailing_address_two,
      mailing_state_id,
      mailing_city,
      mailing_zip_five,
    } = values;

    if (value) {
      setValues({
        ...values,
        physical_phone_number: mailing_phone_number,
        physical_email: mailing_email,
        physical_address_one: mailing_address_one,
        physical_address_two: mailing_address_two,
        physical_state_id: mailing_state_id,
        physical_city: mailing_city,
        physical_zip_five: mailing_zip_five,
        physical_first_name: mailing_first_name,
        physical_last_name: mailing_last_name,
        physical_middle_name: mailing_middle_name,
        physical_contact_position: mailing_contact_position,
        physical_fax: mailing_fax,
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    } else {
      setValues({
        ...values,
        physical_phone_number: "",
        physical_email: "",
        physical_address_one: "",
        physical_address_two: "",
        physical_state_id: "",
        physical_city: "",
        physical_zip_five: "",
        physical_zip_four: "",
        physical_first_name: "",
        physical_middle_name: "",
        physical_last_name: "",
        physical_contact_position: "",
        physical_fax: "",
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    }
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "mailing_zip_five") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, mailing_zip_five: foo });
    } else if (name === "physical_zip_five") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, physical_zip_five: foo });
    } else if (
      name === "fax" ||
      name === "physical_fax" ||
      name === "mailing_fax"
    ) {
      setValues({ ...values, [name]: value.replace(/[^0-9]/g, "") });
    }

    //clear Status
    clearStatus();
  };

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: Number(value) });
  };

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    if (property === " is_collector_multiple_cities") {
      setValues({ ...values, [property]: value });
    }
    clearStatus();
  };

  //tab chage
  const TabOnChange = (key) => {
    setAddressTabKey(key);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editHomMgmtApi.refetch();
    }
    clearStatus();
    setRead_only_comment("");
    setDisplay_oncert_comment("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.phone_number?.length !== 12 && values.phone_number?.length > 0) {
      setGetError("Please enter valid phone number");
    } else if (
      values.mailing_phone_number?.length !== 12 &&
      values.mailing_phone_number?.length > 0
    ) {
      setGetError("Please enter valid mailing phone number");
    } else if (
      values.physical_phone_number?.length !== 12 &&
      values.physical_phone_number?.length > 0
    ) {
      setGetError("Please enter valid physical phone number");
    } else {
      removeBlank(values);

      const mailing_zip_five = values.mailing_zip_five?.split("-");
      const physical_zip_five = values.physical_zip_five?.split("-");
      if (!editId) {
        if (!values.is_collector_multiple_cities) {
          values.hoa_collectors_branches = [];
        }

        addHoaMgmt({
          data: {
            ...values,
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            physical_zip_four:
              physical_zip_five && (physical_zip_five[1] || null),
            read_only_comment,
            display_oncert_comment,
          },
          token: token,
        });
      }
      if (editId) {
        updateHoaMgmt({
          data: {
            ...values,
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            read_only_comment,
            display_oncert_comment,
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("hoamgtError");
    }
  }, [getError]);

  return (
    <div className="position-relative mytab">
      <div className=" border-bottom border-2 " id="hoamgtError">
        <h5>{editId ? "Edit HOA MGMT" : "Add HOA MGMT"}</h5>
      </div>
      <div className="mt-3">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      <form onSubmit={handleSubmit} action="">
        {(editHomMgmtApi.isFetching || activeHoaListResult.isLoading) && (
          <AppSpinner />
        )}
        <div
          className={`${
            editHomMgmtApi.isFetching || activeHoaListResult.isLoading
              ? "invisible"
              : ""
          } `}
        >
          <div className="row">
            <div className="col-12 col-lg-9">
              <Row>
                {inputs.map((input) => (
                  <>
                    {input.type === "checkbox" && !input.tab && (
                      <AppCheckbox
                        {...input}
                        handleChange={appCheckboxHandler}
                      />
                    )}
                    {(input.type === "text" ||
                      input.type === "email" ||
                      input.type === "date") &&
                      input.type !== "number" &&
                      !input?.tab &&
                      input?.address_type !== "annotation" && (
                        <AppInput
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    {input.type === "number" && !input?.tab && (
                      <div className="col-12 col-md-3">
                        <Row className="mt-3">
                          <label className="ms-1 heading-5 text-dark ">
                            {input.label}
                          </label>
                        </Row>
                        <Input
                          className="input-field mt-0 pb-2 w-100 heading-5"
                          maxLength={14}
                          minLength={14}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder={input.placeholder}
                          defaultCountry="US"
                          value={values[input.name]}
                          onChange={(value) => {
                            setValues({ ...values, [input.name]: value });
                            setGetError(false);
                          }}
                        />
                      </div>
                    )}
                    {/* //appSelcet for state  */}
                    {input.type === "select" && input.name === "state_id" && (
                      //base on user field flags
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select State</option>
                            {input.options.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {ele.state_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}

                    {/* //appSelcet for county  */}
                    {input.type === "select" &&
                      input.name === "third_patry_document_service_id" && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select Third Party</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {input.type === "switch" &&
                      input?.address_type !== "annotation" && (
                        <AppSwitch
                          key={input.id}
                          onChange={appSwitchHanler}
                          {...input}
                        />
                      )}
                  </>
                ))}
              </Row>
            </div>
            <div className="col-12 col-lg-3">
              {!activeHoaListResult.isLoading && (
                <MultiCheckList
                  placeholder="HOA"
                  checkbox={!editId}
                  heading={"Active HOA"}
                  afterClose={afterClose}
                  getSelectVal={(value) => {
                    setValues({ ...values, active_hoa_list: value });
                  }}
                  plainOptions={
                    editId
                      ? values?.hoa_lists?.filter((ele) => {
                          return values.hoa_mapped_list.includes(ele.id);
                        })
                      : activeHoaListData || []
                  }
                  defaultCheckedList={values.hoa_mapped_list}
                />
              )}
            </div>
          </div>

          {!activeHoaListResult.isLoading && (
            <>
              {values.is_collector_multiple_cities && (
                <div className="my-3">
                  <MultiBranches
                    whileEdit={(value) => {
                      if (editId) {
                        setValues({
                          ...values,
                          add_hoa_collectors_branches: [
                            ...values?.add_hoa_collectors_branches,
                            value,
                          ],
                        });
                      }
                    }}
                    //update
                    updateContact={(value) => {
                      if (editId) {
                        if (
                          values.edit_hoa_collectors_branches?.length > 0 &&
                          values.edit_hoa_collectors_branches?.find(
                            (val) => val["id"] === value.id
                          )
                        ) {
                          values.edit_hoa_collectors_branches.forEach(
                            (item) => {
                              if (item.id === value.id) {
                                item.address_one = value.address_one;
                                item.address_two = value.address_two;
                                item.branch = value.branch;
                                item.city = value.city;
                                item.state_id = value.state_id;
                                item.zip = value.zip;
                              }
                            }
                          );
                        } else {
                          setValues({
                            ...values,
                            edit_hoa_collectors_branches: [
                              ...values?.edit_hoa_collectors_branches,
                              value,
                            ],
                          });
                        }
                      }
                    }}
                    afterClose={afterClose}
                    //delete record
                    deleteContact={(value) => {
                      if (editId) {
                        if (value?.id) {
                          setValues({
                            ...values,
                            delete_hoa_collectors_branches: [
                              ...values?.delete_hoa_collectors_branches,
                              String(value.id),
                            ],
                          });
                        } else {
                          setValues({
                            ...values,
                            add_hoa_collectors_branches:
                              values.add_hoa_collectors_branches?.filter(
                                (e) => {
                                  return e.temp_id !== value.temp_id;
                                }
                              ),
                          });
                        }
                      }
                    }}
                    addFormVal={(value) => {
                      if (!editId) {
                        values.hoa_collectors_branches = value;
                      }
                      setGetError(false);
                    }}
                    initailValArr={
                      editId ? values?.hoa_collectors_branches_list : []
                    }
                  />
                </div>
              )}
              <div className="row my-2">
                <Tabs
                  className="w-100"
                  defaultActiveKey={String(addressTabKey)}
                  onChange={TabOnChange}
                  type="card"
                >
                  {/* //physical address  */}
                  <TabPane tab="Mailing" key="1">
                    <div className="row">
                      {inputs.map((input) => (
                        <>
                          {(input.type === "text" || input.type === "email") &&
                            input.tab === true &&
                            input?.address_type === "mailing" && (
                              <AppInput
                                key={input.id}
                                defaultSet={afterClose}
                                {...input}
                                onChange={onChange}
                              />
                            )}
                          {input.type === "number" &&
                            input.tab === true &&
                            input?.address_type === "mailing" && (
                              <div className="col-12 col-md-3">
                                <Row className="mt-3">
                                  <label className="ms-1 heading-5 text-dark ">
                                    {input.label}
                                  </label>
                                </Row>
                                <Input
                                  className="input-field mt-0 pb-2 w-100 heading-5"
                                  maxLength={14}
                                  minLength={14}
                                  placeholder={input.placeholder}
                                  defaultCountry="US"
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  value={values[input.name]}
                                  onChange={(value) => {
                                    setValues({
                                      ...values,
                                      [input.name]: value,
                                    });
                                    setGetError(false);
                                  }}
                                />
                              </div>
                            )}
                          {/* //appSelcet for state name  */}

                          {input.type === "select" &&
                            input.name === "mailing_state_id" &&
                            input.tab === true &&
                            input?.address_type === "mailing" && (
                              <AppSelect
                                key={input.id}
                                {...input}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select State</option>

                                    {input.options.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.state_name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}
                          {input.type === "checkbox" &&
                            !editId &&
                            input.tab === true &&
                            input?.address_type === "mailing" && (
                              <AppCheckbox
                                {...input}
                                handleChange={appCheckboxHandler}
                              />
                            )}
                          {input.type === "switch" &&
                            input.tab === true &&
                            input?.address_type === "mailing" && (
                              <AppSwitch
                                key={input.id}
                                value={values[input.name]}
                                onChange={appSwitchHanler}
                                {...input}
                              />
                            )}
                        </>
                      ))}
                    </div>
                  </TabPane>
                  {/* //physical address  */}
                  <TabPane tab="Physical" key="2">
                    <div className="row">
                      {inputs.map((input) => (
                        <>
                          {(input.type === "text" || input.type === "email") &&
                            input.tab === true &&
                            input?.address_type === "physical" && (
                              <AppInput
                                key={input.id}
                                defaultSet={afterClose}
                                {...input}
                                // value={values[input.name]}

                                onChange={onChange}
                              />
                            )}
                          {input.type === "number" &&
                            input.tab === true &&
                            input?.address_type === "physical" && (
                              <div className="col-12 col-md-3">
                                <Row className="mt-3">
                                  <label className="ms-1 heading-5 text-dark ">
                                    {input.label}
                                  </label>
                                </Row>
                                <Input
                                  className="input-field mt-0 pb-2 w-100 heading-5"
                                  maxLength={14}
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  minLength={14}
                                  placeholder={input.placeholder}
                                  defaultCountry="US"
                                  value={values[input.name]}
                                  onChange={(value) => {
                                    setValues({
                                      ...values,
                                      [input.name]: value,
                                    });
                                    setGetError(false);
                                  }}
                                />
                              </div>
                            )}
                          {/* //appSelcet for state name  */}

                          {input.type === "select" &&
                            input.name === "physical_state_id" &&
                            input.tab === true &&
                            input?.address_type === "physical" && (
                              <AppSelect
                                key={input.id}
                                {...input}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select State</option>
                                    {input.options.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.state_name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}

                          {input.type === "switch" &&
                            input.tab === true &&
                            input?.address_type === "physical" && (
                              <AppSwitch
                                key={input.id}
                                value={values[input.name]}
                                onChange={appSwitchHanler}
                                {...input}
                              />
                            )}
                          {input.type === "checkbox" &&
                            !editId &&
                            input.tab === true &&
                            input?.address_type === "physical" && (
                              <AppCheckbox
                                {...input}
                                handleChange={appCheckboxHandler}
                              />
                            )}
                        </>
                      ))}
                    </div>
                  </TabPane>

                  <TabPane tab="Comments" key="3">
                    <div className="row editor">
                      <div className="col-12 col-lg-6 px-2 ">
                        {!DisplayOnCertList.isLoading && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6>Display On Cert</h6>
                            </div>
                            <div className="col-12 col-lg-9">
                              <AppSelect
                                value={values.selDisplay}
                                classStyle="border w-100 p-1 my-2"
                                onChange={(_, value) => {
                                  setValues({
                                    ...values,
                                    selDisplay: value,
                                  });
                                  setDisplay_oncert_comment(
                                    display_oncert_comment + value
                                  );
                                }}
                                options={
                                  <>
                                    <option value={""}>
                                      Select Display On Cert
                                    </option>
                                    {DisplayOnCertList.isSuccess &&
                                      DisplayOnCertList?.data?.data?.map(
                                        ({ title, comments, id }) => {
                                          return (
                                            <option key={id} value={comments}>
                                              {title}
                                            </option>
                                          );
                                        }
                                      )}
                                  </>
                                }
                              />
                            </div>
                          </div>
                        )}
                        <AppTextEditor
                          value={display_oncert_comment}
                          onChange={(newContent) =>
                            setDisplay_oncert_comment(newContent)
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-6 px-2 ">
                        {!ReadOnlyList.isLoading && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6>Read Only</h6>
                            </div>
                            <div className="col-12 col-lg-9">
                              <AppSelect
                                value={values.selReadOnly}
                                classStyle="border w-100 p-1 my-2"
                                onChange={(_, value) => {
                                  setValues({
                                    ...values,
                                    selReadOnly: value,
                                  });
                                  setRead_only_comment(
                                    read_only_comment + value
                                  );
                                }}
                                options={
                                  <>
                                    <option value={""}>Select Read Only</option>
                                    {ReadOnlyList.isSuccess &&
                                      ReadOnlyList?.data?.data.map(
                                        ({ title, comments, id }) => {
                                          return (
                                            <option key={id} value={comments}>
                                              {title}
                                            </option>
                                          );
                                        }
                                      )}
                                  </>
                                }
                              />
                            </div>
                          </div>
                        )}
                        <AppTextEditor
                          value={read_only_comment}
                          onChange={(newContent) =>
                            setRead_only_comment(newContent)
                          }
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              {
                <div className="row">
                  <div className="col-12 px-2">
                    <div className="my-1 p-0">
                      <MultiManagement
                        setDate={editId ? values.date_verified : ""}
                        //getting date info
                        getDate={(value) => {
                          values.date_verified = value;
                        }}
                        afterClose={afterClose}
                        addFormVal={(value) => {
                          values.hoa_collectors_fees = value;
                        }}
                        //add new
                        whileEdit={(value) => {
                          if (editId) {
                            setValues({
                              ...values,
                              add_hoa_collectors_fees: [
                                ...values?.add_hoa_collectors_fees,
                                value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          if (value?.temp_id) {
                            if (
                              values.add_hoa_collectors_fees?.length > 0 &&
                              values.add_hoa_collectors_fees?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_collectors_fees.forEach((item) => {
                                if (item.temp_id === value.temp_id) {
                                  item.description = value.description;
                                  item.fees = value.fees;
                                }
                              });
                            }
                          }

                          if (editId) {
                            if (
                              values.edit_hoa_collectors_fees?.length > 0 &&
                              values.edit_hoa_collectors_fees?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values.edit_hoa_collectors_fees.forEach(
                                (item) => {
                                  if (item.id === value.id) {
                                    item.description = value.description;
                                    item.fees = value.fees;
                                  }
                                }
                              );
                            } else {
                              if (value?.id) {
                                setValues({
                                  ...values,
                                  edit_hoa_collectors_fees: [
                                    ...values?.edit_hoa_collectors_fees,
                                    value,
                                  ],
                                });
                              }
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_collectors_fees: [
                                  ...values?.delete_hoa_collectors_fees,
                                  String(value.id),
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_collectors_fees:
                                  values.add_hoa_collectors_fees?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId ? values?.hoa_collectors_fees_list : []
                        }
                      />
                    </div>
                  </div>
                </div>
              }

              <div className="d-flex justify-content-center pb-3 pt-2">
                <div className="me-md-2 ">
                  <AppButton
                    onClick={handleReset}
                    title={"CLEAR"}
                    variant={"secondary-md"}
                  />
                </div>
                <AppButton title={"SUBMIT"} variant={"primary-md"} />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
