import React, { useEffect, useState } from "react";
import {  Row } from "react-bootstrap";
import { Form, Popconfirm, Collapse } from "antd";
import { FaPlus } from "react-icons/fa";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import AppInput from "../AppInput/AppInput";
import AppSelect from "../AppSelect/AppSelect";
import AppTable from "../../Components/AppTable/AppTable";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { useGetCountyListMutation } from "../../Redux/Services/county";
import {   removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export const ProductTypeComp = ({
  multiCountyListVal,
  afterClose,
  initailValArr,
  addFormVal,
  updateContact,
  deleteContact,
  produt_id,
  title,
  stateList,
  whileEdit = false,
  fullData = false,
  getDefualtVal = true,
  actionControl = false,
}) => {
  const initialVal = {
    county_id: "",
    state_id: "",
    is_auto: false,
    is_pre: false,
    is_manual: false,
    wholesale: "",
    retail: "",
    included: "",
    wholesale_plus: "",
    retail_plus: "",
  };
  const hardCodeData = {
    id: 1,
    state_id: 99,
    product_type_id: produt_id,
    county_id: 9999,
    state_name: "Standard",
    county_name: "Standard",
    wholesale: "$10",
    is_manual: false,
    retail: "$10",
    included: "1",
    wholesale_plus: "$10",
    retail_plus: "$10",
    is_auto: true,
    is_pre: true,
  };
  const [form] = Form.useForm();
  const [values, setValues] = useState(initialVal);
  const [data, setData] = useState([hardCodeData]);
  const [editingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;
  const [countyData, setCountyData] = useState([]);
  const [active, setActive] = useState("2");
  const { token } = useSelector((state) => state.auth);
  //calling rtk query hook for geting County list
  const [getCountyList, getCountyListResult] = useGetCountyListMutation();

  useEffect(() => {
    if (getCountyListResult.isSuccess) {
      setCountyData(getCountyListResult.data.data);
    } else {
      setCountyData([]);
    }
  }, [getCountyListResult.isLoading]);

  const {
    is_auto,
    county_name,
    state_name,
    is_pre,
    county_id,
    state_id,
    wholesale,
    retail,
    included,
    wholesale_plus,
    retail_plus,
  } = values;

  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);

  const newProductData = data?.map(({ product_type_name, ...rest }) => {
    return rest;
  });

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const newCounty = countyData.filter(function (item) {
    return multiCountyListVal.indexOf(String(item.id)) !== -1;
  });

  let res = [];
  res = newCounty?.filter((el) => {
    return !data.find((element) => {
      return element.county_name === el.county_name;
    });
  });

  const defaultColumns = [
    {
      dataIndex: "",
      key: "id",
      width: 1,
    },

    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      render: (value, record) => {
        return <>{record.state_name}</>;
      },
      align: "center",
    },

    {
      title: "County",
      dataIndex: "county_name",
      key: "county_name",
      render: (value, record) => {
        return <>{record.county_name}</>;
      },
      align: "center",
    },
    {
      title: "Auto/Manual",
      inputType: "checkbox",
      dataIndex: "is_auto",
      key: "is_auto",
      align: "center",
      render: (checked, row) =>
        !row.is_auto ? <AiOutlineClose /> : <AiOutlineCheck />,
      editable: true,
    },
    {
      title: "Pre",
      inputType: "checkbox",
      dataIndex: "is_pre",
      key: "is_pre",
      align: "center",
      render: (checked, row) =>
        !row.is_pre ? <AiOutlineClose /> : <AiOutlineCheck />,
      editable: true,
    },
    {
      title: "Wholesale",
      dataIndex: "wholesale",
      key: "wholesale",
      type: "price",
      maxLength: 4,
      align: "center",
      required: true,
      inputType: "number",
      render: (value) => {
        return <>{value}</>;
      },
      editable: true,
    },
    {
      title: "Retail",
      dataIndex: "retail",
      key: "retail",
      inputType: "number",
      type: "price",
      maxLength: 4,
      align: "center",
      render: (value) => {
        return <>{value}</>;
      },
      editable: true,
      required: true,
    },
    {
      title: "Included",
      dataIndex: "included",
      key: "included",
      type: "price",
      maxLength: 4,
      inputType: "number",
      editable: true,
      required: true,
    },
    {
      title: "+Wholesale",
      dataIndex: "wholesale_plus",
      key: wholesale_plus,
      maxLength: 4,
      required: true,
      inputType: "number",
      render: (value) => {
        return <>{value}</>;
      },
      editable: true,
    },
    {
      title: "+Retail",
      dataIndex: "retail_plus",
      key: "retail_plus",
      maxLength: 4,
      required: true,
      inputType: "number",
      render: (value) => {
        return <>{value}</>;
      },
      editable: true,
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            {data?.length > 1 && record.state_name !== "Standard" && (
              <Popconfirm
                getPopupContainer={(trigger) => trigger.parentElement}
                title="Are you sure you want to delete？"
                onConfirm={() => {
                  deleteHandler(record);
                }}
                placement="topLeft"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                okText="Yes"
                cancelText="No"
              >
                <span className={"ms-2 "}>
                  <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
                </span>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    // //sending delete id
    deleteContact(id);
  };

  const inputs = [
    {
      id: 1,
      name: "county_id",
      value: county_id || "",
      column: "2",
      required: true,
    },
    {
      id: 1,
      name: "state_id",
      value: state_id || "",
      column: "2",
      required: true,
    },
    {
      id: 454854,
      name: "state_name",
      type: "select",
      value: state_name || "",
      column: "2",
      options: stateList,
      errorMessage: "Please select state name!",
      required: true,
    },
    {
      id: 45454,
      name: "county_name",
      type: "select",
      value: county_name || "",
      column: "2",
      options: res || [],
      errorMessage: "Please select county name!",
      required: true,
    },

    {
      id: 25,
      name: "is_auto",
      type: "checkbox",
      checked: is_auto,
      label: "Auto",
      column: "1",
      margin: "3",
    },
    {
      id: 42,
      name: "is_pre",
      checked: is_pre,
      type: "checkbox",
      column: "1",
      label: "is_pre",
      margin: "3",
    },
    {
      id: 2,
      name: "wholesale",
      type: "number",
      value: wholesale || "",
      column: "1",
      placeholder: "Wholesale ",
      errorMessage: "Please enter wholesale price!",
      required: true,
      maxLength: 4,
    },
    {
      id: 4,
      name: "retail",
      type: "number",
      value: retail || "",
      column: "1",
      placeholder: "Retail Price",
      errorMessage: "Please enter retail price!",
      required: true,
      maxLength: 4,
    },
    {
      id: 484,
      name: "included",
      type: "text",
      value: included || "",
      column: "1",
      placeholder: "Included ",
      errorMessage: "Please enter included!",
      required: true,
      maxLength: 4,
    },

    {
      id: 445,
      name: "wholesale_plus",
      type: "number",
      value: wholesale_plus || "",
      column: "1",
      placeholder: "+Wholsale",
      errorMessage: "Please enter wholesale!",
      required: true,
      maxLength: 4,
    },
    {
      id: 445,
      name: "retail_plus",
      type: "number",
      value: retail_plus || "",
      column: "1",
      placeholder: "+Retail Price ",
      errorMessage: "Please enter retail price!",
      required: true,
      maxLength: 4,
    },
  ];

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
  };

  const cancelHandler = () => {
    setValues(initialVal);
    setAddRowToggler(false);

    addFormVal(newProductData);
    setEditId(null);
  };

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([...newData, values]);
      setAddRowToggler(false);
      updateContact && updateContact(values);
      setEditId(null);
      setValues(initialVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          product_type_id: produt_id,
          temp_id: randomId,
        },
      ]);
      // while edit and ading new record directly
      setValues(initialVal);
      whileEdit &&
        whileEdit({ ...values, product_type_id: produt_id, temp_id: randomId });
      setAddRowToggler(false);
      setEditId(null);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.replace(/[^a-zA-Z" "]/gi, ""),
    });
    if (
      name === "wholesale" ||
      name === "retail" ||
      name === "retail_plus" ||
      name === "included" ||
      name === "wholesale_plus"
    ) {
      setValues({
        ...values,
        [name]: value.replace(/[^0-9.]/gi, ""),
      });
    } else if (name === "email") {
      setValues({ ...values, [name]: value });
    }
  };

  //check bbox
  const handleChange = (property, value) => {
    setValues({
      ...values,
      [property]: value,
      is_manual: value === false ? true : false,
    });
    ///
  };

  //select handler
  const selectHandler = (property, value, arrtibute) => {
    if (property === "state_name") {
      setValues({
        ...values,
        state_name: value,
        state_id: arrtibute?.getAttribute("data_name"),
      });
      const state_id = { state_id: arrtibute?.getAttribute("data_name") };
      getCountyList({ state_id, token });
    } else if (property === "county_name") {
      setValues({
        ...values,
        county_name: value,
        county_id: arrtibute?.getAttribute("data_name"),
      });
    }
  };

  useEffect(() => {
    // addFormVal(data);
    setAddRowToggler(false);
    if (initailValArr?.length > 0) {
      setData(initailValArr);
    } else {
      if (getDefualtVal) {
        setData([hardCodeData]);
      }
    }
    setValues(initialVal);
    const { id, ...othersData } = hardCodeData;
    if (data.length === 1 && getDefualtVal) {
      addFormVal({ [title]: othersData });
    }
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    if (getDefualtVal) {
      addFormVal({ [title]: newProductData });
    }
  }, [data]);

  useEffect(() => {
    if (initailValArr && !getDefualtVal) {
      setData(initailValArr);
    }
  }, [initailValArr]);

  useEffect(() => {
    fullData && fullData(data);
  }, [data]);

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className="fs-18 mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            {title}
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col border rounded-2 mb-2 p-2">
            {
              <>
                <div
                  className={`d-flex  justify-content-between align-items-center my-2 contact-forms`}
                >
                  <h6 className=" fw-bold me-2" style={{ fontSize: 15 }}></h6>
                  {!actionControl && (
                    <div className={`add_icon`} onClick={addNewRecord}>
                      <FaPlus />
                    </div>
                  )}
                </div>
                <Row>
                  <AppTable
                    // sort={sort}
                    pagination={false}
                    form={form}
                    isEditing={isEditing}
                    dataSource={data}
                    columns={defaultColumns}
                  />
                </Row>
                {addRowToggler && (
                  <div className="row bg-white py-2 shadow-sm">
                    {inputs.map((input) => (
                      <>
                        {input.type === "checkbox" && (
                          <AppCheckbox {...input} handleChange={handleChange} />
                        )}

                        {input.type === "select" &&
                          input.name === "state_name" &&
                          !editId?.id && (
                            <AppSelect
                              key={input.id}
                              {...input}
                              defaultSet={afterClose}
                              onChange={selectHandler}
                              options={
                                <>
                                  <option value={""}>Select State</option>
                                  {input?.options?.map((ele) => {
                                    return (
                                      <option
                                        key={ele.id}
                                        data_name={ele.id}
                                        value={ele.state_name}
                                      >
                                        {ele.state_name}
                                      </option>
                                    );
                                  })}
                                </>
                              }
                            />
                          )}

                        {input.type === "select" &&
                          input.name === "county_name" &&
                          !editId?.id && (
                            <AppSelect
                              key={input.id}
                              defaultSet={afterClose}
                              {...input}
                              onChange={selectHandler}
                              options={
                                <>
                                  <option value={""}>Select County</option>
                                  {input.options.map((ele) => {
                                    return (
                                      <option
                                        key={ele.id}
                                        data_name={ele.id}
                                        value={ele.county_name}
                                      >
                                        {ele.county_name}
                                      </option>
                                    );
                                  })}
                                </>
                              }
                            />
                          )}

                        {input.type === "text" && (
                          <AppInput
                            defaultSet={afterClose}
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            onChange={onChange}
                          />
                        )}
                        {input.type === "number" && (
                          <AppNumeric
                            defaultSet={afterClose}
                            {...input}
                            type="text"
                            maxLength={15}
                            value={values[input.name]}
                            thousandSeparator={true}
                            placeholder={input.placeholder}
                            prefix={"$"}
                            decimalScale={6}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                [input.name]: value,
                              });
                            }}
                          />
                        )}
                      </>
                    ))}
                    <div className="col-12 col-lg-3  mt-2  align-self-center">
                      <div className="d-flex ">
                        <span
                          onClick={handleSubmit}
                          className={`btn btn-primary ${
                            !state_id ||
                            !retail ||
                            !county_id ||
                            !wholesale ||
                            !retail_plus ||
                            !wholesale_plus ||
                            !included
                              ? "disabled"
                              : "btn-success"
                          } btn-sm edit-buttons`}
                        >
                          {editId?.id ? "Update" : "Save"}
                        </span>
                        <span
                          onClick={cancelHandler}
                          className="btn btn-primary ms-1 btn-sm edit-buttons"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            }
          </div>
        )}
      </Panel>
    </Collapse>
  );
};
