import { Input, notification, Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";

import OrderDashboard from "./orderDashboard/OrderDashboard";
import { useNavigate } from "react-router-dom";
import { HiOutlineDocumentText } from "react-icons/hi";
import { MdOutlineAssignment, MdOutlineDeleteSweep } from "react-icons/md";
import AppModal from "../../Components/AppModal/AppModal";
import NoticeAssignModal from "./orderDashboard/NoticeAssignModal";
import {
	useDeleteMultipleOrderMutation,
	useOrderExaminerUpdateMutation,
	useOrdersListQuery,
} from "../../Redux/Services/Orders";
import { useEffect } from "react";
import ExaminerAssignModal from "./orderDashboard/ExaminerAssignModal";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../App";
import { useContext } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function Order() {
	//context api
	const { state } = useContext(GlobalContext);
	const [globalVal, setGlobalVal] = state;

	const [rowSelection, setRowSelection] = useState([]);

	//redux
	const { token } = useSelector((state) => state.auth);
	const { orderModule, orderListToolbar } = useSelector(
		(state) => state.flagRoles
	);

	//router
	const navigate = useNavigate();
	//notice
	const [showAddNoticeModal, setShowAddNoticeModal] = useState(false);
	const [showAddNoticeModalInitial, setShowAddNoticeModalInitial] =
		useState(false);

	//assign
	const [showAddAssignModal, setShowAddAssignModal] = useState(false);
	const [examinerId, setExaminerId] = useState("");
	const [showAddAssignModalInitial, setShowAddAssignModalInitial] =
		useState(false);

	//sending request to  examiner order
	const [orderExaminerUpdate, orderExaminerUpdateResult] =
		useOrderExaminerUpdateMutation();

	//sending request to  delete multiple orders
	const [deleteOrders, deleteOrdersResult] = useDeleteMultipleOrderMutation();

	const ordersListApi = useOrdersListQuery(token);

	//useEffect for update asignee examiner
	useEffect(() => {
		if (orderExaminerUpdateResult.isSuccess) {
			setShowAddAssignModal(false);
			///after resubmit details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: orderExaminerUpdateResult.data?.message,
			});
			ordersListApi.refetch();
			setExaminerId("");
			setRowSelection([]);
		}
		if (orderExaminerUpdateResult.isError) {
			///after submit details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					orderExaminerUpdateResult.error?.data?.error ||
					orderExaminerUpdateResult.error?.data?.message ||
					(orderExaminerUpdateResult.error.error && "server unreachable!") ||
					orderExaminerUpdateResult.error.data?.msg.sqlMessage,
			});
		}
		setGlobalVal({ ...globalVal, loader: orderExaminerUpdateResult.isLoading });
	}, [orderExaminerUpdateResult.isLoading]);

	//useEffect for update asignee examiner
	useEffect(() => {
		if (deleteOrdersResult.isSuccess) {
			///after resubmit details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: deleteOrdersResult.data?.message,
			});
			ordersListApi.refetch();
			setRowSelection([]);
		}
		if (deleteOrdersResult.isError) {
			///after submit details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					deleteOrdersResult.error?.data?.error ||
					deleteOrdersResult.error?.data?.message ||
					(deleteOrdersResult.error.error && "server unreachable!") ||
					deleteOrdersResult.error.data?.msg.sqlMessage,
			});
		}
		setGlobalVal({ ...globalVal, loader: deleteOrdersResult.isLoading });
	}, [deleteOrdersResult.isLoading]);

	//adding assignees
	const assigExaminer = (value) => {
		orderExaminerUpdate({
			data: {
				order_id: rowSelection.map(({ id }) => {
					return id;
				}),
				examiner_id: value,
			},
			token,
		});
	};

	const addNewOrder = () => {
		navigate(`/order-editor`, { state: { orderEvent: "add" } });
	};

	return (
		<>
			{/* //add multiple notice flags  */}

			<AppModal
				width={"40%"}
				height={300}
				onClose={(value) => {
					setShowAddNoticeModal(value);
				}}
				afterClose={() => {
					setShowAddNoticeModalInitial(!showAddNoticeModalInitial);
					setRowSelection([]);
				}}
				showModal={showAddNoticeModal}
				element={
					<NoticeAssignModal
						setShowAddNoticeModal={setShowAddNoticeModal}
						orderId={rowSelection.map(({ id }) => {
							return id;
						})}
						showModalInitalState={showAddNoticeModalInitial}
					/>
				}
			/>
			{/* //add multiple assign  */}

			<AppModal
				width={"40%"}
				height={300}
				onClose={(value) => {
					setShowAddAssignModal(value);
				}}
				showModal={showAddAssignModal}
				afterClose={() =>
					setShowAddAssignModalInitial(!showAddAssignModalInitial)
				}
				element={
					<ExaminerAssignModal
						isLoading={orderExaminerUpdateResult.isLoading}
						value={examinerId}
						showModalInitalState={showAddAssignModalInitial}
						onSubmit={assigExaminer}
						onChange={(e, value) => {
							setExaminerId(value);
						}}
					/>
				}
			/>

			{/* //dashboard  */}
			<h5 className="settings-container ">Tax Certificate Portal | Orders</h5>
			<div className="row  ">
				{/* {tools showing after row selection } */}
				{
					<div className="tools col-12 col-lg-6 ms-auto d-flex gap-1 align-items-center justify-content-end">
						{rowSelection.length > 0 && (
							<div className="d-flex gap-1 align-items-center">
								{" "}
								{orderListToolbar.map((item,index) => {
									if (item.add_notice_flag) {
										return (
											<Tooltip
												key={index}
												overlayInnerStyle={{
													fontSize: 10,
												}}
												placement="right"
												title={"Add Notice Flag"}
											>
												<div
													className="add_icon "
													onClick={() => {
														setShowAddNoticeModal(true);
													}}
												>
													<HiOutlineDocumentText color="#0d6efd" />
												</div>
											</Tooltip>
										);
									} else if (item.assign_order) {
										return (
											<Tooltip
												key={index}
												overlayInnerStyle={{
													fontSize: 10,
												}}
												placement="top"
												title={"Assign"}
											>
												<div
													onClick={() => {
														setShowAddAssignModal(true);
													}}
													className="add_icon "
												>
													<MdOutlineAssignment color="#0d6efd" />
												</div>
											</Tooltip>
										);
									} else if (item.delete_order) {
										return (
											<Popconfirm
												key={index}
												getPopupContainer={(trigger) => trigger.parentElement}
												title="Are you sure you want to delete？"
												onConfirm={() => {
													deleteOrders({
														data: {
															order_ids: rowSelection.map(({ id }) => {
																return id;
															}),
														},
														token,
													});
												}}
												placement="top"
												icon={
													<QuestionCircleOutlined
														style={{
															color: "red",
														}}
													/>
												}
												okText="Yes"
												cancelText="No"
											>
												<Tooltip
													overlayInnerStyle={{
														fontSize: 10,
													}}
													placement="top"
													title={"Delete Selected Orders"}
												>
													<div className="add_icon ">
														<MdOutlineDeleteSweep color="#0d6efd" />
													</div>
												</Tooltip>
											</Popconfirm>
										);
									}
								})}
							</div>
						)}
						<div className=" d-flex align-items-center gap-1 ">
							<div>
								<Input
									placeholder="Search here..."
									className="global-search "
									value={globalVal.orderSearch}
									onChange={(e) => {
										setGlobalVal({ ...globalVal, orderSearch: e.target.value });
									}}
									prefix={<FaSearch color="lightgray" />}
								/>
							</div>
							{orderModule.map((item,index) => {
								if (item.add_button) {
									return (
										<div key={index} className="add_icon ms-auto " onClick={addNewOrder}>
											<Tooltip
												overlayInnerStyle={{
													fontSize: 10,
												}}
												placement="bottom"
												title={"Add New Order"}
											>
												<FaPlus />
											</Tooltip>
										</div>
									);
								}
							})}
						</div>
					</div>
				}
			</div>
			<div className="row mt-2">
				<OrderDashboard
					setRowSelection={setRowSelection}
					search={globalVal.orderSearch}
				/>
			</div>
		</>
	);
}
