import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Theme/DarkMode.css";
import { useSelector } from "react-redux";
import Login from "./View/Login/Login";
import MyDashBoard from "./View/Dashboard/MyDashBoard";
import ForgotPassword from "./View/ForgetPassword/ForgotPassword";
import Settings from "./View/Settings/Settings";
import Tools from "./View/Tools/Tools";
import Reports from "./View/Reports/Reports";
import OrderEditor from "./View/OrderEditor/mainOrder/OrderEditor";
import OrderWizard from "./View/OrderWizard/OrderWizard";
import ResetPassword from "./View/ResetPassword/ResetPassword";
import Helmet from "react-helmet";
import Aside from "./Layouts/Aside/Aside";
import SessionExpire from "./View/SessionExpire/SessionExpire";
import Logout from "./View/Logout/Logout";
import PagenotFound from "./View/PagenotFound/PagenotFound";
// import Notice from "./View/Notice/Notice";

import NewParcel from "./View/OrderEditor/mainOrder/NewParcel/NewParcel";
import { createContext } from "react";
import { useState } from "react";
import AppSpinner from "./Components/AppSpinner/AppSpinner";

import Testing from "./Testing/Testing";
import Notice from "./View/Notice/Notice";

export const GlobalContext = createContext();
export default function App() {
  const { token, user } = useSelector((state) => state.auth);
  const [globalVal, setGlobalVal] = useState({
    loader: false,
    orderSearch: "",
    hoaOrdersListToggle: false,
    orderAllStatusToggle: false,
    ordedashboardProductTypeId:"",
    orderCertificate: false,
  });
  const { dark } = useSelector((state) => state.theme);
  const { userRoutes } = useSelector((state) => state.flagRoles);

  //creating function for user roles routes
  function matchPath(path) {
    if (userRoutes.indexOf(path) === -1) {
      return false;
    } else {
      return true;
    }
  }

  //progress initial state automation
  // const progressData = {
  //   loading: false,
  //   isFecthParcel: false,
  //   isFetchingSupport: false,
  //   isFetchingSupportError: false,
  //   isCertificate: false,
  //   currentTask: "",
  // };

  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case "is":
  //       return {
  //         ...state,
  //         currentTask:"login"
  //       }
  //     default:
  //       return state;
  //   }
  // };

  // const [parcelAutomation, dispatch] = useReducer(reducer, progressData);

  return (
    <GlobalContext.Provider value={{ state: [globalVal, setGlobalVal] }}>
      {globalVal.loader && <AppSpinner position="fixed" />}

      <div>
        <Helmet>
          <body className={` ${dark ? "dark" : ""}-theme view`} />
        </Helmet>
        <Router>
          {/* <AutoLogout minutes={1} /> */}

          <Routes>
            <Route path="/session-expired" element={<SessionExpire />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/test" element={<Testing />} />
            <Route
              path="/login"
              element={
                token && user.first_time_login === 0 ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Login />
                )
              }
            />
            {/* //page not found  */}
            <Route path="*" element={<PagenotFound />} />
            <Route
              path="/"
              element={
                token && user.first_time_login === 0 ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route
              path="/forgot-password"
              element={
                token && user.first_time_login === 0 ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <ForgotPassword />
                )
              }
            />
            <Route
              path="/resetpassword/:reset_token/:user_id"
              element={<ResetPassword />}
            />

            <Route
              path="/first-time-login"
              element={
                //firstTime
                token && user.first_time_login === 1 ? (
                  <ResetPassword />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/"
              element={
                token && user.first_time_login === 0 ? (
                  <Aside />
                ) : (
                  <Navigate to={`${!token ? "/login" : "/first-time-login"}`} />
                )
              }
            >
              <Route index path="dashboard" element={<MyDashBoard />} />

              <Route path="order" element={<OrderEditor />} />
              <Route path="order-editor" element={<OrderEditor />} />
              <Route path="order-editor/:id" element={<OrderEditor />} />
              <Route
                path="settings"
                element={
                  matchPath("settings") ? <Settings /> : <PagenotFound />
                }
              />
              <Route
                path="tools"
                element={matchPath("tools") ? <Tools /> : <PagenotFound />}
              />
              <Route
                path="reports"
                element={matchPath("reports") ? <Reports /> : <PagenotFound />}
              />
              <Route path="notice" element={<Notice />} />
              <Route path="order-wizard" element={<OrderWizard />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </GlobalContext.Provider>
  );
}
