import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const supportVendor = createApi({
  reducerPath: "supportVendor",
  tagTypes: ["supportVendor"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    supportVendorList: builder.query({
      query: (token) => ({
        url: `/support-vendor-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}`  },
      }),
      providesTags: ["supportVendor"],
    }),
    supportVendorStatus: builder.mutation({
      query: (data) => ({
        url: `/update-support-vendor-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["supportVendor"],
    }),
  }),
});

export const {
    useSupportVendorListQuery,
    useSupportVendorStatusMutation
} = supportVendor;
