import React from "react";
import { Spin } from "antd";
import "./AppSpinner.css";
import { LoadingOutlined } from "@ant-design/icons";
export default function AppSpinner({ size = "large", position = "absolute" }) {
  //creating icon
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 44,
        color: "rgba(163, 163, 163, 0.9)",
      }}
      spin
    />
  );
  return (
    <>
      <div
        style={{
          position: position,
          height: `${position === "fixed" ? "100vh" : "100%"}`,
        }}
        className={`app-spinner 1 ${size}`}
      >
        <Spin size="large" />
      </div>
    </>
  );
}
