import { LoadingOutlined } from "@ant-design/icons";
import { Collapse, Spin, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ManualEntry from "../../NewParcel/ManualEntry/ManualEntry";
const { Panel } = Collapse;
export default function ParcelInfo({
  info,
  mode,
  setKey,
  refresh,
  data,
  toolsData,
  EditHandlerComp,
  refecthParcelStatus,
  setShowYearFlag,
}) {
  // const [data, setData] = useState({});
  // const [parcelInfo, parcelInfoResult] = useParcelInfoMutation();

  // const { token } = useSelector((state) => state.auth);

  // //useLocation for url
  // const { state } = useLocation();
  // const orderId = state?.id;

  // useEffect(() => {
  //   if (parcelInfoResult.isSuccess) {
  //     setData(parcelInfoResult.data?.data);
  //     setGetParcelData(parcelInfoResult.data?.data);
  //     toolsData.year = data.year
  //   }
  //   if (parcelInfoResult.error) {
  //     setData([]);
  //     setGetParcelData({ ...data, error: "server unreachable" });
  //   }
  // }, [parcelInfoResult.isLoading]);

  // //getting
  // useEffect(() => {
  //   if (refresh.slice(0, 6) === "parcel") {
  //     parcelInfo({
  //       data: { parcel_id: info.id, year: info.year  },
  //       token,
  //     });
  //   }
  // }, [refresh]);

  //mode
  // useEffect(() => {
  //   if (mode === "view") {
  //     setGetParcelData(data);
  //     toolsData.year = data.year
  //   }
  // }, [mode]);

  return (
    <div className="row mt-2">
      <Collapse
        className="rounded-3 border-0 text-white "
        expandIcon={() => <></>}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3  "
          header={`Parcel Information`}
          style={{ fontSize: 18 }}
          key="1"
        >
          <ManualEntry
            refecthParcelStatus={refecthParcelStatus}
            EditHandlerComp={EditHandlerComp}
            toolsData={toolsData}
            data={data}
            setShowYearFlag={setShowYearFlag}
            refresh={refresh}
            editId={info.id}
            mode={mode}
            setKey={setKey}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
