import React, { useState } from "react";
import { Alert, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";
import { FaPlus} from "react-icons/fa";
import {
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { percentFormat, removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
const randomPass = require("secure-random-password");
const _ = require("lodash");
export default function MultiOwners({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  disabled = false,
  whileEdit = false,
}) {
  const [ownership_percentage, setOwnership_percentage] = useState("");
  ///vendor contacts details
  const [ active,setActive] = useState("2");

  const initailVal = {
    owner_name: "",
    address: "",
    year: "",
  };

  const options = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i > currentYear - 5; i--) {
    options.push(i);
  }

  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { owner_name, address, year } = values;
  //state List

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
    setOwnership_percentage(record.ownership_percentage);
  };

  const columns = [
    {
      title: <span>Year</span>,
      dataIndex: "year",
      key: "year",
      required: true,
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },
    {
      title: <span>Owner Name</span>,
      dataIndex: "owner_name",
      key: "owner_name",
      required: true,
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },
    {
      title: <span>Address</span>,
      dataIndex: "address",
      key: "address",
      required: true,
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },

    {
      title: <span>Percentage</span>,
      dataIndex: "ownership_percentage",
      key: "ownership_percentage",
      render: (value) => {
        return <>{percentFormat(value)}</>;
      },
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      className: `${!disabled ? "" : "d-none"}`,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const taxYearArr = [];
  data?.map(({ year }) => {
    taxYearArr.push(Number(year));
  });

  const myArrayFiltered = options?.filter((el) => {
    return !taxYearArr.includes(el);
  });

  const inputs = [
    {
      id: 256,
      name: "year",
      type: "select",
      value: year || "",
      column: "3",
      options: myArrayFiltered,
      errorMessage: "Please select year!",
      required: true,
      label: "Year",
    },

    {
      id: 4523,
      name: "owner_name",
      type: "text",
      value: owner_name || "",
      column: "3",
      placeholder: "Enter Owner Name",
      errorMessage: "Please enter  owner name!",
      label: "Owner Name",
      required: true,
    },

    {
      id: 7,
      name: "address",
      value: address || "",
      type: "text",
      column: "3",
      placeholder: "Enter Address",
      errorMessage: "Please enter address!",
      label: "Address",
      required: true,
    },
    {
      id: 15454,
      name: "ownership_percentage",
      value: ownership_percentage,
      type: "text",
      column: "3",
      placeholder: "Enter Percentage",
      errorMessage: "Please enter percentage!",
      label: "Percentage",
      required: true,
    },
  ];
  const onChange = (e) => {
    setGetErr(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([
        ...newData,
        {
          ...values,
          ownership_percentage,
        },
      ]);
      setAddRowToggler(false);
      updateContact &&
        updateContact({
          ...values,
          ownership_percentage,
        });
      setEditId(null);
      setValues(initailVal);
      setOwnership_percentage("");
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
          ownership_percentage,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({
        ...values,
        temp_id: randomId,
        ownership_percentage,
      });
      setValues(initailVal);
      setOwnership_percentage("");
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
    setOwnership_percentage("");
  };
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
    setOwnership_percentage("");
  };

  //select handler
  const selectHandler = (property, value, arrtibute) => {
    setValues({
      ...values,
      [property]: value,
    });
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
    setOwnership_percentage("");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <div className="gray-table-col border rounded-3 bg-white my-1 p-2">
      <div className="h6 pb-2 mb-0 text-primary border-bottom  border-primary">
        Owner Information
      </div>
      <div
        className={`d-flex ${"justify-content-between"} align-items-center  my-2 contact-forms`}
      >
        <h6 className="fs-18 fw-bold me-2"></h6>
        {!addRowToggler && data?.length > 0 && !disabled && (
          <div className={`add_icon`} onClick={addNewRecord}>
            <FaPlus />
          </div>
        )}
      </div>

      <AppTable
        // sort={sort}
        pagination={false}
        dataSource={_.orderBy(data, ["year"], ["desc"])}
        columns={columns}
      />

      {getErr && (
        <div className="mt-3">
          <Alert message={getErr} className="py-1 mx-3" type="error" showIcon />{" "}
        </div>
      )}
      {/* //loading  */}
      {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

      {(addRowToggler || data?.length < 1 || !initailValArr) && !disabled && (
        <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
          {inputs.map((input) => (
            <>
              {input.type === "select" && input.name === "year" && (
                <AppSelect
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  options={
                    <>
                      <option value={editId ? editId.year : ""}>
                        {editId ? editId.year : "Select Year"}
                      </option>
                      {input?.options?.map((ele) => {
                        return (
                          <option key={ele} value={ele}>
                            {ele}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}

              {input.name === "ownership_percentage" && (
                <AppNumeric
                  {...input}
                  defaultSet={afterClose}
                  value={ownership_percentage}
                  thousandSeparator={true}
                  placeholder={input.placeholder}
                  suffix={"%"}
                  decimalScale={2}
                  maxLength={6}
                  onChange={(values) => {
                    setOwnership_percentage(values);
                  }}
                />
              )}

              {input.type === "text" &&
                input.name !== "ownership_percentage" && (
                  <AppInput
                    defaultSet={afterClose}
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                )}
            </>
          ))}
          <div className="col-12 col-lg-1 mt-2 p-0">
            <div className="d-flex">
              <span
                onClick={handleSubmit}
                className={`btn btn-primary  btn-sm edit-buttons ${
                  !owner_name || !ownership_percentage || !address
                    ? "disabled"
                    : "btn-success"
                } `}
              >
                Save
              </span>
              <span
                onClick={cancelHandler}
                className="btn btn-primary ms-1 btn-sm edit-buttons"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
