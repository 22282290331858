import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const parcels = createApi({
  reducerPath: "parcels",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  tagTypes: ["parcels"],
  endpoints: (builder) => ({
    parcelStatusList: builder.mutation({
      query: (data) => ({
        url: `/order-parcel-status/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["parcels"],
    }),
    parcelReadOnlyList: builder.query({
      query: (token) => ({
        url: `/parcel-read-only-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    parcelDisplayOnCertList: builder.query({
      query: (token) => ({
        url: `/parcel-display-cert-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    editParcel: builder.query({
      query: (data) => ({
        url: `/edit-parcel/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    parcelInfo: builder.mutation({
      query: (data) => ({
        url: `/parcel-information`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateParcelInfo: builder.mutation({
      query: (data) => ({
        url: `/update-parcel/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addParcelUpdate: builder.mutation({
      query: (data) => ({
        url: `/add-parcel-year`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    searchYearParcel: builder.mutation({
      query: (data) => ({
        url: `/search-cad-info`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addParcelToHoa: builder.mutation({
      query: (data) => ({
        url: `/add-parcel-hoa`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    editParcelHOA: builder.mutation({
      query: (data) => ({
        url: `/edit-parcel-hoa/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    getHoaComments: builder.mutation({
      query: (data) => ({
        url: `/hoa-comments/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteParcel: builder.mutation({
      query: (data) => ({
        url: `/delete-parcel`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["parcels"],
    }),
    hoaComments: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-hoa-comments/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //parcel automation start
    parcelAutomationStart: builder.mutation({
      query: (data) => ({
        url: `/initiate-automation`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //add parcel  start
    addParcelDoucment: builder.mutation({
      query: (data) => ({
        url: `/add-parcel-documents`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //supporting documents list
    supportingDocuments: builder.mutation({
      query: (data) => ({
        url: `/parcel-document-list/${data.parcelId}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //delete supporting documents
    deleteSupportingDoc: builder.mutation({
      query: (data) => ({
        url: `/delete-parcel-document/${data.id}`,
        mode: "cors",
        method: "POST",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //delete all parcels
    deleteAllParcel: builder.mutation({
      query: (data) => ({
        url: `/delete-parcel-based-order-id/${data.id}`,
        mode: "cors",
        method: "POST",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["parcels"],
    }),
    //update parcel status
    updateParcelStatus: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: { status_id: data.status_id },
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //parcel hoa status
    getHoaParcelStatus: builder.mutation({
      query: (data) => ({
        url: `/parcel-hoa-status-info/${data.parcel_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //parcel clone from order
    cloneParcelFromOrder: builder.mutation({
      query: ({ cloneOrderId, currentOrderId, token }) => ({
        url: `/parcel-clone/${cloneOrderId}/${currentOrderId}`,
        mode: "cors",
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //parcel status info
    parcelStatusInfo: builder.mutation({
      query: ({ parcel_id, token }) => ({
        url: `/parcel-status-info/${parcel_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //parcel audit logs list info
    parcelAuditLogsList: builder.mutation({
      query: ({ id, token }) => ({
        url: `/parcel-audit-log-list/${id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //parcel hoa Delete
    parcelHoaDelete: builder.mutation({
      query: ({ id, token }) => ({
        url: `/delete-parcel-hoa/${id}`,
        mode: "cors",
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const {
  useParcelStatusListMutation,
  useParcelReadOnlyListQuery,
  useParcelDisplayOnCertListQuery,
  useEditParcelQuery,
  useParcelInfoMutation,
  useUpdateParcelInfoMutation,
  useAddParcelUpdateMutation,
  useSearchYearParcelMutation,
  useAddParcelToHoaMutation,
  useEditParcelHOAMutation,
  useGetHoaCommentsMutation,
  useDeleteParcelMutation,
  useHoaCommentsMutation,
  useParcelAutomationStartMutation,
  useAddParcelDoucmentMutation,
  useSupportingDocumentsMutation,
  useDeleteSupportingDocMutation,
  useUpdateParcelStatusMutation,
  useDeleteAllParcelMutation,
  useGetHoaParcelStatusMutation,
  useCloneParcelFromOrderMutation,
  useParcelStatusInfoMutation,
  useParcelAuditLogsListMutation,
  useParcelHoaDeleteMutation
} = parcels;
