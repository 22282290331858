import React, { useContext, useState } from "react";
import { Collapse, Input, notification, Popconfirm, Tooltip } from "antd";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MdDeleteOutline, MdOutlineDeleteSweep } from "react-icons/md";
import AppCheckbox from "../../../../Components/AppCheckbox/AppCheckbox";
import { useEffect } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDeleteParcelMutation } from "../../../../Redux/Services/Parcels";
import { GlobalContext } from "../../../../App";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const { Panel } = Collapse;

export default function ParcelAccordian({
  data = [],
  title,
  setOpen,
  onChange,
  onselect = false,
  afterClose = false,
  parcelStatusListRefetch,
}) {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const [show, setShow] = useState(true);
  const [key] = useState("1");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [listData, setListData] = useState([]);

  const [search, setSearch] = useState("");

  const { token } = useSelector((state) => state.auth);
  //getting Delete parcel api
  const [parcelDelete, parcelDeleteResult] = useDeleteParcelMutation();
  const location = useLocation();
  //delete parcel
  const deleteOrderHandler = () => {
    //calling parcel delete api
    parcelDelete({
      data: {
        parcel_id: selectedOptions,
      },
      token,
    });
  };

  ///parcel delete
  useEffect(() => {
    if (parcelDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: parcelDeleteResult.data.message,
      });
      setOpen(false);
      //refect parcel info
      parcelStatusListRefetch &&
        parcelStatusListRefetch({ id: location?.state?.id, token });
    } else if (parcelDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: parcelDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelDeleteResult.isLoading });
  }, [parcelDeleteResult.isLoading]);

  //useEffect for select id
  useEffect(() => {
    if (selectedOptions.length) {
      onselect && onselect(selectedOptions);
    }
  }, [selectedOptions]);

  //search
  useEffect(() => {
    if (search) {
      function filterData(data, filterValues) {
        return data.filter((value) => {
          return filterValues
            .trim()
            .split(", ")
            .every((filterValue) => checkValue(value, filterValue));
        });
      }
      function checkValue(value, filterValue) {
        if (typeof value === "string") {
          return value.toLowerCase().includes(filterValue.toLowerCase());
        } else if (
          typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0
        ) {
          if (Array.isArray(value)) {
            return value.some((v) => checkValue(v, filterValue));
          } else {
            return Object.values(value).some((v) => checkValue(v, filterValue));
          }
        } else {
          return false;
        }
      }
      setListData(filterData(data, search));
    } else {
      setListData(data);
    }
  }, [search]);

  //list
  useEffect(() => {
    setListData(data);
  }, [data]);

  //useEffct for default cheklist
  useEffect(() => {
    setSelectedOptions([]);
  }, [afterClose]);

  return (
    <>
      <Collapse
        bordered={false}
        onChange={(value) => setShow(value)}
        className="rounded-3 bg-white border-0 p-0 text-white parcle-drawer"
        expandIcon={() => (
          <div className="text-dark">{show ? <FaMinus /> : <FaPlus />}</div>
        )}
        expandIconPosition={"end"}
        accordion
        defaultActiveKey={key}
      >
        <div className=" px-3 mb-2 align-items-center justify-content-end  gap-2 d-flex">
          <Input
            placeholder="Search Parcel"
            className="border w-75  py-1 text-dark"
            type={"text"}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          {selectedOptions.length > 0 && (
            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={deleteOrderHandler}
              placement="bottom"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <Tooltip
                overlayInnerStyle={{
                  fontSize: 10,
                }}
                placement="bottom"
                title={"Delete Selected Parcels"}
              >
                <div className="add_icon mt-2">
                  <MdOutlineDeleteSweep color="#0d6efd" />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
        </div>

        <Panel
          className=" rounded-3 "
          header={
            <div className="shadow-sm p-1 py-2 secondary-bg-color text-dark fw-bold px-3 ">
              <h6 className="m-0">{title || "Title"}</h6>
            </div>
          }
          style={{ fontSize: 18 }}
          key="1"
        >
          <ul className="nav flex-column">
            {listData.map((ele) => {
              return (
                <li
                  className="nav-link py-1 fs-13 text-dark border d-flex justify-content-between"
                  key={ele.id}
                >
                  <div className="d-flex">
                    <span>
                      <AppCheckbox
                        checked={selectedOptions.includes(ele.id)}
                        handleChange={(_, value) => {
                          if (!selectedOptions.includes(ele.id) && value) {
                            setSelectedOptions([...selectedOptions, ele.id]);
                          } else {
                            setSelectedOptions(
                              selectedOptions.filter((id) => {
                                return id !== ele.id;
                              })
                            );
                          }
                        }}
                      />
                    </span>
                    <span
                      onClick={() => {
                        setOpen(false);
                        onChange(ele);
                      }}
                      className="fs-6 cursor-pointer w-100 d-inline-block "
                    >
                      {ele.cad_property_id}{" "}
                      <span className="  fs-6">({ele.status})</span>
                    </span>
                  </div>

                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      //calling parcel delete api
                      parcelDelete({
                        data: {
                          parcel_id: [ele.id],
                        },
                        token,
                      });
                    }}
                  >
                    <MdDeleteOutline size={20} color="#0d6efd" />
                  </span>
                </li>
              );
            })}
          </ul>
        </Panel>
      </Collapse>
    </>
  );
}
