import { CloudUploadOutlined } from "@ant-design/icons";
import { message, Spin, Upload, Button } from "antd";
import { useState, React } from "react";
import "./AppFileUpload.css";

const AppFileUpload = ({
  onChange,
  upload,
  showRemoveIcon,
  multiple,
  column,
  margin,
  fileTypes,
  classStyle,
  setDocument_lists,
}) => {
  const [files, setFiles] = useState([]);
  const props = {
    action: "http://localhost:3000",
    // type: "file",
    beforeUpload: async (file, fileList) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (isJpgOrPng) {
        return true;
      } else {
        message.error(`${file.name} invalid file format`);
      }
    },

    onChange: async (e) => {
      // console.log(e, "e");
      if (e.fileList && e.fileList.length > 0) {
        const newImagesPromises = [];
        for (let i = 0; i < e.fileList.length; i++) {
          const { type } = e.fileList[i];
          const checkFileType =
            type === "image/jpeg" ||
            type === "image/png" ||
            type === "image/jpg" ||
            type === "application/pdf";
          if (checkFileType) {
            newImagesPromises.push(await toBase64(e.fileList[i].originFileObj));
          }
        }
        const newImages = await Promise.all(newImagesPromises);
        const mappedFiles = newImages.map((base64File) => ({
          file: base64File.split(",")[1],
        }));

        setDocument_lists(mappedFiles);
        console.log(mappedFiles);
      }
    },
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
        file.base64 = reader.result;
      };
      reader.onerror = (error) => reject(error);
    });
  };
  const onDrop = (e) => {
    console.log("Dropped files", e.dataTransfer.files);
  };

  return (
    <>
      <div className={`mt-${margin} col-12 col-lg-${6} file text-center`}>
        <Upload

          accept={fileTypes}
          multiple={true}
          onDrop={onDrop}
          {...props}
        >
          <Button className={`${classStyle} mt-3 excel-upload w-100`}>
            <p>
              {" "}
              <CloudUploadOutlined style={{ color: "#307BF6" }} /> Click or Drag
              to file Upload
            </p>
          </Button>
        </Upload>
      </div>
    </>
  );
};

export default AppFileUpload;
