import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import NormalTable from "../Components/AppTable/AppTable";
import AppTesting from "./AppTesting";
import Raw from "./Raw";

export default function Testing() {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  // const newData = useCallback(
  //   (val) => {
  //     setData([...data, val]);
  //   },
  //   [data]
  // );

  return (
    <div>
      <button
        onClick={() => {
          setCount(count + 1);
          // newData(count + 1);
          setData([...data, count + 1]);
        }}
      >
        TEst
      </button>
      <button
        onClick={() => {
          setCount(count - 1);
        }}
      >
        -
      </button>
      {count}
      {/* <AppTesting
        dataSource={data}
        columns={[{ id: "1", title: "TESt", dataIndex: "test" }]}
      /> */}

    </div>
  );
}
