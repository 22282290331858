import { useSelector } from "react-redux";
import { ReportsTab } from "./ReportsTab";
import "./Reports.css";

const Reports = () => {
  //labellist store
  const { labelList } = useSelector((state) => state.labelList);

  return (
    <>
      <h5 className="settings-container title-bottom">
        {labelList["cs.settings.header"]} |{" "}
        {labelList["cs.dashboard.menu.reports"]}
      </h5>
      <div>
        <ReportsTab />
      </div>
    </>
  );
};

export default Reports;
