import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import { List, Input, Tooltip } from "antd";

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOrderSearchMutation } from "../../../Redux/Services/Orders";
const { Search } = Input;

export default memo(function OrderList({ onChange, icon, title }) {
  const [activeImport, setActiveImport] = useState(false);
  const [searchOrderData, setSearchOrderData] = useState([]);
  const { token } = useSelector((state) => state.auth);
  //calling rtk query hook for import-list search options
  const [orderSearch, orderSearchResult] = useOrderSearchMutation();

  ///useEffect for search data list
  useEffect(() => {
    if (orderSearchResult.isSuccess) {
      setSearchOrderData(orderSearchResult.data.data);
    }
    if (orderSearchResult.isError) {
      setSearchOrderData([]);
    }
  }, [orderSearchResult.isLoading]);

  return (
    <div
      className={`add_icon  position-relative click-content-comp ms-auto ${
        activeImport ? "active" : ""
      }`}
    >
      {/* <AiOutlineFilePdf color="#0d6efd" /> */}
      {!activeImport ? (
        <Tooltip
          overlayInnerStyle={{
            fontSize: 10,
          }}
          placement="left"
          title={title}
        >
          <div
            className="mb-1"
            onClick={() => {
              setActiveImport(true);
            }}
          >
            {icon}
          </div>
        </Tooltip>
      ) : (
        <CloseOutlined
          onClick={() => {
            setActiveImport(false);
          }}
          color="#0d6efd"
        />
      )}
      <div className="bg-white import-click-text-content p-3 search-table">
        <Search
          placeholder="Search Order"
          loading={orderSearchResult.isLoading}
          enterButton
          onSearch={(value) => {
            orderSearch({
              data: { file_number: value },
              token,
            });
          }}
        />
        <div className="my-2 ">
          <List
            className="search-order-list"
            size="small"
            bordered
            dataSource={searchOrderData}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveImport(false);
                  onChange(item?.id);
                }}
              >
                <div className="d-flex  justify-content-between w-100">
                  {item?.file_number}
                  <DownloadOutlined
                    className=" border p-1 rounded-pill"
                    size={50}
                  />
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
});
