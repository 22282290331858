import "./ResetPassword.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "../../Layouts/Header/Header";

import MyAccount from "../MyAccount/MyAccount";

const ResetPassword = () => {
  return (
    <>
      <div className="shadow-sm update_header">
        <MyHeader />
      </div>

      <div
        style={{
          background: "url(../Assests/login/back.png)",
          minHeight: "100vh",
        }}
      >
        <div className="col-12 col-md-6 mx-auto reset_password">
          <MyAccount
            resetPass={
              window.location.pathname === "/first-time-login" ? false : true
            }
            //for firsttime login flag
            firstTimePass={
              window.location.pathname === "/first-time-login" ? true : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
