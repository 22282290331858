const generateId = (data) =>
  data.reduce((acc, current) => Math.max(acc, current.id), 0) + 1;

export const insertItem = (item, data) => {
  item.id = generateId(data);
  item.inEdit = false;
  data.unshift(item);
  return data;
};
export const getItems = (data) => {
  return data;
};

export const updateItem = (item, current) => {
  let index = current.findIndex((record) => record.id === item.id);
  current[index] = item;
  return current;
};
export const deleteItem = (item,  data) => {
  let index = data.findIndex((record) => record.id === item.id);
  data.splice(index, 1);
  return data;
};
