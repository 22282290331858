import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import { Row } from "react-bootstrap";
import {Popconfirm, Collapse } from "antd";
import { FaPlus } from "react-icons/fa";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import AppSelect from "../AppSelect/AppSelect";
import AppTable from "../AppTable/AppTable";
import { MdOutlineDelete } from "react-icons/md";
import AppNumeric from "../AppNumeric/AppNumeric";
const randomPass = require("secure-random-password");
const _ = require("lodash");
const { Panel } = Collapse;
export const MultiTaxes = ({
  afterClose,
  initailValArr,
  addFormVal,
  updateContact,
  deleteContact,
  whileEdit,
}) => {
  const initialVal = {
    tax_year: "",
    tax_rate: "",
  };
  const [active, setActive] = useState("2");
  const [values, setValues] = useState(initialVal);
  const [data, setData] = useState([]);

  const options = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i > currentYear - 5; i--) {
    options.push(i);
  }

  const { tax_year, tax_rate } = values;

  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const defaultColumns = [
    {
      title: "Tax Rate Year",
      dataIndex: "tax_year",
      key: "tax_year",
      align: "start",
      width: 300,
    },
    {
      title: "Tax Rate",
      inputType: "tax_rate",
      dataIndex: "tax_rate",
      key: "tax_rate",
      editable: true,
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 130,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            {data?.length > 1 && (
              <Popconfirm
                title="Are you sure you want to delete？"
                onConfirm={() => {
                  deleteHandler(record);
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
                placement="topLeft"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                okText="Yes"
                cancelText="No"
              >
                <span className={"ms-2 "}>
                  <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
                </span>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    // //sending delete id
    deleteContact(id);
  };

  const taxYearArr = [];
  data?.map(({ tax_year }) => {
    taxYearArr.push(Number(tax_year));
  });

  const myArrayFiltered = options?.filter((el) => {
    return !taxYearArr.includes(el);
  });

  const inputs = [
    {
      id: 2,
      name: "tax_year",
      type: "select",
      value: tax_year || "",
      column: "3",
      options: myArrayFiltered || [],
      // errorMessage: "Please select tax year!",
      // required: true,
    },

    {
      id: 3,
      name: "tax_rate",
      type: "text",
      value: tax_rate || "",
      column: "3",
      placeholder: "Enter Tax Rate",
      // errorMessage: "Please enter tax rate!",
      // required: true,
    },
  ];

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
  };

  const cancelHandler = () => {
    setValues(initialVal);
    setAddRowToggler(false);
    addFormVal(data);
    setEditId(null);
  };

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele?.temp_id || ele?.id) !== (editId?.temp_id || editId?.id);
      });
      setData([...newData, { ...values, tax_rate: tax_rate }]);
      setAddRowToggler(false);
      updateContact && updateContact({ ...values, tax_rate: tax_rate });
      setEditId(null);
      setValues(initialVal);
    } else {
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          tax_rate: tax_rate,
          temp_id: randomId,
        },
      ]);
      // while edit and ading new record directly
      setValues(initialVal);

      whileEdit({ ...values, tax_rate: tax_rate, temp_id: randomId });

      setAddRowToggler(false);
      setEditId(null);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.replace(/[^0-9.]/g, ""),
    });
  };

  //select handler
  const selectHandler = (property, value) => {
    setValues({
      ...values,
      [property]: value,
    });
  };

  useEffect(() => {
    // addFormVal(data);
    setAddRowToggler(false);
    if (initailValArr?.length > 0) {
      setData(initailValArr);
    } else {
      setData([]);
    }
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  //
  useEffect(() => {
    addFormVal(data);
  }, [data?.length]);

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" fs-18 mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            Tax Rates <span className="text-danger">*</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  rounded-2 p-2 mb-3 ">
            <div
              className={`d-flex  justify-content-between align-items-center my-2 contact-forms`}
            >
              <h6 className="fs-18 fw-bold me-2"></h6>
              {!addRowToggler && (
                <div className={`add_icon`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>
            <Row>
              <AppTable
                // sort={sort}
                pagination={false}
                dataSource={_.orderBy(data, ["tax_year"], ["desc"])}
                columns={defaultColumns}
              />
            </Row>
            {addRowToggler && (
              <div className="row bg-white py-2 shadow-sm">
                {inputs.map((input) => (
                  <>
                    {input.type === "select" && input.name === "tax_year" && (
                      <AppSelect
                        // disabled={editId?.id}
                        key={input.id}
                        {...input}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select Tax Year</option>
                            {input?.options?.map((ele) => {
                              return (
                                <option key={ele} value={ele}>
                                  {ele}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}

                    {input.type === "text" && (
                      // <AppInput
                      //   maxLength={10}
                      //   defaultSet={afterClose}
                      //   key={input.id}
                      //   {...input}
                      //   value={values[input.name]}
                      //   onChange={onChange}
                      // />
                      <AppNumeric
                        {...input}
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        maxLength={14}
                        onChange={(value) => {
                          setValues({ ...values, tax_rate: value });
                        }}
                      />
                    )}
                  </>
                ))}
                <div className="col-12 col-lg-2  mt-2  align-self-center">
                  <div className="d-flex ">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary ${
                        !tax_rate || !tax_year ? "disabled" : "btn-success"
                      } btn-sm edit-buttons`}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
};
