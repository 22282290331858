import React from "react";
import { Input, Tree } from "antd";
import "./appMultiSelect.css";
import { useState } from "react";
import { useEffect } from "react";
import { FaSearch } from "react-icons/fa";
const { TreeNode } = Tree;
export default function AppMultiSelect({
  initialVal = false,
  treeData,
  onSelectVal,
  _id,
  height = false,
  defaultSet,
  placeholder = false,
  checkable = true,
  errorMessage = false,
}) {
  const [expandedKeys, setExpandedKeys] = useState();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setselectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [data, setData] = useState(treeData);
  const [search, setSearch] = useState("");
  //function area
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };
  const onCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  };
  const onSelect = (selectedKeys) => {
    setselectedKeys(selectedKeys);
  };
  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });
  //sending values to parent component
  useEffect(() => {
    //sending only id numbers
    if (onSelectVal) {
      if (checkedKeys.length > 0) {
        onSelectVal(
          checkedKeys?.filter((val) => val.toString().match(/^[0-9]/))
        );
      } else {
        onSelectVal([]);
      }
    }
  }, [checkedKeys]);
  //useEffect for initial State
  useEffect(() => {
    setData(treeData);
    setSearch("");
  }, [treeData]);

  useEffect(() => {
    //initial values
    if (initialVal.length > 0) {
      setExpandedKeys(initialVal);
      setCheckedKeys(initialVal);
    } else if (initialVal) {
      setCheckedKeys(initialVal);
      setExpandedKeys(["Select All"]);
    }
  }, [data, defaultSet]);

  //create search handler
  const inputHandler = (e) => {
    setSearch(e.target.value);
  };

  //useEffect for search globaly
  useEffect(() => {
    if (search === "") {
      setData(treeData);
    } else {
      function filterData(data, filterValues) {
        return data.filter((value) => {
          return filterValues
            .trim()
            .split(", ")
            .every((filterValue) => checkValue(value, filterValue));
        });
      }

      function checkValue(value, filterValue) {
        if (typeof value === "string") {
          return value.toLowerCase().includes(filterValue.toLowerCase());
        } else if (
          typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0
        ) {
          if (Array.isArray(value)) {
            return value.some((v) => checkValue(v, filterValue));
          } else {
            return Object.values(value).some((v) => checkValue(v, filterValue));
          }
        } else {
          return false;
        }
      }

      setData(filterData(treeData[0].children, search));
    }
  }, [search]);

  return (
    <div className="border p-1 pt-0 mt-1">
      <div className="treeselect-input-box p-1 my-2 position-relative">
        <form action="">
          <input
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            value={search || ""}
            onChange={inputHandler}
            type="text"
            name="multislect-tree-selct"
            placeholder={placeholder}
            className=" py-0  w-100 ms-3 treeselect-input"
          />
          <div className="search-icon-tree ">
            <FaSearch color="lightgray" />
          </div>
        </form>
      </div>
      {data[0]?.children.length == 0 ? (
        <>
          <div className="text-center">
            <span className="text-danger ">No {errorMessage} list found!</span>
          </div>
        </>
      ) : (
        <div className="treeSelect " style={{ height: height }} id={_id}>
          <Tree
            checkable={checkable}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
          >
            {renderTreeNodes(data || [{ title: "", children: "" }])}
          </Tree>
        </div>
      )}
    </div>
  );
}
