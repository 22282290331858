import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const branch = createApi({
  reducerPath: "branch",
  tagTypes: ["branch"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    branchList: builder.query({
      query: (token) => ({
        url: `/branch-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["branch"],
    }),
    editBranch: builder.query({
      query: (data) => ({
        url: `/edit-branch/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateBranch: builder.mutation({
      query: (data) => ({
        url: `/update-branch/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["branch"],
    }),
    updateBranchStatus: builder.mutation({
      query: (data) => ({
        url: `/update-branch-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["branch"],
    }),
    addBranch: builder.mutation({
      query: (data) => ({
        url: `/add-branch`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["branch"],
    }),
    updateBranchContacts: builder.mutation({
      query: (data) => ({
        url: `/update-branch-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteBranchContacts: builder.mutation({
      query: (data) => ({
        url: `/delete-branch-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

    addBranchContact: builder.mutation({
      query: (data) => ({
        url: `/add-branch-contact`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

    branchOptions: builder.mutation({
      query: (data) => ({
        url: `/branch-options/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    branchProductType: builder.mutation({
      query: (data) => ({
        url: `/branch-product-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    userIdBaseBranch: builder.mutation({
      query: (data) => ({
        url: `/user-branch-id/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useBranchListQuery,
  useEditBranchQuery,
  useUpdateBranchMutation,
  useAddBranchMutation,
  useBranchOptionsMutation,
  useUpdateBranchStatusMutation,
  useUpdateBranchContactsMutation,
  useDeleteBranchContactsMutation,
  useAddBranchContactMutation,
  useBranchProductTypeMutation,
  useUserIdBaseBranchMutation,
} = branch;
