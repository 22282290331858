import { createSlice } from "@reduxjs/toolkit";
const CryptoJS = require("crypto-js");
//cookie get item variable
export function getCookie(name) {
  const cookies = document.cookie
    .split(";")
    .map((ele) => ele.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
  return cookies[name];
}
const user = getCookie("user");

//initial state
const currentUser = getCookie("user") ? getCookie("user") : [];

var userInfo = getCookie("user")
  ? CryptoJS.AES.decrypt(
      currentUser,
      "$2b$10$hcPg5zgrb0tt8cg9UPgT1ex7c4VTc22bALT8pjNcfNMRbXZToEI46"
    )
  : null;
userInfo = getCookie("user") ? userInfo.toString(CryptoJS.enc.Utf8) : null;
userInfo = getCookie("user") ? JSON.parse(userInfo) : null;

const authReducer = createSlice({
  name: "auth",
  initialState: {
    user: userInfo?.user,
    token: userInfo?.token,
    //user is not updating with cookies directly for that created below prperty as dependancy
    quickUserUpdate: {},
  },
  reducers: {
    userLoginAction: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    userDetailsQuickUpdateAction: (state, action) => {
      state.quickUserUpdate = action.payload;
    },
  },
});

export const { userLoginAction, userDetailsQuickUpdateAction } =
  authReducer.actions;

export default authReducer.reducer;
