import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const hoamgmt = createApi({
  reducerPath: "hoamgmt",
  tagTypes: ["hoamgmt"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addHoaMgmt: builder.mutation({
      query: (data) => ({
        url: `/add-hoa-collector`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoamgmt"],
    }),
    HoaMgmtlist: builder.query({
      query: (token) => ({
        url: `/hoa-collectors-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoamgmt"],
    }),
    HoaMgmtEdit: builder.query({
      query: (data) => ({
        url: `/edit-hoa-collector/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateHoaMgmt: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-collector/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoamgmt"],
    }),
    updateHoaMgmtStatus: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-collector-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoamgmt"],
    }),
    commets_list: builder.query({
      query: (data) => ({
        url: `/hoa_comments/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    hoaSateList: builder.mutation({
      query: (data) => ({
        url: `/hoa-collector-bases-state/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    hoaCountyList: builder.mutation({
      query: (data) => ({
        url: `/hoa-collector-bases-county/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    hoaReadOnlyList: builder.query({
      query: (token) => ({
        url: `/hoa-read-only-comment-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    hoaDisplayOnCertList: builder.query({
      query: (token) => ({
        url: `/hoa-display-cert-comment-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const {
  useAddHoaMgmtMutation,
  useHoaMgmtlistQuery,
  useCommets_listQuery,
  useUpdateHoaMgmtMutation,
  useHoaMgmtEditQuery,
  useUpdateHoaMgmtStatusMutation,
  useHoaCountyListMutation,
  useHoaSateListMutation,
  useHoaDisplayOnCertListQuery,
  useHoaReadOnlyListQuery,
} = hoamgmt;
