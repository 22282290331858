import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import "./appTextEditor.css";
export default function AppTextEditor({ value = "", onChange }) {
  return (
    <>
      <DefaultEditor
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </>
  );
}
