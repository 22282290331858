import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FaSortAmountDown,
  FaSortAmountUp,
  FaSearch,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Row} from "react-bootstrap";
import { Button, Input, notification } from "antd";
import AppSelect from "../../../../../Components/AppSelect/AppSelect";
import { useGetCountyListMutation } from "../../../../../Redux/Services/county";
import { scrollComp } from "../../../../../Controllers/Functions";
import "./PropertyBrowser.css";
import AppSpinner from "../../../../../Components/AppSpinner/AppSpinner";
import {
  useAddPropertyCadParcelMutation,
  useSearchCadListPropertyMutation,
} from "../../../../../Redux/Services/propertyBrowser";
import SearchColumns from "../../../../../Components/SearchColumns";
import { useLocation } from "react-router-dom";
import {
  useCadFilterListMutation,
} from "../../../../../Redux/Services/Cad";
import { useContext } from "react";
import { parcelContext } from "../../OrderEditor";
import { AiOutlineClose } from "react-icons/ai";
import MyTable from "../../../../../Components/AppTable/MyTable";
import {
  useAutoConflictsStatusUpdateMutation,
  useValidateConflictsMutation,
} from "../../../../../Redux/Services/Orders";
import { GlobalContext } from "../../../../../App";

export default function PropertyBrowser({ refresh, setKey }) {
  const globaledata = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = globaledata.state;

  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController] = controller;
  const [allrecord, setAllrecords] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setGetError] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [stateName, setStateName] = useState("");

  const { state } = useLocation();
  const orderId = state?.id;

  //update conflicts info
  const [validateConflicts, validateConflictsResult] =
    useValidateConflictsMutation();

  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoStatusResult] =
    useAutoConflictsStatusUpdateMutation();

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (validateConflictsResult.isSuccess) {
      //auto update status
      autoStatusUpdate({ data: { order_id: orderId }, token });
    }
    if (validateConflictsResult.isError) {
      setGetError(
        validateConflictsResult.error?.data?.message ||
          validateConflictsResult.error.error ||
          validateConflictsResult.error.data?.msg.sqlMessage
      );
    }
    //global spinner
  }, [validateConflictsResult.isLoading]);

  // useEffect for auto status update
  useEffect(() => {
    if (autoStatusResult.isSuccess) {
      setKey("summary");
      //auto update status
      parcelController.storeCertificateInDbFun &&
        parcelController.storeCertificateInDbFun();
    }
    if (autoStatusResult.isError) {
      setGetError(
        autoStatusResult.error?.data?.message ||
          autoStatusResult.error.error ||
          autoStatusResult.error.data?.msg.sqlMessage
      );
    }
    //global spinner
  }, [autoStatusResult.isLoading]);

  //NewParcelAddButtonData
  const [NewParcelAddButtonData, setNewParcelAddButtonData] = useState(false);
  const [countyOptions, setCountyOptions] = useState([
    { id: "", county_name: "" },
  ]);
  //destructure the order data
  const { county_id, state_id, county_name, state_name } =
    parcelController.orderData;
  const initialState = {
    county_id: "",
    state_id: "",
  };

  //calling rtk query hook for geting search list
  const [searchCad, searchCadResult] = useSearchCadListPropertyMutation();

  const [values, setValues] = useState(initialState);

  //calling redux
  const { token } = useSelector((state) => state.auth);

  //filter
  const [propertCadFilter, propertCadFilterResult] = useCadFilterListMutation();

  useEffect(() => {
    if (propertCadFilterResult.isSuccess) {
      if (propertCadFilterResult.data?.data?.cad_filter_result?.length) {
        setPageCount(propertCadFilterResult.data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }
      const newData = propertCadFilterResult.data?.data?.cad_filter_result?.map(
        (
          {
            cad_property_id,
            id,
            owner_name,
            cad_geo_id,
            subdivision,
            situs_address,
            legal,
            ...ele
          },
          index
        ) => {
          return {
            ...ele,
            cad_property_id: cad_property_id || "NA",
            cad_geo_id: cad_geo_id || "NA",
            situs_address: situs_address || "NA",
            subdivision: subdivision || "NA",
            owner_name: owner_name || "NA",
            legal: legal || "NA",
            id,
            key: id,
          };
        }
      );

      setData(newData);
    } else {
      setData([]);
    }
  }, [propertCadFilterResult.isLoading]);

  useEffect(() => {
    if (searchCadResult.isSuccess) {
      // setPageCount(searchCadResult.data?.data?.page_count);
      // scrollComp("table-data");
      if (searchCadResult.data?.data?.cad_list.length) {
        setPageCount(searchCadResult.data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }
      const newData = searchCadResult.data?.data?.cad_list.map(
        ({
          state,
          county,
          legal,
          owner_name,
          subdivision,
          tax_year,
          cad_property_id,
          cad_geo_id,
          situs_address,
          id,
          ...ele
        }) => {
          return {
            ...ele,
            state: state || "NA",
            county: county || "NA",
            legal: legal || "NA",
            tax_year: tax_year || "NA",
            situs_address: situs_address || "NA",
            cad_property_id: cad_property_id || "NA",
            cad_geo_id: cad_geo_id || "NA",
            owner_name: owner_name || "NA",
            subdivision: subdivision || "NA",
            id,
            key: id,
          };
        }
      );
      setData(newData);
    }
    setGlobalVal({ ...globalVal, loader: searchCadResult.isLoading });
  }, [searchCadResult.isLoading]);

  // //list
  // const PropertyCadListApi = usePropertyCadListQuery(token);
  // useEffect(() => {
  //   if (PropertyCadListApi.isSuccess) {
  //     const newData = PropertyCadListApi.data?.data.map(
  //       (
  //         {
  //           tax_id,
  //           id,
  //           owner_name,
  //           cad_geo_id,
  //           subdivision,
  //           situs_address,
  //           legal,
  //           ...ele
  //         },
  //         index
  //       ) => {
  //         return {
  //           ...ele,
  //           tax_id: tax_id || "NA",
  //           cad_geo_id: cad_geo_id || "NA",
  //           situs_address: situs_address || "NA",
  //           subdivision: subdivision || "NA",
  //           owner_name: owner_name || "NA",
  //           legal: legal || "NA",
  //           id,
  //           key: id,
  //         };
  //       }
  //     );

  //     setData(newData);
  //   } else {
  //     setData([]);
  //   }
  // }, [PropertyCadListApi.isFetching]);

  //adding
  const [addPropertyCadParcel, addPropertyCadParcelResult] =
    useAddPropertyCadParcelMutation();

  useEffect(() => {
    if (addPropertyCadParcelResult.isSuccess) {
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addPropertyCadParcelResult.data?.message,
      });

      //validate the order data
      validateConflicts({
        data: { order_id: orderId },
        token,
      });
    }
    if (addPropertyCadParcelResult.error) {
      setGetError(
        addPropertyCadParcelResult.error?.data?.error ||
          addPropertyCadParcelResult.error?.data?.message ||
          (addPropertyCadParcelResult.error.error && "server unreachable!") ||
          addPropertyCadParcelResult.error.data?.msg.sqlMessage
      );
    }
  }, [addPropertyCadParcelResult.isLoading]);

  const selectHandler = (property, value, arrtibute) => {
    setSearch("");
    // clearStatus();
    setAllrecords(false);
    if (property === "state_id") {
      if (value) {
        getCountyList({ state_id: { state_id: value }, token });
        setValues({
          ...values,
          state_id: value,
          state_name: arrtibute?.getAttribute("data_name"),
        });
        //req for filter data
        propertCadFilter({
          data: {
            state: arrtibute?.getAttribute("data_name"),
            page_no: 1,
            count: 200,
          },
          token,
        });
        setCurrentPage(1);
        setStateName(arrtibute?.getAttribute("data_name"));
      }
      if (!value) {
        setCountyOptions([]);
        setValues({
          ...values,
          state_id: value,
          county_id: "",
          county_name: "",
        });
        // PropertyCadListApi.refetch();
      }
    }
    if (property === "county_id") {
      if (value) {
        setCurrentPage(1);
        //req for filter data
        propertCadFilter({
          data: {
            county: arrtibute?.getAttribute("data_name"),
            state: state_name,
            page_no: 1,
            count: 200,
          },
          token,
        });
        setValues({
          ...values,
          county_id: value,
          county_name: arrtibute?.getAttribute("data_name"),
        });
      } else {
        setValues({ ...values, county_id: value, county_name: "" });
        // propertCadFilter({
        //   data: { state: stateName },
        //   token,
        // });
        setData([]);
        setPageCount(0);
        setCurrentPage(1);
      }
    }
  };
  //calling from store to the county
  const { stateList } = useSelector((state) => state.stateList);
  //calling rtk query hook for geting County list

  const [getCountyList, getCountyListResult] = useGetCountyListMutation(token);

  useEffect(() => {
    if (getCountyListResult.isSuccess) {
      setCountyOptions(getCountyListResult.data?.data);
    }

    if (getCountyListResult.error) {
      scrollComp("error");
      setGetError(
        getCountyListResult.error?.data?.message ||
          getCountyListResult.error.error ||
          getCountyListResult.error.data?.msg.sqlMessage
      );
    }
  }, [getCountyListResult.isLoading]);

  useEffect(() => {
    if (!stateName) {
      // PropertyCadListApi.refetch();
      if (!values.county_id) {
        setValues({ ...initialState, county_id, county_name, state_name });
        setCountyOptions([]);
        getCountyList({ state_id: { state_id: state_id }, token });
        propertCadFilter({
          data: {
            county: county_name,
            state: state_name,
            page_no: 1,
            count: 200,
          },
          token,
        });
      }
    }
  }, [refresh]);

  const columns = [
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Tax Year{" "}
            {sortOrder === "ascend" && sortColumn?.dataIndex === "tax_year" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "tax_year" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "tax_year",
      width: 200,
      align: "center",
      sorter: (a, b) => a.tax_year.localeCompare(b.tax_year),
      ...SearchColumns("tax_year", "Tax Year", allrecord),
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Cad Property ID{" "}
            {sortOrder === "ascend" &&
            sortColumn?.dataIndex === "cad_property_id" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "cad_property_id" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },

      dataIndex: "cad_property_id",
      key: "cad_property_id",
      sorter: (a, b) => a.cad_property_id.localeCompare(b.cad_property_id),
      editable: true,
      width: 160,
      ...SearchColumns("cad_property_id", "Cad Property Id", allrecord),
      align: "center",
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Geo ID{" "}
            {sortOrder === "ascend" &&
            sortColumn?.dataIndex === "cad_geo_id" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "cad_geo_id" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "cad_geo_id",
      width: 200,
      align: "center",
      sorter: (a, b) => a.cad_geo_id.localeCompare(b.cad_geo_id),
      ...SearchColumns("cad_geo_id", "Geo ID", allrecord),
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            County{" "}
            {sortOrder === "ascend" && sortColumn?.dataIndex === "county" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "county" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "county",
      width: 200,
      align: "center",
      sorter: (a, b) => a.county.localeCompare(b.county),
      ...SearchColumns("county", "County Name", allrecord),
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Address{" "}
            {sortOrder === "ascend" &&
            sortColumn?.dataIndex === "situs_address" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "situs_address" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "situs_address",
      width: 200,
      align: "center",
      sorter: (a, b) => a.situs_address.localeCompare(b.situs_address),
      ...SearchColumns("situs_address", "Address", allrecord),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Subdivision{" "}
            {sortOrder === "ascend" &&
            sortColumn?.dataIndex === "subdivision" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "subdivision" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "subdivision",
      width: 200,
      align: "center",
      sorter: (a, b) => a.subdivision.localeCompare(b.subdivision),
      ...SearchColumns("subdivision", "Subdivision", allrecord),
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Owner{" "}
            {sortOrder === "ascend" &&
            sortColumn?.dataIndex === "owner_name" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" &&
              sortColumn?.dataIndex === "owner_name" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "owner_name",
      width: 200,
      align: "center",
      sorter: (a, b) => a.owner_name.localeCompare(b.owner_name),
      ...SearchColumns("owner_name", "Owner", allrecord),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: ({ sortOrder, sortColumn }) => {
        return (
          <span>
            Legal{" "}
            {sortOrder === "ascend" && sortColumn?.dataIndex === "legal" ? (
              <FaSortAmountUp className="text-info" />
            ) : sortOrder === "descend" && sortColumn?.dataIndex === "legal" ? (
              <FaSortAmountDown className="text-info" />
            ) : (
              <FaSortAmountUp />
            )}{" "}
          </span>
        );
      },
      dataIndex: "legal",
      width: 200,
      align: "center",
      sorter: (a, b) => a.legal.localeCompare(b.legal),
      ...SearchColumns("legal", "Legal", allrecord),
    },
  ];

  const inputs = [
    {
      id: 1,
      name: "state_id2",
      type: "select",
      column: "3",
      margin: "1",
      value: values.state_id || "",
      placeholder: "Select County Name",
      errorMessage: "Please select county!",
      // label: "County",
      options: stateList,
    },
    {
      id: 15,
      name: "county_id",
      type: "select",
      column: "3",
      margin: "1",
      value: values.county_id || "",
      placeholder: "Select County Name",
      errorMessage: "Please select county!",
      // label: "County",
      options: countyOptions,
    },
  ];

  //row selection
  const rowHandler = useCallback((value) => {
    if (value.length > 0) {
      setNewParcelAddButtonData(value);
    } else {
      setNewParcelAddButtonData(false);
    }
  }, []);

  const cols = useMemo(() => columns, [allrecord]);
  const data1 = useMemo(() => data, [data]);
  const onpageChenge = useCallback((value, pageSi) => {
    setCurrentPage(value);
    // setPageSize(pageSi);

    !allrecord &&
      !search &&
      propertCadFilter({
        data: {
          county: county_name,
          state: state_name,
          page_no: value,
          count: pageSi,
        },
        token,
      });
    // console.log(value);
  }, []);

  let loader =
    addPropertyCadParcelResult.isLoading || propertCadFilterResult.isLoading;
  const loadingChnage = useMemo(() => loader, [loader]);
  return (
    <>
      {getCountyListResult.isLoading && <AppSpinner />}
      <div className="row align-items-center">
        {NewParcelAddButtonData && (
          <div className=" mt-1 mb-1 col-md-1">
            <Button
              onClick={() => {
                //sending req fo add parcels
                addPropertyCadParcel({
                  data: {
                    order_id: orderId,
                    cad_ids: NewParcelAddButtonData.map(({ id }) => {
                      return id;
                    }),
                  },
                  token,
                });
              }}
              className="text-primary add-property"
            >
              <span className="fw-bold ">+</span> Add
            </Button>
          </div>
        )}

        <div className="col-12 col-md-1">
          {allrecord ? (
            <Button
              onClick={() => {
                setSearch("");
                setAllrecords(false);
                setCurrentPage(1);
                propertCadFilter({
                  data: {
                    county: county_name,
                    state: state_name,
                    page_no: 1,
                    count: 200,
                  },
                  token,
                });
              }}
              type="primary"
              htmlType="button"
              size="small"
            >
              Cancel
            </Button>
          ) : (
            <Button
              onClick={() => {
                setAllrecords(true);
                setSearch("");
                setCurrentPage(1);
                propertCadFilter({
                  data: {
                    county: county_name,
                    state: state_name,
                    page_no: "",
                    count: "",
                  },
                  token,
                });
              }}
              type="primary"
              htmlType="button"
              size="small"
            >
              All Records
            </Button>
          )}
        </div>
        <div className="col-12 col-md-6 ms-auto gap-2 d-flex justify-content-end">
          {inputs.map((input) => (
            <>
              {/* //appSelcet for county name  */}
              {input.type === "select" && input.name === "county_id" && (
                <AppSelect
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  classStyle="border w-100 p-1 my-2"
                  options={
                    <>
                      {input.options.map((ele) => {
                        return (
                          <option
                            data_name={ele.county_name}
                            key={ele.id}
                            value={ele.id}
                          >
                            {ele.county_name}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}
              {input.type === "select" && input.name === "state_id" && (
                <AppSelect
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  classStyle="border w-100 p-1 my-2"
                  options={
                    <>
                      <option value={""}>Select State</option>
                      {input.options.map((ele) => {
                        return (
                          <option
                            data_name={ele.state_name}
                            key={ele.id}
                            value={ele.id}
                          >
                            {ele.state_name}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}
            </>
          ))}
        </div>

        <div className=" col-3 col-lg-3 d-flex">
          <Input
            placeholder="Search here"
            className="global-search py-0 pe-0"
            value={search}
            onKeyUp={() => {
              if (!search) {
                setAllrecords(false);
                setCurrentPage(1);
                propertCadFilter({
                  data: {
                    state: state_name,
                    page_no: 1,
                    count: 200,
                  },
                  token,
                });
                setValues({ ...values, state_id: state_id });
              }
            }}
            onChange={(e) => setSearch(e.target.value)}
            suffix={
              <>
                {" "}
                {search && (
                  <Button
                    className="m-0"
                    onClick={() => {
                      if (search) {
                        setAllrecords(false);
                        setCurrentPage(1);
                        propertCadFilter({
                          data: {
                            state: state_name,
                            page_no: 1,
                            count: 200,
                          },
                          token,
                        });
                        setValues({ ...values, state_id: state_id });
                        setSearch("");
                      }
                    }}
                    type="ghost"
                  >
                    <AiOutlineClose />
                  </Button>
                )}
                <Button
                  type="primary"
                  disabled={!search || searchCadResult.isLoading}
                  onClick={() => {
                    // if  search avail
                    if (search) {
                      setAllrecords(false);
                      setCurrentPage(1);
                      searchCad({
                        data: {
                          cad_search: search,
                          state_name: values.state_name,
                          county_name: values.county_name,
                        },
                        token,
                      });
                    }
                  }}
                >
                  <FaSearch color="#fff" />
                </Button>
              </>
            }
          />
        </div>
      </div>

      <div style={{ clear: "both" }}></div>
      <Row>
        <MyTable
          loading={loadingChnage}
          // search={search}
          columns={cols}
          dataSource={data1}
          rowSelection={true}
          onChange={rowHandler}
          pageCount={!allrecord && pageCount}
          currentPage={!allrecord && currentPage}
          defaultPageSize="200"
          onpage={!allrecord && onpageChenge}
        />
      </Row>
    </>
  );
}
