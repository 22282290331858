import { useState } from "react";
import CurrencyFormat from "react-currency-format";
const _ = require("lodash");
//1. functon for base64 converter
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

//scrollToView

export const scrollComp = (COMP) => {
  document.getElementById(COMP).scrollIntoView({ behavior: "smooth" });
};

export const removeBlank = (values) => {
  for (const key in values) {
    if (values[key] === "" || values[key] === null) {
      delete values[key];
    }
  }
};

export const removeArrayBlank = (values) => {
  values?.map((ele) => {
    for (const key in ele) {
      if (ele[key] === "" || ele[key] === null || ele[key] === undefined) {
        delete ele[key];
      }
    }
  });
};

export const filterArray = (arr1, arr2) => {
  const filtered = arr1.filter((el) => {
    return arr2.indexOf(el) === -1;
  });
  return filtered;
};

export const asendingList = (data, name) => {
  return _.orderBy(data, [(item) => _.get(item, name).toLowerCase()]);
};
///retun id
export const returnArryObjID = (data, name) => {
  const res = [];
  data.map((ele) => {
    res.push(ele[name]);
  });
  return res;
};

export function convertDateFormat(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function dateFun(format) {
  let date = new Date();
  switch (format) {
    case "date":
      return convertDateFormat(date);
    case "datetime":
      return (
        convertDateFormat(date) +
        "-" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    default:
      return date;
  }
}

//price format
export function priceFormater(value, showNa = false, toFixedVal = true) {
  if (!value) {
    return showNa ? "NA" : "--";
  } else {
    return (
      <CurrencyFormat
        value={
          toFixedVal
            ? Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, "")).toFixed(2)
            : Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, ""))
        }
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
}
//price format with excel
export function priceDecimalCoverter(value) {
  if (!value) {
    return " ";
  } else {
    return `$${Number(value?.replace(/[|&;$%@"<>()+,]/g, "")).toFixed(2)}`;
  }
}

//price format
export function mobileFormat(value) {
  if (!value) {
    return "--";
  } else {
    return (
      <CurrencyFormat
        displayType={"text"}
        value={value?.replace(/[|&;$%@"<>()+,]/g, "").slice(1)}
        format={"(###) ###-####"}
      />
    );
  }
}
//perscent format
export function percentFormat(value) {
  if (!value) {
    return "NA";
  } else {
    return (
      <CurrencyFormat
        displayType={"text"}
        value={Number(value?.replace(/[|&;$%@"<>()+,]/g, ""))}
        suffix="%"
      />
    );
  }
}

//check if values is changed
export function compareChange(before = [], obj = {}, keyname) {
  let findexIndexVal = before.findIndex((item) => item?.id === obj?.id);
  let obj1 = before[findexIndexVal];
  let result = [];

  if (findexIndexVal !== -1) {
    for (const key in obj1) {
      if (!(obj[key] === obj1[key])) {
        result.push(key);
      }
    }
  }

  return result.includes(keyname);
}

// export function checkObjValidValue(value = []) {
//   value.forEach((ele) => {
//     if (Object.keys(ele).length === 1) {
//       let index = value.findIndex(ele)
//     } else {
//       return true;
//     }
//   });
// }
