import { createSlice } from "@reduxjs/toolkit";

 const themeChanger = createSlice({
    name: "theme",
    initialState: {
       dark:false
    },
    reducers: {
      darkMode: (state, action) => {
        state.dark= action.payload
      },
    },
  });
  
  export const { darkMode } = themeChanger.actions;
  
  export default themeChanger.reducer;