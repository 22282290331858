import { Form, Input, Table} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  required,
  children,
  options,
  dataIndex,
  record,
  type,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const handleChange = async (e) => {
    const values = await form.validateFields();
    // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    values[Object.keys(values)] = e.target.value;
    if (type === "select") {
      // let id = record["id"] || record["tempId"];
    }
    // console.log(values);
    handleSave(
      {
        ...record,
        ...values,
      },
      type
    );
  };

  //selet
  const onBlur = async () => {
    try {
      const values = await form.validateFields();
      //   if (type === "select") {
      //     values[Object.keys(values)[0]] = values[Object.keys(values)[0]]?.value;
      //   }
      toggleEdit();
      if (type !== "select") {
        handleSave(
          {
            ...record,
            ...values,
          },
          type
        );
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const inputHandler = async (e) => {
    try {
      if (record?.dataIndex !== "Total") {
        const values = await form.validateFields();
        values[Object.keys(values)] = e.target.value;

        handleSave({
          ...record,
          ...values,
        });
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const priceHandler = async (value1) => {
    try {
      if (record?.dataIndex !== "Total") {
        const { formattedValue } = value1;
        // values[Object.keys(values)] = formattedValue;
        let values = { [dataIndex]: formattedValue };
        await handleSave({
          ...record,
          ...values,
        });
      }
    } catch (error) {
      console.log("Save failed:", error);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: required || false,
            message: `${title} is required.`,
          },
        ]}
      >
        {type === "select" ? (
          <select
            className="text-primary p-1 border rounded-3"
            ref={inputRef}
            // onPressEnter={save}
            value={record[dataIndex] || ""}
            onBlur={onBlur}
            onChange={handleChange}
          >
            <option value={record[dataIndex] || ""}>
              {record[dataIndex] || `Select ${title}`}
            </option>
            {options.map((ele, i) => {
              return (
                <option key={i} data_name={ele.data_name} value={ele.id}>
                  {ele.name}
                </option>
              );
            })}
          </select>
        ) : type === "number" ? (
          <NumericFormat
            onBlur={onBlur}
            getInputRef={inputRef}
            maxLength={50}
            value={record[dataIndex] || ""}
            thousandSeparator={true}
            decimalScale={6}
            prefix="$"
            type="text"
            style={{width:100}}
            className="border rounded-3 py-0 fs-12 text-primary"
            onValueChange={priceHandler}
          />
        ) : type === "date" ? (
          <input
            className="W-100"
            type={"date"}
            ref={inputRef}
            onBlur={onBlur}
            onChange={inputHandler}
          />
        ) : type === "textarea" ? (
          <textarea
            className="W-100 mx-auto"
            ref={inputRef}
            onBlur={onBlur}
            onChange={inputHandler}
          />
        ) : (
          <Input
            type="text"
            className="text-primary"
            value={record[dataIndex] || ""}
            onChange={inputHandler}
            onBlur={onBlur}
            ref={inputRef}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function InlineTable({
  cols = [],
  data = [],
  setData,
  onEdit = false,
  summary = false,
  showHeader = true,
}) {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row, type) => {
    const newData = [...data];
    const index = newData.findIndex(
      (item) => (row?.id || row?.tempId) === (item?.id || item?.tempId)
    );
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    let id = row?.id || row?.tempId;
    //sending editable data's
    onEdit && onEdit(row, id, type);
    setData(newData);
  };

  const columns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col?.editable(record) || undefined,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col?.type || "text",
        options: col?.options || [],
        required: col?.required || false,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        pagination={false}
        showHeader={showHeader}
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={data}
        columns={columns}
        summary={summary || undefined}
      />
    </div>
  );
}

export default InlineTable;
