import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const orders = createApi({
  reducerPath: "orders",
  tagTypes: ["orders"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    orderuserProductTypeList: builder.mutation({
      query: (data) => ({
        url: `/order-user-product-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    ordercustomerbranchList: builder.mutation({
      query: (data) => ({
        url: `/order-customer-branch-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    ordercustomerList: builder.mutation({
      query: (data) => ({
        url: `/order-customer-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    ordercloserList: builder.mutation({
      query: (data) => ({
        url: `/order-closer-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    orderReadOnlyCommentList: builder.query({
      query: (token) => ({
        url: `/order-read-only-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderDisplayCertCommentList: builder.query({
      query: (token) => ({
        url: `/order-display-cert-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: `/add-order`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["orders"],
    }),
    examinerOrder: builder.query({
      query: (token) => ({
        url: `/order-examiner-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderEditor: builder.query({
      query: (data) => ({
        url: `/edit-order/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderEditorPost: builder.mutation({
      query: (data) => ({
        url: `/edit-order/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    ordersList: builder.query({
      query: (token) => ({
        url: `/order-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-order/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-order-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: { status_id: data.status_id },
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderSearch: builder.mutation({
      query: (data) => ({
        url: `/search-order-file-number`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderResubmit: builder.mutation({
      query: (data) => ({
        url: `/resubmit-order/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderExaminerUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-examiner`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderProductListPricing: builder.mutation({
      query: (data) => ({
        url: `/order-branch-wise-product-pricing`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderProductlistA: builder.mutation({
      query: (data) => ({
        url: `/order-user-product-pricing-list`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteOrder: builder.mutation({
      query: (data) => ({
        url: `/soft-delete-order/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //delete multiple orders
    deleteMultipleOrder: builder.mutation({
      query: (data) => ({
        url: `/delete-multiple-order`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    auditLogsList: builder.mutation({
      query: (data) => ({
        url: `/orders-audit-log-list/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // order docs list
    orderDocsList: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-document-list/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    // get order Doc List
    Conflicts: builder.mutation({
      query: (data) => ({
        url: `/conflicts-error-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // conflicts
    validateConflicts: builder.mutation({
      query: (data) => ({
        url: `/validation-parcel-order`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // auto update status
    autoConflictsStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `/auto-update-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // conflicts
    reviewConflicts: builder.mutation({
      query: (data) => ({
        url: `/update-conflicts/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // orders
    orderCertificate: builder.mutation({
      query: (data) => ({
        url: `/certificate-json/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //update supporting documents
    updateCertifciateDoc: builder.mutation({
      query: ({ id, token, data }) => ({
        url: `/update-order-supporting-documents/${id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //update supporting documents 2
    uploadCertificate: builder.mutation({
      query: ({ order_id, token, data }) => ({
        url: `/add-update-certificate/${order_id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    // orders
    orderClone: builder.mutation({
      query: ({ id, token, data }) => ({
        url: `/order-clone/${id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //parcelwise orderlist
    parcelHoaOrderList: builder.mutation({
      query: ({ token }) => ({
        url: `/parcelwise-hoa-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //orderlis History
    orderHistoryListLogs: builder.mutation({
      query: ({ token, id }) => ({
        url: `/orders-audit-log-list/${id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //send email certificate
    sendEmailCertificate: builder.mutation({
      query: ({ token, order_id , data}) => ({
        url: `/email-tax-certificate/${order_id}`,
        mode: "cors",
        method: "POST",
        body:data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),

    //get email of closer
    getCloserEmail: builder.mutation({
      query: ({ token, order_id }) => ({
        url: `/order-closer-email/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //order download certificate logs insert api
    certificateDownloadLogs: builder.mutation({
      query: ({ token, order_id }) => ({
        url: `/certificate-download/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const {
  useAddOrderMutation,
  useOrderuserProductTypeListMutation,
  useOrdercustomerbranchListMutation,
  useOrdercustomerListMutation,
  useOrdercloserListMutation,
  useOrderReadOnlyCommentListQuery,
  useOrderDisplayCertCommentListQuery,
  useExaminerOrderQuery,
  useOrderEditorQuery,
  useOrdersListQuery,
  useOrderUpdateMutation,
  useOrderStatusUpdateMutation,
  useOrderSearchMutation,
  useOrderEditorPostMutation,
  useOrderResubmitMutation,
  useOrderExaminerUpdateMutation,
  useOrderProductListPricingMutation,
  useOrderProductlistAMutation,
  useDeleteOrderMutation,
  useAuditLogsListMutation,
  useConflictsMutation,
  useValidateConflictsMutation,
  useReviewConflictsMutation,
  useOrderCertificateMutation,
  useDeleteMultipleOrderMutation,
  useOrderCloneMutation,
  useUpdateCertifciateDocMutation,
  useAutoConflictsStatusUpdateMutation,
  useParcelHoaOrderListMutation,
  useOrderHistoryListLogsMutation,
  useUploadCertificateMutation,
  useOrderDocsListMutation,
  useSendEmailCertificateMutation,
  useGetCloserEmailMutation,
  useCertificateDownloadLogsMutation
} = orders;
