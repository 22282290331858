import { Badge, Button, notification, Popconfirm, Switch, Tooltip } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineAudit } from "react-icons/ai";
import {
  MdDeleteOutline,
  MdEdit,
  MdOutlineAssignment,
  MdOutlineAssignmentInd,
  MdSystemUpdateAlt,
} from "react-icons/md";
import { FaRegClone, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppModal from "../../../Components/AppModal/AppModal";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import AppTable from "../../../Components/AppTable/AppTable";
import SearchColumns from "../../../Components/SearchColumns";
import {
  useDeleteOrderMutation,
  useOrderExaminerUpdateMutation,
  useOrderHistoryListLogsMutation,
  useOrderResubmitMutation,
  useOrdersListQuery,
  useOrderuserProductTypeListMutation,
  useParcelHoaOrderListMutation,
} from "../../../Redux/Services/Orders";
import { useOrderStatusQuery } from "../../../Redux/Services/status";
import ExaminerAssignModal from "./ExaminerAssignModal";
import { HiFlag, HiOutlineMail } from "react-icons/hi";
import { GlobalContext } from "../../../App";
import { useContext } from "react";
import {  QuestionCircleOutlined } from "@ant-design/icons";
import { FiDownload } from "react-icons/fi";
import AuditLogs from "../AuditLogs/AuditLogs";
import { Excel } from "antd-table-saveas-excel";
import CloneOrder from "./CloneOrder";
import { useHoaStatusListQuery } from "../../../Redux/Services/Hoa";
import HoaDashboard from "./HoaDashboard/HoaDashboard";
export default function OrderDashboard({ search, setRowSelection }) {
  //context api
  const spinner = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = spinner.state;
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showCloneModalInitialState, setShowCloneModalInitialState] =
    useState(false);
  const [data, setData] = useState([]);
  const { orderListToolbar, orderModule } = useSelector(
    (state) => state.flagRoles
  );

  const [statusColor, setStatusColor] = useState([]);
  const [hoaStatusOptions, setHoaStatusOptions] = useState([]);
  const [showExaminerListModal, setShowExaminerListModal] = useState(false);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [showModalInitalState, setShowModalInitalState] = useState(false);
  const [productTypeOptionsData, setproductTypeOptionsData] = useState([]);

  const [examinerId, setExaminerId] = useState("");
  const [rowData, setRowData] = useState({});
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //audit logs
  const [auditLogsListCall, auditLogsList] = useOrderHistoryListLogsMutation();
  const [auditLogData, setAuditLogData] = useState([]);

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };
  const navigate = useNavigate();

  const { token, user } = useSelector((state) => state.auth);
  const { productTypeList } = useSelector((state) => state.productTypeList);

  const ordersListApi = useOrdersListQuery(token);
  //orders list base on hoa
  const [HoaordersList, HoaordersListResult] = useParcelHoaOrderListMutation();

  //status list for clor
  const statusColorApi = useOrderStatusQuery(token);

  //api hoa status list
  const HoaStatus = useHoaStatusListQuery(token);

  //sending request to  resubmit order
  const [orderResubmit, orderResubmitResult] = useOrderResubmitMutation();

  //getting Delete order api
  const [orderDelete, orderDeleteResult] = useDeleteOrderMutation();

  //calling rtk query hook for state list options based on customer
  const [, orderuserProductTypeListResult] =
    useOrderuserProductTypeListMutation();

  //sending request to  resubmit order
  const [orderExaminerUpdate, orderExaminerUpdateResult] =
    useOrderExaminerUpdateMutation();

  // useEffect for status list
  useEffect(() => {
    if (HoaStatus.isSuccess) {
      setHoaStatusOptions(HoaStatus.data?.data);
    }
    if (HoaStatus.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          HoaStatus.error?.data?.message ||
          HoaStatus.error.error ||
          HoaStatus.error.data?.msg?.sqlMessage,
      });

      setHoaStatusOptions([]);
    }
    //global spinner
  }, [HoaStatus.isFetching]);

  ///useEffect for default data
  useEffect(() => {
    if (orderuserProductTypeListResult.isSuccess) {
      const { product_list } = orderuserProductTypeListResult.data.data;
      setproductTypeOptionsData(product_list);
    }
    if (orderuserProductTypeListResult.isError) {
      setproductTypeOptionsData([]);
    }
  }, [orderuserProductTypeListResult.isLoading]);

  ///order delete
  useEffect(() => {
    if (orderDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderDeleteResult.data.message,
      });

      //rest order list
      ordersListApi.refetch();
      statusColorApi.refetch();
      HoaStatus.refetch();
    } else if (orderDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: orderDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderDeleteResult.isLoading });
  }, [orderDeleteResult.isLoading]);

  useEffect(() => {
    if (ordersListApi.isSuccess) {
      const newData = ordersListApi.data?.data?.map(
        ({
          id,
          researcher,
          notice_name,
          closer,
          legal,
          address,
          updated_at,
          county_name,
          order_hoa_status_name,
          ...others
        }) => {
          return {
            ...others,
            id,
            key: id,
            researcher: researcher || "Not Assigned",
            updated_at: updated_at || " ",
            county_name: county_name || " ",
            address: address || " ",
            legal: legal || " ",
            notice_name: notice_name || " ",
            order_hoa_status_name: order_hoa_status_name || " ",
          };
        }
      );
      if (
        globalVal.ordedashboardProductTypeId &&
        !globalVal.orderAllStatusToggle
      ) {
        setData(
          newData.filter(({ product_type_id, status }) => {
            return (
              !["Delivered", "Deleted", "Completed", "InActive\n"].includes(
                status
              ) && product_type_id === globalVal.ordedashboardProductTypeId
            );
          })
        );
      } else if (
        globalVal.orderAllStatusToggle &&
        globalVal.ordedashboardProductTypeId
      ) {
        setData(
          newData.filter(({ product_type_id }) => {
            return product_type_id === globalVal.ordedashboardProductTypeId;
          })
        );
      } else if (
        globalVal.orderAllStatusToggle &&
        !globalVal.ordedashboardProductTypeId
      ) {
        setData(newData);
      } else {
        let statusFilterData = newData.filter(({ status }) => {
          return !["Delivered", "Deleted", "Completed", "InActive\n"].includes(
            status
          );
        });
        setData(statusFilterData);
      }

      //product type Data
    } else {
      setData([]);
    }
  }, [ordersListApi.isFetching]);

  useEffect(() => {
    if (HoaordersListResult.isSuccess) {
      const newData = HoaordersListResult.data?.data?.map(
        ({
          legal_description,
          county_name,
          parcel_hoa_status_name,
          hoa_date_verified,
          hoa_collector_name,

          ...others
        }) => {
          return {
            ...others,
            county_name: county_name || " ",
            hoa_collector_name: hoa_collector_name || " ",
            hoa_date_verified: hoa_date_verified || " ",
            legal_description: legal_description || " ",
            parcel_hoa_status_name: parcel_hoa_status_name || " ",
          };
        }
      );

      if (
        globalVal.orderAllStatusToggle &&
        globalVal.ordedashboardProductTypeId
      ) {
        setData(
          newData.filter(({ product_type_id, parcel_hoa_status_name }) => {
            return (
              product_type_id === globalVal.ordedashboardProductTypeId &&
              !["HOA Completed"].includes(parcel_hoa_status_name)
            );
          })
        );
      } else if (
        !globalVal.orderAllStatusToggle &&
        globalVal.ordedashboardProductTypeId
      ) {
        setData(
          newData.filter(({ product_type_id }) => {
            return product_type_id === globalVal.ordedashboardProductTypeId;
          })
        );
      } else if (globalVal.orderAllStatusToggle) {
        setData(newData);
      } else {
        let statusFilterData = newData.filter(({ parcel_hoa_status_name }) => {
          return !["HOA Completed"].includes(parcel_hoa_status_name);
        });
        setData(statusFilterData);
      }
      //product type Data
    } else {
      setData([]);
    }
  }, [HoaordersListResult.isLoading]);

  useEffect(() => {
    if (statusColorApi.isSuccess) {
      setStatusColor(statusColorApi.data?.data);
    } else {
      setStatusColor([]);
    }
  }, [statusColorApi.isFetching]);
  //auditlist
  useEffect(() => {
    if (auditLogsList.isSuccess) {
      setAuditLogData(auditLogsList.data?.data);
      setShowAuditLogModal(true);
    } else {
      setAuditLogData([]);
      setShowAuditLogModal(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: auditLogsList.isLoading });
  }, [auditLogsList.isLoading]);

  useEffect(() => {
    if (orderResubmitResult.isSuccess) {
      const { id, reference_no } = orderResubmitResult.data.data;
      navigate("/order-editor", {
        state: {
          id,
          orderNumber: reference_no,
          orderEditableFlag: true,
          orderStatus: 1,
          orderEvent: "resubmit",
        },
      });
      ///after resubmit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderResubmitResult.data.message,
      });
    }
    if (orderResubmitResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          orderResubmitResult.error?.data?.error ||
          orderResubmitResult.error?.data?.message ||
          (orderResubmitResult.error.error && "server unreachable!") ||
          orderResubmitResult.error.data?.msg.sqlMessage,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderResubmitResult.isLoading });
  }, [orderResubmitResult.isLoading]);

  //useEffect for update asignee examiner
  useEffect(() => {
    if (orderExaminerUpdateResult.isSuccess) {
      setShowExaminerListModal(false);

      setRowData({});
      ///after resubmit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderExaminerUpdateResult.data?.message,
      });
      ordersListApi.refetch();
      setExaminerId("");
    }
    if (orderExaminerUpdateResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          orderExaminerUpdateResult.error?.data?.error ||
          orderExaminerUpdateResult.error?.data?.message ||
          (orderExaminerUpdateResult.error.error && "server unreachable!") ||
          orderExaminerUpdateResult.error.data?.msg.sqlMessage,
      });
    }
  }, [orderExaminerUpdateResult.isLoading]);

  useEffect(() => {
    if (globalVal?.hoaOrdersListToggle) {
      //fetch hoa list
      HoaordersList({ token });
    } else {
      ordersListApi.refetch();
      statusColorApi.refetch();
    }
  }, []);

  useEffect(() => {
    setproductTypeOptionsData(productTypeList);
  }, [productTypeList]);

  //columns
  const columns = [
    {
      title: " ",
      data: "flag",
      width: 10,
      render: (value, record) => {
        return (
          <>
            {record?.notice_name !== null &&
              record?.order_hoa_status_colour &&
              record?.notice_id && (
                <span className="fs-13 me-2">
                  <div className="hover-text-comp position-relative d-inline-block">
                    <HiFlag
                      color={
                        record.notice_id === 1
                          ? "#9e0059"
                          : record.notice_id === 2
                          ? "#ff0054"
                          : record.notice_id === 3
                          ? "#ff5400"
                          : record.notice_id === 4
                          ? "#3d5a80"
                          : record.notice_id === 5
                          ? "#293241"
                          : record.notice_id === 6
                          ? "#240046"
                          : record.notice_id === 7
                          ? "#8ac926"
                          : ""
                      }
                    />
                    {record?.notice_comments  && (
                      <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                        <span className="fs-7">
                          {record?.notice_name} :{" "}
                          <span>{record?.notice_comments}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </span>
              )}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Notice{" "}
          {getsortfield === "notice_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "notice_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "notice_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "notice_name",
      key: "notice_name",
      sorter: (a, b) => a.notice_name.localeCompare(b.notice_name),
      width: 150,
      align: "left",
      ...SearchColumns("notice_name", "Notice Flag"),
      render: (value) => {
        return <>{value}</>;
      },
    },

    {
      title: (
        <span>
          File #{" "}
          {getsortfield === "file_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "file_number",
      key: "file_number",
      sorter: (a, b) => a.file_number.localeCompare(b.file_number),
      width: 200,
      align: "start",
      ...SearchColumns("file_number", "File #"),
      render: (value, record) => {
        return (
          <>
            <span
              className="cursor-pointer p-1 ps-4 d-inline-block w-100"
              onClick={() => {
                navigate("/order-editor", {
                  state: {
                    id: record.id,
                    orderData: record,
                    orderStatus: record.status_id,
                    orderNumber: record.file_number,
                    orderEditableFlag: true,
                    orderEvent: "view",
                  },
                });
                setExaminerId(record.examiner_id);
              }}
            >
              {record?.parcel_count !== 0 && (
                <Badge
                  title={`${record?.parcel_count} Parcels`}
                  size="small"
                  offset={[15, 0]}
                  overflowCount={10}
                  count={record?.parcel_count}
                >
                  <span className="text-start">{value}</span>
                </Badge>
              )}
              {record?.parcel_count === 0 && <span>{value}</span>}
            </span>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Customer{" "}
          {getsortfield === "customer_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      width: 200,
      align: "center",
      ...SearchColumns("customer_name", "Customer Name"),
    },

    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "county_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "county_name",
      key: "county_name",
      sorter: (a, b) => a?.county_name.localeCompare(b?.county_name),
      width: 150,
      align: "center",
      ...SearchColumns("county_name", "County Name"),
    },
    {
      title: (
        <span>
          Address{" "}
          {getsortfield === "address" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "address" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "address" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "address",
      key: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),

      width: 200,
      align: "center",
      ...SearchColumns("address", "Address"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                  <p className="d-flex justify-content-center align-items-center ">
                    {value}
                  </p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Legal{" "}
          {getsortfield === "legal" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "legal" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "legal" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "legal",
      key: "legal",
      sorter: (a, b) => a.legal.localeCompare(b.legal),
      width: 100,
      align: "center",
      ...SearchColumns("legal", "Legal"),
      render: (value) => {
        return (
          <>
            <div className="hover-text-comp position-relative ">
              {" "}
              {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
              {value?.length > 15 && (
                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                  <p>{value}</p>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Status{" "}
          {getsortfield === "status" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "status" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "status" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 100,
      align: "center",
      // ...SearchColumns("status", "Status"),
      render: (value) => {
        const colorCode = statusColor?.filter((ele) => {
          return ele.name === value;
        });

        return (
          <>
            <button
              className="text-white rounded-pill fw-bold"
              style={{
                background: colorCode[0]?.colour_code,
                border: `1px solid ${colorCode[0]?.colour_code}`,
                width: 100,
              }}
            >
              {value}
            </button>
          </>
        );
      },
      filters: statusColor?.map(({ name }) => {
        return { text: name, value: name };
      }),
      onFilter: (value, filters) => filters.status.indexOf(value) === 0,
      filterMode: "tree",
    },
    // {
    //   title: (
    //     <span>
    //       HOA Status{" "}
    //       {getsortfield === "order_hoa_status_name" && !getsortorder ? (
    //         <FaSortAmountUp />
    //       ) : getsortorder === "ascend" &&
    //         getsortfield === "order_hoa_status_name" ? (
    //         <span className="sort-svg">
    //           <FaSortAmountUp className="text-info" />
    //         </span>
    //       ) : getsortorder === "descend" &&
    //         getsortfield === "order_hoa_status_name" ? (
    //         <span className="sort-svg">
    //           <FaSortAmountDown className="text-info" />
    //         </span>
    //       ) : (
    //         <FaSortAmountUp />
    //       )}
    //     </span>
    //   ),
    //   dataIndex: "order_hoa_status_name",
    //   key: "order_hoa_status_name",
    //   sorter: (a, b) =>
    //     a.order_hoa_status_name.localeCompare(b.order_hoa_status_name),
    //   width: 150,
    //   align: "center",

    //   render: (value, record) => {
    //     return (
    //       <>
    //         <button
    //           className="text-white rounded-pill fw-bold"
    //           style={{
    //             background: record?.order_hoa_status_colour,
    //             border: `1px solid ${record?.order_hoa_status_colour}`,
    //             width: 100,
    //           }}
    //         >
    //           {value}
    //         </button>
    //       </>
    //     );
    //   },
    //   filters: hoaStatusOptions?.map(({ name }) => {
    //     return { text: name, value: name };
    //   }),
    //   onFilter: (value, record) =>
    //     record?.order_hoa_status_name.startsWith(value),
    //   filterMode: "tree",
    // },
    {
      title: (
        <span>
          Modify Status{" "}
          {getsortfield === "updated_at" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "updated_at" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "updated_at" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
      width: 200,
      align: "center",
      ...SearchColumns("updated_at", "Modify Status"),
    },
    {
      title: (
        <span>
          Created{" "}
          {getsortfield === "created" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "created" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "created" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => a.created.localeCompare(b.created),
      width: 120,
      align: "center",
      ...SearchColumns("created", "Created Date"),
    },

    {
      title: (
        <span>
          Examiner{" "}
          {getsortfield === "researcher" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "researcher" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "researcher" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "researcher",
      key: "researcher",
      sorter: (a, b) => a.researcher.localeCompare(b.researcher),
      width: 200,
      align: "center",
      ...SearchColumns("researcher", "Researcher"),
    },
  ];

  const defaultExpandable = {
    expandedRowRender: (ele) => (
      <ul className="nav row-expand-icons">
        {orderListToolbar.map((item, index) => {
          if (item.edit_order) {
            return (
              <li
                key={index}
                className="me-1"
                onClick={() => {
                  navigate("/order-editor", {
                    state: {
                      id: ele.key,
                      orderNumber: ele.order_number,
                      orderStatus: ele.status_id,
                      orderEditableFlag: true,
                      orderEvent: "edit",
                    },
                  });
                }}
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Edit"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <MdEdit color="#3388FF" size={24} cursor="pointer" />
                </Tooltip>
              </li>
            );
          } else if (item.clone_order) {
            return (
              <li
                key={index}
                className="me-1"
                onClick={() => {
                  setShowCloneModal(true);
                  setRowData(ele);
                }}
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Clone"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <FaRegClone color="#3388FF" size={23} cursor="pointer" />
                </Tooltip>
              </li>
            );
          } else if (item.email_pdf) {
            return (
              <li className="me-1" key={index}>
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Email"}
                  className="border  border-secondary   rounded-3 p-1 "
                >
                  <HiOutlineMail color="#808080" size={23} cursor="pointer" />
                </Tooltip>
              </li>
            );
          } else if (item.download_pdf) {
            return (
              <li className="me-1" key={index}>
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Download"}
                  className="border border-secondary   rounded-3 p-1 "
                >
                  <FiDownload color="#808080" size={23} cursor="pointer" />
                </Tooltip>
              </li>
            );
          } else if (item.assign_order) {
            return (
              <li
                key={index}
                className="me-1"
                onClick={() => {
                  setShowExaminerListModal(true);
                  setRowData(ele);
                  setExaminerId(
                    ele.examiner_id === null ? "" : ele.examiner_id
                  );
                }}
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Assign"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <MdOutlineAssignment
                    color="#3388FF"
                    size={23}
                    cursor="pointer"
                  />
                </Tooltip>
              </li>
            );
          } else if (item.delete_order) {
            return (
              <li className="me-1" key={index}>
                <Popconfirm
                  getPopupContainer={(trigger) => trigger.parentElement}
                  title="Are you sure you want to delete？"
                  onConfirm={() => {
                    orderDelete({ id: ele.key, token });
                  }}
                  placement="bottom"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip
                    overlayInnerStyle={{
                      fontSize: 10,
                    }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placement="bottom"
                    title={"Delete"}
                    className="border  border-primary rounded-3 p-1 "
                  >
                    <MdDeleteOutline
                      color="#3388FF"
                      size={23}
                      cursor="pointer"
                    />
                  </Tooltip>
                </Popconfirm>
              </li>
            );
          } else if (item.update_order) {
            return (
              <li
                key={index}
                className="me-1"
                onClick={() => {
                  orderResubmit({ id: ele.id, token });
                }}
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Update"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <MdSystemUpdateAlt
                    color="#3388FF"
                    size={23}
                    cursor="pointer"
                  />
                </Tooltip>
              </li>
            );
          } else if (item.history) {
            return (
              <li
                key={index}
                onClick={() => {
                  auditLogsListCall({ id: ele.key, token });
                }}
                className="me-1"
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"History"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <AiOutlineAudit color="#3388FF" size={23} cursor="pointer" />
                </Tooltip>
              </li>
            );
          } else if (item.assign_me) {
            return (
              <li
                key={index}
                onClick={() => {
                  //order examiner
                  orderExaminerUpdate({
                    data: { order_id: [ele.key], examiner_id: user?.user_id },
                    token,
                  });
                }}
                className="me-1"
              >
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottom"
                  title={"Assign me"}
                  className="border  border-primary rounded-3 p-1 "
                >
                  <MdOutlineAssignmentInd
                    color="#3388FF"
                    size={23}
                    cursor="pointer"
                  />
                </Tooltip>
              </li>
            );
          }
          return null;
        })}
      </ul>
    ),
  };
  //asigning examiner
  const assigExaminer = (value) => {
    orderExaminerUpdate({
      data: { order_id: [rowData.id], examiner_id: value },
      token,
    });
  };

  const exportscolumns = globalVal.hoaOrdersListToggle
    ? [
        {
          title: "File #",
          dataIndex: "file_number",
          key: "file_number",
        },
        {
          title: "Customer",
          dataIndex: "customer_name",
          key: "customer_name",
        },
        {
          title: "County",
          dataIndex: "county_name",
          key: "county_name",
        },
        {
          title: "Address",
          dataIndex: "situs_address",
          key: "situs_address",
        },
        {
          title: "Legal",
          dataIndex: "legal_description",
          key: "legal_description",
        },
        {
          title: "HOA",
          dataIndex: "hoa_name",
          key: "hoa_name",
        },
        {
          title: "Verify Date",
          dataIndex: "hoa_date_verified",
          key: "hoa_date_verified",
        },
        {
          title: "Collector",
          dataIndex: "hoa_collector_name",
          key: "hoa_collector_name",
        },
        {
          title: "Status",
          dataIndex: "parcel_hoa_status_name",
          key: "parcel_hoa_status_name",
        },
        {
          title: "Created",
          dataIndex: "created_at",
          key: "created_at",
        },
      ]
    : [
        {
          title: "Notice",
          dataIndex: "notice_name",
          key: "notice_name",
        },

        {
          title: "File #",
          dataIndex: "file_number",
          key: "file_number",
        },
        {
          title: "Customer",
          dataIndex: "customer_name",
          key: "customer_name",
        },
        {
          title: "County",
          dataIndex: "county_name",
          key: "county_name",
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "Legal",
          dataIndex: "legal",
          key: "legal",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Modify Status",
          dataIndex: "updated_at",
          key: "updated_at",
        },

        {
          title: "Created",
          dataIndex: "created",
          key: "created",
        },
        {
          title: "Examiner",
          dataIndex: "researcher",
          key: "researcher",
        },
      ];

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(exportscolumns)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs(`${globalVal?.hoaOrdersListToggle ? "HOA" : "Orders"}.xlsx`);
  };

  return (
    <>
      <AppModal
        width={400}
        height={300}
        onClose={(value) => {
          setShowExaminerListModal(value);
          setExaminerId("");
          setRowData({});
        }}
        showModal={showExaminerListModal}
        afterClose={() => {
          setShowModalInitalState(!showModalInitalState);
        }}
        element={
          <ExaminerAssignModal
            isLoading={orderExaminerUpdateResult.isLoading}
            value={examinerId}
            showModalInitalState={showModalInitalState}
            onSubmit={assigExaminer}
            onChange={(e, value) => {
              setExaminerId(value);
            }}
          />
        }
      />
      {/* //history  */}
      <AppModal
        width={"85%"}
        onClose={(value) => {
          setShowAuditLogModal(value);
        }}
        showModal={showAuditLogModal}
        // afterClose={() => {
        //   setShowAuditLogModal(!showAuditLogModal);
        // }}
        element={
          <div className="p-3">
            <AuditLogs title="Order" data={auditLogData} />
          </div>
        }
      />
      <AppModal
        onClose={(value) => {
          setShowCloneModal(value);
        }}
        afterClose={() => {
          setShowCloneModalInitialState(!showCloneModalInitialState);
        }}
        showModal={showCloneModal}
        element={
          <CloneOrder
            closeModal={setShowCloneModal}
            afterClose={showCloneModalInitialState}
            id={rowData?.id}
            initialVals={rowData?.file_number}
            refetchOrder={ordersListApi}
          />
        }
      />

      {orderResubmitResult.isLoading && <AppSpinner position="fixed" />}
      <div className="row mb-2">
        <div className="col-12 col-md-3 d-flex gap-1 align-items-center ">
          {orderModule.map((item, index) => {
            if (item.export_button) {
              return (
                <Button
                  key={index}
                  size="small"
                  type="primary"
                  onClick={handleClick}
                >
                  Export Data
                </Button>
              );
            }
            return null;
          })}

          <select
            value={globalVal.ordedashboardProductTypeId}
            onChange={(e) => {
              const { value } = e.target;
              setGlobalVal({
                ...globalVal,
                ordedashboardProductTypeId: value ? Number(value) : "",
              });
              if (globalVal.hoaOrdersListToggle) {
                HoaordersList({ token });
              } else {
                ordersListApi.refetch();
              }
              // if (value) {
              //   setData(
              //     filterData?.filter((ele, i) => {
              //       return ele.product_type_id == value;
              //     })
              //   );
              // } else {
              //   setData(filterData);
              // }
            }}
            className="w-100 px-2 py-1 border rounded fs-12 text-dark"
          >
            <option value={""}>Select Product Type</option>
            {productTypeOptionsData?.map((e) => {
              return (
                <option key={e.name} value={e.id}>
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12 col-md-2">
          <Switch
            size="small"
            defaultChecked={globalVal?.hoaOrdersListToggle}
            onChange={(value) => {
              setGlobalVal({ ...globalVal, hoaOrdersListToggle: value });
              //call hoa orders api
              if (value) {
                HoaordersList({ token });
              } else {
                ordersListApi.refetch();
              }
            }}
          />
          <span className="fw-bold  "> HOA ORDERS</span>
        </div>
        <div className="col-12 col-md-2">
          <Switch
            size="small"
            defaultChecked={globalVal?.orderAllStatusToggle}
            onChange={(value) => {
              setGlobalVal({ ...globalVal, orderAllStatusToggle: value });
              if (globalVal.hoaOrdersListToggle) {
                HoaordersList({ token });
              } else {
                ordersListApi.refetch();
              }
            }}
          />
          <span className="fw-bold  "> All ORDERS</span>
        </div>
      </div>
      {globalVal?.hoaOrdersListToggle ? (
        <div className="hoa-orders">
          <HoaDashboard
            search={search}
            data={data}
            loading={HoaordersListResult.isLoading}
            parcelHoaStatusOptions={hoaStatusOptions}
          />
        </div>
      ) : (
        <AppTable
          rowSelection={true}
          sort={sort}
          loading={ordersListApi.isFetching || statusColorApi.isFetching}
          onChange={setRowSelection}
          search={search}
          columns={columns}
          dataSource={data}
          expandable={defaultExpandable}
          defaultExpandable
        />
      )}
    </>
  );
}
