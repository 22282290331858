import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./file.css";
import { MdDeleteOutline, MdDownload } from "react-icons/md";
import { AiOutlineFileImage, AiOutlineFolderView } from "react-icons/ai";
import {  FaRegFilePdf } from "react-icons/fa";
import FileBase64 from "react-file-base64";
import { useCallback } from "react";
import { Card } from "./Comps/Card";
import update from "immutability-helper";
import { triggerBase64Download } from "react-base64-downloader";

function MultiFileUpload({
  onChange,
  deleteFile = false,
  limit = 10,
  initialFiles = [],
  isLoading=false,
  component = false,
  edit = true,
  aftreClose = false,
  height = 40,
  dargable = false,
  showErrors = true,
  filesize = 2000000,
  whileupdate = false,
  accepts = ["application/pdf", "image/jpeg", "image/png", "text/csv"],
  onview = false,
  liststyle = "verticle",
}) {
  const [files, setFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [errors, setErrors] = useState([]);
  //   useEffect(() => {

  //   }, [files]);

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const fileHandler = (file) => {
    setFileLimit(false);
    let errors = [];
    const afterFilterType = file.filter(({ type, size, name, file }) => {
      //check duplicates

      if (file.size > filesize || !accepts.includes(type)) {
        errors.push({
          name,
          err: !accepts.includes(type)
            ? `Unsupported File Format, Format Should be ${String(accepts)}`
            : `Max Size Should be less than ${bytesToSize(filesize)}`,
        });
      }
      return accepts.includes(type) && file.size <= filesize;
    });

    // const test = files.map(({ base64, ...others }) => {
    //   return { ...others, base64: btoa(base64) };
    // });

    const uploaded = [...files];
    let limitExceeded = false;

    afterFilterType.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === limit) {
          setFileLimit(false);
        }
        if (uploaded.length > limit) {
          setFileLimit(`You can upload max ${limit} files`);
          limitExceeded = true;
          return true;
        }
      } else {
        errors.push({
          name: `File Size error ${file.name}`,
          err: `Duplicate File`,
        });
      }
    });
    //errors
    setErrors(errors);

    if (!limitExceeded) {
      onChange && onChange(uploaded);
      setFiles(uploaded);

      setTimeout(() => {
        setErrors(false);
      }, 12000);

      const updated = uploaded.filter(({ id }) => {
        return !id;
      });

      //while update
      whileupdate && whileupdate(updated);
    }
  };

  const deleteHandler = (deleteName, id) => {
    const afterDeleted = files.filter(({ name }) => {
      return name !== deleteName;
    });
    setFiles(afterDeleted);
    onChange && onChange(afterDeleted);

    setErrors(false);
    setFileLimit(false);

    //delete file
    if (id) {
      deleteFile && deleteFile(id);
    }

    const updated = afterDeleted.filter(({ id }) => {
      return !id;
    });

    //while update
    whileupdate && whileupdate(updated);
    // if (files.length === 1) {
    //   setErrors([]);
    //   setFileLimit(false);
    // }
  };

  //initial files
  useEffect(() => {
    setFiles(initialFiles);
  }, [aftreClose]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const updated = (prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });
    setFiles(updated);
    //updated
    onChange && onChange(updated);
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id || card.tempId}
        name={card.name}
        type={card.type}
        moveCard={moveCard}
      />
    );
  }, []);

  return (
    <div className="upload">
      {fileLimit && edit && <h6 className="text-danger fs-11 ">{fileLimit}</h6>}

      {edit && (
        <div className="input-file" style={{ height: height }}>
          <FileBase64
            accept="application/pdf, image/png"
            multiple={true}
            onDone={fileHandler}
          />
          <div className="icons">
            {/* <MdOutlineFileUpload size={40} color="#fff" /> */}

            {component || <button type="button">Upload File</button>}
          </div>
        </div>
      )}

      {dargable && (
        <div className="mt-2 ">
          {files.map((card, i) => {
            return (
              <div
                key={card.name}
                className="border p-2 d-flex align-items-center filelist justify-content-between"
              >
                <div className="w-100">{renderCard(card, i)}</div>
                <div className="delete">
                  {edit && (
                    <MdDeleteOutline
                      onClick={() => {
                        deleteHandler(card.name, card?.id);
                      }}
                      size={20}
                      color="red"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!dargable && (
        <ul
          className={` ${
            liststyle === "horizontal" ? "d-flex" : "list-group"
          } mt-2  gap-1`}
        >
          {files.map(({ type, base64, name, id }) => {
            if (type === "application/pdf") {
              return (
                <li
                  key={name}
                  className="list-group-item d-flex align-items-center justify-content-between"
                >
                  <div className=" d-flex align-items-center gap-2">
                    <FaRegFilePdf size={20} /> <span>{name}</span>{" "}
                  </div>
                  <div className="delete ">
                    {/* {id && (
                      <MdDownload
                        onClick={() => {
                          triggerBase64Download(atob(base64), name);
                        }}
                        size={20}
                        color="green"
                      />
                    )} */}
                    {id && (
                      <AiOutlineFolderView
                        onClick={() => {
                          // triggerBase64Download(atob(base64), name);
                          onview && onview(atob(base64), type);
                        }}
                        size={20}
                        color="green"
                      />
                    )}
                    {edit && (
                      <MdDeleteOutline
                        onClick={() => {
                          deleteHandler(name, id);
                        }}
                        size={20}
                        color="red"
                      />
                    )}
                  </div>
                </li>
              );
            }

            if (
              type === "image/png" ||
              type === "image/jpg" ||
              type === "image/jpeg"
            ) {
              return (
                <li
                  key={name}
                  className="list-group-item d-flex align-items-center justify-content-between"
                >
                  <div className=" d-flex align-items-center gap-2">
                    <AiOutlineFileImage size={20} /> <span>{name}</span>{" "}
                  </div>
                  <div className="delete">
                    {id && (
                      <MdDownload
                        onClick={() => {
                          triggerBase64Download(atob(base64), name);
                        }}
                        size={20}
                        color="green"
                      />
                    )}
                    {id && (
                      <AiOutlineFolderView
                        onClick={() => {
                          // triggerBase64Download(atob(base64), name);
                          onview && onview(atob(base64), type);
                        }}
                        size={20}
                        color="green"
                      />
                    )}
                    {edit && (
                      <MdDeleteOutline
                        onClick={() => {
                          deleteHandler(name, id);
                        }}
                        size={20}
                        color="red"
                      />
                    )}
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      )}
      {errors.length > 0 && showErrors && (
        <>
          {" "}
          <h6 className="text-danger  border-danger border-bottom pb-1 mt-2">
            File Errors
          </h6>
          <ul className="list-group bg-light">
            {errors.map(({ name, err }, index) => {
              return (
                <li
                  key={index}
                  className="list-group-item d-flex align-items-center justify-content-between text-danger"
                >
                  <div>
                    <span className="text-primary">{name}</span> ,
                    <span>{err}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
}

export default MultiFileUpload;
