import React, { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import "./appText.css";
export default function AppTextArea(props) {
  const [focused, setFocused] = useState(false);
  const {
    label,
    icon,
    column,
    disabled,
    errorMessage,
    onChange,
    color,
    margin,
    required,
    defaultSet,
    classStyle,
    isEdit,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    if (inputProps.value) {
      setFocused(false);
    } else if (required) {
      setFocused(true);
    }
  };

  useEffect(() => {
    setFocused(false);
  }, [defaultSet]);

  const onChangeHandler = (e) => {
    onChange(e);
    setFocused(false);
  };

  return (
    <>
      <div
        className={`col-12 ${margin} col-md-${column} ${
          disabled && "disable-input"
        }`}
      >
        <div className="mt-2 row">
          <label className="ms-1 heading-5 text-dark">
            {label}
            {required && label && !color && (
              <>
                <span className="text-danger">* </span>
              </>
            )}
            {isEdit && <span className="text-dark fw-bold"> :</span>}
          </label>
        </div>
        <div className="form-floating mt-1">
          {isEdit ? (
            <div className="mx-1 w-100 heading-5" style={{ marginTop: 4 }}>
              <div className="hover-text-comp position-relative">
                {inputProps.value?.slice(0, 150)}{" "}
                {inputProps.value?.length > 150 ? "..." : ""}
                {inputProps.value?.length > 150 && (
                  <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                    <p className="d-flex justify-content-center align-items-center ">
                      {inputProps.value || "NA"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <textarea
                {...inputProps}
                required={required}
                onChange={onChangeHandler}
                onBlur={handleFocus}
                disabled={disabled}
                focused={focused.toString()}
                className={`${classStyle || "pb-2 w-100 form-control "}  ${
                  icon && "icon-input"
                } }`}
              />{" "}
              <span className="input-erro">
                <BsExclamationCircle
                  className="alertIcon"
                  style={{ marginBottom: "3px" }}
                />
                {errorMessage}
              </span>{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
}
