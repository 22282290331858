import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const email = createApi({
  reducerPath: "email",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    emailCheck: builder.mutation({
      query: (data) => ({
        url: `/check-email-unique/${data.email}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const { useEmailCheckMutation } = email;
