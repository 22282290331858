import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const hoa = createApi({
  reducerPath: "hoa",
  tagTypes: ["hoa"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addHoa: builder.mutation({
      query: (data) => ({
        url: `/add-hoa`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoa"],
    }),
    hoaList: builder.query({
      query: (token) => ({
        url: `/hoa-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoa"],
    }),
    hoaTypeList: builder.query({
      query: (token) => ({
        url: `/hoa-type-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    updateHoaStatus: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateHoa: builder.mutation({
      query: (data) => ({
        url: `/update-hoa/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoa"],
    }),
    editHoa: builder.query({
      query: (data) => ({
        url: `/edit-hoa/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    activeHoaList: builder.mutation({
      query: (token) => ({
        url: `/active-hoa-lists`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    hoaFilterList: builder.mutation({
      query: (data) => ({
        url: `/hoa-filter-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    subDivisionFilterList: builder.mutation({
      query: (data) => ({
        url: `/county-hoa-subdivision/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    HOCFilterList: builder.mutation({
      query: (data) => ({
        url: `/hoa-filter-collector`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    HoaSelectFilterList: builder.mutation({
      query: (data) => ({
        url: `/hoa-filter-based-collector/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    HoaSelectList: builder.mutation({
      query: (data) => ({
        url: `/hoa-collector-based-state/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    HoaSubdFiletrList: builder.mutation({
      query: (data) => ({
        url: `/hoa-subdivision-filter-code`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    HoaSubdFiletrListBaseName: builder.mutation({
      query: (data) => ({
        url: `/hoa-subdivision-filter-name`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    activeSubAssociation: builder.mutation({
      query: (data) => ({
        url: `/hoa-sub-association-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    activeMasterAssociation: builder.mutation({
      query: (data) => ({
        url: `/hoa-master-association-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa info prefill for parcels
    hoaPreFill: builder.mutation({
      query: (data) => ({
        url: `/hoa-information/${data.id}`,
        mode: "cors",
        method: "GET",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa info prefill for hoamgmt parcels
    hoaMgmtPreFill: builder.mutation({
      query: (data) => ({
        url: `/hoa-collector-information/${data.id}`,
        mode: "cors",
        method: "GET",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa info update for parcels
    hoaUpdateInfo: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-hoa/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa fees type list
    hoaFeesList: builder.mutation({
      query: (data) => ({
        url: `/hoa-fees-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa fees branch info
    hoaCollectorBranchInfo: builder.mutation({
      query: (data) => ({
        url: `/hoa-collector-branch-info/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //hoa status list info
    hoaStatusList: builder.query({
      query: (token) => ({
        url: `/hoa-status-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    
    //hoa status list info
    parcelHoaStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-hoa-status/${data?.parcel_id}`,
        mode: "cors",
        method: "POST",
        body: { hoa_status_id: data?.hoa_status_id },
        headers: { Authorization: `Bearer ${data?.token}` },
      }),
    }),
    parcelHoaList: builder.mutation({
      query: (data) => ({
        url: `/parcel-hoa-list/${data.parcel_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useAddHoaMutation,
  useHoaListQuery,
  useUpdateHoaStatusMutation,
  useEditHoaQuery,
  useUpdateHoaMutation,
  useSubDivisionFilterListMutation,
  useHoaTypeListQuery,
  useHoaFilterListMutation,
  useActiveHoaListMutation,
  useHOCFilterListMutation,
  useHoaSelectFilterListMutation,
  useHoaSelectListMutation,
  useHoaSubdFiletrListMutation,
  useHoaSubdFiletrListBaseNameMutation,
  useActiveSubAssociationMutation,
  useActiveMasterAssociationMutation,
  useHoaPreFillMutation,
  useHoaMgmtPreFillMutation,
  useHoaUpdateInfoMutation,
  useHoaFeesListMutation,
  useHoaCollectorBranchInfoMutation,
  useHoaStatusListQuery,
  useParcelHoaStatusUpdateMutation,
  useParcelHoaListMutation,
} = hoa;
