import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux/es/exports";
import AppCheckbox from "../../../../Components/AppCheckbox/AppCheckbox";
import { Col, Row } from "react-bootstrap";
import { Alert, Empty, notification } from "antd";
import "./customer.css";
import { useState, useEffect } from "react";
import AppSelect from "../../../../Components/AppSelect/AppSelect";
import {
  useAddCustomerMutation,
  useCustmerProductTypeMutation,
  useEditCustomerQuery,
  useUpdateCustomerMutation,
} from "../../../../Redux/Services/Customers";
import { useStateCountyIdListMutation } from "../../../../Redux/Services/stateCounty";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import AppMultiSelect from "../../../../Components/AppMultiSelect/AppMultiSelect";
import { AppFile } from "../../../../Components/AppFile/AppFile";
import {
  removeArrayBlank,
  removeBlank,
  scrollComp,
} from "../../../../Controllers/Functions";
import MultiContacts from "../../../../Components/MultiContacts/MultiContacts";
import MyMultiSelect from "../../../../Components/MultiSelect/MyMultiSelect";
import { ProductTypeComp } from "../../../../Components/ProductType/ProductTypeComp";
import { IntegrationBridge } from "../../../../Components/IntegrationBridge/IntegrationBridge";
import Input from "react-phone-number-input/input";
import AppNumeric from "../../../../Components/AppNumeric/AppNumeric";
const _ = require("lodash");

export const CustomerModal = ({
  editId,
  initialValues,
  afterClose,
  setShowHide,
}) => {
  const [vendorData, setVendorData] = useState([
    { vendor_id: "", vendor_name: "" },
  ]);
  const [customertypeList, setCustomerTypeList] = useState([
    { id: "", name: "" },
  ]);

  const privateLabeleFields = {
    private_label_address_one: "",
    private_label_address_two: "",
    private_label_state_id: "",
    private_label_city: "",
    private_label_zip_code: "",
    private_label_phone_number: "",
    private_label_email_address: "",
    private_label_logo: "",
    private_label_logo_name: "",
    private_label_display_name: "",
  };

  //setting products
  const [products, setProducts] = useState([]);
  const [productStateList, setProductStateList] = useState([]);

  //multiple contacts
  const [multiContacts, setMultiContacts] = useState([]);

  //multiple products
  const [multiProducts, setMultiProducts] = useState({});

  //multiple integration
  const [multiIntegration, setMultiIntegration] = useState([]);

  const [getError, setGetError] = useState(false);
  const [enableStateCountyList, setEnableStateCountyList] = useState([]);
  const [vendorIdConst, setVendorIdConst] = useState(null);
  //   values assign
  const [values, setValues] = useState(initialValues);

  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  const [price, setPrice] = useState({ notice_to_purchaser_fees: "" });
  //calling redux stor for vendors list
  const { vendorsList } = useSelector((state) => state.vendorsList);
  const data = _.orderBy(vendorsList, [
    (item) => _.get(item, "vendor_name").toLowerCase(),
  ]);

  //calling redux stor for vendors list
  const { token, user } = useSelector((state) => state.auth);

  //calling redux stor for vendors list
  const { customerTypeList } = useSelector((state) => state.customerTypeList);

  //calling redux stor for productTypeList list
  const { productTypeList } = useSelector((state) => state.productTypeList);

  //calling redux stor for vendors list
  const { integrationBridgeList } = useSelector(
    (state) => state.integrationBridgeList
  );

    const { tableColumn } = useSelector(
		(state) => state.flagRoles
	);


  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for geting customerType list
  const editCustomerQuery = useEditCustomerQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  const stateCountyListApi = useStateCountyIdListMutation();
  const [stateCountyIdList] = stateCountyListApi;

  //calling rtk query hook for adding customer
  const addNewCustomer = useAddCustomerMutation();
  const [addCustomer] = addNewCustomer;

  //calling rtk query hook for update customer
  const updateNewCustomer = useUpdateCustomerMutation();
  const [updateCustomer] = updateNewCustomer;

  //calling rtk query hook for geting customer  productType list
  const customerProductTypeApi = useCustmerProductTypeMutation();
  const [custmerProductType] = customerProductTypeApi;

  //clear error function
  function clearStatus() {
    setGetError(false);
  }
  //function for modal close
  // modal will be close
  function closeModal() {
    clearStatus();
    // //it will be close to the modal
    setShowHide(false);
  }

  //branchPricing function
  function custmerProductController(id, edit = false) {
    custmerProductType({ id: id, token: token });
  }

  //useEffet for  add customer
  useEffect(() => {
    if (addNewCustomer[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewCustomer[1].data?.message,
      });
    }
    if (addNewCustomer[1].error) {
      setGetError(
        addNewCustomer[1].error?.data?.error ||
          addNewCustomer[1].error?.data?.message ||
          (addNewCustomer[1].error.error && "server unreachable!") ||
          addNewCustomer[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewCustomer[1].isLoading]);

  useEffect(() => {
    if (updateNewCustomer[1].isSuccess) {
      closeModal();
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateNewCustomer[1].data?.message,
      });
    }
    if (updateNewCustomer[1].error) {
      setGetError(
        updateNewCustomer[1].error?.data?.error ||
          updateNewCustomer[1].error?.data?.message ||
          (updateNewCustomer[1].error.error && "server unreachable!") ||
          updateNewCustomer[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateNewCustomer[1].isLoading]);

  //useEffect for setting default values
  useEffect(() => {
    setVendorData(data);

    setCustomerTypeList(customerTypeList);
    setStateData(stateList);
    setMultiProducts({});
    setPrice({ notice_to_purchaser_fees: "" });
    //calling to stateCouny lis
    // if edit id is exist
  }, [afterClose]);

  //useEfect for Refecth edit details
  useEffect(() => {
    if (editId) {
      editCustomerQuery.refetch();
    } else {
      if (user.user_type_id !== 1) {
        stateCountyIdList({
          id: user.vendor_id,
          token: token,
        });
        setValues({ ...initialValues, vendor_id: user.vendor_id });
      }
    }
    //all alert messages cleared!
    clearStatus();
  }, [afterClose]);

  //variables area
  const isProductList = customerProductTypeApi[1]?.data?.data;

  const inputs = [
    {
      id: 1,
      name: "vendor_id",
      _id: "customer_vendor_id",
      type: "select",
      value: values.vendor_id || "",
      placeholder: "Select Vendor",
      errorMessage: "Please select vendor!",
      label: "Vendor",
      options: vendorData,
      required: true,
      column: "4",
    },
    {
      id: 2,
      name: "customer_type",
      _id: "customer_customer_type",
      type: "select",
      value: values.customer_type || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer type!",
      label: "Customer Type",
      options: customertypeList,
      required: true,
      column: "4",
    },
    {
      id: 3,
      name: "name",
      type: "text",
      _id: "customer_name",
      placeholder: "Enter Customer Name",
      errorMessage: "Please enter customer name!",
      label: "Customer Name",
      required: true,
      column: "4",
    },
    {
      id: 4,
      name: "address_one",
      _id: "customer_address_one",
      type: "text",
      column: "4",
      placeholder: "Enter Address 1",
      errorMessage: "Please enter address 1!",
      label: "Address 1",
    },
    {
      id: 5,
      name: "address_two",
      _id: "customer_address_two",
      type: "text",
      column: "4",
      placeholder: "Enter Address 2",
      label: "Address 2",
    },
    {
      id: 6,
      name: "state_id",
      _id: "customer_state_id",
      type: "select",
      value: values.state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      column: "4",
    },

    {
      id: 7,
      name: "city",
      type: "text",
      _id: "customer_city",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "4",
    },
    {
      id: 12,
      name: "zip_five",
      type: "text",
      _id: "customer_zip_five",
      column: "4",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
    },
    {
      id: 9,
      name: "customer_email",
      _id: "customer_email",
      type: "email",
      value: values.customer_email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "4",
    },
    {
      id: 10,
      name: "phone_number",
      type: "text",
      column: "4",
      _id: "customer_phone_number",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      maxLength: "10",
      minLength: "10",
    },

    {
      id: 11,
      name: "website",
      type: "text",
      _id: "customer_website",
      placeholder: "Enter Website Name",
      label: "Website",
      column: "4",
    },
    {
      id: 14859,
      name: "notice_to_purchaser_fees",
      label: "Notice To Puchaser Fees",
      value: price.notice_to_purchaser_fees || "",
      placeholder: "Enter Notice To Puchaser Fees",
      column: "4",
      // errorMessage: "Please enter notice to puchaser fees!",
    },
    {
      id: 24,
      name: "is_private_label",
      _id: "customer_is_private_label",
      checked: values.is_private_label || "",
      type: "switch",
      column: "2",
      label: "Private Label",
      margin: "5",
      is_private_label: false,
    },

    {
      id: 20,
      name: "is_active",
      _id: "customer_is_active",
      checked: values.is_active || "",
      type: "switch",
      column: "2",
      label: "Active",
      margin: "5",
    },

    //appMultiSelect
    {
      id: 225,
      type: "multiselect",
    },
    {
      id: 24,
      type: "file",
      column: "3",
      preview: values.logo,
      errorMessage: "Please add Logo!",
      label: "Logo",
    },

    {
      id: 23,
      name: "is_branch",
      type: "checkbox",
      _id: "customer_is_branch",
      checked: values.is_branch || "",
      label: "Customer Also Branch",
      column: `3 ${editId && "d-none"}`,
      margin: "5",
    },
    {
      id: 1541,
      name: "branch_name",
      type: "text",
      _id: "customer_branch_name",
      placeholder: "Enter Branch Name",
      label: "Branch Name",
      value: values.branch_name || "",
      errorMessage: "Please enter branch name!",
      column: `4 ${values.is_branch ? "" : "d-none"}`,
      required: values.is_branch ? true : false,
    },
    //if customer Also Branch

    {
      id: 2645455,
      name: "private_label_display_name",
      _id: "customer_private_label_display_name",
      type: "text",
      column: "3",
      value: values?.private_label_display_name || "",
      placeholder: "Enter Private Label Name",
      errorMessage: "Please enter display name",
      label: "Private Label Name",
      required: true,
      is_private_label: "true",
    },
    {
      id: 25,
      name: "private_label_address_one",
      _id: "customer_private_label_address_one",
      type: "text",
      column: "3",
      placeholder: "Enter Address 1",
      errorMessage: "Please enter address 1!",
      label: "Address 1",

      is_private_label: "true",
    },

    {
      id: 26,
      name: "private_label_address_two",
      _id: "customer_private_label_address_two",
      type: "text",
      column: "3",
      placeholder: "Enter Address 2",
      label: "Address 2",
      is_private_label: "true",
    },
    {
      id: 27,
      name: "private_label_state_id",
      type: "select",
      _id: "customer_private_label_state_id",
      value: values?.private_label_state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,

      column: "3",
      is_private_label: "true",
    },
    {
      id: 28,
      name: "private_label_city",
      _id: "customer_private_label_city",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",

      is_private_label: "true",
    },
    {
      id: 29,
      name: "private_label_zip_code",
      type: "text",
      _id: "customer_private_label_zip_code",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",

      is_private_label: "true",
    },
    {
      id: 30,
      name: "private_label_phone_number",
      _id: "customer_private_label_phone_number",
      type: "text",
      column: "3",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      maxLength: "10",
      minLength: "10",

      is_private_label: "true",
    },
    {
      id: 31,
      name: "private_label_email_address",
      _id: "customer_private_label_email_address",
      type: "email",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",

      column: "3",
      is_private_label: "true",
    },
    {
      id: 32,
      type: "file",
      column: "3",
      preview: values?.private_label_logo,
      errorMessage: "Please add Logo!",
      label: "Logo",
      is_private_label: "true",
    },
  ];
  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
    if (property === "is_branch") {
      setValues({ ...values, [property]: value, branch_name: "" });
    }
  };

  // console.log("products", multiProducts);
  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
    if (property === "vendor_id") {
      //calling to stateCouny list
      stateCountyIdList({
        id: Number(value),
        token: token,
      });
      custmerProductController(Number(value), true);
      setValues({
        ...values,
        product_list_values: [],
        county_id_list: [],
        vendor_id: value,
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editCustomerQuery.refetch();
    }
    clearStatus();
  };

  useEffect(() =>
  {
    if (user.user_type_id !== 1)
    {
      const vendorid = user.vendor_id;
       custmerProductController(vendorid, false);
    }
    
  },[user.vendor_id])
  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });

    if (property === "is_private_label") {
      if (value) {
        setValues({
          ...values,
          is_private_label: value,
        });
      } else {
        setValues({
          ...values,
          is_private_label: value,
          private_label_state_id: "",
          ...privateLabeleFields,
        });
      }
    }
    clearStatus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.county_id_list.length === 0) {
      setGetError("Please select State and County");
    } else if (values.customer_type.length === 0) {
      setGetError("Please select Customer Type List");
    } else if (values.contact_list_values.length === 0) {
      setGetError("Please Add Contact");
    } else if (values.product_list_values.length === 0) {
      setGetError("Please select Product List");
    } else if (
      values.phone_number?.length !== 12 &&
      values.phone_number?.length > 0
    ) {
      setGetError("Please enter valid phone number");
    } else {
      removeBlank(values);
      removeArrayBlank(values?.edit_customer_contact);
      removeArrayBlank(multiIntegration);

      //nested products loop
      const addproductPrincing = [];
      for (const key in multiProducts) {
        addproductPrincing.push(multiProducts[key]);
      }

      //deleteing values base on products listed
      const addproductPriceList = addproductPrincing.flat().filter((e) => {
        return values?.product_list_values.includes(e?.product_type_id);
      });
      //zip five
      const zip_five = values?.zip_five?.split("-");
      const private_label_zip_Data = values?.private_label_zip_code?.split("-");

      if (!editId && multiContacts?.length > 0) {
        addCustomer({
          data: {
            ...values,
            contact_info: multiContacts,
            notice_to_purchaser_fees: price.notice_to_purchaser_fees,
            integration_bridge: multiIntegration,
            customer_product_price: addproductPriceList,
            zip_five: zip_five ? Number(zip_five[0]) : "",
            zip_four: zip_five ? Number(zip_five[1]) : null,
            private_label_zip_five: private_label_zip_Data
              ? Number(private_label_zip_Data[0])
              : "",
            private_label_zip_four: private_label_zip_Data
              ? Number(private_label_zip_Data[1])
              : "",
          },
          token: token,
        });
      }

      if (editId) {
        //deleteing values base on products listed
        const result = values.customer_product_price_values.filter((e) => {
          return !values?.product_list_values.includes(e.product_type_id);
        });

        const deleteProductsPrice = values?.delete_customer_product_price;
        await result.map((ele) => {
          deleteProductsPrice.push(String(ele.id));
        });

        //while adding new products
        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return (
                element.product_type_id === el.product_type_id &&
                el.county_name === element.county_name
              );
            });
          });
          return res;
        };

        //adding new product pricing
        const addNewProduct = filterByReference(
          addproductPriceList,
          values.customer_product_price_values
        );

        const editCustomerContact = values?.edit_customer_contact?.filter(
          (ele) => {
            if (ele?.id !== undefined) {
              return ele;
            }
            return null;
          }
        );

        const editIntegration = values?.edit_integration_bridge?.filter(
          (ele) => {
            if (ele?.id !== undefined) {
              return ele;
            }
            return null;
          }
        );

        updateCustomer({
          data: {
            ...values,
            notice_to_purchaser_fees: price.notice_to_purchaser_fees,
            add_customer_product_price: addNewProduct,
            delete_customer_product_price: deleteProductsPrice,
            edit_customer_contact: editCustomerContact,
            edit_integration_bridge: editIntegration,
            zip_five: zip_five && Number(zip_five[0]),
            zip_four: zip_five && Number(zip_five[1]),
            private_label_zip_five:
              private_label_zip_Data && Number(private_label_zip_Data[0]),
            private_label_zip_four:
              private_label_zip_Data && Number(private_label_zip_Data[1]),
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    //text and number
    if (e.target.type === "email") {
      setValues({ ...values, [name]: value });
    } else if (name === "zip_five") {
      const value1 = value.replace(/[^0-9.]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, zip_five: foo });
    } else if (name === "private_label_zip_code") {
      const value1 = value.replace(/[^0-9.]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, private_label_zip_code: foo });
    }
    //claser status values
    clearStatus();
  };

  //useEffect for editCustomer
  useEffect(() => {
    if (editCustomerQuery.isSuccess) {
      const {
        zip_five,
        zip_four,
        vendor_id,
        logo,
        notice_to_purchaser_fees,
        private_label_zip_five,
        private_label_zip_four,
        private_label_logo,
        ...othersData
      } = editCustomerQuery.data?.data[0];
      //storing venodro id as permnent for for AppmultiSelect
      setVendorIdConst(String(vendor_id));

      setPrice({ notice_to_purchaser_fees });

      var newArr = {
        ...othersData,
        zip_five: `${zip_five}${zip_four ? "-" + zip_four : ""}`,
        private_label_zip_code: `${private_label_zip_five}${
          private_label_zip_four ? "-" + private_label_zip_four : ""
        }`,
        logo: atob(logo),
        private_label_logo: private_label_logo ? atob(private_label_logo) : "",
        vendor_id: String(vendor_id),
        add_customer_contact: [],
        delete_contact: [],
        edit_customer_contact: [],
        add_integration_bridge: [],
        edit_integration_bridge: [],
        delete_integration_bridge: [],
        edit_customer_product_price: [],
        delete_customer_product_price: [],
      };
      setValues(newArr);
      custmerProductController(vendor_id, false);

      //state countyList
      stateCountyIdList({
        id: editCustomerQuery.data?.data[0]?.vendor_id,
        token: token,
      });
    }
    if (editCustomerQuery.isError) {
      setGetError(
        editCustomerQuery.error?.data?.message ||
          editCustomerQuery.error.data ||
          (editCustomerQuery.error.error && "server unreachable!") ||
          editCustomerQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editCustomerQuery.isFetching]);

  // useEffect for stateCountyList
  useEffect(() => {
    if (stateCountyListApi[1].isSuccess) {
      //storing new stateCountyLists base on vendor id
      setEnableStateCountyList(stateCountyListApi[1].data?.data);
      const newData = stateCountyListApi[1].data?.data[0].children.map(
        ({ id, title, ...rest }) => {
          return { id: id, state_name: title };
        }
      );
      setProductStateList(newData);
    }
    if (stateCountyListApi[1].error) {
      setProductStateList([]);
      setGetError(
        stateCountyListApi[1].error?.data?.message ||
          (stateCountyListApi[1].error.error && "server unreachable!") ||
          stateCountyListApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [stateCountyListApi[1].isLoading]);

  //multiSelect County
  const multiSelectCountyHandler = (value) => {
    setValues({ ...values, county_id_list: value });
    clearStatus();
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("customerError");
    }
  }, [getError]);

  function getProductName(id) {
    const filetrData = productTypeList.filter((ele) => {
      return ele.id === id;
    });
    return filetrData[0].name;
  }

  var variables = [];
  values?.product_list_values?.forEach((ele) => {
    if (ele) {
      variables.push({
        [getProductName(Number(ele))]: [
          ...values?.customer_product_price_values?.filter((item) => {
            return item?.product_type_id === Number(ele);
          }),
        ],
        product_id: Number(ele),
      });
    }
  });

  return (
    <div className="position-relative">
      <div className=" border-bottom border-2" id="customerError">
        <h5>{editId ? "Edit Customer" : "Add Customer"}</h5>
      </div>
      {getError && (
        <div className="mt-3">
          <Alert message={getError} type="error" showIcon />{" "}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {(addNewCustomer[1].isFetching ||
          updateNewCustomer[1].isLoading ||
          editCustomerQuery.isFetching) && <AppSpinner />}
        <Row className={`${editCustomerQuery.isFetching ? "invisible" : ""} `}>
          <div className="col-12 col-lg-9">
            <div className="row">
              {inputs.map((input) => (
                <>
                  {input.type === "checkbox" && !input?.is_private_label && (
                    <AppCheckbox {...input} handleChange={appCheckboxHandler} />
                  )}

                  {/* //input file  */}
                  {input.type === "file" && !input?.is_private_label && (
                    <AppFile
                      _id={"customer_file"}
                      key={input.id}
                      fileVal={(e) => {
                        setValues({
                          ...values,
                          logo: e.logo,
                          logo_name: e.name,
                        });
                      }}
                      size={2000000}
                      label={"Logo"}
                      afterClose={afterClose}
                      initailVal={
                        editId
                          ? {
                              fileName: values?.logo_name,
                              imgPreview: values?.logo,
                            }
                          : ""
                      }
                      column={4}
                    />
                  )}
                  {(input.type === "text" ||
                    input.type === "email" ||
                    input.type === "tel") &&
                    input.name !== "phone_number" &&
                    !input?.is_private_label && (
                      <FormInput
                        key={input.id}
                        defaultSet={afterClose}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                  {input.name === "notice_to_purchaser_fees" && (
                    <AppNumeric
                      {...input}
                      defaultSet={afterClose}
                      value={price[input.name]}
                      thousandSeparator={true}
                      placeholder={input.placeholder}
                      prefix={"$"}
                      decimalScale={5}
                      maxLength={12}
                      onChange={(values) => {
                        setPrice({
                          ...price,
                          [input.name]: values,
                        });
                      }}
                    />
                  )}

                  {input.name === "phone_number" && (
                    <div className="col-12 col-md-4">
                      <Row className="mt-3">
                        <label className="ms-1 heading-5 text-dark ">
                          {input.label}
                        </label>
                      </Row>
                      <Input
                        className="input-field mt-0 pb-2 w-100 heading-5"
                        maxLength={14}
                        minLength={14}
                        placeholder={input.placeholder}
                        defaultCountry="US"
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        value={values.phone_number}
                        onChange={(value) => {
                          setGetError(false);
                          setValues({ ...values, phone_number: value });
                        }}
                      />
                    </div>
                  )}
                  {!stateCountyListApi[1].isLoading &&
                    input.type === "multiselect" &&
                    !input?.is_private_label && (
                      <>
                        <label className="ms-1 heading-5 mt-3 mb-2 text-dark ">
                          Customer Type
                          <span className="text-danger">*</span>
                        </label>
                        <MyMultiSelect
                          key={input.id}
                          placeholder="Select Customer Type"
                          afterClose={afterClose}
                          initailVal={editId ? values?.customer_type : []}
                          selectedValues={(value) => {
                            values.customer_type = value;
                            setGetError(false);
                          }}
                          list={
                            customerTypeList || [
                              { id: "1", name: "no productList found" },
                            ]
                          }
                        />
                      </>
                    )}
                  {/* //appSelcet for vendors name  */}
                  {(input.type === "select" &&
                    input.name === "vendor_id") &&
                    !input?.is_private_label &&  tableColumn.map(item=>item.vendor ? (
                      <>
                        {
                            <AppSelect
                              key={input.id}
                              {...input}
                              defaultSet={afterClose}
                              onChange={selectHandler}
                              options={
                                <>
                                  <option value={""}>Select Vendor</option>
                                  {input.options.map((ele) => {
                                    return (
                                      <option
                                        key={ele.vendor_id}
                                        value={ele.vendor_id}
                                      >
                                        {ele.vendor_name}
                                      </option>
                                    );
                                  })}
                                </>
                              }
                            />
                        }
                      </>
                    ):'')}
                  {/* //appSelcet for county  */}
                  {input.type === "select" &&
                    input.name === "county_id" &&
                    !input?.is_private_label && (
                      <AppSelect
                        key={input.id}
                        {...input}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select County</option>
                            {input.options.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {ele.county_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  {/* //appSelcet for state  */}
                  {input.type === "select" &&
                    input.name === "state_id" &&
                    !input?.is_private_label && (
                      <AppSelect
                        key={input.id}
                        {...input}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select State</option>
                            {input.options.map((ele) => {
                              return (
                                <option
                                  data_name={ele.state_name}
                                  key={ele.id}
                                  value={ele.id}
                                >
                                  {ele.state_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  {input.type === "switch" && !input?.is_private_label && (
                    <AppSwitch
                      key={input.id}
                      value={values[input.name]}
                      onChange={appSwitchHanler}
                      {...input}
                    />
                  )}
                </>
              ))}
            </div>
          </div>

          <div className="col-12 col-lg-3 mt-4 mt-md-0">
            <label htmlFor="" className="mt-3  heading-5 text-dark">
              Active State and County<span className="text-danger">*</span>
            </label>
            {stateCountyListApi[1].isLoading && <AppSpinner />}
            <div
              className={`${
                stateCountyListApi[1].isLoading ? "invisible" : ""
              } `}
            >
              {!stateCountyListApi[1].isLoading &&
              (values.vendor_id || user.vendor_id) ? (
                <AppMultiSelect
                  height={300}
                  _id={"customer_multicounty"}
                  //default state
                  defaultSet={afterClose}
                  placeholder={"Search State/County"}
                  errorMessage={"State/County"}
                  //here passing vendor id
                  //1.vendorIdConst is permnent vendorId while it was storred in editCustomer query with setVendorIdConst
                  //1.values.vendor_id is default vendorId while it was storred in editCustomer query
                  expandCompare={{
                    vendorIdConst,
                    vendor_id: String(values["vendor_id"]),
                  }}
                  treeData={enableStateCountyList}
                  //initial values acceteps only ["5","6","7"] format
                  initialVal={editId ? values.county_id_list : []}
                  onSelectVal={multiSelectCountyHandler}
                />
              ) : (
                <div className="my-2">
                  <Empty description={<span>No Active State County</span>} />
                </div>
              )}
            </div>
          </div>
        </Row>
        <div className={`${editCustomerQuery.isFetching ? "invisible" : ""} `}>
          {/* if custome is also brnach  */}
          {values?.is_private_label && (
            <Row className="border p-2  mt-5  rounded-2">
              <h6 className="fs-18 fw-bold p-0">
                Private Labels<span className="text-danger">*</span>
              </h6>
              <div className="row mx-auto pb-5 shadow-sm rounded-2 mt-2 py-2 private-lable bg-white">
                {inputs.map((input) => (
                  <>
                    {input.name === "private_label_phone_number" && (
                      <div className="col-12 col-md-3">
                        <Row className="mt-3">
                          <label className="ms-1 heading-5 text-dark ">
                            {input.label}
                          </label>
                        </Row>
                        <Input
                          className="input-field mt-0 pb-2 w-100 heading-5"
                          maxLength={14}
                          minLength={14}
                          placeholder={input.placeholder}
                          defaultCountry="US"
                          value={values.private_label_phone_number}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onChange={(value) => {
                            setGetError(false);
                            setValues({
                              ...values,
                              private_label_phone_number: value,
                            });
                          }}
                        />
                      </div>
                    )}
                    {/* //input file  */}
                    {input.type === "file" && input?.is_private_label && (
                      <AppFile
                        _id={"customer_private_label_file"}
                        fileVal={(e) =>
                          setValues({
                            ...values,
                            private_label_logo: e.logo,
                            private_label_logo_name: e.name,
                          })
                        }
                        size={2000000}
                        label={"Logo"}
                        afterClose={afterClose}
                        initailVal={
                          editId
                            ? {
                                fileName: values?.private_label_logo_name,
                                imgPreview: values?.private_label_logo,
                              }
                            : ""
                        }
                        column={3}
                      />
                    )}
                    {(input.type === "text" || input.type === "email") &&
                     ( input.name !== "private_label_phone_number" && input.name !== "name") &&
                      input?.is_private_label && (
                        <FormInput
                          key={input.id}
                          defaultSet={afterClose}
                          {...input}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    {input.name === "name" && tableColumn.map(item=>item.customer ?   <FormInput
                            key={input.id}
                            {...input}
                            defaultSet={afterClose}
                            value={values[input.name]}
                            onChange={onChange}
                          />:'')}
                    {input.type === "switch" && input?.is_private_label && (
                      <AppSwitch
                        key={input.id}
                        value={values[input.name]}
                        onChange={appSwitchHanler}
                        {...input}
                      />
                    )}

                    {/* //appSelcet for state  */}
                    {input.type === "select" &&
                      input.name === "private_label_state_id" &&
                      input?.is_private_label && (
                        <AppSelect
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option>select State</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                  </>
                ))}
              </div>
            </Row>
          )}

          {!stateCountyListApi[1].isLoading && (
            <Row className={`${values?.is_private_label ? "mt-2" : "mt-5"}`}>
              <div className="col-12 px-2">
                {/* <Contacts /> */}
                <MultiContacts
                  roleList={[4, 5, 12]}
                  whileEdit={(value) => {
                    setGetError(false);
                    if (editId) {
                      setValues({
                        ...values,
                        add_customer_contact: [
                          ...values?.add_customer_contact,
                          value,
                        ],
                      });
                    }
                  }}
                  //update
                  updateContact={(value) => {
                    setGetError(false);
                    if (editId) {
                      if (
                        values.add_customer_contact?.length > 0 &&
                        values.add_customer_contact?.find(
                          (val) => val["temp_id"] === value.temp_id
                        )
                      ) {
                        values.add_customer_contact.forEach((item) => {
                          if (item.temp_id === value.temp_id) {
                            item.first_name = value.first_name;
                            item.middle_name = value.middle_name;
                            item.last_name = value.last_name;
                            item.contact_email = value.contact_email;
                            item.is_primary = value.is_primary;
                            item.phone_number = value.phone_number;
                            item.work_phone_number = value.work_phone_number;
                            item.user_type_id = value.user_type_id;
                            item.user_type_name = value.user_type_name;
                            item.position = value.position;
                          }
                        });
                      } else {
                        setValues({
                          ...values,
                          add_customer_contact: [
                            ...values?.add_customer_contact,
                            value,
                          ],
                        });
                      }

                      if (
                        values.edit_customer_contact?.length > 0 &&
                        values.edit_customer_contact?.find(
                          (val) => val["id"] === value.id
                        )
                      ) {
                        values.edit_customer_contact.forEach((item) => {
                          if (item.id === value.id) {
                            item.first_name = value.first_name;
                            item.middle_name = value.middle_name;
                            item.last_name = value.last_name;
                            item.contact_email = value.contact_email;
                            item.is_primary = value.is_primary;
                            item.phone_number = value.phone_number;
                            item.work_phone_number = value.work_phone_number;
                            item.user_type_id = value.user_type_id;
                            item.user_type_name = value.user_type_name;
                            item.position = value.position;
                          }
                        });
                      } else {
                        setValues({
                          ...values,
                          edit_customer_contact: [
                            ...values?.edit_customer_contact,
                            value,
                          ],
                        });
                      }
                    }
                  }}
                  //delete record
                  deleteContact={(value) => {
                    setGetError(false);
                    if (editId) {
                      if (value) {
                        setValues({
                          ...values,
                          delete_contact: value?.id
                            ? [...values?.delete_contact, String(value?.id)]
                            : values?.delete_contact,
                          edit_customer_contact:
                            values.edit_customer_contact?.filter((e) => {
                              return (
                                (e.id || e?.temp_id) !==
                                (value.id || value?.temp_id)
                              );
                            }),
                        });
                      } else {
                        setValues({
                          ...values,
                          add_customer_contact:
                            values.add_customer_contact?.filter((e) => {
                              return (
                                (e.id || e?.temp_id) !==
                                (value.id || value?.temp_id)
                              );
                            }),
                        });
                      }
                    }
                  }}
                  afterClose={afterClose}
                  initailValArr={editId ? values?.contact_list_values : []}
                  addFormVal={(value) => {
                    setValues({ ...values, contact_list_values: value });
                    setMultiContacts(value);
                    setGetError(false);
                  }}
                />
              </div>
              {!stateCountyListApi[1].isLoading && values.vendor_id && (
                <div className="row my-2">
                  <label className="ms-1 heading-5 mt-2 mb-2 text-dark ">
                    <h6 className="fs-18 fw-bold me-2">
                      Products <span className="text-danger">*</span>
                    </h6>
                  </label>
                  {isProductList ? (
                    <MyMultiSelect
                      _id={"customer_products"}
                      placeholder="Select Products"
                      afterClose={afterClose}
                      initailVal={editId ? values?.product_list_values : []}
                      selectedValues={(value) => {
                        setValues({
                          ...values,
                          product_list_values: value,
                        });
                        setGetError(false);
                      }}
                      productGet={(value) => setProducts(value)}
                      list={
                        isProductList || [
                          { id: "1", name: "no productList found" },
                        ]
                      }
                    />
                  ) : (
                    <div className="ms-1">
                      {values.vendor_id && (
                        <Alert
                          message="Productlist not found!"
                          type="error"
                          showIcon
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {
                variables?.map((items, index) => {
                  return (
                    <div key={index} className="col-12 px-2">
                      <div>
                        {!stateCountyListApi[1].isLoading && isProductList && (
                          <ProductTypeComp
                            produt_id={items.product_id}
                            stateList={productStateList}
                            multiCountyListVal={values.county_id_list || []}
                            title={String(Object.keys(items)[0])}
                            updateContact={(value) => {
                              if (editId) {
                                if (
                                  values.edit_customer_product_price?.length >
                                    0 &&
                                  values.edit_customer_product_price?.find(
                                    (val) => val["id"] === value.id
                                  )
                                ) {
                                  values.edit_customer_product_price.forEach(
                                    (item) => {
                                      if (item.id === value.id) {
                                        item.product_type_id =
                                          value.product_type_id;
                                        item.included = value.included;
                                        item.is_auto = value.is_auto;
                                        item.is_pre = value.is_pre;
                                        item.retail = value.retail;
                                        item.retail_plus = value.retail_plus;
                                        item.wholesale = value.wholesale;
                                        item.wholesale_plus =
                                          value.wholesale_plus;
                                        item.state_id = value.state_id;
                                        item.county_id = value.county_id;
                                      }
                                    }
                                  );
                                } else {
                                  setValues({
                                    ...values,
                                    edit_customer_product_price: [
                                      ...values?.edit_customer_product_price,
                                      value,
                                    ],
                                  });
                                }
                              }
                            }}
                            //delete record
                            deleteContact={(value) => {
                              if (editId) {
                                if (value?.id) {
                                  setValues({
                                    ...values,
                                    delete_customer_product_price: [
                                      ...values?.delete_customer_product_price,
                                      String(value.id),
                                    ],
                                  });
                                } else {
                                  setValues({
                                    ...values,
                                    add_customer_product_price:
                                      values.add_customer_product_price?.filter(
                                        (e) => {
                                          return e.temp_id !== value.temp_id;
                                        }
                                      ),
                                  });
                                }
                              }
                            }}
                            getProductsList={products}
                            addFormVal={(value) => {
                              multiProducts[Object.keys(value)] =
                                value[Object.keys(value)];
                              setGetError(false);
                            }}
                            afterClose={afterClose}
                            initailValArr={
                              items[String(Object.keys(items)[0])] || []
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })

                /* //multi productTypes  */
              }

              {/* //multi integration bridge  */}
              {!stateCountyListApi[1].isLoading && (
                <div className="col-12 px-2 mt-2">
                  <IntegrationBridge
                    whileEdit={(value) => {
                      if (editId) {
                        setValues({
                          ...values,
                          add_integration_bridge: [
                            ...values?.add_integration_bridge,
                            value,
                          ],
                        });
                      }
                    }}
                    //update
                    updateContact={(value) => {
                      if (editId) {
                        if (
                          values.add_integration_bridge?.length > 0 &&
                          values.add_integration_bridge?.find(
                            (val) => val["temp_id"] === value.temp_id
                          )
                        ) {
                          values.add_integration_bridge.forEach((item) => {
                            if (item.temp_id === value.temp_id) {
                              item.integration_bridge_id =
                                value.integration_bridge_id;
                              item.partner_id = value.partner_id;
                              item.password = value.password;
                              item.username = value.username;
                            }
                          });
                        }

                        if (
                          values.edit_integration_bridge?.length > 0 &&
                          values.edit_integration_bridge?.find(
                            (val) => val["id"] === value.id
                          )
                        ) {
                          values.edit_integration_bridge.forEach((item) => {
                            if (item.id === value.id) {
                              item.integration_bridge_id =
                                value.integration_bridge_id;
                              item.partner_id = value.partner_id;
                              item.password = value.password;
                              item.username = value.username;
                            }
                          });
                        } else {
                          setValues({
                            ...values,
                            edit_integration_bridge: [
                              ...values?.edit_integration_bridge,
                              value,
                            ],
                          });
                        }
                      }
                    }}
                    //delete
                    deleteContact={(value) => {
                      if (editId) {
                        if (value) {
                          setValues({
                            ...values,
                            delete_integration_bridge: value?.id
                              ? [
                                  ...values?.delete_integration_bridge,
                                  String(value?.id),
                                ]
                              : values?.delete_integration_bridge,
                            edit_integration_bridge:
                              values.edit_integration_bridge?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                            add_integration_bridge:
                              values.add_integration_bridge?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                          });
                        }
                      }
                    }}
                    addFormVal={(value) => setMultiIntegration(value)}
                    getIntegration={integrationBridgeList}
                    afterClose={afterClose}
                    initailValArr={editId ? values?.integration_bridge : []}
                  />
                </div>
              )}
            </Row>
          )}

          <Row
            className={`${editCustomerQuery.isFetching ? "invisible" : ""} `}
          >
            <Col className="my-3">
              <div className="d-flex justify-content-center">
                <div className="me-md-2 ">
                  <MyButton
                    onClick={handleReset}
                    title={"CLEAR"}
                    variant={"secondary-md"}
                  />
                </div>

                <MyButton title={"SUBMIT"} variant={"primary-md"} />
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};
