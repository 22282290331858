import AppTable from "../../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
	useSupportCustomerListQuery,
	useUpdateSupportCustomerStatusMutation,
} from "../../../../Redux/Services/supportCustomer";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import SearchColumns from "../../../../Components/SearchColumns";
import { Input, notification, Switch } from "antd";
import { useEffect } from "react";

export const Customer = ({ refresh, childTab }) => {
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	//redux data store
	const { token } = useSelector((state) => state.auth);
	const { authColumn } = useSelector((state) => state.flagRoles);

	//calling rtk query hook for geting customerType list
	const supportCustomer = useSupportCustomerListQuery(token);

	//calling rtk query hook for geting VendorsType list
	const updateSupportCustomerStatusApi =
		useUpdateSupportCustomerStatusMutation();
	const [updateSupportCustomerStatus] = updateSupportCustomerStatusApi;

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	//create function for customer fields
	function matchColumn(value) {
		if (authColumn.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}

	const columns = [
		{
			title: (
				<span>
					Vendor Name{" "}
					{getsortfield === "vendor_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "vendor_name",
			align: "center",
			key: "vendor_name",
			sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
			width: 200,
			...SearchColumns("vendor_name", "Vendor Name"),
		},
		{
			title: (
				<span>
					Customer{" "}
					{getsortfield === "customer_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "customer_name",
			key: "customer_name",
			align: "center",
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			width: 200,
			...SearchColumns("customer_name", "Customer Name"),
		},
		{
			title: (
				<span>
					State{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			width: 200,
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: (
				<span>
					County{" "}
					{getsortfield === "county_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "county_name",
			key: "county_name",
			sorter: (a, b) => a.county_name.localeCompare(b.county_name),
			width: 200,
			align: "center",
			...SearchColumns("county_name", "County Name"),
		},
		{
			title: <span>Active Status</span>,
			className: `${matchColumn("is_active") ? "" : "d-none"}`,
			dataIndex: "is_active",
			key: "is_active",
			align: "center",
			width: matchColumn("is_active") && 100,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		if (supportCustomer.isSuccess) {
			setData(supportCustomer.data?.data);
			// setColumn(columns);
		}
	}, [supportCustomer]);

	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value === true ? 1 : 0,
		};
		updateSupportCustomerStatus({
			data,
			id: row.id,
			token: token,
		});
	}
	//update Customers details
	useEffect(() => {
		if (updateSupportCustomerStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateSupportCustomerStatusApi[1].data?.message,
			});
		}
		if (updateSupportCustomerStatusApi[1].error) {
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateSupportCustomerStatusApi[1].error.data.message ||
					updateSupportCustomerStatusApi[1].error.error ||
					updateSupportCustomerStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateSupportCustomerStatusApi[1].isLoading]);
	useEffect(() => {
		setSearch("");
		if (childTab === "Customer") {
			supportCustomer.refetch();
		}
	}, [childTab]);
	return (
		<>
			{supportCustomer.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
					</div>
					<div style={{ clear: "both" }}></div>
					<AppTable
						loading={updateSupportCustomerStatusApi[1].isLoading}
						search={search}
						dataSource={data}
						columns={columns}
						sort={sort}
					/>
				</>
			)}
		</>
	);
};
