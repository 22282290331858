import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Collapse,
  notification,
  Popconfirm,
  Radio,
  Tabs,
  Modal,
} from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { MdClose, MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import AppSelect from "../../../../../Components/AppSelect/AppSelect";
import {
  useAddParcelToHoaMutation,
  useEditParcelHOAMutation,
  useGetHoaParcelStatusMutation,
  useHoaCommentsMutation,
  useParcelHoaDeleteMutation,
} from "../../../../../Redux/Services/Parcels";
import NumberInput from "react-phone-number-input/input";
import AppInput from "../../../../../Components/AppInput/AppInput";
import HOAComments from "../HOAINFO/HOAComments";
import { TabPane } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useFilterHoaBasedStateCountyMutation } from "../../../../../Redux/Services/hoamgtinfo";
import MultiDescription from "../../../../../Components/MultiDescription/MultiDescription";
import {
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../../App";
import {
  useHoaCollectorBranchInfoMutation,
  useHoaMgmtPreFillMutation,
  useHoaPreFillMutation,
  useHoaUpdateInfoMutation,
  useParcelHoaStatusUpdateMutation,
} from "../../../../../Redux/Services/Hoa";

import { useCycleListQuery } from "../../../../../Redux/Services/Cycles";
import {
  asendingList,
  mobileFormat,
  removeArrayBlank,
  removeBlank,
} from "../../../../../Controllers/Functions";
import { parcelContext } from "../../OrderEditor";
import {
  useAutoConflictsStatusUpdateMutation,
  useValidateConflictsMutation,
} from "../../../../../Redux/Services/Orders";
import { FiEdit } from "react-icons/fi";
import {
  useHoaStatusListQuery,
  useHoaTypeListQuery,
} from "../../../../../Redux/Services/hoaType";
import AppCheckbox from "../../../../../Components/AppCheckbox/AppCheckbox";
import AppNumeric from "../../../../../Components/AppNumeric/AppNumeric";
import AppButton from "../../../../../Components/AppButton/AppButton";
import AppTextEditor from "../../../../../Components/AppTextEditor/AppTextEditor";
import { BiRefresh } from "react-icons/bi";
const { Panel } = Collapse;
const { confirm } = Modal;
const randomPass = require("secure-random-password");
const parse = require("html-react-parser");
const initialState = {
  hoa_name: "",
  hoa_cycle_name: "",
  hoa_date_verified: "",
  hoa_type_id: "",
  hoa_website: "",
  hoa_address_one: "",
  hoa_address_two: "",
  is_direct_contacts: false,
  is_self_collected: false,
  hoa_not_found_comments: "",
  hoa_address_city: "",
  hoa_cycle_id: "",
  hoa_address_zip: "",
  selDisplay: "",
  selReadOnly: "",
  hoa_collector_name: "",
  hoa_type_name: "",
  hoa_collector_id: "",
  hoa_collector_website: "",
  hoa_collector_address_one: "",
  hoa_collector_address_one: "",
  hoa_collector_phone: "",
  hoa_collector_city: "",
  hoa_collector_zip: "",
  phone_number: "",
  hoa_collector_fax: "",
  email: "",
  hoamgt_selDisplay: "",
  hoamgt_selReadOnly: "",
  fees: "",
  balance: "",
  status_id: "1",
  fees_type: "1",
  is_rush: true,
  hoa_collector_branch_name: "",
  hoa_collector_branch_id: "",
  hoa_collector_fees_list: [],
  hoa_information_fees_list: [],
  order_parcel_hoa_fees_list: [],
  apply_hoa_to_all: false,
  is_hoa: true,
};

export default function HoaData({
  editId,
  parcelHoaList,
  setMode,
  hoanotFoundCall,
  mode,
  showIsHoa,
  isHoaFound,
  orderMemoData,
  dispaceCertData = [],
  readCertData = [],
  refetchHoaList = false,
  validateConflicts,
  autoStatusUpdateResult,
}) {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;

  const [parcelHOAInfo, parcelHOAInfoResult] = useEditParcelHOAMutation();
  const [hoaListOptions, setHoaListOptions] = useState([]);
  const [getError, setGetError] = useState(false);
  const [hoaStaus, setHoaStatus] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [isValueUpdate, setIsValueUpdate] = useState(false);

  const [hoaStatusOptions, setHoaStatusOptions] = useState([]);
  //states
  const [cycleListData, setCycleListData] = useState([]);
  const [hoaMgmtListOptions, setHoaMgmtListOptions] = useState([]);
  const [hoaTypeListData, setHoaTypeListData] = useState([]);
  const [branchListOptions, setBranchListOptions] = useState([]);
  const [values, setValues] = useState(initialState);

  const [hoa_read_only_comments, setHoa_read_only_comments] = useState("");
  const [hoa_display_on_cert_comments, setHoa_display_on_cert_comments] =
    useState("");
  const [commetsView, setCommetsView] = useState("display-cert");
  const [hoaMgmtCommetsView, setHoaMgmtCommetsView] = useState("display-cert");

  //fees
  const [parcel_hoa_fees, setParcel_hoa_fees] = useState([]);

  const [parcelHoaFessList, setParcelHoaFessList] = useState([]);
  const [parcelHoaCollectorFessList, setParcelHoaCollectorFessList] = useState(
    []
  );
  const [parcel_hoa_information_fees, setParcel_hoa_information_fees] =
    useState([]);

  const [order_parcel_hoa_fees, setOrder_parcel_hoa_fees] = useState([]);
  const [add_order_parcel_hoa_fees, setAdd_order_parcel_hoa_fees] = useState(
    []
  );
  const [edit_order_parcel_hoa_fees, setEdit_order_parcel_hoa_fees] = useState(
    []
  );
  const [delete_order_parcel_hoa_fees, setDelete_order_parcel_hoa_fees] =
    useState([]);

  const [
    hoa_collector_read_only_comments,
    setHoa_collector_read_only_comments,
  ] = useState("");

  const [
    hoa_collector_display_on_cert_comments,
    setHoa_collector_display_on_cert_comments,
  ] = useState("");

  //redux store
  const { token, user } = useSelector((state) => state.auth);
  const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

  //api hoa status list
  const HoaStatus = useHoaStatusListQuery(token, {
    skip: !parcelController.parcelId,
  });

  const [hoaComments, hoaCommentsResult] = useHoaCommentsMutation();

  //calling rtk query hook for geting cycle list
  const cycleListApi = useCycleListQuery(token);

  //calling redux stor for hoaType list
  const hoaTypeListApi = useHoaTypeListQuery(token);

  //update parcel info
  const [getParcelHoaStatus, getParcelHoaStatusResult] =
    useGetHoaParcelStatusMutation();

  //update status base on conflict for auto update status info
  // const [autoStatusUpdate, autoStatusUpdateResult] =
  // 	useAutoConflictsStatusUpdateMutation();

  //update conflicts info
  // const [validateConflicts, validateConflictsResult] =
  // 	useValidateConflictsMutation();

  //add hoa to parcel
  const [addParcelHoa, addParcelHoaResult] = useAddParcelToHoaMutation();

  //add hoa to parcel
  const [updateHOAParcel, updateHOAParcelResult] = useHoaUpdateInfoMutation();

  //update parcel info
  const [updateParcelHoaStatus, updateParcelHoaStatusResult] =
    useParcelHoaStatusUpdateMutation();

  //filter hoaname based on state and county
  const [filterHoaname, filterHoanameResult] =
    useFilterHoaBasedStateCountyMutation();

  //prefill hoamgmt data
  const [hoaMgmtPrefill, hoaMgmtPrefillResult] = useHoaMgmtPreFillMutation();

  //prefill hoa data
  const [hoaPrefill, hoaPrefillResult] = useHoaPreFillMutation();

  //prefill hoamgmt branch data
  const [hoaBranchCollector, hoaBranchCollectorResult] =
    useHoaCollectorBranchInfoMutation();

  //Dlete parcel hoa
  const [deleteparcelhoa, deleteparcelhoaResult] = useParcelHoaDeleteMutation();

  const hoaNames = [];
  parcelHoaList?.map(({ hoa_name }) => {
    hoaNames.push(hoa_name);
  });

  const hoaNameFilterList = hoaListOptions?.filter(({ name }) => {
    return !hoaNames.includes(name);
  });

  // useEffect for hoaType list
  useEffect(() => {
    if (hoaTypeListApi.isSuccess) {
      setHoaTypeListData(
        asendingList(
          hoaTypeListApi.data.data.map(({ name, id }) => {
            return { name, id };
          }),
          "name"
        )
      );
    }
    if (hoaTypeListApi.error) {
      setHoaTypeListData([]);
    }
  }, [hoaTypeListApi.isFetching]);

  //after adding
  useEffect(() => {
    if (hoaCommentsResult.isSuccess) {
      setMode("view");
      //refetch api for hoa list
      // hoanotFoundCall();
      refetchHoaList();
      validateConflicts({
        data: { order_id: orderMemoData.id },
        token,
      });
    }
    if (hoaCommentsResult.isError) {
      setGetError(
        hoaCommentsResult.error?.data?.error ||
          hoaCommentsResult.error?.data?.message ||
          hoaCommentsResult.error.data?.msg?.sqlMessage ||
          (hoaCommentsResult.error.error && "server unreachable!") ||
          hoaCommentsResult.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: hoaCommentsResult.isLoading });
  }, [hoaCommentsResult.isLoading]);

  // useEffect for get parcel  status
  useEffect(() => {
    if (autoStatusUpdateResult.isSuccess) {
      //get the parcel hoa status
      getParcelHoaStatus({ parcel_id: parcelController.parcelId, token });
    }
  }, [autoStatusUpdateResult.isLoading]);

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (updateParcelHoaStatusResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateParcelHoaStatusResult.data?.message,
      });
      setEditStatus(false);
      //validate conflicts
      validateConflicts({
        data: { order_id: orderMemoData.id },
        token,
      });
    }
    if (updateParcelHoaStatusResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          updateParcelHoaStatusResult.error?.data?.message ||
          updateParcelHoaStatusResult.error?.error ||
          updateParcelHoaStatusResult.error.data?.msg?.sqlMessage,
      });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: updateParcelHoaStatusResult.isLoading,
    });
  }, [updateParcelHoaStatusResult.isLoading]);

  // useEffect for status list
  useEffect(() => {
    if (HoaStatus.isSuccess) {
      setHoaStatusOptions(HoaStatus.data?.data);
      //valifate confilcts
    }
    if (HoaStatus.isError) {
      setGetError(
        HoaStatus.error?.data?.message ||
          HoaStatus.error.error ||
          HoaStatus.error.data?.msg?.sqlMessage
      );
      setHoaStatusOptions([]);
    }
    //global spinner
  }, [HoaStatus.isFetching]);

  // useEffect for countylist based on state selection
  // useEffect(() => {
  // 	if (validateConflictsResult.isSuccess) {
  // 		//auto update status
  // 		autoStatusUpdate({ data: { order_id: orderMemoData.id }, token });
  // 	}
  // 	if (validateConflictsResult.isError) {
  // 		setGetError(
  // 			validateConflictsResult.error?.data?.message ||
  // 				validateConflictsResult.error.error ||
  // 				validateConflictsResult.error.data?.msg.sqlMessage
  // 		);
  // 	}
  // }, [validateConflictsResult.isLoading]);

  // useEffect for get parcel hoa status
  useEffect(() => {
    if (getParcelHoaStatusResult.isSuccess) {
      setHoaStatus(getParcelHoaStatusResult.data?.data[0]?.hoa_status_id);
    }
    if (getParcelHoaStatusResult.isError) {
      setHoaStatus("");
    }
  }, [getParcelHoaStatusResult.isLoading]);

  //useEffect for cycle list
  useEffect(() => {
    if (cycleListApi.isSuccess) {
      setCycleListData(asendingList(cycleListApi.data?.data, "name"));
    } else {
      setCycleListData([]);
    }
  }, [cycleListApi.isFetching]);

  // useEffect for add hoa
  useEffect(() => {
    if (updateHOAParcelResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateHOAParcelResult.data?.message,
      });

      //get the parcel hoa status
      getParcelHoaStatus({ parcel_id: parcelController.parcelId, token });

      // //parcel hoa info
      // parcelHOAInfo({
      //   id: data.id,
      //   token,
      // });

      //calling api for parcel api validate
      validateConflicts({
        data: { order_id: orderMemoData.id },
        token,
      });

      // if (!data.is_hoa) {
      //   //parcel hoa coments
      //   hoaNotFoundCmments({
      //     id: info.id,
      //     token,
      //   });
      // }
      setMode("view");
      //parcel hoa
      parcelHOAInfo({ id: editId, token });
    }
    if (updateHOAParcelResult.error) {
      setGetError(
        updateHOAParcelResult.error?.data?.error ||
          updateHOAParcelResult.error?.data?.message ||
          updateHOAParcelResult.error.data?.msg?.sqlMessage ||
          (updateHOAParcelResult.error.error && "server unreachable!") ||
          updateHOAParcelResult.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: updateHOAParcelResult.isLoading });
  }, [updateHOAParcelResult.isLoading]);

  // useEffect for add hoa
  useEffect(() => {
    if (addParcelHoaResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addParcelHoaResult.data?.message,
      });
      // //parcel hoa info
      refetchHoaList();
      // //parcel hoa coments

      setMode("view");
    }
    if (addParcelHoaResult.error) {
      setGetError(
        addParcelHoaResult.error?.data?.error ||
          addParcelHoaResult.error?.data?.message ||
          addParcelHoaResult.error.data?.msg?.sqlMessage ||
          (addParcelHoaResult.error.error && "server unreachable!") ||
          addParcelHoaResult.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: addParcelHoaResult.isLoading });
  }, [addParcelHoaResult.isLoading]);

  useEffect(() => {
    if (hoaBranchCollectorResult.isSuccess) {
      const { address_one, address_two, city, zip, ...others } =
        hoaBranchCollectorResult.data.data[0];
      setValues({
        ...values,
        ...others,
        hoa_collector_address_one: address_one,
        hoa_collector_address_two: address_two,
        hoa_collector_city: city,
        hoa_collector_zip: zip,
      });
    }
    if (hoaBranchCollectorResult.error) {
      setGetError(
        hoaBranchCollectorResult.error?.data?.message ||
          hoaBranchCollectorResult.error.error ||
          hoaBranchCollectorResult.error.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: hoaBranchCollectorResult.isLoading });
  }, [hoaBranchCollectorResult.isLoading]);

  useEffect(() => {
    if (hoaPrefillResult.isSuccess) {
      const {
        hoa_address_zip_four,
        hoa_address_zip_five,
        hoa_information_fees_list,
        hoa_display_on_cert_comments,
        hoa_collector_id,
        hoa_read_only_comments,
        hoa_date_verified,
        hoa_address_two,
        hoa_address_city,
        hoa_address_one,
        ...others
      } = hoaPrefillResult.data.data[0];

      setHoa_display_on_cert_comments(hoa_display_on_cert_comments);
      setHoa_read_only_comments(hoa_read_only_comments);
      //refcth the api cycle api
      cycleListApi.refetch();

      const addDescription = [];
      if (hoa_information_fees_list?.length > 0) {
        hoa_information_fees_list?.map(({ amount, fees_description }) => {
          addDescription.push({
            fees_description,
            amount,
            tempId: randomPass.randomPassword({
              length: 9,
              characters: randomPass.digits,
            }),
          });
        });
      }
      //adding hoa fees
      setParcel_hoa_information_fees(addDescription);

      setValues({
        ...values,
        ...others,
        hoa_address_two,
        hoa_address_one,
        hoa_address_city,
        hoa_collector_id,
        add_parcel_hoa_information_fees: addDescription,
        hoa_information_fees_list: addDescription,
        parcel_hoa_information_fees: addDescription,
        hoa_date_verified: hoa_date_verified || "",
        hoa_address_zip: `${hoa_address_zip_five}${
          hoa_address_zip_four ? "-" + hoa_address_zip_four : ""
        }`,
      });

      //if hoa collectopr id found then make request for hoa collector
      if (hoa_collector_id) {
        hoaMgmtPrefill({ id: hoa_collector_id, token });
      }
    }
    if (hoaPrefillResult.error) {
      //clearHoa Comments
      clearCommets();

      setGetError(
        hoaPrefillResult.error?.data?.message ||
          hoaPrefillResult.error.error ||
          hoaPrefillResult.error.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: hoaPrefillResult.isLoading });
  }, [hoaPrefillResult.isLoading]);

  // useEffect for prefill data
  useEffect(() => {
    if (hoaMgmtPrefillResult.isSuccess) {
      const {
        hoa_collector_fees_list,
        hoa_collector_zip_five,
        hoa_collector_zip_four,
        hoa_collector_read_only_comments,
        hoa_collector_display_on_cert_comments,
        hoa_collector_branch_list,
        hoa_collector_address_one,
        hoa_branch_flag,
        phone_number,
        ...others
      } = hoaMgmtPrefillResult.data.data[0];

      setHoa_collector_display_on_cert_comments(
        hoa_collector_display_on_cert_comments
      );
      setHoa_collector_read_only_comments(hoa_collector_read_only_comments);

      const addDescription = [];
      if (hoa_collector_fees_list?.length) {
        hoa_collector_fees_list?.map((ele) => {
          addDescription.push({
            ...ele,
            tempId: randomPass.randomPassword({
              length: 9,
              characters: randomPass.digits,
            }),
          });
        });

        setValues({
          ...values,
          ...others,
          hoa_collector_address_one,
          add_parcel_hoa_fees: addDescription,
          parcel_hoa_fees: addDescription,
          hoa_collector_phone: phone_number,
          hoa_collector_fees_list: addDescription,
          hoa_collector_zip: `${hoa_collector_zip_five}${
            hoa_collector_zip_four ? "-" + hoa_collector_zip_four : ""
          }`,
        });
        // adding parecl hoa collector fees
        setParcel_hoa_fees(addDescription);

        if (!hoa_branch_flag) {
          setBranchListOptions([]);
          //seeting values
        } else {
          setBranchListOptions(hoa_collector_branch_list);
        }
      }
    }
    if (hoaMgmtPrefillResult.error) {
      clearHoaCollectorCommets();
      setGetError(
        hoaMgmtPrefillResult.error?.data?.message ||
          hoaMgmtPrefillResult.error.error ||
          hoaMgmtPrefillResult.error.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: hoaMgmtPrefillResult.isLoading });
  }, [hoaMgmtPrefillResult.isLoading]);

  // useEffect for hoa list
  useEffect(() => {
    if (filterHoanameResult.isSuccess) {
      setHoaListOptions(filterHoanameResult.data?.data?.hoa_information);
      setHoaMgmtListOptions(filterHoanameResult.data?.data?.hoa_collector);
    }
    if (filterHoanameResult.error) {
      setGetError(
        filterHoanameResult.error?.data?.message ||
          filterHoanameResult.error.error ||
          filterHoanameResult.error.data?.msg.sqlMessage
      );
      setHoaListOptions([]);
      setHoaMgmtListOptions([]);
    }
  }, [filterHoanameResult.isLoading]);

  // Delete parcel for hoa
  useEffect(() => {
    if (deleteparcelhoaResult.isSuccess) {
      //refetch parcel hoa list after delete
      refetchHoaList();
    }
    if (deleteparcelhoaResult.error) {
      setGetError(
        deleteparcelhoaResult.error?.data?.message ||
          deleteparcelhoaResult.error.error ||
          deleteparcelhoaResult.error.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: deleteparcelhoaResult.isLoading });
  }, [deleteparcelhoaResult.isLoading]);

  //after order id change
  useEffect(() => {
    if (orderMemoData.id) {
      const { state_id, county_id } = orderMemoData;
      //getting parcel information
      filterHoaname({ data: { state_id, county_id }, token });
    }
  }, [orderMemoData.id]);

  //get parcel status
  useEffect(() => {
    if (
      parcelController.parcelId &&
      values.is_hoa &&
      !values.hoa_not_found_comments &&
      editId
    ) {
      getParcelHoaStatus({ parcel_id: parcelController.parcelId, token });
    }
  }, [parcelController.parcelId]);

  const inputs = [
    {
      id: 1,
      name: "hoa_name",
      label: "HOA Name",
      type: "select",
      value: values?.hoa_name || "",
      errorMessage: "Please select hoa name!",
      column: "3",
      options: hoaNameFilterList,
      required: values.is_hoa,
      tab: "hoa",
      disabled: isHoaFound && mode === "edit",
    },
    {
      id: 2,
      name: "hoa_date_verified",
      label: "Verified Date",
      type: "date",
      value: values?.hoa_date_verified || "",
      errorMessage: "Please enter verified date!",
      column: "3",
      required: false,
      tab: "hoa",
    },
    {
      id: 3,
      name: "hoa_cycle_name",
      label: "Collection Cycle",
      placeholder: "Enter Collection Cycle",
      type: "select",
      value: values?.hoa_cycle_name || "",
      errorMessage: "Please enter collection cycle!",
      column: "3",
      options: cycleListData,
      required: false,
      tab: "hoa",
    },
    {
      id: 352,
      name: "hoa_type_name",
      label: "Hoa Type",
      type: "select",
      value: values?.hoa_type_name || "",
      // errorMessage: "Please enter collection cycle!",
      column: "3",
      options: hoaTypeListData,
      required: false,
      tab: "hoa",
    },
    {
      id: 4,
      name: "hoa_website",
      label: "Website",
      placeholder: "Enter Website",
      type: "text",
      value: values?.hoa_website || "",
      errorMessage: "Please enter hoa_website!",
      column: "3",
      required: false,
      tab: "hoa",
    },

    {
      id: 5,
      name: "hoa_address_one",
      type: "text",
      label: "Address 1",
      placeholder: "Enter Address 1",
      value: values?.hoa_address_one || "",
      column: "3",
      errorMessage: "Please enter address 1!",
      required: false,
      tab: "hoa",
    },
    {
      id: 6,
      name: "hoa_address_two",
      type: "text",
      label: "Address 2",
      placeholder: "Enter Address 2",
      value: values?.hoa_address_two || "",
      column: "3",
      errorMessage: "Please enter address 2!",
      required: false,
      tab: "hoa",
    },

    {
      id: 7,
      name: "hoa_address_city",
      type: "text",
      label: "City",
      placeholder: "Enter City",
      value: values?.hoa_address_city || "",
      column: "3",
      errorMessage: "Please enter city!",
      required: false,
      tab: "hoa",
    },

    {
      id: 8,
      name: "hoa_address_zip",
      type: "text",
      label: "Zip",
      placeholder: "Enter Zip",
      value: values?.hoa_address_zip || "",
      column: "3",
      errorMessage: "Please enter valid zip!",
      required: false,
      maxLength: "10",
      minLength: "5",
      tab: "hoa",
    },
    {
      id: 67454,
      name: "is_self_collected",
      checked: values.is_self_collected || "",
      type: "switch",
      column: "3",
      label: "Self Collected",
      margin: "4",
      tab: "hoa",
      disabled: mode === "view",
    },
    {
      id: 11234,
      name: "hoa_collector_name",
      label: "HOA Management",
      type: "select",
      value: values?.hoa_collector_name || "",
      errorMessage: "Please select hoa management!",
      column: "3",
      options: hoaMgmtListOptions,
      required: values.is_hoa,
      disabled: isHoaFound && mode === "edit",
      tab: "hoamgt",
    },
    {
      id: 11234,
      name: "hoa_collector_branch_name",
      label: "Select Branch Name",
      placeholder: "Select Branch Name",
      type: "select",
      value: values?.hoa_collector_branch_name || "",
      column: "3",
      options: branchListOptions,
      // disabled: mode === "edit",
      tab: "hoamgt",
    },
    {
      id: 9,
      name: "hoa_collector_address_one",
      type: "text",
      label: "Address 1",
      placeholder: "Enter Address 1",
      value: values?.hoa_collector_address_one || "",
      column: "3",
      errorMessage: "Please enter address 1!",
      required: false,
      tab: "hoamgt",
    },

    {
      id: 10,
      name: "hoa_collector_address_two",
      type: "text",
      label: "Address 2",
      placeholder: "Enter Address 2",
      value: values?.hoa_collector_address_two || "",
      column: "3",
      errorMessage: "Please enter address 2!",
      required: false,
      tab: "hoamgt",
    },

    {
      id: 11,
      name: "hoa_collector_city",
      type: "text",
      label: "City",
      placeholder: "Enter City",
      value: values?.hoa_collector_city || "",
      column: "3",
      errorMessage: "Please enter city!",
      required: false,
      tab: "hoamgt",
    },

    {
      id: 12,
      name: "hoa_collector_zip",
      type: "text",
      label: "Zip",
      placeholder: "Enter Zip",
      value: values?.hoa_collector_zip || "",
      column: "3",
      errorMessage: "Please enter valid zip!",
      required: false,
      maxLength: "10",
      minLength: "5",
      tab: "hoamgt",
    },
    {
      id: 13,
      name: "hoa_collector_phone",
      type: "number",
      label: "Phone",
      placeholder: "Enter Phone Number",
      value: values?.hoa_collector_phone || "",
      column: "3",
      errorMessage: "Please enter phone number!",
      required: false,
      maxLength: "10",
      minLength: "10",
      tab: "hoamgt",
    },
    {
      id: 14,
      name: "hoa_collector_fax",
      type: "text",
      label: "Fax",
      placeholder: "Enter Fax",
      value: values?.hoa_collector_fax || "",
      column: "3",
      errorMessage: "Please enter fax!",
      required: false,
      tab: "hoamgt",
    },
    {
      id: 15,
      name: "hoa_collector_email",
      type: "hoa_collector_email",
      label: "Email",
      placeholder: "Enter Email",
      value: values?.hoa_collector_email || "",
      column: "3",
      errorMessage: "Please enter email!",
      required: false,
      tab: "hoamgt",
    },
    {
      id: 16,
      name: "hoa_collector_website",
      label: "Website",
      placeholder: "Enter Website",
      type: "text",
      value: values?.hoa_collector_website || "",
      errorMessage: "Please enter hoa_website!",
      column: "3",
      required: false,
      tab: "hoamgt",
    },
    {
      id: 145456,
      name: "fees",
      label: "Fees",
      placeholder: "Enter Fees",
      type: "number",
      value: values?.fees || "",
      errorMessage: "Please enter fees!",
      column: "3",
      required: false,
      tab: "hoamgt",
    },
    {
      id: 14545456,
      name: "balance",
      label: "Balance",
      placeholder: "Enter balance",
      type: "number",
      value: values?.balance || "",
      errorMessage: "Please enter balance!",
      column: "3",
      required: false,
      tab: "hoamgt",
    },
    {
      id: 514546,
      name: "is_direct_contacts",
      checked: values.is_direct_contacts || "",
      type: "checkbox",
      column: "3",
      label: "Direct Contact",
      margin: "4",
      tab: "hoamgt",
      disabled: mode === "view",
    },
  ];
  // useEffect(() => {
  //   //fetching Parcel Info
  //   parcelHOAInfo({ id, token });
  // }, []);

  useEffect(() => {
    if (parcelHOAInfoResult.isSuccess) {
      const {
        hoa_collector_read_only_comments,
        hoa_read_only_comments,
        hoa_display_on_cert_comments,
        hoa_collector_display_on_cert_comments,
        hoa_collector_branch_list,
        hoa_collector_fees_list,
        hoa_information_fees_list,
      } = parcelHOAInfoResult.data?.data[0];
      setHoa_collector_display_on_cert_comments(
        hoa_collector_display_on_cert_comments
      );
      setParcelHoaCollectorFessList(hoa_collector_fees_list);
      setParcelHoaFessList(hoa_information_fees_list);

      setValues({
        ...values,
        ...parcelHOAInfoResult.data?.data[0],
        hoa_collector_fees_list,
        hoa_information_fees_list,
      });

      setBranchListOptions(hoa_collector_branch_list);
      setHoa_collector_read_only_comments(hoa_collector_read_only_comments);
      setHoa_display_on_cert_comments(hoa_display_on_cert_comments);
      setHoa_read_only_comments(hoa_read_only_comments);
      setCommetsView("display-cert");
    }
    if (parcelHOAInfoResult.isError) {
      setGetError(
        parcelHOAInfoResult.error?.data?.error ||
          parcelHOAInfoResult.error?.data?.message ||
          parcelHOAInfoResult.error.data?.msg?.sqlMessage ||
          (parcelHOAInfoResult.error.error && "server unreachable!") ||
          parcelHOAInfoResult.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
    setGlobalVal({ ...globalVal, loader: parcelHOAInfoResult.isLoading });
  }, [parcelHOAInfoResult.isLoading]);

  function clearHoaCollectorCommets() {
    setHoa_collector_display_on_cert_comments("");
    setHoa_collector_read_only_comments("");
  }
  function clearCommets() {
    setHoa_display_on_cert_comments("");
    setHoa_read_only_comments("");
  }

  //select handler
  const selectHandler = (property, value, attribute) => {
    // clearStatus();
    const id = attribute.getAttribute("data_id");
    setValues({ ...values, [property]: value });
    if (property === "hoa_name") {
      setValues({
        ...values,
        [property]: value,
        hoa_id: id,
        hoa_address_one: "",
        hoa_address_two: "",
        hoa_address_zip: "",
        hoa_website: "",
        hoa_address_city: "",
        hoa_date_verified: "",
        hoa_collector_phone: "",
        hoa_information_fees_list: [],
        hoa_collector_name: "",
        hoa_collector_id: "",
        hoa_collector_fees_list: [],
        hoa_collector_branch: "",
        hoa_collector_address_one: "",
        hoa_collector_address_two: "",
        hoa_collector_city: "",
        hoa_collector_zip: "",
      });
      if (value) {
        hoaPrefill({ id, token });
      } else {
        clearHoaCollectorCommets();
        clearCommets();

        setCycleListData([]);
      }
    } else if (property === "hoa_collector_branch_name") {
      //hoa collector branch info fectch
      if (value) {
        hoaBranchCollector({ id, token });
        setValues({
          ...values,
          [property]: value,
          hoa_collector_branch_id: id,
        });
      } else {
        setValues({
          ...values,
          [property]: value,
          hoa_collector_address_one: "",
          hoa_collector_address_two: "",
          hoa_collector_city: "",
          hoa_collector_zip: "",
          hoa_collector_branch_id: "",
        });
      }
    } else if (property === "hoa_collector_name") {
      if (value) {
        hoaMgmtPrefill({ id, token });

        setValues({
          ...values,
          hoa_collector_name: value,
          hoa_collector_id: id,
        });
      } else {
        setBranchListOptions([]);
        clearHoaCollectorCommets();
        setValues({
          ...values,
          hoa_collector_name: value,
          hoa_collector_id: id,
          hoa_collector_fees_list: [],
          hoa_collector_branch: "",
          hoa_collector_address_one: "",
          hoa_collector_address_two: "",
          hoa_collector_city: "",
          hoa_collector_zip: "",
        });
      }
    } else if (property === "hoa_cycle_name") {
      setValues({ ...values, hoa_cycle_id: id, hoa_cycle_name: value });
    } else if (property === "hoa_type_name") {
      setValues({ ...values, hoa_type_id: id, hoa_type_name: value });
    }
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "hoa_address_zip" || name === "hoa_collector_zip") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, [name]: foo });
    }
    //number only

    //claser status values
    // clearStatus();
  };

  //commemts change handler
  const displayCommentsChangeHandler = useCallback(
    (value) => {
      setHoa_display_on_cert_comments(value);
    },
    [hoa_display_on_cert_comments]
  );

  const readCommentsChangeHandler = useCallback(
    (value) => {
      setHoa_read_only_comments(value);
    },
    [hoa_read_only_comments]
  );

  const displayCertList = useMemo(() => dispaceCertData, [dispaceCertData]);
  const readCertList = useMemo(() => readCertData, [readCertData]);

  useEffect(() => {
    if (mode === "add") {
      setValues(initialState);

      //clear comments
      clearCommets();
      clearHoaCollectorCommets();
    }
    setIsValueUpdate(false);
    setDelete_order_parcel_hoa_fees([]);
    setAdd_order_parcel_hoa_fees([]);
    setEdit_order_parcel_hoa_fees([]);
    setParcel_hoa_information_fees([]);
  }, [mode]);

  useEffect(() => {
    if (mode === "view" && editId) {
      parcelHOAInfo({ id: editId, token });
    } else if (showIsHoa) {
      setValues({
        ...initialState,
        is_hoa: false,
        hoa_not_found_comments: showIsHoa,
      });
    }
  }, [editId]);

  //getting updated values from
  const updateOrderParcelHoaFeesDescription = useCallback(
    (value) => {
      if (mode === "add") {
        if (
          order_parcel_hoa_fees?.length > 0 &&
          order_parcel_hoa_fees?.find((val) => val["tempId"] === value.tempId)
        ) {
          order_parcel_hoa_fees.forEach((item) => {
            if (item.tempId == value.tempId) {
              item.amount = value.amount;
              item.fees_description = value.fees_description;
              item.amount_due = value.amount_due;
              item.hoa_fees_type = value.hoa_fees_type;
            }
          });
        } else if (value["tempId"]) {
          setOrder_parcel_hoa_fees([
            ...order_parcel_hoa_fees,
            {
              ...value,
            },
          ]);
        }
      } else if (mode === "edit") {
        if (
          add_order_parcel_hoa_fees?.length > 0 &&
          add_order_parcel_hoa_fees?.find(
            (val) => val["tempId"] === value.tempId
          )
        ) {
          add_order_parcel_hoa_fees.forEach((item) => {
            if (item.tempId == value.tempId) {
              item.amount = value.amount;
              item.fees_description = value.fees_description;
              item.amount_due = value.amount_due;
              item.hoa_fees_type = value.hoa_fees_type;
            }
          });
        } else if (value["tempId"]) {
          setAdd_order_parcel_hoa_fees([
            ...add_order_parcel_hoa_fees,
            {
              ...value,
            },
          ]);
        }

        if (
          edit_order_parcel_hoa_fees?.length > 0 &&
          edit_order_parcel_hoa_fees?.find((val) => val["id"] === value.id)
        ) {
          edit_order_parcel_hoa_fees.forEach((item) => {
            if (item.id == value.id) {
              item.amount = value.amount;
              item.fees_description = value.fees_description;
              item.amount_due = value.amount_due;
              item.hoa_fees_type = value.hoa_fees_type;
            }
          });
        } else if (value?.id) {
          setEdit_order_parcel_hoa_fees([
            ...edit_order_parcel_hoa_fees,
            {
              ...value,
            },
          ]);
        }
      }
    },
    [
      edit_order_parcel_hoa_fees,
      add_order_parcel_hoa_fees,
      mode,
      order_parcel_hoa_fees,
    ]
  );

  //useCallBack for deleteHandler
  const deleteOrderParcelHoaFessHandler = useCallback(
    (value) => {
      if (mode === "add" && value?.tempId) {
        setOrder_parcel_hoa_fees(
          order_parcel_hoa_fees?.filter((e) => {
            return e.tempId !== value.tempId;
          })
        );
      } else if (mode === "edit") {
        if (value?.id) {
          //
          setDelete_order_parcel_hoa_fees([
            ...delete_order_parcel_hoa_fees,
            String(value.id),
          ]);
        } else {
          setAdd_order_parcel_hoa_fees(
            add_order_parcel_hoa_fees?.filter((e) => {
              return e.tempId !== value.tempId;
            })
          );
        }
      }
    },
    [
      delete_order_parcel_hoa_fees,
      mode,
      add_order_parcel_hoa_fees,
      order_parcel_hoa_fees,
    ]
  );

  ///multifees
  const feeslist = useMemo(
    () => values?.hoa_collector_fees_list,
    [values?.hoa_collector_fees_list]
  );

  ///multifees
  const orderParcelHoafeeslist = useMemo(
    () => values?.order_parcel_hoa_fees_list,
    [values?.order_parcel_hoa_fees_list]
  );

  const hoaFeesInfolist = useMemo(
    () => values?.hoa_information_fees_list,
    [values?.hoa_information_fees_list]
  );

  //delete parcel hoa
  const deletParcelHoa = () => {
    deleteparcelhoa({ id: editId, token });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { hoa_not_found_comments, is_hoa, ...rest } = values;

    removeBlank(rest);

    if (mode === "add") {
      removeArrayBlank(parcel_hoa_fees);
      // console.log(parcel_hoa_information_fees)
      removeArrayBlank(parcel_hoa_information_fees);
      removeArrayBlank(order_parcel_hoa_fees);
      // console.log({
      //   ...rest,
      //   parcel_hoa_information_fees,
      //   order_parcel_hoa_fees,
      //   parcel_hoa_fees,
      //   hoa_display_on_cert_comments,
      //   hoa_read_only_comments,
      //   hoa_collector_display_on_cert_comments,
      //   hoa_collector_read_only_comments,
      //   parcel_id: parcelController.parcelId,
      // });

      //add new parcel hoa
      addParcelHoa({
        data: {
          ...rest,
          parcel_hoa_information_fees,
          order_parcel_hoa_fees,
          parcel_hoa_fees,
          hoa_display_on_cert_comments,
          hoa_read_only_comments,
          hoa_collector_display_on_cert_comments,
          hoa_collector_read_only_comments,
          parcel_id: parcelController.parcelId,
        },
        token,
      });
    } else if (mode === "edit" && !editId) {
      removeArrayBlank(parcel_hoa_fees);
      removeArrayBlank(parcel_hoa_information_fees);
      removeArrayBlank(order_parcel_hoa_fees);
      removeArrayBlank(values.add_parcel_hoa_information_fees);
      // console.log({
      //   ...rest,
      //   parcel_hoa_information_fees,
      //   order_parcel_hoa_fees,
      //   parcel_hoa_fees,
      //   hoa_display_on_cert_comments,
      //   hoa_read_only_comments,
      //   hoa_collector_display_on_cert_comments,
      //   hoa_collector_read_only_comments,
      //   parcel_id: parcelController.parcelId,
      // });
      addParcelHoa({
        data: {
          ...rest,
          parcel_hoa_information_fees,
          order_parcel_hoa_fees,
          parcel_hoa_fees,
          hoa_display_on_cert_comments,
          hoa_read_only_comments,
          hoa_collector_display_on_cert_comments,
          hoa_collector_read_only_comments,
          parcel_id: parcelController.parcelId,
        },
        token,
      });
    } else if (mode === "edit") {
      removeArrayBlank(edit_order_parcel_hoa_fees);
      removeArrayBlank(add_order_parcel_hoa_fees);
      let delete_parcel_hoa_fees = [];
      let delete_parcel_hoa_information_fees = [];
      if (isValueUpdate) {
        parcelHoaFessList.map(({ id }) => {
          delete_parcel_hoa_information_fees.push(id);
        });
        parcelHoaCollectorFessList.map(({ id }) => {
          delete_parcel_hoa_fees.push(id);
        });
      } else {
        delete rest["add_parcel_hoa_fees"];
        delete rest["add_parcel_hoa_information_fees"];
      }

      // console.log({
      //   ...rest,
      //   hoa_display_on_cert_comments,
      //   delete_order_parcel_hoa_fees,
      //   delete_parcel_hoa_information_fees,
      //   add_order_parcel_hoa_fees,
      //   edit_order_parcel_hoa_fees,
      //   delete_parcel_hoa_fees,
      //   hoa_read_only_comments,
      //   hoa_collector_display_on_cert_comments,
      //   hoa_collector_read_only_comments,
      //   parcel_id: parcelController.parcelId,
      // });

      updateHOAParcel({
        data: {
          ...rest,
          hoa_display_on_cert_comments,
          delete_order_parcel_hoa_fees,
          delete_parcel_hoa_information_fees,
          add_order_parcel_hoa_fees,
          edit_order_parcel_hoa_fees,
          delete_parcel_hoa_fees,
          hoa_read_only_comments,
          hoa_collector_display_on_cert_comments,
          hoa_collector_read_only_comments,
          parcel_id: parcelController.parcelId,
        },
        id: editId,
        token,
      });
    }
  };

  //hoa not found submit handler
  const hoaNotSubmitHandler = (e) => {
    const { hoa_not_found_comments } = values;
    e.preventDefault();

    if (
      !hoa_not_found_comments ||
      hoa_not_found_comments === "<p><br></p>" ||
      hoa_not_found_comments === "<br>"
    ) {
      setGetError("Please add comments");
    } else {
      if (mode === "edit" && editId) {
        confirm({
          title:
            "All the HOA's will be removed for this parcel click ok to proceed?",
          icon: <ExclamationCircleFilled />,
          onOk() {
            hoaComments({
              data: { hoa_not_found_comments, is_hoa: false },
              id: parcelController.parcelId,
              token,
            });
          },
        });
      } else {
        hoaComments({
          data: { hoa_not_found_comments, is_hoa: false },
          id: parcelController.parcelId,
          token,
        });
      }
    }
  };

  const appCheckboxHandler = (property, value) => {
    setValues({ ...values, [property]: value });
    setGetError(false);
  };

  const hoaDisplayCommentsChangeHandler = useCallback(
    (value) => {
      setHoa_collector_display_on_cert_comments(value);
    },
    [hoa_collector_display_on_cert_comments]
  );

  const hoaReadCommentsChangeHandler = useCallback(
    (value) => {
      setHoa_collector_read_only_comments(value);
    },
    [hoa_collector_read_only_comments]
  );

  function resetComments() {
    setHoa_collector_display_on_cert_comments("");
    setHoa_collector_read_only_comments("");
    setHoa_display_on_cert_comments("");
    setHoa_read_only_comments("");
  }

  return (
    <div>
      {mode !== "view" && (
        <div>
          <Radio.Group
            className="mb-3 ms-2"
            disabled={mode === "view"}
            name="radiogroup"
            onChange={(e) => {
              setGetError(false);
              const { value } = e.target;

              if (mode !== "add") {
                if (editId) {
                  setValues({
                    ...values,
                    hoa_not_found_comments: "",
                    is_hoa: value === 1 ? true : false,
                  });
                } else {
                  setValues({
                    ...values,
                    is_hoa: value === 1 ? true : false,
                  });
                }
              } else {
                setValues({
                  ...initialState,
                  is_hoa: value === 1 ? true : false,
                });
                resetComments();
              }
            }}
            value={values.is_hoa ? 1 : 2}
            defaultValue={values.is_hoa ? 1 : 2}
          >
            <Radio value={1}>
              <span className="fw-bold text-success">HOA Found</span>
            </Radio>
            <Radio value={2}>
              <span className="fw-bold text-success">No HOA Found</span>
            </Radio>
          </Radio.Group>
        </div>
      )}

      {!values.is_hoa ? (
        <>
          {mode !== "view" ? (
            <form onSubmit={hoaNotSubmitHandler}>
              {(mode === "edit" || mode === "add") && (
                <div className="d-flex justify-content-end align-items-center gap-2  mb-1 ">
                  <AppButton
                    type="submit"
                    title={mode === "add" ? "ADD" : "UPDATE"}
                    variant={"primary-md"}
                  />

                  {mode !== "add" && (
                    <div
                      className={`${
                        mode === "view" ? "ms-auto" : null
                      } rounded-pill bg-danger p-1 border bg-white   shadow-sm d-flex align-items-center justify-content-center cursor-pointer`}
                      style={{ width: 30, height: 30 }}
                    >
                      <div
                        onClick={() => {
                          setMode("view");
                          // setValues({ ...values, ...hoaEditData });
                          refetchHoaList();
                        }}
                      >
                        <MdClose size={20} color="#0d6efd" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <h6 className="mb-2 text-danger border-bottom pb-1">
                HOA not found comment
              </h6> */}
              <AppTextEditor
                value={values.hoa_not_found_comments}
                onChange={(newContent) => {
                  setGetError(false);
                  setValues({ ...values, hoa_not_found_comments: newContent });
                }}
              />
            </form>
          ) : (
            <div className="p-3 fs-11 text-dark">
              {mode !== "add" && (
                <div
                  className={`${
                    mode === "view" ? "ms-auto" : null
                  } rounded-pill bg-danger p-1 border bg-white   shadow-sm d-flex align-items-center justify-content-center cursor-pointer`}
                  style={{ width: 30, height: 30 }}
                >
                  <div
                    onClick={() => {
                      setMode("edit");
                    }}
                  >
                    <AiOutlineEdit size={20} color="#0d6efd" />
                  </div>
                </div>
              )}
              {/* <h6 className="mb-2 text-danger border-bottom pb-1">
                HOA not found comment
              </h6> */}
              {parse(values.hoa_not_found_comments)}
            </div>
          )}
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row w-100 mx-auto">
            <div className="edit-toggle  d-flex gap-2 align-items-center mb-2">
              <div className="col-12 col-md-6 d-flex gap-2 align-items-center">
                <h6 className="text-primary mb-0 border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
                  HOA Information
                </h6>
                {mode === "edit" && editId && (
                  <div>
                    <Popconfirm
                      getPopupContainer={(trigger) => trigger.parentElement}
                      title="Are you sure you want to update latest values？"
                      onConfirm={() => {
                        setIsValueUpdate(true);
                        hoaPrefill({ id: values.hoa_id, token });
                      }}
                      placement="right"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="bg-white border rounded px-2 py-1 cursor-pointer">
                        <BiRefresh size={20} />
                      </div>
                    </Popconfirm>
                  </div>
                )}
                <div
                  className={`${
                    mode !== "view" ? "d-none" : null
                  } text-center ms-2 d-flex gap-2 align-items-center`}
                >
                  <label
                    htmlFor="status"
                    className="text-dark fw-bold fs-6 m-0"
                  >
                    Hoa Status :
                  </label>
                  <div>
                    <select
                      value={hoaStaus}
                      className={`${
                        editStatus || mode === "edit"
                          ? "border  px-2 py-1 fs-11 text-dark"
                          : "border-0 bg-light form-control"
                      }`}
                      disabled={!editStatus}
                      onChange={(e) => {
                        const { value } = e.target;
                        // setStatus(value);
                        setHoaStatus(value);
                      }}
                    >
                      {hoaStatusOptions?.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {editStatus ? (
                    <>
                      <button
                        onClick={() => {
                          //update hoa Status
                          if (hoaStaus) {
                            updateParcelHoaStatus({
                              parcel_id: parcelController.parcelId,
                              hoa_status_id: hoaStaus,
                              token,
                            });
                          }
                        }}
                        className=" border bg-primary  fs-12 text-white"
                      >
                        Update
                      </button>
                      <button
                        onTransitionEnd={false}
                        onClick={() => {
                          setEditStatus(false);
                          // setStatus(orderStatus);
                        }}
                        className=" border fs-12 text-dark"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    mode !== "edit" && (
                      <FiEdit
                        onClick={() => {
                          setEditStatus(true);
                        }}
                        size={12}
                        className="mt-1"
                        cursor="pointer"
                      />
                    )
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex gap-2 align-items-center justify-content-end">
                {(mode === "view" || mode === "edit") &&
                  editId &&
                  parcelEditInOrder.map((item, index) => {
                    if (item.hoa_delete_button) {
                      return (
                        <Popconfirm
                          key={index}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          title="Are you sure you want to delete？"
                          onConfirm={deletParcelHoa}
                          placement="bottom"
                          icon={
                            <QuestionCircleOutlined
                              style={{
                                color: "red",
                              }}
                            />
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <div className="add_icon ">
                            <MdDeleteOutline color="#0d6efd" />
                          </div>
                        </Popconfirm>
                      );
                    }
                  })}
                {(mode === "edit" || mode === "add") && (
                  <Button htmlType="submit" type="primary">
                    {mode === "add" || !editId ? "ADD HOA" : "UPDATE HOA"}
                  </Button>
                )}

                {editId && mode !== "edit" && mode !== "add"
                  ? parcelEditInOrder.map((item, index) => {
                      if (item.hoa_edit_button) {
                        return (
                          <div
                            key={index}
                            className="add_icon "
                            onClick={() => {
                              setMode("edit");
                            }}
                          >
                            <AiOutlineEdit size={20} color="#0d6efd" />
                          </div>
                        );
                      }
                    })
                  : mode !== "add" && (
                      <div
                        className="add_icon"
                        onClick={() => {
                          setMode("view");
                          if (editId) {
                            parcelHOAInfo({ id: editId, token });
                          } else {
                            hoanotFoundCall();
                          }
                        }}
                      >
                        <MdClose size={20} color="#0d6efd" />
                      </div>
                    )}
              </div>
            </div>
            {/* //hoa info  */}
            <div className="bg-light border p-2">
              <div className="row w-100 mx-auto">
                {inputs.map((input) => {
                  if (
                    input.type == "select" &&
                    input.name === "hoa_name" &&
                    input.tab === "hoa"
                  ) {
                    return (
                      <AppSelect
                        isEdit={editId && mode !== "edit" && mode !== "add"}
                        key={input.id}
                        {...input}
                        defaultSet={mode}
                        onChange={selectHandler}
                        options={
                          <>
                            <option
                              value={
                                mode === "edit" && editId
                                  ? values?.hoa_name
                                  : ""
                              }
                            >
                              {mode === "edit" && editId
                                ? values?.hoa_name
                                : " Select Name"}
                            </option>
                            {input.options?.map((ele) => {
                              return (
                                <option
                                  key={ele.id}
                                  data_id={ele.id}
                                  value={ele.name}
                                >
                                  {ele.name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    );
                  } else if (
                    input.type == "select" &&
                    input.name === "hoa_type_name" &&
                    input.tab === "hoa"
                  ) {
                    return (
                      <AppSelect
                        isEdit={editId && mode !== "edit" && mode !== "add"}
                        key={input.id}
                        {...input}
                        //   defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select Hoa Type</option>
                            {input.options?.map((ele) => {
                              return (
                                <option
                                  key={ele.id}
                                  data_id={ele.id}
                                  value={ele.name}
                                >
                                  {ele.name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    );
                  } else if (
                    input.type == "select" &&
                    input.name === "hoa_cycle_name" &&
                    input.tab === "hoa"
                  ) {
                    return (
                      <AppSelect
                        isEdit={editId && mode !== "edit" && mode !== "add"}
                        key={input.id}
                        {...input}
                        defaultSet={mode}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""} data_id={""}>
                              Select Collection Cycle
                            </option>
                            {input.options?.map((ele) => {
                              return (
                                <option
                                  data_id={ele.id}
                                  key={ele.id}
                                  value={ele.name}
                                >
                                  {ele.name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    );
                  } else if (
                    (input.type === "text" || input.type === "date") &&
                    input.tab === "hoa"
                  ) {
                    return (
                      <AppInput
                        isEdit={editId && mode !== "edit" && mode !== "add"}
                        classlabelstyle="ms-1 dark-label"
                        defaultSet={""}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                        // isEdit={editId && !editable}
                      />
                    );
                  }
                })}
              </div>
              {/* //hoa Fees  */}
              {((mode === "view" && hoaFeesInfolist.length > 0) ||
                mode !== "view") && (
                <div style={{ width: "99%" }} className="mx-auto">
                  <MultiDescription
                    columnshow={["Fees Description", "Amount"]}
                    isedit={true}
                    initailValArr={hoaFeesInfolist}
                  />
                </div>
              )}

              {mode !== "view" ? (
                <div className="row w-100 mx-auto my-2">
                  <div className="col-12 col-md-6">
                    <HOAComments
                      value={hoa_display_on_cert_comments}
                      onChange={displayCommentsChangeHandler}
                      title="Display on Cert"
                      options={displayCertList}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <HOAComments
                      value={hoa_read_only_comments}
                      onChange={readCommentsChangeHandler}
                      title="Read Only"
                      options={readCertList}
                    />
                  </div>
                </div>
              ) : (
                (hoa_read_only_comments || hoa_display_on_cert_comments) &&
                values.is_hoa && (
                  <Collapse
                    defaultActiveKey={"1"}
                    accordion
                    style={{ width: "98%" }}
                    className="comments-collase my-2 mx-auto"
                  >
                    <Panel header="Comments" key="1">
                      <Tabs
                        activeKey={commetsView}
                        onChange={(k) => {
                          setCommetsView(k);
                        }}
                      >
                        {" "}
                        <TabPane
                          tab="Display On Certificate"
                          key="display-cert"
                        >
                          <div className="p-2">
                            {parse(hoa_display_on_cert_comments)}
                          </div>
                        </TabPane>
                        <TabPane tab="Read Only" key="read-only">
                          <div className="p-2">
                            {parse(hoa_read_only_comments)}
                          </div>
                        </TabPane>
                      </Tabs>
                    </Panel>
                  </Collapse>
                )
              )}
            </div>
          </div>
          <>
            <div
              id="hoamgt-span1"
              className=" h6 mt-3 bg-light p-2 py-1 border rounded-2"
              style={{ fontSize: 15 }}
            >
              <h6 className="text-primary border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
                {" "}
                HOA Management
              </h6>

              <div className="row  px-3 ">
                {inputs.map((input) => (
                  <>
                    {input.type == "select" &&
                      input.name === "hoa_collector_name" &&
                      input.tab === "hoamgt" && (
                        <AppSelect
                          isEdit={editId && mode !== "edit" && mode !== "add"}
                          key={input.id}
                          {...input}
                          //   defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""} data_id="">
                                Select Name
                              </option>
                              {input.options?.map((ele) => {
                                return (
                                  <option
                                    data_id={ele.id}
                                    key={ele.id}
                                    value={ele.name}
                                  >
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {input.type === "checkbox" && input.tab === "hoamgt" && (
                      <AppCheckbox
                        {...input}
                        handleChange={appCheckboxHandler}
                      />
                    )}
                    {input.type == "select" &&
                      input.name === "hoa_collector_branch_name" &&
                      input.tab === "hoamgt" && (
                        <AppSelect
                          isEdit={editId && mode !== "edit" && mode !== "add"}
                          key={input.id}
                          {...input}
                          //   defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""} data_id="">
                                Select Branch
                              </option>
                              {input.options?.map((ele) => {
                                return (
                                  <option
                                    data_id={ele.id}
                                    key={ele.id}
                                    value={ele.name}
                                  >
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {input.type === "number" &&
                      input.name === "hoa_collector_phone" && (
                        <div className="col-12 col-md-3">
                          <div className="mt-3 row">
                            <label className="ms-1 heading-5 text-dark ">
                              {input.label}{" "}
                              {mode !== "edit" && mode !== "add" && (
                                <div>
                                  {values[input.name]
                                    ? mobileFormat(values[input.name])
                                    : "NA"}
                                </div>
                              )}
                            </label>
                          </div>
                          {((editId && mode === "edit") || mode === "add") && (
                            <NumberInput
                              className="input-field mt-0 pb-2 w-100 heading-5"
                              maxLength={14}
                              minLength={14}
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              placeholder={input.placeholder}
                              defaultCountry="US"
                              value={values[input.name]}
                              onChange={(value) => {
                                setValues({
                                  ...values,
                                  [input.name]: value,
                                });

                                setGetError(false);
                              }}
                            />
                          )}
                        </div>
                      )}
                    {input.type === "number" &&
                      input.name !== "hoa_collector_phone" && (
                        <AppNumeric
                          {...input}
                          isEdit={editId && mode !== "edit" && mode !== "add"}
                          type="text"
                          defaultSet={false}
                          value={values[input.name]}
                          thousandSeparator={true}
                          placeholder={input.placeholder}
                          prefix={"$"}
                          decimalScale={7}
                          maxLength={14}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              [input.name]: value,
                            });
                          }}
                        />
                      )}
                    {(input.type === "text" ||
                      input.type === "date" ||
                      input.type === "email") &&
                      input.tab === "hoamgt" && (
                        <AppInput
                          isEdit={editId && mode !== "edit" && mode !== "add"}
                          classlabelstyle="ms-1 dark-label"
                          defaultSet={""}
                          key={input.id}
                          {...input}
                          value={values[input.name]}
                          onChange={onChange}
                          // isEdit={editId && !editable}
                        />
                      )}

                    {/* //appSelcet for vendors name  */}
                  </>
                ))}
              </div>
              {((mode === "view" && feeslist.length > 0) ||
                mode !== "view") && (
                <MultiDescription
                  columnshow={["Fees Description", "Amount"]}
                  isedit={true}
                  // afterClose={modeMemo}
                  initailValArr={feeslist}
                />
              )}
              {mode !== "view" && (
                <div className="row">
                  <div className="col-12 col-md-6">
                    <HOAComments
                      value={hoa_collector_display_on_cert_comments}
                      onChange={hoaDisplayCommentsChangeHandler}
                      title="Display on Cert"
                      options={displayCertList}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <HOAComments
                      value={hoa_collector_read_only_comments}
                      onChange={hoaReadCommentsChangeHandler}
                      title="Read Only"
                      options={readCertList}
                    />
                  </div>
                </div>
              )}
              {mode === "view" &&
                (hoa_collector_read_only_comments ||
                  hoa_collector_display_on_cert_comments) && (
                  <Collapse
                    defaultActiveKey={"1"}
                    accordion
                    className="comments-collase my-1"
                  >
                    <Panel header="Comments" key="1">
                      <Tabs
                        activeKey={hoaMgmtCommetsView}
                        onChange={(k) => {
                          setHoaMgmtCommetsView(k);
                        }}
                      >
                        <TabPane
                          tab="Display On Certificate"
                          key="display-cert"
                        >
                          <div className="p-2">
                            {parse(
                              hoa_collector_display_on_cert_comments || ""
                            )}
                          </div>
                        </TabPane>
                        <TabPane tab="Read Only" key="read-only">
                          <div className="p-2">
                            {parse(hoa_collector_read_only_comments || "")}
                          </div>
                        </TabPane>
                      </Tabs>
                    </Panel>
                  </Collapse>
                )}
            </div>
          </>
          {((mode === "view" && orderParcelHoafeeslist.length > 0) ||
            mode !== "view") && (
            <div>
              <MultiDescription
                columnshow={[
                  "Bill Period",
                  "Fees Description",
                  "Amount",
                  "Amount Due",
                  "Action",
                ]}
                isedit={mode === "view"}
                // afterClose={modeMemo}
                updateDescription={updateOrderParcelHoaFeesDescription}
                //delete
                deleteHandler={deleteOrderParcelHoaFessHandler}
                initailValArr={orderParcelHoafeeslist}
              />
            </div>
          )}
        </form>
      )}
    </div>
  );
}
