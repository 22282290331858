import React, { useEffect, useState } from "react";
import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import { Form, Col, Row } from "react-bootstrap";
import { Alert, notification } from "antd";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import AppSelect from "../../../../Components/AppSelect/AppSelect";
import {
  useAddVendorsMutation,
  useEditVendorsQuery,
  useUpdateVendorsMutation,
} from "../../../../Redux/Services/Vendors";
import { useSelector } from "react-redux";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import {
  removeArrayBlank,
  removeBlank,
  scrollComp,
} from "../../../../Controllers/Functions";
import AppMultiSelect from "../../../../Components/AppMultiSelect/AppMultiSelect";
import { useStateCountyQuery } from "../../../../Redux/Services/stateCounty";
import { AppFile } from "../../../../Components/AppFile/AppFile";
import MultiContacts from "../../../../Components/MultiContacts/MultiContacts";
import AppCheckbox from "../../../../Components/AppCheckbox/AppCheckbox";
import MyMultiSelect from "../../../../Components/MultiSelect/MyMultiSelect";

import Input from "react-phone-number-input/input";
export default function VendorModal({
  initialValues,
  afterClose,
  setShowHide,
  editId,
}) {
  //status
  const [getError, setGetError] = useState(false);
  const [, setFieldErr] = useState({
    countyId: false,
    contactList: false,
  });
  const [stateoptions, setStateOptions] = useState([
    { id: "", state_name: "" },
  ]);

  //default states
  const [values, setValues] = useState(initialValues);

  const [multiContacts, setMultiContacts] = useState([]);
  const [enableStateCountyList, setEnableStateCountyList] = useState([]);
  //clear error function
  function clearStatus() {
    setGetError(false);
    setFieldErr({ countyId: false, contactList: false });
  }

  // modal will be close
  function closeModal() {
    clearStatus();
    // //it will be close to the modal
    setShowHide(false);
    if (values?.is_customer) {
      localStorage.setItem("is_customer", JSON.stringify(values));
    }
  }

  //calling rtk query hook for user
  const { token } = useSelector((state) => state.auth);

  //calling from store to the statelist
  const { stateList } = useSelector((state) => state.stateList);

  //calling from store to the productType
  const { productTypeList } = useSelector((state) => state.productTypeList);

  //calling redux stor for customerType list
  const { customerTypeList } = useSelector((state) => state.customerTypeList);

  
  const { tableColumn } = useSelector(
		(state) => state.flagRoles
	);

  //call
  //useEffect for Default
  useEffect(() => {
    //asigning to stateOptions
    setStateOptions(stateList);
    //clear status
    clearStatus();
  }, [afterClose]);

  const stateCountyApi = useStateCountyQuery(token);

  //calling rtk query for adding new vendor
  const [addVendors, { data, isSuccess, error, isLoading }] =
    useAddVendorsMutation();

  //edit-vendors request send
  const editVendors = useEditVendorsQuery(
    { id: editId, token: token },
    { skip: !editId } //it will render only once
  );

  //useEffect for editValuess
  useEffect(() => {
    if (editVendors.isSuccess) {
      const { zip_five, zip_four, logo, ...othersData } =
        editVendors.data?.data[0];
      var newArr = {
        ...othersData,
        zip_five: `${zip_five}${zip_four ? "-" + zip_four : ""}`,
        logo: atob(logo),
        add_vendor_contact: [],
        delete_contact: [],
        edit_vendor_contact: [],
      };
      //request for countylist
      // const state_id = { state_id: editVendors.data?.data[0]?.state_id };
      // getCountyList({ state_id, token: token });
      setValues(newArr);
      stateCountyApi.refetch();
    }
    if (editVendors.isError) {
      setGetError(
        editVendors.error?.data?.message ||
          editVendors.error.data ||
          (editVendors.error.error && "server unreachable!") ||
          editVendors.error.data?.msg.sqlMessage
      );
    }
  }, [editVendors.isFetching]);

  //useEffect for edit refecth
  useEffect(() => {
    if (editId) {
      editVendors.refetch();
    }
    setValues(initialValues);
  }, [afterClose]);

  //rtk query for vendors update
  const vendorsUpdate = useUpdateVendorsMutation();
  //geting acction from above hook
  const [updateVendors] = vendorsUpdate;

  //useEfffect for creating for when label list is loaded
  useEffect(() => {
    if (stateCountyApi.isSuccess) {
      setEnableStateCountyList(stateCountyApi.data?.data);
    }
  }, [stateCountyApi.isFetching]);

  const inputs = [
    {
      id: 1,
      name: "name",
      _id: "vendor_name",
      type: "text",
      value: values.name || "",
      column: "4",
      placeholder: "Enter Vendor Name",
      errorMessage: "Please enter vendor name!",
      label: "Vendor Name",
      required: true,
    },
    {
      id: 2,
      name: "address_one",
      _id: "vendor_address_one",
      type: "text",
      column: "4",
      placeholder: "Enter Address 1",
      errorMessage: "Please enter address 1!",
      label: "Address 1",
      // required: true,
    },
    {
      id: 3,
      name: "address_two",
      _id: "vendor_address_two",
      type: "text",
      column: "4",
      placeholder: "Enter Address 2",
      label: "Address 2",
    },
    {
      id: 4,
      name: "state_id",
      _id: "vendor_state_id",
      type: "select",
      value: values.state_id || "",
      column: "4",
      placeholder: "Select State",
      errorMessage: "Please enter state!",
      label: "State",
      options: stateoptions,
      // required: true,
    },
    {
      id: 5,
      name: "city",
      _id: "vendor_city",
      type: "text",
      column: "4",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      // required: true,
    },
    {
      id: 10,
      name: "zip_five",
      type: "text",
      _id: "vendor_zip_five",
      column: "4",
      placeholder: "Enter Zip Code",
      errorMessage: "Please enter Zip code!",
      label: "Zip Code",
      maxlength: "10",
      minlength: "5",
      // required: true,
    },
    {
      id: 7,
      name: "vendor_email",
      type: "email",
      value: values.vendor_email || "",
      _id: "vendor_email",
      column: "4",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      // required: true,
    },

    {
      id: 8,
      name: "phone_number",
      type: "text",
      _id: "vendor_phone_number",
      column: "4",
      value: values.phone_number || "",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      maxLength: "10",
      minLength: "10",
      // required: true,
    },

    {
      id: 1784540,
      name: "website",
      _id: "vendor_website",
      type: "text",
      placeholder: "Enter Website Name",
      errorMessage: "Please enter website!",
      label: "Website",
      column: "4",
      // required: true,
      tab: false,
    },

    {
      id: 2487454,
      name: "customer_type_id",
      _id: "vendor_customer_type_id",
      type: "select",
      value: values.customer_type_id || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer type!",
      label: "Customer Type",
      options: customerTypeList,
      required: true,
      column: "4",
    },
    {
      id: 13,
      name: "is_active",
      type: "switch",
      _id: "vendor_is_active",
      checked: values.is_active || "",
      column: "2",
      label: "Active",
      required: true,
      margin: "5",
    },

    {
      id: 12,
      type: "file",
      _id: "vendor_file",
      preview: values.logo,
      errorMessage: "Please add Logo!",
      label: "Logo",
    },
    {
      id: 225,
      type: "multiselect",
    },
  ];

  const handleReset = (e) => {
    e.preventDefault();
    //claser status values
    clearStatus();
    if (editId) {
      editVendors.refetch();
    } else {
      setValues(initialValues);
    }
  };
  const appSwitchHandler = (checked, value) => {
    setValues({ ...values, [checked]: value });
    //claser status values
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.county_id_list.length === 0) {
      setGetError("Please select State and County");
    } else if (values.product_list_values.length === 0) {
      setGetError("Please select Product List");
    } else if (values.contact_list_values.length === 0) {
      setGetError("Please Add Contact");
    } else if (
      values.phone_number?.length !== 12 &&
      values.phone_number?.length > 0
    ) {
      setGetError("Please enter valid phone number");
    } else {
      //remove blank values
      removeBlank(values);
      removeArrayBlank(values?.edit_vendor_contact);
      const zip_five = values?.zip_five?.split("-");
      const addNewContact = values.add_vendor_contact?.map(
        ({ temp_id, user_type_name, generate_password_check, ...rest }) => {
          return rest;
        }
      );

      const newContactInfo = multiContacts?.map(
        ({ temp_id, user_type_name, generate_password_check, ...rest }) => {
          return rest;
        }
      );

      if (!editId) {
        addVendors({
          token,
          data: {
            ...values,
            contact_info: newContactInfo,
            zip_five: zip_five && Number(zip_five[0]),
            zip_four: zip_five && Number(zip_five[1]),
          },
        });
      }

      if (editId) {
        const editVendorContact = values?.edit_vendor_contact?.filter((ele) => {
          if (ele?.id !== undefined) {
            return ele;
          }
          return null;
        });
        //sending update request
        updateVendors({
          data: {
            ...values,
            contact_info: newContactInfo,
            edit_vendor_contact: editVendorContact,
            add_vendor_contact: addNewContact,
            zip_five: zip_five && Number(zip_five[0]),
            zip_four: zip_five && Number(zip_five[1]),
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  //here changing status values and geting add vendors status values
  useEffect(() => {
    if (error) {
      setGetError(
        error?.data?.error ||
          error?.data?.message ||
          (error.error && "server unreachable!")
      );
    }
    if (isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: data.message,
      });
      closeModal();
    }
  }, [isLoading]);

  //here changing status values when vendor update
  useEffect(() => {
    if (vendorsUpdate[1].isSuccess) {
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: vendorsUpdate[1].data.message,
      });
      closeModal();
    }
    if (vendorsUpdate[1].error) {
      setGetError(
        vendorsUpdate[1].error?.data?.error ||
          vendorsUpdate[1].error?.data?.message ||
          (vendorsUpdate[1].error.error && "server unreachable!") ||
          vendorsUpdate[1].error.data?.msg.sqlMessage
      );
    }
  }, [vendorsUpdate[1].isLoading]);

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    //text and number
    if (e.target.type === "email") {
      setValues({ ...values, [name]: value });
    } else if (name === "zip_five") {
      const value1 = value.replace(/[^0-9.]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, zip_five: foo });
    }
    //claser status values
    clearStatus();
  };

  //select onchangeHandler
  const selectFieldsHandler = (property, value) => {
    setValues({ ...values, [property]: Number(value) });
    //setting default values
    clearStatus();
  };

  //multiSelect County
  const multiSelectCountyHandler = (value) => {
    setValues({ ...values, county_id_list: value });
    clearStatus();
  };

  //error highlight
  useEffect(() => {
    if (getError) {
      scrollComp("venodrError");
    }
  }, [getError]);

  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
    if (property === "is_customer") {
      setValues({
        ...values,
        is_customer: value,
        customer_type_id: value ? customerTypeList[0].id : "",
      });
    }
  };

  return (
    <>
      <div className="position-relative">
        <div className="border-bottom border-2" id="venodrError">
          <h5>{!editId ? "Add Vendor" : "Edit Vendor"} </h5>
        </div>

        {getError && (
          <div className="mt-2">
            <Alert message={getError} type="error" showIcon />{" "}
          </div>
        )}
        <Form onSubmit={handleSubmit} className="vendor ">
          {(isLoading ||
            editVendors.isFetching ||
            vendorsUpdate[1].isLoading ||
            stateCountyApi.isFetching) && <AppSpinner />}
          <div
            className={`${
              editVendors.isFetching || stateCountyApi.isFetching
                ? "invisible"
                : ""
            } `}
          >
            <Row>
              <div className="col-12 col-lg-9">
                <div className="row">
                  {inputs.map((input) => (
                    <>
                      {
                        //auto genrate password
                        input.type === "checkbox" && (
                          <AppCheckbox
                            {...input}
                            handleChange={appCheckboxHandler}
                          />
                        )
                      }
                      {input.type === "select" && input.name === "state_id" && (
                        <AppSelect
                          key={input.id}
                          {...input}
                          onChange={selectFieldsHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option
                                    data_name={ele.state_name}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                      {input.type === "select" &&
                        input.name === "product_type" && (
                          <AppSelect
                            key={input.id}
                            {...input}
                            onChange={selectFieldsHandler}
                            options={input.options.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {ele.name}
                                </option>
                              );
                            })}
                          />
                        )}

                      {/* //appSelcet for customer type name  */}
                      {input.type === "select" &&
                        input.name === "customer_type_id" &&
                        !input?.also_customer &&
                        values?.is_customer && (
                          <AppSelect
                            key={input.id}
                            {...input}
                            onChange={selectFieldsHandler}
                            options={input.options.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {ele.name}
                                </option>
                              );
                            })}
                          />
                        )}
                      {/* //input file  */}

                      {input.type === "file" && (
                        <AppFile
                          _id={"vendor_file"}
                          fileVal={(e) =>
                            setValues({
                              ...values,
                              logo: e.logo,
                              logo_name: e.name,
                            })
                          }
                          size={2000000}
                          label={"Logo"}
                          afterClose={afterClose}
                          initailVal={
                            editId
                              ? {
                                  fileName: values?.logo_name,
                                  imgPreview: values?.logo,
                                }
                              : ""
                          }
                          column={3}
                        />
                      )}
                      {/* //multiSelects  */}
                      {!stateCountyApi.isFetching &&
                        input.type === "multiselect" && (
                          <>
                            <label className="ms-1 heading-5 mt-5  mb-2 text-dark ">
                              <h6 className="fs-18 fw-bold me-2">
                                Products <span className="text-danger">*</span>
                              </h6>
                          </label>
                          
                          <MyMultiSelect
                            className="test-products"
                              _id={"vendor_products"}
                              placeholder="Select Products"
                              afterClose={afterClose}
                              initailVal={
                                editId ? values?.product_list_values : []
                              }
                              selectedValues={(value) => {
                                setValues({
                                  ...values,
                                  product_list_values: value,
                                });
                                setGetError(false);
                              }}
                              list={
                                productTypeList || [
                                  { id: "1", name: "no productList found" },
                                ]
                              }
                            />
                          </>
                        )}
                      {(input.type === "text" || input.type === "email") &&
                       ( input.name !== "phone_number" && input.name!=="name" ) && (
                          <FormInput
                            key={input.id}
                            {...input}
                            defaultSet={afterClose}
                            value={values[input.name]}
                            onChange={onChange}
                          />
                        )}
                       {input.name==="name" && tableColumn.map(item=>item.vendor ?   <FormInput
                            key={input.id}
                            {...input}
                            defaultSet={afterClose}
                            value={values[input.name]}
                            onChange={onChange}
                          />:'')}
                      {input.name === "phone_number" && (
                        <div className="col-12 col-md-4">
                          <Row className="mt-3">
                            <label className="ms-1 heading-5 text-dark ">
                              {input.label}
                            </label>
                          </Row>
                          <Input
                            className="input-field mt-0 pb-2 w-100 heading-5"
                            maxLength={14}
                            minLength={14}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            placeholder={input.placeholder}
                            defaultCountry="US"
                            value={values.phone_number}
                            onChange={(value) =>
                              setValues({ ...values, phone_number: value })
                            }
                          />
                        </div>
                      )}
                      {input.type === "switch" && (
                        <AppSwitch
                          className="mt-5"
                          key={input.id}
                          {...input}
                          value={values[input.name]}
                          onChange={appSwitchHandler}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <label htmlFor="county" className="mt-3  heading-5 text-dark">
                  Active State and County
                  <span className="text-danger">*</span>
                </label>
                {!stateCountyApi.isFetching && (
                  <AppMultiSelect
                    height={300}
                    _id={"vendor_multicounty"}
                    errorMessage={"State/County"}
                    //default state
                    placeholder={"Search State/County"}
                    defaultSet={afterClose}
                    treeData={enableStateCountyList}
                    //initial values acceteps only ["5","6","7"] format
                    initialVal={editId ? values.county_id_list : []}
                    onSelectVal={multiSelectCountyHandler}
                  />
                )}
              </div>
            </Row>
            {!stateCountyApi.isFetching && (
              <Row className="mt-2">
                <div className="col-12">
                  {/* <Contacts /> */}
                  <MultiContacts
                    _id={"vendor_multicontacts"}
                    roleList={[2, 3, 12]}
                    placeholder="Select Products"
                    whileEdit={(value) => {
                      setGetError(false);
                      if (editId) {
                        setValues({
                          ...values,
                          add_vendor_contact: [
                            ...values?.add_vendor_contact,
                            value,
                          ],
                        });
                      }
                    }}
                    //update
                    updateContact={(value) => {
                      setGetError(false);
                      if (editId) {
                        if (
                          values.add_vendor_contact?.length > 0 &&
                          values.add_vendor_contact?.find(
                            (val) => val["temp_id"] === value.temp_id
                          )
                        ) {
                          values.add_vendor_contact.forEach((item) => {
                            if (item.temp_id === value.temp_id) {
                              item.first_name = value.first_name;
                              item.middle_name = value.middle_name;
                              item.last_name = value.last_name;
                              item.contact_email = value.contact_email;
                              item.is_primary = value.is_primary;
                              item.phone_number = value.phone_number;
                              item.work_phone_number = value.work_phone_number;
                              item.user_type_id = value.user_type_id;
                              item.user_type_name = value.user_type_name;
                              item.position = value.position;
                            }
                          });
                        } else {
                          setValues({
                            ...values,
                            add_vendor_contact: [
                              ...values?.add_vendor_contact,
                              value,
                            ],
                          });
                        }

                        if (
                          values.edit_vendor_contact?.length > 0 &&
                          values.edit_vendor_contact?.find(
                            (val) => val["id"] === value.id
                          )
                        ) {
                          values.edit_vendor_contact.forEach((item) => {
                            if (item.id === value.id) {
                              item.first_name = value.first_name;
                              item.middle_name = value.middle_name;
                              item.last_name = value.last_name;
                              item.contact_email = value.contact_email;
                              item.is_primary = value.is_primary;
                              item.phone_number = value.phone_number;
                              item.work_phone_number = value.work_phone_number;
                              item.user_type_id = value.user_type_id;
                              item.user_type_name = value.user_type_name;
                              item.position = value.position;
                            }
                          });
                        } else {
                          setValues({
                            ...values,
                            edit_vendor_contact: [
                              ...values?.edit_vendor_contact,
                              value,
                            ],
                          });
                        }
                      }
                    }}
                    //delete record
                    deleteContact={(value) => {
                      setGetError(false);
                      if (editId) {
                        if (value) {
                          setValues({
                            ...values,
                            delete_contact: value?.id
                              ? [...values?.delete_contact, String(value?.id)]
                              : values?.delete_contact,
                            edit_vendor_contact:
                              values.edit_vendor_contact?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                          });
                        } else {
                          setValues({
                            ...values,
                            add_vendor_contact:
                              values.add_vendor_contact?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                          });
                        }
                      }
                    }}
                    //defualt close values
                    afterClose={afterClose}
                    initailValArr={editId ? values?.contact_list_values : []}
                    addFormVal={(value) => {
                      setValues({ ...values, contact_list_values: value });
                      setMultiContacts(value);
                      setGetError(false);
                    }}
                  />
                </div>
              </Row>
            )}
            <Row>
              <Col className="my-3">
                <div className="d-flex justify-content-center">
                  <div className="me-md-2 ">
                    <MyButton
                      onClick={handleReset}
                      type={"button"}
                      title={"CLEAR"}
                      variant={"secondary-md"}
                    />
                  </div>

                  <MyButton
                    isLoading={vendorsUpdate[1].isLoading}
                    type="submit"
                    title={"SUBMIT"}
                    variant={"primary-md"}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
}