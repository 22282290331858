import { NavLink } from "react-router-dom";

export const items = [
  {
    key: "/dashboard",
    icon: <img src="../Assets/img/dashboard.svg" width={"22px"} alt="" />,
    label: <NavLink to={"dashboard"}>My Dashboard</NavLink>,
    menu: "myDashboard",
  },
  // {
  //   key: "/order-wizard",
  //   icon: <img src="../Assets/img/order.png" width={"22px"} alt="" />,
  //   label: <NavLink to={"order-wizard"}>Order Wizard</NavLink>,
  //   menu: "orderWizrd",
  // },
 {
    key: "/order",
    icon: <img src="../Assets/img/order.png" width={"22px"} alt="" />,
    label: <NavLink to={"order"}>Orders</NavLink>,
    menu: "orders",
  },
  {
    key: "/reports",
    icon: <img src="../Assets/img/reports.png" width={"22px"} alt="" />,
    label: <NavLink to={"reports"}>Reports</NavLink>,
    menu: "reports",
  },
  {
    key: "/tools",
    icon: <img src="../Assets/img/settings.png" width={"22px"} alt="" />,
    label: <NavLink to={"tools"}>Tools</NavLink>,
    menu: "tools",
  },
  {
    key: "/settings",
    icon: <img src="../Assets/img/tools.png" width={"22px"} alt="" />,
    label: <NavLink to={"/settings"}>Vendor/Customer</NavLink>,
    menu: "settings",
  },
];
