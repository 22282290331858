import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import { useState, useEffect } from "react";
import { Col} from "react-bootstrap";
import { Alert, notification } from "antd";
import { useAddUserTypeMutation } from "../../../../Redux/Services/User";
import { useSelector } from "react-redux";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";

export const UserTypeModal = ({ afterClose, setShowHide, ...props }) => {
  const [values, setValues] = useState({
    name: "",
  });
  //status
  const [getError, setGetError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  //setDefault values
  useEffect(() => {
    setValues({
      name: "",
    });
  }, [afterClose]);
  // calling redux store
  const { token, user } = useSelector((state) => state.auth);
  //calling rtk query hook for adding customer
  const addNewUserType = useAddUserTypeMutation();
  const [addUserType] = addNewUserType;

  //useEffet for  add customer
  useEffect(() => {
    if (addNewUserType[1].isSuccess) {
      setShowHide(false);
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewUserType[1].data?.message,
      });
    }
    if (addNewUserType[1].error) {
      var errorData = [];
      for (const prop in addNewUserType[1].error.data?.error) {
        errorData.push(addNewUserType[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          addNewUserType[1].error.data.message ||
          addNewUserType[1].error.error ||
          addNewUserType[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewUserType[1].isLoading]);

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Enter User Type",
      errorMessage: "Please enter user type!",
      label: "Name",
      required: true,
    },
  ];

  const handleReset = (e) => {
    e.preventDefault();
    setValues({
      name: "",
    });
    // claer status
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addUserType({
      data: { ...values, created_by: user?.user_id },
      token: token,
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    clearStatus();
  };

  return (
    <div>
      <div className=" border-bottom border-2">
        <h5>Add UserType</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {addNewUserType[1].isLoading && <AppSpinner />}
        <div className={`${addNewUserType[1].isLoading ? "invisible" : ""} `}>
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              defaultSet={afterClose}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <Col>
            <div className="d-flex justify-content-center">
              <div className="me-md-2 my-3 mt-4 ">
                <MyButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>
              <div className="me-md-2 my-3 mt-4 ">
                <MyButton title={"SAVE"} variant={"primary-md"} />
              </div>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};
