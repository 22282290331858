import { Button, notification } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import AppTextArea from "../../../Components/AppTextArea/AppText";
import {
  useNoticeFlagListQuery,
  useUpdateMultipleNoticeFlagMutation,
} from "../../../Redux/Services/NoticeFlags";
import {
  useOrdersListQuery,
} from "../../../Redux/Services/Orders";

export default function NoticeAssignModal({
  showModalInitalState,
  orderId = [],
  setShowAddNoticeModal = false,
}) {
  const initialState = {
    order_id: [],
    notice_id: "",
    comments: "",
  };
  const [noticeOptionsData, setNoticeOptionsData] = useState([]);
  const [values, setValues] = useState(initialState);

  const { token } = useSelector((state) => state.auth);

  const ordersListApi = useOrdersListQuery(token, { skip: false });

  //calling rtk query for noticeFlag list
  const noticeFlagList = useNoticeFlagListQuery(token);
  //calling rtk query for noticeFlag list
  const [updateMultipleNoticeFlag, updateMultipleNoticeFlagResult] =
    useUpdateMultipleNoticeFlagMutation(token);

  ///useEffect for noticeFlag list
  useEffect(() => {
    if (noticeFlagList.isSuccess) {
      setNoticeOptionsData(noticeFlagList.data.data);
    }
    if (noticeFlagList.isError) {
      setNoticeOptionsData([]);
    }
  }, [noticeFlagList.isFetching]);

  ///useEffect for noticeFlag update
  useEffect(() => {
    if (updateMultipleNoticeFlagResult.isSuccess) {
      ///after resubmit details
      setShowAddNoticeModal(false);
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateMultipleNoticeFlagResult.data.message,
      });
      ordersListApi.refetch();
    }
    if (updateMultipleNoticeFlagResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          updateMultipleNoticeFlagResult.error?.data?.error ||
          updateMultipleNoticeFlagResult.error?.data?.message ||
          (updateMultipleNoticeFlagResult.error.error &&
            "server unreachable!") ||
          updateMultipleNoticeFlagResult.error.data?.msg.sqlMessage,
      });
    }
  }, [updateMultipleNoticeFlagResult.isLoading]);

  useEffect(() => {
    noticeFlagList.refetch();
    setValues(initialState);
  }, [showModalInitalState]);

  return (
    <div className="position-relative">
      {(noticeFlagList.isFetching ||
        updateMultipleNoticeFlagResult.isLoading) && <AppSpinner />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          //sending request for updating multiple coments
          updateMultipleNoticeFlag({
            data: {
              ...values,
              order_id: orderId,
            },
            token,
          });
        }}
        className="py-3"
      >
        <AppSelect
          value={values.notice_id}
          required
          label="Notice Flag"
          errorMessage="Please select notice flag!"
          classStyle={"border p-1 mt-3 w-100 "}
          defaultSet={showModalInitalState}
          onChange={(e, value) => setValues({ ...values, notice_id: value })}
          options={
            <>
              <option value={""}>Select Notice Flag</option>
              {noticeOptionsData?.map((ele) => {
                if (!noticeFlagList.isFetching) {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                }
              })}
            </>
          }
        />

        <AppTextArea
          required={true}
          label={"Comment"}
          errorMessage={"please enter comment!"}
          placeholder="Enter Comment"
          value={values.comments}
          onChange={(e) => setValues({ ...values, comments: e.target.value })}
        />
        <Button
          className="fs-12 text-white mt-2"
          type="primary"
          size="small"
          htmlType="submit"
        >
          SUBMIT
        </Button>
      </form>
    </div>
  );
}
