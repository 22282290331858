import { createSlice } from "@reduxjs/toolkit";

const branchList = createSlice({
  name: "branchList",
  initialState: {
    branchList: [],
  },
  reducers: {
    branchListAction: (state, action) => {
      state.branchList = action.payload;
    },
  },
});

export const {branchListAction} = branchList.actions;

export default branchList.reducer;
