import React from "react";
import {
	FiMessageCircle,
	FiPause,
	FiTrendingUp,
	FiUserCheck,
} from "react-icons/fi";

import { HiOutlineBookOpen } from "react-icons/hi";
import { AiFillExclamationCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { RiFileTextLine } from "react-icons/ri";
import { Card, Col, Row } from "react-bootstrap";
import "./dashboard.css";
export default function StatusCards(props) {
	const { data = [] } = props;

	const cardsStyle = [
		{
			id: 1,
			openIcon: <HiOutlineBookOpen size={40} className={"openImg"} />,
		},
		{
			id: 2,
			InProgressIcon: <FiTrendingUp size={40} className={"openImg"} />,
		},
		{
			id: 3,
			HoldIcon: <AiFillExclamationCircle size={40} className={"openImg"} />,
		},
		{
			id: 4,
			cmpleteIcon: <AiOutlineCheckCircle size={40} className={"openImg"} />,
		},
		{
			id: 5,
			pendingIcon: <FiMessageCircle size={40} className={"openImg"} />,
		},
		{
			id: 6,
			ClarifyIcon: <FiPause size={40} className={"openImg"} />,
		},

		{
			id: 7,
			QcIcon: <FiUserCheck size={40} className={"openImg"} />,
		},
		{
			id: 8,
			closeIcon: <RiFileTextLine size={40} className={"openImg"} />,
		},
	];
	return (
		<>
			<div className="row  status-cards-row p-2">
				<>
					{cardsStyle.map((cardstyleinfo) => (
						<>
							{cardstyleinfo.openIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Open"}
												</h5>
												<h3 className="headding-3"> {data?.open_orders}</h3>
											</Col>
											<Col lg={4} className={"text-center align-item-center"}>
												{cardstyleinfo.openIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.InProgressIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"In progress"}
													{"  "}
												</h5>
												<h3 className="headding-3">
													{" "}
													{data?.in_progress_orders}
												</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.InProgressIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}

							{cardstyleinfo.HoldIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"On Hold"}
													{"  "}
												</h5>
												<h3 className="headding-3"> {data?.on_hold_orders}</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.HoldIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.ClarifyIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Delinquent"}
													{"  "}
												</h5>
												<h3 className="headding-3">
													{" "}
													{data?.delinquent_orders}
												</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.ClarifyIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}

							{cardstyleinfo.pendingIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Overdue "}
													{"  "}
												</h5>
												<h3 className="headding-3"> {data?.overdue_orders}</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.pendingIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.QcIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Rejected"}
													{"  "}
												</h5>
												<h3 className="headding-3"> {data?.rejected_orders}</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.QcIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.cmpleteIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Completed"}
													{"  "}
												</h5>
												<h3 className="headding-3">
													{" "}
													{data?.completed_orders}
												</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.cmpleteIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.closeIcon && (
								<div className={"col-lg-3 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Rush Orders"}
													{"  "}
												</h5>
												<h3 className="headding-3"> {data?.rush_orders}</h3>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.closeIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
						</>
					))}
				</>
			</div>
		</>
	);
}
