import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import {  FaPlus, FaStarOfLife } from "react-icons/fa";
import "./MultiManagement.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiManagement({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  getDate = false,
  setDate = false,
  _id,
}) {
  const [active, setActive] = useState("2");

  const initailVal = {
    description: "",
    fees: "",
    verify_date: setDate,
  };
  const [values, setValues] = useState(initailVal);
  const [ showDate,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { description, fees, verify_date } = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Description</span>,
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: <span>Amount</span>,
      dataIndex: "fees",
      key: "fees",
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      id: 1,
      name: "description",
      _id: "fess_description",
      type: "text",
      value: description || "",
      column: "3",
      placeholder: "Enter Description",
      errorMessage: "Please enter description!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 4545,
      name: "fees",
      _id: "fees",
      type: "number",
      value: fees || "",
      column: "3",
      maxLength: 9,
      placeholder: "Enter Amount",
      errorMessage: "Please enter amount!",
    },
    {
      id: 454455,
      name: "verify_date",
      _id: "verify_date",
      type: "date",
      value: verify_date || "",
      column: "3",
      placeholder: "Enter Verify Date",
      errorMessage: "Please enter verify date!",
    },
  ];

  const onChange = (e) => {
    setGetErr(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "fees") {
      setValues({
        ...values,
        [name]: value.replace(/[^0-9.]/gi, ""),
      });
    }
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      setData([...newData, { ...values, fees: values?.fees }]);
      updateContact && updateContact({ ...values, fees: values?.fees });
      setAddRowToggler(false);
      setEditId(null);
      setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          fees: values?.fees,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, fees: values?.fees, temp_id: randomId });
      setValues(initailVal);
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <RiMoneyDollarCircleLine size={15} /> <span>Fees</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              <div className="date align-items-center d-flex gap-1">
                <h6 className="align-items-center d-flex gap-2 fs-13">
                  <span className="text-dark heading-5"> Verified Date:</span>{" "}
                </h6>
              </div>
              {inputs.map((input) => (
                <>
                  {input.type === "date" && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={(e) => {
                        setShowDate(true);
                        getDate && getDate(e.target.value);
                        setValues({ ...values, verify_date: e.target.value });
                      }}
                    />
                  )}
                </>
              ))}
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable
              pagination={false}
              dataSource={data}
              columns={columns}
            />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {input.name === "fees" && (
                      <AppNumeric
                        {...input}
                        type="text"
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        onChange={(value) => {
                          setValues({ ...values, fees: value });
                        }}
                      />
                    )}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${
                        !fees || !description ? "disabled" : "btn-success"
                      } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
