import { createSlice } from "@reduxjs/toolkit";

const vendorsList = createSlice({
  name: "vendorsList",
  initialState: {
    vendorsList: [],
  },
  reducers: {
    vendorsListAction: (state, action) => {
      state.vendorsList = action.payload;
    },
  },
});

export const {vendorsListAction} = vendorsList.actions;

export default vendorsList.reducer;
