import React, { useState } from "react";
import { Alert, Form, Popconfirm, Typography, Collapse } from "antd";
import AppTable from "../AppTable/AppTable";
import { AiOutlineCheck, AiOutlineClose,AiOutlineContacts } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { FaPlus, FaStarOfLife } from "react-icons/fa";
import "./contacts-form.css";
import { EditOutlined, QuestionCircleOutlined,CaretRightOutlined} from "@ant-design/icons";
import { MdAccountCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import AppInput from "../AppInput/AppInput";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { useEmailCheckMutation } from "../../Redux/Services/email";
import { removeArrayBlank, removeBlank } from "../../Controllers/Functions";
import Input from "react-phone-number-input/input";
import { Row } from "react-bootstrap";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;

export default function MultiContacts({
	afterClose,
	addFormVal,
	initailValArr,
	updateContact,
	deleteContact,
	roleList = [],
	whileEdit = false,
	_id,
}) {
	///vendor contacts details
	const [active, setActive] = useState("2");
	const [editingKey, setEditingKey] = useState("");
	const initailVal = {
		name: "",
		position: "",
		work_phone_number: "",
		phone_number: "",
		contact_email: "",
		user_type_name: "",
		user_type_id: "",
		is_primary: false,
		contact_password: "",
		generate_password_check: false,
	};
	const [form] = Form.useForm();
	const [values, setValues] = useState(initailVal);
	const [data, setData] = useState(initailValArr);
	const [addRowToggler, setAddRowToggler] = useState(false);
	const isEditing = (record) => record.id === editingKey;
	const [editId, setEditId] = useState(null);
	const [getErr, setGetErr] = useState(false);

	const {
		first_name,
		middle_name,
		last_name,
		user_type_name,
		position,
		contact_password,
		work_phone_number,
		phone_number,
		contact_email,
		user_type_id,
	} = values;

	const { authColumn } = useSelector((state) => state.flagRoles);

	const { token } = useSelector((state) => state.auth);

	//api request
	const emailCheckApi = useEmailCheckMutation();
	const [emailCheck] = emailCheckApi;

	//edit row
	const editHandler = (record) => {
		setEditId(record);
		setAddRowToggler(true);
		setValues(record);
	};

	//calling redux stor for vendors list
	const { userTypeList } = useSelector((state) => state.userTypeList);

	//create function for customer fields
	function matchColumn(value) {
		if (authColumn.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}
	let regex = new RegExp("[A-Za-z0-9.]+@[a-zA-Z]+.[a-zA-Z]{2,3}");
	const columns = [
		{
			title: "User Type",
			dataIndex: "user_type_name",
			key: "user_type_name",
			align: "center",
			width: 130,
		},
		{
			title: <span>First Name</span>,
			dataIndex: "first_name",
			key: "first_name",
			inputType: "text",
			align: "center",
		},
		{
			title: <span>Middle Name</span>,
			dataIndex: "middle_name",
			key: "middle_name",
			inputType: "text",
			render: (value) => {
				return <>{value || "NA"}</>;
			},
			align: "center",
		},
		{
			title: <span>Last Name</span>,
			dataIndex: "last_name",
			key: "last_name",
			inputType: "text",
			render: (value) => {
				return <>{value || "NA"}</>;
			},
			align: "center",
		},
		{
			title: <span>Position</span>,
			dataIndex: "position",
			inputType: "text",
			key: "position",
			required: false,
			editable: true,
			render: (value) => {
				return <>{value || "NA"}</>;
			},
			align: "center",
		},
		{
			title: <span>Work Phone Number</span>,
			dataIndex: "work_phone_number",
			inputType: "number",
			key: "work_phone_number",
			required: true,
			type: "phone",
			maxLength: 10,
			minLength: 10,
			editable: true,
			render: (value) => {
				return (
					<>
						{value && value?.slice(0, 2) === "+1"
							? value?.slice(2)
							: value || "NA"}
					</>
				);
			},
			align: "center",
		},

		{
			title: <span>Mobile Number</span>,
			inputType: "number",
			dataIndex: "phone_number",
			key: "phone_number",
			maxLength: 10,
			type: "phone",
			minLength: 10,
			required: false,
			editable: true,
			render: (value) => {
				return (
					<>
						{value && value?.slice(0, 2) === "+1"
							? value?.slice(2)
							: value || "NA"}
					</>
				);
			},
			align: "center",
		},
		{
			title: <span>Email</span>,
			dataIndex: "contact_email",
			inputType: "email",
			key: "contact_email",
			align: "center",
		},
		{
			title: <span>Password</span>,
			dataIndex: "contact_password",
			inputType: "text",
			key: "contact_password",
			render: (value) => {
				return <span>***********</span>;
			},
			align: "center",
		},
		{
			title: "Primary",
			inputType: "checkbox",
			dataIndex: "is_primary",
			align: "center",
			key: "is_primary",
			render: (checked, row) =>
				row.is_primary === false ? <AiOutlineClose /> : <AiOutlineCheck />,
			editable: true,
		},

		{
			title: "Action",
			dataIndex: "operation",
			key: "operation",
			className: `${matchColumn("edit") ? "" : "d-none"}`,
			width: matchColumn("edit") && 130,
			align: "center",
			render: (_, record) => {
				return (
					<>
						<span>
							<EditOutlined
								style={{ cursor: "pointer" }}
								onClick={() => editHandler(record)}
								size={50}
							/>
						</span>
						<Popconfirm
							title="Are you sure you want to delete？"
							onConfirm={() => {
								deleteHandler(record);
							}}
							getPopupContainer={(trigger) => trigger.parentElement}
							placement="topLeft"
							icon={
								<QuestionCircleOutlined
									style={{
										color: "red",
									}}
								/>
							}
							okText="Yes"
							cancelText="No"
						>
							<span className={"ms-2 "}>
								<MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
							</span>
						</Popconfirm>
					</>
				);
			},
		},
	];

	//fileter user types
	const filetrUserTypeById = (arr = [], keys = []) => {
		const res = [];
		for (let i = 0; i < arr.length; i++) {
			const { id } = arr[i];
			if (keys.includes(id)) {
				res.push(arr[i]);
			}
		}
		return res;
	};

	const filetrValues = filetrUserTypeById(userTypeList, roleList);

	// let res = [];
	// res = filetrValues?.filter((el) => {
	//   return !data?.find((element) => {
	//     return element.user_type_name === el.name;
	//   });
	// });

	const inputs = [
		{
			id: 14545,
			name: "user_type_name",
			_id: "contacts_user_type_name",
			type: "select",
			value: user_type_name || "",
			column: "3",
			options: filetrValues || [{ id: "", name: "" }],
			errorMessage: "Please select user type!",
			required: true,
		},
		{
			id: 1,
			name: "first_name",
			_id: "contacts_first_name",
			type: "text",
			value: first_name || "",
			column: "3",
			placeholder: "Enter First Name",
			errorMessage: "Please enter first name!",
			required: true,
			icon: <FaStarOfLife size={5} color="red" />,
		},
		{
			id: 1455,
			name: "middle_name",
			_id: "contacts_middle_name",
			type: "text",
			value: middle_name || "",
			column: "3",
			placeholder: "Enter Middle Name",
			errorMessage: "Please enter middle name!",
		},
		{
			id: 14557545,
			name: "last_name",
			_id: "contacts_last_name",
			type: "text",
			value: last_name || "",
			column: "3",
			placeholder: "Enter Last Name",
		},

		{
			id: 2,
			name: "position",
			_id: "contacts_position",
			type: "text",
			value: position || "",
			column: "3",
			placeholder: "Enter Position",
			errorMessage: "Please enter position!",
		},
		{
			id: 4,
			name: "phone_number",
			_id: "contacts_phone_number",
			type: "number",
			value: phone_number || "",
			column: "3",
			placeholder: "Enter Mobile Number",
			errorMessage: "Please enter 10 digits mobile number!",
			maxLength: 10,
			minLength: 10,
		},
		{
			id: 24545,
			name: "work_phone_number",
			_id: "contacts_work_phone_number",
			type: "number",
			value: work_phone_number || "",
			column: "3",
			placeholder: "Enter Work Phone Number",
			errorMessage: "Please enter 10 digits work phone number!",
			required: true,
			maxLength: 10,
			minLength: 10,
			icon: <FaStarOfLife size={5} color="red" />,
		},

		{
			id: 545785,
			name: "contact_email",
			_id: "contacts_email",
			type: "email",
			column: "3",
			value: contact_email || "",
			placeholder: "Enter Email",
			errorMessage: "Please enter valid email!",
			required: true,
			icon: <FaStarOfLife size={5} color="red" />,
		},
		,
		{
			id: 598989,
			name: "contact_password",
			type: "text",
			_id: "contact_password",
			column: "3",
			value: contact_password || "",
			placeholder: "Enter Password",
			errorMessage: "Please enter password!",
			required: true,
			icon: <FaStarOfLife size={5} color="red" />,
		},
		,
		{
			id: 145452,
			name: "generate_password_check",
			type: "checkbox",
			_id: "contacts_generate_password_check",
			checked: values.generate_password_check || "",
			label: "Generate Password",
			column: `3`,
			margin: "3",
		},
		{
			id: 6645644,
			name: "is_primary",
			type: "checkbox",
			_id: "contacts_is_primary",
			checked: values?.is_primary || "",
			label: "Pri",
			required: true,
			column: "1",
			margin: "3",
		},
	];
	const onChange = (e) => {
		setGetErr(false);
		const { name, value } = e.target;

		if (name === "contact_password" && values?.generate_password_check) {
			setValues({ ...values, [name]: values.contact_password });
		} else if (
			name === "contact_password" &&
			!values?.generate_password_check
		) {
			setValues({ ...values, [name]: value });
		} else {
			setValues({
				...values,
				[name]: value,
			});
		}
	};

	//check bbox
	const handleChange = (property, value) => {
		setValues({ ...values, [property]: value });
		setGetErr(false);
		if (property === "generate_password_check") {
			setValues({
				...values,
				[property]: value,
				contact_password: value ? randomPass.randomPassword() : "",
			});
		}
		///
	};
	//sending request
	const handleSubmit = () => {
		if (phone_number?.length !== 12 && phone_number?.length > 0) {
			setGetErr("Please enter valid phone number");
		} else if (work_phone_number?.length !== 12) {
			setGetErr("Please enter valid work phone number");
		} else {
			if (editId?.contact_email === values?.contact_email) {
				removeBlank(values);
				const newData = data.filter((ele) => {
					return (ele?.temp_id || ele?.id) !== (editId?.temp_id || editId?.id);
				});
				setData([...newData, values]);
				setAddRowToggler(false);
				updateContact && updateContact(values);
				setEditId(null);
				setValues(initailVal);
			} else {
				emailCheck({ email: values.contact_email, token: token });
			}
		}
	};

	const cancelHandler = () => {
		setValues(initailVal);
		addFormVal(data);
		setAddRowToggler(false);
		setEditId(null);
		setGetErr(false);
	};
	const addNewRecord = () => {
		setAddRowToggler(!addRowToggler);
		setEditId(null);
	};

	const deleteHandler = (id) => {
		setData(
			data.filter((ele) => {
				return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
			})
		);
		//sending delete id
		deleteContact(id);
	};

	//select handler
	const selectHandler = (property, value, arrtibute) => {
		setValues({
			...values,
			user_type_name: value,
			user_type_id: arrtibute?.getAttribute("data_name"),
		});
	};

	useEffect(() => {
		setAddRowToggler(false);
		setData(initailValArr);
		setValues(initailVal);
		setGetErr(false);
		setEditId(null);
		setActive("2");
	}, [afterClose]);

	useEffect(() => {
		addFormVal(data);
	}, [data?.length]);

	//checking existing email
	var checkvaluesEmail = false;
	data?.map((e) => {
		if (e.contact_email === values.contact_email) {
			if (
				editId?.contact_email === e.contact_email &&
				editId?.contact_email === values.contact_email
			) {
				checkvaluesEmail = false;
			} else {
				checkvaluesEmail = true;
			}
		}
	});

	useEffect(() => {
		if (emailCheckApi[1].isSuccess) {
			if (editId) {
				if (!checkvaluesEmail) {
					removeBlank(values);
					const newData = data.filter((ele) => {
						return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
					});
					setData([...newData, values]);
					setAddRowToggler(false);
					updateContact && updateContact(values);
					setEditId(null);
					setValues(initailVal);
				} else {
					setGetErr("Email already exist in contacts list!");
				}
			} else {
				if (!checkvaluesEmail) {
					//remove blank values
					removeBlank(values);
					const randomId = randomPass.randomPassword({
						length: 9,
						characters: randomPass.digits,
					});
					setData([
						...data,
						{
							...values,
							temp_id: randomId,
						},
					]);
					//while edit and ading new record directly
					whileEdit({ ...values, temp_id: randomId });
					setValues(initailVal);
					setAddRowToggler(false);
				} else {
					setGetErr("Email already exist in contacts list!");
				}
			}
		}
		if (emailCheckApi[1].isError) {
			setGetErr(
				emailCheckApi[1].error.data.message || emailCheckApi[1].error.error
			);
		}
	}, [emailCheckApi[1].isLoading]);

	return (
		<Collapse
			className="m-0 py-0 gray-table site-collapse-custom-collapse"
			expandIcon={({ isActive }) => (
				<CaretRightOutlined
					style={{ fontSize: 14 }}
					className="mt-1"
					rotate={isActive ? 90 : 0}
				/>
			)}
			expandIconPosition={"end"}
			activeKey={active}
			accordion
			onChange={(value) => {
				setActive(value);
			}}
    >
       <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className="fs-18 mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <AiOutlineContacts size={18} />Contacts <span className="text-danger">*</span>
          </h6>
        }
        key="1"
      >
      {active === "1" && (
        <div className="gray-table-col border rounded-2 mb-2 p-2 py-1" id={_id}>
          <div
            className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
          >
            <h6 className="fs-18 fw-bold me-2">
             
            </h6>
            {!addRowToggler && (
              <div className={`add_icon`} onClick={addNewRecord}>
                <FaPlus />
              </div>
            )}
          </div>

          <AppTable
            // sort={sort}
            errors={{
              max: 10,
              min: 10,
              dataIndex: ["phone_number", "work_phone_number"],
            }}
            pagination={false}
            form={form}
            isEditing={isEditing}
            dataSource={data}
            columns={columns}
          />

          {getErr && (
            <div className="mt-3">
              <Alert
                message={getErr}
                className="py-1 mx-3"
                type="error"
                showIcon
              />{" "}
            </div>
          )}
          {/* //loading  */}
          {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

          {addRowToggler && (
            <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
              {inputs.map((input) => (
                <>
                  {input.type == "checkbox" &&
                    input.name === "generate_password_check" &&
                    !editId?.id && (
                      <AppCheckbox {...input} handleChange={handleChange} />
                    )}
                  {input.type == "checkbox" &&
                    input.name !== "generate_password_check" && (
                      <AppCheckbox {...input} handleChange={handleChange} />
                    )}
                  {input.type === "select" &&
                    input.name === "user_type_name" &&
                    !editId?.id && (
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select User Type</option>
                            {input.options.map((ele) =>
                            {
                              return (
                                <option
                                  key={ele.id}
                                  data_name={ele.id}
                                  value={ele.name}
                                >
                                  {ele.name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  {(input.type === "text" || input.type === "email") &&
                    input.type !== "number" &&
                    input.name !== "contact_password" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                  {input.name === "contact_password" && !editId?.id && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  )}
                  {input.type === "number" && (
                    <div className="col-12 col-md-3 position-relative">
                      <Input
                        onKeyPress={(e) =>
                        {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        className="input-field mt-0 pb-2 w-100 heading-5"
                        maxLength={14}
                        minLength={14}
                        placeholder={input.placeholder}
                        defaultCountry="US"
                        value={values[input.name]}
                        onChange={(value) =>
                        {
                          setGetErr(false);
                          setValues({ ...values, [input.name]: value });
                        }}
                      />
                      {input.name !== "phone_number" && (
                        <div className={`input-icon`}>
                          <FaStarOfLife size={5} color="red" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))}
              <div className="col-12 col-lg-1 mt-3 p-0">
                <div className="d-flex">
                  <span
                    onClick={handleSubmit}
                    className={`btn btn-primary  btn-sm edit-buttons ${!work_phone_number ||
                        !contact_email ||
                        !first_name ||
                        (!contact_password && !editId?.id) ||
                        !regex.test(contact_email) ||
                        !user_type_id
                        ? "disabled"
                        : "btn-success"
                      }`}
                  >
                    {editId?.id ? "Update" : "Save"}
                  </span>
                  <span
                    onClick={cancelHandler}
                    className="btn btn-primary ms-1 btn-sm edit-buttons"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        )}
        </Panel>
		</Collapse>
	);
}
