import React from "react";
import logo from "../Notice/Temp.png";
import "./Notice.css";
export default function NoticeContent({ values }) {
  const Prints = () => (
    <div className="text-center ">
      <div>
        <b>MUD Notice Invoice</b>{" "}
      </div>
      <div className="text-start">
        <br />
        <br />
        <div>
          <h4>
            {" "}
            Title Company:Trinity Title of {values?.state_name || ""}, LLC
            <br />
            GF Number: {values?.gf_number || ""} <br />
            Date Provided:
            <br />
            District: City Of {values?.jurisdiction_name || ""}
            <br />
            Fee(including tax):$10.00
            <br />
          </h4>
        </div>
      </div>
      <br />
      <br />
      <br />

      <p>
        Please remit Notice To Purchaser fee to our PO Box:
        <br />
        <img src={logo} width="98px" height="50px" />
        <br />
        <br />
        Notice to Purchaser can be found on the following page.
      </p>
      <br />
    </div>
  );
  const Page2 = () => (
    <div className="page-break">
      <div className="text-center mb-3">
        <h3>NOTICE TO PURCHASER</h3>
      </div>
      <div>
        <h5> GF Number: {values?.gf_number}</h5>
        <br />
        <h5>The State of {values?.state_name}</h5>
        <h5> County of {values?.county_name}</h5>
      </div>
      <br />

      <p>
        The real property, described below, that you are about to purchase is
        located in the <b>City Of {values?.jurisdiction_name}</b> (the
        "District").The District has taxing authority separate from any other
        taxing authority. As of this date, the rate of taxes levied by the
        district on real property located in the district is <b>0.7405</b>on
        each $100 of assessed valuation. The totalamount of bonds,excluding
        refunding bonds and any bonds or any portion of bonds issued that are
        payable solely from revenues received or expected to be received under a
        contract with governmentalentity,approvedby the voters and which have
        been or may, at this date,be issued by the
        <b> City Of {values?.jurisdiction_name}</b>,is <b>$0</b> and the
        aggregate initial principal amounts of all bonds issued for one or more
        of the specified facilities of the district and payable in whole or in
        part from property taxes is <b>$0</b>.
        <br />
        <br />
        The District has the authority to adopt and impose a standby fee on
        property in the District that has water, sanitary sewer, or drainage
        facilities and services available but not connected and which does not
        have a house, building, or other improvement located there on and does
        not substantiallyutilize the utility capacity available to the property.
        The District may exercise the authoritywithout holding election on the
        matter. As of this date, the most recent amount of the standby feeis{" "}
        <b>$0</b>. An unpaid standby fee is a personal obligation of the person
        that owned the property at thetime of imposition and is secured by a
        lien on the property. Any person may request a certificatefrom the
        District stating the amount, if any, of unpaid standby fees on a tract
        of property in the district.
        <br />
        <br />
        By law, if a MUD, WCID, Utility or Limited District is annexed into the
        corporate boundaries of a municipality,it may be dissolved.
        <br />
        <br />
        The purpose of this District is to provide water, sewer, drainage, or
        flood control facilities and services within the District through the
        issuance of bonds payable in whole or in part from property taxes. The
        cost of these utility facilities is not included in the purchase price
        of your property, and these utility facilities are owned or to be owned
        by the District. The legal description of the property which you are
        acquiring is as follows:
        <br />
        <br />
        <b>{values?.legal_description}</b>
        <br />
        <br />
        <h4 className="w-100">
          PURCHASER IS ADVISED THAT THE INFORMATION SHOWN ON THIS FORM IS
          SUBJECT TO CHANGE BY THE DISTRICT AT ANY TIME. THE DISTRICT ROUTINELY
          ESTABLISHES TAX RATES DURING THE MONTHS OF SEPTEMBER THROUGH DECEMBER
          OF EACHYEAR, EFFECTIVE FOR THE YEAR IN WHICH THE TAX RATES ARE
          APPROVED BY THE DISTRICT. PURCHASER IS ADVISED TO CONTACT THE DISTRICT
          TO DETERMINE THE STATUS OF ANY CURRENT OR PROPOSED CHANGES TO THE
          INFORMATION SHOWN ON THIS FORM.
        </h4>
      </p>
    </div>
  );
  const Page3 = () => (
    <div className="page-break">
      <div>
        <p>
          The undersigned purchaser hereby acknowledges receipt of the foregoing
          notice at our prior execution of a binding contract for the purchase
          of real property described in such notice or at closing of purchase of
          the real property.
        </p>
      </div>
      <br />
      <br />
      <br />
      <p>Date:_________________</p>
      <div className="row">
        <div className="col-6">
          <br />
          <br />
          <hr className="m-0 mb-1" />
          <h5>Purchaser(Buyer1):{values?.buyer_1 || ""}</h5>
        </div>
        <div className="col-6">
          <br />
          <br />
          <hr className="m-0 mb-1" />
          <h5> Purchaser(Buyer2):{values?.buyer_2 || ""}</h5>
        </div>
        <div className="col-6 mt-4">
          <br />
          <hr className="m-0 mb-1" />
          <h5>Purchaser(Buyer3):{values?.buyer_3 || ""}</h5>
        </div>
        <div className="col-6 mt-4">
          <br />
          <hr className="m-0 mb-1" />
          <h5> Purchaser(Buyer4):{values?.buyer_4 || ""}</h5>
        </div>
      </div>
      <div>
        <br />
        <p>
          STATE OF {values?.state_name || ""}
          <br />
          COUNTY OF {values?.county_name || ""}
          <br />
          This instrument was acknowledged by me on the _____ day of
          _______________, 20____, by {values?.buyer_1 || ""},{" "}
          {values?.buyer_2 || " "}, {values?.buyer_3 || " "} and{" "}
          {values?.buyer_4 || " "}.
        </p>
      </div>
      <br />
      <br />
      <p>
        ________________________________ <br />
        Notary Public Signature
      </p>
    </div>
  );
  const Page4 = () => (
    <div className="page-break">
      <br />
      <br />
      <br />

      <p>Date:_________________</p>

      <div className="row">
        <div className="col-6">
          <br />
          <br />
          <hr className="m-0 mb-1" />
          <h5> Seller(Seller1):{values?.seller_1 || ""}</h5>
        </div>
        <div className="col-6">
          <br />
          <br />
          <hr className="m-0 mb-1" />
          <h5> Seller(Seller2):{values?.seller_2 || ""}</h5>
        </div>
        <div className="col-6 mt-4">
          <br />
          <hr className="m-0 mb-1" />
          <h5> Seller(Seller_3):{values?.seller_3 || ""}</h5>
        </div>
        <div className="col-6 mt-4">
          <br />
          <hr className="m-0 mb-1" />
          <h5> Seller(Seller4):{values?.seller_4 || ""}</h5>
        </div>
      </div>
      <div>
        <br />
        <p>
          STATE OF {values?.state_name || ""}
          <br />
          COUNTY OF {values?.county_name || ""}
        </p>
        <h5>
          {" "}
          This instrument was acknowledged by me on the ______ day of
          _______________, 20____, by &nbsp; {values?.seller_1 || ""}
          {values?.seller_2 || ""} &nbsp; {values?.seller_3 || ""} &nbsp; and &nbsp;
          {values?.seller_4 || ""}.
        </h5>
        <p>
          <br /> ________________________________
          <br />
          Notary Public Signature
          <br />
          <br />
          <br />
          <br />
          AFTER RECORDING RETURN TO:
        </p>
        <h5>
          {" "}
          Trinity Title of {values?.state_name || " "}, LLC : 204 S. IH 35, Ste.
          201 Georgetown &nbsp; {values?.state_name || " "} 78628
        </h5>
      </div>
    </div>
  );

  return (
    <div className="k-pdf-export ">
      <Prints />
      <Page2 />
      <Page3 />
      <Page4 />
    </div>
  );
}
