import React, { useState, useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { Form } from "antd";
import "./orderwizard.css";
import AppSelect from "../../Components/AppSelect/AppSelect";
import AppButton from "../../Components/AppButton/AppButton";
import FormInput from "../../Components/AppInput/AppInput";
import AppCheckbox from "../../Components/AppCheckbox/AppCheckbox";
import { useSelector } from "react-redux/es/exports";
import OrderTab from "./orderTab";
import { RightOutlined } from "@ant-design/icons";
export default function OrderWizard() {
	const [values, setValues] = useState({
		product_type_id: "",
		file_no: "",
		buyer_name: "",
		customer_id: "",
		seller_name: "",
		closer_id: "",
		address: "",
		county_id: "",
		legal: "",
		branch_id: "",
		is_hoa: "",
	});
	const [defaultActiveKey, setDefaultActivekey] = useState("1");
	const [productTypeData, setproductTypeData] = useState([
		{ product_type_id: "", name: "" },
	]);
	const [customerData, setCustomerData] = useState([
		{ customer_id: "", name: "" },
	]);
	const [branchData, setBranchData] = useState([{ branch_id: "", name: "" }]);
	const [closerData, setCloserData] = useState([{ closer_id: "", name: "" }]);
	const [countyData, setCountyData] = useState([{ county_id: "", name: "" }]);

	const { productTypeList } = useSelector((state) => state.productTypeList);
	const { customerList } = useSelector((state) => state.customerList);
	const { branchList } = useSelector((state) => state.branchList);
	const { countyList } = useSelector((state) => state.countyList);
	const { closerList } = useSelector((state) => state.closerList);
	useEffect(() => {
		setproductTypeData(productTypeList);
		setCustomerData(customerList);
		setBranchData(branchList);
		setCountyData(countyList);
		setCloserData(closerList);
	}, [productTypeList, customerList, branchList, countyList, closerList]);

	const { labelList } = useSelector((state) => state.labelList);
	const inputs = [
		{
			id: 1,
			name: "product_type_id",
			type: "select",
			options: productTypeData,
			value: values.product_type_id || "",
			column: "6",
			placeholder: "certSimple",
			errorMessage: "Please select product type!",
			label: "Product Type",
			subhead: "",
			required: true,
		},
		{
			id: 2,
			name: "file_no",
			type: "text",
			column: "6",
			placeholder: "Enter File Number",
			errorMessage: "Please enter file number!",
			label: "File #",
			subhead: "",
			required: true,
		},
		{
			id: 3,
			name: "buyers",
			type: "text",
			column: "6",
			placeholder: "Buyer name(s) (optional)",
			errorMessage: "Please select customer!",
			label: "Buyer(s)",
			subhead: "",
			required: true,
		},
		{
			id: 4,
			name: "customer_id",
			type: "select",
			value: values.customer_id || "",
			placeholder: "Select Customer ",
			errorMessage: "Please select customer !",
			label: "Customer",
			options: customerData,
			required: true,
			column: "6",
		},
		{
			id: 5,
			name: "sellers",
			type: "text",
			column: "6",
			placeholder: "Seller name(s) (optional)",
			errorMessage: "Please enter seller names!",
			label: "Sellers",
			subhead: "",
			required: true,
		},
		{
			id: 6,
			name: "closer_id",
			type: "select",
			value: values.closer_id || "",
			options: closerData,
			column: "6",
			placeholder: "Select Closer",
			errorMessage: "Please select closer!",
			label: "Closer",
			subhead: "",
			required: true,
		},
		{
			id: 6,
			name: "address",
			type: "text",
			column: "6",
			placeholder: "Enter address",
			errorMessage: "Please enter address!",
			label: "Address",
			subhead: "",
			required: true,
		},
		{
			id: 7,
			name: "county_id",
			type: "select",
			value: values.county_id || "",
			options: countyData,
			column: "6",
			placeholder: "Select County",
			errorMessage: "Please select county !",
			label: "County",
			subhead: "",
			required: true,
		},
		{
			id: 8,
			name: "legal",
			type: "text",
			column: "6",
			placeholder: "Property Legal (optional)",
			errorMessage: "Please select property!",
			label: "Legal",
			subhead: "",
			required: "",
		},
		{
			id: 9,
			name: "branch_id",
			type: "select",
			value: values.branch_id || "",
			options: branchData,
			column: "6",
			placeholder: "Select Branch",
			errorMessage: "Please select branch !",
			label: "Branch",
			subhead: "",
			required: true,
		},
		{
			id: 10,
			name: "is_hoa",
			type: "checkbox",
			checked: true,
			column: "12",
			// errorMessage: "Please select branch!",
			label: "Is HOA?",
			subhead: "",
			required: true,
			margin: 3,
		},
	];

	const handleSubmit = (e) => {
		e.preventDefault();
	};
	const handleReset = (e) => {
		e.preventDefault();
		setValues("");
	};
	const onCheckboxChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	const onChange = async (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value.replace(/[^a-zA-Z" "]/gi, ""),
		});
		//text and number
		if (name === "address" || name === "legal") {
			setValues({
				...values,
				[name]: value,
			});
		}
		//number only
		else if (name === "file_no") {
			setValues({
				...values,
				[name]: value.replace(/[^0-9]/gi, ""),
			});
		}
	};

	const selectHandler = (property, value) => {
		setValues({ ...values, [property]: value });
	};
	const handleClick = () => {
		if (defaultActiveKey === "1")
			setDefaultActivekey("0")
		else
		setDefaultActivekey("1");
		
	};
	useEffect(() => {}, []);
	return (
		<>
			<h5 className="settings-container">
				{labelList["cs.settings.header"]} |{" "}
				{labelList["cs.dashboard.menu.neworder"]}
			</h5>
			<Accordion defaultActiveKey={defaultActiveKey} onClick={handleClick}>
				<Accordion.Item eventKey={"1"}>
					
					<Accordion.Header bsPrefix="Accordianheader my-0 rounded-0">
						{defaultActiveKey==="1"? <>
						<h6 className="col-6 orderheader m-0">New Order Information</h6>
						<h6 className="col-6 orderheader m-0">
							Additional Order Information
						</h6>
						</>
						:
							<h6 className="col-6 orderheader m-0">
							456581122 <RightOutlined /> CertSimple
						</h6>}
					</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Form onSubmit={handleSubmit}>
								<Row>
									{inputs.map((input) => (
										<>
											{/* //appSelcet for product type  */}
											{input.type === "select" &&
												input.name === "product_type_id" && (
													<AppSelect
														key={input.id}
														{...input}
														onChange={onChange}
														options={input.options.map((ele) => {
															return (
																<option key={ele.id} value={ele.id}>
																	{ele.name}
																</option>
															);
														})}
													/>
												)}
											{/* //appSelcet for customers  */}
											{input.type === "select" && input.name === "customer_id" && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													options={input.options.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.name}
															</option>
														);
													})}
												/>
											)}
											{/* //appSelcet for branch  */}
											{input.type === "select" && input.name === "branch_id" && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													options={input.options.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.name}
															</option>
														);
													})}
												/>
											)}
											{/* //appSelcet for county  */}
											{input.type === "select" && input.name === "county_id" && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													options={input.options.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.county_name}
															</option>
														);
													})}
												/>
											)}
											{/* //appSelcet for county  */}
											{input.type === "select" && input.name === "closer_id" && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={onChange}
													options={input.options.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.closer_name}
															</option>
														);
													})}
												/>
											)}

											{input.type === "text" ? (
												<FormInput
													key={input.id}
													{...input}
													value={values[input.name]}
													onChange={onChange}
												/>
											) : (
												""
											)}
											{input.type === "checkbox" ? (
												<AppCheckbox
													key={input.id}
													{...input}
													//value={values[input.name]}
													value={values[input.name]}
													onChange={onCheckboxChange}
												/>
											) : (
												""
											)}
										</>
									))}
								</Row>

								<Row>
									<Col className="my-3 accordian">
										<div className="d-flex justify-content-center">
											<div className="me-md-2 ">
												<AppButton
													onClick={handleReset}
													title={"CLEAR"}
													variant={"secondary-md"}
												/>
											</div>

											<AppButton title={"SUBMIT"} variant={"primary-md"} />
										</div>
									</Col>
								</Row>
							</Form>{" "}
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			{defaultActiveKey === '0' ? <div>
				<OrderTab />
			</div>:''}
			
		</>
	);
}
