import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const producttype = createApi({
  reducerPath: "producttype",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  tagTypes: ["producttype"],
  endpoints: (builder) => ({
    getProductType: builder.query({
      query: (token) => ({
        url: `/product-type-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["producttype"],
    }),
  }),
});

export const { useGetProductTypeQuery } = producttype;

