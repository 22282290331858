import React, { useEffect, useState } from "react";
import BranchTable from "../../../../Components/AppTable/AppTable";
import { EditOutlined } from "@ant-design/icons";
import {
	FaPlus,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import AppModal from "../../../../Components/AppModal/AppModal";
import { BranchModal } from "./BranchModal";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import SearchColumns from "../../../../Components/SearchColumns";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
	useBranchListQuery,
	useUpdateBranchStatusMutation,
} from "../../../../Redux/Services/Branch";
import { Input, notification, Switch } from "antd";

export default function Branch({ refresh, updateTab }) {
	const [editId, setEditId] = useState(null);
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [preDefualtStateValues] = useState({
		name: "",
		address_one: "",
		address_two: "",
		city: "",
		state_id: "",
		customer_id: "",
		branch_email: "",
		zip_five: "",
		is_active: true,
		vendor_id: "",
		website: "",
		is_pricing_same_as_customer: true,
		product_list_values: [],
		contact_list_values: [],
		branch_product_price_values: [],
	});

	function afterClose(status) {
		setInitialStateVal(!initialStateVal);
	}

	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	const { token, user } = useSelector((state) => state.auth);
	const { branchModule, tableColumn } = useSelector(
		(state) => state.flagRoles
	);
	//calling rtk query hook for geting customerType list
	const branchData = useBranchListQuery(token);
	//create function for branch fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//calling rtk query hook for geting VendorsType list
	const updateBranchStatusApi = useUpdateBranchStatusMutation();
	const [updateBranchStatus] = updateBranchStatusApi;
	useEffect(() => {
		if (branchData.isSuccess) {
			const newData = branchData.data?.data.map(
				({ zip_five, zip_four, ...ele }) => {
					const zip = zip_five
						? `${zip_five}${zip_four ? `-${zip_four}` : ""}`
						: false;

					return {
						...ele,
						state_name: ele.state_name || "NA",
						city: ele.city || "NA",
						zip_five: zip || "NA",
					};
				}
			);
			setData(newData);
		}
	}, [branchData]);

	const columns = [
		{
			title: (
				<span>
					Vendor Name{" "}
					{getsortfield === "vendor_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "vendor_name",
			key: "vendor_name",
			align: "center",
			sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
			className: `${tableColumn[0].vendor ?" " :"d-none" }`,
			width:matchColumn(" ") && 200,
			...SearchColumns("vendor_name", "Vendor Name"),
		},

		{
			title: (
				<span>
					Customer Name{" "}
					{getsortfield === "customer_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "customer_name",
			key: "customer_name",
			align: "center",
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			className: `${tableColumn[1].customer ? tableColumn[0].vendor ?" ":"border-style": "d-none"}`,
			width: matchColumn(" ") && 200,
			...SearchColumns("customer_name", "Customer Name"),
		},
		{
			title: (
				<span>
					Branch Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			align: "center",
			sorter: (a, b) => a.name.localeCompare(b.name),
			className: `${tableColumn[2].branch ?" " :"d-none" }`,
			width: matchColumn(" ") && 200,
			...SearchColumns("name", "Branch Name"),
		},
		{
			title: (
				<span>
					State Name{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			width: matchColumn(" ") && 200,
			...SearchColumns("state_name", "State Name"),
		},

		{
			title: (
				<span>
					City{" "}
					{getsortfield === "city" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "city" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "city" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "city",
			key: "city",
			align: "center",
			sorter: (a, b) => a.city.localeCompare(b.city),
			width: matchColumn(" ") && 200,
			...SearchColumns("city", "City"),
		},
		{
			title: (
				<span>
					Zip{" "}
					{/* {getsortfield === "zip_five" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "zip_five" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "zip_five" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )} */}
				</span>
			),
			dataIndex: "zip_five",
			className: `${branchModule[2].active_status ? "": "border-endstyle"}`,
			key: "zip_five",
			width: matchColumn(" ") && 100,
			align: "center",
			...SearchColumns("zip_five", "Zip"),
		},
		{
			title: <span>Active Status</span>,
			dataIndex: "is_active",
			key: "is_active",
			align: "center",
			className: `${branchModule[2].active_status ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
		{
			title: "Action",
			dataIndex: "edit",
			key: "edit",
			className: `${branchModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
			align: "center",
			render: (value, row) => {
				return (
					<>
						<EditOutlined size={50} onClick={() => editHandler(row)} />
					</>
				);
			},
		},
	];

	function closeModal(data) {
		setShowHide(data);
	}

	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.id);
	};

	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value === true ? 1 : 0,
			updated_by: user?.user_id,
		};
		updateBranchStatus({
			data,
			id: row.id,
			token: token,
		});
	}

	//update Customers details
	useEffect(() => {
		if (updateBranchStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateBranchStatusApi[1].data?.message,
			});
		}
		if (updateBranchStatusApi[1].error) {
			/// erros details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateBranchStatusApi[1].error.data.message ||
					updateBranchStatusApi[1].error.error ||
					updateBranchStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateBranchStatusApi[1].isLoading]);

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "branches") {
			branchData.refetch();
		}
	}, [refresh]);

	return (
		<>
			{branchData.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						{/* <AlertBox message={"checking afjshfidshgjdygudsjdsgusgjdsviuds hiufiudsgv jgusfjdsu"}  type="success"/> */}
						<AppModal
							confirmProp={true}
							showModal={showHide}
							onClose={closeModal}
							afterClose={afterClose}
							width={"90%"}
							height={500}
							element={
								<BranchModal
									//passing tab of customers
									updateTab={updateTab}
									//passing row customer editId
									editId={editId}
									initialValues={preDefualtStateValues}
									setShowHide={setShowHide}
									getStatus={afterClose}
									//passing deafult dependency to initial state
									afterClose={initialStateVal}
								/>
							}
						/>

						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
						{branchModule.map((item,index) => {
							if (item.add_button) {
								return (
									<div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
							}
							return null;
						})}
					</div>
					<div style={{ clear: "both" }}></div>
					<Row>
						<BranchTable
							sort={sort}
							loading={updateBranchStatusApi[1].isLoading}
							search={search}
							columns={columns}
							dataSource={data}
						/>
					</Row>
				</>
			)}
		</>
	);
}
