import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import "./ManualEntry.css";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import AppFileUpload from "../../../Components/AppFileUpload/AppFileUpload";
import { useSelector } from "react-redux/es/exports";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import { convertBase64 } from "../../../Controllers/Functions";

export default function ManualEntry() {
	const [values, setValues] = useState({
		state_id: "",
		county_id: "",
		parcel_no: "",
		address_1: "",
		city: "",
		zip_code: "",
		comments: "",
		file: "",
	});

	const { token } = useSelector((state) => state.auth);
	const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);

	const [countyData, setCountyData] = useState([{ id: "", county_name: "" }]);

	const { stateList } = useSelector((state) => state.stateList);
	const { countyList } = useSelector((state) => state.countyList);
	const [ setFileError] = useState(null);

	//calling rtk query hook for geting County list
	const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;
	useEffect(() => {
		setFileError(null);
		setStateData(stateList);
		setCountyData(countyList);

		setStateData(stateList);
	}, [stateList, countyList]);

	// useEffect for countylist based on state selection
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyData(countyList2[1].data?.data);
			setValues({ ...values, county_id: countyList2[1].data?.data[0]?.id });
		}
		// if (countyList2[1].error) {
		//   setGetError(
		//     countyList2[1].error?.data?.message ||
		//       countyList2[1].error.error ||
		//       countyList2[1].error.data?.msg.sqlMessage
		//   );
		// }
	}, [countyList2[1].isLoading]);

	const inputs = [
		{
			id: 1,
			name: "state_id",
			type: "select",
			options: stateData,
			value: values.state_id || "",
			column: "6",
			placeholder: "certSimple",
			errorMessage: "Please select state!",
			label: "State",
			subhead: "",
			required: true,
		},

		{
			id: 2,
			name: "county_id",
			type: "select",
			value: values.county_id || "",
			options: countyData,
			column: "6",
			placeholder: "Select County",
			errorMessage: "Please select county !",
			label: "County",
			subhead: "",
			required: true,
		},
		{
			id: 3,
			name: "parcel_no",
			type: "text",
			column: "6",
			placeholder: "Parcel Number",
			errorMessage: "Please enter parcel number!",
			label: "Parcel Number",
			subhead: "",
			required: "",
		},

		{
			id: 4,
			name: "address1",
			type: "text",
			column: "6",
			placeholder: "eg. 37 jaden Mountain",
			errorMessage: "Please enter address!",
			label: "Address 1",
			subhead: "",
			required: "",
		},

		{
			id: 5,
			name: "city",
			type: "text",
			column: "6",
			placeholder: "City",
			errorMessage: "Please enter city!",
			label: "City",
			subhead: "",
			required: "",
		},

		{
			id: 6,
			name: "zip_code",
			type: "text",
			column: "6",
			placeholder: "eg. 8457892-748",
			errorMessage: "Please select zip code!",
			label: "Zip Code",
			subhead: "",
			required: "",
		},
		{
			id: 7,
			name: "comments",
			type: "text",
			column: "6",
			placeholder: "Your Comment",
			errorMessage: "Please enter your comment!",
			label: "Comments",
			subhead: "",
			required: "",
		},
		{
			id: 10,
			name: "file",
			type: "file",
			preview: values.file,
			column: "6",
			errorMessage: "Please select file!",
			label: "File ",
		},
	];

	const handleSubmit = (e) => {
		e.preventDefault();
		// const zip_code = values.zip_code.split("-");
		// if (fileError) {
		// 	setGetError(fileError);
		// } else {
		// 	// addVendors({
		// 	//   token,
		// 	//   data: {
		// 	//     ...values,
		// 	//     phone_number: values.phone_number,
		// 	//     zip_five: Number(zip_five[0]),
		// 	//     zip_four: Number(zip_five[1]) || null,
		// 	//     created_by: user?.user_id,
		// 	//   },
		// 	// });
		// }
	};
	const handleReset = (e) => {
		e.preventDefault();
		setValues("");
	};

	const onChange = async (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value.replace(/[^a-zA-Z" "]/gi, ""),
		});
		//text and number
		if (name === "address" || name === "legal") {
			setValues({
				...values,
				[name]: value,
			});
		}
		//number only
		else if (name === "parcel_no") {
			setValues({
				...values,
				[name]: value.replace(/[^0-9]/gi, ""),
			});
		}
		//zip code
		else if (name === "zip_code") {
			const value1 = value.replace(/[^0-9.]/g, "");
			var foo = value1.split("-").join("");
			if (foo.length > 0) {
				foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
			}
			// value1.replace(/(\d{5})/, "$1-")
			setValues({ ...values, zip_code: foo });
		}

		if (e.target?.files) {
			const file = e.target.files[0];
			//base64 covert function
			const base64 = await convertBase64(file);

			//values of file validations
			if (file.size >= 2097152) {
				setFileError("File size must be less 2mb! ");
			} else if (
				file.type !== "image/png" ||
				file.type !== "image/jpg" ||
				file.type !== "image/jpeg"
			) {
				setFileError("File must be jpg | png | jpeg format ! ");
			}
			if (
				(file.type === "image/png" ||
					!file.type === "image/jpg" ||
					file.type === "image/jpeg") &&
				file.size <= 2097152
			) {
				setFileError(null);
				//asign to blob to logo
				setValues({ ...values, logo: base64 });
			}
		}
	};

	//select handler
	const selectHandler = (property, value) => {
		// clearStatus();
		setValues({ ...values, [property]: value });
		//calling rtk  query for geting county list for options
		const state_id = { state_id: Number(value) };
		if (property === "state_id") {
			getCountyList({ state_id, token: token });
		}
	};

	useEffect(() => {}, []);
	return (
		<>
			<div className="col-12">
				<Card>
					<Card.Body className="manual-entry">
						<form onSubmit={handleSubmit}>
							<Row>
								<div className="col-9">
									<Row>
										{inputs.map((input) => (
											<>
												{/* //appSelcet for state  */}
												{input.type === "select" && input.name === "state_id" && (
													<AppSelect
														key={input.id}
														{...input}
														onChange={selectHandler}
														options={input.options.map((ele) => {
															return (
																<option key={ele.id} value={ele.id}>
																	{ele.state_name}
																</option>
															);
														})}
													/>
												)}

												{/* //appSelcet for county  */}
												{input.type === "select" && input.name === "county_id" && (
													<AppSelect
														key={input.id}
														{...input}
														onChange={selectHandler}
														options={input.options.map((ele) => {
															return (
																<option key={ele.id} value={ele.id}>
																	{ele.county_name}
																</option>
															);
														})}
													/>
												)}

												{input.type === "text" ? (
													<AppInput
														key={input.id}
														{...input}
														value={values[input.name]}
														onChange={onChange}
													/>
												) : (
													""
												)}

												{input.type === "file" && (
													<>
														<AppInput
															key={input.id}
															{...input}
															// fileError={fileError}
															value={values[input.value]}
															onChange={onChange}
														/>
													</>
												)}
											</>
										))}
									</Row>
								</div>

								<div className="col-3">
									<AppFileUpload />
								</div>

								<Row>
									<Col className="my-3 mt-5">
										<div className="d-flex justify-content-center">
											<div className="me-md-2 ">
												<AppButton
													onClick={handleReset}
													title={"CLEAR"}
													variant={"secondary-md"}
													type={"button"}
												/>
											</div>

											<AppButton
												type="submit"
												title={"SUBMIT"}
												variant={"primary-md"}
											/>
										</div>
									</Col>
								</Row>
							</Row>
						</form>
					</Card.Body>
				</Card>
			</div>
		</>
	);
}
