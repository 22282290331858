import React, { useState } from "react";
import { Radio } from "antd";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { List } from "./List/List";
import { Batch } from "./Batch/Batch";
import { memo } from "react";

const CadTab = ({ refresh }) => {
  const [key, setKey] = useState("List");
  const [dependency, setDependency] = useState(false);
  const OnradiohandleChange = (e) => {
    setKey(e.target.value);
  };

  //redux store
  const { cadTabs } = useSelector((state) => state.flagRoles);

  useEffect(() => {
    setDependency(!dependency);
  }, [refresh]);

  useEffect(() => {
    setKey(cadTabs[0]);
  }, [dependency]);

  return (
    <>
      <Row className="seprate">
        <Col lg="12">
          <Radio.Group onChange={OnradiohandleChange} value={key}>
            {/* tabs showing base on users roles  */}
            {cadTabs &&
              cadTabs.map((ele) => {
                return (
                  <Radio key={ele} value={ele} defaultChecked>
                    {ele}
                  </Radio>
                );
              })}
          </Radio.Group>
          <div className="seprate"></div>
          {key === "List" && <List refresh={refresh} childTab={key} />}
          {key === "Batch" && <Batch refresh={refresh} childTab={key} />}
        </Col>
      </Row>
    </>
  );
};

export default memo(CadTab);
