import React from "react";
// import "antd/dist/antd.css";
import "./styles.css";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import { BiPalette } from "react-icons/bi";
export default function InputColor({ initialColor, onColorChange, title }) {
  function changeHandler(colors) {
    onColorChange(colors);
  }

  return (
    <>
      <div className="position-relative input-color-box">
        <div className="color-box">
          <BiPalette cursor={"pointer"} size={25} color="gray" />
        </div>
        <ColorPicker
          animation="slide-up"
          color={initialColor}
          onChange={changeHandler}
        />
      </div>
      <span className="mt-3"><label> {!title ? <>&nbsp;</> : <> {title}&nbsp;</>}</label></span>
    </>
  );
}
