import { useState } from "react";
import "./AppInput.css";
import { Col, Row } from "react-bootstrap";

import { BsExclamationCircle } from "react-icons/bs";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useEffect } from "react";

const AppInput = (props) => {
  const [focused, setFocused] = useState(false);
  const [showHide, setShowHide] = useState(true);
  const {
    subhead,
    column,
    label,
    errorMessage,
    onChange,
    defaultSet,
    id,
    preview,
    hide,
    color,
    margin,
    getErrorFocus,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  useEffect(() => {
    setFocused(false);
  }, [defaultSet]);

  // useEffect(() => {
  //   getErrorFocus(focused)
  // }, [focused]);

  return (
    <>
      {subhead && <h6 className="mt-3">{subhead}</h6>}
      {!hide && (
        <div
          className={`  col-12 ${margin} col-md-${column} ${
            inputProps.disabled && "disable-input"
          }`}
        >
          <div className={` position-relative `}>
            {label && (
              <Row className="mt-3">
                <label className="ms-1 heading-5 text-dark ">
                  {label}
                  {inputProps.required && label && !color && (
                    <span className="text-danger">*</span>
                  )}
                  {inputProps.isEdit && (
                    <span className="text-dark fw-bold"> :</span>
                  )}
                </label>
              </Row>
            )}
            {!inputProps.isEdit ? (
              <input
                maxLength={50}
                id={inputProps.name}
                className={`input-field mt-0 pb-2 w-100  heading-5 ${
                  inputProps.icon && "icon-input"
                }`}
                {...inputProps}
                type={
                  inputProps.type === "password" && showHide
                    ? "password"
                    : inputProps.type === "number"
                    ? "number"
                    : inputProps.type === "email"
                    ? "email"
                    : inputProps.type === "date"
                    ? "date"
                    : inputProps.type === "datetime"
                    ? "datetime-local"
                    : "text"
                }
                onChange={onChange}
                onBlur={handleFocus}
                disabled={inputProps.disabled}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                  if (inputProps.type === "date") {
                    e.preventDefault();
                  }
                }}
                onFocus={() =>
                  (inputProps.name === "confirmPassword" ||
                    inputProps.name === "newPassword") &&
                  setFocused(true)
                }
                focused={focused.toString()}
              />
            ) : (
              <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
                {inputProps.value || "NA"}
              </div>
            )}

            <div className={`input-icon`}>
              {inputProps?.icon && (
                <>
                  <span>{inputProps.icon}</span>
                </>
              )}
              {inputProps?.type === "password" && (
                <span
                  className="passIcon"
                  onClick={() => setShowHide(!showHide)}
                >
                  {showHide ? <FaEyeSlash /> : <FaEye />}
                </span>
              )}
            </div>

            <span className="input-error">
              <BsExclamationCircle
                className="alertIcon"
                style={{ marginBottom: "3px" }}
              />
              {errorMessage}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AppInput;
