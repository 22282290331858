import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const noticeFlags = createApi({
  reducerPath: "noticeFlags",
  tagTypes: ["noticeFlags"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addNoticeFlag: builder.mutation({
      query: (data) => ({
        url: `/add-notice`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["noticeFlags"],
    }),
    noticeFlagList: builder.query({
      query: (token) => ({
        url: `/notice-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["noticeFlags"],
    }),

    editNoticeFlag: builder.query({
      query: (data) => ({
        url: `/edit-notice/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["noticeFlags"],
    }),
    updateNoticeFlag: builder.mutation({
      query: (data) => ({
        url: `/update-notice/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["noticeFlags"],
    }),
    updateMultipleNoticeFlag: builder.mutation({
      query: (data) => ({
        url: `/update-order-notice-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useAddNoticeFlagMutation,
  useEditNoticeFlagQuery,
  useUpdateNoticeFlagMutation,
  useNoticeFlagListQuery,
  useUpdateMultipleNoticeFlagMutation
} = noticeFlags;
