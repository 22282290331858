import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  notification,
  Popconfirm,
  Steps,
  Collapse,
  Button,
  Tooltip,
  Spin,
} from "antd";
import React, { useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { AiOutlineAudit, AiOutlineEye } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { FiDownload, FiEdit } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import {
  MdAutorenew,
  MdDeleteOutline,
  MdDone,
  MdError,
  MdOutlineDeleteSweep,
} from "react-icons/md";
import { VscRunErrors } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../../App";
import {
  useAddParcelDoucmentMutation,
  useDeleteParcelMutation,
  useParcelAutomationStartMutation,
  useParcelInfoMutation,
  useParcelStatusInfoMutation,
  useParcelAuditLogsListMutation,
} from "../../../../Redux/Services/Parcels";
import { parcelContext } from "../OrderEditor";

import ParcelInfo from "./Parcel_info/ParcelInfo";
import { TaxCollectorDetails } from "./Tax_Collector_info/TaxCollectorDetails";

import {
  useAutomationStatusUpdateMutation,
  useTestOrderDetailsMutation,
} from "../../../../Redux/Services/TaxCollector";
import { useUpdateParcelJuriAutomationMutation } from "../../../../Redux/Services/Jurisdiction";

import SupportDocs from "./SupportingDocs/SupportDocs";
import { useLocation } from "react-router-dom";
import { dateFun, scrollComp } from "../../../../Controllers/Functions";
import "./parcel.css";
import Conflicts from "../Conflicts/Conflicts";
import {
  useAutoConflictsStatusUpdateMutation,
  useConflictsMutation,
  useReviewConflictsMutation,
  useValidateConflictsMutation,
} from "../../../../Redux/Services/Orders";
import { CgViewSplit } from "react-icons/cg";

import MyHoa from "./MyHOA/MyHoa";
import AppModal from "../../../../Components/AppModal/AppModal";
import AuditLogs from "../../AuditLogs/AuditLogs";
import { BsFilePdf } from "react-icons/bs";
const config = require("../../../../config.json");
const { Panel } = Collapse;
export default function ParcelsMain({
  info,
  setKey,
  setNewParcelTabFlag,
  refresh,
  setOpen,
  parcelList,
  setViewParcelData,
  SendEmailComp,
  certficateDownload,
  parcelStatusList = false,
  fecthingAuditHistoryLogs = false,
}) {
  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;

  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const progressData = {
    initiated: true,
    progress: false,
    failed: false,
    success: false,
  };
  const [processbar, setProcessbar] = useState(progressData);
  //states
  const [getParcelData, setGetParcelData] = useState({ year_list: [] });
  const [hoaListData, setHoaListData] = useState([]);

  const [parcelYears, setParcelYears] = useState([]);
  const [toolsData, setToolsData] = useState({ year: "" });
  const [showYearFlag, setShowYearFlag] = useState(true);
  const [showSearchErr, setShowSearchErr] = useState(false);
  const [conflictsProcess, setConflictsProcess] = useState(false);
  const [conflictsNotification, setConflictsNotification] = useState(false);
  const [automationReqCount, setAutomationReqCount] = useState(0);
  const [editable, setEditable] = useState(false);
  const [parcelConflicts, setParcelConflicts] = useState([]);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [auditLogData, setAuditLogData] = useState([]);

  const { product_type_id } = parcelController.orderData;
  const { token, user } = useSelector((state) => state.auth);
  const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

  const location = useLocation();
  //third party
  const [orderDetailsTest, orderDetailsTestResult] =
    useTestOrderDetailsMutation();

  //update automation status
  const [automationStatusUpdate, automationStatusUpdateResult] =
    useAutomationStatusUpdateMutation();

  //audit logs
  const [auditLogsListCall, auditLogsListResult] =
    useParcelAuditLogsListMutation();

  //api
  const [addAutomationDocuments, addAutomationDocumentsResult] =
    useAddParcelDoucmentMutation();

  //update automation juri data
  const [juriupdateAutomation, juriupdateAutomationResult] =
    useUpdateParcelJuriAutomationMutation();

  //parcel automation start
  const [automationStart, automationStartResult] =
    useParcelAutomationStartMutation();

  //default
  const [parcelInfo, parcelInfoResult] = useParcelInfoMutation();
  const [getParcelStatusInfo, getParcelStatusInfoResult] =
    useParcelStatusInfoMutation();

  //getting Delete parcel api
  const [parcelDelete, parcelDeleteResult] = useDeleteParcelMutation();

  //order conflicts m
  const [conflicts, conflictsResult] = useConflictsMutation();

  //update conflicts info
  const [validateConflicts, validateConflictsResult] =
    useValidateConflictsMutation();

  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoStatusUpdateResult] =
    useAutoConflictsStatusUpdateMutation();

  //review conflicts
  const [reviewConflicts, reviewConflictsResult] = useReviewConflictsMutation();

  //delete parcel
  const deleteOrderHandler = () => {
    //calling parcel delete api
    parcelDelete({
      data: {
        parcel_id: [getParcelData.id],
      },
      token,
    });
  };

  function getParcelStatusFun() {
    //gtting parcel status info
    getParcelStatusInfo({ token, parcel_id: info.id });
  }

  useEffect(() => {
    if (automationStatusUpdateResult.isSuccess) {
      setAutomationReqCount(0);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: automationStatusUpdateResult.isLoading,
    });
  }, [automationStatusUpdateResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (auditLogsListResult.isSuccess) {
      setAuditLogData(auditLogsListResult.data?.data);
      setShowAuditLogModal(true);
    } else {
      setAuditLogData([]);
      setShowAuditLogModal(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: auditLogsListResult.isLoading });
  }, [auditLogsListResult.isLoading]);

  //auto status update
  useEffect(() => {
    if (autoStatusUpdateResult.isSuccess) {
      //get parcel status
      getParcelStatusFun();
    }
  }, [autoStatusUpdateResult.isLoading]);

  //setParcel Status
  useEffect(() => {
    if (getParcelStatusInfoResult.isSuccess) {
      setGetParcelData({
        ...getParcelData,
        status_id: getParcelStatusInfoResult.data.status,
      });
    }
  }, [getParcelStatusInfoResult.isLoading]);

  // useEffect for validation parcel
  useEffect(() => {
    if (validateConflictsResult.isSuccess) {
      //calling conflicts api
      conflicts({ data: { parcel_id: getParcelData.id }, token });
      //auto update status
      autoStatusUpdate({ data: { order_id: location?.state?.id }, token });

      //save certificate store in DB
      parcelController?.storeCertificateInDbFun &&
        parcelController?.storeCertificateInDbFun();
    }

    setConflictsProcess(validateConflictsResult.isLoading);
  }, [validateConflictsResult.isLoading]);

  useEffect(() => {
    if (reviewConflictsResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: reviewConflictsResult.data?.message,
      });
      //calling conflicts api
      // conflicts({ data: { parcel_id: getParcelData.id }, token });
      validateConflicts({
        data: { order_id: location?.state?.id },
        token,
      });
      //conflicts review and load parcel info
      // parcelInfo({
      //   data: {
      //     parcel_id: info.id,
      //     year: info.parcel_year || parcelController.year,
      //   },
      //   token,
      // });

      //gtting parcel status info
      // getParcelStatusFun();
    }
  }, [reviewConflictsResult.isLoading]);

  //automation start api
  useEffect(() => {
    if (automationStartResult.isSuccess) {
      //check after order automation test
      setTimeout(() => {
        orderDetailsTest({
          data: { order_id: automationStartResult.data?.data },
          token,
        });
      }, 5000);
    }
    if (automationStartResult.isError) {
      setProcessbar({ ...processbar, progress: false });
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          automationStartResult.error?.data?.message || "Automation Faild",
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: automationStartResult.isLoading });
  }, [automationStartResult.isLoading]);

  useEffect(() => {
    if (orderDetailsTestResult.isLoading) {
      //processbar
      setProcessbar({
        ...progressData,
        progress: true,
      });
    }
    if (orderDetailsTestResult.isSuccess) {
      const { OrderStatus, result, automation_ref_no, supporting_files } =
        orderDetailsTestResult.data.data;

      if (OrderStatus === "3") {
        setProcessbar({
          ...progressData,
          success: true,
        });

        //when  finished update the status
        automationStatusUpdate({
          data: { automation_status_id: 3, automation_json: result },
          token,
          id: getParcelData.id,
        });
        //calling conflicts api
        validateConflicts({
          data: { order_id: location?.state?.id },
          token,
        });

        //when  finished update the juri data
        juriupdateAutomation({
          data: {
            taxes: result?.taxes,
            parcel_id: getParcelData.id,
          },
          token,
        });

        let docs = supporting_files.map(({ file, file_name }) => {
          return {
            document_classification_id: 2,
            document: `data:application/pdf;base64,${file}`,
            name: file_name + "_" + dateFun("datetime"),
            type: "application/pdf",
          };
        });

        // //adding supporting docs
        addAutomationDocuments({
          data: { document_list: docs, parcel_id: getParcelData.id },
          token,
        });
      } else if (OrderStatus === "2") {
        //making max request
        if (automationReqCount <= config.automationStatusCount) {
          setTimeout(() => {
            orderDetailsTest({
              data: { order_id: automation_ref_no },
              token,
            });
          }, 10000);

          setAutomationReqCount(automationReqCount + 1);
          setProcessbar({
            ...progressData,
            progress: true,
          });
        } else {
          setProcessbar({
            ...progressData,
            failed: true,
          });
          // //when request limit finished
          automationStatusUpdate({
            data: { automation_status_id: 4 },
            token,
            id: getParcelData.id,
          });
          //calling conflicts api
          validateConflicts({
            data: { order_id: location?.state?.id },
            token,
          });
        }
      } else if (OrderStatus === "4") {
        //when request limit finished
        automationStatusUpdate({
          data: { automation_status_id: 4 },
          token,
          id: getParcelData.id,
        });
        //calling conflicts api
        validateConflicts({
          data: { order_id: location?.state?.id },
          token,
        });
        //processbar
        setProcessbar({ ...progressData, failed: true });
      }
    }
  }, [orderDetailsTestResult.isLoading]);

  ///parcel delete
  useEffect(() => {
    if (parcelDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: parcelDeleteResult.data.message,
      });
      //navigate to summary
      setKey("summary");
    } else if (parcelDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: parcelDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelDeleteResult.isLoading });
  }, [parcelDeleteResult.isLoading]);

  //select
  useEffect(() => {
    if (parcelInfoResult.isSuccess) {
      const {
        automation_status_id,
        automation_ref_no,
        year_list,
        year,
        status_id,
        id,
      } = parcelInfoResult.data?.data;
      setGetParcelData({
        ...parcelInfoResult.data?.data,
        id,
        year,
        year_list: Array.from(year_list)?.sort((a, b) => {
          return b - a;
        }),
      });

      //gtting parcel status info
      getParcelStatusFun();

      setParcelController({
        ...parcelController,
        refetch: false,
        parcelId: id,
        parcelConflicts: false,
        parcelTwoView: false,
        parcelPdfDoc: false,
      });
      setToolsData({ ...toolsData, year });

      setParcelYears(parcelInfoResult.data?.data);

      //optimise state
      setParcelOpData(parcelInfoResult.data?.data);

      //calling conflicts api
      conflicts({ data: { parcel_id: id }, token });

      //making request for parcel automation
      if (automation_status_id === 2) {
        //details of order
        orderDetailsTest({
          data: { order_id: automation_ref_no },
          token,
        });
      } else if (automation_status_id === 3) {
        setProcessbar({
          ...progressData,
          success: true,
        });
      } else if (automation_status_id === 4) {
        setProcessbar({
          ...progressData,
          failed: true,
        });
      } else if (!automation_status_id) {
        setProcessbar({
          ...progressData,
          initiated: false,
        });
      }
    }
    if (parcelInfoResult.error) {
      setGetParcelData({ ...getParcelData, error: "server unreachable" });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelInfoResult.isLoading });
  }, [parcelInfoResult.isLoading]);

  ///conflicts status
  useEffect(() => {
    if (conflictsResult.isSuccess) {
      const newData = conflictsResult.data.data;
      setParcelConflicts(newData);
      let isError = newData.filter(({ is_fixed }) => {
        return !is_fixed;
      });

      if (isError.length) {
        setConflictsNotification(true);
      } else {
        setConflictsNotification(false);
      }
    } else if (conflictsResult.isError) {
      setParcelConflicts([]);
    }
  }, [conflictsResult.isLoading]);

  useEffect(() => {
    setShowYearFlag(true);
    setToolsData({ ...toolsData, year: "" });
    if (refresh !== "summary") {
      setParcelYears([]);
      //parcel info
      parcelInfo({
        data: {
          parcel_id: info.id,
          year: info.parcel_year || parcelController.year,
        },
        token,
      });
    }
  }, [info.id]);

  //refecth t=>{he parcel info
  useEffect(() => {
    if (parcelController?.refetch) {
      parcelInfo({
        data: { parcel_id: info.id, year: parcelController.year },
        token,
      });
      //gtting parcel status info
      getParcelStatusFun();
      //refetch parcel list after doing any changes in parcel
      if (parcelList.length > 5) {
        parcelStatusList({ id: location.state.id, token });
      }
    }
    if (parcelController.mode === "view") {
      setEditable(false);
    } else if (parcelController.mode === "edit") {
      setEditable(true);
    }
  }, [parcelController.refetch]);

  //disables error message
  useEffect(() => {
    if (showSearchErr) {
      setTimeout(() => {
        setShowSearchErr(false);
      }, 5000);
    }
  }, [showSearchErr]);

  useEffect(() => {
    if (parcelController.parcelPdfDoc?.base64) {
      scrollComp("parcelembed");
    }
  }, [parcelController.parcelPdfDoc]);

  //edithandlerComp
  const EditHandlerComp = () => {
    return (
      <>
        {" "}
        {parcelController.mode === "view" ? (
          <Tooltip
            overlayInnerStyle={{
              fontSize: 10,
            }}
            placement="bottom"
            title={"Edit"}
          >
            <div
              className="add_icon "
              onClick={() => {
                setParcelController({
                  ...parcelController,
                  mode: "edit",
                });
                setShowSearchErr(false);
              }}
            >
              <EditOutlined size={18} color="#0d6efd" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            overlayInnerStyle={{
              fontSize: 10,
            }}
            placement="bottom"
            title={"close"}
          >
            <div
              className="add_icon "
              onClick={() => {
                setParcelController({
                  ...parcelController,
                  mode: "view",
                });
                setShowYearFlag(true);
                setShowSearchErr(false);
                setToolsData({
                  ...toolsData,
                  year: parcelYears?.year,
                });
                setGetParcelData({
                  ...getParcelData,
                  year_list: parcelYears?.year_list,
                });
              }}
            >
              <CloseOutlined size={18} color="#0d6efd" />
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  //optimize
  const [parcelOpData, setParcelOpData] = useState({});
  const parcelMemoData = useMemo(() => parcelOpData, [parcelOpData]);
  const orderMemoData = useMemo(
    () => parcelController?.orderData,
    [parcelController?.orderData]
  );

  return (
    <>
      {/* //history  */}
      <AppModal
        width={"85%"}
        onClose={(value) => {
          setShowAuditLogModal(value);
        }}
        showModal={showAuditLogModal}
        element={
          <div className="p-3">
            <AuditLogs title="Parcel" data={auditLogData} />
          </div>
        }
      />

      {showSearchErr && <h6 className="text-danger">{showSearchErr}</h6>}
      {getParcelData?.error ? (
        <div className=" mb-2">
          <Alert message={getParcelData?.error} type="error" showIcon />
        </div>
      ) : (
        <>
          {" "}
          {parcelController?.toolbar && (
            <div className="row bg-white p-2 align-items-center">
              <div
                className={`col-12 col-md-2 d-flex gap-2 align-items-center`}
              >
                {showYearFlag ? (
                  <>
                    <select
                      onChange={(e) => {
                        //adding year default
                        setToolsData({ ...toolsData, year: e.target.value });
                        //search year data
                        parcelInfo({
                          data: {
                            parcel_id: getParcelData.id,
                            year: e.target.value,
                          },
                          token,
                        });
                        //gtting parcel status info
                        getParcelStatusFun();
                      }}
                      value={toolsData.year}
                      className="border rounded-3 border-secondary w-75 p-1 border-2"
                    >
                      {getParcelData?.year_list?.map((ele) => {
                        return (
                          <option value={ele} key={ele}>
                            {ele}
                          </option>
                        );
                      })}
                    </select>

                    {getParcelData?.year_list.length < 5 && (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Add Prior Year"}
                      >
                        <div
                          onClick={() => {
                            setShowYearFlag(false);
                            setShowSearchErr(false);
                            setParcelController({
                              ...parcelController,
                              mode: "addyear",
                            });

                            let currentYear = new Date().getFullYear();
                            const options = [];
                            for (
                              let i = currentYear;
                              i > currentYear - 5;
                              i--
                            ) {
                              if (
                                !getParcelData.year_list.includes(Number(i))
                              ) {
                                options.push(i);
                              }
                            }
                            setGetParcelData({
                              ...getParcelData,
                              year_list: options?.sort((a, b) => {
                                return b - a;
                              }),
                            });
                            setToolsData({ ...toolsData, year: options[0] });
                          }}
                          className="add_icon"
                        >
                          <FaPlus />
                        </div>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <select
                    onChange={(e) => {
                      //search year data
                      // parcelSearchYearInfo({
                      //   data: {
                      //     cad_property_id: info.cad_property_id,
                      //     year: e.target.value,
                      //   },
                      //   token,
                      // });
                      // console.log(e.target.value)
                      // setToolsData({ ...toolsData, year: options[0] });
                      setToolsData({ ...toolsData, year: e.target.value });
                    }}
                    className="border rounded-3 border-secondary w-75 p-1 border-2 w-100 "
                  >
                    {getParcelData?.year_list?.map((ele) => {
                      return (
                        <option value={ele} key={ele}>
                          {ele}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              {conflictsNotification && (
                <div className="col-12 col-lg-2 px-0">
                  <h6 className="conflicts-pop fs-10 mb-0 d-inline-block">
                    Please Review the conflicts
                  </h6>
                </div>
              )}
              {!conflictsNotification && !parcelInfoResult.isLoading && (
                <div className="col-12 col-lg-2 px-0">
                  <h6 className="fs-10 mb-0 d-inline-block fw-bold text-success">
                    No conflicts found
                  </h6>
                </div>
              )}

              <div className="col-12 col-md-3 ms-auto d-flex align-items-center justify-content-end ">
                <div className="action-buttons d-flex align-items-center justify-content-end  gap-2">
                  {/* <Button
                    type="primary"
                    loading={tokenTest.loading}
                    onClick={() => {
                      //login api call
                      loginTest();
                    }}
                    className="btn-sm"
                  >
                    {" "}
                    Extract Bills
                  </Button> */}
                  {parcelEditInOrder.map((item, index) => {
                    if (item.run_validation) {
                      return (
                        !conflictsProcess && (
                          <Tooltip
                            key={index}
                            overlayInnerStyle={{
                              fontSize: 10,
                            }}
                            placement="left"
                            title={"Run Validation"}
                          >
                            <div
                              onClick={() => {
                                //validatio start
                                //calling api for parcel api validate
                                validateConflicts({
                                  data: { order_id: location?.state?.id },
                                  token,
                                });
                              }}
                              className="add_icon"
                            >
                              <VscRunErrors color="#0d6efd" />
                            </div>
                          </Tooltip>
                        )
                      );
                    } else if (item.conflicts_action_required) {
                      return conflictsProcess ? (
                        <Spin
                          key={index}
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 24,
                              }}
                              spin
                            />
                          }
                        />
                      ) : (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="bottom"
                          title={"Conflicts Action Required"}
                        >
                          <div
                            onClick={async () => {
                              setParcelController({
                                ...parcelController,
                                parcelTwoView: parcelController.parcelPdfDoc
                                  ? true
                                  : !parcelController.parcelTwoView,
                                parcelConflicts:
                                  !parcelController.parcelConflicts,
                              });
                            }}
                            className="add_icon border-danger test-svg"
                          >
                            <CgViewSplit color="red" />
                          </div>
                        </Tooltip>
                      );
                    } else if (item.delete_parcel) {
                      return (
                        <Popconfirm
                          key={index}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          title="Are you sure you want to delete？"
                          onConfirm={deleteOrderHandler}
                          placement="bottom"
                          icon={
                            <QuestionCircleOutlined
                              style={{
                                color: "red",
                              }}
                            />
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip
                            overlayInnerStyle={{
                              fontSize: 10,
                            }}
                            placement="bottom"
                            title={"Delete Parcel"}
                          >
                            <div className="add_icon ">
                              <MdDeleteOutline color="#0d6efd" />
                            </div>
                          </Tooltip>
                        </Popconfirm>
                      );
                    } else if (item.download_pdf) {
                      return (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="bottom"
                          title={"Download Certificate"}
                        >
                          <div
                            onClick={() => {
                              //this function as props we are calling from orderEditor.js
                              certficateDownload();
                            }}
                            className="add_icon "
                          >
                            <FiDownload color="#0d6efd" />
                          </div>
                        </Tooltip>
                      );
                    } else if (item.email_pdf) {
                      return <SendEmailComp key={index} />;
                    } else if (item.view_parcel) {
                      return (
                        parcelList.length > 5 && (
                          <Tooltip
                            key={index}
                            overlayInnerStyle={{
                              fontSize: 10,
                            }}
                            placement="bottom"
                            title={"View Parcel"}
                          >
                            <div className="add_icon">
                              <AiOutlineEye
                                onClick={() => setOpen(true)}
                                color="#0d6efd"
                              />
                            </div>
                          </Tooltip>
                        )
                      );
                    } else if (item?.view_documents) {
                      let disable = parcelController?.supportigDocList;

                      return (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="bottom"
                          title={"View Document"}
                        >
                          <div
                            onClick={() => {
                              if (disable) {
                                // document_classification_id

                                let certificate =
                                  parcelController?.supportigDocList.filter(
                                    ({ document_classification_id }) =>
                                      document_classification_id === 3
                                  );
                                let notcertificate =
                                  parcelController?.supportigDocList.filter(
                                    ({ document_classification_id }) =>
                                      document_classification_id !== 3
                                  );
                                let document = {};
                                if (certificate?.length) {
                                  document = certificate[0];
                                } else {
                                  document = notcertificate[0];
                                }
                                const { base64, type, name } = document;

                                setParcelController({
                                  ...parcelController,
                                  parcelPdfDoc: { type, base64, name },
                                  parcelTwoView: parcelController.parcelPdfDoc
                                    ? true
                                    : !parcelController.parcelTwoView,
                                  supportigDocListShowFromPdfBtn: true,
                                });
                              }
                            }}
                            className={`${
                              !disable ? "border-secondary" : null
                            } add_icon`}
                          >
                            <BsFilePdf
                              color={!disable ? "#808080" : "#0d6efd"}
                            />
                          </div>
                        </Tooltip>
                      );
                    } else if (item.history) {
                      return (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="bottom"
                          title={"History"}
                        >
                          <div
                            className="add_icon"
                            onClick={() => {
                              auditLogsListCall({
                                id: getParcelData.id,
                                token,
                              });
                            }}
                          >
                            <AiOutlineAudit color="#3388FF" cursor="pointer" />
                          </div>
                        </Tooltip>
                      );
                    } else if (item.add_parcel) {
                      return (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="bottom"
                          title={"Add Parcel"}
                        >
                          <div
                            onClick={() => {
                              setKey("new_parcel");
                              setNewParcelTabFlag(true);
                            }}
                            className="add_icon"
                          >
                            <FaPlus />
                          </div>
                        </Tooltip>
                      );
                    }
                  })}

                  {/* <Tooltip
                    overlayInnerStyle={{
                      fontSize: 10,
                    }}
                    placement="bottom"
                    title={"View Certificate"}
                  >
                    <div
                      onClick={async () => {
                        // localStorage.setItem(
                        //   "pdf",
                        //   btoa(
                        //     await certificateData.output(
                        //       "datauristring"
                        //     )
                        //   )
                        // );
                        // window.open(
                        //   "/certificate-view",
                        //   "_blank",
                        //   "width=1300,height=700"
                        // );
                        setParcelController({
                          ...parcelController,
                          parcelTwoView: parcelController.parcelTwoView,
                          
                        });
                      }}
                      className="add_icon  "
                    >
                      <AiOutlineFilePdf color="#0d6efd" />
                    </div>
                  </Tooltip> */}
                </div>
              </div>
            </div>
          )}
          {/* //parcel info  */}
          <div
            className="row align-items-stretch manual-entry"
            id="parcelembed"
          >
            {parcelController?.parcelTwoView && (
              <div className="col-12 col-lg-6">
                <Conflicts
                  onchek={(value) => {
                    reviewConflicts({
                      data: { user_id: user?.user_id },
                      id: value,
                      token,
                    });
                  }}
                  onclose={() => {
                    setParcelController({
                      ...parcelController,
                      parcelPdfDoc: false,
                      parcelTwoView: parcelController.parcelConflicts
                        ? true
                        : false,
                    });
                  }}
                  isconflicts={parcelController.parcelConflicts}
                  conflicts={parcelConflicts}
                  orderContorller={{
                    pdfDoc: parcelController.parcelPdfDoc,
                  }}
                />
              </div>
            )}
            <div
              className={`col-12  col-lg-${
                parcelController?.parcelTwoView
                  ? "6 mt-2 parcel-two-view"
                  : null
              }`}
            >
              {/* //Supporting Documents info in half page  */}
              {parcelController?.parcelTwoView &&
                !parcelController.supportigDocListShowFromPdfBtn && (
                  <div className="row">
                    <SupportDocs
                      refecth={addAutomationDocumentsResult.isLoading}
                      parceldata={getParcelData}
                    />
                  </div>
                )}

              <ParcelInfo
                ///controlling contentex api states
                setKey={setKey}
                info={info}
                toolsData={toolsData}
                mode={parcelController.mode}
                refecthParcelStatus={getParcelStatusFun}
                data={getParcelData}
                refresh={refresh}
                EditHandlerComp={EditHandlerComp}
                setShowYearFlag={setShowYearFlag}
              />
              {/* //Tax Collector info  */}

              {parcelController.mode !== "addyear" &&
                product_type_id !== 4 &&
                product_type_id !== 9 &&
                product_type_id !== 5 &&
                product_type_id && (
                  <TaxCollectorDetails
                    info={info}
                    toolsData={toolsData}
                    data={getParcelData}
                    setKey={setKey}
                    refresh={refresh}
                    parcelAutomationStatus={processbar}
                    setProcessbar={setProcessbar}
                    //api call automation start
                    automationStart={automationStart}
                    //api call conflicts refecth
                    validateConflicts={validateConflicts}
                    //automation start result for fetching details of tax
                    orderDetailsTestResult={orderDetailsTestResult}
                  />
                )}
              {/* //Supporting Documents info in full page  */}
              {((!parcelController?.parcelTwoView &&
                parcelController.mode !== "addyear") ||
                parcelController.supportigDocListShowFromPdfBtn) && (
                <div className="mt-2">
                  {parcelEditInOrder.map((item, index) => {
                    if (item.supporting_docs) {
                      return (
                        <SupportDocs
                          key={index}
                          refecth={addAutomationDocumentsResult.isLoading}
                          parceldata={getParcelData}
                        />
                      );
                    }
                  })}
                </div>
              )}

              {/* //Hoa Enformation  */}
              {parcelController.mode !== "addyear" &&
                (product_type_id === 5 ||
                  product_type_id === 4 ||
                  product_type_id === 9 ||
                  product_type_id === 10 ||
                  product_type_id === 2) && (
                  // <div className="mt-2">
                  //   <Collapse
                  //     className="rounded-3 border-0 text-white"
                  //     expandIcon={() => <></>}
                  //     accordion
                  //     defaultActiveKey={"1"}
                  //   >
                  //     <Panel
                  //       className="main-bg-color rounded-3 "
                  //       header={
                  //         <div
                  //           className=" text-white d-flex justify-content-between "
                  //           style={{ fontSize: 18, marginBottom: 0 }}
                  //         >
                  //           HOA Information
                  //         </div>
                  //       }
                  //       style={{ fontSize: 18 }}
                  //       key="1"
                  //     >
                  //       {/* <HOAInformationMain
                  //         editId={info.id}
                  //         //parcel Data passing
                  //         parentMode={parcelController.mode}
                  //         info={info}
                  //         data={getParcelData}
                  //         setKey={setKey}
                  //         refresh={refresh}
                  //         //api call conflicts refecth
                  //         parcleconflicts={conflicts}
                  //       /> */}
                  //       <HoaInfoOp
                  //         parcelMemoData={parcelMemoData}
                  //         orderMemoData={orderMemoData}
                  //       />
                  //     </Panel>
                  //   </Collapse>
                  // </div>
                  <MyHoa
                    validateConflicts={validateConflicts}
                    autoStatusUpdateResult={autoStatusUpdateResult}
                    parcelMemoData={parcelMemoData}
                    orderMemoData={orderMemoData}
                  />
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
