import React, { useContext, useEffect, useState } from "react";
// import "antd/dist/antd.css";
import "./MultiJurisdiction.css";

import { Popconfirm, Alert, Tooltip, Switch } from "antd";
import { FaPlus, FaSearch } from "react-icons/fa";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import AppInput from "../AppInput/AppInput";
import AppSelect from "../AppSelect/AppSelect";
import AppTable from "../AppTable/AppTable";
import AppNumeric from "../AppNumeric/AppNumeric";
import { useSelector } from "react-redux";
import { MdOutlineDelete } from "react-icons/md";
import { removeBlank } from "../../Controllers/Functions";
import {
  useJuriInfoSearchMutation,
  useJuriInfoSelectMutation,
} from "../../Redux/Services/Jurisdiction";
import { GlobalContext } from "../../App";
const randomPass = require("secure-random-password");

export const MultiJurisdiction = ({
  afterClose,
  initailValArr = [],
  addFormVal,
  juriListOptions,
  updateJurisdiction,
  deleteJurisdiction,
  whileEdit = false,
  isedit = false,
  parcelData,
  taxData,
}) => {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const initialVal = {
    jurisdiction_id: "",
    jurisdiction_name: taxData?.tax_collector_name,
    tax_office_code: "",
    tax_amount: "",
    cad_office_code: "",
    penalty: "",
    total_due: "",
    notes: "tets notes",
    current_exemption_applied: "sample",
    estimated_tax_without_exemption: "test test",
    hom: "",
    ov65: "",
    due_date: "",
    total_amount_paid: "",
    disabled_person: "",
    exemption_other: "",
    bonds_issued: "",
    stand_by_fees: "",
    tax_rate: "",
    collection_percentage: "",
    early_pay_discounts_percentage: "",
    verified_date: "",
    balance: "",
    next_balance: "",
    prior_years: "",
    year: "2022",
  };

  const [values, setValues] = useState(initialVal);
  const [data, setData] = useState([]);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);

  const [getError, setGetError] = useState(false);
  const [showfieldPrior, setShowFieldPrior] = useState(false);
  const [showExemptionOnly, setShowExemptionOnly] = useState(false);

  const {
    jurisdiction_id,
    jurisdiction_name,
    cad_office_code,
    tax_rate,
    balance,
    penalty,
    stand_by_fees,
    total_amount_paid,
    tax_amount,
    total_due,
    next_balance,
    prior_years,
    // hom,
    // ov65,
    // disabled_person,
    // first_responder,
    // exemption_other,
    // bonds_approved,
    // bonds_issued,
    // solar,
    due_date,
  } = values;


  const { authColumn } = useSelector((state) => state.flagRoles);

  //redux data store
  const { token } = useSelector((state) => state.auth);

  //update info of tax juri details
  const [fetchJuriInfo, fetchJuriInfoResult] = useJuriInfoSelectMutation();

  //search info of tax juri details
  const [searchJuriInfo, searchJuriInfoResult] = useJuriInfoSearchMutation();

  // useEffect for juri info based on juri code
  useEffect(() => {
    if (fetchJuriInfoResult.isSuccess) {
      // setJuriListOptions(fetchJuriInfoResult.data?.data);
      setValues({ ...values, ...fetchJuriInfoResult.data?.data[0] });
      console.log({ ...values, ...fetchJuriInfoResult.data?.data[0] });
    }
    if (fetchJuriInfoResult.isError) {
      setGetError(
        fetchJuriInfoResult.error?.data?.error ||
          fetchJuriInfoResult.error?.data?.message ||
          fetchJuriInfoResult.error.error ||
          fetchJuriInfoResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: fetchJuriInfoResult.isLoading });
  }, [fetchJuriInfoResult.isLoading]);

  // useEffect for juri info based on juri search
  useEffect(() => {
    if (searchJuriInfoResult.isSuccess) {
      // setJuriListOptions(searchJuriInfoResult.data?.data);
      setValues({
        ...values,
        ...searchJuriInfoResult.data?.data,
        jurisdiction_id: searchJuriInfoResult.data?.data.jurisdiction_id,
      });
    }
    if (searchJuriInfoResult.isError) {
      setGetError(
        searchJuriInfoResult.error?.data?.error ||
          searchJuriInfoResult.error?.data?.message ||
          searchJuriInfoResult.error.error ||
          searchJuriInfoResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: searchJuriInfoResult.isLoading });
  }, [searchJuriInfoResult.isLoading]);

  function matchColumn(value) {
    if (authColumn.indexOf(value) === -1) {
      return false;
    } else {
      return true;
    }
  }
  //edit row
  const editHandler = (record) => {
    setEditId(record);
    if (record.jurisdiction_id === 9999998) {
      setAddRowToggler(false);
      setShowFieldPrior(true);
    } else {
      setAddRowToggler(true);
      setShowFieldPrior(false);
    }
    setValues(record);
  };

  const defaultColumns = [
    {
      title: <span> Jurisdiction</span>,
      dataIndex: "jurisdiction_name",
      key: "jurisdiction_name",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">{value}</div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span> Code</span>,
      dataIndex: "cad_office_code",
      key: "cad_office_code",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              <>
                {record.key === "total" ? (
                  <div className="heading-5 fs-10 fw-bold text-dark">{"-"}</div>
                ) : (
                  value || "NA"
                )}
              </>
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Rate</span>,
      dataIndex: "tax_rate",
      key: "tax_rate",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Base Amount</span>,
      dataIndex: "tax_amount",
      key: "tax_amount",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Penalities</span>,
      dataIndex: "penalty",
      key: "penalty",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Fees</span>,
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      align: "center",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      width: 100,
    },
    {
      title: <span>Collects</span>,
      dataIndex: "total_amount_paid",
      key: "total_amount_paid",
      align: "center",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      width: 100,
    },
    {
      title: <span>Base Due</span>,
      dataIndex: "total_due",
      key: "total_due",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Balance</span>,
      dataIndex: "balance",
      key: "balance",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Next Balance</span>,
      dataIndex: "next_balance",
      key: "next_balance",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Due Date</span>,
      dataIndex: "due_date",
      key: "due_date",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Prior Year</span>,
      dataIndex: "prior_years",
      key: "prior_years",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 9999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (value, record) => {
        return (
          <>
            {record.dataIndex !== "Total" && record.dataIndex !== "Grand" && (
              <>
                <span>
                  <EditOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => editHandler(record)}
                    size={50}
                  />
                </span>
                <Popconfirm
                  title="Are you sure you want to delete？"
                  onConfirm={() => {
                    deleteHandler(record);
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="topLeft"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <span className={"ms-2 "}>
                    <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
                  </span>
                </Popconfirm>
              </>
            )}
          </>
        );
      },
    },
  ];

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    // //sending delete id
    deleteJurisdiction(id);
  };

  const cancelHandler = () => {
    setValues(initialVal);
    addFormVal(data);
    setAddRowToggler(false);
    setEditId(null);
    setGetError(false);
    setShowFieldPrior(false);
  };

  //here saving to the row
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
    setShowFieldPrior(false);
    setValues(initialVal);
  };

  const juriListOptionsFilter = [];
  data?.map(({ jurisdiction_id }) => {
    juriListOptionsFilter.push(Number(jurisdiction_id));
  });

  const myArrayFiltered = juriListOptions?.filter((el) => {
    return !juriListOptionsFilter.includes(el.id);
  });

  const inputs = [
    {
      id: 7,
      name: showfieldPrior ? "jurisdiction_name" : "jurisdiction_id",
      type: showfieldPrior ? "text" : "select",
      value: (showfieldPrior ? jurisdiction_name : jurisdiction_id) || "",
      column: "3",
      options: myArrayFiltered,
      placeholder: "Enter Jurisdiction",
      errorMessage: "Please select jurisdiction!",
      required: true,
      label: showfieldPrior ? "Tax Collector" : "Jurisdiction",
      disabled: showfieldPrior,
    },

    {
      id: 8,
      name: "cad_office_code",
      type: "text",
      label: "Code",
      placeholder: "Enter Code",
      value: cad_office_code || "",
      column: `3 ${showfieldPrior ? "d-none" : ""}`,
      errorMessage: "Please enter tax_office_code!",
      required: true,
      icon: !showfieldPrior && (
        <h6
          onClick={() => {
            if (parcelData?.year) {
              const { county_id, cad_property_id, tax_collector_id, year } =
                parcelData;
              const { taxcollector_id, tax_year } = taxData;
              //calling search api
              searchJuriInfo({
                data: {
                  tax_id: taxcollector_id,
                  year,
                  county_id,
                  cad_office_code: cad_office_code,
                },
                token,
              });
            }
          }}
          className="mt-4 cursor-pointer"
        >
          <Tooltip
            overlayInnerStyle={{
              fontSize: 10,
            }}
            placement="bottom"
            title={"Search By Jurisdiction Code"}
          >
            <FaSearch color="#778899" />
          </Tooltip>
        </h6>
      ),
    },
    {
      id: 9,
      name: "tax_rate",
      label: "Rate",
      placeholder: "Enter Rate",
      value: tax_rate || "",
      column: `3 ${showfieldPrior ? "d-none" : ""}`,
      errorMessage: "Please enter rate!",
      required: true,
    },
    {
      id: 10,
      name: "tax_amount",
      label: "Base Amount",
      placeholder: "Enter Base Amount",
      value: tax_amount || "",
      column: "3",
      errorMessage: "Please enter base amount!",
      required: true,
    },
    {
      id: 100,
      name: "penalty",
      label: "Penalities",
      placeholder: "Enter Penalities",
      value: penalty || "",
      column: "3",
      errorMessage: "Please enter  penalities!",
      table: true,
    },
    {
      id: 11,
      name: "stand_by_fees",
      label: "Fees",
      placeholder: "Enter Fees",
      value: stand_by_fees || "",
      column: "3",
      errorMessage: "Please enter fees!",
    },
    {
      id: 12,
      name: "total_amount_paid",
      _id: "total_amount_paid",
      label: "Collects",
      placeholder: "Enter Collects",
      value: total_amount_paid || "",
      column: "3",
      errorMessage: "Please enter total_amount_paid!",
    },
    {
      id: 14,
      name: "total_due",
      label: "Base Due",
      placeholder: "Enter Base Due",
      value: total_due || "",
      column: "3",
      errorMessage: "Please enter base due!",
      required: true,
    },
    {
      id: 13,
      name: "balance",
      label: "Balance",
      placeholder: "Enter Balance Amount",
      value: balance || "",
      column: "3",
      errorMessage: "Please enter balance!",
      required: true,
    },

    {
      id: 14,
      name: "next_balance",
      label: "Next Balance",
      placeholder: "Enter Next Balance",
      value: next_balance || "",
      column: "3",
      errorMessage: "Please enter next balance!",
      required: true,
    },
    {
      id: 15,
      name: "prior_years",
      label: "Prior Year/s",
      placeholder: "Enter Prior Year/s",
      value: prior_years || "",
      column: "3",
      errorMessage: "Please enter prior years!",
      required: true,
    },

    // {
    //   id: 86546,
    //   name: "hom",
    //   label: "HOM",
    //   type: "text",
    //   placeholder: "Enter HOM",
    //   value: hom || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter hom!",
    //   required: false,
    // },
    // {
    //   id: 569,
    //   name: "ov65",
    //   label: "OV65",
    //   type: "text",
    //   placeholder: "Enter OV65",
    //   value: ov65 || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter ov65!",
    //   required: false,
    // },
    // {
    //   id: 10456,
    //   name: "disabled_person",
    //   label: "Disable Person",
    //   type: "text",
    //   placeholder: "Enter Disable Person",
    //   value: disabled_person || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter disable person!",
    //   required: false,
    // },
    // {
    //   id: 10540,
    //   name: "first_responder",
    //   label: "First Responder",
    //   type: "text",
    //   placeholder: "Enter First Responder",
    //   value: first_responder || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter  first responder!",
    //   required: false,
    //   table: true,
    // },
    // {
    //   id: 1561,
    //   name: "exemption_other",
    //   type: "text",
    //   label: "Other Exemption",
    //   placeholder: "Enter Other Exemption",
    //   value: exemption_other || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter other exemption!",
    //   required: false,
    // },
    // {
    //   id: 15454561,
    //   name: "verified_date",
    //   type: "date",
    //   label: "Verified Date",
    //   value: verified_date || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter verified date!",
    //   required: true,
    // },
    {
      id: 3265661,
      name: "due_date",
      type: "date",
      label: "Due Date",
      value: due_date || "",
      column: `3 ${!showfieldPrior ? "d-none" : ""}`,
      errorMessage: "Please enter due date!",
    },
    // {
    //   id: 12546,
    //   name: "solar",
    //   label: "Solar",
    //   type: "text",
    //   placeholder: "Enter solar",
    //   value: solar || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter solar!",
    //   required: false,
    // },
    // {
    //   id: 14456,
    //   name: "bonds_approved",
    //   label: "Bonds Approved",
    //   placeholder: "Enter Bonds Approved",
    //   value: bonds_approved || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter bonds approved!",
    //   required: false,
    // },
    // {
    //   id: 13456,
    //   name: "bonds_issued",
    //   label: "Bonds Issues",
    //   placeholder: "Enter Bonds Issues",
    //   value: bonds_issued || "",
    //   column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter bonds issues!",
    //   required: false,
    // },

    // {
    //   id: 14546,
    //   name: "stand_by_fees",
    //   label: "Stand By Fees",
    //   placeholder: "Stand By Fees",
    //   value: stand_by_fees || "",
    //      column: `3 ${showfieldPrior ? "d-none" : ""}`,
    //   errorMessage: "Please enter stand by fees!",
    //   required: false,
    // },
  ];

  const handleSubmit = () => {
    removeBlank(values);
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele?.temp_id || ele?.id) !== (editId?.temp_id || editId?.id);
      });
      setData([...newData, values]);
      setAddRowToggler(false);
      updateJurisdiction && updateJurisdiction(values);
      setEditId(null);
      setValues(initialVal);
    } else {
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      //prior yaer

      setData([
        ...data,
        {
          ...values,
          jurisdiction_id: showfieldPrior ? 9999998 : values.jurisdiction_id,
          temp_id: randomId,
        },
      ]);
      // while edit and ading new record directly
      setValues(initialVal);

      whileEdit &&
        whileEdit({
          ...values,
          jurisdiction_id: showfieldPrior ? 9999998 : values.jurisdiction_id,
          temp_id: randomId,
        });

      setAddRowToggler(false);
      setEditId(null);
    }
    setShowFieldPrior(false);
  };
  const onChange = (e) => {
    setGetError(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //select handler
  const selectHandler = (property, value, attribute) => {
    setGetError(false);
    setValues({
      ...values,
      [property]: value,
      jurisdiction_name: attribute.getAttribute("data_name"),
    });

    //juri
    if (property === "jurisdiction_id") {
      if (value) {
        fetchJuriInfo({
          data: { jurisdiction_id: value, year: parcelData.year },
          token,
        });
      }
    }
  };

  useEffect(() => {
    setAddRowToggler(false);
    setShowFieldPrior(false);
    setData(initailValArr);
    setValues(initialVal);
    setGetError(false);
    setEditId(null);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
  }, [initailValArr]);

  useEffect(() => {
    addFormVal(data);
  }, [data]);

  const PriorYear = data?.filter(({ jurisdiction_id }) => {
    return jurisdiction_id === 9999998;
  });

  let checkLength = data?.filter((ele) => {
    return ele.jurisdiction_id !== 9999998;
  });

  //grand-total
  const grandTotal =
    PriorYear.length > 0
      ? data
          ?.filter((ele) => {
            return ele.jurisdiction_id !== 9999998;
          })
          .concat(
            (() => {
              let newrate = 0;
              let newfees = 0;
              let newpenalty = 0;
              let newtotal_amount_paid = 0;
              let newtax_amount = 0;
              let newtotal_due = 0;
              let newbalance = 0;
              let newnext_balance = 0;

              for (let row of data) {
                if (row["tax_rate"]) {
                  newrate += Number(
                    row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var tax_rate =
                    "$" +
                    newrate.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["balance"]) {
                  newbalance += Number(
                    row["balance"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var balance =
                    "$" +
                    newbalance.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["stand_by_fees"]) {
                  newfees += Number(
                    row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var stand_by_fees =
                    "$" +
                    newfees.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["tax_amount"]) {
                  newtax_amount += Number(
                    row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var tax_amount =
                    "$" +
                    newtax_amount.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["total_due"]) {
                  newtotal_due += Number(
                    row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var total_due =
                    "$" +
                    newtotal_due.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["penalty"]) {
                  newpenalty += Number(
                    row["penalty"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var penalty =
                    "$" +
                    newpenalty.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["total_amount_paid"]) {
                  newtotal_amount_paid += Number(
                    row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var total_amount_paid =
                    "$" +
                    newtotal_amount_paid.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }

                if (row["next_balance"]) {
                  newnext_balance += Number(
                    row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
                  );
                  var next_balance =
                    "$" +
                    newnext_balance.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    });
                }
              }
              return {
                dataIndex: "Grand",
                key: "Grand",
                align: "center",
                jurisdiction_name: (
                  <span className="heading-5  text-success  fw-bold">
                    Grand Total
                  </span>
                ),
                balance: (
                  <span className="heading-5 text-success  fw-bold">
                    {balance || "-"}
                  </span>
                ),
                tax_amount: (
                  <span className="heading-5 text-success  fw-bold">
                    {tax_amount || "-"}
                  </span>
                ),
                total_due: (
                  <span className="heading-5 text-success  fw-bold">
                    {total_due || "-"}
                  </span>
                ),
                penalty: (
                  <span className="heading-5 text-success  fw-bold">
                    {penalty || "-"}
                  </span>
                ),
                total_amount_paid: (
                  <span className="heading-5 text-success  fw-bold">
                    {total_amount_paid || "-"}
                  </span>
                ),
                next_balance: (
                  <span className="heading-5 text-success  fw-bold">
                    {next_balance || "-"}
                  </span>
                ),
                tax_rate: (
                  <span className="heading-5 text-success  fw-bold">
                    {tax_rate || "-"}
                  </span>
                ),
                stand_by_fees: (
                  <span className="heading-5 text-success  fw-bold">
                    {stand_by_fees || "-"}
                  </span>
                ),
              };
            })()
          )
      : [];

  //total
  const ToalAmount =
    checkLength.length > 0
      ? data
          ?.filter((ele) => {
            return ele.jurisdiction_id !== 9999998;
          })
          .concat(
            (() => {
              let newrate = 0;
              let newfees = 0;
              let newpenalty = 0;
              let newtotal_amount_paid = 0;
              let newtax_amount = 0;
              let newtotal_due = 0;
              let newbalance = 0;
              let newnext_balance = 0;
              for (let row of data) {
                if (row["jurisdiction_id"] !== 9999998) {
                  if (row["tax_rate"]) {
                    newrate += Number(
                      row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var tax_rate =
                      "$" +
                      newrate.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["stand_by_fees"]) {
                    newfees += Number(
                      row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var stand_by_fees =
                      "$" +
                      newfees.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["tax_amount"]) {
                    newtax_amount += Number(
                      row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var tax_amount =
                      "$" +
                      newtax_amount.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["total_due"]) {
                    newtotal_due += Number(
                      row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var total_due =
                      "$" +
                      newtotal_due.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["penalty"]) {
                    newpenalty += Number(
                      row["penalty"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var penalty =
                      "$" +
                      newpenalty.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["total_amount_paid"]) {
                    newtotal_amount_paid += Number(
                      row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var total_amount_paid =
                      "$" +
                      newtotal_amount_paid.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["balance"]) {
                    newbalance += Number(
                      row["balance"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var balance =
                      "$" +
                      newbalance.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }

                  if (row["next_balance"]) {
                    newnext_balance += Number(
                      row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
                    );
                    var next_balance =
                      "$" +
                      newnext_balance.toLocaleString(undefined, {
                        maximumFractionDigits: 6,
                      });
                  }
                }
              }
              return {
                dataIndex: "Total",
                key: "total",
                align: "center",
                jurisdiction_name: (
                  <span className="heading-5   fw-bold text-dark">Total</span>
                ),
                balance: (
                  <span className="heading-5  fw-bold text-dark">
                    {balance || "-"}
                  </span>
                ),
                tax_amount: (
                  <span className="heading-5  fw-bold text-dark">
                    {tax_amount || "-"}
                  </span>
                ),
                total_due: (
                  <span className="heading-5  fw-bold text-dark">
                    {total_due || "-"}
                  </span>
                ),
                penalty: (
                  <span className="heading-5  fw-bold text-dark">
                    {penalty || "-"}
                  </span>
                ),
                total_amount_paid: (
                  <span className="heading-5  fw-bold text-dark">
                    {total_amount_paid || "-"}
                  </span>
                ),
                next_balance: (
                  <span className="heading-5  fw-bold text-dark">
                    {next_balance || "-"}
                  </span>
                ),
                tax_rate: (
                  <span className="heading-5  fw-bold text-dark">
                    {tax_rate || "-"}
                  </span>
                ),
                stand_by_fees: (
                  <span className="heading-5  fw-bold text-dark">
                    {stand_by_fees || "-"}
                  </span>
                ),
              };
            })()
          )
      : [];

  //exemption columns
  const exemptionsColumns = [
    {
      title: <span> HOM</span>,
      dataIndex: "hom",
      key: "hom",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>OV65</span>,
      dataIndex: "ov65",
      key: "ov65",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>Disable Person</span>,
      dataIndex: "disabled_person",
      key: "disabled_person",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>First Responder</span>,
      dataIndex: "first_responder",
      key: "first_responder",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Verified Date</span>,
      dataIndex: "verified_date",
      key: "verified_date",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 100,
    },
    {
      title: <span>Other Exemption</span>,
      dataIndex: "exemption_other",
      key: "exemption_other",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 100,
    },
    {
      title: <span>Solar</span>,
      dataIndex: "solar",
      key: "solar",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 60,
    },
    {
      title: <span>Bonds Approved</span>,
      dataIndex: "bonds_approved",
      key: "bonds_approved",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Bonds Issues</span>,
      dataIndex: "bonds_issued",
      key: "bonds_issued",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },

    {
      title: <span>Stand By Fees</span>,
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
  ];

  // const defaultExpandable = {
  //   expandedRowRender: (ele) => {
  //     const {} = ele;
  //     console.log(ele);
  //     return (
  //       <div className="exemption">
  //         <span className="text-primary fw-bold">Exemptions</span>
  //         <AppTable
  //           pagination={false}
  //           columns={exemptionsColumns}
  //           dataSource={[ele]}
  //         />
  //       </div>
  //     );
  //   },
  //   rowExpandable: (record) =>
  //     record.dataIndex !== "Total" &&
  //     record.dataIndex !== "Grand" &&
  //     record.jurisdiction_id !== 9999998,
  // };

  return (
    <div>
      {
        //classname add tax-card-table
        <div className="tax-card-table gray-table  rounded-2 ">
          <div
            className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
          >
            <h6 className="fs-18 fw-bold me-2 "></h6>

            <div className="action-buttons d-flex align-items-center gap-2">
              {!showfieldPrior && !PriorYear[0] && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="left"
                  title={!showExemptionOnly ? "Show Exemptions" : "Show Bills"}
                >
                  <span className="me-2 d-inline-block fs-10 fw-bold text-dark">
                    Bills / Exemptions
                  </span>
                  <Switch
                    onChange={() => {
                      setShowExemptionOnly(!showExemptionOnly);
                    }}
                    size="small"
                  ></Switch>
                </Tooltip>
              )}
              {!showfieldPrior &&
                !PriorYear[0] &&
                !isedit &&
                !editId &&
                !showExemptionOnly && (
                  <Tooltip
                    overlayInnerStyle={{
                      fontSize: 10,
                    }}
                    placement="bottom"
                    title={"Add Prior Year Bill"}
                  >
                    <div
                      className={`tax-card-icon`}
                      onClick={() => {
                        setShowFieldPrior(true);
                        setAddRowToggler(false);
                        setValues(initialVal);
                      }}
                    >
                      <AiOutlineMenuUnfold />
                    </div>
                  </Tooltip>
                )}
              {!addRowToggler && !isedit && !editId && !showExemptionOnly && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Add Jurisdiction"}
                >
                  <div className={`tax-card-icon`} onClick={addNewRecord}>
                    <FaPlus style={{ backgroundColor: "white" }} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="gray-table-col">
            {!showExemptionOnly ? (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Bills</p>
                <AppTable
                  pagination={false}
                  columns={defaultColumns}
                  dataSource={ToalAmount.concat(PriorYear || [])
                    .concat(
                      grandTotal?.filter((ele) => {
                        return ele.key === "Grand";
                      })
                    )
                    .map((ele, i) => {
                      return { ...ele, key: ele.jurisdiction_id };
                    })}
                  // expandable={defaultExpandable}
                  // defaultExpandable
                />
              </div>
            ) : (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Exemptions</p>
                <AppTable
                  pagination={false}
                  columns={[
                    {
                      title: <span> Jurisdiction</span>,
                      dataIndex: "jurisdiction_name",
                      key: "jurisdiction_name",
                      render: (value, record) => {
                        return (
                          <>
                            {record.jurisdiction_id === 9999998 ? (
                              <div className="heading-5 text-danger fs-11 fw-bold">
                                {value}
                              </div>
                            ) : (
                              value || "NA"
                            )}
                          </>
                        );
                      },
                      align: "center",
                      width: 100,
                    },
                  ].concat(exemptionsColumns)}
                  dataSource={data}
                />
              </div>
            )}
          </div>

          {getError && (
            <div className="mt-3">
              <Alert
                message={getError}
                className="py-1 mx-3"
                type="error"
                showIcon
              />{" "}
            </div>
          )}
          {(addRowToggler || showfieldPrior) && (
            <div className="row pt-0 mt-0 p-2 mx-auto shadow-sm" id="addRow">
              {inputs.map((input) => (
                <>
                  {input.type === "select" &&
                    input.name === "jurisdiction_id" && (
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={initialVal}
                        classlabelStyle="ms-1 dark-label"
                        classStyle="dim-blue-bg-color form-control1 w-100 heading-5"
                        onChange={selectHandler}
                        options={
                          <>
                            <option
                              value={editId?.id ? values.jurisdiction_id : ""}
                            >
                              {editId?.id
                                ? values.jurisdiction_name
                                : "Select Jurisdiction"}
                            </option>
                            {input.options.map((ele) => {
                              return (
                                <option
                                  key={ele.id}
                                  data_name={ele.display_name}
                                  value={ele.id}
                                >
                                  {ele.display_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  {(input.name === "tax_rate" ||
                    input.name === "tax_amount" ||
                    input.name === "bonds_approved" ||
                    input.name === "bonds_issued" ||
                    input.name === "penalty" ||
                    input.name === "stand_by_fees" ||
                    input.name === "total_amount_paid" ||
                    input.name === "total_due" ||
                    input.name === "balance" ||
                    input.name === "next_balance") && (
                    <AppNumeric
                      // isEdit={editId && !editable}
                      {...input}
                      classlabelStyle="ms-1 dark-label"
                      maxLength={15}
                      defaultSet={initialVal}
                      value={values[input.name]}
                      thousandSeparator={true}
                      placeholder={input.placeholder}
                      prefix={"$"}
                      decimalScale={6}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          [input.name]: value,
                        });
                      }}
                    />
                  )}

                  {(input.type === "text" || input.type === "date") && (
                    <AppInput
                      classlabelStyle="ms-1 dark-label"
                      defaultSet={initialVal}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  )}
                  {input.name === "prior_years" && showfieldPrior && (
                    <AppInput
                      classlabelStyle="ms-1 dark-label"
                      defaultSet={initialVal}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  )}
                </>
              ))}
              <div className="col-12 col-lg-3  mt-5  align-self-center">
                <div className="d-flex ">
                  <span
                    onClick={handleSubmit}
                    className={`btn btn-primary ${
                      (showfieldPrior
                        ? !jurisdiction_name
                        : !jurisdiction_id) ||
                      (!showfieldPrior && !cad_office_code) ||
                      !total_due ||
                      !balance ||
                      !tax_amount ||
                      (!showfieldPrior && !tax_rate) ||
                      !next_balance ||
                      (showfieldPrior && !prior_years)
                        ? // !prior_years
                          "disabled"
                        : "btn-success"
                    } btn-sm edit-buttons`}
                  >
                    {editId?.id ? "Update" : "Save"}
                  </span>
                  <span
                    onClick={cancelHandler}
                    className="btn btn-primary ms-1 btn-sm edit-buttons"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};