import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const taxCollectorType = createApi({
  reducerPath: "taxcollectortype",
  tagTypes: ["taxcollectortype"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addTaxCollectorType: builder.mutation({
      query: (data) => ({
        url: `/add-tax-collector-type`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollectortype"],
    }),
    taxCollectorTypeList: builder.query({
      query: (token) => ({
        url: `/tax-collector-type-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["taxcollectortype"],
    }),

    editTaxCollectorType: builder.query({
      query: (data) => ({
        url: `/edit-tax-collector-type/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["taxcollectortype"],
    }),
    updateTaxCollectorType: builder.mutation({
      query: (data) => ({
        url: `/update-tax-collector-type/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollectortype"],
    }),
  }),
});

export const {
    useAddTaxCollectorTypeMutation,
    useEditTaxCollectorTypeQuery,
    useTaxCollectorTypeListQuery,
    useUpdateTaxCollectorTypeMutation
} = taxCollectorType;
