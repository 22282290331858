import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const labels = createApi({
  reducerPath: "labels",
  tagTypes: ["labels"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url}),
  endpoints: (builder) => ({
    addlabels: builder.mutation({
      query: (data) => ({
        url: `/add-label`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["labels"],
    }),
    labelList: builder.query({
      query: (token) => ({
        url: `/label-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["labels"],
    }),

    editLabel: builder.query({
      query: (data) => ({
        url: `/edit-label/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["labels"],
    }),
    updateLabel: builder.mutation({
      query: (data) => ({
        url: `/update-label/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["labels"],
    }),
    fetchLabelList: builder.query({
      query: (token) => ({
        url: `/fetch-label-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["labels"],
    }),

  }),
});

export const {
  useAddlabelsMutation,
  useEditLabelQuery,
  useLabelListQuery,
  useUpdateLabelMutation,
  useFetchLabelListQuery,
} = labels;
