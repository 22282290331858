import React from "react";
import HoaPage from "./HoaPage";
import "./test.css";
import "./certificate.css";
import { mobileFormat, priceFormater } from "../../../../Controllers/Functions";
const parse = require("html-react-parser");

export default function CertificateContent({ values = {} }) {
  const { input_Order, parcel_Information } = values;

  return (
    <div className="certificate">
      <div className="k-pdf-export ">
        <div className="mb-3">
          <h2 className="w-100 text-center main-cert-heading-line">
            TAX CERTIFICATE
          </h2>
        </div>
        {/* //issue date  */}
        <div className="issue-date bg-gray d-flex justify-content-between align-items-center my-2 p-1">
          <h4>Issue Date : {input_Order?.issue_Date || "--"}</h4>
          <h4>Certificate Fee : {input_Order?.cert_Fee || "--"}</h4>
        </div>

        {/* //gf Number  */}

        {/* //gf Number  */}
        <div className="d-flex justify-content-between">
          <div
            className=" buyer-seller  justify-content-between"
            style={{ width: "45%" }}
          >
            <div className="gf-number d-flex ">
              <h5 style={{ width: 60 }}> GF # </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>&nbsp;{input_Order?.file_Number}</h5>
            </div>{" "}
            <div className="buyer d-flex ">
              <h5 style={{ width: 60 }}> Buyer(s) </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>
                &nbsp;{input_Order?.client_Name || "--"}
              </h5>
            </div>
            <div className="seller d-flex ">
              <h5 style={{ width: 60 }}> Owner(s) / Seller(s) </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>
                &nbsp;{input_Order?.seller_Name || "--"}
              </h5>
            </div>
          </div>
          <div className="to w-50" style={{ width: "40%" }}>
            <div className="d-flex gap-1">
              <h5 className="m-0">Remit Certificate Fee To</h5>
              <h5> :</h5>
            </div>{" "}
            <div>
              <h5 className="m-0">
                CERTSIMPLE, INC <br />
                P.O.BOX 340787 <br />
                Austin, TX 78734 <br />
                (800) 806-3639 <br />
                taxcerts@certsimpleusa.com
              </h5>
            </div>
          </div>
        </div>

        {/* //parcel data and assesed data */}
        {parcel_Information?.length > 0 &&
          parcel_Information?.map((parcel, index) => {
            return (
              <div key={parcel?.parcel_No}>
                <div
                  className={`${
                    index === 0 ? null : "page-break"
                  } parcel-assessed d-flex mt-3 bg-outline`}
                >
                  {/* //parcel data  */}
                  <div className="parcel">
                    <div className=" bg-gray  p-1">
                      <h4>
                        Parcel {index + 1} of{" "}
                        {input_Order?.no_of_Parcels || "--"}
                      </h4>
                    </div>

                    <div className="p-1">
                      <div className="info d-flex">
                        <h5>Geographic ID </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.geographic_Id || "--"}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Property ID </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.parcel_No || "--"}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Situs Address </h5>
                        <h5>:&nbsp;</h5>
                        <h5>
                          {parcel?.property_Address || "--"}
                          {/* {parcel?.property_City +
                          ", " +
                          parcel?.property_State +
                          ", " +
                          parcel?.property_Zip} */}
                        </h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Assessed Owner(s) </h5>
                        <h5>:&nbsp;</h5>
                        {/* <h5>JERNIGAN-BILLUPS. JUSTIN NABE(100%)</h5> */}
                        <h5>
                          {parcel?.owner?.map((owner) => {
                            return (
                              <span key={owner.owner_Name + owner?.percentage}>
                                {owner.owner_Name} ({owner?.percentage}){" "}
                                {parcel?.owner?.length > 1 ? "," : null}
                              </span>
                            );
                          })}
                        </h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Mailing Address(es) </h5>
                        <h5>:&nbsp;</h5>
                        <h5>
                          {parcel?.owner?.map((owner) => {
                            return (
                              <span key={owner.owner_Name + owner?.percentage}>
                                {owner.address}
                                {parcel?.owner?.length > 1 ? "," : null}
                              </span>
                            );
                          })}
                        </h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Deed Reference </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.deed_Ref}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Subdivision </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.subdivision}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Acreage </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.acreage}</h5>
                      </div>
                    </div>
                  </div>
                  {/* //assessed data  */}
                  <div className="assessed">
                    <div className=" bg-gray d-flex gap-2 p-1">
                      <h4>Assessment</h4>
                      <h4>Data</h4>
                    </div>
                    <div className="p-1">
                      {/* //header  */}
                      <div className="info header d-flex bg-light-gray">
                        <h4>&nbsp;</h4>
                        <h4>&nbsp;</h4>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h4 key={header.assessment_Year}>
                              {header.assessment_Year || "--"} VALUES
                            </h4>
                          );
                        })}
                      </div>
                      {/* //row data  */}
                      <div className="info d-flex">
                        <h5>Land</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.land_Value
                                ? priceFormater(header.land_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Improvement</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.improvement_Value
                                ? priceFormater(header.improvement_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Agricultural</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.agricultural_Value
                                ? priceFormater(header.agricultural_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Appraised</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.total_Amt
                                ? priceFormater(header.total_Amt)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>10% Cap</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.capped_Value === "$null"
                                ? "$0"
                                : header.capped_Value
                                ? priceFormater(header.capped_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Assessed</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.net_iable
                                ? priceFormater(header.net_Taxable)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Est Taxes w/o Exemptions</h5>
                        <h5>:&nbsp;</h5>
                        <h5>&nbsp;</h5>
                        <h5>&nbsp;</h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Exemptions</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5 key={header.assessment_Year}>
                              {header.exemption_Amount || "No Exemptions"}
                            </h5>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* //legal description */}
                <div className="legal-description bg-outline mt-2">
                  <div className="legal-description-header bg-gray p-1">
                    <h4>Legal Description</h4>
                  </div>
                  <div className="legal-description-para d-flex">
                    <p>{parcel?.legal_Description || "--"}</p>
                  </div>
                </div>

                {/* //tax bill summary*/}

                {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                  <div>
                    <div className="tax-bill-summary bg-outline mt-3">
                      <div className="tax-bill-summary-header bg-gray p-1">
                        <h4>Tax Bill Summary</h4>
                      </div>

                      <table
                        style={{ marginBottom: 0 }}
                        className="table  taxing-bill-summary"
                      >
                        <thead className="bg-light-gray info ">
                          <tr>
                            <th>
                              <h4>Collector</h4>
                            </th>
                            <th style={{ width: 120 }}>
                              <h4>Tax Year</h4>
                            </th>
                            <th style={{ width: 80 }}>
                              <h4>Base Tax</h4>
                            </th>
                            <th style={{ width: 80 }}>
                              <h4>Base Due</h4>{" "}
                            </th>
                            <th style={{ width: 96 }}>
                              <h4>
                                Due By <br />{" "}
                                {parcel?.tax_Information?.due_by_date_one ||
                                  "Date-1"}
                              </h4>
                            </th>
                            <th style={{ width: 120 }}>
                              <h4>
                                Due By <br />{" "}
                                {parcel?.tax_Information?.due_by_date_two ||
                                  "Date-2"}
                              </h4>
                            </th>
                            <th style={{ width: 80 }}>
                              <h4>Status</h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {parcel?.tax_Information?.collectorInfo?.map(
                            (collector, i) => {
                              return (
                                <tr>
                                  <td>
                                    {" "}
                                    <h4>C/O: {collector?.name || "--"}</h4>
                                    {collector?.payable_to && (
                                      <h5> {collector?.payable_to}</h5>
                                    )}
                                    {collector?.account_number && (
                                      <h5>
                                        Acct #: {collector?.account_number}
                                      </h5>
                                    )}
                                    <h5>{collector?.mail_To || "--"}</h5>
                                    {collector?.phone_No && (
                                      <h5>
                                        Ph: {mobileFormat(collector?.phone_No)}
                                      </h5>
                                    )}
                                  </td>

                                  <td>
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.taxyear || "--"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {" "}
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.basetax
                                            ? priceFormater(ele?.basetax)
                                            : "$0"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.basedue
                                            ? priceFormater(ele?.basedue)
                                            : "$0"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {" "}
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.duedate1
                                            ? priceFormater(ele?.duedate1)
                                            : "$0"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {" "}
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.duedate2
                                            ? priceFormater(ele?.duedate2)
                                            : "$0"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {" "}
                                    {collector.bill_summary?.map((ele, i) => {
                                      return (
                                        <h5
                                          key={i}
                                          className={` ${
                                            ele?.status === "Due" ||
                                            Number(
                                              ele?.basedue?.replace(
                                                /[|&;$%@"<>()+,]/g,
                                                ""
                                              )
                                            ) > 0
                                              ? "text-danger"
                                              : null
                                          }`}
                                        >
                                          {ele?.status || "--"}
                                        </h5>
                                      );
                                    })}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                        <tfoot>
                          <tr className="bg-gray">
                            <th style={{ border: "none " }}></th>
                            <th>
                              <h4>Total Taxes :</h4>
                            </th>
                            <th>
                              <h4>
                                {parcel?.tax_Information?.totalAmt?.basetax
                                  ? priceFormater(
                                      parcel?.tax_Information?.totalAmt?.basetax
                                    )
                                  : "$0"}
                              </h4>
                            </th>
                            <th>
                              <h4>
                                {parcel?.tax_Information?.totalAmt?.basedue
                                  ? priceFormater(
                                      parcel?.tax_Information?.totalAmt?.basedue
                                    )
                                  : "$0"}
                              </h4>
                            </th>
                            <th>
                              {" "}
                              <h4>
                                {parcel?.tax_Information?.totalAmt?.duedate1
                                  ? priceFormater(
                                      parcel?.tax_Information?.totalAmt
                                        ?.duedate1
                                    )
                                  : "$0"}
                              </h4>
                            </th>
                            <th>
                              {" "}
                              <h4>
                                {parcel?.tax_Information?.totalAmt?.duedate2
                                  ? priceFormater(
                                      parcel?.tax_Information?.totalAmt
                                        ?.duedate2
                                    )
                                  : "$0"}
                              </h4>
                            </th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                )}

                {/* //Notice  */}
                {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                  <div className="notice mt-3 fst-italic text-red">
                    <h6 className="text-red">
                      This Section is for Information Purposes Only - Tax Bill
                      Payment Status is Not reflected Below
                    </h6>
                  </div>
                )}

                {/* //taxing juri  */}
                {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                  <div className="taxing-juri bg-outline mt-1">
                    <div className="taxing-juri-header bg-gray p-1">
                      <h4>
                        Taxing Jurisdictions - Total Tax Rate :-
                        <span>
                          {" "}
                          {" " + parcel?.jurisdiction_info?.total_taxrate
                            ? priceFormater(
                                parcel?.jurisdiction_info?.total_taxrate
                              )
                            : "$0"}
                        </span>
                      </h4>
                    </div>
                    <table
                      style={{ marginBottom: 0 }}
                      className="table  taxing-jurisdiction"
                    >
                      <thead className="bg-light-gray info ">
                        <tr>
                          <th>
                            <h4>Taxing Jurisdiction</h4>
                          </th>
                          <th>
                            <h4>Rate Year</h4>
                          </th>
                          <th>
                            <h4>Tax Rate</h4>
                          </th>
                          <th>
                            <h4>Est Tax</h4>
                          </th>
                          <th>
                            <h4>Bill Year</h4>{" "}
                          </th>
                          <th>
                            <h4>Base Amount</h4>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcel?.jurisdiction_info?.juri_data?.map(
                          (juricollector, index) => {
                            return (
                              <>
                                {juricollector?.jurisdiction_data?.map(
                                  (data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <h5>{data?.name || "--"}</h5>
                                        </td>
                                        <td>
                                          <h5>{data?.tax_year || ""}</h5>
                                        </td>
                                        <td>
                                          <h5>
                                            {data?.tax_rate
                                              ? priceFormater(data?.tax_rate)
                                              : "$0"}
                                          </h5>
                                        </td>
                                        <td>
                                          <h5>
                                            {data?.estimated_tax_without_exemption
                                              ? priceFormater(
                                                  data?.estimated_tax_without_exemption
                                                )
                                              : "$0"}
                                          </h5>
                                        </td>
                                        <td>
                                          <h5>{data?.billyear || "--"}</h5>
                                        </td>
                                        <td>
                                          <h5>
                                            {data?.baseamount
                                              ? priceFormater(data?.baseamount)
                                              : "$0"}
                                          </h5>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                <tr className="bg-gray">
                                  <td>
                                    <h4>
                                      {juricollector?.collector_name || "--"}
                                    </h4>
                                  </td>
                                  <td>
                                    <h4></h4>
                                  </td>
                                  <td>
                                    <h4>
                                      {juricollector?.tax_rate
                                        ? priceFormater(juricollector?.tax_rate)
                                        : "$0"}
                                    </h4>
                                  </td>
                                  <td>
                                    <h4>$0</h4>
                                  </td>
                                  <td>
                                    <h4></h4>
                                  </td>
                                  <td>
                                    <h4>$0</h4>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* //Bonds */}
                {parcel?.bonds?.length > 0 && (
                  <div className="bonds bg-outline mt-2">
                    <div className="bonds-header bg-gray p-1">
                      <h4>Bond Information</h4>
                    </div>

                    <table style={{ marginBottom: 0 }} className="table">
                      <thead className="bg-light-gray info bonds">
                        <tr>
                          <th>
                            <h4>Name</h4>
                          </th>
                          <th>
                            <h4>Bonds Approved</h4>
                          </th>
                          <th>
                            <h4>Bonds Issued</h4>
                          </th>
                          <th>
                            <h4>Standby Fees</h4>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcel?.bonds?.map((bond, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <h5>{bond?.name || "--"}</h5>
                              </td>
                              <td>
                                <h5>
                                  {bond?.approved
                                    ? priceFormater(bond?.approved)
                                    : "$0"}
                                </h5>
                              </td>
                              <td>
                                <h5>
                                  {bond?.issued
                                    ? priceFormater(bond?.issued)
                                    : "$0"}
                                </h5>
                              </td>
                              <td>
                                <h5>
                                  {bond?.standfees
                                    ? priceFormater(bond?.standfees)
                                    : "$0"}
                                </h5>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* <div className="bonds-header-content bg-light-gray info d-flex px-1 ">
                  <h4>Name</h4>
                  <h4>Bonds Approved</h4>
                  <h4>Bonds Issued</h4>
                  <h4>Standby Fees</h4>
                </div> */}

                    {/* {parcel?.bonds?.map((bond, i) => {
                  return (
                    <div
                      key={i}
                      className="bonds-header-content  info d-flex px-1 "
                    >
                      <h5>{bond?.name || "-"}</h5>
                      <h5>{bond?.approved || "-"}</h5>
                      <h5>{bond?.issued || "-"}</h5>
                      <h5>{bond?.standfees || "-"}</h5>
                    </div>
                  );
                })} */}
                  </div>
                )}

                {/* //disable Veteran */}
                {parcel?.exemption?.length > 0 && (
                  <div className="Veteran bg-outline mt-2">
                    <div className="d-flex Veteran-header">
                      <div className="Veteran-header p-1 bg-gray">
                        <h4>Exemptions by Jurisdictions</h4>
                      </div>
                      {/* <div className=" bg-gray p-1">
                      <h4>Disabled Veteran</h4>
                    </div> */}
                    </div>
                    <table
                      style={{ marginBottom: 0 }}
                      className="table  exemptions"
                    >
                      <thead className="bg-light-gray info ">
                        <tr>
                          <th>
                            <h4>Collector</h4>
                          </th>
                          <th>
                            <h4>HOM</h4>
                          </th>
                          <th>
                            <h4>OV65</h4>
                          </th>
                          <th>
                            <h4>DP</h4>
                          </th>
                          <th>
                            <h4>10-30%</h4>
                          </th>
                          <th>
                            <h4>31-50%</h4>
                          </th>
                          <th>
                            <h4>51-70%</h4>
                          </th>
                          <th>
                            <h4>71-100%</h4>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcel?.exemption?.map((excemp, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <h5>{excemp?.name || "--"}</h5>
                              </td>
                              <td>
                                <h5>{excemp?.hom || "--"}</h5>
                              </td>
                              <td>
                                <h5>{excemp?.ov65 || "--"}</h5>
                              </td>
                              <td>
                                <h5>{excemp?.dp || "--"}</h5>
                              </td>
                              <td>
                                <h5>{excemp["10to30"] || "5K"}</h5>
                              </td>
                              <td>
                                <h5>{excemp["31to50"] || "7.5K"}</h5>
                              </td>
                              <td>
                                <h5>{excemp["51to70"] || "10K"}</h5>
                              </td>
                              <td>
                                <h5>{excemp["71to100"] || "12K"}</h5>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* //certificate comments */}

                    {/* <div className="Veteran-header-content bg-light-gray info d-flex px-1 ">
                    <h4>Collector</h4>
                    <h4>HOM</h4>
                    <h4>OV65</h4>
                    <h4>DP</h4>
                    <h4>10-30%</h4>
                    <h4>31-50%</h4>
                    <h4>51-70%</h4>
                    <h4>71-100%</h4>
                  </div> */}

                    {/* {parcel?.exemption?.map((excemp, i) => {
                    return (
                      <div
                        key={i}
                        className="Veteran-header-content  info d-flex px-1 "
                      >
                        <h5>{excemp?.name || "-"}</h5>
                        <h5>{excemp?.hom || "-"}</h5>
                        <h5>{excemp?.ov65 || "-"}</h5>
                        <h5>{excemp?.dp || "-"}</h5>
                        <h5>{excemp["10to30"] || "5k"}</h5>
                        <h5>{excemp["31to50"] || "7.5K"}</h5>
                        <h5>{excemp["51to70"] || "10K"}</h5>
                        <h5>{excemp["71to100"] || "12K"}</h5>
                      </div>
                    );
                  })} */}
                  </div>
                )}
                {parcel?.parcel_comments && (
                  <div className="certificate-comments bg-outline pb-1 my-1">
                    <div className="comments-header bg-gray p-1 d-flex gap-1">
                      <h3 className="text-red">IMPORTANT PROPERTY COMMENTS</h3>
                      {/* <h3 className="text-red"> </h3>
                    <h3 className="text-red"></h3> */}
                    </div>
                    <div className="comments-para">
                      <p>{parse(parcel?.parcel_comments) || "NA"}</p>
                    </div>
                  </div>
                )}
                {input_Order?.comments && (
                  <div className="certificate-comments bg-outline pb-1 my-1">
                    <div className="comments-header bg-gray p-1 d-flex gap-1">
                      <h3 className="text-red">
                        IMPORTANT CERTIFICATE COMMENTS
                      </h3>
                      {/* <h3 className="text-red">CERTIFICATE &nbsp;</h3>
                    <h3 className="text-red">COMMENTS</h3> */}
                    </div>
                    <div className="comments-para">
                      <p>{parse(input_Order?.comments) || "NA"}</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

        {parcel_Information?.length > 0 &&
          Object.keys(parcel_Information[0].tax_Information).length > 0 && (
            <div className="page-break">
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div className="disclaimer mb-3">
                <h3>Tax Certificate Disclaimer :</h3>
                <div className="content mt-2">
                  <p className="ps-0">
                    All applicable ad valorem taxes on the above referenced
                    property have been checked and are found to have the status
                    provided except: Status does not cover any changes made to
                    the tax records of the agencies listed after the “Order
                    Completed” date hereof. Does not include and is not a
                    certification of any mineral taxes, personal property taxes(
                    including mobile homes), or any other non-ad valorem taxes.
                    <br />
                    In the event a parcel or tract of real property covered in a
                    request had been previously re-subdivided, split out, our
                    deleted( killed), whereas previous tax account was assigned,
                    and/or is to be or was split, or was an undivided interest
                    account, or creating a new tax account, and specific parcel
                    or tract information is not readily available on the county
                    appraisal district records; the Property Tax Service shall
                    only be required to, and claims limited to, furnish
                    Appraisal and Tax Information for the parcel or tract as
                    shown on the Current Records of the Appraisal District.
                    <br />
                    Proposed Values are strictly preliminary and may
                    change.Proposed Values are obtained directly from the
                    appraisal districts and are provided for information
                    purposes only.The Property Tax Service is not liable for any
                    pro-ration shortage or overage based on these Proposed
                    Values.
                  </p>
                </div>
              </div>
            </div>
          )}
        {/* //hoa certificate  */}
        {values?.hoa_info?.map((data, i) => {
          return (
            <div key={i}>
              <HoaPage data={data} />
              {/* //hoa fees  */}
              {data?.hoa_order_fees_list?.length > 0 && (
                <div className="bg-outline mt-2 p-1">
                  <h4>Bill Period</h4>
                  <table
                    style={{ marginBottom: 0 }}
                    className="table  hoa-fees"
                  >
                    <thead className="bg-light-gray info ">
                      <tr>
                        <th>
                          <h4>Bill Period </h4>
                        </th>
                        <th>
                          <h4>Fee Description </h4>
                        </th>
                        <th>
                          <h4>Amount </h4>
                        </th>
                        <th>
                          <h4>Due</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.hoa_order_fees_list?.map((hoaFees, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <h5
                                className={`${
                                  hoaFees?.total_amount_value ? "fw-bold" : null
                                }`}
                              >
                                {hoaFees?.hoa_fees_type || "--"}
                              </h5>
                            </td>
                            <td>
                              <h5
                                className={`${
                                  hoaFees?.total_amount_value ? "fw-bold" : null
                                }`}
                              >
                                {hoaFees?.fees_description || "--"}
                              </h5>
                            </td>
                            <td>
                              <h5
                                className={`${
                                  hoaFees?.total_amount_value ? "fw-bold" : null
                                }`}
                              >
                                {priceFormater(
                                  hoaFees?.total_amount_value || hoaFees?.amount
                                ) || "$0"}
                              </h5>
                            </td>
                            <td>
                              <h5
                                className={`${
                                  hoaFees?.total_amount_value ? "fw-bold" : null
                                }`}
                              >
                                {priceFormater(hoaFees?.amount_due) || "$0"}
                              </h5>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          );
        })}
        {values?.hoa_info?.length > 0 && (
          <div className={"page-break"}>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <div className="disclaimer">
              <h3>HOA Certificate Disclaimer :</h3>
              <div className="content mt-2 hoa-disclaimer">
                <p className="ps-0 pe-2">
                  HOA Assessments being reported are good for 30 days OR through
                  the end of HOA Billing Cycle. Please Request an Update Prior
                  to Closing.Please Review All Comments and Statements furnished
                  on this Certificate.Research is conducted through various
                  resources such as Deed Restrictions, online information, etc..
                  however, Information Not Readily Available toCertsimple, such
                  as Sales Contract HOA addendums, may need to be forwarded to
                  CertSimple for further review.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
