import { useEffect, useState } from "react";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { Empty } from "antd";

import { Card } from "react-bootstrap";
import { Alert, Col, Row, Tabs } from "antd";
import AppTable from "../../Components/AppTable/AppTable";
import SearchColumns from "../../Components/SearchColumns";
import StackedBarChart from "../../Components/StackedBarChart/StackedBarChart";
import ColumnBarChart from "../../Components/ColumnBarChart/ColumnBarchart";
import NormalPie from "../../Components/NormalPieChart/NormalPieChart";
import AppSelect from "../../Components/AppSelect/AppSelect";
import AppSpinner from "../../Components/AppSpinner/AppSpinner";
import { scrollComp } from "../../Controllers/Functions";
import { useCustomerListQuery } from "../../Redux/Services/Customers";
import StatusCards from "./Cards";
import {
	useCustomerOrdersStatusMutation,
	useExaminerOrdersStatusMutation,
	useLiveOrdersListQuery,
	useMonthlyOrdersListMutation,
} from "../../Redux/Services/dashBoardApiList";
import { useSelector } from "react-redux";
import { FiRefreshCcw } from "react-icons/fi";
const { TabPane } = Tabs;
const DashboardNewDesign = () => {
	const [key, setKey] = useState("orders_by_customer");
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	const [customersData, setCustomersData] = useState();
	const [liveOrdersDataList, setLiveOrderList] = useState([]);
	const [monthlyOrdersData, setMonthlyOrdersData] = useState([]);
	const [ordersByStatusData, setOrdersByStatus] = useState([]);
	const [ordersByProductData, setOrdersByProduct] = useState([]);
	const [hourlyOrderData, setHourlyOrderData] = useState([]);
	const [examinerOrderStatus, setExaminerOrderStatus] = useState([]);
	const [customerOrderStatus, setCustomerOrderStatus] = useState([]);
	const [getError] = useState(false);
	//   values assign
	const [values, setValues] = useState({
		customer_id: 0,
		year: `${new Date().getFullYear()}`,
		month: `${new Date().getMonth() + 1}`,
		customer_year: `${new Date().getFullYear()}`,
		customer_month: `${new Date().getMonth() + 1}`,
		examiner_year: `${new Date().getFullYear()}`,
		examiner_month: `${new Date().getMonth() + 1}`,
	});
	//redux data store
	const { token } = useSelector((state) => state.auth);
	const year = new Date().getFullYear();

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	// //calling rtk query hook for customer-list options

	const customerListOptions = useCustomerListQuery(token);

	useEffect(() => {
		if (customerListOptions.isSuccess) {
			setCustomersData(customerListOptions.data?.data);
		}
	}, [customerListOptions.isFetching]);

	const liveOrdersList = useLiveOrdersListQuery(token);
	useEffect(() => {
		if (liveOrdersList.isSuccess) {
			setLiveOrderList(liveOrdersList.data?.data);
			setOrdersByStatus(liveOrdersList.data.data[3]);
			setHourlyOrderData(liveOrdersList.data.data[2]);
			setOrdersByProduct(liveOrdersList.data.data[4]);
		}
	}, [liveOrdersList.isFetching]);

	const months = [
		{
			id: 1,
			month: "Jan",
		},
		{
			id: 2,
			month: "Feb",
		},
		{
			id: 3,
			month: "March",
		},
		{
			id: 4,
			month: "April",
		},
		{
			id: 5,
			month: "May",
		},
		{
			id: 6,
			month: "June",
		},
		{
			id: 7,
			month: "July",
		},
		{
			id: 8,
			month: "Aug",
		},
		{
			id: 9,
			month: "Sept",
		},
		{
			id: 10,
			month: "Oct",
		},
		{
			id: 11,
			month: "Nov",
		},
		{
			id: 12,
			month: "Dec",
		},
	];

	const [monthOptions] = useState(months);
	//pie chart

	const piedata = [
		{
			type: "Open Orders",
			value: liveOrdersDataList[0]?.open_orders,
		},
		{
			type: "In Progress",
			value: liveOrdersDataList[0]?.in_progress_orders,
		},
		{
			type: "On Hold",
			value: liveOrdersDataList[0]?.on_hold_orders,
		},
		{
			type: "Completed",
			value: liveOrdersDataList[0]?.completed_orders,
		},
		{
			type: "Rush Orders",
			value: liveOrdersDataList[0]?.rush_orders,
		},
		{
			type: "Overdue",
			value: liveOrdersDataList[0]?.overdue_orders,
		},
		{
			type: "Delinquent",
			value: liveOrdersDataList[0]?.delinquent_orders,
		},
		{
			type: "Rejected",
			value: liveOrdersDataList[0]?.rejected_orders,
		},
	];
	const CustomeorderByProduct = [
		{
			type: "Standard",
			value: ordersByProductData[0]?.standard,
		},
		{
			type: "Texas Tax And Hoa",
			value: ordersByProductData[0]?.texas_tax_and_hoa,
		},
		{
			type: "Texas Tax Only",
			value: ordersByProductData[0]?.texas_tax_only,
		},
		{
			type: "Hoa Estoppel Resale",
			value: ordersByProductData[0]?.hoa_estoppel_resale_cert,
		},
		{
			type: "Municipal Lien",
			value: ordersByProductData[0]?.hoa_estoppel_resale_cert,
		},
		{
			type: "Current Owner",
			value: ordersByProductData[0]?.current_owner_search,
		},
		{
			type: "Tax Nationwide",
			value: ordersByProductData[0]?.tax_nationwide,
		},
		{
			type: "Hoa Nationwide",
			value: ordersByProductData[0]?.hoa_nationwide,
		},
	];

	const CustomeorderByStatus = [
		{
			type: "Open Orders",
			value: ordersByStatusData[0]?.open_orders,
		},
		{
			type: "In Progress Orders",
			value: ordersByStatusData[0]?.in_progress_orders,
		},
		{
			type: "On Hold Orders",
			value: ordersByStatusData[0]?.on_hold_orders,
		},
		{
			type: "Cancelled Orders",
			value: ordersByStatusData[0]?.cancelled_orders,
		},
		{
			type: "Rejected Orders",
			value: ordersByStatusData[0]?.rejected_orders,
		},
		{
			type: "Completed Orders",
			value: ordersByStatusData[0]?.completed_orders,
		},
	];

	//custome Pie Chart Config
	const pieOrdersByproductConfig = {
		data: CustomeorderByProduct,
		appendPadding: 10,
		angleField: "value",
		colorField: "type",
		radius: 0.9,
		legend: {
			layout: "verticale",
			position: "right",
		},
	};

	const pieOrdersByStatusConfig = {
		data: CustomeorderByStatus,
		appendPadding: 10,
		angleField: "value",
		colorField: "type",
		radius: 0.9,
		legend: {
			layout: "verticale",
			position: "right",
		},
	};

	//stacked bar Chart Config
	const stackedbarchart = {
		data: hourlyOrderData.map((info) => info),
		isStack: true,
		xField: `value`,
		yField: "hour",
		seriesField: "type",
		colorField: "type", // or seriesField in some cases
	};

	//column Chart Config
	const columnBarchartConfig = {
		data: customerOrderStatus.map((info) => info),
		xField: "customer_name",
		yField: "value",
		seriesField: "type",
	};

	//column Chart Config
	const columnBarchartConfig2 = {
		data: examinerOrderStatus.map((info) => info),
		xField: "examiner_name",
		yField: `no_of_orders`,
		seriesField: "type",
	};

	const normalPieconfig = {
		data: piedata,
		appendPadding: 10,
		angleField: "value",
		colorField: "type",
		radius: 0.9,
		legend: {
			layout: "verticale",
			position: "right",
		},
	};

	const column = [
		{
			title: (
				<span>
					Order No{" "}
					{getsortfield === "file_number" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "file_number" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "file_number" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "file_number",
			key: "file_number",
			sorter: (a, b) => a.file_number.localeCompare(b.file_number),
			width: 200,
			align: "center",
			...SearchColumns("file_number", "File Number"),
		},
		{
			title: (
				<span>
					Status{" "}
					{getsortfield === "order_status" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "order_status" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "order_status" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "order_status",
			key: "order_status",
			sorter: (a, b) => a.order_status.localeCompare(b.order_status),
			width: 200,
			align: "center",
			...SearchColumns("order_status", "Status"),
		},

		{
			title: (
				<span>
					Due Date{" "}
					{getsortfield === "due_date" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "due_date" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "due_date" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "due_date",
			key: "due_date",
			sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
			width: 150,
			align: "center",
			...SearchColumns("due_date", "Date"),
		},
	];

	const column2 = [
		{
			title: (
				<span>
					Date{" "}
					{getsortfield === "date" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "date" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "date" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "date",
			key: "date",
			sorter: (a, b) => a.date.localeCompare(b.date),
			width: 150,
			align: "center",
			...SearchColumns("date", "Date"),
		},
		{
			title: (
				<span>
					Orders Count{" "}
					{getsortfield === "order_count" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "order_count" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "order_count" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "order_count",
			key: "order_count",
			sorter: (a, b) => a.order_count - b.order_count,
			width: 180,
			align: "center",
			...SearchColumns("order_count", "Order Count"),
		},
		{
			title: (
				<span>
					Open{" "}
					{getsortfield === "open_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "open_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "open_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "open_orders",
			key: "open_orders",
			sorter: (a, b) => a.open_orders - b.open_orders,
			width: 100,
			align: "center",
			...SearchColumns("open_orders", "open_orders"),
		},
		{
			title: (
				<span>
					InProgress{" "}
					{getsortfield === "in_progress_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "in_progress_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "in_progress_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "in_progress_orders",
			key: "in_progress_orders",
			sorter: (a, b) => a.in_progress_orders - b.in_progress_orders,
			width: 130,
			align: "center",
			...SearchColumns("in_progress_orders", "Inprogress"),
		},
		{
			title: (
				<span>
					Overdue{" "}
					{getsortfield === "overdue_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "overdue_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "overdue_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "overdue_orders",
			key: "overdue_orders",
			sorter: (a, b) => a.overdue_orders - b.overdue_orders,
			width: 150,
			align: "center",
			...SearchColumns("overdue_orders", "Overdue Orders"),
		},
		{
			title: (
				<span>
					Rush{" "}
					{getsortfield === "rush_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "rush_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "rush_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "rush_orders",
			key: "rush_orders",
			sorter: (a, b) => a.rush_orders - b.rush_orders,
			width: 120,
			align: "center",
			...SearchColumns("rush_orders", "Rush Orders"),
		},
		{
			title: (
				<span>
					Hold{" "}
					{getsortfield === "on_hold_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "on_hold_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "on_hold_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "on_hold_orders",
			key: "on_hold_orders",
			sorter: (a, b) => a.on_hold_orders - b.on_hold_orders,
			width: 120,
			align: "center",
			...SearchColumns("on_hold_orders", "On Hold Orders"),
		},
		{
			title: (
				<span>
					Reject{" "}
					{getsortfield === "rejected_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "rejected_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "rejected_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "rejected_orders",
			key: "rejected_orders",
			sorter: (a, b) => a.rejected_orders - b.rejected_orders,
			width: 120,
			align: "center",
			...SearchColumns("rejected_orders", "Rejected Orders"),
		},
		{
			title: (
				<span>
					Cancel{" "}
					{getsortfield === "cancelled_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "cancelled_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "cancelled_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "cancelled_orders",
			key: "cancelled_orders",
			sorter: (a, b) => a.cancelled_orders - b.cancelled_orders,
			width: 120,
			align: "center",
			...SearchColumns("cancelled_orders", "Cancelled Orders"),
		},
		{
			title: (
				<span>
					Delinquent{" "}
					{getsortfield === "delinquent_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "delinquent_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "delinquent_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "delinquent_orders",
			key: "delinquent_orders",
			sorter: (a, b) => a.delinquent_orders - b.delinquent_orders,
			width: 145,
			align: "center",
			...SearchColumns("delinquent_orders", "Delinquent Orders"),
		},
		{
			title: (
				<span>
					Completed{" "}
					{getsortfield === "completed_orders" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "completed_orders" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" &&
					  getsortfield === "completed_orders" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "completed_orders",
			key: "completed_orders",
			sorter: (a, b) => a.completed_orders - b.completed_orders,
			width: 145,
			align: "center",
			...SearchColumns("completed_orders", "Completed Orders"),
		},
	];

	//const tab change handler
	const tabChangeHandler = (value) => {
		setKey(value);
	};

	//select handler
	const selectHandler = (property, value) => {
		setValues({
			...values,
			[property]: Number(value),
		});

		if (
			property === "customer_id" &&
			property === "month" &&
			property === "year"
		) {
			if (Number(value)) {
				setValues({
					...values,
					[property]: Number(value),
				});
			} else {
				setValues({
					...values,
					[property]: null,
				});
				// setBranchListData([]);
			}
		}

		if (property === "month") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}

		if (property === "year") {
			// if (!value) {
			//   setMonthOptions([]);
			//   setValues({ ...values, month: "", [property]: "" });
			// } else {
			//   setMonthOptions(months);
			setValues({
				...values,
				[property]: Number(value),
			});
			// }
		}
		if (property === "customer_year") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}
		if (property === "customer_month") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}
		if (property === "examiner_year") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}
		if (property === "examiner_month") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}
	};

	useEffect(() => {
		if (values.customer_id && values.month && values.year !== "") {
			monthlyOrdersList({
				data: {
					customer_id: Number(values.customer_id),
					month: Number(values.month),
					year: Number(values.year),
				},
				token: token,
			});
		} else if (!values.customer_id || values.month || values.year) {
			monthlyOrdersList({
				data: {
					customer_id: 0 || values.customer_id,
					month: 0 || values.month,
					year: 0 || values.year,
				},
				token: token,
			});
		} else {
			setMonthlyOrdersData([]);
		}
	}, [values.customer_id, values.month, values.year]);

	useEffect(() => {
		if (values.examiner_month && values.examiner_year !== "") {
			ExaminerOrdersList({
				data: {
					month: Number(values.examiner_month),
					year: Number(values.examiner_year),
				},
				token: token,
			});
		} else if (!values.examiner_month || values.examiner_year) {
			ExaminerOrdersList({
				data: {
					month: 0 || values.examiner_month,
					year: 0 || values.examiner_year,
				},
				token: token,
			});
		} else {
			setExaminerOrderStatus([]);
		}
	}, [values.examiner_month, values.examiner_year]);

	useEffect(() => {
		if (values.customer_month && values.customer_month !== "") {
			CustomerOrdersList({
				data: {
					month: Number(values.customer_month),
					year: Number(values.customer_year),
				},
				token: token,
			});
		} else if (!values.customer_month || values.customer_year) {
			CustomerOrdersList({
				data: {
					month: 0 || values.customer_month,
					year: 0 || values.customer_year,
				},
				token: token,
			});
		} else {
			setCustomerOrderStatus([]);
		}
	}, [values.customer_month, values.customer_year]);

	const [monthlyOrdersList, MonthlyOrdersListResult] =
		useMonthlyOrdersListMutation();
	useEffect(() => {
		if (MonthlyOrdersListResult.isSuccess) {
			const newData = MonthlyOrdersListResult.data?.data?.map(
				({
					id,
					order_count,
					open_orders,
					in_progress_orders,
					cancelled_orders,
					rejected_orders,
					completed_orders,
					delinquent_orders,
					overdue_orders,
					...others
				}) => {
					return {
						...others,
						id,
						key: id,
						order_count: order_count,
						open_orders: open_orders,
						in_progress_orders: in_progress_orders,
						cancelled_orders: cancelled_orders,
						rejected_orders: rejected_orders,
						completed_orders: completed_orders,
						delinquent_orders: delinquent_orders,
						overdue_orders: overdue_orders,
					};
				}
			);
			setMonthlyOrdersData(newData);
		} else {
			setMonthlyOrdersData([]);
		}
	}, [MonthlyOrdersListResult.isLoading]);

	//calling rtk query for filter orders
	const [ExaminerOrdersList, ExaminerOrdersListResult] =
		useExaminerOrdersStatusMutation();
	useEffect(() => {
		if (ExaminerOrdersListResult.isSuccess) {
			const newData = ExaminerOrdersListResult.data?.data?.map(
				({ id, no_of_orders, examiner_name, ...others }) => {
					return {
						...others,
						id,
						key: id,
						no_of_orders: no_of_orders,
						examiner_name: examiner_name,
					};
				}
			);
			setExaminerOrderStatus(newData);
		} else {
			setExaminerOrderStatus([]);
		}
	}, [ExaminerOrdersListResult.isLoading]);

	//calling rtk query for filter orders
	const [CustomerOrdersList, CustomerOrdersListResult] =
		useCustomerOrdersStatusMutation();
	useEffect(() => {
		if (CustomerOrdersListResult.isSuccess) {
			const newData = CustomerOrdersListResult.data?.data?.map(
				({ id, type, value, customer_name, ...others }) => {
					return {
						...others,
						id,
						key: id,
						type: type,
						value: value,
						customer_name: customer_name,
					};
				}
			);
			setCustomerOrderStatus(newData);
		} else {
			setCustomerOrderStatus([]);
		}
	}, [CustomerOrdersListResult.isLoading]);

	//error
	useEffect(() => {
		if (getError) {
			scrollComp("dashboardError");
		}
	}, [getError]);

	//refecth list
	function refetchApi() {
		// liveOrdersList.refetch();
		monthlyOrdersList({
			data: {
				customer_id: Number(values.customer_id),
				month: Number(values.month),
				year: Number(values.year),
			},
			token: token,
		});

		ExaminerOrdersList({
			data: {
				month: Number(values.examiner_month),
				year: Number(values.examiner_year),
			},
			token: token,
		});

		CustomerOrdersList({
			data: {
				month: Number(values.customer_month),
				year: Number(values.customer_year),
			},
			token: token,
		});
	}

	return (
		<>
			<div className="mt-3" id="dashboardError">
				{getError && <Alert message={getError} type="error" showIcon />}
			</div>
			{(liveOrdersList.isLoading ||
				MonthlyOrdersListResult.isLoading ||
				CustomerOrdersListResult.isLoading ||
				ExaminerOrdersListResult.isLoading) && <AppSpinner />}
			<h5 className="float-left">Dashboard</h5>

			<div className="row">
				<div className="col">
					<FiRefreshCcw
						className="float-end"
						type="button"
						id="template"
						onClick={() => {
							// navigate("/dashboard")
							refetchApi();
						}}
						title="Refresh"
						style={{ color: "green" }}
						size={20}
					/>
				</div>
			</div>

			<StatusCards data={liveOrdersDataList[0]} />

			<Card className="dounut-barchart-row">
				<h5 align="left" className="h-5 ">
					My Orders
				</h5>
				<div className="row  p-2 ">
					<div className="col-lg-6 p-1">
						<AppTable
							dataSource={liveOrdersDataList[1]}
							columns={column}
							pagination={true}
							defaultPageSize={5}
							pageSizeOptions={["5", "10", "15", "20", "25"]}
							loading={liveOrdersList.isLoading}
							sort={sort}
						/>
					</div>
					<div className="col-lg-6 p-1">
						<Card>
							<NormalPie {...normalPieconfig} />
						</Card>
					</div>
				</div>
			</Card>
			<Card className="dounut-barchart-row mt-3">
				<div className="mt-0 p-2 ">
					<h5 align="left" className="h-5 ">
						Monthly Order Status
					</h5>
					<div className="row ">
						<div className="col-lg-6 p-0">
							<div className="col-lg-3 m-2 ">
								<AppSelect
									//   defaultSet={afterClose}
									classStyle={"border px-2 py-1"}
									name="customer_id"
									onChange={selectHandler}
									value={values.customer_id}
									options={
										<>
											<option value={0}>All Customers</option>
											{customersData?.map((ele) => {
												return (
													<option key={ele.id} value={ele.id}>
														{ele.name}
													</option>
												);
											})}
										</>
									}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="d-flex align-items-center m-0 p-0  mb-0 gap-2 justify-content-end">
								<div>
									<AppSelect
										classStyle={"border px-2 py-1 "}
										//   defaultSet={afterClose}
										name="year"
										onChange={selectHandler}
										value={values.year}
										defaultValue={new Date().getFullYear()}
										options={
											<>
												<option value={0} selected>
													Select Year
												</option>
												{Array.from(new Array(50), (v, i) => (
													<option key={i} value={year - i}>
														{year - i}
													</option>
												))}
											</>
										}
									/>
								</div>
								<div>
									<AppSelect
										classStyle={"border px-2 py-1 "}
										//   defaultSet={afterClose}
										name="month"
										onChange={selectHandler}
										value={values.month}
										options={
											<>
												<option value={0}>Select Month</option>
												{monthOptions?.map((ele) => {
													return (
														<option key={ele.id} value={ele.id}>
															{ele.month}
														</option>
													);
												})}
											</>
										}
									/>
								</div>
							</div>
						</div>
					</div>

					<AppTable
						dataSource={monthlyOrdersData}
						columns={column2}
						pagination={true}
						sort={sort}
						loading={MonthlyOrdersListResult.isLoading}
					/>

					<Row>
						<Col className="col-lg-4">
							<Card className="d-flex m-1 total-count-card">
								<Row className="text-center p-3 ">
									<Col className="col align-item-center text-center">
										{"Total Orders"}
									</Col>
									<Col className="col align-item-center text-center">
										{liveOrdersDataList[0]?.order_count}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col className="col-lg-4">
							<Card className="d-flex m-1 total-count-card">
								<Row className="text-center p-3 ">
									<Col className="col align-item-center text-center">
										{"Open Orders"}
									</Col>
									<Col className="col align-item-center text-center">
										{liveOrdersDataList[0]?.open_orders}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col className="col-lg-4">
							<Card className="d-flex m-1 total-count-card">
								<Row className="text-center p-3 ">
									<Col className="col align-item-center text-center">
										{"Completed Orders"}
									</Col>
									<Col className="col align-item-center text-center">
										{liveOrdersDataList[0]?.completed_orders}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</Card>
			<div className="row">
				<div className="col-lg-6">
					<Card className="dounut-barchart-row mt-3 nodata-card">
						<h5 align="left" className="h-5 ">
							Hourly Order Status
						</h5>
						{hourlyOrderData.length !== 0 ? (
							<StackedBarChart {...stackedbarchart} />
						) : (
							<Empty className="mt-5 mb-5" />
						)}
					</Card>
				</div>
				<div className="col-lg-6">
					<Card className="dounut-barchart-row mt-3 nodata-card">
						<Tabs
							//   type="card"
							style={{ borderRadius: "25" }}
							tabBarStyle={{
								borderRadius: "40 !important",
								backgroundColor: "white",
							}}
							activeKey={key}
							className="pupil-shape-tabs"
							type="card"
							defaultActiveKey="summary"
							onChange={tabChangeHandler}
						>
							<TabPane
								tab="Order's By Customer"
								key={"orders_by_customer"}
								className="bordered"
								style={{ borderRadius: "15" }}
							>
								<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
									<div>
										<AppSelect
											classStyle={"border px-2 py-1 "}
											//   defaultSet={afterClose}
											name="customer_year"
											onChange={selectHandler}
											value={values.customer_year}
											options={
												<>
													<option value={0}>Select Year</option>
													{Array.from(new Array(50), (v, i) => (
														<option key={i} value={year - i}>
															{year - i}
														</option>
													))}
												</>
											}
										/>
									</div>
									<div>
										<AppSelect
											classStyle={"border px-2 py-1 "}
											//   defaultSet={afterClose}
											name="customer_month"
											onChange={selectHandler}
											value={values.customer_month}
											options={
												<>
													<option value={0}>Select Month</option>
													{monthOptions?.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.month}
															</option>
														);
													})}
												</>
											}
										/>
									</div>
								</div>
								{customerOrderStatus.length !== 0 ? (
									<ColumnBarChart {...columnBarchartConfig} />
								) : (
									<Empty className="mt-4 mb-3" />
								)}
							</TabPane>
							<TabPane
								tab="Order's By Examiner"
								key={"orders_by_examiner"}
								className="bordered h-1"
								style={{ borderRadius: "15" }}
							>
								<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
									<div>
										<AppSelect
											classStyle={"border px-2 py-1 "}
											//   defaultSet={afterClose}
											name="examiner_year"
											onChange={selectHandler}
											value={values.examiner_year}
											options={
												<>
													<option value={0}>Select Year</option>
													{Array.from(new Array(50), (v, i) => (
														<option key={i} value={year - i}>
															{year - i}
														</option>
													))}
												</>
											}
										/>
									</div>
									<div>
										<AppSelect
											classStyle={"border px-2 py-1 "}
											//   defaultSet={afterClose}
											name="examiner_month"
											onChange={selectHandler}
											value={values.examiner_month}
											options={
												<>
													<option value={0}>Select Month</option>
													{monthOptions?.map((ele) => {
														return (
															<option key={ele.id} value={ele.id}>
																{ele.month}
															</option>
														);
													})}
												</>
											}
										/>
									</div>
								</div>
								{examinerOrderStatus.length !== 0 ? (
									<ColumnBarChart {...columnBarchartConfig2} />
								) : (
									<Empty className="mt-4 mb-3" />
								)}
							</TabPane>
						</Tabs>
					</Card>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6 ">
					<Card className="dounut-barchart-row mt-3">
						<h5 align="left" className="h-5 ">
							Today's Order By Status
						</h5>
						<NormalPie {...pieOrdersByStatusConfig} />
					</Card>
				</div>
				<div className="col-lg-6">
					<Card className="dounut-barchart-row mt-3">
						<h5 align="left" className="h-5 ">
							Today's Order By Products
						</h5>
						<NormalPie {...pieOrdersByproductConfig} />
					</Card>
				</div>
			</div>
		</>
	);
};

export default DashboardNewDesign;
