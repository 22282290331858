import { useEffect, useState } from "react";
import {
  FaFileExcel,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";
import { Alert, Form, Popconfirm } from "antd";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { Input, notification,Button } from "antd";
import {
  useAddTaxCadMutation,
  useTaxcadListMutation,
  useDeleteSingleTaxCadListMutation,
  useFulltaxcadListQuery,
  useBatchTaxCadListQuery,
  useTaxcadFilterListMutation,
} from "../../../../Redux/Services/Taxcad";
import upload_order_file from "../../../../Images/Sample Tax CAD Template.xlsx";
import { Row } from "react-bootstrap";
import { useGetCountyListMutation } from "../../../../Redux/Services/county";
import AppTable from "../../../../Components/AppTable/AppTable";
import AppModal from "../../../../Components/AppModal/AppModal";
import AppExcelUpload from "../../../../Components/AppExcelUpload/AppExcelUpload";
import { scrollComp } from "../../../../Controllers/Functions";
import AppFilterSelect from "../../../../Components/AppFilterSelect/AppFilterSelect";
import SearchColumns from "../../../../Components/SearchColumns";
import { FiFilter,FiRotateCw } from "react-icons/fi";
import { ListModal } from "./ListModal";

export const List = ({ refresh, optionsShow = true, updateTab }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [showHide, setShowHide] = useState(null);
  const [form] = Form.useForm();
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [editId, setEditId] = useState(null);
  const [getsortfield, setSortField] = useState("");
  //redux data store
  const { token } = useSelector((state) => state.auth);
  const { taxcadModule } = useSelector((state) => state.flagRoles);
  const [upload, setUpload] = useState(false);
  const [getError, setGetError] = useState(false);
  const [initialStateVal, setInitialStateVal] = useState(false);
  const [open, setOpen] = useState(false);
  const [countyOptions, setCountyOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);

  //state list
  const { stateList } = useSelector((state) => state.stateList);
  //county list
  const { countyList } = useSelector((state) => state.countyList);

  //tax-cad list
  const [taxcadValues] = useState({
    state: "",
    county: "",
    tax_year: "",
    tax_collector: "",
    due_date: "",
    cad_property_id: "",
    cad_geo_id: "",
    tax_assessor_prop_id: "",
    cross_tax_id: "",
    manual_bill_indicator: "",
    tax_suite_indicator: "",
    payment_plan: "",
    discount_plan: "",
    bill_type_one: "",
    jurisdiction_name_one: "",
    jurisdiction_code_one: "",
    current_bill_base_tax: "",
    current_base_tax_due: "",
    pi_collection_fees: "",
    current_pay_off_month_one: "",
    current_pay_off_month_two: "",
    bill_type_two: "",
    prior_bill_years: "",
    jurisdiction_name_two: "",
    jurisdiction_code_two: "",
    combined_bases_tax_due: "",
    prior_year_pay_off_month_one: "",
    prior_year_pay_off_month_two: "",
  });

  //edit taxcad list module
  const editHandler = (e) => {
    setShowHide(true);
    setEditId(e.id);
  };

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;
  // useEffect for countylist
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    } else {
      setCountyOptions([]);
    }
  }, [countyList2[1].isLoading]);

  //calling rtk query for batchcadlist query
  const batchList = useBatchTaxCadListQuery(token);
  useEffect(() => {
    if (batchList.isSuccess) {
      setBatchOptions(batchList.data?.data);
    }
  }, [batchList]);

  const [filetrVal, setFiletrVal] = useState({
    state: "",
    county: "",
    batch: "",
  });

  //filter options
  //filter options

  const options = [
    {
      name: "state",
      value: stateList?.filter(({ state_name }) => {
        return state_name === filetrVal.state;
      })[0]?.id,
      data: stateList?.map(({ state_name, id }) => {
        return { name: state_name, id };
      }),
      disabled: filetrVal.batch,
    },
    {
      name: "county",
      value: countyOptions?.filter(({ county_name }) => {
        return county_name === filetrVal.county;
      })[0]?.id,
      data: countyOptions?.map(({ county_name, id }) => {
        return { name: county_name, id };
      }),
      disabled: filetrVal.batch,
    },
    {
      name: "batch",
      value: filetrVal.batch,
      data: batchOptions?.map(({ name, id }) => {
        return { name: name, id };
      }),
      disabled: filetrVal.state || filetrVal.county,
    },
  ];

  //calling rtk query for tax-cad-list
  const taxcadList = useFulltaxcadListQuery(token);
  useEffect(() => {
    if (taxcadList.isSuccess) {
      const newData = taxcadList.data?.data?.map(
        ({
          state,
          county,
          tax_collector,
          tax_year,

          ...ele
        }) => {
          return {
            ...ele,
            state: state || "NA",
            county: county || "NA",
            tax_collector: tax_collector || "NA",
            tax_year: tax_year || "NA",
          };
        }
      );
      setData(newData);
    }
  }, [taxcadList]);

  useEffect(() => {
    setCountyOptions(countyList);
  }, [countyList]);

  //calling rtk query hook for upload tax-cad data
  const addNewTaxCadFile = useAddTaxCadMutation();
  const [addTaxCadFile] = addNewTaxCadFile;

  //useEffect for upload-tax-cad file
  useEffect(() => {
    if (addNewTaxCadFile[1].isSuccess) {
      ///after submit details
      setUpload(true);
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewTaxCadFile[1].data?.message,
      });
      setGetError(false);
    }
    if (addNewTaxCadFile[1].error) {
      setGetError(
        addNewTaxCadFile[1].error?.data?.error ||
          addNewTaxCadFile[1].error.data?.msg?.sqlMessage ||
          (addNewTaxCadFile[1].error.error && "server unreachable!")
      );
    }
  }, [addNewTaxCadFile[1].isLoading]);

  const DeleteSingleTaxcad = useDeleteSingleTaxCadListMutation();
  const [GetDeleteSingleTaxCad] = DeleteSingleTaxcad;

  //useEffect for delete
  useEffect(() => {
    if (DeleteSingleTaxcad[1].isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: DeleteSingleTaxcad[1].data?.message,
      });
    }
    if (DeleteSingleTaxcad[1].error) {
      /// erros details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          DeleteSingleTaxcad[1].error.data.message ||
          DeleteSingleTaxcad[1].error.error ||
          DeleteSingleTaxcad[1].error.data?.msg.sqlMessage,
      });
    }
  }, [DeleteSingleTaxcad[1].isLoading]);

  function closeModal(data) {
    setShowHide(data);
  }

  function afterClose() {
    setInitialStateVal(!initialStateVal);
  }

  //uploading tax cad file
  const handleUpload = (file) => {
    const new_taxcad = new FormData();
    new_taxcad.append("file", file);
    addTaxCadFile({
      data: new_taxcad,
      token: token,
    });
  };

  //delete taxcad data
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    GetDeleteSingleTaxCad({
      id: key,
      token: token,
    });
    setData(newData);
  };

  const sort = (sortOrder, sortfield) => {
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  //calling rtk query hook for getting tax-cad list based on batch-id
  const taxcadListFilter = useTaxcadListMutation();
  const [getTaxcadListFilter] = taxcadListFilter;

  useEffect(() => {
    if (taxcadListFilter[1].isSuccess) {
      const newData = taxcadListFilter[1].data?.data?.map(
        ({ state, county, tax_collector, tax_year, ...ele }) => {
          return {
            ...ele,
            state: state || "NA",
            county: county || "NA",
            tax_collector: tax_collector || "NA",
            tax_year: tax_year || "NA",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [taxcadListFilter[1].isLoading]);

  //calling rtk query for taxcad-list filter based on state & count
  const [taxcadFilterList, TaxCadFilterListResult] =
    useTaxcadFilterListMutation();
  useEffect(() => {
    if (TaxCadFilterListResult.isSuccess) {
      const newData = TaxCadFilterListResult.data?.data?.map(
        ({ state, county, tax_collector, tax_year, ...ele }) => {
          return {
            ...ele,
            state: state || "NA",
            county: county || "NA",
            tax_collector: tax_collector || "NA",
            tax_year: tax_year || "NA",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [TaxCadFilterListResult.isLoading]);

  const columns = [
    {
      title: (
        <span>
          State{" "}
          {getsortfield === "state" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "state" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "state" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
      width:300,
      ...SearchColumns("state", " State Name"),
      align: "center",
    },
    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "county" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "county",
      key: "county",
      sorter: (a, b) => a.county.localeCompare(b.county),
      width: 300,
      ...SearchColumns("county", "County Name"),
      align: "center",
    },

    {
      title: (
        <span>
          Tax Year{" "}
          {getsortfield === "tax_year" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "tax_year" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "tax_year" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "tax_year",
      key: "tax_year",
      sorter: (a, b) => a.tax_year.localeCompare(b.tax_year),
      width: 300,
      ...SearchColumns("tax_year", "Tax Year"),
      align: "center",
    },

    {
      title: (
        <span>
          Tax Collector{" "}
          {getsortfield === "tax_collector" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "tax_collector" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "tax_collector" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "tax_collector",
      key: "tax_collector",
      sorter: (a, b) => a.tax_collector.localeCompare(b.tax_collector),
      width: 300,
      ...SearchColumns("tax_collector", "Tax Collector"),
      align: "center",
    },
    {
      title: <span>Actions</span>,
     	className: `${taxcadModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => {
        return (
          <>
            {<EditOutlined size={50} onClick={() => editHandler(record)} />}
            {"  "}
            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                handleDelete(record.id);
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className="gap-2">
                <DeleteOutlined
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  //ondrop
  const onDrop = (e) => {
    console.log(e);
    console.log("Dropped files", e.dataTransfer.files);
  };

  //onchange of file-upload
  const onChange = (info) => {
    if (info.file !== "uploading") {
      console.log(info.file, info.fileList);
    }
    handleUpload(info.file);
  };

  function clearFilter() {
    setOpen(false);
    clearState();
  }
  //function for clear reset
  function clearReset() {
    setCountyOptions([]);
    //reset
    if (filetrVal.state || filetrVal.batch) {
      taxcadList.refetch();
    }

    setFiletrVal({
      ...filetrVal,
      state: "",
      county: "",
      batch: "",
    });
  }

  //function for clear state
  function clearState() {
    setCountyOptions([]);
    //reset
    if (filetrVal.state) {
      taxcadList.refetch();
    }

    setFiletrVal({
      ...filetrVal,
      state: "",
      county: "",
      // batch: "",
    });
  }


  //function -reset county
  function clearCounty() {
    //reset
    if (filetrVal.state) {
      taxcadFilterList({
        data: { state: filetrVal.state },
        token,
      });
    }
    setFiletrVal({
      ...filetrVal,
      county: "",
    });
  }

  //function-reset batch
  function clearBatch() {
    //reset
    if (filetrVal.batch) {
      taxcadList.refetch();
    }
    setFiletrVal({
      ...filetrVal,
      batch: "",
    });
  }
  //addTaxCad Handler function with dependency
  const AddTaxCadHandler = () => {
    setShowHide(true);
    setEditId(null);
  };

  //for radio & main tab
  useEffect(() => {
    setSearch("");
    if (refresh === "taxcad") {
      taxcadList.refetch();
    }
    setCountyOptions([]);
    setOpen(false);
  }, [refresh]);

  //sample upload
  const downloadFile = () => {
    window.location.href = upload_order_file;
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("taxCadError");
    }
  }, [getError]);

  return (
    <>
      {(taxcadListFilter[1].isLoading ||
        TaxCadFilterListResult.isLoading ||
        countyList2[1].isLoading ||
        DeleteSingleTaxcad[1].isLoading ||
        addNewTaxCadFile[1].isLoading) && <AppSpinner />}
      <div className="mt-3" id="taxCadError">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
        <>
          <AppModal
            confirmProp={true}
            showModal={showHide}
            onClose={closeModal}
            afterClose={afterClose}
            width={"90%"}
            height={400}
            element={
              <ListModal
                updateTab={updateTab}
                editId={editId}
                initialValues={taxcadValues}
                setShowHide={setShowHide}
                afterClose={initialStateVal}
                setOpen={clearFilter}
              />
            }
          />
          <div id="">
            <FaFileExcel
              className="btn btn-md"
              type="button"
              id="template"
              onClick={downloadFile}
              title="Sample Template"
              style={{ color: "green" }}
              size={40}
            />
          </div>
          <AppExcelUpload
            showRemoveIcon={false}
            margin={"0"}
            onChange={onChange}
            onDrop={onDrop}
            upload={upload}
            // showRemoveIcon={showRemoveIcon}
            multiple={false}
          />
        </>

        {/* changing input values globaly  */}
        <>
          {optionsShow && (
            <>
              {" "}
              <div className=" col-3 col-lg-3 ">
                <Input
                  placeholder="Search here"
                  className="global-search "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<FaSearch color="lightgray" />}
                />
              </div>
              <div className="filter-button ">
                <Button
                  className="filter-btn"
                  onClick={() => {
                    setOpen(!open);
                    setFiletrVal({ state: "", county: "", batch: "" });
                    if (open) {
                      taxcadList.refetch();
                    }
                  }}
                  type={open ? "primary" : "default"}
                >
                  <FiFilter color={open ? "#fff" : ""} />{" "} <span className="ms-1">Filter</span>
                </Button>
              </div>
              {taxcadModule.map((item,index) => {
								if (item.add_button) {
									return (
                    <div key={index} className="add_icon" onClick={AddTaxCadHandler}>
											<FaPlus />
										</div>
									);
                }
                return null;
							})}
            </>
          )}
        </>
      </div>
      <div style={{ clear: "both" }}></div>
      {open && (
        <div className="my-2 p-2 bg-white border shadow-sm d-flex align-items-center ">
          <div className="row w-100">
            {options?.map(({ value, name, data, disabled }, index) => {
              return (
                <div className="col-12 col-lg-3">
                  <AppFilterSelect
                    name={name}
                    value={value}
                    options={data}
                    disabled={disabled}
                    onClear={() => {
                      if (name === "state") {
                        clearState();
                      } else if (name === "county") {
                        clearCounty();
                      } else if (name === "batch") {
                        clearBatch();
                      }
                    }}
                    onChange={(e) => {
                      setFiletrVal({
                        ...filetrVal,
                        [name]:
                          e.target?.selectedOptions[0].getAttribute(
                            "data_name"
                          ),
                      });

                      if (name === "state" && e.target.value) {
                        setFiletrVal({
                          state:
                            e.target?.selectedOptions[0].getAttribute(
                              "data_name"
                            ),
                          batch: "",
                          county: "",
                        });
                        //fetching county list
                        getCountyList({
                          state_id: { state_id: e.target.value },
                          token: token,
                        });
                        taxcadFilterList({
                          data: {
                            state:
                              e.target?.selectedOptions[0].getAttribute(
                                "data_name"
                              ),
                          },
                          token,
                        });
                      }
                      if (name === "state" && !e.target.value) {
                        clearState();
                      }

                      if (name === "county" && e.target.value) {
                        taxcadFilterList({
                          data: {
                            county:
                              e.target?.selectedOptions[0].getAttribute(
                                "data_name"
                              ),
                            state: filetrVal.state,
                          },
                          token,
                        });
                      }
                      if (name === "county" && !e.target.value) {
                        clearCounty();
                      }

                      if (name === "batch" && e.target.value) {
                        setFiletrVal({
                          batch: e.target.value,
                          sate: "",
                          county: "",
                        });
                        setCountyOptions([]);
                        getTaxcadListFilter({
                          id: e.target.value,
                          token,
                        });
                      }
                      if (name === "batch" && !e.target.value) {
                        clearBatch();
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="d-flex justify-centent-end ms-md-2">
            <Button
              className="reset-btn"
              onClick={() => {
                clearReset();
              }}
              type="default"
            >
              <FiRotateCw /> <span className="ms-1"> Reset</span>
            </Button>
          </div>
        </div>
      )}
      <Row>
        <AppTable
          loading={
            (DeleteSingleTaxcad.isLoading ||
              taxcadList.isLoading ||
              batchList.isLoading) && <AppSpinner />
          }
          search={search}
          dataSource={data}
          columns={columns}
          sort={sort}
          form={form}
        />
      </Row>
    </>
  );
};
