import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const authApi = createApi({
  reducerPath: "authApi",
  tagTypes: ["user"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (data) => ({
        url: `/login`,
        method: "POST",
        mode: "cors",
        headers: { "Access-Control-Allow-Origin": "*" },
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    userEmailPassLinkSend: builder.mutation({
      query: (data) => ({
        url: `/forgot-password`,
        method: "POST",
        mode: "cors",
        body: data,
        headers: { "Access-Control-Allow-Origin": "*" },
      }),
    }),
    userResetPassword: builder.mutation({
      query: (data) => ({
        url: `/reset-password`,
        method: "POST",
        mode: "cors",
        body: data,
        headers: { "Access-Control-Allow-Origin": "*" },
      }),
    }),
    testing: builder.mutation({
      query: (data) => ({
        url: `/reset-password`,
        method: "POST",
        mode: "cors",
        body: data,
        headers: { "Access-Control-Allow-Origin": "*" },
      }),
    }),
    firstLoginUpdatePass: builder.mutation({
      query: (data) => ({
        url: `/first-login-update-password/${data.id}`,
        method: "POST",
        mode: "cors",
        body: {password:data.password},
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    activeNotification: builder.query({
      query: () => ({
        url: `/active-maintenance-notification-list`,
        method: "GET",
        mode: "cors",
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useUserEmailPassLinkSendMutation,
  useUserResetPasswordMutation,
  useFirstLoginUpdatePassMutation,
  useActiveNotificationQuery,
} = authApi;