import React from "react";
import { mobileFormat, priceFormater } from "../../../../Controllers/Functions";
const parse = require("html-react-parser");
export default function HoaPage({ data }) {
  return (
    <>
      <div className="k-pdf-export certificate page-break ">
        {/* //header  */}
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-center w-100">HOA CERTIFICATE</h2>
        </header>

        {/* //date  */}
        <div className="disclaimer">
          <h3>HOA Certificate</h3>
        </div>
        <div className="my-1">
          <h5>HOA Completed: {data?.completed_date}</h5>
        </div>
        {/* //property details  */}
        <div className="property bg-outline">
          <div className="info d-flex p-1">
            <h5>Property Address</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.property_address}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Owner Name</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.owner}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Mailing Address</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.mailing_adderess}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Legal Description</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.legal_description}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Tax Account Number</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.tax_account_number}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Parcel ID</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.parcel_id}</h5>
          </div>

          <div className="info d-flex p-1">
            <h5>Acreage</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.acreage}</h5>
          </div>
          <div className="info d-flex p-1">
            <h5>Subcode</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.subcode}</h5>
          </div>
        </div>
      </div>
      {/* //Hoa details  */}
      <div className="Hoa bg-outline my-2">
        <div className="info d-flex p-1">
          <h5>HOA</h5>
          <h5>:&nbsp;</h5>
          <h5>{data?.hoa || "HOA NOT FOUND - VERIFY HOA ADDENDUM DOCUMENT"}</h5>
        </div>

        <div className="info d-flex p-1">
          <h5>Date Verified</h5>
          <h5>:&nbsp;</h5>
          <h5>{data?.date_verified}</h5>
        </div>

        <div className="info d-flex p-1">
          <h5>Collection Cycle</h5>
          <h5>:&nbsp;</h5>
          <h5>{data?.collection_cycle}</h5>
        </div>

        {/* <div className="info d-flex p-1">
          <h5>HOA Notes</h5>
          <h5>:&nbsp;</h5>
          <h5>{parse(data?.hoa_notes)}</h5>
        </div> */}
        <div className="info d-flex p-1">
          <h5>Address</h5>
          <h5>:&nbsp;</h5>
          <h5>{data?.hoa_address}</h5>
        </div>
        <div className="info d-flex p-1">
          <h5>Contact</h5>
          <h5>:&nbsp;</h5>
          <h5>{data?.Hoa_contact}</h5>
        </div>
        <div className="info d-flex p-1">
          <h5>HOA Notes</h5>
          <h5>:&nbsp;</h5>
          <h5>{parse(data?.hoa_notes)}</h5>
        </div>
        {data?.hoa_fees?.length > 0 &&
          data?.hoa_fees.map((ele, i) => {
            return (
              <div className="master-fess d-flex p-1" key={i}>
                <h5>{Object.keys(ele)}</h5>
                <h5>&nbsp;=&nbsp;</h5>
                <h5>{priceFormater(ele[Object.keys(ele)])}</h5>
              </div>
            );
          })}
      </div>

      {/* //Hoa MGMT Details  */}
      {data?.collector_mgmt && (
        <div className="Hoa bg-outline my-2">
          <div className="info d-flex p-1">
            <h5>Collector/Management Company</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.collector_mgmt || ""}</h5>
          </div>

          {/* <div className="info d-flex p-1">
          <h5>HOA Notes</h5>
          <h5>:&nbsp;</h5>
          <h5>{parse(data?.hoa_notes)}</h5>
        </div> */}
          <div className="info d-flex p-1">
            <h5>Address</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.collector_mgmt_address}</h5>
          </div>
          <div className="info d-flex p-1">
            <h5>Contact</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.contact}</h5>
          </div>
          <div className="info d-flex p-1">
            <h5>Phone</h5>
            <h5>:&nbsp;</h5>
            <h5>
              {data?.hoa_collector_phone
                ? mobileFormat(data?.hoa_collector_phone)
                : null}
            </h5>
          </div>
          <div className="info d-flex p-1">
            <h5>Email</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.hoa_collector_email}</h5>
          </div>
          <div className="info d-flex p-1">
            <h5>Website</h5>
            <h5>:&nbsp;</h5>
            <h5>{data?.hoa_collector_website}</h5>
          </div>
          <div className="info d-flex p-1">
            <h5>General Management Company Notes</h5>
            <h5>:&nbsp;</h5>
            <h5>{parse(data?.mgmt_notes)}</h5>
          </div>
          {data?.hoa_mgmt_fees?.length > 0 &&
            data?.hoa_mgmt_fees.map((ele, i) => {
              return (
                <div className="master-fess d-flex p-1" key={i}>
                  <h5>{Object.keys(ele)}</h5>
                  <h5>&nbsp;=&nbsp;</h5>
                  <h5>{priceFormater(ele[Object.keys(ele)])}</h5>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}
