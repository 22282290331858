import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux/es/exports";
import AppCheckbox from "../../../../Components/AppCheckbox/AppCheckbox";
import { Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import AppSelect from "../../../../Components/AppSelect/AppSelect";
import { Alert, Empty, notification } from "antd";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import {
	useAddBranchMutation,
	useBranchProductTypeMutation,
	useEditBranchQuery,
	useUpdateBranchMutation,
} from "../../../../Redux/Services/Branch";
import { useCustomerOptionsMutation } from "../../../../Redux/Services/Customers";
import AppMultiSelect from "../../../../Components/AppMultiSelect/AppMultiSelect";

import { useCustomerStateCountyListMutation } from "../../../../Redux/Services/customerStateCounty";
import { removeBlank, scrollComp } from "../../../../Controllers/Functions";
import MultiContacts from "../../../../Components/MultiContacts/MultiContacts";
import MyMultiSelect from "../../../../Components/MultiSelect/MyMultiSelect";
import { ProductTypeComp } from "../../../../Components/ProductType/ProductTypeComp";
import Input from "react-phone-number-input/input";
const _ = require("lodash");

export const BranchModal = ({
	editId,
	initialValues,
	setShowHide,
	afterClose,
}) => {
	const [vendorData, setVendorData] = useState([
		{ vendor_id: "", vendor_name: "" },
	]);
	const [customertypeList, setCustomerTypeList] = useState([]);

	//setting products
	const [products, setProducts] = useState([]);
	const [productStateList, setProductStateList] = useState([]);
	const [productCountyList, setProductCountyList] = useState([]);
	//multiple products
	const [multiProducts] = useState([]);

	//multiple contacts
	const [multiContacts, setMultiContacts] = useState([]);

	const [getError, setGetError] = useState(false);
	const [enableStateCountyList, setEnableStateCountyList] = useState([]);

	const [vendorIdConst, setVendorIdConst] = useState(null);
	const [customerConst, setCustomerConst] = useState(null);
	//   values assign
	const [values, setValues] = useState(initialValues);

	const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);

	//calling redux stor for vendors list
	const { vendorsList } = useSelector((state) => state.vendorsList);
	const data = _.orderBy(vendorsList, [
		(item) => _.get(item, "vendor_name").toLowerCase(),
	]);

	//calling redux stor for vendors list
	const { token, user } = useSelector((state) => state.auth);

	const { tableColumn } = useSelector((state) => state.flagRoles);

	//calling from store to the productType
	const { productTypeList } = useSelector((state) => state.productTypeList);

	//calling redux stor for vendors list
	const { stateList } = useSelector((state) => state.stateList);

	//calling rtk query hook for geting customerType list
	const editBranchQuery = useEditBranchQuery(
		{ id: editId, token: token },
		{ skip: !editId }
	);
	//calling rtk query hook for adding customer
	const addNewBranch = useAddBranchMutation();
	const [addBranch] = addNewBranch;

	//calling rtk query hook for update customer
	const updateBranchDetails = useUpdateBranchMutation();
	const [updateBranch] = updateBranchDetails;

	//calling rtk query hook for customer-list options
	const customerListOptions = useCustomerOptionsMutation();
	const [customerOptions] = customerListOptions;

	const stateCountyListApi = useCustomerStateCountyListMutation();
	const [stateCountyIdList] = stateCountyListApi;

	//calling rtk query hook for geting branch productType list
	const branchProductTypeApi = useBranchProductTypeMutation();
	const [branchProductType] = branchProductTypeApi;

	//clear error function
	function clearStatus() {
		setGetError(null);
	}
	//function for modal close
	// modal will be close
	function closeModal() {
		clearStatus();
		setShowHide(false);
	}

	//branchPricing function
	function branchProductController(id) {
		branchProductType({ id: id, token: token });
	}



	//useEffect for setting default values
	useEffect(() => {
		setVendorData(data);
		setStateData(stateList);

		//when we edit

		if (user.user_type_id !== 1 && !editId) {
			// stateCountyIdList({
			//   id: user.customer_id,
			//   token: token,
			// });
			//customer options
			customerOptions({ id: user.vendor_id, token: token });
			setValues({ ...initialValues, vendor_id: user.vendor_id });
		}
		if (!editId) {
			setValues(initialValues);
		}
		// if edit id is exist
	}, [afterClose]);

	//useEffet for  add customer
	useEffect(() => {
		if (addNewBranch[1].isSuccess) {
			closeModal();
			///after submit details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: addNewBranch[1].data?.message,
			});
		}
		if (addNewBranch[1].error) {
			setGetError(
				addNewBranch[1].error?.data?.error ||
					addNewBranch[1].error?.data?.message ||
					(addNewBranch[1].error.error && "server unreachable!") ||
					addNewBranch[1].error.data?.msg.sqlMessage
			);
		}
	}, [addNewBranch[1].isLoading]);

	//update branch details
	useEffect(() => {
		if (updateBranchDetails[1].isSuccess) {
			closeModal();
			///after submit details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateBranchDetails[1].data?.message,
			});
		}
		if (updateBranchDetails[1].error) {
			setGetError(
				updateBranchDetails[1].error?.data?.error ||
					updateBranchDetails[1].error?.data?.message ||
					(updateBranchDetails[1].error.error && "server unreachable!") ||
					updateBranchDetails[1].error.data?.msg.sqlMessage
			);
		}
	}, [updateBranchDetails[1].isLoading]);

	// useEffect for stateCountyList
	useEffect(() => {
		if (stateCountyListApi[1].isSuccess) {
			//storing new stateCountyLists base on vendor id
			setEnableStateCountyList(stateCountyListApi[1].data?.data);
			// setValues({ ...values, county_id_list: values.county_id_list });
			//county state names
			const counties = [];
			const newData = stateCountyListApi[1].data?.data[0].children.map(
				({ id, title, ...rest }) => {
					counties.push(rest.children);
					return { id: id, state_name: title };
				}
			);
			//geting counties id
			const county_id_list_list_val = [];

			counties.flat().map((ele) => {
				county_id_list_list_val.push(ele.key);
			});

			setProductStateList(newData);
			setProductCountyList(county_id_list_list_val);
		}
		if (stateCountyListApi[1].error) {
			setProductStateList([]);
			setProductCountyList([]);
			setGetError(
				stateCountyListApi[1].error?.data?.message ||
					(stateCountyListApi[1].error.error && "server unreachable!") ||
					stateCountyListApi[1].error.data?.msg.sqlMessage
			);
		}
	}, [stateCountyListApi[1].isLoading]);

	//useEffect for editCustomer
	useEffect(() => {
		if (editBranchQuery.isSuccess) {
			setCustomerTypeList(editBranchQuery.data?.customer_list);
			const { zip_five, zip_four, vendor_id, customer_id, ...othersData } =
				editBranchQuery.data?.data;
			//storing venodro id as permnent for for AppmultiSelect
			setVendorIdConst(String(vendor_id));
			setCustomerConst(String(customer_id));
			var newArr = {
				...othersData,
				zip_five: `${zip_five}${zip_four ? "-" + zip_four : ""}`,
				vendor_id: vendor_id,
				customer_id: customer_id,
				add_branch_contact: [],
				delete_contact: [],
				edit_branch_contact: [],
				edit_branch_product_price: [],
				delete_branch_product_price: [],
			};
			//request for countylist
			// const state_id = { state_id: editBranchQuery.data?.data?.state_id };

			setValues(newArr);
			//productList fecting for multiProducts
			branchProductController(customer_id);

			stateCountyIdList({
				id: editBranchQuery.data?.data?.customer_id,
				token: token,
			});
		}
		if (editBranchQuery.isError) {
			setGetError(
				editBranchQuery.error?.data?.message ||
					editBranchQuery.error.data ||
					(editBranchQuery.error.error && "server unreachable!") ||
					editBranchQuery.error.data?.msg.sqlMessage
			);
		}
	}, [editBranchQuery.isFetching]);

	//useEffect for customerList options
	useEffect(() => {
		if (customerListOptions[1].isSuccess) {
			setCustomerTypeList(customerListOptions[1].data?.data);
		}
		if (customerListOptions[1].isError) {
			setCustomerTypeList([]);
			setValues({ ...values, customer_id: "" });
			setEnableStateCountyList([{ children: [] }]);
		}
	}, [customerListOptions[1].isLoading]);

	//useEffect for editCustomer refecth
	useEffect(() => {
		if (editId) {
			editBranchQuery.refetch();
		}
		clearStatus();
		setCustomerTypeList([]);
	}, [afterClose, editId]);

	//variables area
	const isProductList = branchProductTypeApi[1]?.data?.data;

	const inputs = [
		{
			id: 1,
			name: "vendor_id",
			type: "select",
			value: values.vendor_id || "",
			placeholder: "Select Vendor",
			errorMessage: "Please select vendor!",
			label: "Vendor",
			options: vendorData,
			required: true,
			column: "3",
		},
		{
			id: 2,
			name: "customer_id",
			type: "select",
			value: values.customer_id || "",
			placeholder: "Select Customer Type",
			errorMessage: "Please select customer!",
			label: "Customer",
			options: customertypeList,
			required: true,
			column: "3",
		},
		{
			id: 3,
			name: "name",
			type: "text",
			placeholder: "Enter Branch Name",
			errorMessage: "Please enter branch name!",
			label: "Branch Name",
			required: true,
			column: "3",
		},
		{
			id: 4,
			name: "address_one",
			type: "text",
			column: "3",
			placeholder: "Enter Address 1",
			errorMessage: "Please enter address 1!",
			label: "Address 1",
			// required: true,
		},
		{
			id: 5,
			name: "address_two",
			type: "text",
			column: "3",
			placeholder: "Enter Address 2",
			label: "Address 2",
		},
		{
			id: 6,
			name: "state_id",
			type: "select",
			value: values.state_id || "",
			placeholder: "Select State",
			errorMessage: "Please select state!",
			label: "State",
			options: stateData,
			// required: true,
			column: "3",
		},
		{
			id: 944,
			name: "branch_email",
			_id: "branch_email",
			type: "email",
			value: values.branch_email || "",
			placeholder: "Enter Email",
			errorMessage: "Please enter valid email!",
			label: "Email",
			column: "3",
		},

		{
			id: 7,
			name: "city",
			type: "text",
			placeholder: "Enter City",
			errorMessage: "Please enter city!",
			label: "City",
			column: "3",
			// required: true,
		},

		{
			id: 12,
			name: "zip_five",
			type: "text",
			column: "3",
			placeholder: "Enter Zip Code",
			errorMessage: "Zip code must be min 5 digits!",
			label: "Zip Code",
			maxlength: "10",
			minlength: "5",
			// required: true,
		},
		{
			id: 15451,
			name: "website",
			type: "text",
			_id: "customer_website",
			placeholder: "Enter Website Name",
			label: "Website ",
			column: "3",
		},

		{
			id: 20,
			name: "is_active",
			checked: values.is_active || "",
			type: "switch",
			column: "3",
			label: "Active",
			// required: true,
			margin: "5",
		},
		{
			id: 22,
			name: "is_pricing_same_as_customer",
			type: "checkbox",
			checked: values.is_pricing_same_as_customer || "",
			label: "Is Pricing Same As Customer",
			required: true,
			column: "4",
			margin: "3",
		},

		//products type
		{
			id: 225,
			type: "multiselect",
		},
	];

	//check bbox
	const handleChange = (property, value) => {
		clearStatus();
		setValues({ ...values, [property]: value });
		if (property === "is_pricing_same_as_customer") {
			setValues({
				...values,
				[property]: value,
				branch_product_price: [],
				product_list_values: [],
			});
		}
		///
	};

	//select handler
	const selectHandler = (property, value) => {
		clearStatus();

		if (property === "vendor_id") {
			if (!value) {
				setValues({ ...values, [property]: value, customer_id: "" });
				setCustomerTypeList([]);
			} else {
				setValues({ ...values, [property]: value });
				customerOptions({ id: value, token: token });
			}
		}
		if (property === "state_id") {
			setValues({ ...values, [property]: value });
		}
		if (property === "customer_id") {
			//calling to stateCouny list
			if (value) {
				stateCountyIdList({
					id: value,
					token: token,
				});
				//productList fecting for multiProducts
				branchProductController(Number(value));
			}
			setValues({
				...values,
				product_list_values: [],
				customer_id: value,
			});
		}
	};
	const handleReset = (e) => {
		e.preventDefault();
		setValues(initialValues);
		if (editId) {
			editBranchQuery.refetch();
		}

		clearStatus();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (values.contact_list_values.length === 0) {
			setGetError("Please Add Contact");
		} else if (
			values.product_list_values.length === 0 &&
			!values.is_pricing_same_as_customer
		) {
			setGetError("Please select Product List");
		} else {
			removeBlank(values);

			//nested products loop
			const addproductPrincing = [];
			for (const key in multiProducts) {
				addproductPrincing.push(multiProducts[key]);
			}

			const newContactInfo = multiContacts?.map(({ temp_id, ...rest }) => {
				return rest;
			});

			//deleteing values base on products listed
			const addproductPriceList = addproductPrincing.flat().filter((e) => {
				return values?.product_list_values.includes(e?.product_type_id);
			});
			// if edit id not exists then add branch
			const zip_five = values.zip_five?.split("-");

			if (!editId && multiContacts?.length > 0) {
				addBranch({
					data: {
						...values,
						branch_product_price: addproductPriceList,
						contact_info: newContactInfo,
						zip_five: zip_five && Number(zip_five[0]),
						zip_four: zip_five && Number(zip_five[1]),
						vendor_id:!values.vendor_id?user?.vendor_id:values.vendor_id,
					},
					token: token,
				});
			}

			if (editId) {
				//deleteing values base on products listed
				const result = values.branch_product_price_values.filter((e) => {
					return !values?.product_list_values.includes(e.product_type_id);
				});

				const deleteProductsPrice = values?.delete_branch_product_price;
				await result.map((ele) => {
					deleteProductsPrice.push(String(ele.id));
				});

				//while adding new products
				const filterByReference = (arr1, arr2) => {
					let res = [];
					res = arr1.filter((el) => {
						return !arr2.find((element) => {
							return (
								element.product_type_id === el.product_type_id &&
								el.county_name === element.county_name
							);
						});
					});
					return res;
				};

				//adding new product pricing
				const addNewProduct = filterByReference(
					addproductPriceList,
					values.branch_product_price_values
				);

				const editBranchContact = values?.edit_branch_contact?.filter((ele) => {
					if (ele?.id !== undefined) {
						return ele;
					}
				});

				updateBranch({
					data: {
						...values,
						add_branch_product_price: addNewProduct,
						edit_branch_contact: editBranchContact,
						delete_branch_product_price: deleteProductsPrice,
						zip_five: zip_five && Number(zip_five[0]),
						zip_four: zip_five && Number(zip_five[1]),
						vendor_id:!values.vendor_id?user?.vendor_id:values.vendor_id,
					},
					id: editId,
					token: token,
				});
			}
		}
	};

	const onChange = (e) => {
		//buttons status
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
		if (name === "zip_five") {
			const value1 = value.replace(/[^0-9.]/g, "");
			var foo = value1.split("-").join("");
			if (foo.length > 0) {
				foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
			}
			setValues({ ...values, zip_five: foo });
		}
		//claser status values
		clearStatus();
	};

	//AppSwitch box
	const appSwitchHanler = (property, value) => {
		setValues({ ...values, [property]: value });
		clearStatus();
	};

	//error
	useEffect(() => {
		if (getError) {
			scrollComp("branchError");
		}
	}, [getError]);

	//multiproduct price base on customer
	function getProductName(id) {
		const filetrData = productTypeList.filter((ele) => {
			return ele.id === id;
		});
		return filetrData[0].name;
	}

	//loop for multiproducts
	var variables = [];
	values?.product_list_values?.forEach((ele) => {
		if (ele) {
			variables.push({
				[getProductName(Number(ele))]: [
					...values?.branch_product_price_values?.filter((item) => {
						return item?.product_type_id === Number(ele);
					}),
				],
				product_id: Number(ele),
			});
		}
	});

	return (
		<div className="position-relative">
			<div className="border-bottom border-2" id="branchError">
				<h5>{editId ? "Edit Branch" : "Add Branch"}</h5>
			</div>
			{getError && (
				<div className="mt-3">
					<Alert message={getError} type="error" showIcon />{" "}
				</div>
			)}
			<form onSubmit={handleSubmit} className>
				{(editBranchQuery.isFetching ||
					addNewBranch[1].isFetching ||
					updateBranchDetails[1].isFetching ||
					customerListOptions[1].isFetching) && <AppSpinner />}

				<div className={`${editBranchQuery.isFetching ? "invisible" : ""} `}>
					<Row>
						<div className="col-12 col-lg-9">
							<div className="row">
								{inputs.map((input) => (
									<>
										{input.type === "checkbox" && (
											<AppCheckbox {...input} handleChange={handleChange} />
										)}
										{(input.type === "text" || input.type === "email") &&
											input.name !== "phone_number" &&
											input.name !== "name" && (
												<FormInput
													key={input.id}
													{...input}
													defaultSet={afterClose}
													value={values[input.name]}
													onChange={onChange}
												/>
											)}
										{input.name === "name" &&
											tableColumn.map((item) =>
												item.branch ? (
													<FormInput
														key={input.id}
														{...input}
														defaultSet={afterClose}
														value={values[input.name]}
														onChange={onChange}
													/>
												) : (
													""
												)
											)}
										{input.name === "phone_number" && (
											<div className="col-12 col-md-3">
												<Row className="mt-3">
													<label className="ms-1 heading-5 text-dark ">
														{input.label}
													</label>
												</Row>
												<Input
													className="input-field mt-0 pb-2 w-100 heading-5"
													maxLength={14}
													minLength={14}
													placeholder={input.placeholder}
													defaultCountry="US"
													value={values.phone_number}
													onChange={(value) =>
														setValues({ ...values, phone_number: value })
													}
												/>
											</div>
										)}
										{/* //appSelcet for vendors name  */}
										{input.type === "select" &&
											input.name === "vendor_id" &&
											tableColumn.map((item) =>
												item.vendor ? (
													<>
														{
															//base on user field flags
														
																<AppSelect
																	key={input.id}
																	{...input}
																	defaultSet={afterClose}
																	onChange={selectHandler}
																	options={
																		<>
																			<option value={""}>Select Vendor</option>
																			{input.options.map((ele) => {
																				return (
																					<option
																						key={ele.vendor_id}
																						value={ele.vendor_id}
																					>
																						{ele.vendor_name}
																					</option>
																				);
																			})}
																		</>
																	}
																/>
															
														}
													</>
												) : (
													""
												)
											)}
										{/* //appSelcet for customer type name  */}
										{input.type === "select" &&
											input.name === "customer_id" &&
											tableColumn.map((item) =>
												item.customer ? (
													<AppSelect
														key={input.id}
														{...input}
														defaultSet={afterClose}
														onChange={selectHandler}
														options={
															<>
																<option value={""}>Select Customer</option>
																{customertypeList &&
																	input.options?.map((ele) => {
																		return (
																			<option key={ele.id} value={ele.id}>
																				{ele.name}
																			</option>
																		);
																	})}
															</>
														}
													/>
												) : (
													""
												)
											)}
										{/* //appSelcet for state  */}
										{input.type === "select" && input.name === "state_id" && (
											//base on user field flags
											<AppSelect
												key={input.id}
												{...input}
												defaultSet={afterClose}
												onChange={selectHandler}
												options={
													<>
														<option value={""}>Select State</option>
														{input.options?.map((ele) => {
															return (
																<option
																	data_name={ele.state_name}
																	key={ele.id}
																	value={ele.id}
																>
																	{ele.state_name}
																</option>
															);
														})}
													</>
												}
											/>
										)}
										{input.type === "switch" ? (
											<AppSwitch
												key={input.id}
												onChange={appSwitchHanler}
												{...input}
											/>
										) : (
											""
										)}
									</>
								))}
							</div>
						</div>

						<div className="col-12 col-lg-3">
							<label htmlFor="" className="mt-3  heading-5 text-dark">
								Active State and County<span className="text-danger">*</span>
							</label>
							{stateCountyListApi[1].isLoading && <AppSpinner />}
							<div
								className={`${
									stateCountyListApi[1].isLoading ? "invisible" : ""
								} `}
							>
								{!stateCountyListApi[1].isLoading &&
								Boolean(values.vendor_id) &&
								Boolean(values.customer_id) ? (
									<AppMultiSelect
										height={162}
										checkable={false}
										//default state
										defaultSet={afterClose}
										placeholder={"Search State/County"}
										treeData={enableStateCountyList}
										errorMessage={"State/County"}
										//here passing vendor id
										//1.vendorIdConst is permnent vendorId while it was storred in editCustomer query with setVendorIdConst
										//1.values.vendor_id is default vendorId while it was storred in editCustomer query
										expandCompare={{
											vendorIdConst,
											vendor_id: String(values["vendor_id"]),
										}}
										//initial values acceteps only ["5","6","7"] format
										initialVal={[]}
									/>
								) : (
									<div className="my-2">
										<Empty description={<span>No Active State County</span>} />
									</div>
								)}
							</div>
						</div>
					</Row>
					{!stateCountyListApi[1].isLoading && (
						<Row className="mt-3">
							{/* <Contacts /> */}
							<div className="col-12 px-2">
								<MultiContacts
									roleList={[6, 7, 12]}
									//while ediit ad update
									whileEdit={(value) => {
										setGetError(false);
										if (editId) {
											setValues({
												...values,
												add_branch_contact: [
													...values?.add_branch_contact,
													value,
												],
											});
										}
									}}
									//update
									updateContact={(value) => {
										setGetError(false);
										if (editId) {
											if (
												values.add_branch_contact?.length > 0 &&
												values.add_branch_contact?.find(
													(val) => val["temp_id"] === value.temp_id
												)
											) {
												values.add_branch_contact.forEach((item) => {
													if (item.temp_id === value.temp_id) {
														item.first_name = value.first_name;
														item.middle_name = value.middle_name;
														item.last_name = value.last_name;
														item.contact_email = value.contact_email;
														item.is_primary = value.is_primary;
														item.phone_number = value.phone_number;
														item.work_phone_number = value.work_phone_number;
														item.user_type_id = value.user_type_id;
														item.user_type_name = value.user_type_name;
														item.position = value.position;
													}
												});
											} else {
												setValues({
													...values,
													add_branch_contact: [
														...values?.add_branch_contact,
														value,
													],
												});
											}

											if (
												values.edit_branch_contact?.length > 0 &&
												values.edit_branch_contact?.find(
													(val) => val["id"] === value.id
												)
											) {
												values.edit_branch_contact.forEach((item) => {
													if (item.id === value.id) {
														item.first_name = value.first_name;
														item.middle_name = value.middle_name;
														item.last_name = value.last_name;
														item.contact_email = value.contact_email;
														item.is_primary = value.is_primary;
														item.phone_number = value.phone_number;
														item.work_phone_number = value.work_phone_number;
														item.user_type_id = value.user_type_id;
														item.user_type_name = value.user_type_name;
														item.position = value.position;
													}
												});
											} else {
												setValues({
													...values,
													edit_branch_contact: [
														...values?.edit_branch_contact,
														value,
													],
												});
											}
										}
									}}
									//delete record
									deleteContact={(value) => {
										setGetError(false);
										if (editId) {
											if (value) {
												setValues({
													...values,
													delete_contact: value?.id
														? [...values?.delete_contact, String(value?.id)]
														: values?.delete_contact,
													edit_branch_contact:
														values.edit_branch_contact?.filter((e) => {
															return (
																(e.id || e?.temp_id) !==
																(value.id || value?.temp_id)
															);
														}),
												});
											} else {
												setValues({
													...values,
													add_branch_contact: values.add_branch_contact?.filter(
														(e) => {
															return (
																(e.id || e?.temp_id) !==
																(value.id || value?.temp_id)
															);
														}
													),
												});
											}
										}
									}}
									//defualt close values
									afterClose={afterClose}
									initailValArr={editId ? values?.contact_list_values : []}
									addFormVal={(value) => {
										setGetError(false);
										setValues({ ...values, contact_list_values: value });
										setMultiContacts(value);
									}}
								/>
							</div>

							{!stateCountyListApi[1].isLoading &&
								!values.is_pricing_same_as_customer &&
								Boolean(values.vendor_id) &&
								Boolean(values.customer_id) && (
									<>
										<label className="ms-1 heading-5 mt-2 mb-2 text-dark ">
											<h6 className="fs-18 fw-bold me-2">
												Products <span className="text-danger">*</span>
											</h6>
										</label>
										{branchProductTypeApi[1]?.data?.data && isProductList ? (
											<div className="mb-2">
												<MyMultiSelect
													placeholder="Select Products"
													selectedCopmere={{
														oldId: customerConst,
														newId: String(values["customer_id"]),
													}}
													afterClose={afterClose}
													initailVal={editId ? values?.product_list_values : []}
													productGet={(value) => setProducts(value)}
													selectedValues={(value) => {
														setValues({
															...values,
															product_list_values: value,
														});
														setGetError(false);
													}}
													list={branchProductTypeApi[1]?.data?.data || []}
												/>
											</div>
										) : (
											<div className="ms-1 mb-2">
												<Alert
													message="Productlist not found!"
													type="error"
													showIcon
												/>
											</div>
										)}
									</>
								)}
							{
								variables?.map((items, index) => {
									return (
										<div key={index} className="col-12 px-2">
											{!stateCountyListApi[1].isLoading && isProductList && (
												<ProductTypeComp
													produt_id={items.product_id}
													stateList={productStateList}
													multiCountyListVal={productCountyList || []}
													title={String(Object.keys(items)[0])}
													updateContact={(value) => {
														if (editId) {
															if (
																values.edit_branch_product_price?.length > 0 &&
																values.edit_branch_product_price?.find(
																	(val) => val["id"] === value.id
																)
															) {
																values.edit_branch_product_price.forEach(
																	(item) => {
																		if (item.id === value.id) {
																			item.product_type_id =
																				value.product_type_id;
																			item.is_pre = value.is_pre;
																			item.is_auto = value.is_auto;
																			item.is_manual = value.is_manual;
																			item.password = value.password;
																			item.username = value.username;
																		}
																	}
																);
															} else {
																setValues({
																	...values,
																	edit_branch_product_price: [
																		...values?.edit_branch_product_price,
																		value,
																	],
																});
															}
														}
													}}
													//delete record
													deleteContact={(value) => {
														if (editId) {
															if (value?.id) {
																setValues({
																	...values,
																	delete_branch_product_price: [
																		...values?.delete_branch_product_price,
																		String(value.id),
																	],
																});
															}
														}
													}}
													getProductsList={products}
													addFormVal={(value) => {
														multiProducts[Object.keys(value)] =
															value[Object.keys(value)];
														setGetError(false);
													}}
													afterClose={afterClose}
													initailValArr={
														items[String(Object.keys(items)[0])] || []
													}
												/>
											)}
										</div>
									);
								})
								/* //multi productTypes  */
							}
						</Row>
					)}

					<div className="d-flex justify-content-center py-3">
						<div className="me-md-2 ">
							<MyButton
								onClick={handleReset}
								title={"CLEAR"}
								variant={"secondary-md"}
							/>
						</div>

						<MyButton title={"SUBMIT"} variant={"primary-md"} />
					</div>
				</div>
			</form>
		</div>
	);
};
