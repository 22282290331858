import AppTable from "../../../Components/AppTable/AppTable";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import SearchColumns from "../../../Components/SearchColumns";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { useEffect, useState, useMemo } from "react";
import { Input, Button, Alert, Row, notification, Space } from "antd";
import { Excel } from "antd-table-saveas-excel";
import { useSelector } from "react-redux";
import { useStatusListQuery } from "../../../Redux/Services/status";
import { useOrdersListQuery } from "../../../Redux/Services/Orders";
import AppButton from "../../../Components/AppButton/AppButton";
import { Form } from "react-bootstrap";
import { Drawer } from "antd";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { FiRefreshCcw } from "react-icons/fi";
import { BiX } from "react-icons/bi";
import {
	useCustomerListQuery,
	useCustomerOptionsMutation,
} from "../../../Redux/Services/Customers";
import { useBranchOptionsMutation } from "../../../Redux/Services/Branch";
import { useHoaStatusListQuery } from "../../../Redux/Services/Hoa";
import {scrollComp } from "../../../Controllers/Functions";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import { useOrderReportFilterListMutation } from "../../../Redux/Services/ReportsListApi";
import { useUsersListQuery } from "../../../Redux/Services/User";
import { useOrderStatusQuery } from "../../../Redux/Services/status";
import { addDays } from "date-fns";
import "../Reports.css";
import AppDateRangePicker from "../../../Components/AppDateRangePicker/AppDateRangePicker";

export const OrdersReport = ({ refresh }) => {
	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: "selection",
		},
	]);

	const [data, setData] = useState([]);
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(null);
	const [getError, setGetError] = useState(false);
	const [, setStatusColor] = useState([]);
	const [, setColumns] = useState([]);
	const [hoaStatusOptions, setHoaStatusOptions] = useState([]);
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
	//calling redux stor for vendors list
	const { token,} = useSelector((state) => state.auth);
	const [, setBranchListData] = useState([]);
	const [customertypeList, setCustomerTypeList] = useState([]);
	const [countyOptions, setCountyOptions] = useState([
		{ id: "", county_name: "" },
	]);
	const [initialValues] = useState({
		start_date: "",
		end_date: "",
		customer_id: "",
		user_id: "",
		state_id: "",
		county_id: "",
		// integration_id: "",
		status_id: "",
		hoa_status_id: "",
		tat: false,
	});
	const [values, setValues] = useState(initialValues);
	const [userOptions, setUserOptions] = useState([]);
	const [taxStatusOptions, setTaxStatusOptions] = useState([]);
	const [istat, setTat] = useState(false);
	//calling redux stor for vendors list
	
	//calling rtk query hook for customer-list options
	const customerListOptions = useCustomerOptionsMutation();
	// const [customerOptions] = customerListOptions;

	//calling rtk query hook for branch-list options
	const branchListOptions = useBranchOptionsMutation();
	// const [branchOptions] = branchListOptions;

	//calling redux stor for vendors list
	const { stateList } = useSelector((state) => state.stateList);

	//calling redux stor for vendors list
	// const { countyList } = useSelector((state) => state.countyList);

	//calling rtk query hook for geting County list
	const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;

	//calling rtk query for batchcadlist query
	const userList = useUsersListQuery(token);
	useEffect(() => {
		if (userList.isSuccess) {
			setUserOptions(userList.data?.data);
		}
	}, [userList.isFetching]);

	//calling rtk query for tax-statuslist
	const taxStatusList = useOrderStatusQuery(token);
	useEffect(() => {
		if (taxStatusList.isSuccess) {
			setTaxStatusOptions(taxStatusList.data?.data);
		}
	}, [taxStatusList.isFetching]);

	const Customer = useCustomerListQuery(token);
	useEffect(() => {
		if (Customer.isSuccess) {
			setCustomerTypeList(Customer.data?.data);
		}
	}, [Customer.isFetching]);

	// useEffect for stateslist
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyOptions(countyList2[1].data?.data);
		}
		if (countyList2[1].error) {
			setGetError(
				countyList2[1].error?.data?.error ||
					countyList2[1].error?.data?.message ||
					countyList2[1].error.data?.data?.sqlMessage ||
					(countyList2[1].error.error && "server unreachable!")
			);
		}
	}, [countyList2[1].isLoading]);

	//useEffect for setting default values
	useEffect(() => {
		setStateData(stateList);
		setCountyOptions([]);
		setState([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		]);
	}, []);

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	//status list for clor
	const statusColorApi = useStatusListQuery(token);
	// order list query
	const ordersListApi = useOrdersListQuery(token);

	useEffect(() => {
		if (ordersListApi.isSuccess) {
			setColumns({ columns: Object.keys(ordersListApi.data.data[0]) });
			const newData = ordersListApi.data?.data?.map(
				({
					id,
					county_name,
					branch_name,
					customer_name,
					state_name,
					address,
					status,
					user_name,
					user_id,
					closer_name,
					legal_description,
					order_number,
					notice_name,
					created,
					researcher,
					file_number,
					examiner_id,
					updated_at,
					completion_date,
					tat,
					...others
				}) => {
					return {
						...others,
						id: id,
						address: address || "NA",
						customer_name: customer_name || "NA",
						state_name: state_name || "NA",
						branch_name: branch_name || "NA",
						county_name: county_name || "NA",
						status: status || "NA",
						closer: closer_name || "NA",
						legal: legal_description || "NA",
						notice_name: notice_name || "NA",
						created: created || "NA",
						researcher: researcher || "NA",
						user_name: user_name || "NA",
						user_id: user_id || "NA",
						order_number: order_number || "NA",
						file_number: file_number || "NA",
						examiner_id: examiner_id || "NA",
						updated_at: updated_at || "NA",
						completion_date: completion_date || "NA",
						tat: tat || "NA",
					};
				}
			);
			setData(newData);
		} else {
			setData([]);
		}
	}, [ordersListApi.isFetching]);

	useEffect(() => {
		if (statusColorApi.isSuccess) {
			setStatusColor(statusColorApi.data?.data);
		} else {
			setStatusColor([]);
		}
	}, [statusColorApi.isFetching]);

	//api hoa status list
	const HoaStatus = useHoaStatusListQuery(token);

	// useEffect for status list
	useEffect(() => {
		if (HoaStatus.isSuccess) {
			setHoaStatusOptions(HoaStatus.data?.data);
		}
		if (HoaStatus.isError) {
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					HoaStatus.error?.data?.message ||
					HoaStatus.error.error ||
					HoaStatus.error.data?.data?.sqlMessage,
			});

			setHoaStatusOptions([]);
		}
		//global spinner
	}, [HoaStatus.isFetching]);

	const Excelcolumns = [
		{
			title: "Order Number",
			dataIndex: "order_number",
			key: "order_number",
		},
		{
			title: "Customer",
			dataIndex: "customer_name",
			key: "customer_name",
		},
		{
			title: "County",
			dataIndex: "county_name",
			key: "county_name",
		},
		{
			title: "GF Number",
			dataIndex: "file_number",
			key: "file_number",
		},
		{
			title: "Branch",
			dataIndex: "branch_name",
			key: "branch_name",
		},
		{
			title: "User Id",
			dataIndex: "user_id",
			key: "user_id",
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
		},
		{
			title: "Examiner Id",
			dataIndex: "examiner_id",
			key: "examiner_id",
		},
		{
			title: "Legal",
			dataIndex: "legal",
			key: "legal",
		},
		{
			title: "Researcher",
			dataIndex: "researcher",
			key: "researcher",
		},
		{
			title: "Closer",
			dataIndex: "closer",
			key: "closer",
		},
		{
			title: "Notice Name",
			dataIndex: "notice_name",
			key: "notice_name",
		},
		{
			title: "Parcel Count",
			dataIndex: "parcel_count",
			key: "parcel_count",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Created /Opened Date",
			dataIndex: "created",
			key: "created",
		},
		{
			title: "Modify Date",
			dataIndex: "updated_at",
			key: "updated_at",
		},
		{
			title: "Completed Date",
			dataIndex: "completion_date",
			key: "completion_date",
		},
		{
			title: "TAT",
			dataIndex: "tat",
			key: "tat",
		},
	];

	const onClose = () => {
		setOpen(false);
		setValues(initialValues);
		setCountyOptions([]);
		setState([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		]);
	};

	const btnStyle = { color: "#cacaca" };

	//columns

	const columns = [
		{
			title: (
				<span>
					GF Number{" "}
					{getsortfield === "file_number" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "file_number" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "file_number" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "file_number",
			key: "file_number",
			sorter: (a, b) => a.file_number.localeCompare(b.file_number),
			width: 200,
			align: "center",
			...SearchColumns("file_number", "GF Number"),
		},
		{
			title: (
				<span>
					Customer{" "}
					{getsortfield === "customer_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "customer_name",
			key: "customer_name",
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			width: 200,
			align: "center",
			...SearchColumns("customer_name", "Customer Name"),
		},
		{
			title: (
				<span>
					Date Opened / Created{" "}
					{getsortfield === "created" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "created" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "created" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "created",
			key: "created",
			sorter: (a, b) => a.created.localeCompare(b.created),
			width: 200,
			align: "center",
			...SearchColumns("created", "Date Opened"),
		},
		{
			title: (
				<span>
					Modify Date{" "}
					{getsortfield === "updated_at" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "updated_at" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "updated_at" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
			width: 200,
			align: "center",
			...SearchColumns("updated_at", "Modify Date"),
		},
		{
			title: (
				<span>
					Date Completed{" "}
					{getsortfield === "completion_date" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
					  getsortfield === "completion_date" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
					  getsortfield === "completion_date" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "completion_date",
			key: "completion_date",
			sorter: (a, b) => a.completion_date.localeCompare(b.completion_date),
			width: 200,
			align: "center",
			...SearchColumns("completion_date", "Date Completed"),
		},
		{
			title: (
				<span>
					TAT{" "}
					{getsortfield === "tat" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "tat" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "tat" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "tat",
			key: "tat",
			sorter: (a, b) => a.tat.localeCompare(b.tat),
			width: 200,
			align: "center",
			render: (value) => {
				return <>{value}</>;
			},
			...SearchColumns("tat", "TAT"),
		},
	];
	//calling rtk query for filter orders
	const [orderFilterList, OrderFilterListResult] =
		useOrderReportFilterListMutation();
	useEffect(() => {
		if (OrderFilterListResult.isSuccess) {
			const newData = OrderFilterListResult.data?.data?.map(
				({
					id,
					county_name,
					branch_name,
					customer_name,
					state_name,
					address,
					status,
					user_name,
					user_id,
					closer_name,
					legal_description,
					order_number,
					notice_name,
					created,
					researcher,
					file_number,
					examiner_id,
					updated_at,
					completion_date,
					tat,
					...others
				}) => {
					return {
						...others,
						id: id,
						address: address || "NA",
						customer_name: customer_name || "NA",
						state_name: state_name || "NA",
						branch_name: branch_name || "NA",
						county_name: county_name || "NA",
						status: status || "NA",
						closer: closer_name || "NA",
						legal: legal_description || "NA",
						notice_name: notice_name || "NA",
						created: created || "NA",
						researcher: researcher || "NA",
						user_name: user_name || "NA",
						user_id: user_id || "NA",
						order_number: order_number || "NA",
						file_number: file_number || "NA",
						examiner_id: examiner_id || "NA",
						updated_at: updated_at || "NA",
						completion_date: completion_date || "NA",
						tat: tat == 0 ? "0" : tat || "NA",
					};
				}
			);
			setData(newData);
		} else {
			setData([]);
		}
	}, [OrderFilterListResult.isLoading]);

	// useEffect(() => {
	// 	ordersListApi.refetch();
	// 	statusColorApi.refetch();
	// }, []);

	useEffect(() => {
		setSearch("");
		if (refresh === "OrderReport") {
			ordersListApi.refetch();
			setCountyOptions([]);
			setState([
				{
					startDate: new Date(),
					endDate: new Date(),
					key: "selection",
				},
			]);
		}
	}, [refresh]);

	const inputs = [
		{
			id: 1,
			name: "start_date",
			type: "date",
			value: values.start_date || "",
			placeholder: "Select Date",
			errorMessage: "Please select Start Date!",
			label: "Start Date",
			column: "6",
			required: values.end_date !== "" ? true : false,
		},
		{
			id: 2,
			name: "end_date",
			type: "date",
			value: values.end_date || "",
			placeholder: "Select Date",
			errorMessage: "Please select End Date!",
			label: "End Date",
			column: "6",
			required: values.start_date !== "" ? true : false,
		},
		{
			id: 6,
			name: "state_id",
			type: "select",
			value: values.state_id || "",
			placeholder: "Select State",
			errorMessage: "Please select state!",
			label: "State",
			options: stateData,
			required: true,
			column: "12",
			required: false,
		},
		{
			id: 7,
			name: "county_id",
			type: "select",
			value: values.county_id || "",
			placeholder: "Select County",
			errorMessage: "Please select county!",
			label: "County",
			options: countyOptions,
			column: "12",
			required: false,
		},
		{
			id: 4,
			name: "customer_id",
			type: "select",
			value: values.customer_id || "",
			placeholder: "Select Customer Type",
			errorMessage: "Please select customer!",
			label: "Customer",
			options: customertypeList,
			column: "12",
			required: false,
		},
		{
			id: 7,
			name: "user_id",
			type: "select",
			value: values.user_id || "",
			placeholder: "Select Employee",
			errorMessage: "Please select employee!",
			label: "Employee",
			options: userOptions,
			column: "12",
			required: false,
		},
		{
			id: 3,
			name: "status_id",
			type: "select",
			value: values.status_id || "",
			placeholder: "Select Tax Status",
			errorMessage: "Please select tax status!",
			label: "Tax Status",
			options: taxStatusOptions,
			required: false,
			column: "12",
		},
		{
			id: 3,
			name: "hoa_status_id",
			type: "select",
			value: values.hoa_status_id || "",
			placeholder: "Select HOA Status",
			errorMessage: "Please select hoa status!",
			label: "HOA Status",
			options: hoaStatusOptions,
			required: false,
			column: "12",
		},
		{
			id: 51,
			name: "tat",
			checked: values.tat || "",
			type: "switch",
			column: "12",
			label: "TAT",
			margin: "3",
		},
	];

	//clear error function
	function clearStatus() {
		setGetError(null);
	}

	// const onChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setValues({
	// 		...values,
	// 		[name]: value,
	// 	});
	// 	// if(name==="from_date"){
	// 	//   orderFilterList({
	// 	//     data:{user_id:Number(values.user_id), start_date:value},
	// 	//      token:token,
	// 	//    })
	// 	// }
	// };

	//AppSwitch box
	const appSwitchHanler = (property, value) => {
		setValues({ ...values, [property]: value });
		if (property === " tat") {
			setValues({ ...values, [property]: value });
		}
		console.log("values", values);
		clearStatus();
	};

	//select handler
	const selectHandler = (property, value) => {
		// clearStatus();
		setValues({
			...values,
			[property]: Number(value),
		});

		if (property === "customer_id") {
			if (Number(value) !== 0) {
				// orderFilterList({
				// 	data: {
				// 		user_id: Number(values.user_id),
				// 		customer_id: Number(value),
				// 		branch_id: Number(values.branch_id),
				// 		start_date: values.start_date,
				// 		end_date: values.end_date,
				// 	},
				// 	token: token,
				// });
				setValues({
					...values,
					[property]: Number(value),
				});
			} else {
				setValues({
					...values,
					[property]: null,
				});
				setBranchListData([]);
				ordersListApi.refetch();
			}
		}

		if (property === "user_id") {
			setValues({
				...values,
				[property]: Number(value),
			});
		}
		if (property === "state_id") {
			const state_id = { state_id: Number(value) };
			if (value) {
				getCountyList({ state_id, token: token });
				setValues({
					...values,
					[property]: Number(value),
					county_id: "",
				});
			} else {
				setCountyOptions([]);
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setOpen(false);
		// setValues(initialValues);
		if (values.tat) setTat(true);
		else setTat(false);
		// e.target.reset();
		if (
			values.customer_id ||
			values.start_date ||
			values.end_date ||
			values.county_id ||
			values.state_id ||
			values.user_id ||
			values.hoa_status_id ||
			values.status_id ||
			values.tat
		) {
			orderFilterList({
				data: {
					customer_id: Number(values.customer_id),
					start_date: values.start_date,
					end_date: values.end_date,
					user_id: Number(values.user_id),
					state_id: Number(values.state_id),
					county_id: Number(values.county_id),
					status_id: Number(values.status_id),
					hoa_status_id: Number(values.hoa_status_id),
					tat: values.tat,
				},
				token: token,
			});
		}
	};

	const handleReset = (e) => {
		e.preventDefault();
		setTat(false);
		setValues(initialValues);
		setCountyOptions([]);
		ordersListApi.refetch();
		setState([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		]);

		// setOpen(false);
		//   e.target.reset();
	};
	const Onclose = () => {
		setValues(initialValues);
		setCountyOptions([]);
		ordersListApi.refetch();
		setState([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		]);
		setOpen(false);
	};

	//useEffect for branchList options
	useEffect(() => {
		if (branchListOptions[1].isSuccess) {
			setBranchListData(branchListOptions[1].data?.data);
		}
		if (branchListOptions[1].isError && customerListOptions[1].isSuccess) {
			setBranchListData([]);
		}
		if (branchListOptions[1].isError) {
			setValues({
				...values,
				branch_id: "",
			});
		}
	}, [branchListOptions[1].isLoading]);
	//error
	useEffect(() => {
		if (getError) {
			scrollComp("OdersReportError");
		}
	}, [getError]);

	const handleClick = () => {
		const excel = new Excel();
		excel
			.addSheet("test")
			.addColumns(
				istat
					? Excelcolumns
					: Excelcolumns.filter((col) => col.dataIndex !== "tat")
			)
			.addDataSource(data, {
				str2Percent: true,
			})
			.saveAs("OrderReport.xlsx");
	};
	const cols = useMemo(() => columns, [columns]);
	const data1 = useMemo(() => data, [data]);

	function convertDate(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	}

	return (
		<>
			<div id="OdersReportError">
				{getError && <Alert message={getError} type="error" showIcon />}
			</div>
			{(ordersListApi.isFetching || OrderFilterListResult.isLoading) && (
				<AppSpinner />
			)}

			<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
				{/* changing input values globaly  */}
				<>
					<div
						className="file-icons"
						data-toggle="tooltip"
						title="Excel Export"
					>
						<Button size="small" type="primary" onClick={handleClick}>
							Export Data
						</Button>
					</div>
					<div className=" me-1 w-25 ">
						<Input
							placeholder="Search here"
							className="global-search "
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							prefix={<FaSearch color="lightgray" />}
						/>
					</div>
					<div className="filter-button ">
						<Button
							onClick={() => setOpen(true)}
							type={open ? "primary" : "default"}
						>
							<FiFilter color={open ? "#fff" : ""} />{" "}
							<span className="ms-1">Filter</span>
						</Button>
					</div>
					<FiRefreshCcw
						type="button"
						id="template"
						onClick={() =>
						{
							setValues(initialValues);
							ordersListApi.refetch();
							setTat(false);
							setState([
								{
									startDate: new Date(),
									endDate: new Date(),
									key: "selection",
								},
							]);
						}}
						title="Refresh"
						size={20}
					/>
				</>
			</div>
			<div>
				<div className="filter-drawer">
					<Drawer
						title={"Select Filter"}
						placement="right"
						onClose={Onclose}
						maskClosable={true}
						open={open}
						destroyOnClose={true}
						closable={false}
						className="order view-parcel"
						extra={
							<Space>
								<BiX size={20} style={btnStyle} onClick={onClose} />
							</Space>
						}
					>
						<Form onSubmit={handleSubmit} action="">
							{(countyList2[1].isLoading ||
								branchListOptions[1].isLoading ||
								customerListOptions[1].isLoading) && <AppSpinner />}
							<div className=" mx-2">
								<Row>
									<div className="col-12 ">
										<AppDateRangePicker
											label="Select Date Range"
											onChange={(item) => {
												setState([item.selection]);
												const start_date = convertDate(
													item.selection.startDate
												);
												const end_date = convertDate(item.selection.endDate);
												setValues({
													...values,
													start_date: start_date,
													end_date: end_date,
												});
											}}
											ranges={state}
											months={1}
										/>
									</div>
									{inputs.map((input) => (
										<>
											{/* {input.type === "date" && input.name === "start_date" && (
												<AppInput
													key={input.id}
													{...input}
													value={values[input.name]}
													max={values.end_date}
													onChange={onChange}
												/>
											)}
											{input.type === "date" && input.name === "end_date" && (
												<AppInput
													key={input.id}
													{...input}
													value={values[input.name]}
													min={values.start_date}
													onChange={onChange}
												/>
											)} */}

											{/* Filter based on state */}
											{input.type === "select" && input.name === "state_id" && (
												<AppSelect
													key={input.id}
													{...input}
													// defaultSet={afterClose}
													onChange={selectHandler}
													options={
														<>
															<option value={""}>Select State</option>
															{input.options.map((ele) => {
																return (
																	<option key={ele.id} value={ele.id}>
																		{ele.state_name}
																	</option>
																);
															})}
														</>
													}
												/>
											)}
											{/* Filter based on County */}

											{input.type === "select" && input.name === "county_id" && (
												<AppSelect
													key={input.id}
													{...input}
													// defaultSet={afterClose}
													onChange={selectHandler}
													options={
														<>
															<option value={""}>Select County</option>
															{input.options.map((ele) => {
																return (
																	<option key={ele.id} value={ele.id}>
																		{ele.county_name}
																	</option>
																);
															})}
														</>
													}
												/>
											)}

											{/* //appSelcet for customer type name  */}
											{input.type === "select" &&
												input.name === "customer_id" && (
													//based on user field flags
													<AppSelect
														key={input.id}
														{...input}
														onChange={selectHandler}
														// defaultSet={afterClose}
														options={
															<>
																<option value={""}> Select Customer</option>
																{input?.options?.map((ele) => {
																	return (
																		<option key={ele.id} value={ele.id}>
																			{ele.name}
																		</option>
																	);
																})}
															</>
														}
													/>
												)}
											{/* Filter Based on Employee(User) */}
											{input.type === "select" && input.name === "user_id" && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													// defaultSet={afterClose}
													options={
														<>
															<option value={""}>Select Employee</option>
															{input?.options?.map((ele) => {
																return (
																	<option key={ele.id} value={ele.id}>
																		{ele.first_name}
																	</option>
																);
															})}
														</>
													}
												/>
											)}
											{/* Filter Based on Integrartion */}
											{input.type === "select" &&
												input.name === "integration_id" && (
													//based on user field flags
													<AppSelect
														key={input.id}
														{...input}
														onChange={selectHandler}
														// defaultSet={afterClose}
														options={
															<>
																<option value={""}>Select Integrartion</option>
																{input?.options?.map((ele) => {
																	return (
																		<option key={ele.id} value={ele.id}>
																			{ele.first_name}
																		</option>
																	);
																})}
															</>
														}
													/>
												)}
											{/* Filter Based on Tax Status */}
											{input.type === "select" && input.name === "status_id" && (
												//based on user field flags
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													// defaultSet={afterClose}
													options={
														<>
															<option value={""}>Select Tax Status</option>
															{input?.options?.map((ele) => {
																return (
																	<option key={ele.id} value={ele.id}>
																		{ele.name}
																	</option>
																);
															})}
														</>
													}
												/>
											)}
											{/* Filter Based on HOA Status */}
											{input.type === "select" &&
												input.name === "hoa_status_id" && (
													<AppSelect
														key={input.id}
														{...input}
														onChange={selectHandler}
														// defaultSet={afterClose}
														options={
															<>
																<option value={""}>Select HOA Status</option>
																{input?.options?.map((ele) => {
																	return (
																		<option key={ele.id} value={ele.id}>
																			{ele.name}
																		</option>
																	);
																})}
															</>
														}
													/>
												)}
											{input.type === "switch" && (
												<AppSwitch
													className="m-3"
													key={input.id}
													onChange={appSwitchHanler}
													{...input}
												/>
											)}
										</>
									))}
								</Row>
							</div>
							<div className="d-flex justify-content-center pb-3 pt-2 mt-4">
								<div className="me-md-2 ">
									<AppButton
										onClick={handleReset}
										title={"CLEAR"}
										variant={"secondary-md"}
									/>
								</div>
								<AppButton
									className="search-btn"
									disabled={
										values.start_date ||
										values.end_date ||
										values.state_id ||
										values.county_id ||
										values.customer_id ||
										values.status_id ||
										values.hoa_status_id ||
										values.tat ||
										values.user_id
											? false
											: true
									}
									title={"SEARCH"}
									variant={"primary-md"}
									type={"submit"}
								/>
							</div>
						</Form>
					</Drawer>
				</div>
			</div>
			<div style={{ clear: "both" }}></div>

			<AppTable
				sort={sort}
				loading={ordersListApi.isFetching || statusColorApi.isFetching}
				search={search}
				columns={istat ? cols : cols.filter((col) => col.dataIndex !== "tat")}
				dataSource={data1}
			/>
		</>
	);
};
