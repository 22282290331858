import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const stateCounty = createApi({
  reducerPath: "stateCounty",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    stateCounty: builder.query({
      query: (token) => ({
        url: `/state-county-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    stateCountyIdList: builder.mutation({
      query: (data) => (
        {
          url: `/enabled-state-county-vendor/${data.id}`,
          mode: "cors",
          method:"GET",
          headers: { Authorization: `Bearer ${data.token}` },
        }
      ),
    }),
  }),
});

export const { useStateCountyQuery, useStateCountyIdListMutation } = stateCounty;
