import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const thirdPartyDocuments = createApi({
  reducerPath: "thirdPartyDocuments",
  tagTypes: ["thirdPartyDocuments"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addThirdPartyDoc: builder.mutation({
      query: (data) => ({
        url: `/add-third-party-document`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["thirdPartyDocuments"],
    }),
    ThirdPartyDocList: builder.query({
      query: (token) => ({
        url: `/third-party-document-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["thirdPartyDocuments"],
    }),

    editThirdPartyDoc: builder.query({
      query: (data) => ({
        url: `/edit-third-party-document/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["thirdPartyDocuments"],
    }),
    updateThirdPartyDoc: builder.mutation({
      query: (data) => ({
        url: `/update-third-party-document/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["thirdPartyDocuments"],
    }),
  }),
});

export const {
    useAddThirdPartyDocMutation,
    useThirdPartyDocListQuery,
    useUpdateThirdPartyDocMutation,
    useEditThirdPartyDocQuery
} = thirdPartyDocuments;
