import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const state = createApi({
  reducerPath: "state",
  tagTypes: ["state"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    getStates: builder.query({
      query: (token) => ({
        url: `/state-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["state"],
    }),
    getStatesBaseOnCustomer: builder.mutation({
      query: (data) => ({
        url: `/order-customer-wise-state-list/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["state"],
    }),
  }),
});

export const { useGetStatesQuery, useGetStatesBaseOnCustomerMutation } = state;
