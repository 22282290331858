import React from "react";
import "./Tools.css";
import ToolsTab from "./ToolsTab";
import { useSelector } from "react-redux";

export default function Tools() {
  //redux store for labels
  const { labelList } = useSelector((state) => state.labelList);
  return (
    <>
      <h5 className="settings-container">
        {labelList["cs.settings.header"]} |{" "}
        {labelList["cs.dashboard.menu.tools"]}
      </h5>
      <div>
        <ToolsTab />
      </div>
    </>
  );
}
