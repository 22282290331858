import { createSlice } from "@reduxjs/toolkit";

const userTypeList = createSlice({
  name: "userTypeList",
  initialState: {
    userTypeList: [],
  },
  reducers: {
    userTypeListAction: (state, action) => {
      state.userTypeList = action.payload;
    },
  },
});

export const {userTypeListAction} = userTypeList.actions;

export default userTypeList.reducer;
