import React, { useEffect, useState } from "react";
import AppTable from "../../../Components/AppTable/AppTable";
import {
	FaPlus,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import AppModal from "../../../Components/AppModal/AppModal";
import { LabelModal } from "../Labels/LabelModal";
import { Form, Input, notification } from "antd";
import "./label.css";
import { useSelector } from "react-redux";
import {
	useLabelListQuery,
	useUpdateLabelMutation,
} from "../../../Redux/Services/Labels";
import SearchColumns from "../../../Components/SearchColumns";

export default function Labels({ refresh }) {
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState();
	const [editingKey] = useState("");
	const isEditing = (record) => record.id === editingKey;
	const [form] = Form.useForm();
	const [search, setSearch] = useState("");
	const [initialState, setInitialState] = useState(false);
	// const [editable, setEditable] = useState(true);

	//set state for ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//to close the modal
	function closeModal(data) {
		setShowHide(data);
	}

	function afterClose() {
		setInitialState(!initialState);
	}

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	//use redux store for auth
	const { labelModule, edit_keylabels, edit_valuelabels } = useSelector(
		(state) => state.flagRoles
	);

	//label list fetch
	const { token } = useSelector((state) => state.auth);
	const labelListFetch = useLabelListQuery(token);

	//calling rtk query for update labels
	const updatelabelData = useUpdateLabelMutation();
	// const [updateLabel] = updatelabelData;

	const columns = [
		{
			title: (
				<span>
					Key{" "}
					{getsortfield === "label_key" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "label_key" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="sort-svg text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "label_key" ? (
						<span className="sort-svg">
							<FaSortAmountDown className=" sort-svg text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "label_key",
			key: "label_key",
			sorter: (a, b) => a.label_key.localeCompare(b.label_key),
			width: 800,
			...SearchColumns("label_key", "Label Key"),
			// only super admin(user-type-id-1) & vendor admin(user-type-id-2) have the permission for editing label key
			editable: edit_keylabels,
		},
		{
			title: (
				<span>
					Value{" "}
					{getsortfield === "label_value" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "label_value" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="sort-svg text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "label_value" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="sort-svg text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "label_value",
			key: "label_value",
			width: 200,
			align: "center",
			...SearchColumns("label_value", "Label Value"),
			// only super admin(user-type-id-1) , vendor admin(user-type-id-2) and customer admin(user-type-id:3) have the permission for editing label value
			editable: edit_valuelabels,
			sorter: (a, b) => a.label_value.localeCompare(b.label_value),
		},

		// {
		//   title: "Action",
		//   dataIndex: "operation",
		//   width: 150,
		//   align: "center",
		//   render: (_, record) => {
		//     const editable = isEditing(record);
		//     return editable ? (
		//       <span>
		//         <Typography.Link
		//           onClick={() => save(record.id)}
		//           style={{
		//             marginRight: 8,
		//           }}
		//         >
		//           <button className="btn btn-primary edit-buttons"> Save</button>
		//         </Typography.Link>
		//         <Typography.Link>
		//           <button onClick={cancel} className="btn btn-danger edit-buttons">
		//             Cancel
		//           </button>
		//         </Typography.Link>
		//       </span>
		//     ) : (
		//       <>
		//         <span disabled={editingKey !== ""} onClick={() => edit(record)}>
		//           <EditOutlined
		//             style={{ cursor: "pointer" }}
		//             onClick={editHandler}
		//             size={50}
		//           />
		//         </span>
		//       </>
		//     );
		//   },
		// },
	];


	//useEfffect for creating for when label list is loaded
	useEffect(() => {
		if (labelListFetch.isSuccess) {
			setData(labelListFetch.data?.data);
		}
	}, [labelListFetch, search]);
	//update label details
	useEffect(() => {
		if (updatelabelData[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updatelabelData[1].data?.message,
			});
		}
		if (updatelabelData[1].error) {
			/// erros details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updatelabelData[1].error.data.message ||
					updatelabelData[1].error.error ||
					updatelabelData[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updatelabelData[1].isLoading]);

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "labels") {
			labelListFetch.refetch();
		}
	}, [refresh]);

	return (
		<>
			<div className="d-flex align-items-center float-end w-25 mb-1 ">
				<AppModal
					showModal={showHide}
					onClose={closeModal}
					afterClose={afterClose}
					width={700}
					height={500}
					element={
						<LabelModal setShowHide={setShowHide} afterClose={initialState} />
					}
				/>

				{/* changing input values globaly  */}
				<div className=" me-1 w-100 ">
					<Input
						placeholder="Search here"
						className="global-search "
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						prefix={<FaSearch color="lightgray" />}
					/>
				</div>
				{/* only super admin has the permission to add labels */}
				{labelModule.map((item,index) => {
					if (item.add_button) {
						return (
							<div key={index} className="add_icon">
								<FaPlus onClick={() => setShowHide(true)} />
							</div>
						);
					}
					return null;
				})}
			</div>
			<div style={{ clear: "both" }}></div>
			<AppTable
				sort={sort}
				loading={updatelabelData[1].isLoading || labelListFetch.isLoading}
				search={search}
				isEditing={isEditing}
				form={form}
				columns={columns}
				dataSource={data}
			/>
		</>
	);
}
