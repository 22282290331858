import React, { useState } from "react";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import NormalTable from "../../../Components/AppTable/AppTable";
import SearchColumns from "../../../Components/SearchColumns";
import { Excel } from "antd-table-saveas-excel";
import { Button } from "antd";
export default function AuditLogs({ data = [], title = "" }) {
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const columns = [
    {
      title: <span>Description</span>,
      dataIndex: "changes",
      key: "changes",
      align: "center",
      width: 300,
    },
    {
      title: <span>Old Value</span>,
      dataIndex: "old_value",
      key: "old_value",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
    },
    {
      title: <span>New Value</span>,
      dataIndex: "new_value",
      key: "new_value",
      align: "center",
    },
    {
      title: (
        <span>
          Date{" "}
          {getsortfield === "date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "date" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "date" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      width: 200,
      align: "center",
      ...SearchColumns("date", "Date"),
    },

    {
      title: <span>Updated By</span>,
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      width: 200,
    },
  ];
  const exportscolumns = [
    {
      title: "Description",
      dataIndex: "changes",
      key: "changes",
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Updated By",
      dataIndex: "user_name",
      key: "user_name",
    },
  ];
  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("Sheet1")
      .addColumns(exportscolumns)
      .addDataSource(data, {
        str2Percent: true,
        str2num: true,
      })
      .saveAs(`${title}.xlsx`);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-1 mb-2">
        <h6 className="text-primary m-0 bg-light d-inline-block p-2 rounded-3 shadow-sm border">
          Audit Logs for {title} : {data?.length > 0 && data[0].reference_id}
        </h6>
        <Button size="middle" type="primary" onClick={handleClick}>
          Export Data
        </Button>
      </div>
      <NormalTable
        sort={sort}
        activeState={false}
        dataSource={data}
        columns={columns}
      />
    </>
  );
}
