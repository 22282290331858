import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const cad = createApi({
  reducerPath: "cad",
  tagTypes: ["cad"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addCad: builder.mutation({
      query: (data) => (
        console.log("data:", data.data),
        {
          url: `/cad-upload`,
          method: "POST",
          mode: "cors",
          body: data.data,
          headers: { Authorization: `Bearer ${data.token}` },
        }
      ),
      invalidatesTags: ["cad"],
    }),
    cadList: builder.mutation({
      query: (data) => ({
        url: `/cad-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      // invalidatesTags:["cad"]
    }),
    batchList: builder.query({
      query: (token) => ({
        url: `/batch-list/0`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["cad"],
    }),
    UpdateCadList: builder.mutation({
      query: (data) => ({
        url: `/update-cad/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cad"],
    }),
    DeleteSingleCadList: builder.mutation({
      query: (data) => ({
        url: `/cad-single-delete/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cad"],
    }),
    DeleteCadBatch: builder.mutation({
      query: (data) => ({
        url: `/cad-batch-delete/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cad"],
    }),
    fullCadList: builder.mutation({
      query: (data) => ({
        url: `/full-cad-list`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["cad"],
    }),

    editCad: builder.query({
      query: (data) => ({
        url: `/edit-cad/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["cad"],
    }),
    addSingleCad: builder.mutation({
      query: (data) => ({
        url: `/single-cad-upload`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
    cadFilterList: builder.mutation({
      query: (data) => ({
        url: `/cad-filter`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //cad search list
    cadSearchList: builder.mutation({
      query: (data) => ({
        url: `/cad-search`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useAddCadMutation,
  useCadListMutation,
  useBatchListQuery,
  useUpdateCadListMutation,
  useDeleteSingleCadListMutation,
  useDeleteCadBatchMutation,
  useFullCadListMutation,
  useEditCadQuery,
  useCadFilterListMutation,
  useAddSingleCadMutation,
  useCadSearchListMutation,
} = cad;
