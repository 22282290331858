import { createSlice } from "@reduxjs/toolkit";

const customerList = createSlice({
  name: "customerList",
  initialState: {
    customerList: [],
  },
  reducers: {
    customerListAction: (state, action) => {
      state.customerList = action.payload;
    },
  },
});

export const {customerListAction} = customerList.actions;

export default customerList.reducer;
