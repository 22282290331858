import React from "react";
import SettingsTab from "./SettingsTab";
import "./settings.css";
import { useSelector } from "react-redux";

export default function Settings() {
  //redux store for labels
  const { labelList } = useSelector((state) => state.labelList);
  return (
    <>
      <h5 className="settings-container ">
        {labelList["cs.settings.header"]} |{" "}
        {labelList["cs.dashboard.menu.settings"]}
      </h5>
      <div>
        <SettingsTab />
      </div>
    </>
  );
}
