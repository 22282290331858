import AppTable from "../../../../Components/AppTable/AppTable";
import AppModal from "../../../../Components/AppModal/AppModal";
import SearchColumns from "../../../../Components/SearchColumns";
import {
  FaPlus,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { CyclesModal } from "./CyclesModal";
import { useEffect, useState } from "react";
import { Form, Input, notification, Typography } from "antd";
import {
  useUpdateCycleMutation,
  useCycleListQuery,
} from "../../../../Redux/Services/Cycles";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";

export const Cycles = ({ childTab }) => {
  const [showHide, setShowHide] = useState(null);
  const [data, setData] = useState();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const [editId, setEditId] = useState(null);
  const [initialState, setInitialState] = useState(false);
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  function closeModal(data) {
    setShowHide(data);
  }
  function afterClose() {
    setInitialState(!initialState);
  }
  //use redux stor for auth
  const { token} = useSelector((state) => state.auth);
  const { typeModule } = useSelector((state) => state.flagRoles);

  //calling rtk query for cyclelist
  const CycleListFetch = useCycleListQuery(token);

  //create function for cycle fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  //calling rtk query for update to cycle
  const updateCycleData = useUpdateCycleMutation();
  const [updateCycle] = updateCycleData;

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (value) => <span data-idfe={value}>{""}</span>,
      width: 1,
    },
    {
      title: (
        <span>
          Cycle Name{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "name" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      editable: true,
      width: 1150,
      ...SearchColumns("name", "Cycle Name"),
    },

    {
      title: <span>Actions</span>,
      width: matchColumn("  ") && 50,
			className: `${typeModule[1].action ? "" : "d-none"}`,
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              <button className="btn btn-sm btn-primary edit-buttons">
                {" "}
                Save
              </button>
            </Typography.Link>
            <Typography.Link>
              <button
                onClick={cancel}
                className="btn btn-sm btn-danger edit-buttons"
              >
                Cancel
              </button>
            </Typography.Link>
          </span>
        ) : (
          <>
            <span disabled={editingKey !== ""} onClick={() => edit(record)}>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={editHandler}
                size={50}
              />
            </span>
          </>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      id: "",
      ...record,
    });

    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  //here saving to the row
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
        console.log({ ...row, editId });
        //here updating Cycle
        updateCycle({
          data: { name: row.name },
          id: editId,
          token: token,
        });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  //useEfffect for creating for when Cycle is loaded
  useEffect(() => {
    if (CycleListFetch.isSuccess) {
      setData(CycleListFetch.data?.data);
    }
  }, [CycleListFetch, search]);

  //update cycle details
  useEffect(() => {
    if (updateCycleData[1].isSuccess) {
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateCycleData[1].data?.message,
      });
    }
    if (updateCycleData[1].error) {
      ///cycle erros details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          updateCycleData[1].error.data.message ||
          updateCycleData[1].error.error ||
          updateCycleData[1].error.data?.msg.sqlMessage,
      });
    }
  }, [updateCycleData[1].isLoading]);

  //edit cycle module
  const editHandler = (e) => {
    const id = e.target
      .closest("tr")
      .querySelector("td:first-child>span")
      .getAttribute("data-idfe");

    setEditId(id);
  };

  useEffect(() => {
    setSearch("");
    if (childTab === "Cycles") {
      CycleListFetch.refetch();
    }
  }, [childTab]);

  return (
    <>
      <div className="d-flex align-items-center float-end w-25 mb-1 ">
        <AppModal
          showModal={showHide}
          onClose={closeModal}
          afterClose={afterClose}
          width={500}
          height={500}
          element={
            <CyclesModal setShowHide={setShowHide} afterClose={initialState} />
          }
        />

        {/* changing input values globaly  */}
        <div className=" me-1 w-100 mt-2">
          <Input
            placeholder="Search here"
            className="global-search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div>
         {typeModule.map((item,index) => {
					if (item.add_button) {
						return (
              <div key={index} className="add_icon">
								<FaPlus onClick={() => setShowHide(true)} />
							</div>
						);
           }
           return null;
				})}
      </div>
      <div style={{ clear: "both" }}></div>

      <AppTable
        sort={sort}
        loading={updateCycleData[1].isLoading || CycleListFetch.isLoading}
        search={search}
        isEditing={isEditing}
        form={form}
        dataSource={data}
        columns={columns}
      />
    </>
  );
};
