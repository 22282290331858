import React, { useState } from "react";
import {  Tabs } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Notice } from "./Notice/Notice";
import { InvoiceReports } from "./InvoiceReport/InvoiceReports";
import { OrdersReport } from "./OrdersReport/OrdersReport";
import "./Reports.css";

const { TabPane } = Tabs;

export const ReportsTab = () => {
	const [key, setKey] = useState("Notice");
	//redux store using

	const { reportsTabs } = useSelector((state) => state.flagRoles);
	//create function for settings tabs
	function matchTab(value) {
		if (reportsTabs.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}
	useEffect(() => {
		setKey(reportsTabs[0]);
	}, [reportsTabs]);

	return (
		<>
			<Tabs
				activeKey={key}
				className="app-tab"
				defaultActiveKey="1"
				onChange={(k) => {
					setKey(k);
				}}
			>
				{
					//matching base on roles reportsTab Flag
					matchTab("Notice") && (
						<TabPane tab="Notice to Purchaser" key="Notice">
							<Notice refresh={key} />{" "}
						</TabPane>
					)
				}
				{
					//matching base on roles reportsTab Flag
					matchTab("OrderReport") && (
						<TabPane tab="Order Report" key="OrderReport">
							<OrdersReport refresh={key} />{" "}
						</TabPane>
					)
				}
				{
					//matching base on roles reportsTab Flag
					matchTab("InvoiceReport") && (
						<TabPane className="invoice-tab" tab="Invoice Report" key="InvoiceReport">
							<InvoiceReports refresh={key}  />{" "}
						</TabPane>
					)
				}
			</Tabs>
		</>
	);
};

export default ReportsTab;
