import FormInput from "../../../Components/AppInput/AppInput";
import "./Jurisdiction.css";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux/es/exports";
import { Row } from "react-bootstrap";
import "./Jurisdiction.css";
import { useState, useEffect } from "react";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { notification, Tabs, Alert } from "antd";
import {
  useAddJurisdictionMutation,
  useAutoUpdateJuriDataMutation,
  useEditJurisdictionQuery,
  useJuriDisplayCertListQuery,
  useJuriReadOnlyListQuery,
  useJuriTypeListSelectQuery,
  useUpdateJurisdictionMutation,
} from "../../../Redux/Services/Jurisdiction";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import AppTextArea from "../../../Components/AppTextArea/AppText";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
// import { useEditAnnotationPostMutation } from "../../../Redux/Services/Annotation";
import {
  useSearchTaxCollectorMutation,
  useTaxCollectorTableFilterListMutation,
  useTaxCollectorThirdPartFlagMutation,
} from "../../../Redux/Services/TaxCollector";
import {
  removeBlank,
  returnArryObjID,
  scrollComp,
} from "../../../Controllers/Functions";
import AppCheckbox from "../../../Components/AppCheckbox/AppCheckbox";
import MultiJuri from "../../../Components/MultiJuri/MultiJuri";
import { MultiTaxes } from "../../../Components/MultiTaxes/MultiTaxes";
import AppButton from "../../../Components/AppButton/AppButton";

import AppTextEditor from "../../../Components/AppTextEditor/AppTextEditor";

import AppNumeric from "../../../Components/AppNumeric/AppNumeric";

import MultiOthersCounty from "../../../Components/MultiOthersCounty/MultiOthersCounty";
const _ = require("lodash");
const { TabPane } = Tabs;
export const JurisdictionModal = ({
  editId,
  initialValues,
  afterClose,
  setOpen,
  setShowHide,
}) => {
  const [getError, setGetError] = useState(false);
  const [payee, setPayee] = useState("");
  const [read_only_comment, setRead_only_comment] = useState("");
  const [display_oncert_comment, setDisplay_oncert_comment] = useState("");
  const [taxCollectorOption, setTaxCollectorOption] = useState([]);
  const [juriCountyEditList, setJuriCountyEditList] = useState([]);

  const [stateoptions, setStateOptions] = useState([
    { id: "", state_name: "" },
  ]);
  const initialPrice = {
    bonds_approved: "",
    bonds_issued: "",
    stand_by_fees: "",
    collection_rate: "",
  };

  const [setPopError] = useState(false);
  const [countyOptions, setCountyOptions] = useState([]);
  const [juriTypeListOptions, setJuriTypeListOptions] = useState([]);
  const [price, setPrice] = useState(initialPrice);
  //   values assign
  const [values, setValues] = useState(initialValues);

  //changing tab of address
  const [addressTabKey, setAddressTabKey] = useState("1");

  //calling redux store
  const { token } = useSelector((state) => state.auth);
  const juriTypeListSelectResult = useJuriTypeListSelectQuery(token);

  //calling rtk query hook for geting customerType list
  const editJurisdictionQuery = useEditJurisdictionQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  const juriReadOnlyList = useJuriReadOnlyListQuery(token);
  const juriDisplayCertList = useJuriDisplayCertListQuery(token);

  //calling rtk query hook for geting edit annotation
  // const editAnnotationQuery = useEditAnnotationPostMutation();

  //calling rtk query hook for adding Jurisdiction
  const addNewJurisdiction = useAddJurisdictionMutation();
  const [addJurisdiction] = addNewJurisdiction;

  //calling from store to the statelist
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for geting County list

  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  //calling rtk query hook for update Jurisdiction

  const updateJurisdictionData = useUpdateJurisdictionMutation();
  const [updateJurisdiction] = updateJurisdictionData;

  //shwoing taxlist base on state
  const [taxCollectorTableFilterList, taxCollectorTableFilterListResult] =
    useTaxCollectorTableFilterListMutation();

  //shwoing Bill year base on tax select
  const [taxCollectorThirdPartFlag, taxCollectorThirdPartFlagResult] =
    useTaxCollectorThirdPartFlagMutation();

  //clear error function

  function clearStatus() {
    setGetError(null);
  }

  function refecthApi() {
    // if (editId) {
    //   setValues({ ...values, is_jurisdiction_multiple_cities: false });
    // }
    juriReadOnlyList.refetch();
    juriDisplayCertList.refetch();
    juriTypeListSelectResult.refetch();
  }
  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }

  //calling rtk query FOR getting searchJurisdiction data as import
  const taxCollectorSearchApi = useSearchTaxCollectorMutation();

  //calling rtk query FOR getting Jurisdiction data as import and use in the input fields
  const autoJuriData = useAutoUpdateJuriDataMutation();

  //useEffect for searchJurisdiction
  useEffect(() => {
    if (taxCollectorSearchApi[1].error) {
      setPopError(
        taxCollectorSearchApi[1].error.data ||
          taxCollectorSearchApi[1].error?.data?.error ||
          taxCollectorSearchApi[1].error?.data?.message ||
          taxCollectorSearchApi[1].error?.error ||
          taxCollectorSearchApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [taxCollectorSearchApi[1].isLoading]);

  //useEffect for juriType
  useEffect(() => {
    if (juriTypeListSelectResult.isSuccess) {
      setJuriTypeListOptions(juriTypeListSelectResult?.data?.data);
    } else {
      setJuriTypeListOptions([]);
    }
  }, [juriTypeListSelectResult.isFetching]);

  //useEffect for auto Juri data fill
  useEffect(() => {
    if (autoJuriData[1].isSuccess) {
      //import data fetch and used
      setValues({ ...values, ...autoJuriData[1].data?.data[0] });
      //countyList  fech base on stateId
      const state_id = { state_id: autoJuriData[1].data?.data[0]?.state_id };
      if (state_id) {
        getCountyList({
          state_id,
          token: token,
        });
      }
    }
    if (autoJuriData[1].error) {
      scrollComp("error");
      setGetError(
        autoJuriData[1].error.data ||
          autoJuriData[1].error?.data?.error ||
          autoJuriData[1].error?.data?.message ||
          autoJuriData[1].error?.error ||
          autoJuriData[1].error.data?.msg.sqlMessage
      );
    }
  }, [autoJuriData[1].isLoading]);

  //useEffet for  add jurisdiction
  useEffect(() => {
    if (addNewJurisdiction[1].isSuccess) {
      closeModal();
      setOpen(false);
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewJurisdiction[1].data?.message,
      });
    }

    if (addNewJurisdiction[1].error) {
      scrollComp("error");
      setGetError(
        addNewJurisdiction[1].error?.error ||
          addNewJurisdiction[1].error?.data?.error ||
          addNewJurisdiction[1].error?.data?.message ||
          addNewJurisdiction[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewJurisdiction[1].isLoading]);

  useEffect(() => {
    if (taxCollectorTableFilterListResult.isSuccess) {
      const newData = taxCollectorTableFilterListResult.data?.data?.map(
        ({ name, id }) => {
          return {
            name,
            id,
          };
        }
      );
      setTaxCollectorOption(newData);
    } else {
      setTaxCollectorOption([]);
    }
  }, [taxCollectorTableFilterListResult.isLoading]);

  useEffect(() => {
    if (taxCollectorThirdPartFlagResult.isSuccess) {
      let newVal =
        taxCollectorThirdPartFlagResult?.data?.data[0].is_third_party;
      setValues({
        ...values,
        is_third_party: Boolean(newVal),
        bill_year: newVal ? values.bill_year : "",
      });
      setPayee(Boolean(newVal) ? payee : "");
    }
  }, [taxCollectorThirdPartFlagResult.isLoading]);

  useEffect(() => {
    if (updateJurisdictionData[1].isSuccess) {
      ///after update details
      setOpen(false);
      closeModal();
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateJurisdictionData[1].data?.message,
      });
    }

    if (updateJurisdictionData[1].error) {
      scrollComp("error");
      setGetError(
        updateJurisdictionData[1].error?.error ||
          updateJurisdictionData[1].error?.data?.error ||
          updateJurisdictionData[1].error?.data?.message ||
          updateJurisdictionData[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateJurisdictionData[1].isLoading]);



  //useEffect for setting default values
  useEffect(() => {
    //asigning to stateOptions
    setStateOptions(stateList);
    setCountyOptions([]);
    setTaxCollectorOption([]);
    setValues(initialValues);
    setJuriCountyEditList([]);
    //if not eidt id
    setAddressTabKey("1");
    refecthApi();
    setPayee("");
    setPrice(initialPrice);
    setRead_only_comment("");

    setDisplay_oncert_comment("");
  }, [afterClose]);

  useEffect(() => {
    if (editJurisdictionQuery.isSuccess) {
      const {
        jurisdiction_county,
        jurisdiction_county_list,
        read_only_comment,
        bonds_approved,
        bonds_issued,
        stand_by_fees,
        collection_rate,
        display_oncert_comment,
        payee,
        ...othersData
      } = editJurisdictionQuery.data?.data[0];
      var newArr = {
        ...othersData,
        add_alias_name: [],
        edit_alias_name: [],
        delete_alias_name: [],
        add_jurisdiction_discount: [],
        add_jurisdiction_county: [],
        edit_jurisdiction_discount: [],
        edit_jurisdiction_county: [],
        delete_jurisdiction_discount: [],
        add_jurisdiction_tax_rate: [],
        edit_jurisdiction_tax_rate: [],
        delete_jurisdiction_tax_rates: [],
        delete_jurisdiction_county: [],
        jurisdiction_county: jurisdiction_county,
        jurisdiction_county_list,
      };
      const state_id = {
        state_id: editJurisdictionQuery.data?.data[0]?.state_id,
      };
      setJuriCountyEditList(jurisdiction_county_list);
      setValues(newArr);
      setPayee(payee);
      setRead_only_comment(read_only_comment);
      setDisplay_oncert_comment(display_oncert_comment);
      getCountyList({ state_id, token });
      //tax list request
      taxCollectorTableFilterList({
        data: { state_id: editJurisdictionQuery.data?.data[0]?.state_id },
        token,
      });
      setPrice({
        bonds_approved,
        bonds_issued,
        stand_by_fees,
        collection_rate,
      });
      taxCollectorThirdPartFlag({
        id: editJurisdictionQuery.data?.data[0]?.tax_collector_id,
        token,
      });
      //if thre is annotation id is available
    }

    if (editJurisdictionQuery.isError) {
      scrollComp("error");
      setGetError(
        editJurisdictionQuery.error.data ||
          editJurisdictionQuery.error?.data?.error ||
          editJurisdictionQuery.error?.data?.message ||
          editJurisdictionQuery.error?.error ||
          editJurisdictionQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editJurisdictionQuery.isFetching]);

  //useEffect for Anonotation

  // useEffect for countylist

  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
      setAddressTabKey("1");
    }

    if (countyList2[1].error) {
      scrollComp("error");
      setGetError(
        countyList2[1].error?.error ||
          countyList2[1].error?.data?.error ||
          countyList2[1].error?.data?.message ||
          countyList2[1].error.data?.msg.sqlMessage
      );
    }
  }, [countyList2[1].isLoading]);

  //useEfect for Refecth edit details

  useEffect(() => {
    editJurisdictionQuery.refetch();
    clearStatus();
  }, [afterClose]);

  const inputs = [
    {
      id: 1,

      name: "state_id",

      type: "select",

      value: values.state_id || "",

      column: "3",

      placeholder: "Select State",

      errorMessage: "Please select state!",

      label: "State",

      options: stateoptions,

      required: true,

      tab: false,
    },

    {
      id: 6,

      name: "county_id",

      type: "select",

      column: "3",

      value: values.county_id || "",

      placeholder: "Select County Name",

      errorMessage: "Please select county!",

      label: "County",

      options: countyOptions,

      required: true,

      tab: false,
    },

    {
      id: 335,

      name: "display_name",

      type: "text",

      value: values?.display_name || "",

      placeholder: "Enter Display Name",

      errorMessage: "Please enter display name!",

      label: "Display Name",

      required: true,

      column: "3",

      tab: false,
    },
    {
      id: 6455,

      name: "jurisdictions_type_id",

      type: "select",

      column: "3",

      value: values.jurisdictions_type_id || "",

      placeholder: "Select Type Name",

      errorMessage: "Please select type name!",

      label: "Type",

      options: juriTypeListOptions,

      required: true,

      tab: false,
    },

    {
      id: 4,

      name: "cad_office_code",

      type: "text",

      value: values.cad_office_code || "",

      placeholder: "Enter Cad Office Code",

      errorMessage: "Please enter cad office code!",

      label: "CAD Office Code",

      required: true,

      column: "3",

      tab: false,
    },

    {
      id: 6,
      name: "tax_office_code",
      type: "text",
      value: values?.tax_office_code || "",
      placeholder: "Enter Tax Code",
      errorMessage: "Please enter tax code!",
      label: "Tax Office Code",
      column: "3",
      tab: false,
    },

    {
      id: 645512155,

      name: "tax_collector_id",

      type: "select",

      column: "3",

      value: values.tax_collector_id || "",

      placeholder: "Select Tax Collector",

      errorMessage: "Please select tax collector!",

      label: "Tax Collector Id",

      options: taxCollectorOption,

      required: true,

      tab: false,
    },
    {
      id: 7,
      name: "collection_rate",
      value: price?.collection_rate || "",
      placeholder: "Enter Atty Collection Rate",
      errorMessage: "Please enter atty collection rate!",
      label: "Atty Collection Rate",
      required: true,
      column: "3",
      tab: false,
    },

    // {
    //   id: 14485512,
    //   name: "is_third_party",
    //   checked: values.is_third_party || "",
    //   type: "switch",
    //   column: "2",
    //   label: "Is Third Party",
    //   required: true,
    //   margin: "3",
    //   tab: false,
    // },
    {
      id: 8,
      name: "bill_year",
      value: values?.bill_year || "",
      type: "text",
      placeholder: "Enter Bill Year",
      errorMessage: "Please enter 4 digits bill year!",
      label: "Bill Year",
      required: values.is_third_party,
      maxLength: 4,
      minLength: 4,
      column: `3 ${!values.is_third_party ? "d-none" : ""}`,
      tab: false,
    },
    {
      id: 454656,
      name: "payee",
    },
    //bonds
    {
      id: 16454545,
      name: "is_early_pay_discount",
      checked: values.is_early_pay_discount || "",
      type: "switch",
      column: "3",
      label: "Early Pay Discount",
      required: true,
      margin: "3",
      tab: false,
      tooltip: (
        <>
          <ul className="nav flex-column ps-3">
            <li className="nav-item">
              <span className="text-while">3% off Base Tax in October</span>
            </li>
            <li className="nav-item">
              <span className="text-while">2% off Base Tax in November</span>
            </li>
            <li className="nav-item">
              <span className="text-while">1% off Base Tax in December</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 514546,
      name: "is_jurisdiction_multiple_cities",
      _id: "juri_is_jurisdiction_multiple_cities",
      checked: values.is_jurisdiction_multiple_cities || "",
      type: "checkbox",
      column: "3",
      label: "Jurisdiction In Multiple Counties",
      margin: "3",
    },

    {
      id: 301,

      name: "hms",

      type: "text",

      value: values?.hms || "",

      column: "3",

      placeholder: "Enter HMS",

      label: "HMS",

      address_type: "bonds",

      tab: true,
    },

    {
      id: 303,

      name: "over65",

      value: values?.over65 || "",

      type: "text",

      column: "3",

      placeholder: "Enter Over65",

      label: "Over65",

      address_type: "bonds",

      tab: true,
    },

    {
      id: 304,

      name: "disabled_person",

      value: values?.disabled_person || "",

      type: "text",
      column: "3",

      placeholder: "Enter Disabled Person",

      label: "Disabled Person",

      address_type: "bonds",

      tab: true,
    },

    {
      id: 305,

      name: "exemption_other",

      value: values?.exemption_other || "",

      type: "text",

      column: "3",

      placeholder: "Enter Other Exemption",

      label: "Other Exemption",

      address_type: "bonds",

      tab: true,
    },
    {
      id: 3051254,

      name: "first_responder",

      value: values?.first_responder || "",

      type: "text",

      column: "3",

      placeholder: "Enter First Responder",

      label: "First Responder",

      address_type: "bonds",

      tab: true,
    },

    {
      id: 306556,

      name: "solar",

      value: values?.solar || "",

      type: "text",

      column: "3",

      placeholder: "Enter Solar",

      label: "Solar",

      address_type: "bonds",

      tab: true,
    },
    {
      id: 306,
      name: "bonds_approved",
      value: values?.bonds_approved || "",
      column: "3",
      placeholder: "Enter Bonds Approved",
      label: "Bonds Approved",
      errorMessage: "pleae enter valid",
      address_type: "bonds",
      tab: true,
    },

    {
      id: 307,

      name: "bonds_issued",
      value: values?.bonds_issued || "",
      column: "3",
      placeholder: "Enter Bonds Issued",
      maxLength: 10,
      label: "Bonds Issued",
      address_type: "bonds",
      tab: true,
    },

    {
      id: 308,
      name: "stand_by_fees",
      minLength: 1,
      value: values?.stand_by_fees || "",
      maxLength: 10,
      column: "3",
      placeholder: "Enter Stand By Fees",
      label: "Stand By Fees",
      address_type: "bonds",
      tab: true,
    },
    {
      id: 485678,
      name: "bonds_verified_date",
      type: "date",
      value: values.bonds_verified_date || false,
      placeholder: "Select Date Verified",
      errorMessage: "Please select Date Verified!",
      label: "Verify Date",
      column: "3",
      address_type: "bonds",
      tab: true,
    },
    {
      id: 785445,
      name: "is_october",
      _id: "juri_is_october",
      checked: values.is_october || "",
      type: "checkbox",
      column: "1",
      label: "Oct 3%",
      margin: "3",
      address_type: "discount",
      tab: true,
    },
    {
      id: 78521,
      name: "is_november",
      _id: "juri_is_november",
      checked: values.is_november || "",
      type: "checkbox",
      column: "1",
      label: "Nov 2%",
      margin: "3",
      address_type: "discount",
      tab: true,
    },
    {
      id: 785413421,
      name: "is_december",
      _id: "juri_is_december",
      checked: values.is_december || "",
      type: "checkbox",
      column: "1",
      label: "Dec 1%",
      margin: "3",
      address_type: "discount",
      tab: true,
    },
  ];
  useEffect(() => {
    if (getError) {
      scrollComp("error");
    }
  }, [getError]);
  //select handler

  const selectHandler = (property, value) => {
    clearStatus();

    //calling rtk  query for geting county list for options
    const state_id = { state_id: Number(value) };
    if (property === "tax_collector_id") {
      if (value) {
        // calling req for getting flag is tird party
        taxCollectorThirdPartFlag({ id: value, token });
        setValues({
          ...values,
          [property]: value,
        });
        values.bill_year = "";
        setPayee("");
      } else {
        setValues({
          ...values,
          [property]: value,
        });
        values.is_third_party = false;
        values.bill_year = "";
        setPayee("");
      }
    }
    if (property === "state_id") {
      if (value) {
        getCountyList({ state_id, token: token });
        setValues({
          ...values,
          [property]: value,
        });
        // base on state id fetching tax collector list
        taxCollectorTableFilterList({
          data: { state_id: value },
          token,
        });
        values.jurisdiction_county = [];
        values.add_jurisdiction_county = [];
        values.edit_jurisdiction_county = [];
        values.jurisdiction_county_list = [];
      } else {
        setCountyOptions([]);
        values.jurisdiction_county = [];
        values.add_jurisdiction_county = [];
        values.edit_jurisdiction_county = [];
        values.jurisdiction_county_list = [];
        setValues({
          ...values,
          [property]: value,
          county_id: "",
        });
      }
    }
    setValues({ ...values, [property]: value });
  };

  //clear the values
  const handleReset = (e) => {
    setValues(initialValues);
    if (editId) {
      editJurisdictionQuery.refetch();
    }

    clearStatus();
    setRead_only_comment("");
    setDisplay_oncert_comment("");
    setPayee("");
  };

  //AppSwitch box

  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });

    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //remove blank values
    if (!values.is_jurisdiction_multiple_cities) {
      values.jurisdiction_county = [];
    }

    if (!payee && values.is_third_party) {
      setGetError("Please enter payee name");
    } else if (!editId && values.jurisdiction_taxrates.length === 0) {
      setGetError("Please add tax rate");
      setAddressTabKey("1");
    } else {
      removeBlank(values);
      removeBlank(price);
      if (!editId) {
        addJurisdiction({
          data: {
            ...values,
            ...price,
            payee,
            jurisdiction_county_list: values.is_jurisdiction_multiple_cities
              ? values.jurisdiction_county_list
              : [],
            read_only_comment,
            display_oncert_comment,
          },
          id: editId,
          token: token,
        });
      }

      if (editId) {
        const deleteCountyBaseOnChange = juriCountyEditList.filter(
          ({ county_id }) => {
            return returnArryObjID(countyOptions, "id").includes(county_id);
          }
        );

        //if is_jurisdiction_multiple_cities false
        var deletedValues = values.delete_jurisdiction_county;
        if (!values.is_jurisdiction_multiple_cities) {
          juriCountyEditList.map(({ id }) => {
            deletedValues.push(id);
          });
          values.add_jurisdiction_county = [];
          values.edit_jurisdiction_county = [];
        }

        if (deleteCountyBaseOnChange.length === 0) {
          juriCountyEditList.map(({ id }) => {
            deletedValues.push(id);
          });
          values.edit_jurisdiction_county = [];
        }

        updateJurisdiction({
          data: {
            ...values,
            ...price,
            payee,
            add_jurisdiction_county: values.is_jurisdiction_multiple_cities
              ? values.add_jurisdiction_county
              : [],
            edit_jurisdiction_county: values.is_jurisdiction_multiple_cities
              ? values.edit_jurisdiction_county
              : [],
            delete_jurisdiction_county: _.uniq(deletedValues),
            read_only_comment,
            display_oncert_comment,
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "bill_year") {
      setValues({ ...values, [name]: value.replace(/[^0-9]/g, "") });
    }

    //claser status values
    clearStatus();
  };
  const TabOnChange = (key) => {
    setAddressTabKey(key);
    console.log(key);
  };

  //html editor

  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();
    setValues({
      ...values,
      [property]: value,
    });
  };

  return (
    <div className="position-relative mytab">
      <div className=" border-bottom border-2 d-flex " id="error">
        <div className="col-11">
          <h5>{editId ? "Edit Jurisdiction" : "Add Jurisdiction"}</h5>
        </div>
        {/* //popupSarch modal  */}
        {/* {!editId && (
          <AppPopup
            error={popError}
            closePop={(value) => {
              !value && setPopError(false);
            }}
            afterClose={afterClose}
            getId={(e) => autoUpdateJuriData({ id: e, token })}
            list={
              taxCollectorSearchApi[1].isSuccess &&
              taxCollectorSearchApi[1].data?.data
            }
            isLoading={taxCollectorSearchApi[1].isLoading}
            onSearch={(e) => {
              searchTaxCollector({ value: { tax_collector_name: e }, token });
            }}
            placeholder="Search Tax Collector"
          />
        )} */}
      </div>

      {getError && (
        <div className="mt-2">
          <Alert message={getError} type="error" showIcon />{" "}
        </div>
      )}

      <form onSubmit={handleSubmit} className="jurisdiction">
        {(addNewJurisdiction[1].isLoading ||
          updateJurisdictionData[1].isLoading ||
          editJurisdictionQuery.isFetching ||
          countyList2[1].isLoading ||
          taxCollectorThirdPartFlagResult.isLoading) && <AppSpinner />}
        <div
          className={`${editJurisdictionQuery.isFetching ? "invisible" : ""} `}
        >
          <Row>
            <div className="col-12 col-lg-12">
              <div className="row">
                {inputs.map((input) => (
                  <>
                    {input.type === "checkbox" &&
                      !input.tab &&
                      input.name !== "others" && (
                        <AppCheckbox
                          {...input}
                          handleChange={appCheckboxHandler}
                        />
                      )}
                    {(input.type === "text" || input.type === "email") &&
                      input.tab === false && (
                        <FormInput
                          key={input.id}
                          defaultSet={afterClose}
                          {...input}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    {input.name === "collection_rate" && (
                      <AppNumeric
                        {...input}
                        defaultSet={afterClose}
                        value={price[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        suffix={"%"}
                        decimalScale={0}
                        maxLength={4}
                        onChange={(values) => {
                          setPrice({
                            ...price,
                            [input.name]: values,
                          });
                        }}
                      />
                    )}
                    {/* //appSelcet for tax_collector  */}

                    {input.type === "select" &&
                      input.name === "state_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {input.type === "select" &&
                      input.name === "county_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select County</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.county_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {input.type === "select" &&
                      input.name === "jurisdictions_type_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select Type Name</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {input.type === "select" &&
                      input.name === "tax_collector_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>
                                Select Tax Collector Name
                              </option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {/* //appSwicth  */}

                    {input.type === "switch" && input.tab === false && (
                      <AppSwitch
                        key={input.id}
                        value={values[input.name]}
                        onChange={appSwitchHanler}
                        {...input}
                      />
                    )}

                    {/* //textarea  */}
                    {input.name === "payee" && values.is_third_party && (
                      <div className="col-12 col-lg-3 my-2 editor">
                        <label htmlFor="" className="my-1  heading-5 text-dark">
                          Payee Name
                          <span className="text-danger">*</span>
                        </label>

                        <AppTextArea
                          required={true}
                          name={"payee"}
                          defaultSet={afterClose}
                          value={payee}
                          errorMessage={"Please enter payee name!"}
                          onChange={(e) => {
                            setPayee(e.target.value);
                            setGetError(false);
                          }}
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </Row>

          {!editJurisdictionQuery.isFetching &&
            values.is_jurisdiction_multiple_cities &&
            !countyList2[1].isLoading && (
              <div className="row my-2 border p-2 shadow-sm rounded-3 m-0 align-items-center">
                <div className="col-12">
                  <MultiOthersCounty
                    countyList={countyOptions}
                    addFormVal={(value) => {
                      if (!editId) {
                        values.jurisdiction_county = value;
                      }
                    }}
                    whileEdit={(value) => {
                      if (editId) {
                        setValues({
                          ...values,
                          add_jurisdiction_county: [
                            ...values?.add_jurisdiction_county,
                            value,
                          ],
                        });
                      }
                    }}
                    //update
                    updateContact={(value) => {
                      if (editId) {
                        if (
                          values.add_jurisdiction_county?.length > 0 &&
                          values.add_jurisdiction_county?.find(
                            (val) => val["temp_id"] === value.temp_id
                          )
                        ) {
                          values.add_jurisdiction_county.forEach((item) => {
                            if (item.temp_id === value.temp_id) {
                              item.county_id = value.county_id;
                              item.county_name = value.county_name;
                              item.cad_code = value.cad_code;
                            }
                          });
                        }

                        if (
                          values.edit_jurisdiction_county?.length > 0 &&
                          values.edit_jurisdiction_county?.find(
                            (val) => val["id"] === value.id
                          )
                        ) {
                          values.edit_jurisdiction_county.forEach((item) => {
                            if (item.id === value.id) {
                              item.county_id = value.county_id;
                              item.county_name = value.county_name;
                              item.cad_code = value.cad_code;
                            }
                          });
                        } else if (value?.id) {
                          setValues({
                            ...values,
                            edit_jurisdiction_county: [
                              ...values?.edit_jurisdiction_county,
                              value,
                            ],
                          });
                        }
                      }
                    }}
                    //delete
                    deleteContact={(value) => {
                      if (editId) {
                        if (value) {
                          setValues({
                            ...values,
                            delete_jurisdiction_county: value?.id
                              ? [
                                  ...values?.delete_jurisdiction_county,
                                  String(value?.id),
                                ]
                              : values?.delete_jurisdiction_county,
                            edit_jurisdiction_county:
                              values.edit_jurisdiction_county?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                            add_jurisdiction_county:
                              values.add_jurisdiction_county?.filter((e) => {
                                return (
                                  (e.id || e?.temp_id) !==
                                  (value.id || value?.temp_id)
                                );
                              }),
                          });
                        }
                      }
                    }}
                    afterClose={afterClose}
                    initailValArr={
                      editId ? values?.jurisdiction_county_list : []
                    }
                  />
                </div>
              </div>
            )}

          {!editJurisdictionQuery.isFetching &&
            (editId ? !countyList2[1].isLoading : true) && (
              <div className="mt-3 ">
                <Tabs
                  className="w-100"
                  // key={String(addressTabKey)}
                  activeKey={addressTabKey}
                  onChange={TabOnChange}
                  type="card"
                >
                  {/* //Bonds  */}
                  <TabPane tab="Tax Rates" key="1">
                    <div className="row">
                      <div className="col-12 px-2">
                        <MultiTaxes
                          addFormVal={(value) => {
                            if (!editId) {
                              values.jurisdiction_taxrates = value;
                            }
                          }}
                          whileEdit={(value) => {
                            if (editId) {
                              setValues({
                                ...values,
                                add_jurisdiction_tax_rate: [
                                  ...values?.add_jurisdiction_tax_rate,
                                  value,
                                ],
                              });
                            }
                          }}
                          //update
                          updateContact={(value) => {
                            if (editId) {
                              if (
                                values.add_jurisdiction_tax_rate?.length > 0 &&
                                values.add_jurisdiction_tax_rate?.find(
                                  (val) => val["temp_id"] === value.temp_id
                                )
                              ) {
                                values.add_jurisdiction_tax_rate.forEach(
                                  (item) => {
                                    if (item.temp_id === value.temp_id) {
                                      item.tax_rate = value.tax_rate;
                                      item.tax_year = value.tax_year;
                                    }
                                  }
                                );
                              }

                              if (
                                values.edit_jurisdiction_tax_rate?.length > 0 &&
                                values.edit_jurisdiction_tax_rate?.find(
                                  (val) => val["id"] === value.id
                                )
                              ) {
                                values.edit_jurisdiction_tax_rate.forEach(
                                  (item) => {
                                    if (item.id === value.id) {
                                      item.tax_rate = value.tax_rate;
                                      item.tax_year = value.tax_year;
                                    }
                                  }
                                );
                              } else if (value?.id) {
                                setValues({
                                  ...values,
                                  edit_jurisdiction_tax_rate: [
                                    ...values?.edit_jurisdiction_tax_rate,
                                    value,
                                  ],
                                });
                              }
                            }
                          }}
                          //delete
                          deleteContact={(value) => {
                            if (editId) {
                              if (value) {
                                setValues({
                                  ...values,
                                  delete_jurisdiction_tax_rates: value?.id
                                    ? [
                                        ...values?.delete_jurisdiction_tax_rates,
                                        String(value?.id),
                                      ]
                                    : values?.delete_jurisdiction_tax_rates,
                                  edit_jurisdiction_tax_rate:
                                    values.edit_jurisdiction_tax_rate?.filter(
                                      (e) => {
                                        return (
                                          (e.id || e?.temp_id) !==
                                          (value.id || value?.temp_id)
                                        );
                                      }
                                    ),
                                  add_jurisdiction_tax_rate:
                                    values.add_jurisdiction_tax_rate?.filter(
                                      (e) => {
                                        return (
                                          (e.id || e?.temp_id) !==
                                          (value.id || value?.temp_id)
                                        );
                                      }
                                    ),
                                });
                              }
                            }
                          }}
                          getIntegration={[]}
                          afterClose={afterClose}
                          initailValArr={
                            editId ? values?.juri_tax_rates_list : []
                          }
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Bonds & Exemptions" key="2">
                    <div className="row">
                      {inputs.map((input) => (
                        <>
                          {(input.type === "text" || input.type === "date") &&
                            input.name !== "stand_by_fees" &&
                            input.name !== "bonds_approved" &&
                            input.name !== "bonds_issued" &&
                            input.tab === true &&
                            input?.address_type === "bonds" && (
                              <FormInput
                                key={input.id}
                                defaultSet={afterClose}
                                {...input}
                                onChange={onChange}
                              />
                            )}
                          {(input.name === "stand_by_fees" ||
                            input.name === "bonds_approved" ||
                            input.name === "bonds_issued") &&
                            input.tab === true &&
                            input?.address_type === "bonds" && (
                              <AppNumeric
                                {...input}
                                maxLength={15}
                                defaultSet={afterClose}
                                value={price[input.name]}
                                thousandSeparator={true}
                                placeholder={input.placeholder}
                                prefix={"$"}
                                decimalScale={6}
                                onChange={(values) => {
                                  setPrice({
                                    ...price,
                                    [input.name]: values,
                                  });
                                }}
                              />
                            )}

                          {/* //appSelcet for state name  */}

                          {input.type === "select" &&
                            input.name === "physical_state_id" &&
                            input.tab === true &&
                            input?.address_type === "bond" && (
                              <AppSelect
                                defaultSet={afterClose}
                                key={input.id}
                                {...input}
                                onChange={selectHandler}
                                options={input?.options?.map((ele) => {
                                  return (
                                    <option key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </option>
                                  );
                                })}
                              />
                            )}

                          {input.type === "switch" &&
                            input.tab === true &&
                            input?.address_type === "bonds" && (
                              <AppSwitch
                                key={input.id}
                                value={values[input.name]}
                                onChange={appSwitchHanler}
                                {...input}
                              />
                            )}
                        </>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tab="Comments" key="3">
                    <div className="row editor">
                      <div className="col-12 col-lg-6 px-2 ">
                        {juriDisplayCertList.isSuccess && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6>Display On Cert</h6>
                            </div>
                            <div className="col-12 col-lg-9">
                              <AppSelect
                                value={values.selDisplay}
                                classStyle="border w-100 p-1 my-2"
                                onChange={(_, value) => {
                                  setDisplay_oncert_comment(
                                    display_oncert_comment + value
                                  );
                                  setValues({ ...values, selDisplay: value });
                                }}
                                options={
                                  <>
                                    <option value={""}>
                                      Select Display On Cert
                                    </option>
                                    {juriDisplayCertList?.data?.data?.map(
                                      ({ title, comments, id }) => {
                                        return (
                                          <option key={id} value={comments}>
                                            {title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </>
                                }
                              />
                            </div>
                          </div>
                        )}
                        <AppTextEditor
                          value={display_oncert_comment}
                          onChange={(newContent) =>
                            setDisplay_oncert_comment(newContent)
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-6 px-2 ">
                        {juriReadOnlyList.isSuccess && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6>Read Only</h6>
                            </div>
                            <div className="col-12 col-lg-9">
                              <AppSelect
                                value={values.selReadOnly}
                                classStyle="border w-100 p-1 my-2"
                                onChange={(_, value) => {
                                  setRead_only_comment(
                                    read_only_comment + value
                                  );
                                  setValues({ ...values, selReadOnly: value });
                                }}
                                options={
                                  <>
                                    <option value={""}>Select Read Only</option>
                                    {juriReadOnlyList?.data?.data?.map(
                                      ({ title, comments, id }) => {
                                        return (
                                          <option key={id} value={comments}>
                                            {title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </>
                                }
                              />
                            </div>
                          </div>
                        )}
                        <AppTextEditor
                          value={read_only_comment}
                          onChange={(newContent) =>
                            setRead_only_comment(newContent)
                          }
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Jurisidiction Aliases" key="4">
                    <div className="row ">
                      <div className="col-12">
                        <MultiJuri
                          afterClose={afterClose}
                          addFormVal={(value) => {
                            if (!editId) {
                              values.alias_names = value;
                            }
                          }}
                          //add new
                          whileEdit={(value) => {
                            if (editId) {
                              setValues({
                                ...values,
                                add_alias_name: [
                                  ...values?.add_alias_name,
                                  value,
                                ],
                              });
                            }
                          }}
                          //update
                          updateContact={(value) => {
                            if (editId) {
                              if (
                                values.edit_alias_name?.length > 0 &&
                                values.edit_alias_name?.find(
                                  (val) => val["id"] === value.id
                                )
                              ) {
                                values.edit_alias_name.forEach((item) => {
                                  if (item.id === value.id) {
                                    item.name = value.name;
                                  }
                                });
                              } else {
                                setValues({
                                  ...values,
                                  edit_alias_name: [
                                    ...values?.edit_alias_name,
                                    value,
                                  ],
                                });
                              }
                            }
                          }}
                          //delete
                          deleteContact={(value) => {
                            if (editId) {
                              if (value) {
                                setValues({
                                  ...values,
                                  delete_alias_name: [
                                    ...values?.delete_alias_name,
                                    String(value.id),
                                  ],
                                });
                              } else {
                                setValues({
                                  ...values,
                                  add_alias_name: values.add_alias_name?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                                });
                              }
                            }
                          }}
                          initailValArr={editId ? values?.juri_alias_list : []}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            )}

          <Row
            className={`${
              editJurisdictionQuery.isFetching ? "invisible" : ""
            } `}
          >
            <div className="d-flex justify-content-center pb-3 pt-2">
              <div className="me-md-2 ">
                <AppButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              <AppButton title={"SUBMIT"} variant={"primary-md"} />
            </div>
          </Row>
        </div>
      </form>
    </div>
  );
};
