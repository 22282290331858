import { useState } from "react";
import FormInput from "../../Components/AppInput/AppInput";
import { Form, Col, Container, Row } from "react-bootstrap";
import { Alert } from "antd";
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaRegEnvelope } from "react-icons/fa";
import "./ForgotPassword.css";
import AppButton from "../../Components/AppButton/AppButton";
import { useUserEmailPassLinkSendMutation } from "../../Redux/Services/authApi";
import { useEffect } from "react";
const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({});
  const inputs = [
    {
      id: 1,
      name: "email",
      column: "12",
      type: "email",
      icon: <FaRegEnvelope />,
      placeholder: "Email Address",
      errorMessage: "Please enter your registered email !",
      // label: "Email Address",
      required: true,
    },
  ];

  //redux

  //rtk query
  const [userEmailPassLinkSend, { error, isLoading, isSuccess, data }] =
    useUserEmailPassLinkSendMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    userEmailPassLinkSend(values);
  };
  useEffect(() => {
    if (isSuccess) {
      setSuccess(data);
    }
    if (error) {
      setShow(error.data?.message || error.error);
    }
  }, [isLoading]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setShow(false);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ background: "url(../Assets/login/back.png)", height: "100vh" }}
    >
      <Container>
        <Row className="align-items-center">
          <Col sm="5" lg="5">
            {success ? (
              <div>
                <Row>
                  <Col
                    sm="6"
                    lg="6"
                    className="mx-auto d-flex justify-content-center "
                  >
                    <img
                      className="img-fluid"
                      src={"../Assets/login/successIcon.png"}
                      alt=""
                    />
                  </Col>
                </Row>
                <Row>
                  <h4 className="heading-1 text-center">
                    Email has been sent!
                  </h4>
                </Row>
                <Row className="description text-center">
                  <p>
                    Please check your inbox and click in the
                    <p>{success.message}</p>
                  </p>
                </Row>
                <Row>
                  <p className="text-center heading-4">
                    Don't receive the email?{" "}
                    <button className="resend-btn">Click to resend</button>
                  </p>
                </Row>
                <Row>
                  <p className="text-center">
                    <NavLink
                      className="login-back text-decoration-none"
                      to={`/resetpassword/${success?.token}/${success?.user_id}`}
                    >
                      Demo Reset Link <FaArrowRight />
                    </NavLink>
                  </p>
                  <p className="text-center">
                    <NavLink
                      className="login-back text-decoration-none"
                      to="/login"
                    >
                      <FaArrowLeft /> Back to Login
                    </NavLink>
                  </p>
                </Row>
              </div>
            ) : (
              <div className="forgot-form">
                <div className="mb-3">
                  <h5 className="heading-1 text-center">
                    Forgot Your Password?
                  </h5>
                  <p className="description text-center">
                    Enter Your Registered email below to receive<br></br>{" "}
                    password reset instruction
                  </p>

                  {show && <Alert message={show} type="error" showIcon />}
                </div>
                <Form onSubmit={handleSubmit}>
                  <Row className="login-input-field">
                    {inputs.map((input) => (
                      <div className="w-100">
                        <FormInput
                          key={input.id}
                          {...input}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="my-2 ">
                    <div className="text-center padding-top">
                      <Form.Text>Remember Password? </Form.Text>
                      <NavLink className="forgot-password" to="/">
                        Login
                      </NavLink>
                    </div>
                  </Row>
                  <Row className="mt-4">
                    <AppButton
                      isLoading={isLoading}
                      variant={"primary"}
                      title={"SEND"}
                    />
                  </Row>
                  <Row>
                    <div className="text-center"></div>
                  </Row>
                </Form>
              </div>
            )}
          </Col>
          <Col
            sm="6"
            lg="6"
            className="ms-auto d-flex justify-conten-center  my-md-0 my-3"
          >
            <img
              className="loginImage img-fluid"
              src="../Assets/login/loginImage.png"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
