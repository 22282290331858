import React from "react";
import { Checkbox } from "antd";
import { BsExclamationCircle } from "react-icons/bs";

export default function AppCheckbox(props) {
  const {
    placeholder,
    subhead,
    column,
    label,
    name,
    errorMessage,
    id,
    hide,
    _id,
    margin,
    handleChange,
    checked,
    disabled,
    ...inputProps
  } = props;

  const onChange = (e) => {
    handleChange(name, e.target.checked);
  };
  return (
    <div className={`mt-${margin}  col-12 col-md-${column}`}>
      {subhead && <h6 className="mt-3">{subhead}</h6>}

      <div className="d-flex align-items-center gap-1">
        <Checkbox
          disabled={disabled}
          id={_id}
          onChange={onChange}
          checked={checked}
        ></Checkbox>
        <label className="ms-1 d-inline-block heading-5 text-dark">
          {!label ? <>&nbsp;</> : <> {label}&nbsp;</>}
        </label>
      </div>
      <span className="input-error">
        <BsExclamationCircle className="alertIcon" />
        {errorMessage}
      </span>
    </div>
  );
}
