import { createSlice } from "@reduxjs/toolkit";

const TaxcadList = createSlice({
  name: "TaxcadList",
  initialState: {
    TaxcadList: [],
  },
  reducers: {
      TaxcadListAction: (state, action) => {
      state.TaxcadList = action.payload;
    },
  },
});

export const {TaxcadListAction} = TaxcadList.actions;

export default TaxcadList.reducer;