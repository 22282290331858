import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const ReportsListApi = createApi({
	reducerPath: "reports",
	tagTypes: ["reports"],
	baseQuery: fetchBaseQuery({ baseUrl: config.url }),
	endpoints: (builder) => ({
		orderReportFilterList: builder.mutation({
			query: (data) => ({
				url: `/order-reports-filter`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		invoiceReportList: builder.query({
			query: (token) => ({
				url: `/invoice-report`,
				method: "GET",
				mode: "cors",
				headers: { Authorization: `Bearer ${token}` },
			}),
			providesTags: ["reports"],
		}),
		invoiceReportFilterList: builder.mutation({
			query: (data) => ({
				url: `/invoice-report-filter`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
	}),
});

export const {
  useOrderReportFilterListMutation,
  useInvoiceReportListQuery,
  useInvoiceReportFilterListMutation
} = ReportsListApi;