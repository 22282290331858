import AppTable from "../../../../Components/AppTable/AppTable";
import AppModal from "../../../../Components/AppModal/AppModal";
import { UserModal } from "./UserModal";
import { EditOutlined } from "@ant-design/icons";
import {
	FaPlus,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Input from "antd/lib/input/Input";
import { notification, Switch } from "antd";
import { useEffect } from "react";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import SearchColumns from "../../../../Components/SearchColumns";
import {
	useUpdateUserStatusMutation,
	useUsersListQuery,
} from "../../../../Redux/Services/User";

const User = ({ refresh, updateTab }) => {
	const [editId, setEditId] = useState(null);
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [preDefualtStateValues] = useState({
		first_name: "",
		middle_name: "",
		last_name: "",
		email: "",
		customer_id: "",
		office_number: "",
		fax_number: "",
		user_type_id: "",
		branch_id: "",
		generate_password_check: false,
		is_active: true,
		is_dark_theme: true,
		phone_number: "",
		position: "",
		password: "",
	});
	//search useeffect
	useEffect(() => {
		setSearch("");
	}, [refresh]);

	function afterClose() {
		setInitialStateVal(!initialStateVal);
	}

	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	function closeModal(data) {
		setShowHide(data);
	}

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	const { token, user } = useSelector((state) => state.auth);
	const { usersModule, tableColumn } = useSelector(
		(state) => state.flagRoles
	);
	//calling rtk query hook for geting customerType list
	const usersList = useUsersListQuery(token);
	//create function for branch fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//calling rtk query hook for geting VendorsType list
	const updateUserStatusApi = useUpdateUserStatusMutation();
	const [updateUserStatus] = updateUserStatusApi;

	useEffect(() => {
		if (usersList.isSuccess) {
			const newData = usersList.data?.data.map((ele) => {
				return {
					...ele,
					vendor_name: ele.vendor_name || "NA",
					customer_name: ele.customer_name || "NA",
					branch_name: ele.branch_name || "NA",
					last_name: ele.last_name || "NA",
				};
			});
			setData(newData);
		}
	}, [usersList]);

	const columns = [
		{
			title: (
				<span>
					Vendor Name{" "}
					{getsortfield === "vendor_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "vendor_name",
			key: "vendor_name",
			sorter: (a, b) => a?.vendor_name.localeCompare(b?.vendor_name),
			responsive: ["sm"],
			className: `${tableColumn[0].vendor ?" " :"d-none" }`,
			width: matchColumn(" ") && 120,
			align: "center",
			...SearchColumns("vendor_name", "Vendor Name"),
		},
		{
			title: (
				<span>
					Customer Name{" "}
					{getsortfield === "customer_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "customer_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "customer_name",
			key: "customer_name",
			align: "center",
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			responsive: ["sm"],
			className: `${tableColumn[1].customer ? tableColumn[0].vendor ?" ":"border-style": "d-none"}`,
			width: matchColumn(" ") && 200,
			...SearchColumns("customer_name", "Customer Name"),
		},
		{
			title: (
				<span>
					Branch Name{" "}
					{getsortfield === "branch_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "branch_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "branch_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "branch_name",
			key: "branch_name",
			align: "center",
			sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
			responsive: ["sm"],
			className: `${tableColumn[2].branch ? tableColumn[1].customer ?" ":"border-style": "d-none"}`,
			width: matchColumn(" ") && 200,
			...SearchColumns("branch_name", "Branch Name"),
		},
		{
			title: (
				<span>
					First Name{" "}
					{getsortfield === "first_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "first_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "first_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "first_name",
			className: `${tableColumn[2].branch ? " ": "border-style"}`,
		
			key: "first_name",
			align: "center",
			responsive: ["sm"],
			  width: matchColumn(" ") && 200,
			sorter: (a, b) => a.first_name.localeCompare(b.first_name),
			...SearchColumns("first_name", "First Name"),
		},
		{
			title: (
				<span>
					Last Name{" "}
					{getsortfield === "last_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "last_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "last_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "last_name",
			key: "last_name",
			align: "center",
			sorter: (a, b) => a.last_name.localeCompare(b.last_name),
			responsive: ["sm"],
			  width: matchColumn(" ") && 200,
			...SearchColumns("last_name", "Last Name"),
		},
		{
			title: (
				<span>
					Email{" "}
					{getsortfield === "email" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "email" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "email" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "email",
			key: "email",
			align: "center",
			sorter: (a, b) => a.email.localeCompare(b.email),
			responsive: ["sm"],
			  width: matchColumn(" ") && 200,
			...SearchColumns("email", "Email"),
		},
		{
			title: (
				<span>
					User Type{" "}
					{getsortfield === "user_type" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "user_type" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "user_type" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "user_type",
			className: `${usersModule[2].active_status ? "": "border-endstyle"}`,
			key: "user_type",
			align: "center",
			sorter: (a, b) => a.user_type.localeCompare(b.user_type),
			  width: matchColumn(" ") && 200,
			...SearchColumns("user_type", "User Type"),
		},
		{
			title: <span>Active Status</span>,
			dataIndex: "is_active",
			key: "is_active",
			align: "center",
			className: `${usersModule[2].active_status ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
		{
			title: "Action",
			dataIndex: "edit",
			className: `${usersModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
			key: "edit",
			align: "center",
			render: (value, row) => {
				return (
					<>
						<EditOutlined size={50} onClick={() => editHandler(row)} />
					</>
				);
			},
		},
	];
	
	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.id);
	};
	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value === true ? 1 : 0,
			updated_by: user?.user_id,
		};

		updateUserStatus({
			data,
			id: row.id,
			token: token,
		});
	}

	//update Customers details
	useEffect(() => {
		if (updateUserStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateUserStatusApi[1].data?.message,
			});
		}
		if (updateUserStatusApi[1].error) {
			/// error details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateUserStatusApi[1].error.data.message ||
					updateUserStatusApi[1].error.error ||
					updateUserStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateUserStatusApi[1].isLoading]);

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "users") {
			usersList.refetch();
		}
	}, [refresh]);

	return (
		<>
			{usersList.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						<AppModal
							showModal={showHide}
							onClose={closeModal}
							afterClose={afterClose}
							width={"90%"}
							confirmProp={true}
							height={500}
							element={
								<UserModal
									//passing tab of customers
									updateTab={updateTab}
									//passing row customer editId
									editId={editId}
									initialValues={preDefualtStateValues}
									setShowHide={setShowHide}
									//passing deafult dependency to initial state
									afterClose={initialStateVal}
								/>
							}
						/>

						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
						{usersModule.map((item,index) => {
							if (item.add_button) {
								return (
									<div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
							}
							return null;
						})}
					</div>
					<div style={{ clear: "both" }}></div>
					<Row>
						<AppTable
							sort={sort}
							loading={updateUserStatusApi[1].isLoading}
							search={search}
							columns={columns}
							dataSource={data}
						/>
					</Row>
				</>
			)}
		</>
	);
};

export default User;
