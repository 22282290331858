import { createSlice } from "@reduxjs/toolkit";

const closerList = createSlice({
  name: "closerList",
  initialState: {
    closerList: [],
  },
  reducers: {
    closerListAction: (state, action) => {
      state.closerList = action.payload;
    },
  },
});

export const {closerListAction} = closerList.actions;

export default closerList.reducer;