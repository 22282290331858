import AppInput from "../../../../../Components/AppInput/AppInput";
import AppSelect from "../../../../../Components/AppSelect/AppSelect";
import "./TaxCollectorInfo.css";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import AppNumeric from "../../../../../Components/AppNumeric/AppNumeric";
import { MultiJurisdiction } from "../../../../../Components/MultiJurisdiction/MultiJurisdiction";
import AppTextEditor from "../../../../../Components/AppTextEditor/AppTextEditor";

import {
  useAddTaxToParcelMutation,
  useAutomationParcelTaxCollectorListMutation,
  useCheckParcelTaxCollectorMutation,
  useEditParcelJuriTaxCollectorMutation,
  useEditParcelTaxCollectorMutation,
  useGetParcelTaxCollectorMutation,
  useParcelTaxCollectorListMutation,
  useUpdateParcelTaxCollectorMutation,
} from "../../../../../Redux/Services/taxcollectorInfo";
import {
  useAutomationStatusUpdateMutation,
  useAutomationTokenMutation,
  useTaxCollectorListBaseOnCountyMutation,
  useTaxCollectorTableFilterListMutation,
  useTaxDisplayCertListQuery,
  useTaxReadOnlyListQuery,
  useTestOrderDetailsMutation,
  useUpdateLatestDataMutation,
} from "../../../../../Redux/Services/TaxCollector";
import AppButton from "../../../../../Components/AppButton/AppButton";
import {
  convertDateFormat,
  mobileFormat,
  removeArrayBlank,
  removeBlank,
  scrollComp,
} from "../../../../../Controllers/Functions";
import { Col, Row } from "react-bootstrap";
import { useContext } from "react";
import { GlobalContext } from "../../../../../App";
import {
  Alert,
  Collapse,
  notification,
  Tooltip,
  Steps,
  Radio,
  Spin,
  Modal,
  Tabs,
  Button,
} from "antd";
import {
  useJuriInfoSelectMutation,
  useParcelTaxJuriListMutation,
  useUpdateParcelJuriAutomationMutation,
} from "../../../../../Redux/Services/Jurisdiction";
import { useCallback } from "react";
import ViewComments from "../../../../../Components/ViewComments/ViewComments";
import { FaPlus } from "react-icons/fa";
import {
  MdAutorenew,
  MdDone,
  MdError,
  MdOutlineCloudDone,
  MdOutlineCloudOff,
  MdOutlineDelete,
  MdOutlineNotStarted,
} from "react-icons/md";
import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { getCookie } from "../../../../../Redux/reducers/authReducer";
import { AiFillCloseCircle, AiFillExclamationCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";

import { parcelContext } from "../../OrderEditor";
import AppSwitch from "../../../../../Components/AppSwitch/AppSwitch";
import NumberInput from "react-phone-number-input/input";
import { MultiJurisdiction2 } from "../../../../../Components/MultiJurisdiction/MultiJurisdiction2";
const parse = require("html-react-parser");
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { confirm } = Modal;

export const TaxCollectorDetails = ({
  data,
  editId,
  info,
  refresh,
  parcelAutomationStatus,
  automationStart,
  setProcessbar = false,
  validateConflicts = false,
  orderDetailsTestResult = false,
}) => {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;
  const [currentYearBill, setCurrentYearBill] = useState(null);
  const [taxInfoId, setTaxInfoId] = useState("");

  const { progress, initiated, failed, success } = parcelAutomationStatus;

  const location = useLocation();
  //states
  const [mode, setMode] = useState("view");

  const [getError, setGetError] = useState(false);

  const [taxDataList, setTaxListData] = useState([]);

  const { token, user } = useSelector((state) => state.auth);
  const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

  const [taxAutonmationProgressbar, setTaxAutonmationProgressbar] =
    useState(false);

  const initialState = {
    taxcollector_id: "",
    payment_plan: "",
    total: "",
    grand_total: "",
    tax_collector_name: "",
    tax_bill_year: "",
    payee_name: "",
    phone_number: "",
    instalment_per_year: "",
    due_date: "",
    type: "",
    is_manual: false,
    taxing_unit: "",
    pay_plan: "",
    del_date: "",
    manual_or_auto: "",
    website: "",
    tax_year: "",
    extractor_name: "",
    address_one: "",
    selDisplay: "",
    selReadOnly: "",
    current_year_bill: "",
    parcel_jurisdiction_information_list: [],
  };
  const [values, setValues] = useState(initialState);

  const [parcel_jurisdiction_information, setParcel_jurisdiction_information] =
    useState([]);
  const [
    add_parcel_jurisdiction_information,
    setAdd_parcel_jurisdiction_information,
  ] = useState([]);

  const [
    delete_parcel_jurisdiction_information,
    setDelete_parcel_jurisdiction_information,
  ] = useState([]);

  const [
    edit_parcel_jurisdiction_information,
    setEdit_parcel_jurisdiction_information,
  ] = useState([]);

  const [getTaxcollectorList, setTaxcollectorList] = useState([]);
  const [juriListOptions, setJuriListOptions] = useState([]);
  const [display_oncert_comment, setDisplay_oncert_comment] = useState("");
  const [read_only_comment, setRead_only_comment] = useState("");
  const [commetsView, setCommetsView] = useState("display-cert");

  const [taxOrderRefNumber, setTaxOrderRefNumber] = useState({
    order: "",
    taxId: "",
  });
  const [automationReqCount, setAutomationReqCount] = useState(0);

  const ReadOnlyList = useTaxReadOnlyListQuery(token);
  const DisplayOnCertList = useTaxDisplayCertListQuery(token);

  //getting tax collector info
  const [editTaxCollectorInfo, editTaxCollectorInfoResult] =
    useEditParcelTaxCollectorMutation();

  //api
  //getting tax collector list
  const [taxCollectorsListApiCall, taxCollectorsListApi] =
    useParcelTaxCollectorListMutation();

  //getting tax collector info
  const [editJuriInfo, editJuriInfoResult] =
    useEditParcelJuriTaxCollectorMutation();

  //getting tax collector info
  const [updateLatestJuriData, updateLatestJuriDataResult] =
    useUpdateLatestDataMutation();

  //api tax collector list
  const [taxCollectorList, taxCollectorListResult] =
    useTaxCollectorListBaseOnCountyMutation();

  //getting info of tax collector details base on taxcollector id
  const [taxCollectorInfo, taxCollectorInfoResult] =
    useGetParcelTaxCollectorMutation();

  //add info of tax collector details
  const [addTaxCollectorInfo, addTaxCollectorInfoResult] =
    useAddTaxToParcelMutation();

  //update info of tax collector details
  const [updateTaxCollectorInfo, updateTaxCollectorInfoResult] =
    useUpdateParcelTaxCollectorMutation();

  //update info of tax collector details
  const [juriList, juriListResult] = useParcelTaxJuriListMutation();

  //check info of tax collector details
  const [checkTax, checkTaxResult] = useCheckParcelTaxCollectorMutation();

  //delete tax handler
  const deleteTaxInfoHandler = () => {};

  //useEffects
  useEffect(() => {
    if (taxCollectorsListApi.isSuccess) {
      setTaxListData(taxCollectorsListApi.data?.data);
      // //getting list first index info
      const id = taxCollectorsListApi.data?.data[0]?.id;
      setTaxInfoId(id);
      editTaxCollectorInfo({ id, token });
    }

    if (taxCollectorsListApi.isError) {
      setTaxListData([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: taxCollectorsListApi.isLoading });
  }, [taxCollectorsListApi.isLoading]);

  //useEffects juri latest update
  useEffect(() => {
    if (updateLatestJuriDataResult.isSuccess) {
      //caling juri info
      editTaxCollectorInfo({ id: taxInfoId, token });
    }

    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: updateLatestJuriDataResult.isLoading,
    });
  }, [updateLatestJuriDataResult.isLoading]);

  //useEffects for tax list info
  useEffect(() => {
    if (editTaxCollectorInfoResult.isSuccess) {
      const { taxcollector_id, current_year_bill } =
        editTaxCollectorInfoResult.data?.data;
      setValues({
        ...initialState,
        ...editTaxCollectorInfoResult.data?.data,
        current_year_bill: editTaxCollectorInfoResult.data?.data.tax_bill_year,
        id: editId,
      });
      const { read_only_comment, display_oncert_comment } =
        editTaxCollectorInfoResult.data?.data;
      setDisplay_oncert_comment(display_oncert_comment);
      setRead_only_comment(read_only_comment);

      setEdit_parcel_jurisdiction_information([]);
      setAdd_parcel_jurisdiction_information([]);
      setDelete_parcel_jurisdiction_information([]);
      ///adding current year value
      setCurrentYearBill(current_year_bill);

      //juri list
      const { county_id } = data;
      juriList({ data: { county_id, tax_id: taxcollector_id }, token });

      //caling juri info
      editJuriInfo({ id: taxInfoId, token });
    }
    if (editTaxCollectorInfoResult.isError) {
      setValues(initialState);
      setDisplay_oncert_comment("");
      setRead_only_comment("");
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: editTaxCollectorInfoResult.isLoading,
    });
  }, [editTaxCollectorInfoResult.isLoading]);

  //useEffects for tax list info
  useEffect(() => {
    if (editJuriInfoResult.isSuccess) {
      setValues({
        ...values,
        grand_total_id: editJuriInfoResult.data.grand_total_id,
        parcel_jurisdiction_information_list: editJuriInfoResult.data.data,
      });
    }

    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: editJuriInfoResult.isLoading,
    });
  }, [editJuriInfoResult.isLoading]);

  ///
  useEffect(() => {
    setGetError(false);
    setEdit_parcel_jurisdiction_information([]);
    setAdd_parcel_jurisdiction_information([]);
    setDelete_parcel_jurisdiction_information([]);
  }, [mode]);

  useEffect(() => {
    if (orderDetailsTestResult.isSuccess) {
      const { OrderStatus, result, automation_ref_no } =
        orderDetailsTestResult.data.data;

      if (OrderStatus === "3") {
        //calling api for taxCOllector info
        editTaxCollectorInfo({ id: taxInfoId, token });
        //caling juri info
        editJuriInfo({ id: taxInfoId, token });
      }
    }
    if (orderDetailsTestResult.isError) {
      //calling api for taxCOllector info
      editTaxCollectorInfo({ id: taxInfoId, token });
      //caling juri info
      editJuriInfo({ id: taxInfoId, token });
    }
  }, [orderDetailsTestResult.isLoading]);

  useEffect(() => {
    const { state_id, county_id, year, id } = data;
    if (state_id) {
      taxCollectorList({
        county_id,
        token,
      });

      taxCollectorsListApiCall({ id, year, token });
    }
    setParcel_jurisdiction_information([]);
    setTaxcollectorList([]);
    setJuriListOptions([]);
    setValues(initialState);
    setTaxListData([]);
    setCurrentYearBill(null);
    setTaxInfoId("");
    setDisplay_oncert_comment("");
    setRead_only_comment("");
    setMode("view");
  }, [refresh, data.id]);

  // useEffect for tax list based on state selection
  useEffect(() => {
    if (taxCollectorListResult.isSuccess) {
      setTaxcollectorList(taxCollectorListResult.data?.data);
      //calling juri list

      //fecth juri list
    }
    if (taxCollectorListResult.isError) {
      setGetError(
        taxCollectorListResult.error?.data?.message ||
          taxCollectorListResult.error.error ||
          taxCollectorListResult.error.data?.msg.sqlMessage
      );
      setTaxcollectorList([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: taxCollectorListResult.isLoading });
  }, [taxCollectorListResult.isLoading]);

  // useEffect for juri list based on county and taxlist selction selection
  useEffect(() => {
    if (juriListResult.isSuccess) {
      setJuriListOptions(juriListResult.data?.data);
    }
    if (juriListResult.isError) {
      setJuriListOptions([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: juriListResult.isLoading });
  }, [juriListResult.isLoading]);

  // useEffect for tax info based on tax info
  useEffect(() => {
    if (taxCollectorInfoResult.isSuccess) {
      const { address_one, full_address, current_year_bill } =
        taxCollectorInfoResult.data?.data[0];
      setValues({
        ...initialState,
        taxcollector_id: values.taxcollector_id,
        tax_collector_name: values.tax_collector_name,
        ...taxCollectorInfoResult.data?.data[0],
        address_one: full_address || "",
        current_year_bill,
      });

      setCurrentYearBill(current_year_bill);

      //adding values
      // setValues({
      //   ...initialState,
      //   due_date,
      //   website,
      //   full_address: mailing_full_address,
      //   current_year_bill,
      // taxcollector_id: values.taxcollector_id,
      // tax_collector_name: values.tax_collector_name,
      //   instalment_per_year: inst_per_year,
      //   payee_name: extractor_name,
      // });
    }
    if (taxCollectorInfoResult.isError) {
      setGetError(
        taxCollectorInfoResult.error?.data?.message ||
          taxCollectorInfoResult.error.error ||
          taxCollectorInfoResult.error.data?.msg.sqlMessage
      );
    }
    //setGLobal Loader
    //global spinner
    setGlobalVal({ ...globalVal, loader: taxCollectorInfoResult.isLoading });
  }, [taxCollectorInfoResult.isLoading]);

  // useEffect for add tax info based on tax info
  useEffect(() => {
    if (addTaxCollectorInfoResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addTaxCollectorInfoResult.data?.message,
      });
      //setmode view
      setMode("view");
      //calling api for tax list
      const id = addTaxCollectorInfoResult.data.data;
      editTaxCollectorInfo({ id, token });
      taxCollectorsListApiCall({ id: data.id, year: data.year, token });
      setTaxInfoId(String(id));
    }
    if (addTaxCollectorInfoResult.isError) {
      setGetError(
        addTaxCollectorInfoResult.error?.data?.error ||
          addTaxCollectorInfoResult.error?.data?.message ||
          addTaxCollectorInfoResult.error.error ||
          addTaxCollectorInfoResult.error.data?.msg.sqlMessage
      );
    }
    //setGLobal Loader
    //global spinner
    setGlobalVal({ ...globalVal, loader: addTaxCollectorInfoResult.isLoading });
  }, [addTaxCollectorInfoResult.isLoading]);

  // useEffect for update tax info based
  useEffect(() => {
    if (updateTaxCollectorInfoResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateTaxCollectorInfoResult.data?.message,
      });
      //setmode view
      setMode("view");
      editTaxCollectorInfo({ id: taxInfoId, token });
      //refecth parcel info

      // //calling api for parcel api validate
      validateConflicts({
        data: { order_id: location?.state?.id },
        token,
      });

      setGlobalVal({
        ...globalVal,
        orderCertificate: !globalVal.orderCertificate,
      });
    }
    if (updateTaxCollectorInfoResult.isError) {
      setGetError(
        updateTaxCollectorInfoResult.error?.data?.error ||
          updateTaxCollectorInfoResult.error?.data?.message ||
          updateTaxCollectorInfoResult.error.error ||
          updateTaxCollectorInfoResult.error.data?.msg.sqlMessage
      );
    }
    //setGLobal Loader
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: updateTaxCollectorInfoResult.isLoading,
    });
  }, [updateTaxCollectorInfoResult.isLoading]);

  // useEffect for update tax info based
  useEffect(() => {
    if (checkTaxResult.isSuccess) {
      if (checkTaxResult.data.data) {
        setGetError("Tax collector already added to the parcel!");
      } else {
        setGetError(false);
      }
    }

    //setGLobal Loader
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: checkTaxResult.isLoading,
    });
  }, [checkTaxResult.isLoading]);

  const taxCollInfoList = [];
  taxDataList?.map(({ tax_collector_name }) => {
    taxCollInfoList.push(tax_collector_name);
  });

  const uniqeTaxList = getTaxcollectorList?.filter(({ name }) => {
    return !taxCollInfoList.includes(name);
  });

  const inputs = [
    {
      id: 1,
      name: "taxcollector_id",
      label: "Name",
      placeholder: "Taxcollector Name",
      type: "select",
      value:
        (mode === "view"
          ? values.tax_collector_name
          : values.taxcollector_id) || "",
      options: uniqeTaxList,
      errorMessage: "Please select tax collector!",
      column: "3",
      required: true,
      disabled: mode !== "add",
    },
    {
      id: 12,
      name: "address_one",
      type: "text",
      label: "Address",
      placeholder: "Enter Address",
      value: values.address_one || "",
      column: "3",
      errorMessage: "Please enter address!",
      required: false,
    },
    {
      id: 10,
      name: "website",
      label: "Website",
      placeholder: "Enter Website",
      type: "text",
      value: values.website || "",
      errorMessage: "Please enter website!",
      column: "3",
      required: false,
    },
    {
      id: 1644,
      name: "phone_number",
      label: "Phone Number",
      placeholder: "Enter Phone Number",
      type: "number",
      value: values.phone_number || "",
      errorMessage: "Please enter phone number!",
      column: "3",
      required: false,
    },
    {
      id: 3,
      name: "instalment_per_year",
      label: "Installment/Yr",
      placeholder: "Enter Installment/Yr",
      type: "text",
      value: values.instalment_per_year || "",
      errorMessage: "Please enter Installment/Yr!",
      column: "3",
      maxLength: 3,
      required: false,
    },
    {
      id: 34545,
      name: "payment_plan",
      label: "Payment Plan",
      placeholder: "Enter Payment Plan",
      type: "text",
      value: values.payment_plan || "",
      column: "3",
    },
    {
      id: 4,
      name: "due_date",
      label: "Due Date",
      placeholder: "Due Date",
      type: "date",
      value: values?.due_date || false,
      errorMessage: "Please enter due date!",
      column: "3",
      required: false,
    },
    {
      id: 5,
      name: "type",
      label: "Type",
      placeholder: "Enter Type",
      type: "text",
      value: values.type || "",
      errorMessage: "Please enter type!",
      column: "3",
      required: false,
    },

    {
      id: 16235,
      name: "account_number",
      type: "text",
      placeholder: "Enter Account #",
      errorMessage: "Please enter account #!",
      label: "Account #",
      column: "3",
      tab: false,
    },
    // {
    //   id: 11,
    //   name: "current_year_bill",
    //   value: values.current_year_bill || "",
    //   type: "text",
    //   placeholder: "Enter Bill Rate Year",
    //   errorMessage: "Please enter 4 digits Bill Rate Year!",
    //   label: "Bill Rate Year",
    //   column: "3",
    //   required: true,
    //   maxLength: 4,
    //   minLength: 4,
    //   tab: false,
    // },

    {
      id: 11656,
      name: "current_year_bill",
      value: values?.current_year_bill || "",
      type: "text",
      placeholder: "Enter Current Year Bill",
      errorMessage: "Please enter 4 digits Current Year Bill!",
      label: "Current Year Bill",
      column: "3",
      required: values?.is_manual,
      disabled: !values?.is_manual,
      maxLength: 4,
      minLength: 4,
    },
    {
      id: 175645,
      name: "is_manual",
      checked: values.is_manual || "",
      type: "switch",
      column: "2",
      label: "Is Manual",
      required: true,
      margin: "3",
    },
  ];

  //select handler
  const selectHandler = (property, value, attribute) => {
    // clearStatus();
    let name = attribute.getAttribute("data_name");
    setGetError(false);
    setValues({ ...values, [property]: value });
    if (property === "taxcollector_id") {
      setValues({
        ...values,
        [property]: value,
        tax_collector_name: name,
      });
      if (value) {
        if (mode === "add") {
          taxCollectorInfo({ id: value, token });
          //check tax
          checkTax({
            data: {
              parcel_id: data.id,
              taxcollector_id: value,
              tax_year: data.year,
            },
            token,
          });
        }

        const { county_id } = data;
        //fecth juri list
        juriList({ data: { county_id, tax_id: value }, token });
      }
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialState);
    setGetError(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setGetError(false);
    setValues({
      ...values,

      [name]: value,
    });
    if (name === "tax_year" || name === "instalment_per_year") {
      setValues({
        ...values,
        [name]: value.replace(/[^0-9.]/gi, ""),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parcel_jurisdiction_information.length === 0 && mode === "add") {
      setGetError("Please add at least one Jurisdiction");
    } else {
      //if no errors
      removeBlank(values);
      delete values["selReadOnly1"];
      delete values["id"];
      delete values["selDisplay1"];
      const { current_year_bill, ...rest } = values;
      if (mode === "add") {
        removeArrayBlank(parcel_jurisdiction_information);

        // console.log( {
        //   ...rest,
        //   tax_bill_year: values?.is_manual
        //     ? current_year_bill
        //     : currentYearBill,
        //   parcel_jurisdiction_information,
        //   current_year_bill: currentYearBill,
        //   parcel_id: info.id,
        //   tax_year: data.year,
        //   read_only_comment,
        //   display_oncert_comment,
        // });

        addTaxCollectorInfo({
          data: {
            ...rest,
            tax_bill_year: values?.is_manual
              ? current_year_bill
              : currentYearBill,
            parcel_jurisdiction_information,
            current_year_bill: currentYearBill,
            parcel_id: info.id,
            tax_year: data.year,
            read_only_comment,
            display_oncert_comment,
          },
          token,
        });
      } else if (mode === "edit") {
        removeArrayBlank(add_parcel_jurisdiction_information);
        removeArrayBlank(edit_parcel_jurisdiction_information);
        // console.log({
        //   ...rest,
        //   tax_bill_year: values?.is_manual
        //     ? current_year_bill
        //     : currentYearBill,
        //   current_year_bill: currentYearBill,
        //   add_parcel_jurisdiction_information,
        //   delete_parcel_jurisdiction_information,
        //   edit_parcel_jurisdiction_information,
        //   tax_year: data.year,
        //   read_only_comment,
        //   display_oncert_comment,
        // });

        updateTaxCollectorInfo({
          data: {
            ...rest,
            tax_bill_year: values?.is_manual
              ? current_year_bill
              : currentYearBill,
            current_year_bill: currentYearBill,
            add_parcel_jurisdiction_information,
            delete_parcel_jurisdiction_information,
            edit_parcel_jurisdiction_information,
            tax_year: data.year,
            read_only_comment,
            display_oncert_comment,
          },
          id: taxInfoId,
          token,
        });
      }
    }
  };

  //useEffect for is manual true if any chnages happened in jurisdiction
  useEffect(() => {
    if (
      mode === "edit" &&
      (edit_parcel_jurisdiction_information.length > 0 ||
        add_parcel_jurisdiction_information.length > 0 ||
        delete_parcel_jurisdiction_information.length > 0)
    ) {
      setValues({ ...values, is_manual: true });
    }
  }, [
    edit_parcel_jurisdiction_information,
    add_parcel_jurisdiction_information,
    delete_parcel_jurisdiction_information,
  ]);

  // show errors
  useEffect(() => {
    if (getError) {
      scrollComp("taxinfo_Error");
    }
  }, [getError]);

  const taxSeleHandler = (e) => {
    //seting tax info id
    const id = e.target.value;
    setTaxInfoId(id);
    editTaxCollectorInfo({ id, token });
    if (!id) {
      setMode("view");
    }
    //testing
  };

  //useCallBack for MaultiJuri
  const MultiJuri = useCallback(() => {
    return (
      <div
        className={`row mt-1 px-3  ${
          (mode === "view" &&
            values.parcel_jurisdiction_information_list.length > 0) ||
          mode === "edit" ||
          mode === "add"
            ? null
            : "invisible"
        }`}
      >
        <div className="col-12">
          <MultiJurisdiction2
            parcelData={data}
            taxData={values}
            isedit={mode === "edit" ? false : mode !== "add"}
            addFormVal={(total, grandTotal) => {
              values["total"] = total;
              values["grand_total"] = grandTotal;
            }}
            juriListOptions={juriListOptions}
            //while adding tax details
            newAdd={(value) => {
              setGetError(false);
              setParcel_jurisdiction_information(value);
            }}
            //update
            updateJurisdiction={(value) => {
              // setGetError(false);

              let juriData = juriListOptions?.filter((ele) => {
                return ele.display_name === value?.jurisdiction_name;
              });

              if (mode === "edit") {
                if (
                  add_parcel_jurisdiction_information?.length > 0 &&
                  add_parcel_jurisdiction_information?.find(
                    (val) => val["tempId"] === value.tempId
                  )
                ) {
                  add_parcel_jurisdiction_information.forEach((item) => {
                    if (item.tempId == value.tempId) {
                      item.due_date = value.due_date;
                      item.prior_years = value.prior_years;
                      item.collects = value.collects;
                      item.next_balance = value.next_balance;
                      item.balance = value.balance;
                      item.total_due = value.total_due;
                      item.total_amount_paid = value.total_amount_paid;
                      item.stand_by_fees = value.stand_by_fees;
                      item.penalty = value.penalty;
                      item.base_amount = value.base_amount;
                      item.tax_amount = value.tax_amount;
                      item.tax_rate = value.tax_rate;
                      item.jurisdiction_id =
                        value?.jurisdiction_id !== 999998
                          ? juriData[0]?.id
                          : value?.jurisdiction_id;
                      item.jurisdiction_name = value.jurisdiction_name;
                      item.tax_office_code = value.tax_office_code;
                      item.tax_office_code = value.tax_office_code;
                      item.bonds_approved = value.bonds_approved;
                      item.bonds_issued = value.bonds_issued;
                      item.disabled_person = value.disabled_person;
                      item.exemption_other = value.exemption_other;
                      item.first_responder = value.first_responder;
                      item.ov65 = value.ov65;
                      item.solar = value.solar;
                      item.hom = value.hom;
                    }
                  });
                } else if (value["tempId"]) {
                  setAdd_parcel_jurisdiction_information([
                    ...add_parcel_jurisdiction_information,
                    {
                      ...value,
                      jurisdiction_id:
                        value?.jurisdiction_id !== 999998
                          ? juriData[0]?.id
                          : value?.jurisdiction_id,
                    },
                  ]);
                }

                if (
                  edit_parcel_jurisdiction_information?.length > 0 &&
                  edit_parcel_jurisdiction_information?.find(
                    (val) => val["id"] === value.id
                  )
                ) {
                  edit_parcel_jurisdiction_information.forEach((item) => {
                    if (item.id == value.id) {
                      item.due_date = value.due_date;
                      item.prior_years = value.prior_years;
                      item.collects = value.collects;
                      item.next_balance = value.next_balance;
                      item.balance = value.balance;
                      item.total_due = value.total_due;
                      item.total_amount_paid = value.total_amount_paid;
                      item.stand_by_fees = value.stand_by_fees;
                      item.penalty = value.penalty;
                      item.base_amount = value.base_amount;
                      item.tax_amount = value.tax_amount;
                      item.tax_rate = value.tax_rate;
                      item.jurisdiction_id =
                        value?.jurisdiction_id !== 999998
                          ? juriData[0]?.id
                          : value?.jurisdiction_id;
                      item.jurisdiction_name = value.jurisdiction_name;
                      item.tax_office_code = value.tax_office_code;
                      item.tax_office_code = value.tax_office_code;
                      item.bonds_approved = value.bonds_approved;
                      item.bonds_issued = value.bonds_issued;
                      item.disabled_person = value.disabled_person;
                      item.exemption_other = value.exemption_other;
                      item.first_responder = value.first_responder;
                      item.ov65 = value.ov65;
                      item.solar = value.solar;
                      item.hom = value.hom;
                    }
                  });
                } else if (value?.id) {
                  setEdit_parcel_jurisdiction_information([
                    ...edit_parcel_jurisdiction_information,
                    {
                      ...value,
                      jurisdiction_id:
                        value?.jurisdiction_id !== 999998
                          ? juriData[0]?.id
                          : value?.jurisdiction_id,
                    },
                  ]);
                }
              }
            }}
            //delete
            deleteJurisdiction={(value) => {
              if (mode === "edit") {
                if (value?.id) {
                  if (
                    value?.jurisdiction_id === 999998 &&
                    values?.grand_total_id
                  ) {
                    setDelete_parcel_jurisdiction_information([
                      ...delete_parcel_jurisdiction_information,
                      String(value.id),
                      String(values?.grand_total_id),
                    ]);
                  } else {
                    setDelete_parcel_jurisdiction_information([
                      ...delete_parcel_jurisdiction_information,
                      String(value.id),
                    ]);
                  }
                } else {
                  setAdd_parcel_jurisdiction_information(
                    add_parcel_jurisdiction_information?.filter((e) => {
                      return e.tempId !== value.tempId;
                    })
                  );
                }
              }
            }}
            afterClose={mode}
            initailValArr={values.parcel_jurisdiction_information_list}
          />
        </div>
      </div>
    );
  }, [
    !juriListResult.isLoading,
    mode,
    juriListOptions,
    parcel_jurisdiction_information,
    values.parcel_jurisdiction_information_list,
    add_parcel_jurisdiction_information,
    delete_parcel_jurisdiction_information,
    edit_parcel_jurisdiction_information,
    taxCollectorsListApi.isLoading,
    !editJuriInfoResult.isLoading,
  ]);

  const genExtra = () => (
    <div
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    >
      <h6 className="mt-2 fs-11 fw-bold text-white">
        Updated Date : {values.updated_at}
      </h6>
    </div>
  );

  //AppSwitch box
  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    if (value) {
      setValues({ ...values, [property]: value });
    } else {
      setValues({
        ...values,
        [property]: value,
        current_year_bill: currentYearBill,
      });
    }
    setGetError(false);
  };
  return (
    <div className="mt-2">
      <Collapse
        className="rounded-3 border-0 text-white "
        expandIcon={() => <></>}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3  "
          header={
            <div
              className=" d-flex align-items-center "
              style={{ fontSize: 18, marginBottom: 0 }}
            >
              TaxCollector Information
            </div>
          }
          //
          key="1"
          extra={values?.updated_at && genExtra()}
        >
          <div className=" border bg-light p-2">
            <div className="row">
              <div
                className={`col-12 col-md-8 d-flex gap-2 align-items-center `}
              >
                {taxDataList.length > 0 && (
                  <select
                    value={taxInfoId}
                    onChange={taxSeleHandler}
                    className="border rounded-3 border-secondary w-25  p-1 border-2  "
                  >
                    <option value={""}>Select Tax Collector</option>
                    {taxDataList?.map((ele) => {
                      return (
                        <option value={ele.id} key={ele.id}>
                          {ele.tax_collector_name}
                        </option>
                      );
                    })}
                  </select>
                )}
                {mode !== "edit" &&
                  mode !== "add" &&
                  parcelEditInOrder.map((item, index) => {
                    if (item.taxcollector_bills) {
                      return (
                        <Tooltip
                          key={index}
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="right"
                          title={"Add Bills"}
                        >
                          <div
                            onClick={() => {
                              setMode("add");
                              setValues(initialState);
                              setDisplay_oncert_comment("");
                              setRead_only_comment("");
                            }}
                            className="add_icon"
                          >
                            <FaPlus />
                          </div>
                        </Tooltip>
                      );
                    }
                  })}

                {/* {taxDataList.length > 1 && (
            <div onClick={deleteTaxInfoHandler} className="add_icon">
              <MdOutlineDelete />
            </div>
          )} */}
              </div>
              {taxDataList.length > 0 && (
                <div className="col-12 col-md-4 ms-auto d-flex align-items-center justify-content-center">
                  <div className="action-buttons d-flex align-items-center ms-auto  gap-2  ">
                    {success ? (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Automation Success"}
                      >
                        <MdOutlineCloudDone size={22} color="green" />{" "}
                      </Tooltip>
                    ) : failed ? (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Automation Failed"}
                      >
                        <MdOutlineCloudOff size={22} color="red" />{" "}
                      </Tooltip>
                    ) : progress ? (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Automation In Progress"}
                      >
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 24,
                              }}
                              spin
                            />
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Automation Not Initiated"}
                      >
                        <MdOutlineNotStarted size={22} color="#FF7000" />{" "}
                      </Tooltip>
                    )}
                    {!progress && (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="leftBottom"
                        title={
                          values.is_manual
                            ? "Fetch Bills[The bills has been updated manually]"
                            : "Fetch Bills"
                        }
                      >
                        <div
                          onClick={() => {
                            //automation start from props
                            automationStart({
                              token,
                              data: {
                                order_id: location?.state?.id,
                                parcel_id: data.id,
                              },
                            });
                            setProcessbar({
                              ...parcelAutomationStatus,
                              progress: true,
                              failed: false,
                              success: false,
                            });
                          }}
                          className="add_icon"
                        >
                          <MdAutorenew color="#0d6efd" />
                        </div>
                      </Tooltip>
                    )}
                    {mode === "view" && taxDataList.length > 0 && taxInfoId ? (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="bottom"
                        title={"Edit"}
                      >
                        {parcelEditInOrder.map((item, index) => {
                          if (item.edit_taxcollector) {
                            return (
                              <div key={index} className="add_icon ms-auto">
                                <div
                                  onClick={() => {
                                    setMode("edit");
                                    //calling juri
                                    if (mode === "view") {
                                      const { county_id } = data;
                                      juriList({
                                        data: {
                                          county_id,
                                          tax_id: values.taxcollector_id,
                                        },
                                        token,
                                      });
                                    }
                                  }}
                                  className="add_icon "
                                >
                                  <EditOutlined color="#0d6efd" />
                                </div>
                              </div>
                            );
                          }
                        })}
                      </Tooltip>
                    ) : (
                      (((taxDataList.length > 0 || mode === "add") &&
                        taxInfoId) ||
                        mode === "add") && (
                        <Tooltip
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          placement="bottom"
                          title={mode === "view" ? "Edit" : "Close"}
                        >
                          <div
                            onClick={() => {
                              setMode("view");
                              if (mode === "edit" || mode === "add") {
                                editTaxCollectorInfo({
                                  id: taxInfoId,
                                  token,
                                });
                              }
                              //calling again
                              if (mode === "view") {
                                const { county_id } = data;
                                //fecth juri list
                                juriList({
                                  data: {
                                    county_id,
                                    tax_id: values.taxcollector_id,
                                  },
                                  token,
                                });
                              }
                            }}
                            className="add_icon "
                          >
                            <CloseOutlined color="#0d6efd" />
                          </div>
                        </Tooltip>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {getError && (
            <div className=" my-2 " id="taxinfo_Error">
              <Alert message={getError} type="error" showIcon />
            </div>
          )}
          {taxDataList.length > 0 || mode !== "view" ? (
            <form onSubmit={handleSubmit} className="pb-2">
              <div className="row mx-0">
                {inputs.map((input) => (
                  <>
                    {input.type === "number" &&
                      input.name === "phone_number" && (
                        <div className="col-12 col-md-3">
                          <Row className="mt-3">
                            <label className="ms-1 heading-5 text-dark ">
                              {input.label}{" "}
                              {mode !== "edit" && mode !== "add" && (
                                <div>
                                  {values[input.name]
                                    ? mobileFormat(values[input.name])
                                    : "NA"}
                                </div>
                              )}
                            </label>
                          </Row>
                          {(mode === "edit" || mode === "add") && (
                            <NumberInput
                              className="input-field mt-0 pb-2 w-100 heading-5"
                              maxLength={14}
                              minLength={14}
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              placeholder={input.placeholder}
                              defaultCountry="US"
                              value={values[input.name]}
                              onChange={(value) => {
                                setValues({
                                  ...values,
                                  [input.name]: value,
                                });

                                setGetError(false);
                              }}
                            />
                          )}
                        </div>
                      )}

                    {input.type === "switch" && (
                      <AppSwitch
                        disabled={mode !== "edit" && mode !== "add"}
                        key={input.id}
                        value={values[input.name]}
                        onChange={appSwitchHanler}
                        {...input}
                      />
                    )}
                    {(input.type === "text" || input.type === "date") && (
                      <AppInput
                        isEdit={mode !== "edit" && mode !== "add"}
                        classlabelStyle="ms-1 dark-label"
                        defaultSet={mode}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                        // isEdit={!editable}
                      />
                    )}
                    {/* {input.name === "tax_year" && (
                <AppNumeric
                  // isEdit={!editable}
                  classlabelStyle="ms-1 dark-label"
                  {...input}
                  maxLength={15}
                  value={values[input.name]}
                  thousandSeparator={true}
                  placeholder={input.placeholder}
                  prefix={"$"}
                  decimalScale={6}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      [input.name]: value,
                    });
                  }}
                />
              )} */}
                    {/* //appSelcet for taxcollector name  */}
                    {input.type === "select" &&
                      input.name === "taxcollector_id" && (
                        <AppSelect
                          key={input.id}
                          {...input}
                          isEdit={mode !== "edit" && mode !== "add"}
                          defaultSet={mode}
                          classlabelStyle="ms-1 dark-label"
                          classStyle=" form-control1 w-100 heading-5"
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>
                                {mode === "edit"
                                  ? values["tax_collector_name"]
                                  : "Select TaxCollector"}{" "}
                              </option>
                              {input.options.map((ele) => {
                                return (
                                  <option
                                    key={ele.id}
                                    data_name={ele.name}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                  </>
                ))}
                {values?.current_year_flag === "yes" &&
                  mode !== "view" &&
                  !values.is_manual && (
                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                      <div className="d-flex gap-1 align-items-center mt-2">
                        <Button
                          type="default"
                          size="small"
                          className="d-inline-block mt-1"
                          htmlType="button"
                          onClick={() => {
                            confirm({
                              title:
                                "Are you sure to update the current year bill?",
                              icon: <ExclamationCircleFilled />,
                              onOk() {
                                updateLatestJuriData({ id: taxInfoId, token });
                              },
                            });
                          }}
                        >
                          Update Tax Rate
                        </Button>
                        <div className="position-relative d-inline-block">
                          <div className="hover-text">
                            <AiFillExclamationCircle color="gray" />
                            <div className="text-hover">
                              Latest tax rates are available
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              {/* //multiJuri  */}
              {MultiJuri()}

              <div className="row">
                <div className="col-12 px-4">
                  {mode === "view" &&
                    (read_only_comment || display_oncert_comment) && (
                      <Collapse
                        accordion
                        defaultActiveKey={"1"}
                        className="comments-collase my-1"
                      >
                        <Panel header="Comments" key="1">
                          <Tabs
                            activeKey={commetsView}
                            onChange={(k) => {
                              setCommetsView(k);
                            }}
                          >
                            <TabPane
                              tab="Display On Certificate"
                              key="display-cert"
                            >
                              <div className="p-2">
                                {parse(display_oncert_comment)}
                              </div>
                            </TabPane>
                            <TabPane tab="Read Only" key="read-only">
                              <div className="p-2">
                                {parse(read_only_comment)}
                              </div>
                            </TabPane>
                          </Tabs>
                        </Panel>
                      </Collapse>
                    )}
                </div>
              </div>

              {
                // (mode === "addyear" && editId) ||{}
                (mode === "add" || mode === "edit") && (
                  <div
                    className=" mt-3 mx-2 editor border rounded-4 mb-2 p-2 new-order-comments "
                    id={"_id"}
                  >
                    <div
                      className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
                    >
                      <span>Comments</span>
                    </div>

                    <div className="row">
                      <div
                        className={`${
                          mode === "view" ? "bg-light-yello-200" : ""
                        } col-12 col-lg-${
                          mode === "edit" ||
                          mode === "addyear" ||
                          mode === "add"
                            ? "6"
                            : "12"
                        } px-2 `}
                      >
                        {!DisplayOnCertList.isFetching && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6
                                className={`${
                                  mode === "view"
                                    ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3 "
                                    : ""
                                } `}
                              >
                                Display On Cert
                              </h6>
                            </div>
                            {mode === "edit" ||
                            mode === "addyear" ||
                            mode === "add" ? (
                              <div className="col-12 col-lg-9">
                                <AppSelect
                                  value={values?.selDisplay1}
                                  classStyle="border w-100 p-1 my-2"
                                  onChange={(_, value) => {
                                    setValues({
                                      ...values,
                                      selDisplay1: value,
                                    });
                                    setDisplay_oncert_comment(
                                      display_oncert_comment + value
                                    );
                                    setGetError(false);
                                  }}
                                  options={
                                    <>
                                      <option value={""}>
                                        Select Display On Cert
                                      </option>
                                      {DisplayOnCertList.isSuccess &&
                                        DisplayOnCertList?.data?.data?.map(
                                          ({ title, comments, id }) => {
                                            return (
                                              <option key={id} value={comments}>
                                                {title}
                                              </option>
                                            );
                                          }
                                        )}
                                    </>
                                  }
                                />
                              </div>
                            ) : (
                              <>
                                {" "}
                                {
                                  <div className="col-12 py-1  ">
                                    <pre className="py-1  px-2">
                                      {parse(display_oncert_comment || "NA")}
                                    </pre>
                                  </div>
                                }
                              </>
                            )}
                          </div>
                        )}
                        {mode === "edit" ||
                        mode === "addyear" ||
                        mode === "add" ? (
                          <AppTextEditor
                            value={display_oncert_comment}
                            onChange={(newContent) => {
                              setGetError(false);

                              setDisplay_oncert_comment(newContent);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={` ${
                          mode === "view" ? "bg-light-blue-300" : ""
                        } col-12 col-lg-${
                          mode === "edit" ||
                          mode === "addyear" ||
                          mode === "add"
                            ? "6"
                            : "12 mb-2"
                        } px-2 `}
                      >
                        {!ReadOnlyList.isFetching && (
                          <div className="row align-items-center">
                            <div className="col-12 col-lg-3">
                              <h6
                                className={`${
                                  mode === "view"
                                    ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                    : ""
                                } `}
                              >
                                Read Only
                              </h6>
                            </div>
                            {mode === "edit" ||
                            mode === "addyear" ||
                            mode === "add" ? (
                              <div className="col-12 col-lg-9 ">
                                <AppSelect
                                  value={values?.selReadOnly1}
                                  classStyle="border w-100 p-1 my-2"
                                  onChange={(_, value) => {
                                    setValues({
                                      ...values,
                                      selReadOnly1: value,
                                    });
                                    setRead_only_comment(
                                      read_only_comment + value
                                    );
                                    setGetError(false);
                                  }}
                                  options={
                                    <>
                                      <option value={""}>
                                        Select Read Only
                                      </option>
                                      {ReadOnlyList.isSuccess &&
                                        ReadOnlyList?.data?.data.map(
                                          ({ title, comments, id }) => {
                                            return (
                                              <option key={id} value={comments}>
                                                {title}
                                              </option>
                                            );
                                          }
                                        )}
                                    </>
                                  }
                                />
                              </div>
                            ) : (
                              <div className="col-12  col-lg-12    ">
                                {
                                  <pre className=" py-1 px-2">
                                    {parse(read_only_comment || "NA")}
                                  </pre>
                                }
                              </div>
                            )}
                          </div>
                        )}
                        {mode === "edit" ||
                        mode === "addyear" ||
                        mode === "add" ? (
                          <AppTextEditor
                            value={read_only_comment}
                            onChange={(newContent) => {
                              setGetError(false);
                              setRead_only_comment(newContent);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )
              }

              {(mode === "edit" || mode === "addyear" || mode === "add") && (
                <Row>
                  <Col className="my-3 mt-5">
                    <div className="d-flex justify-content-center align-items-center">
                      {/* {mode === "add" && (
                        <div className="me-md-2 ">
                          <AppButton
                            disabled={getError}
                            onClick={handleReset}
                            title={"CLEAR"}
                            variant={"secondary-md"}
                            type={"button"}
                          />
                        </div>
                      )} */}
                      {/* {mode === "edit" && (
                  <select
                    value={values?.status_id}
                    onChange={(e) => {
                      setValues({ ...values, status_id: e.target.value });
                    }}
                    className="mx-2 fs-6 py-1 rounded-2"
                  >
                    {[
                      { name: "Open", id: 1 },
                      { name: "In Progress", id: 2 },
                      { id: 3, name: "Completed" },
                    ].map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                )} */}
                      <AppButton
                        disabled={getError}
                        type="submit"
                        title={mode === "add" ? "ADD BILL" : "UPDATE BILL"}
                        variant={"primary-md"}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </form>
          ) : (
            <h6 className="text-danger text-center my-2 pb-3">
              Tax Collector Details Not Found
            </h6>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};
