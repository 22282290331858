import AppTable from "../../../../Components/AppTable/AppTable";
import SearchColumns from "../../../../Components/SearchColumns";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Input } from "antd";
import { useGetProductTypeQuery } from "../../../../Redux/Services/productType";
import { useSelector } from "react-redux";
export const ProductType = ({ childTab }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");

  const { token } = useSelector((state) => state.auth);
  const productTypeListFetch = useGetProductTypeQuery(token);
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (value) => <span data-idfe={value}>{""}</span>,
      width: 1,
    },
    {
      title: (
        <span>
          Product Type Name{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "name" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      editable: true,
      ...SearchColumns("name", "Product Type"),
    },
  ];

  //useEfffect for creating for when productTypeList is loaded
  useEffect(() => {
    if (productTypeListFetch.isSuccess) {
      setData(productTypeListFetch.data?.data);
    }
  }, [productTypeListFetch, search]);

  useEffect(() => {
    setSearch("");
    if (childTab === "Product Type") {
      productTypeListFetch.refetch();
    }
  }, [childTab]);

  return (
    <>
      <div className="d-flex align-items-center float-end w-25 mb-1 ">
        {/* changing input values globaly  */}
        <div className=" me-1 w-100 mt-2">
          <Input
            placeholder="Search here"
            className="global-search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div>
      </div>
      <div style={{ clear: "both" }}></div>

      <AppTable
        sort={sort}
        loading={productTypeListFetch.isLoading}
        search={search}
        dataSource={data}
        columns={columns}
      />
    </>
  );
};
