import React, { useState } from "react";
// import "antd/dist/antd.css";
import {  FaPlus, FaSearch } from "react-icons/fa";
import {  Input } from "antd";
// import "./AnnotationMain.css";
import { AnnotationModal } from "./AnnotationModal";

import AppTable from "../../../Components/AppTable/AppTable";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import AppModal from "../../../Components/AppModal/AppModal";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { useEffect } from "react";
import { useAnnotationListQuery } from "../../../Redux/Services/Annotation";
import { EditOutlined } from "@ant-design/icons";
import SearchColumns from "../../../Components/SearchColumns";

export default function AnnotationMain({ refresh }) {
	const [showHide, setShowHide] = useState(false);
	const [initialStateVal, setInitialStateVal] = useState(false);
	//for editDetails id
	const [editId, setEditId] = useState(null);
	//const [search, setSearch] = useState("");
	// const { add_button } = useSelector((state) => state.flagRoles);
	//Annotation
	const [annotationValues] = useState({
		comment_type_id: "",
		title: "",
		read_only: true,
	});

	// const [getError, setGetError] = useState(null);
	// const accordianHandler = (id) => {
	// 	setSearch("");
	// 	setInitialStateVal(!initialStateVal);
	// 	if (!id) {
	// 		setAccordianId(null);
	// 	} else {
	// 		setAccordianId(id);
	// 	}
	// 	setEditId(null);
	// };
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//redux data store
	const { token } = useSelector((state) => state.auth);

	const { authColumn, commentsModule } = useSelector(
		(state) => state.flagRoles
	);

	//calling rtk query hook for geting Annotation list
	const Annotation = useAnnotationListQuery(token);

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	//create function for annotation fields
	function matchColumn(value) {
		if (authColumn.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}

	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.id);
	};

	const columns = [
		{
			title: (
				<span>
					Comment Type{" "}
					{getsortfield === "comment_type" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "comment_type" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "comment_type" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "comment_type",
			key: "comment_type",
			align: "center",
			width: 600,
			sorter: (a, b) => a.comment_type.localeCompare(b.comment_type),
			...SearchColumns("comment_type", "Comment Type"),
		},
		{
			title: (
				<span>
					Title{" "}
					{getsortfield === "title" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "title" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "title" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "title",
			key: "title",
			align: "center",
			width: 600,
			sorter: (a, b) => a.title.localeCompare(b.title),
			...SearchColumns("title", "Title"),
		},
		{
			title: "Action",
			dataIndex: "edit",
			className: `${commentsModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
			align: "center",
			key: "edit",
			render: (value, row) => {
				return (
					<>{<EditOutlined size={50} onClick={() => editHandler(row)} />}</>
				);
			},
		},
	];

	//useEffect for annotation list
	useEffect(() => {
		if (Annotation.isSuccess) {
			setData(Annotation.data?.data);
		}
	}, [Annotation]);

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "annotations") {
			Annotation.refetch();
		}
	}, [refresh]);

	// const getEditId = (id, accordion) => {
	// 	// if (tableId === accordianId) {

	// 	// }
	// 	setAccordianId(accordion);
	// 	setEditId(id);
	// 	// console.log(accordion)
	// };

	// const buttonHandler = (e) => {
	// 	setSearch("");
	// };
	// const genExtra = (name, key) => (
	// 	<div className={`modal-button  d-inline-block `}>
	// 		<button
	// 			id={key}
	// 			onClick={buttonHandler}
	// 			className={`accordian-button  ${
	// 				accordianId === key && "active-button"
	// 			}`}
	// 		>
	// 			{name}
	// 		</button>
	// 	</div>
	// );

	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	//create function for customer fields

	//ccreating function for geting Default state for edi and add functionality
	// function defaultClose(value) {
	// 	setInitialStateVal(!initialStateVal);
	// 	setEditId(null);
	// 	// setAccordianId(null);
	// }

	function closeModal(data) {
		setShowHide(data);
	}
	//create flag for initail state
	const afterClose = () => {
		setInitialStateVal(!initialStateVal);
		// setPreDefualtStateValues(preDefualtStateValues)
	};

	return (
		<>
			{Annotation.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						<AppModal
							showModal={showHide}
							onClose={closeModal}
							width={1000}
							height={400}
							afterClose={afterClose}
							// passing props for default values set
							element={
								<AnnotationModal
									AddVendorHandler={AddVendorHandler}
									editId={editId}
									afterClose={initialStateVal}
									setShowHide={setShowHide}
									initialValues={annotationValues}
								/>
							}
						/>
						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
						{commentsModule.map((item,index) => {
							if (item.add_button) {
								return (
									<div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
							}
							return null;
						})}
					</div>
					<div style={{ clear: "both" }}></div>
					<AppTable
						sort={sort}
						loading={Annotation.isFetching}
						search={search}
						dataSource={data}
						columns={columns}
					/>
				</>
			)}
		</>
	);
}
