import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const taxcollectorinfo = createApi({
  reducerPath: "taxcollectorinfo",
  tagTypes: ["taxcollectorinfo"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    AddTaxToParcel: builder.mutation({
      query: (data) => ({
        url: `/add-parcel-tax`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollectorinfo"],
    }),

    UpdateParcelTax: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-tax/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollectorinfo"],
    }),
    EditParcelTax: builder.query({
      query: (data) => ({
        url: `/edit-parcel-tax/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    EditParcelJurisdiction: builder.query({
      query: (data) => ({
        url: `/edit-parcel-jurisdiction/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    parcelTaxCollectorList: builder.mutation({
      query: (data) => ({
        url: `/parcel-tax-collector-list/${data.id}/${data.year}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["taxcollectorinfo"],
    }),

    automationParcelTaxCollectorList: builder.mutation({
      query: (data) => ({
        url: `/parcel-tax-collector-list/${data.id}/${data.year}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["taxcollectorinfo"],
    }),
    editParcelTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/edit-parcel-tax/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //ediit parcel tax juri
    editParcelJuriTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/edit-parcel-jurisdiction/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //update parcel tax info
    updateParcelTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-tax/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //get parcel tax info
    getParcelTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/parcel-tax-collector-information/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //check parcel tax info
    checkParcelTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/parcel-tax-information-check`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useAddTaxToParcelMutation,
  useEditParcelJurisdictionQuery,
  useEditParcelTaxQuery,
  useUpdateParcelTaxMutation,
  useParcelTaxCollectorListMutation,
  useEditParcelTaxCollectorMutation,
  useUpdateParcelTaxCollectorMutation,
  useGetParcelTaxCollectorMutation,
  useCheckParcelTaxCollectorMutation,
  useEditParcelJuriTaxCollectorMutation,
  useAutomationParcelTaxCollectorListMutation,
} = taxcollectorinfo;
