import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/authReducer";
import themeChanger from "./reducers/themeChanger";
import inputHandler from "./reducers/Controllers/inputReducer";

import { authApi } from "./Services/authApi";
import { branch } from "./Services/Branch";
import { customers, customersType } from "./Services/Customers";
import { userInfo, userType } from "./Services/User";
import { vendors } from "./Services/Vendors";
import { state } from "./Services/state";
import { county } from "./Services/county";
import { labels } from "./Services/Labels";
import statelist from "./reducers/statelist";
import countyList from "./reducers/countyList";
import vendorsList from "./reducers/vendorsList";
import customerList from "./reducers/customerList";
import branchList from "./reducers/branchList";
import userTypeList from "./reducers/userTypeList";
import customerTypeList from "./reducers/customerTypeList";
import vendorCustomerList from "./reducers/vendorCustomerList";
import productTypeList from "./reducers/productTypeList";
import labelList from "./reducers/labelList";
import { closer } from "./Services/closer";
import { status } from "./Services/status";
import { notification } from "./Services/notification";
import { producttype } from "./Services/productType";
import closerList from "./reducers/closerList";
import flagRoles from "./reducers/flagRoles";
import { stateCounty } from "./Services/stateCounty";
import stateCountyReducer from "./reducers/stateCountyReducer";
import { supportVendor } from "./Services/supportVendor";
import { supportCustomer } from "./Services/supportCustomer";
import { taxCollectorType } from "./Services/TaxCollectorType";
import { cycles } from "./Services/Cycles";
import { supportbranch } from "./Services/supportBranch";
import { taxcollector } from "./Services/TaxCollector";
import { jurisdiction } from "./Services/Jurisdiction";
import { annotationtype } from "./Services/annotationtype";
import { contacttype } from "./Services/contacttype";
import taxcollectorList from "./reducers/taxcollectorList";
import taxcollectorTypeList from "./reducers/taxcollectorTypeList";
import { customerStateCounty } from "./Services/customerStateCounty";
import annotationtypeList from "./reducers/annotationtypeList";
import { annotation } from "./Services/Annotation";
import { countyJurisdiction } from "./Services/countyJurisdiction";
import cycleList from "./reducers/cycleList";
import { hoamgmt } from "./Services/HaoMgmt";
import { hoa } from "./Services/Hoa";
import hoaCollectorList from "./reducers/hoaCollectorList";
import juridictionsList from "./reducers/jurisdictionList";
import { notice } from "./Services/Notice";
import { integrationBridge } from "./Services/IntegrationBridge";
import IntegrationBridgeList from "./reducers/IntegrationBridgeList";
import { branchProductList } from "./Services/branchProductTypelist";
import { email } from "./Services/email";
import { thirdParty } from "./Services/ThirdParty";
import hoaTypeList from "./reducers/hoaTypeList";
import { hoatypes } from "./Services/hoaType";
import thirdPartyDocList from "./reducers/thirdPartyDocumentList";
import { thirdPartyDocuments } from "./Services/thirdPartyDocument";
import cadList from "./reducers/cadList";
import { cad } from "./Services/Cad";
import { Taxcad } from "./Services/Taxcad";
import taxcadlist from "./reducers/taxcadlist";
import { batch } from "react-redux";
import { orders } from "./Services/Orders";
import { noticeFlags } from "./Services/NoticeFlags";
import { noticeFlagAction } from "./reducers/noticeFlags";
import { propertyBrwoser } from "./Services/propertyBrowser";
import { manualEntry } from "./Services/manualEntry";
import { parcels } from "./Services/Parcels";
import { hoahoamgtinfo } from "./Services/hoamgtinfo";
import { taxcollectorinfo } from "./Services/taxcollectorInfo";
import { dashboard } from "./Services/dashBoardApiList";
import dashBoardApi from "./reducers/dashBoardApi";
import ReportsList from "./reducers/ReportsList";
import { ReportsListApi } from "./Services/ReportsListApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeChanger,
    inputValue: inputHandler,
    stateList: statelist,
    labelList: labelList,
    vendorsList: vendorsList,
    countyList: countyList,
    customerList: customerList,
    branchList: branchList,
    productTypeList: productTypeList,
    userTypeList: userTypeList,
    vendorCustomerList: vendorCustomerList,
    customerTypeList: customerTypeList,
    closerList: closerList,
    flagRoles: flagRoles,
    stateCountyList: stateCountyReducer,
    taxcollectorList: taxcollectorList,
    taxcollectorTypeList: taxcollectorTypeList,
    annotationtypeList: annotationtypeList,
    cycleList: cycleList,
    hoaCollectorList: hoaCollectorList,
    juridictionsList: juridictionsList,
    integrationBridgeList: IntegrationBridgeList,
    thirdPartyDocList: thirdPartyDocList,
    hoaTypeList: hoaTypeList,
    cadList: cadList,
    taxcadlist: taxcadlist,
    dashBoardApi:dashBoardApi,
    ReportsList:ReportsList,
    [authApi.reducerPath]: authApi.reducer,
    [customers.reducerPath]: customers.reducer,
    [customersType.reducerPath]: customersType.reducer,
    [vendors.reducerPath]: vendors.reducer,
    [branch.reducerPath]: branch.reducer,
    [userInfo.reducerPath]: userInfo.reducer,
    [userType.reducerPath]: userType.reducer,
    [state.reducerPath]: state.reducer,
    [county.reducerPath]: county.reducer,
    [labels.reducerPath]: labels.reducer,
    [closer.reducerPath]: closer.reducer,
    [status.reducerPath]: status.reducer,
    [customerStateCounty.reducerPath]: customerStateCounty.reducer,
    [notification.reducerPath]: notification.reducer,
    [producttype.reducerPath]: producttype.reducer,
    [stateCounty.reducerPath]: stateCounty.reducer,
    [supportVendor.reducerPath]: supportVendor.reducer,
    [supportCustomer.reducerPath]: supportCustomer.reducer,
    [taxCollectorType.reducerPath]: taxCollectorType.reducer,
    [cycles.reducerPath]: cycles.reducer,
    [supportbranch.reducerPath]: supportbranch.reducer,
    [taxcollector.reducerPath]: taxcollector.reducer,
    [jurisdiction.reducerPath]: jurisdiction.reducer,
    [annotationtype.reducerPath]: annotationtype.reducer,
    [contacttype.reducerPath]: contacttype.reducer,
    [annotation.reducerPath]: annotation.reducer,
    [countyJurisdiction.reducerPath]: countyJurisdiction.reducer,
    [hoamgmt.reducerPath]: hoamgmt.reducer,
    [notice.reducerPath]: notice.reducer,
    [hoa.reducerPath]: hoa.reducer,
    [integrationBridge.reducerPath]: integrationBridge.reducer,
    [branchProductList.reducerPath]: branchProductList.reducer,
    [email.reducerPath]: email.reducer,
    [thirdParty.reducerPath]: thirdParty.reducer,
    [thirdPartyDocuments.reducerPath]: thirdPartyDocuments.reducer,
    [hoatypes.reducerPath]: hoatypes.reducer,
    [cad.reducerPath]: cad.reducer,
    [Taxcad.reducerPath]: Taxcad.reducer,
    [orders.reducerPath]: orders.reducer,
    [noticeFlags.reducerPath]: noticeFlags.reducer,
    [propertyBrwoser.reducerPath]: propertyBrwoser.reducer,
    [manualEntry.reducerPath]: manualEntry.reducer,
    [parcels.reducerPath]: parcels.reducer,
    [hoahoamgtinfo.reducerPath]: hoahoamgtinfo.reducer,
    [taxcollectorinfo.reducerPath]: taxcollectorinfo.reducer,
    [dashboard.reducerPath]:dashboard.reducer,
    [ReportsListApi.reducerPath]:ReportsListApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      customers.middleware,
      vendors.middleware,
      userInfo.middleware,
      branch.middleware,
      state.middleware,
      county.middleware,
      customersType.middleware,
      userType.middleware,
      closer.middleware,
      labels.middleware,
      status.middleware,
      notification.middleware,
      stateCounty.middleware,
      supportVendor.middleware,
      supportCustomer.middleware,
      taxCollectorType.middleware,
      cycles.middleware,
      supportbranch.middleware,
      taxcollector.middleware,
      jurisdiction.middleware,
      annotationtype.middleware,
      customerStateCounty.middleware,
      contacttype.middleware,
      annotation.middleware,
      countyJurisdiction.middleware,
      hoamgmt.middleware,
      hoa.middleware,
      notice.middleware,
      integrationBridge.middleware,
      email.middleware,
      branchProductList.middleware,
      thirdParty.middleware,
      hoatypes.middleware,
      thirdPartyDocuments.middleware,
      cad.middleware,
      Taxcad.middleware,
      orders.middleware,
      propertyBrwoser.middleware,
      parcels.middleware,
      manualEntry.middleware,
      hoahoamgtinfo.middleware,
      taxcollectorinfo.middleware,
      dashboard.middleware
    ),
});

