import React from "react";
import "./viewcomments.css";
const parse = require("html-react-parser");

export default function ViewComments({ title, html }) {
  return (
    <div className="comments-box my-2">
      <div className="position-relative">
        <div disabled className="border rounded p-2 w-100">
          <p className="fs-13">{parse(html || "NA")}</p>
        </div>
        {/* <div className="position-absolute top-0 end-0 ">
          <span className="btn-outline-primary bg-white btn btn-sm hover-text-comp-comments position-relative ">
            {title === "Read Only" ? (
              <AiOutlineRead color="black" />
            ) : title === "Display on Cert" ? (
              <MdOutlineSpeakerNotes color="black"/>
            ) : (
              <BiEdit color="black" />
            )}
            <div className="hover-text-comments-label border p-1 rounded-3 shadow-sm">
              {title || "test"}
            </div>
          </span>
        </div> */}
      </div>
    </div>
  );
}
