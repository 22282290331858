import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const hoahoamgtinfo = createApi({
  reducerPath: "hoahoamgtinfo",
  tagTypes: ["hoahoamgtinfo"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    FilterHoaBasedStateCounty: builder.mutation({
      query: (data) => ({    
        url: `/hoa-state-county-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoahoamgtinfo"],
    }),
    AddHoaToParcel: builder.mutation({
        query: (data) => ({
          url: `/add-parcel-hoa`,
          mode: "cors",
          method: "POST",
          body: data.data,
          headers: { Authorization: `Bearer ${data.token}` },
        }),
        invalidatesTags: ["hoahoamgtinfo"],
      }),
  
      HoaInformationList: builder.mutation({
        query: (data) => ({
          url: `/hoa-information/${data.id}`,
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Bearer ${data.token}` },
        }),
        //invalidatesTags: ["hoahoamgtinfo"],
      }),

      HoaCollectorInformationList: builder.mutation({
        query: (data) => ({
          url: `/hoa-collector-information/${data.id}`,
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Bearer ${data.token}` },
        }),
        //invalidatesTags: ["hoahoamgtinfo"],
      }),

    UpdateParcelHoa: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-hoa/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoahoamgtinfo"],
    }),
    EditParcelHoa: builder.query({
      query: (data) => ({
        url: `/edit-parcel-hoa/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

   
  }),
});

export const {
useAddHoaToParcelMutation,
useEditParcelHoaQuery,
useFilterHoaBasedStateCountyMutation,
useHoaCollectorInformationListMutation,
useHoaInformationListMutation,
useUpdateParcelHoaMutation
}=hoahoamgtinfo;
