import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const annotation = createApi({
  reducerPath: "annotation",
  tagTypes: ["annotation"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url}),
  endpoints: (builder) => ({
    annotationList: builder.query({
      query: (token) => ({
        url: `/annotation-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}`  },
      }),
      providesTags: ["annotation"],
    }),
    editAnnotation: builder.query({
      query: (data) => ({
        url: `/edit-annotation/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    editAnnotationPost: builder.mutation({
      query: (data) => ({
        url: `/edit-annotation/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateAnnotation: builder.mutation({
      query: (data) => ({
        url: `/update-annotation/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["annotation"],
    }),
    addAnnotation: builder.mutation({
      query: (data) => ({
        url: `/add-annotation`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["annotation"],
    }),
  }),
});

export const {
    useAnnotationListQuery,
    useEditAnnotationQuery,
    useUpdateAnnotationMutation,
    useAddAnnotationMutation,
    useEditAnnotationPostMutation
} = annotation;
