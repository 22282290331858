import { createSlice } from "@reduxjs/toolkit";

const annotationtypeList = createSlice({
  name: "annotationtypeList",
  initialState: {
    annotationtypeList: [],
  },
  reducers: {
    annotationtypeListAction: (state, action) => {
      state.annotationtypeList = action.payload;
    },
  },
});

export const {annotationtypeListAction} = annotationtypeList.actions;

export default annotationtypeList.reducer;