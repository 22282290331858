import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useSendEmailCertificateMutation } from "../../../../../Redux/Services/Orders";

import { MdDelete } from "react-icons/md";
import AppInput from "../../../../../Components/AppInput/AppInput";
import AppSpinner from "../../../../../Components/AppSpinner/AppSpinner";
import Multiselect from "multiselect-react-dropdown";
import { AntdMultiSelectDropdown } from "../../../../../Components/antdMultiSelectDropdown/AntdMultiSelectDropdown";
export default function EmailModal({
  email,
  random,
  editId,
  afterSubmit,
  options = [],
}) {
  const [values, setValues] = useState({ email: [], message: "" });
  // const [emailData, setEmailData] = useState("");
  // const [extraEmails, setExtraEmails] = useState([]);
  // const [emailOptions, setEmailOptions] = useState([]);

  // const [addEmail, setAddEmail] = useState(false);
  const { token } = useSelector((state) => state.auth);
  //send email certificate
  const [sendEmailCertificate, sendEmailCertificateResult] =
    useSendEmailCertificateMutation();

  //certifcate send on emal
  useEffect(() => {
    if (sendEmailCertificateResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: sendEmailCertificateResult.data?.Message,
      });
      //   close modal
      afterSubmit(false);
    }
    if (sendEmailCertificateResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          sendEmailCertificateResult.error.data?.Message ||
          "Something went wrong!",
      });
    }
  }, [sendEmailCertificateResult.isLoading]);

  useEffect(() => {
    setValues({ ...values, message: "" });
  }, [random]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.email.length !== 0) {
      sendEmailCertificate({
        order_id: editId,
        token,
        data: {
          to_email_list: values.email,
          message: values.message,
        },
      });
      // console.log(values);
    }
  };

  // useEffect(() => {
  //   if (values.email.length === 0) {
  //     setAddEmail(true);
  //     setEmailData("");
  //   } else {
  //     setAddEmail(false);
  //     setEmailData("");
  //   }
  // }, [values.email, random]);

  return (
    <div className="position-relative">
      {sendEmailCertificateResult.isLoading && <AppSpinner size="small" />}
      {/* <Multiselect
        isObject={false}
        onKeyPressFn={(e) => {
          e.stopPropagation();
        }}
        onRemove={function noRefCheck(value) {
          setExtraEmails(value);
        }}
        onSelect={function noRefCheck(value) {
          setExtraEmails(value);
        }}
        selectedValues={extraEmails}
        options={emailOptions}
      /> */}
      <label htmlFor="">
        Email <span className="text-danger">*</span>
      </label>
      <br />
      <AntdMultiSelectDropdown
        random={random}
        selected={[email]}
        options={options}
        onChangeVal={(value) => setValues({ ...values, email: value })}
      />
      <form onSubmit={handleSubmit} action="">
        {/* {values.email.length > 0 &&
          values?.email?.map((value, i) => {
            return (
              <div key={i} className="d-inline me-1 ">
                <span className="mt-1 d-inline-block">{value}</span>
                <span
                  onClick={() => {
                    setValues({
                      ...values,
                      email: values.email.filter((ele, index) => {
                        return i !== index;
                      }),
                    });
                  }}
                  className="rounded text-danger border px-2 cursor-pointer "
                >
                  <MdDelete />
                </span>
              </div>
            );
          })} */}
        {/* <br /> */}
        {/* {addEmail && (
          <div className="d-flex align-items-center gap-1">
            <AppInput
              defaultSet={random}
              column={8}
              errorMessage={"Please Enter Valid Email Id"}
              value={emailData || ""}
              onChange={(e) => {
                setEmailData(e.target.value);
              }}
              type="email"
              placeholder="Enter Your Email"
              className="form-control "
            />
            <span
              onClick={() => {
                if (regex.test(emailData)) {
                  setValues({
                    ...values,
                    email: [...values.email, emailData],
                  });
                  setEmailData("");
                  setAddEmail(false);
                }
              }}
              className={`bg-primary d-inline-block mt-2 p-2  rounded text-white cursor-pointer ${
                regex.test(emailData) ? null : "cursor-disabled"
              }`}
            >
              +
            </span>
            <span
              onClick={() => {
                if (values.email.length > 0) {
                  setEmailData("");
                  setAddEmail(false);
                }
              }}
              className={`bg-danger d-inline-block mt-2 p-2  rounded text-white cursor-pointer ${
                values.email.length > 0 ? null : "cursor-disabled"
              }`}
            >
              -
            </span>
          </div>
        )} */}
        <label htmlFor="" className="mt-2">
          Message
        </label>
        <textarea
          value={values.message || ""}
          onChange={(e) => {
            setValues({ ...values, message: e.target.value });
          }}
          name="message"
          className="w-100 border rounded"
          cols="10"
          rows="4"
        />

        {/* {!addEmail && (
          <button
            onClick={() => {
              setAddEmail(true);
            }}
            type="button"
            className="btn btn-secondary btn-sm mt-2 me-1"
          >
            Add Email
          </button>
        )} */}
        <button
          type="submit"
          className={`btn btn-primary btn-sm mt-2 ${
            values.email.length === 0 ? "cursor-disabled" : null
          }`}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
