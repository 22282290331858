import {
  CloseOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import "./AppModal.css";
const { confirm } = Modal;
export default function AppModal({
  showModal,
  onClose,
  afterClose,
  element,
  confirmProp = false,
  width = 700,
}) {
  const [confirm, setConfirm] = useState(false);
  const [visible, setVisible] = useState(false);

  // const handleCancel = () => {
  // setVisible(false);
  // onClose(visible);
  // };

  const showDeleteConfirm = () => {
    if (confirmProp) {
      setConfirm(true);
    } else {
      setVisible(false);
      onClose(visible);
    }
  };

  return (
    <Modal
      style={{ border: "8px" }}
      width={width}
      centered={true}
      visible={showModal}
      afterClose={afterClose}
      maskClosable={false}
      transitionName=""
      footer={false}
      closeIcon={true}
    >
      {element}
      <div className="position-absolute  modal-close">
        <CloseOutlined
          className="cursor-pointer"
          onClick={showDeleteConfirm}
          style={{ fontWeight: "bold", fontSize: 20 }}
          color="#0f0f0f"
        />
        {confirm && (
          <div className="confirm-box text-center position-absolute border shadow-sm rounded mt-1 p-2">
            <div className="d-flex align-items-center gap-2">
              <QuestionCircleOutlined
                style={{ color: "#faad14", fontSize: 18 }}
              />{" "}
              <h6 className="mt-1">
                {" "}
                Are you sure you want to close this form?
              </h6>
            </div>
            <p style={{ fontSize: 12 }}>
              Changes won't be saved until you click on the submit button{" "}
            </p>
            <div className="buttons ">
              <Button
                onClick={() => {
                  setConfirm(false);
                }}
                size="small"
                type="primary"
                danger
                ghost
              >
                Stay
              </Button>
              <Button
                onClick={() => {
                  setConfirm(false);
                  setVisible(false);
                  onClose(visible);
                }}
                className="ms-2"
                size="small"
                type="primary"
                ghost
              >
                Ok
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
