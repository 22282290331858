import React from "react";
import { useState } from "react";
import { MdClear } from "react-icons/md";
import "./AppFilterSelect.css";
export default function AppFilterSelect({
  onChange,
  options = [],
  name = "",
  onClear = false,
  value = "",
  disabled = false,
}) {
  return (
    <>
      <div className="d-flex gap-1 align-items-center">
        <select
          disabled={disabled}
          value={value}
          name={name}
          className={` w-100 border p-1 ${disabled ? "disabled bg-light" : ""}`}
          onChange={(e) => {
            onChange(e);
          }}
        >
          <option value="">
            Select {name.charAt(0).toUpperCase() + name.slice(1)}
          </option>
          {options?.map(({ name, id }) => {
            return (
              <option data_name={name} value={id} key={id}>
                {name}
              </option>
            );
          })}
        </select>
        <div className="p-1 rounded-pill border bg-white ">
          <div
            onClick={onClear}
            className="d-flex gap-2 align-items-center text-center justify-content-center"
            style={{ width: 18, height: 18, cursor: "pointer" }}
          >
            <MdClear />
          </div>
        </div>
      </div>
    </>
  );
}
