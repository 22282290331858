import React, { useEffect, useState } from "react";
import { FaSortAmountDown } from "react-icons/fa";
import { MdFilterListAlt } from "react-icons/md";
import { Row } from "react-bootstrap";
import { Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import AppTable from "../../../Components/AppTable/AppTable";

export default function Vendor({ refresh }) {
	// const [data, setData] = useState();
	const [search, setSearch] = useState("");

	useEffect(() => {
		setSearch("");
	}, [refresh]);

	// useEffect(() => {
	//   if (branchData.isSuccess) {
	//     setData(branchData.data?.data);
	//   }
	// }, [branchData]);

	const columns = [
		{
			dataIndex: "id",
			sorter: true,
			render: (value) => <span data-idfe={value}>{""}</span>,
			width: 1,
		},
		{
			title: (
				<span>
					Year <FaSortAmountDown />
				</span>
			),
			dataIndex: "year",
			sorter: (a, b) => a.year.localeCompare(b.year),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Tax ID
					<FaSortAmountDown />
				</span>
			),
			dataIndex: "tax_id",
			sorter: (a, b) => a.tax_id.localeCompare(b.tax_id),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Geo ID <FaSortAmountDown />
				</span>
			),
			dataIndex: "geo_id",
			sorter: (a, b) => a.geo_id.localeCompare(b.geo_id),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Address
					<FaSortAmountDown />
				</span>
			),
			dataIndex: "address",
			sorter: (a, b) => a.address.localeCompare(b.address),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Subdivision
					<FaSortAmountDown />
				</span>
			),
			dataIndex: "subdivison",
			sorter: (a, b) => a.subdivison.localeCompare(b.subdivison),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Owner
					<FaSortAmountDown />
				</span>
			),
			dataIndex: "owner",
			sorter: (a, b) => a.owner.localeCompare(b.owner),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
		{
			title: (
				<span>
					Legal
					<FaSortAmountDown />
				</span>
			),
			dataIndex: "legal",
			sorter: (a, b) => a.owner.legal(b.owner),
			filterIcon: () => (
				<MdFilterListAlt size={15} className="filter" color="#7CFFA7" />
			),
			filters: [
				{
					text: "",
					value: "",
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			filterMode: "tree",
		},
	];

	const data = [
		{
			key: "1",
			year: "2020",
			tax_id: "4375589",
			geo_id: "10012005200",
			address: "726 Dana Dr,Spring Branch..",
			subdivison: "100120-N..",
			owner: "Melsha James",
			legal: "Lot 12,NCB 649 Longview..",
		},
		{
			key: "2",
			year: "2020",
			tax_id: "4375589",
			geo_id: "10012005200",
			address: "726 Dana Dr,Spring Branch..",
			subdivison: "100120-N..",
			owner: "Melsha James",
			legal: "Lot 12,NCB 649 Longview..",
		},
		{
			key: "3",
			year: "2020",
			tax_id: "4375589",
			geo_id: "10012005200",
			address: "726 Dana Dr,Spring Branch..",
			subdivison: "100120-N..",
			owner: "Melsha James",
			legal: "Lot 12,NCB 649 Longview..",
		},
		{
			key: "4",
			year: "2020",
			tax_id: "4375589",
			geo_id: "10012005200",
			address: "726 Dana Dr,Spring Branch..",
			subdivison: "100120-N..",
			owner: "Melsha James",
			legal: "Lot 12,NCB 649 Longview..",
		},
		{
			key: "5",
			year: "2020",
			tax_id: "4375589",
			geo_id: "10012005200",
			address: "726 Dana Dr,Spring Branch..",
			subdivison: "100120-N..",
			owner: "Melsha James",
			legal: "Lot 12,NCB 649 Longview..",
		},
	];

	return (
		<>
			<div className="d-flex align-items-center float-end w-25 mb-4 ">
				{/* <AlertBox message={"checking afjshfidshgjdygudsjdsgusgjdsviuds hiufiudsgv jgusfjdsu"}  type="success"/> */}
				{/* <AppModal
              showModal={showHide}
              onClose={closeModal}
              afterClose={afterClose}
              width={890}
              height={500}
              element={
                <BranchModal
                  //passing tab of customers
                  updateTab={updateTab}
                  //passing row customer editId
                  editId={editId}
                  initialValues={preDefualtStateValues}
                  setShowHide={setShowHide}
                  getStatus={afterClose}
                  //passing deafult dependency to initial state
                  afterClose={initialStateVal}
                />
              }
            /> */}

				{/* changing input values globaly  */}
				<div className=" me-1 w-100 ">
					<Input
						value={search}
						className="global-search "
						onChange={(e) => setSearch(e.target.value)}
						prefix={<AiOutlineSearch />}
					/>
				</div>
			</div>
			<div style={{ clear: "both" }}></div>
			<Row>
				<AppTable
					// loading={updateBranchStatusApi[1].isLoading}
					// search={search}
					columns={columns}
					dataSource={data}
					rowSelection={true}
				/>
			</Row>
		</>
	);
}
