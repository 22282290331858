import { createSlice } from "@reduxjs/toolkit";

const integrationBridgeList = createSlice({
  name: "integrationBridgeList",
  initialState: {
    integrationBridgeList: [],
  },
  reducers: {
    integrationBridgeListAction: (state, action) => {
      state.integrationBridgeList = action.payload;
    },
  },
});

export const { integrationBridgeListAction } = integrationBridgeList.actions;

export default integrationBridgeList.reducer;
