import React, { useContext, useEffect, useRef, useState } from "react";
// import "antd/dist/antd.css";
import { Row } from "react-bootstrap";
import { Form, Popconfirm, Typography, Collapse } from "antd";
import { FaPlus } from "react-icons/fa";
import {
	EditOutlined,
	QuestionCircleOutlined,
	CaretRightOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import AppSelect from "../AppSelect/AppSelect";
import AppTable from "../AppTable/AppTable";
import { MdOutlineDelete } from "react-icons/md";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export const IntegrationBridge = ({
	getIntegration,
	afterClose,
	initailValArr,
	addFormVal,
	updateContact,
	deleteContact,
	whileEdit,
}) => {
	const initialVal = {
		integration_bridge_id: "",
		integration_name: "",
		partner_id: "",
		username: "",
		password: "",
	};
	const [active, setActive] = useState("2");
	const [form] = Form.useForm();
	const [values, setValues] = useState(initialVal);
	const [data, setData] = useState([]);

	const {
		integration_bridge_id,
		integration_name,
		partner_id,
		username,
		password,
	} = values;

	const [addRowToggler, setAddRowToggler] = useState(false);
	const [editId, setEditId] = useState(null);

	//edit row
	const editHandler = (record) => {
		setEditId(record);
		setAddRowToggler(true);
		setValues(record);
	};

	const newIntegrationData = data?.map(({ integration_name, ...rest }) => {
		return rest;
	});

	const defaultColumns = [
		{
			dataIndex: "integration_bridge_id",
			key: "integration_bridge_id",
			width: 1,
			render: () => <> </>,
		},

		{
			title: "Integration Bridge",
			dataIndex: "integration_name",
			key: "integration_name",
			align: "start",
			width: 130,
		},
		{
			title: "Partner ID",
			inputType: "partner_id",
			dataIndex: "partner_id",
			key: "partner_id",
			editable: true,
			render: (value) => {
				return <>{value || "NA"}</>;
			},
			align: "center",
		},
		{
			title: "Username",
			dataIndex: "username",
			key: "username",
			editable: true,
			align: "center",
			render: (value) => {
				return <>{value || "NA"}</>;
			},
		},
		{
			title: "Password",
			dataIndex: "password",
			key: "password",
			render: (value) => {
				return <>{value ? <span>***********</span> : "NA"}</>;
			},
			editable: true,
			align: "center",
		},
		{
			title: "Action",
			dataIndex: "operation",
			key: "operation",
			align: "center",
			render: (_, record) => {
				return (
					<>
						<span>
							<EditOutlined
								style={{ cursor: "pointer" }}
								onClick={() => editHandler(record)}
								size={50}
							/>
						</span>

						<Popconfirm
							title="Are you sure you want to delete？"
							onConfirm={() => {
								deleteHandler(record);
							}}
							getPopupContainer={(trigger) => trigger.parentElement}
							placement="topLeft"
							icon={
								<QuestionCircleOutlined
									style={{
										color: "red",
									}}
								/>
							}
							okText="Yes"
							cancelText="No"
						>
							<span className={"ms-2 "}>
								<MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
							</span>
						</Popconfirm>
					</>
				);
			},
		},
	];

	const deleteHandler = (id) => {
		setData(
			data.filter((ele) => {
				return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
			})
		);
		// //sending delete id
		deleteContact(id);
	};

	const inputs = [
		{
			id: 1,
			name: "integration_bridge_id",
			value: integration_bridge_id || "",
			// required: true,
		},
		{
			id: 2,
			name: "integration_name",
			type: "select",
			value: integration_name || "",
			column: "3",
			options: getIntegration || [],
			errorMessage: "Please select integration name!",
			required: true,
		},

		{
			id: 3,
			name: "partner_id",
			type: "text",
			value: partner_id || "",
			column: "3",
			placeholder: "Partner ID",
			errorMessage: "Please enter partner id!",
			// required: true,
		},

		{
			id: 4,
			name: "username",
			type: "text",
			value: username || "",
			column: "2",
			placeholder: "Username",
			errorMessage: "Please enter username!",
			// required: true,
		},
		{
			id: 5,
			name: "password",
			type: "text",
			value: password || "",
			column: "2",
			placeholder: "Password",
			errorMessage: "Please enter password!",
			// required: true,
		},
	];

	const addNewRecord = () => {
		setAddRowToggler(!addRowToggler);
		setEditId(null);
	};

	const cancelHandler = () => {
		setValues(initialVal);
		setAddRowToggler(false);
		addFormVal(newIntegrationData);
		setEditId(null);
	};

	const handleSubmit = () => {
		if (editId) {
			const newData = data.filter((ele) => {
				return (ele?.temp_id || ele?.id) !== (editId?.temp_id || editId?.id);
			});
			setData([...newData, values]);
			setAddRowToggler(false);
			updateContact && updateContact(values);
			setEditId(null);
			setValues(initialVal);
		} else {
			const randomId = randomPass.randomPassword({
				length: 9,
				characters: randomPass.digits,
			});
			setData([
				...data,
				{
					...values,
					temp_id: randomId,
				},
			]);
			// while edit and ading new record directly
			setValues(initialVal);

			const { integration_name, ...othersData } = values;
			whileEdit({ ...othersData, temp_id: randomId });

			setAddRowToggler(false);
			setEditId(null);
		}
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	//select handler
	const selectHandler = (property, value, arrtibute) => {
		setValues({
			...values,
			integration_name: value,
			integration_bridge_id: arrtibute?.getAttribute("data_name"),
		});
	};

	useEffect(() => {
		// addFormVal(data);
		setAddRowToggler(false);
		if (initailValArr?.length > 0) {
			setData(initailValArr);
		} else {
			setData([]);
		}
		setEditId(null);
		setActive("2");
	}, [afterClose]);

	//
	useEffect(() => {
		addFormVal(newIntegrationData);
	}, [data?.length]);
	return (
		<Collapse
			className="m-0 py-0 gray-table site-collapse-custom-collapse"
			expandIcon={({ isActive }) => (
				<CaretRightOutlined
					style={{ fontSize: 14 }}
					className="mt-1"
					rotate={isActive ? 90 : 0}
				/>
			)}
			expandIconPosition={"end"}
			activeKey={active}
			accordion
			onChange={(value) => {
				setActive(value);
			}}
		>
			<Panel
				className="my-1 border-0 site-collapse-custom-panel "
				header={
					<h6 className=" fs-18 mb-0 fw-bold me-2 d-flex align-items-center gap-1">
						<span>Integration Bridge</span>
					</h6>
				}
				key="1"
			>
				{active === "1" && (
					<div className="gray-table-col border rounded-2 p-2 mb-3">
						<div
							className={`d-flex  justify-content-between align-items-center my-2 contact-forms`}
						>
							<h6 className="fs-18 fw-bold me-2"></h6>
							{!addRowToggler && (
								<div className={`add_icon`} onClick={addNewRecord}>
									<FaPlus />
								</div>
							)}
						</div>
						<Row>
							<AppTable
								// sort={sort}
								pagination={false}
								dataSource={data}
								columns={defaultColumns}
							/>
						</Row>
						{addRowToggler && (
							<div className="row bg-white py-2 shadow-sm">
								{inputs.map((input) => (
									<>
										{input.type === "select" &&
											input.name === "integration_name" &&
											!editId?.id && (
												<AppSelect
													key={input.id}
													{...input}
													onChange={selectHandler}
													options={
														<>
															<option value={""}>
																Select Integration Bridge
															</option>
															{input.options.map((ele) => {
																return (
																	<option
																		key={ele.id}
																		data_name={ele.id}
																		value={ele.name}
																	>
																		{ele.name}
																	</option>
																);
															})}
														</>
													}
												/>
											)}

										{input.type === "text" && (
											<AppInput
												defaultSet={afterClose}
												key={input.id}
												{...input}
												value={values[input.name]}
												onChange={onChange}
											/>
										)}
									</>
								))}
								<div className="col-12 col-lg-2  mt-2  align-self-center">
									<div className="d-flex ">
										<span
											onClick={handleSubmit}
											className={`btn btn-primary ${
												!integration_bridge_id ? "disabled" : "btn-success"
											} btn-sm edit-buttons`}
										>
											{editId?.id ? "Update" : "Save"}
										</span>
										<span
											onClick={cancelHandler}
											className="btn btn-primary ms-1 btn-sm edit-buttons"
										>
											Cancel
										</span>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</Panel>
		</Collapse>
	);
};
