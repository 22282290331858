import React from "react";
import AppDragTable from "../../Components/AppDragTable/AppDragTable";

import "./dashboard.css"

import DashboardNewDesign from "./dashCard";

export default function MyDashBoard() {
 
  return (
    <>

    <DashboardNewDesign/>
   
    </>
  );
}
