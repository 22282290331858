import { useState } from "react";
import "./AppInput.css";
import { Row } from "react-bootstrap";

import { BsExclamationCircle } from "react-icons/bs";
import { useEffect } from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
const AppNumeric = ({
  subhead,
  column,
  inputFormat = "numeric",
  format = "####",
  label,
  errorMessage,
  onChange,
  defaultSet,
  id,
  decimalScale = 2,
  preview,
  hide,
  color,
  margin,
  icon,
  isEdit,
  required,
  value,
  classlabelstyle,
  disabled,
  ref,
  thousandSeparator = true,
  getErrorFocus,
  ...inputs
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  useEffect(() => {
    setFocused(false);
  }, [defaultSet]);

  return (
    <>
      {subhead && <h6 className="mt-3">{subhead}</h6>}
      {!hide && (
        <div
          className={`col-12 ${margin} col-md-${column} ${
            disabled && "disable-input"
          }`}
        >
          <div className={`  position-relative `}>
            {label && (
              <Row className="mt-3">
                <label
                  className={`${
                    classlabelstyle || "ms-1 heading-5 text-dark"
                  } `}
                >
                  {label}
                  {required && label && !color && (
                    <span className="text-danger">
                      * <span className="text-dark fw-bold"></span>
                    </span>
                  )}
                </label>
                {isEdit && (
                  <div
                    className={`mx-1 heading-5 mt-0`}
                    style={{ marginTop: 16 }}
                  >
                    {value || "NA"}
                  </div>
                )}
              </Row>
            )}
            {!isEdit && (
              <>
                {inputFormat === "numeric" && (
                  <NumericFormat
                    ref={ref}
                    maxLength={50}
                    {...inputs}
                    required={required}
                    value={value}
                    thousandSeparator={true}
                    decimalScale={decimalScale}
                    className="input-field mt-0 pb-2 w-100  heading-5"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onChange(formattedValue, value);
                    }}
                  />
                )}
                {inputFormat === "pattern" && (
                  <PatternFormat
                    format={format}
                    maxLength={50}
                    {...inputs}
                    required={required}
                    value={value}
                    decimalScale={decimalScale}
                    className="input-field mt-0 pb-2 w-100  heading-5"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onChange(formattedValue, value);
                    }}
                  />
                )}
              </>
            )}

            <div className={`input-icon`}>
              {icon && (
                <>
                  <span>{icon}</span>
                </>
              )}
            </div>

            <span className="input-error">
              <BsExclamationCircle
                className="alertIcon"
                style={{ marginBottom: "3px" }}
              />
              {errorMessage}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AppNumeric;
