import React, { useState } from "react";
import { Alert, Form, Popconfirm, Typography } from "antd";
import AppTable from "../AppTable/AppTable";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { FaPlus, FaStarOfLife } from "react-icons/fa";
import "./multi-branches.css";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AppInput from "../AppInput/AppInput";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { removeBlank } from "../../Controllers/Functions";
const randomPass = require("secure-random-password");

export default function MultiBranches({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  roleList = [],
  whileEdit = false,
  _id,
}) {
  ///vendor contacts details
  const [editingKey, setEditingKey] = useState("");
  const { stateList } = useSelector((state) => state.stateList);
  const initailVal = {
    branch: "",
    address_one: "",
    address_two: "",
    city: "",
    state_id: "",
    state_name: "",
    zip: "",
  };
  const [form] = Form.useForm();
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const isEditing = (record) => record.id === editingKey;
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const edit = (record) => {
    form.setFieldsValue({
      ...initailVal,
      ...record,
    });
    setEditingKey(record?.id || record?.temp_id);
  };

  const { branch, address_one, address_two, city, state_name, zip } = values;

  const { authColumn } = useSelector((state) => state.flagRoles);
  //state List

  const { token } = useSelector((state) => state.auth);

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  //calling redux stor for vendors list
  const { userTypeList } = useSelector((state) => state.userTypeList);

  //create function for customer fields
  function matchColumn(value) {
    if (authColumn.indexOf(value) === -1) {
      return false;
    } else {
      return true;
    }
  }

  const columns = [
    {
      title: <span>Branches/City</span>,
      dataIndex: "branch",
      key: "branch",
      inputType: "text",
      required: true,
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },
    {
      title: <span>Address 1</span>,
      dataIndex: "address_one",
      key: "address_one",
      inputType: "text",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
    },

    {
      title: <span>Address 2</span>,
      dataIndex: "address_two",
      key: "address_two",
      inputType: "text",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },
    {
      title: <span>City</span>,
      dataIndex: "city",
      key: "city",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      inputType: "text",
      align: "center",
    },
    {
      title: <span>State</span>,
      dataIndex: "state_name",
      key: "state_name",
      inputType: "text",
      required: true,

      align: "center",
    },
    {
      title: <span>Zip-Code</span>,
      dataIndex: "zip",
      key: "zip",
      inputType: "text",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  //fileter user types
  const filetrUserTypeById = (arr = [], keys = []) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const { id } = arr[i];
      if (keys.includes(id)) {
        res.push(arr[i]);
      }
    }
    return res;
  };

  // let res = [];
  // res = filetrValues?.filter((el) => {
  //   return !data?.find((element) => {
  //     return element.user_type_name === el.name;
  //   });
  // });

  const inputs = [
    {
      id: 1,
      name: "branch",
      _id: "branch",
      type: "text",
      value: branch || "",
      column: "3",
      placeholder: "Enter Branch Name",
      errorMessage: "Please enter branch name!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 4545,
      name: "address_one",
      _id: "address_one",
      type: "text",
      value: address_one || "",
      column: "3",
      placeholder: "Enter Address 1",
      errorMessage: "Please enter address 1!",
    },
    {
      id: 454455,
      name: "address_two",
      _id: "address_two",
      type: "text",
      value: address_two || "",
      column: "3",
      placeholder: "Enter Address 2",
      errorMessage: "Please enter address 2!",
    },
    {
      id: 45489855,
      name: "city",
      _id: "city",
      type: "text",
      value: city || "",
      column: "3",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
    },
    {
      id: 455,
      name: "state_name",
      _id: "state_name",
      type: "select",
      value: state_name || "",
      column: "3",
      options: stateList || [],
      errorMessage: "Please select state name!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 49855,
      name: "zip",
      _id: "zip",
      type: "text",
      value: zip || "",
      column: "3",
      maxlength: "10",
      minlength: "5",
      placeholder: "Enter zip",
      errorMessage: "Please enter zip!",
    },
  ];
  const onChange = (e) => {
    setGetErr(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "zip") {
      const value1 = value.replace(/[^0-9]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, zip: foo });
    }
  };

  const handleChange = (property, value) => {
    setValues({ ...values, [property]: value });
    setGetErr(false);

    ///
  };
  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([...newData, values]);
      setAddRowToggler(false);
      updateContact && updateContact(values);
      setEditId(null);
      setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, temp_id: randomId });
      setValues(initailVal);
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  //select handler
  const selectHandler = (property, value, arrtibute) => {
    setValues({
      ...values,
      state_id: arrtibute?.getAttribute("data_name"),
      state_name: value,
    });
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <div className="gray-table-col border rounded-2 mb-2 p-2" id={_id}>
      <div
        className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
      >
        <h6 className="fs-18 fw-bold me-2">Branches/Cities</h6>
        {!addRowToggler && data?.length > 0 && (
          <div className={`add_icon`} onClick={addNewRecord}>
            <FaPlus />
          </div>
        )}
      </div>

      <AppTable
        // sort={sort}
        errors={{
          max: 10,
          min: 10,
          dataIndex: ["phone_number", "work_phone_number"],
        }}
        pagination={false}
        form={form}
        isEditing={isEditing}
        dataSource={data}
        columns={columns}
      />

      {getErr && (
        <div className="mt-3">
          <Alert message={getErr} className="py-1 mx-3" type="error" showIcon />{" "}
        </div>
      )}
      {/* //loading  */}
      {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

      {(addRowToggler || data?.length < 1 || !initailValArr) && (
        <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
          {inputs.map((input) => (
            <>
              {input.type == "checkbox" && (
                <AppCheckbox
                  key={input._id}
                  {...input}
                  handleChange={handleChange}
                />
              )}
              {input.type === "select" && input.name === "state_name" && (
                <AppSelect
                  key={input.id}
                  {...input}
                  defaultSet={afterClose}
                  onChange={selectHandler}
                  options={
                    <>
                      <option value="">Please select state name</option>
                      {input.options.map((ele) => {
                        return (
                          <option
                            key={ele.id}
                            data_name={ele.id}
                            value={ele.state_name}
                          >
                            {ele.state_name}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}
              {(input.type === "text" ||
                input.type === "email" ||
                input.type === "password") && (
                <AppInput
                  defaultSet={afterClose}
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
              )}
            </>
          ))}
          <div className="col-12 col-lg-1 mt-3 p-0">
            <div className="d-flex">
              <span
                onClick={handleSubmit}
                className={`btn btn-primary  btn-sm edit-buttons ${
                  !state_name || !branch ? "disabled" : "btn-success"
                } `}
              >
                Save
              </span>
              <span
                onClick={cancelHandler}
                className="btn btn-primary ms-1 btn-sm edit-buttons"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
