import { Checkbox, Collapse, Empty, notification } from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiFillCloseCircle, AiOutlineCheck } from "react-icons/ai";
import { CgCloseO } from "react-icons/cg";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
import NormalTable from "../../../../Components/AppTable/AppTable";
import { useReviewConflictsMutation } from "../../../../Redux/Services/Orders";
import "./conflicts.css";
const { Panel } = Collapse;

export default function Conflicts({
  orderContorller,
  conflicts = [],
  isconflicts = false,
  onclose = false,
  onchek = false,
}) {
  return (
    <Collapse
      className="rounded-3 border-0 text-white mt-2"
      expandIcon={() => <></>}
      accordion
      defaultActiveKey={"1"}
    >
      {isconflicts && (
        <Panel
          className="main-bg-color rounded-3 "
          header={
            <div
              className=" text-white d-flex justify-content-between "
              style={{ fontSize: 18, marginBottom: 0 }}
            >
              Conflicts
            </div>
          }
          style={{ fontSize: 18 }}
          key="1"
        >
          <div
            style={{ maxHeight: "130vh", overflowY: "auto" }}
            className="p-2 gray-table-col conflicts "
          >
            <NormalTable
              pagination={false}
              activeState={false}
              dataSource={conflicts}
              columns={[
                {
                  title: "Review",
                  dataIndex: "is_checkbox",
                  key: "is_checkbox",
                  align: "center",
                  width: 35,
                  render: (value, record) => {
                    return (
                      <div>
                        {Boolean(!record.is_fixed) && Boolean(value) && (
                          <Checkbox
                            onChange={() => {
                              onchek && onchek(record.id);
                            }}
                          ></Checkbox>
                        )}
                        {Boolean(record.is_fixed) && Boolean(value) && (
                          <Checkbox checked disabled></Checkbox>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Parcel No",
                  dataIndex: "cad_property_id",
                  key: "cad_property_id",
                  align: "center",
                  width: 200,
                  render: (value) => {
                    return <div className="text-dark">{value || "-"}</div>;
                  },
                },
                {
                  title: "Errors",
                  dataIndex: "message",
                  key: "message",
                  align: "center",
                  width: 200,
                  render: (value, record) => {
                    return (
                      <div className="text-danger hover-text-comp position-relative">
                        {value}
                        {record?.jurisdiction_code_message && (
                          <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                            <p className="d-flex justify-content-center align-items-center ">
                              {record.jurisdiction_code_message}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  dataIndex: "action_required",
                  key: "action_required",
                  align: "center",
                  width: 200,
                  render: (value) => {
                    return <div className="text-success">{value}</div>;
                  },
                },
                {
                  title: "Validated",
                  dataIndex: "is_fixed",
                  key: "is_fixed",
                  align: "center",
                  width: 35,
                  render: (value) => {
                    return value ? (
                      <AiOutlineCheck color="green" />
                    ) : (
                      <MdClose color="red" />
                    );
                  },
                  filters: [
                    {
                      text: "Yes",
                      value: true,
                    },
                    {
                      text: "No",
                      value: false,
                    },
                    {
                      text: "Review",
                      value: 1,
                    },
                  ],
                  defaultFilteredValue: [false, 1],
                  onFilter: (value, record) => {
                    if (value === 1) {
                      return record.is_checkbox === value;
                    } else {
                      return Boolean(record.is_fixed) === Boolean(value);
                    }
                  },
                  filterMode: "tree",
                },
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  align: "center",
                  width: 120,
                  render: (value) => {
                    return <div className="text-primary">{value || "-"}</div>;
                  },
                },
              ]}
            />
          </div>
        </Panel>
      )}
      {orderContorller.pdfDoc && (
        <div className="view-docs">
          <div className="close bg-white rounded-circle">
            <CgCloseO
              onClick={() => {
                onclose && onclose(false);
              }}
              size={25}
              color="red"
              cursor={"pointer"}
            />
          </div>
          {orderContorller.pdfDoc.type === "application/pdf" ? (
            <embed
              src={orderContorller.pdfDoc.base64}
              width="100%"
              style={{
                minHeight: "130vh",
                maxHeight: "130vh",
              }}
            />
          ) : (
            <div className="img-view w-100 my-2">
              <img width={"100%"} src={orderContorller.pdfDoc.base64} alt="" />
            </div>
          )}
        </div>
      )}
    </Collapse>
  );
}
