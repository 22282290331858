import AppTable from "../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNoticeListQuery } from "../../../Redux/Services/Notice";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Alert, Input} from "antd";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useEffect } from "react";
import AppModal from "../../../Components/AppModal/AppModal";
import SearchColumns from "../../../Components/SearchColumns";

export const Notice = ({ refresh }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [showHide, setShowHide] = useState(null);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //redux data store
  const { token } = useSelector((state) => state.auth);
  // const { authColumn } = useSelector((state) => state.flagRoles);

  //set pdfdata
  const [getpdfdata, setPdfData] = useState("");

  //calling rtk query hook for geting notice list
  const noticeList = useNoticeListQuery(token);

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const onPdfHandler = (pdfdata) => {
    setPdfData(pdfdata);
    setShowHide(true);
  };
  //create function for customer fields
  // function matchColumn(value) {
  //   if (authColumn.indexOf(value) === -1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  function closeModal(data) {
    setShowHide(data);
    setPdfData("");
  }

  function afterClose() {}

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (value) => <span data-idfe={value}>{""}</span>,
      width: 1,
    },
    {
      title: (
        <span>
          State{" "}
          {getsortfield === "state_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "state_name" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "state_name" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "state_name",
      key: "state_name",
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
      width: 200,
      ...SearchColumns("state_name", "State Name"),
    },
    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "county_name" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "county_name",
      key: "county_name",
      sorter: (a, b) => a.county_name.localeCompare(b.county_name),
      width: 200,
      ...SearchColumns("county_name", "County Name"),
    },
    {
      title: (
        <span>
          Jurisdiction{" "}
          {getsortfield === "jurisdiction_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
              getsortfield === "jurisdiction_name" ? (
                 <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
          ) : getsortorder === "descend" &&
                getsortfield === "jurisdiction_name" ? (
                   <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "jurisdiction_name",
      key: "jurisdiction_name",
      sorter: (a, b) => a.jurisdiction_name.localeCompare(b.jurisdiction_name),
      width: 200,
      ...SearchColumns("jurisdiction_name", "Jurisdiction Name"),
    },
    {
      title: (
        <span>
          GF Number{" "}
          {getsortfield === "gf_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "gf_number" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "gf_number" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "gf_number",
      key: "gf_number",
      sorter: (a, b) => a.gf_number.localeCompare(b.gf_number),
      width: 200,
      ...SearchColumns("gf_number", "GF Number"),
    },
    {
      title: "View",
      dataIndex: "pdf_document",
      align: "center",
      key: "view",
      width: 50,
      render: (value, row) => {
        return (
          <>
            {
              <>
                <AiOutlineFilePdf
                  size={20}
                  cursor="pointer"
                  onClick={(e) => onPdfHandler(row.pdf_document)}
                />
              </>
            }
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (noticeList.isSuccess) {
      setData(noticeList.data?.data);
      // setColumn(columns);
    }
  }, [noticeList]);

  useEffect(() => {
    setSearch("");
    //request
    if (refresh === "Notice") {
      noticeList.refetch();
    }
  }, [refresh]);

  return (
    <>
      {noticeList.isLoading ? (
        <AppSpinner />
      ) : noticeList.error ? (
        <Alert
          message={
            noticeList.data?.message ||
            noticeList.error.error ||
            noticeList.error.data?.msg.sqlMessage
          }
          type="error"
          showIcon
        />
      ) : (
        <>
          <div className="d-flex align-items-center float-end w-25 mb-1 ">
            <AppModal
              showModal={showHide}
              onClose={closeModal}
              afterClose={afterClose}
              width={1100}
              height={900}
              element={
                <embed
                  src={atob(getpdfdata)}
                  width="100%"
                  className="mt-4"
                  height="550"
                />
              }
            />
            {/* changing input values globaly  */}
            <div className=" me-1 w-100 ">
              <Input
                placeholder="Search here"
                className="global-search "
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                prefix={<FaSearch color="lightgray" />}
              />
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
          <AppTable
            search={search}
            dataSource={data}
            columns={columns}
            sort={sort}
          />
        </>
      )}
    </>
  );
};
