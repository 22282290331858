import { createSlice } from "@reduxjs/toolkit";

const reportsList = createSlice({
  name: "reportsList",
  initialState: {
    reportsList: [],
  },
  reducers: {
    reportsListAction: (state, action) => {
      state.reportsList = action.payload;
    },
  },
});

export const {reportsListAction} = reportsList.actions;

export default reportsList.reducer;