import React from "react";
import "./PagenotFound.css";
import {  Col, Container, Row } from "react-bootstrap";
import AppButton from "../../Components/AppButton/AppButton";
import { useNavigate } from "react-router-dom";

export default function PagenotFound() {
  //clear token from store
  let navigate = useNavigate();

  const gohome = () => {
    navigate("/");
  };

  return (
    <div
      className="pagenotfound"
      style={{ background: "url(../Assets/login/back.png)" }}
    >
      <Container>
        <Row className="align-items-center text-start">
          <Col
            sm="12"
            lg="6"
            md="6"
            className="flex-column align-items-center text-center  d-flex justify-content-center"
          >
            <div className="pagenotfound-form">
              <h1 className="pagenotfound-heading m-0">404</h1>
              <h2 className="my-2">Page Not Found!</h2>

              <h3 className="pagenotfound-subtext description my-4 ">
                We're sorry the page you requested for could not be found,
                Please go back to Homepage!
              </h3>
            </div>
            <div className="w-75">
              <AppButton
                variant={"primary"}
                title={"GO HOME"}
                onClick={gohome}
              />
            </div>
          </Col>

          <Col
            sm="12"
            lg="6"
            md="6"
            className="mx-auto d-flex justify-content-center"
          >
            <img
              className="pagenotfoundImage img-fluid"
              src="../Assets/login/loginImage.png"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
