import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const ColumnBarChart = (props) => {
    const{
        data=[],
      xField,
      yField,
      isGroup=true,
      seriesField
    }=props;
  const config = {
    data:data,
    isGroup:isGroup,
    xField: xField,
    yField: yField,
    seriesField: seriesField,
    autoFit: true,
    color:['#1C39BB','#FF9F00'],
    width:360,
    height:360,
  
    legend: {
      layout: "horizontal",
      position: "top-left",
    },
    // columnStyle: {
    //   radius: [20, 20, 0, 0],
    // },
    minColumnWidth: 20,
    maxColumnWidth: 20,
  };
  return <Column {...config} />;
};

export default ColumnBarChart;
