import { createSlice } from "@reduxjs/toolkit";

const juridictionsList = createSlice({
  name: "juridictionsList",
  initialState: {
    juridictionsList: [],
  },
  reducers: {
    juridictionsListAction: (state, action) => {
      state.juridictionsList = action.payload;
    },
  },
});

export const { juridictionsListAction } = juridictionsList.actions;

export default juridictionsList.reducer;
