import AppTable from "../../../Components/AppTable/AppTable";
import SearchColumns from "../../../Components/SearchColumns";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { useEffect, useState, useMemo } from "react";
import { Input, Button, Alert, Row, Card, Empty, Space } from "antd";
import { useSelector } from "react-redux";
import { useStatusListQuery } from "../../../Redux/Services/status";
import AppInput from "../../../Components/AppInput/AppInput";
import AppButton from "../../../Components/AppButton/AppButton";
import { Form } from "react-bootstrap";
import { Drawer } from "antd";
import { FcComboChart, FcGrid } from "react-icons/fc";
import { FiRefreshCcw } from "react-icons/fi";
import { BiX } from "react-icons/bi";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import {
  useCustomerListQuery,
  useCustomerOptionsMutation,
} from "../../../Redux/Services/Customers";
import {
  priceDecimalCoverter,
  priceFormater,
  scrollComp,
} from "../../../Controllers/Functions";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import { Excel } from "antd-table-saveas-excel";
import {
  useInvoiceReportListQuery,
  useInvoiceReportFilterListMutation,
} from "../../../Redux/Services/ReportsListApi";
import { Line } from "@ant-design/plots";
import ColumnBarChart from "../../../Components/ColumnBarChart/ColumnBarchart";
import AppDateRangePicker from "../../../Components/AppDateRangePicker/AppDateRangePicker";
import "../Reports.css";
import { addDays } from "date-fns";
export const InvoiceReports = ({ refresh }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(null);
  const [getError, setGetError] = useState(false);
  const [, setStatusColor] = useState([]);
  const [, setColumns] = useState([]);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  const [invoiiceMapTab, setMapInvoiceTab] = useState(false);
  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);
  const [customertypeList, setCustomerTypeList] = useState([]);
  const [demoData1] = useState([
    {
      Date: "2010-01",
      scales: 1998,
      type: "Payment Received",
    },
    {
      Date: "2010-02",
      scales: 1850,
      type: "Payment Spent",
    },
    {
      Date: "2010-03",
      scales: 1720,
      type: "Payment Received",
    },
    {
      Date: "2010-04",
      scales: 1818,
      type: "Payment Spent",
    },
    {
      Date: "2010-05",
      scales: 1920,
      type: "Payment Spent",
    },
    {
      Date: "2010-06",
      scales: 1802,
      type: "Payment Received",
    },
    {
      Date: "2010-07",
      scales: 1945,
      type: "Payment Spent",
    },
    {
      Date: "2010-08",
      scales: 1856,
      type: "Payment Received",
    },
  ]);
  const [demoData] = useState([
    {
      Date: "2010-01",
      scales: 1998,
      type: "uv",
    },
    {
      Date: "2010-02",
      scales: 1850,
      type: "uv",
    },
    {
      Date: "2010-03",
      scales: 1720,
      type: "uv",
    },
    {
      Date: "2010-04",
      scales: 1818,
      type: "uv",
    },
    {
      Date: "2010-05",
      scales: 1920,
      type: "uv",
    },
    {
      Date: "2010-06",
      scales: 1802,
      type: "uv",
    },
    {
      Date: "2010-07",
      scales: 1945,
      type: "uv",
    },
    {
      Date: "2010-08",
      scales: 1856,
      type: "uv",
    },
  ]);

  const [yearOptions] = useState([
    { id: 1, year: 2020 },
    { id: 2, year: 2010 },
    { id: 3, year: 2020 },
    { id: 4, year: 2023 },
  ]);
  const [countyOptions, setCountyOptions] = useState([
    { id: "", county_name: "" },
  ]);
  const [initialValues] = useState({
    start_date: "",
    end_date: "",
    vender_id: "",
    customer_id: "",
    branch_id: "",
    user_id: "",
    state_id: "",
    county_id: "",
  });
  const [values, setValues] = useState(initialValues);
  //calling rtk query hook for customer-list options
  const customerListOptions = useCustomerOptionsMutation();
  // const [customerOptions] = customerListOptions;

  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  const Customer = useCustomerListQuery(token);
  useEffect(() => {
    if (Customer.isSuccess) {
      setCustomerTypeList(Customer.data?.data);
    }
  }, [Customer.isFetching]);

  // useEffect for countylist
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    }
    if (countyList2[1].error) {
      setGetError(
        countyList2[1].error?.data?.error ||
          countyList2[1].error?.data?.message ||
          countyList2[1].error.data?.msg?.sqlMessage ||
          (countyList2[1].error.error && "server unreachable!")
      );
    }
  }, [countyList2[1].isLoading]);

  //useEffect for setting default values
  useEffect(() => {
    setStateData(stateList);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setCountyOptions([]);
  }, []);

  const sort = (sortOrder, sortfield) => {
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };
  //status list for clor
  const statusColorApi = useStatusListQuery(token);
  // order list query
  const invoiceListApi = useInvoiceReportListQuery(token);

  useEffect(() => {
    if (invoiceListApi.isSuccess) {
      setColumns({ columns: Object.keys(invoiceListApi.data.data[0]) });
      const newData = invoiceListApi.data?.data?.map(
        ({
          id,
          branch_name,
          file_number,
          wholesale_fee,
          retail_fee,
          created_at,
          completion_date,
          paid_date,
          ...others
        }) => {
          return {
            ...others,
            id,
            key: id,
            branch_name: branch_name || " ",
            file_number: file_number || " ",
            wholesale_fee: wholesale_fee || " ",
            retail_fee: retail_fee || " ",
            created_at: created_at || " ",
            completion_date: completion_date || " ",
            paid_date: paid_date || "",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoiceListApi.isFetching]);

  useEffect(() => {
    if (statusColorApi.isSuccess) {
      setStatusColor(statusColorApi.data?.data);
    } else {
      setStatusColor([]);
    }
  }, [statusColorApi.isFetching]);

  //calling rtk query for filter orders
  const [invoiceFilterList, invoiceFilterListResult] =
    useInvoiceReportFilterListMutation();
  useEffect(() => {
    if (invoiceFilterListResult.isSuccess) {
      const newData = invoiceFilterListResult.data?.data?.map(
        ({
          id,
          branch_name,
          file_number,
          wholesale_fee,
          retail_fee,
          created_at,
          completion_date,
          paid_date,
          ...others
        }) => {
          return {
            ...others,
            id: id,
            branch_name: branch_name || " ",
            file_number: file_number || " ",
            wholesale_fee: wholesale_fee || " ",
            retail_fee: retail_fee || " ",
            created_at: created_at || " ",
            completion_date: completion_date || " ",
            paid_date: paid_date || " ",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [invoiceFilterListResult.isLoading]);

  //columns
  const columns = [
    {
      title: (
        <span>
          Branch{" "}
          {getsortfield === "branch_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "branch_name" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "branch_name" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "branch_name",
      key: "branch_name",
      sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
      width: 200,
      align: "center",
      ...SearchColumns("branch_name", "Branch"),
    },
    {
      title: (
        <span>
          GF Number{" "}
          {getsortfield === "file_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "file_number",
      key: "file_number",
      sorter: (a, b) => a.file_number.localeCompare(b.file_number),
      width: 200,
      align: "center",
      ...SearchColumns("file_number", "GF Number"),
    },
    {
      title: (
        <span>
          Wholesale Price{" "}
          {getsortfield === "wholesale_fee" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "wholesale_fee" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "wholesale_fee" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "wholesale_fee",
      key: "wholesale_fee",
      sorter: (a, b) =>
        a.wholesale_fee?.replace(/[|&;$%@"<>()+,]/g, "") -
        b.wholesale_fee?.replace(/[|&;$%@"<>()+,]/g, ""),
      width: 200,
      align: "center",
      ...SearchColumns("wholesale_fee", " Wholesale Price"),
      render: (value) => {
        return (
          <>
            {value
              ? //it will convert price format
                priceFormater(value)
              : " "}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Retail Price{" "}
          {getsortfield === "retail_fee" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "retail_fee" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "retail_fee" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "retail_fee",
      key: "retail_fee",
      sorter: (a, b) =>
        Number(a.retail_fee?.replace(/[|&;$%@"<>()+,]/g, "")) -
        b.retail_fee?.replace(/[|&;$%@"<>()+,]/g, ""),
      width: 200,
      align: "center",
      ...SearchColumns("retail_fee", "Retail Price"),
      render: (value) => {
        return (
          <>
            {value
              ? //it will convert price format
                priceFormater(value)
              : " "}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Date Created{" "}
          {getsortfield === "created_at" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "created_at" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "created_at" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      width: 200,
      align: "center",
      ...SearchColumns("created_at", "Date Created"),
    },
    {
      title: (
        <span>
          Date Completed{" "}
          {getsortfield === "completion_date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "completion_date" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" &&
            getsortfield === "completion_date" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "completion_date",
      key: "completion_date",
      sorter: (a, b) => a.completion_date.localeCompare(b.completion_date),
      width: 200,
      align: "center",
      ...SearchColumns("completion_date", "Date Completed"),
    },
    {
      title: (
        <span>
          Paid Date{" "}
          {getsortfield === "paid_date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "paid_date" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "paid_date" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "paid_date",
      key: "paid_date",
      sorter: (a, b) => a.paid_date.localeCompare(b.paid_date),
      width: 200,
      align: "center",
      ...SearchColumns("paid_date", "Paid Date"),
    },
  ];

  useEffect(() => {
    setSearch("");
    if (refresh === "InvoiceReport") {
      setMapInvoiceTab(false);
      invoiceListApi.refetch();
      setCountyOptions([]);
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [refresh]);

  const inputs = [
    {
      id: 6,
      name: "state_id",
      type: "select",
      value: values.state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      column: "12",
      required: false,
    },
    {
      id: 7,
      name: "county_id",
      type: "select",
      value: values.county_id || "",
      placeholder: "Select County",
      errorMessage: "Please select county!",
      label: "County",
      options: countyOptions,
      column: "12",
      required: false,
    },
    {
      id: 4,
      name: "customer_id",
      type: "select",
      value: values.customer_id || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer!",
      label: "Customer",
      options: customertypeList,
      column: "12",
      required: false,
    },
  ];

  const btnStyle = { color: "#cacaca" };
  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //select handler
  const selectHandler = (property, value) => {
    setValues({
      ...values,
      [property]: Number(value),
    });

    if (property === "customer_id") {
      if (Number(value) !== 0) {
        setValues({
          ...values,
          [property]: Number(value),
        });
      } else {
        setValues({
          ...values,
          [property]: null,
        });
      }
    }

    if (property === "state_id") {
      const state_id = { state_id: Number(value) };
      if (value) {
        getCountyList({ state_id, token: token });
        setValues({
          ...values,
          [property]: Number(value),
          county_id: "",
        });
      } else {
        setCountyOptions([]);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    // setValues(initialValues);
    // e.target.reset();
    if (
      values.customer_id ||
      values.start_date ||
      values.end_date ||
      values.county_id ||
      values.state_id
    ) {
      invoiceFilterList({
        data: {
          customer_id: Number(values.customer_id),
          start_date: values.start_date,
          end_date: values.end_date,
          state_id: Number(values.state_id),
          county_id: Number(values.county_id),
        },
        token: token,
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    setCountyOptions([]);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    invoiceListApi.refetch();
    //   e.target.reset();
  };

  const Onclose = () => {
    setValues(initialValues);
    setCountyOptions([]);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    invoiceListApi.refetch();
    setOpen(false);
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("InvoiceReportError");
    }
  }, [getError]);

  //demo data
  const config1 = {
    data: demoData1,
    xField: "Date",
    yField: "scales",
    seriesField: "type",
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    smooth: true,
    // autoFit:true,
    padding: "15",
    height: 300,
    width: 300,
    // appendPadding: 70,
  };
  const config = {
    data: demoData,
    xField: "Date",
    yField: "scales",
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    smooth: true,
    // autoFit:true,
    padding: "15",
    height: 300,
    width: 300,
    // appendPadding: 70,
  };

  const invoicebtn = {
    borderColor: "#1b77ff",
    backgroundColor: "#eff8ff",
  };

  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const cols = useMemo(() => columns, [columns]);
  const data1 = useMemo(() => data, [data]);

  const Excelcolumns = [
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
    },
    {
      title: "GF Number",
      dataIndex: "file_number",
      key: "file_number",
    },
    {
      title: "Wholesale Price",
      dataIndex: "wholesale_fee",
      key: "wholesale_fee",
    },
    {
      title: "Retail Price",
      dataIndex: "retail_fee",
      key: "retail_fee",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Paid Date",
      dataIndex: "paid_date",
      key: "paid_date",
    },
  ];

  const exportInvoiceData = () => {
    const excel = new Excel();
    excel
      .addSheet("Sheet1")
      .addColumns(Excelcolumns)
      .addDataSource(
        data1?.map(({ wholesale_fee, retail_fee, ...rest }) => {
          return {
            ...rest,
            wholesale_fee: priceDecimalCoverter(wholesale_fee),
            retail_fee: priceDecimalCoverter(retail_fee),
          };
        }),
        {
          str2Percent: true,
        }
      )
      .saveAs("Invoice Reports.xlsx");
  };

  return (
    <>
      {invoiiceMapTab === false ? (
        <>
          <div id="OdersReportError">
            {getError && <Alert message={getError} type="error" showIcon />}
          </div>
          {(invoiceListApi.isFetching || invoiceFilterListResult.isLoading) && (
            <AppSpinner />
          )}
          <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
            {/* changing input values globaly  */}
            <>
              <div className="d-flex align-items-center gap-2 justify-content-end m-0">
                <div
                  className="file-icons"
                  data-toggle="tooltip"
                  title="Excel Export"
                >
                  <Button
                    size="small"
                    type="primary"
                    onClick={exportInvoiceData}
                  >
                    Export Data
                  </Button>
                </div>
                <div data-toggle="tooltip" title="Data View">
                  <Button
                    onClick={() => setMapInvoiceTab(false)}
                    style={!invoiiceMapTab ? invoicebtn : {}}
                  >
                    <FcGrid size={20} />
                  </Button>
                </div>
                <div data-toggle="tooltip" title="Analytical View">
                  <Button onClick={() => setMapInvoiceTab(true)}>
                    <FcComboChart size={20} />
                  </Button>
                </div>
              </div>
              <div className=" me-1 w-25 ">
                <Input
                  placeholder="Search here"
                  className="global-search "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<FaSearch color="lightgray" />}
                />
              </div>
              <div className="filter-button ">
                <Button
                  onClick={() => setOpen(true)}
                  type={open ? "primary" : "default"}
                >
                  <FiFilter color={open ? "#fff" : ""} />{" "}
                  <span className="ms-1">Filter</span>
                </Button>
              </div>
              <FiRefreshCcw
                type="button"
                id="template"
                onClick={() => {
                  invoiceListApi.refetch();
                  setState([
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: "selection",
                    },
                  ]);
                  setCountyOptions([]);
                  setValues(initialValues);
                }}
                title="Refresh"
                size={20}
              />
            </>
          </div>
          <div>
            <>
              <Drawer
                title={"Select Filters"}
                placement="right"
                onClose={Onclose}
                maskClosable={true}
                open={open}
                destroyOnClose={true}
                closable={false}
                className="order view-parcel"
                extra={
                  <Space>
                    <BiX size={20} style={btnStyle} onClick={Onclose} />
                  </Space>
                }
              >
                <Form onSubmit={handleSubmit} action="">
                  {(countyList2[1].isLoading ||
                    customerListOptions[1].isLoading) && <AppSpinner />}
                  <div className=" mx-2">
                    <Row>
                      <div className="col-12 ">
                        <AppDateRangePicker
                          label="Select Date Range"
                          onChange={(item) => {
                            setState([item.selection]);
                            const start_date = convertDate(
                              item.selection.startDate
                            );
                            const end_date = convertDate(
                              item.selection.endDate
                            );
                            setValues({
                              ...values,
                              start_date: start_date,
                              end_date: end_date,
                            });
                          }}
                          ranges={state}
                          months={1}
                        />
                      </div>
                      {inputs.map((input) => (
                        <>
                          {input.type === "date" &&
                            input.name === "start_date" && (
                              <AppInput
                                key={input.id}
                                {...input}
                                value={values[input.name]}
                                max={values.end_date}
                                onChange={onChange}
                              />
                            )}
                          {input.type === "date" &&
                            input.name === "end_date" && (
                              <AppInput
                                key={input.id}
                                {...input}
                                value={values[input.name]}
                                min={values.start_date}
                                onChange={onChange}
                              />
                            )}

                          {/* //appSelcet for customer type name  */}
                          {input.type === "select" &&
                            input.name === "customer_id" && (
                              //base on user field flags

                              <AppSelect
                                key={input.id}
                                {...input}
                                onChange={selectHandler}
                                // defaultSet={afterClose}
                                options={
                                  <>
                                    <option value={""}> Select Customer</option>
                                    {input?.options?.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}

                          {input.type === "select" &&
                            input.name === "state_id" && (
                              //base on user field flags
                              <AppSelect
                                key={input.id}
                                {...input}
                                // defaultSet={afterClose}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select State</option>
                                    {input.options.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.state_name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}

                          {input.type === "select" &&
                            input.name === "county_id" && (
                              //base on user field flags
                              <AppSelect
                                key={input.id}
                                {...input}
                                // defaultSet={afterClose}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select County</option>
                                    {input.options.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.county_name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}
                        </>
                      ))}
                    </Row>
                  </div>
                  <div className="d-flex justify-content-center pb-3 pt-2 mt-5">
                    <div className="me-md-2 ">
                      <AppButton
                        onClick={handleReset}
                        title={"CLEAR"}
                        variant={"secondary-md"}
                      />
                    </div>
                    <AppButton
                      className="search-btn"
                      disabled={
                        values.state_id ||
                        values.county_id ||
                        values.customer_id ||
                        values.start_date ||
                        values.end_date
                          ? false
                          : true
                      }
                      title={"SEARCH"}
                      variant={"primary-md"}
                      type={"submit"}
                    />
                  </div>
                </Form>
              </Drawer>
            </>
          </div>
          <div style={{ clear: "both" }}></div>

          <AppTable
            loading={invoiceListApi.isFetching}
            sort={sort}
            search={search}
            dataSource={data1}
            columns={cols}
          />
        </>
      ) : (
        <>
          <div className="d-flex align-items-center gap-2 justify-content-end mb-2">
            <div data-toggle="tooltip" title="Data View">
              <Button onClick={() => setMapInvoiceTab(false)}>
                <FcGrid size={20} />
              </Button>
            </div>
            <div data-toggle="tooltip" title="Analytical View">
              <Button
                onClick={() => setMapInvoiceTab(true)}
                style={invoiiceMapTab ? invoicebtn : {}}
              >
                <FcComboChart size={20} />
              </Button>
            </div>
          </div>
          <Card className="invoice-chart-row mt-0">
            <div className="d-flex align-items-center  mb-1 gap-2  justify-content-between">
              <div>
                <h5 align="left" className="h-5 ">
                  Revenue / Payment Recieved Graph
                </h5>
              </div>
              <div>
                <AppSelect
                  classStyle={"border px-2 py-1 "}
                  //defaultSet={afterClose}
                  name="year"
                  onChange={selectHandler}
                  value={values.year}
                  defaultValue={new Date().getFullYear()}
                  options={
                    <>
                      <option value={0} selected>
                        Select Year
                      </option>
                      {yearOptions?.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.year}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <Line {...config} />
            </div>
          </Card>
          <Card className="invoice-chart-row mt-3">
            <div className="d-flex align-items-center  mb-1 gap-2  justify-content-between">
              <div>
                <h5 align="left" className="h-5 ">
                  Revenue / Payment Recieved Graph - Overall Customers Report
                </h5>
              </div>
              <div>
                <AppSelect
                  classStyle={"border px-2 py-1 "}
                  //   defaultSet={afterClose}
                  name="year"
                  onChange={selectHandler}
                  value={values.year}
                  defaultValue={new Date().getFullYear()}
                  options={
                    <>
                      <option value={0} selected>
                        Select Year
                      </option>
                      {yearOptions?.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.year}
                          </option>
                        );
                      })}
                      {/* {Array.from(new Array(50), (v, i) => (
                          <option key={i} value={year - i}>
                            {year - i}
                          </option>
                        ))} */}
                    </>
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              {demoData.length !== 0 ? (
                <ColumnBarChart {...config1} />
              ) : (
                <Empty className="mt-4 mb-3" />
              )}
            </div>
          </Card>
        </>
      )}
    </>
  );
};
