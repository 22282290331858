import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const integrationBridge = createApi({
  reducerPath: "integrationBridge",
  tagTypes: ["integrationBridge"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    integrationBridgeList: builder.query({
      query: (token) => ({
        url: `/integrationbridge-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["integrationBridge"],
    }),
  }),
});

export const {useIntegrationBridgeListQuery} = integrationBridge;
