import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// import "antd/dist/antd.css";
import "./MultiJurisdiction.css";
import {
  Popconfirm,
  Alert,
  Tooltip,
  Switch,
  Button,
  Table,
} from "antd";
import { FaPlus } from "react-icons/fa";
import {  QuestionCircleOutlined } from "@ant-design/icons";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import AppTable from "../AppTable/AppTable";
import { useSelector } from "react-redux";
import { MdOutlineDelete } from "react-icons/md";
import {
  compareChange,
  priceFormater,
  removeBlank,
} from "../../Controllers/Functions";
import { useJuriInfoSelectMutation } from "../../Redux/Services/Jurisdiction";
import { GlobalContext } from "../../App";
import InlineTable from "../antd/Tables/InlineTable";
import { updateItem } from "../KendoComps/KendoTable/services";
const randomPass = require("secure-random-password");
export const MultiJurisdiction2 = ({
  afterClose,
  initailValArr = [],
  addFormVal,
  juriListOptions,
  updateJurisdiction,
  deleteJurisdiction,
  isedit = false,
  parcelData,
  newAdd,
  taxData,
}) => {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const initialVal = {
    jurisdiction_id: "",
    jurisdiction_name: taxData?.tax_collector_name,
    tax_office_code: "",
    tax_amount: "",
    cad_office_code: "",
    penalty: "",
    total_due: "",
    notes: "",
    current_exemption_applied: "",
    estimated_tax_without_exemption: "",
    hom: "",
    ov65: "",
    due_date: "",
    total_amount_paid: "",
    disabled_person: "",
    exemption_other: "",
    bonds_issued: "",
    stand_by_fees: "",
    tax_rate: "",
    collection_percentage: "",
    early_pay_discounts_percentage: "",
    verified_date: "",
    balance: "",
    next_balance: "",
    prior_years: "",
    year: parcelData.year,
  };

  const [values, setValues] = useState(initialVal);
  const [data, setData] = useState([]);

  const [getError, setGetError] = useState(false);
  const [showfieldPrior, setShowFieldPrior] = useState(false);
  const [showExemptionOnly, setShowExemptionOnly] = useState(false);

  //redux data store
  const { token} = useSelector((state) => state.auth);

  //update info of tax juri details
  const [fetchJuriInfo, fetchJuriInfoResult] = useJuriInfoSelectMutation();

  //update info of tax juri details for select exiting data
  const [fetchJuriInfoExitsData, fetchJuriInfoExitsDataResult] =
    useJuriInfoSelectMutation();

  // useEffect for juri info based on juri code
  useEffect(() => {
    if (fetchJuriInfoResult.isSuccess) {
      // setJuriListOptions(fetchJuriInfoResult.data?.data);
      const { id, ...rest } = fetchJuriInfoResult.data?.data[0];
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      const newData = {
        total_due: "$0",
        balance: "$0",
        next_balance: "$0",
        ...rest,
        year: parcelData.year,
        tempId: randomId,
        tax_year: "",
        jurisdiction_id: myArrayFiltered[0]?.id,
        jurisdiction_name: myArrayFiltered[0]?.display_name,
      };
      // setDataSource([...dataSource, newData]);
      setData([...data, newData]);
      updateJurisdiction && updateJurisdiction(newData);
      //after adding row scroll to bottom the table
    }
    if (fetchJuriInfoResult.isError) {
      setGetError(
        fetchJuriInfoResult.error?.data?.error ||
          fetchJuriInfoResult.error?.data?.message ||
          fetchJuriInfoResult.error.error ||
          fetchJuriInfoResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: fetchJuriInfoResult.isLoading });
  }, [fetchJuriInfoResult.isLoading]);

  // useEffect for juri info based on juri code
  useEffect(() => {
    if (fetchJuriInfoExitsDataResult.isSuccess) {
      // setJuriListOptions(fetchJuriInfoExitsDataResult.data?.data);
      const { id, ...rest } = fetchJuriInfoExitsDataResult.data?.data[0];

      const juriData = juriListOptions.filter((ele) => {
        return ele.id === id;
      });

      const newData = {
        ...values,
        ...rest,
        jurisdiction_id: juriData[0].id,
        jurisdiction_name: juriData[0].display_name,
      };
      setData([...updateItem(newData, data)]);
      updateJurisdiction && updateJurisdiction(newData);
    }
    if (fetchJuriInfoExitsDataResult.isError) {
      setGetError(
        fetchJuriInfoExitsDataResult.error?.data?.error ||
          fetchJuriInfoExitsDataResult.error?.data?.message ||
          fetchJuriInfoExitsDataResult.error.error ||
          fetchJuriInfoExitsDataResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: fetchJuriInfoExitsDataResult.isLoading,
    });
  }, [fetchJuriInfoExitsDataResult.isLoading]);

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    // //sending delete id
    deleteJurisdiction(id);
  };

  const juriListOptionsFilter = [];
  data?.map(({ jurisdiction_name }) => {
    juriListOptionsFilter.push(jurisdiction_name);
  });

  const myArrayFiltered = juriListOptions?.filter((el) => {
    return !juriListOptionsFilter.includes(el.display_name);
  });

  const addpriorYearHandler = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });
    const newData = {
      jurisdiction_id: 999998,
      year: parcelData.year,
      jurisdiction_name: taxData.tax_collector_name,
      tempId: randomId,
    };
    setPriorData([newData]);
    //send prior data
    updateJurisdiction && updateJurisdiction(newData);
  };

  useEffect(() => {
    setShowFieldPrior(false);
    setData(initailValArr);
    setValues(initialVal);
    setPriorData(initailValArr);
    setGetError(false);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
    setPriorData(initailValArr);
  }, [initailValArr]);

  const PriorYear = data?.filter(({ jurisdiction_id }) => {
    return jurisdiction_id === 999998;
  });

  //exemption columns
  const exemptionsColumns = [
    {
      title: <span> HOM</span>,
      dataIndex: "hom",
      key: "hom",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>OV65</span>,
      dataIndex: "ov65",
      key: "ov65",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>Disable Person</span>,
      dataIndex: "disabled_person",
      key: "disabled_person",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>First Responder</span>,
      dataIndex: "first_responder",
      key: "first_responder",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Verified Date</span>,
      dataIndex: "verified_date",
      key: "verified_date",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 100,
    },
    {
      title: <span>Other Exemption</span>,
      dataIndex: "exemption_other",
      key: "exemption_other",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 100,
    },
    {
      title: <span>Solar</span>,
      dataIndex: "solar",
      key: "solar",
      align: "center",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      width: 60,
    },
    {
      title: <span>Bonds Approved</span>,
      dataIndex: "bonds_approved",
      key: "bonds_approved",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Bonds Issues</span>,
      dataIndex: "bonds_issued",
      key: "bonds_issued",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },

    {
      title: <span>Stand By Fees</span>,
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      render: (value) => {
        return <>{value || "NA"}</>;
      },
      align: "center",
      width: 100,
    },
  ];

  const cols = [
    {
      title: "Jurisdiction Name",
      dataIndex: "jurisdiction_name",
      key: "jurisdiction_name",
      type: "select",
      options: myArrayFiltered?.map(({ id, display_name }) => {
        return { id: display_name, name: display_name, data_name: id };
      }),
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "jurisdiction_name")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">{value}</div>
            ) : (
              value || "NA"
            )}
          </div>
        );
      },
      width: 100,
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Jurisdiction Code",
      dataIndex: "cad_office_code",
      key: "cad_office_code",
      render: (value, record) => {
        return (
          <div>
            {record.jurisdiction_id === 999998 ? (
              <div className={"heading-5 text-danger fs-11 fw-bold"}>
                {value || "NA"}
              </div>
            ) : (
              <>
                {record.key === "total" ? (
                  <div className="heading-5 fs-10 fw-bold text-dark">{"-"}</div>
                ) : (
                  value || "NA"
                )}
              </>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: "Rate",
      dataIndex: "tax_rate",
      key: "tax_rate",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "tax_rate")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? `$${value.replace(/[|&;$%@"<>()+,]/g, "")}` : "NA"}
              </div>
            ) : value ? (
              `$${Number(value.replace(/[|&;$%@"<>()+,]/g, "")).toLocaleString(
                undefined,
                { minimumFractionDigits: 6 }
              )}`
            ) : (
              "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Estimated Tax",
      dataIndex: "estimated_tax_without_exemption",
      key: "estimated_tax_without_exemption",
      render: (value, record) => {
        return (
          <>
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                    priceFormater(value)
                  : "NA"}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              "NA"
            )}
          </>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: () => {
        return false;
      },
    },
    {
      title: "Base Amount",
      dataIndex: "tax_amount",
      key: "tax_amount",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "tax_amount")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? priceFormater(value) : "NA"}
              </div>
            ) : value ? (
              priceFormater(value)
            ) : (
              "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999999) {
          return false;
        } else {
          return false;
        }
      },
    },

    {
      title: "Penalities",
      dataIndex: "penalty",
      key: "penalty",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "penalty")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Fees",
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "stand_by_fees")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Collects",
      dataIndex: "collects",
      key: "collects",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "collects")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Base Due",
      dataIndex: "total_due",
      key: "total_due",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "total_due")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                    priceFormater(value)
                  : "NA"}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "balance")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                    priceFormater(value)
                  : "NA"}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },
    {
      title: "Next Balance",
      dataIndex: "next_balance",
      key: "next_balance",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "next_balance")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                    priceFormater(value)
                  : "NA"}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit;
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit;
        } else {
          return false;
        }
      },
    },

    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "due_date")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 150,
      type: "date",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return false;
        } else {
          return !isedit;
        }
      },
    },
    {
      title: "Prior Year",
      dataIndex: "prior_years",
      key: "prior_years",
      render: (value, record) => {
        return (
          <div
            className={`${
              compareChange(initailValArr, record, "prior_years")
                ? "text-warning fw-bold"
                : null
            }`}
          >
            {record.jurisdiction_id === 999998 ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || "NA"}
              </div>
            ) : (
              value || "NA"
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "text",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return false;
        } else {
          return !isedit;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler(record);
    if (record.jurisdiction_id === 999998) {
      const newData = priorData?.filter(
        (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
      );
      setPriorData(newData);
    } else {
      const newData = data?.filter(
        (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
      );
      setData(newData);
    }
  };

  const handleAdd = () => {
    fetchJuriInfo({
      data: {
        jurisdiction_id: myArrayFiltered[0].id,
        year: parcelData.year,
      },
      token,
    });
  };

  var total = {
    tax_rate: 0,
    tax_amount: 0,
    estimated_tax_without_exemption: 0,
    penalty: 0,
    stand_by_fees: 0,
    collects: 0,
    total_due: 0,
    balance: 0,
    next_balance: 0,
  };
  const summaryCallFun = useCallback(
    (pageData) => {
      let newrate = 0;
      let newfees = 0;
      let newpenalty = 0;
      let newtotal_amount_paid = 0;
      let collects = 0;
      let newtax_amount = 0;
      let newestimated_tax_amount = 0;
      let newtotal_due = 0;
      let newbalance = 0;
      let newnext_balance = 0;

      pageData.forEach((row) => {
        if (row["tax_rate"]) {
          newrate += Number(row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["stand_by_fees"]) {
          newfees += Number(
            row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["collects"]) {
          collects += Number(row["collects"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["tax_amount"]) {
          newtax_amount += Number(
            row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["total_due"]) {
          newtotal_due += Number(
            row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["estimated_tax_without_exemption"]) {
          newestimated_tax_amount += Number(
            row["estimated_tax_without_exemption"].replace(
              /[|&;$%@"<>()+,]/g,
              ""
            )
          );
        }

        if (row["penalty"]) {
          newpenalty += Number(row["penalty"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["total_amount_paid"]) {
          newtotal_amount_paid += Number(
            row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
          );
          var total_amount_paid =
            "$" +
            newtotal_amount_paid.toLocaleString(undefined, {
              maximumFractionDigits: 6,
            });
        }

        if (row["balance"]) {
          newbalance += Number(row["balance"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["next_balance"]) {
          newnext_balance += Number(
            row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        total["tax_rate"] = newrate;
        total["tax_amount"] = newtax_amount;
        total["penalty"] = newpenalty;
        total["stand_by_fees"] = newfees;
        total["collects"] = collects;
        total["total_due"] = newtotal_due;
        total["estimated_tax_without_exemption"] = newestimated_tax_amount;
        total["balance"] = newbalance;
        total["next_balance"] = newnext_balance;
      });
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell editable={true} index={0}>
              <span className="text-dark fw-bold "> Total</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-dark fw-bold "> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={2} align="center">
              <span className="text-dark fw-bold ">
                {newrate ? (
                  `$${newrate.toLocaleString(undefined, {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 6,
                  })}`
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={4} align="center">
              <span className="text-dark fw-bold ">
                {newestimated_tax_amount ? (
                  `$${newestimated_tax_amount.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={3} align="center">
              <span className="text-dark fw-bold ">
                {priceFormater(newtax_amount, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={5} align="center">
              <span className="text-dark fw-bold ">
                {newpenalty ? priceFormater(newpenalty, true) : <>&nbsp;</>}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={6} align="center">
              <span className="text-dark fw-bold ">
                {newfees ? priceFormater(newfees, true) : <>&nbsp;</>}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={7} align="center">
              <span className="text-dark fw-bold ">
                {collects ? priceFormater(collects, true) : <>&nbsp;</>}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={8} align="center">
              <span className="text-dark fw-bold ">
                {priceFormater(newtotal_due, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9} align="center">
              <span className="text-dark fw-bold ">
                {priceFormater(newbalance, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={10} align="center">
              <span className="text-dark fw-bold ">
                {priceFormater(newnext_balance, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={11} align="center">
              <span className="text-dark fw-bold ">NA</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12} align="center">
              <span className="text-dark fw-bold ">NA</span>
            </Table.Summary.Cell>
            {!isedit && (
              <Table.Summary.Cell index={13} align="center">
                <span className="text-dark fw-bold "></span>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </>
      );
    },
    [isedit, total, data]
  );

  const oneEditFun = useCallback(
    async (value, id, type) => {
      let juriData = juriListOptions?.filter((ele) => {
        return ele.display_name === value?.jurisdiction_name;
      });

      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { jurisdiction_id, ...rest } = value;
        if (type === "select" && juriData?.length > 0) {
          fetchJuriInfoExitsData({
            data: {
              jurisdiction_id: juriData[0].id,
              year: parcelData.year,
            },
            token,
          });
          // setValues({ ...values, ...value });
        } else if (type !== "select") {
          // console.log({...value,
          //   jurisdiction_id: newDataVals[0].id,
          // });
          updateJurisdiction &&
            updateJurisdiction({
              ...values,
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data]
  );

  const data1 = useMemo(
    () =>
      data.filter((ele) => {
        return ele.jurisdiction_id !== 999998;
      }),
    [data]
  );

  const cols1 = useMemo(() => cols, [cols]);

  //prior data

  const [priorData, setPriorData] = useState([]);
  const priorData1 = useMemo(
    () => priorData.filter((ele) => ele.jurisdiction_id === 999998),
    [priorData]
  );

  const onPriorEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);

      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        if (value?.tempId) {
          const { id, ...res } = value;
          updateJurisdiction && updateJurisdiction(res);
        } else {
          updateJurisdiction &&
            updateJurisdiction({
              ...values,
              ...value,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [priorData]
  );

  let grandTotalVals = {
    tax_rate: 0,
    tax_amount: 0,
    penalty: 0,
    stand_by_fees: 0,
    collects: 0,
    total_due: 0,
    balance: 0,
    next_balance: 0,
    estimated_tax_without_exemption: 0,
  };
  const summaryPriorCallFun = useCallback(
    (pageData) => {
      let newrate = 0;
      let newfees = 0;
      let newpenalty = 0;
      let newtotal_amount_paid = 0;
      let collects = 0;
      let newtax_amount = 0;
      let newestimated_tax_amount = 0;
      let newtotal_due = 0;
      let newbalance = 0;
      let newnext_balance = 0;
      pageData.forEach((row) => {
        if (row["tax_rate"]) {
          newrate += Number(row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["stand_by_fees"]) {
          newfees += Number(
            row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["collects"]) {
          collects += Number(row["collects"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["tax_amount"]) {
          newtax_amount += Number(
            row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["total_due"]) {
          newtotal_due += Number(
            row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["estimated_tax_without_exemption"]) {
          newestimated_tax_amount += Number(
            row["estimated_tax_without_exemption"].replace(
              /[|&;$%@"<>()+,]/g,
              ""
            )
          );
        }
        if (row["penalty"]) {
          newpenalty += Number(row["penalty"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["total_amount_paid"]) {
          newtotal_amount_paid += Number(
            row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["balance"]) {
          newbalance += Number(row["balance"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["next_balance"]) {
          newnext_balance += Number(
            row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        grandTotalVals["tax_rate"] = total.tax_rate + newrate;
        grandTotalVals["tax_amount"] = total.tax_amount + newtax_amount;
        grandTotalVals["penalty"] = total.penalty + newpenalty;
        grandTotalVals["stand_by_fees"] = total.stand_by_fees + newfees;
        grandTotalVals["collects"] = total.collects + collects;
        grandTotalVals["total_due"] = total.total_due + newtotal_due;
        grandTotalVals["estimated_tax_without_exemption"] =
          total.estimated_tax_without_exemption + newestimated_tax_amount;
        grandTotalVals["balance"] = total.balance + newbalance;
        grandTotalVals["next_balance"] = total.next_balance + newnext_balance;
      });
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <span className="text-success fw-bold "> Grand Total</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-success fw-bold "> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.tax_rate ? (
                  `$${grandTotalVals.tax_rate.toLocaleString(undefined, {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 6,
                  })}`
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={4} align="center">
              <span className="text-dark fw-bold ">
                {grandTotalVals.estimated_tax_without_exemption ? (
                  `$${grandTotalVals.estimated_tax_without_exemption.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )}`
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.tax_amount ? (
                  priceFormater(grandTotalVals.tax_amount, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.penalty ? (
                  priceFormater(grandTotalVals.penalty, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.stand_by_fees ? (
                  priceFormater(grandTotalVals.stand_by_fees, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.collects ? (
                  priceFormater(grandTotalVals.collects, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.total_due ? (
                  priceFormater(grandTotalVals.total_due, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.balance ? (
                  priceFormater(grandTotalVals.balance, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10} align="center">
              <span className="text-success fw-bold ">
                {grandTotalVals.next_balance ? (
                  priceFormater(grandTotalVals.next_balance, true)
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11} align="center">
              <span className="text-success fw-bold ">NA</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12} align="center">
              <span className="text-success fw-bold ">NA</span>
            </Table.Summary.Cell>

            {!isedit && (
              <Table.Summary.Cell index={13} align="center">
                <span className="text-success fw-bold "></span>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </>
      );
    },
    [isedit, grandTotalVals]
  );

  useEffect(() => {
    if (data1.length) {
      const {
        collects,
        next_balance,
        balance,
        penalty,
        stand_by_fees,
        tax_amount,
        tax_rate,
        total_due,
      } = total;
      addFormVal &&
        addFormVal(
          {
            jurisdiction_id: 999997,
            collects: `$${collects}`,
            next_balance: `$${next_balance}`,
            balance: `$${balance}`,
            penalty: `$${penalty}`,
            stand_by_fees: `$${stand_by_fees}`,
            tax_amount: `$${tax_amount}`,
            tax_rate: `$${tax_rate}`,
            total_due: `$${total_due}`,
            year: parcelData.year,
            tax_year: parcelData.year,
            current_year_bill: parcelData.year,
          },
          {
            jurisdiction_id: 999999,
            collects: `$${grandTotalVals.collects}`,
            next_balance: `$${grandTotalVals.next_balance}`,
            balance: `$${grandTotalVals.balance}`,
            penalty: `$${grandTotalVals.penalty}`,
            stand_by_fees: `$${grandTotalVals.stand_by_fees}`,
            tax_amount: `$${grandTotalVals.tax_amount}`,
            tax_rate: `$${grandTotalVals.tax_rate}`,
            total_due: `$${grandTotalVals.total_due}`,
          }
        );
    }
  }, [data1, priorData1]);

  useEffect(() => {
    newAdd && newAdd([...data, ...priorData]);
  }, [data, priorData]);

  return (
    <div>
      {
        //classname add tax-card-table
        <div className="tax-card-table gray-table  rounded-2 ">
          <div
            className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
          >
            <h6 className="fs-18 fw-bold me-2 "></h6>

            <div className="action-buttons d-flex align-items-center gap-2">
              {!showfieldPrior && !PriorYear[0] && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="left"
                  title={!showExemptionOnly ? "Show Exemptions" : "Show Bills"}
                >
                  <span className="me-2 d-inline-block fs-10 fw-bold text-dark">
                    Bills / Exemptions
                  </span>
                  <Switch
                    onChange={() => {
                      setShowExemptionOnly(!showExemptionOnly);
                    }}
                    size="small"
                  ></Switch>
                </Tooltip>
              )}
              {!isedit && priorData1.length < 1 && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Add Prior Year Bill"}
                >
                  <div
                    className={`tax-card-icon`}
                    onClick={addpriorYearHandler}
                  >
                    <AiOutlineMenuUnfold />
                  </div>
                </Tooltip>
              )}
              {!isedit && myArrayFiltered?.length > 0 && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Add Jurisdiction"}
                >
                  <div className={`tax-card-icon`} onClick={handleAdd}>
                    <FaPlus style={{ backgroundColor: "white" }} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="gray-table-col">
            {getError && (
              <div className="mt-3">
                <Alert
                  message={getError}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}

            {!showExemptionOnly ? (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Bills</p>
                {/* <AppTable
                  pagination={false}
                  columns={defaultColumns}
                  dataSource={ToalAmount.concat(PriorYear || [])
                    .concat(
                      grandTotal?.filter((ele) => {
                        return ele.key === "Grand";
                      })
                    )
                    .map((ele, i) => {
                      return { ...ele, key: ele.jurisdiction_id };
                    })}
                  // expandable={defaultExpandable}
                  // defaultExpandable
                /> */}
                <InlineTable
                  onEdit={oneEditFun}
                  setData={setData}
                  data={data1}
                  cols={cols1}
                  summary={data1.length ? summaryCallFun : undefined}
                />
                {priorData1.length > 0 && (
                  <InlineTable
                    onEdit={onPriorEditFun}
                    setData={setPriorData}
                    data={priorData1}
                    cols={cols1}
                    showHeader={false}
                    summary={summaryPriorCallFun}
                  />
                )}
                <p id="table-bottom"></p>
              </div>
            ) : (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Exemptions</p>
                <AppTable
                  pagination={false}
                  columns={[
                    {
                      title: <span> Jurisdiction Name</span>,
                      dataIndex: "jurisdiction_name",
                      key: "jurisdiction_name",
                      render: (value, record) => {
                        return (
                          <>
                            {record.jurisdiction_id === 999998 ? (
                              <div className="heading-5 text-danger fs-11 fw-bold">
                                {value}
                              </div>
                            ) : (
                              value || "NA"
                            )}
                          </>
                        );
                      },
                      align: "center",
                      width: 100,
                    },
                  ].concat(exemptionsColumns)}
                  dataSource={data}
                />
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};
