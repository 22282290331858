import FormInput from "../../../Components/AppInput/AppInput";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import AppTextArea from "../../../Components/AppTextArea/AppText";
import { useSelector } from "react-redux/es/exports";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Row } from "react-bootstrap";
import { Alert, notification, Tabs, Empty } from "antd";
import "./TaxCollector.css";
import { useState, useEffect } from "react";
import {
  useAddTaxCollectorMutation,
  useAutoUpdateDetailsTaxCollectorMutation,
  useEditTaxCollectorQuery,
  useTaxReadOnlyListQuery,
  useTaxDisplayCertListQuery,
  useUpdateTaxCollectorMutation,
} from "../../../Redux/Services/TaxCollector";
import {
  useEditJurisdictionQuery,
  useJuriTaxBaseListMutation,
  useSearchJurisdictionMutation,
  useMultiCountyJuriListMutation,
} from "../../../Redux/Services/Jurisdiction";
import { useCountyJurisdictionMutation } from "../../../Redux/Services/countyJurisdiction";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import Input from "react-phone-number-input/input";
import AppCheckbox from "../../../Components/AppCheckbox/AppCheckbox";
import AppInput from "../../../Components/AppInput/AppInput";
import MultiBillCycle from "../../../Components/MultiBillCycle/MultiBillCycle";
import AppMultiSelect from "../../../Components/AppMultiSelect/AppMultiSelect";
import AppButton from "../../../Components/AppButton/AppButton";
import { removeBlank, scrollComp } from "../../../Controllers/Functions";
import AppTextEditor from "../../../Components/AppTextEditor/AppTextEditor";
import MultiCheckList from "../../../Components/MultiCheckList/MultiCheckList";
const { TabPane } = Tabs;
export const TaxCollectorModal = ({
  editId,
  initialValues,
  afterClose,
  setShowHide,
  setOpen,
}) => {
  //   values assign
  const [values, setValues] = useState(initialValues);
  const [countyOptions, setCountyOptions] = useState([]);

  const [setPopError] = useState(false);
  const [getError, setGetError] = useState(null);
  const [juriId] = useState("");
  const [read_only_comment, setRead_only_comment] = useState("");
  const [display_oncert_comment, setDisplay_oncert_comment] = useState("");
  const [juriTaxBaseListData, setJuriTaxBaseListData] = useState([]);
  const [enableCountyJurisdictionList, setEnableCountyJurisdictionList] =
    useState([]);
  //for statelist data
  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  //for taxcollectortype data

  //calling redux stor for state list
  const { stateList } = useSelector((state) => state.stateList);

  //changing tab of address
  const [addressTabKey, setAddressTabKey] = useState("1");
  //calling redux store
  const { token } = useSelector((state) => state.auth);

  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  //calling rtk query hook for geting edit taxcollector list
  const editTaxCollectorQuery = useEditTaxCollectorQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for geting edit Jurisdiction data
  const editJurisdictionQuery = useEditJurisdictionQuery(
    { id: juriId, token: token },
    { skip: !juriId }
  );

  //calling rtk query hook for geting edit Jurisdiction data
  const [juriTaxBaseList, juriTaxBaseListResult] = useJuriTaxBaseListMutation();

  //calling rtk query hook Multi COunty juir list  Jurisdiction
  const [multiCountyJuriList, multiCountyJuriListResult] =
    useMultiCountyJuriListMutation();

  //calling rtk query hook for adding taxcollector
  const addNewTaxCollector = useAddTaxCollectorMutation();
  const [addTaxCollector] = addNewTaxCollector;

  const taxReadOnlyList = useTaxReadOnlyListQuery(token);
  const taxDisplayCertList = useTaxDisplayCertListQuery(token);

  function refecthApi() {
    taxReadOnlyList.refetch();
    taxDisplayCertList.refetch();
  }
  //calling rtk query FOR getting searchJurisdiction data as import
  const juriSearchApi = useSearchJurisdictionMutation();
 
  //calling rtk query FOR getting taxCollector data as import and use in the input fields
  const autoTaxCollectorData = useAutoUpdateDetailsTaxCollectorMutation();

  //calling rtk query hook for update taxcollector
  const updateTaxCollectorData = useUpdateTaxCollectorMutation();
  const [updateTaxCollector] = updateTaxCollectorData;

  const countyJurisdictionApi = useCountyJurisdictionMutation();
  // const [getCountyJurisdictionList] = countyJurisdictionApi;

  //clear error function
  function clearStatus() {
    setGetError(null);
  }
  //function for modal close
  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }

  //useEffect for setting default values
  useEffect(() => {
    setCountyOptions([]);
    setEnableCountyJurisdictionList([]);
    setStateData(stateList);
    setValues(initialValues);
    setRead_only_comment("");
    setDisplay_oncert_comment("");
    refecthApi();
  }, [afterClose]);

  //useEffect for edit TaxCollector
  useEffect(() => {
    if (editTaxCollectorQuery.isSuccess) {
      juriTaxBaseList({ id: editId, token });
      const {
        mailing_zip_five,
        mailing_zip_four,
        physical_zip_five,
        physical_zip_four,
        read_only_comment,
        display_oncert_comment,
        state_id,
        ...othersData
      } = editTaxCollectorQuery.data?.data[0];
      var newArr = {
        ...othersData,
        mailing_zip_five: `${mailing_zip_five}${
          mailing_zip_four ? "-" + mailing_zip_four : ""
        }`,
        physical_zip_five: `${physical_zip_five}${
          physical_zip_four ? "-" + physical_zip_four : ""
        }`,
        state_id: state_id,
        add_tax_collector_bill_cycle: [],
        edit_tax_collector_bill_cycle: [],
        delete_tax_collector_bill_cycle: [],
      };

      getCountyList({ state_id: { state_id: state_id }, token: token });
      //fexthig multi county juri list
      multiCountyJuriList({ id: editId, token });
      setValues(newArr);
      setRead_only_comment(read_only_comment);
      setDisplay_oncert_comment(display_oncert_comment);
    }
    if (editTaxCollectorQuery.isError) {
      setGetError(
        editTaxCollectorQuery.error?.data?.error ||
          editTaxCollectorQuery.error?.data?.message ||
          editTaxCollectorQuery.error.data ||
          editTaxCollectorQuery.error.error ||
          editTaxCollectorQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editTaxCollectorQuery.isFetching]);

  //useEffect for error Highlight
  useEffect(() => {
    if (getError) {
      scrollComp("tax_error");
    }
  }, [getError]);

  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
      setAddressTabKey("1");
    }
  }, [countyList2[1].isLoading]);

  useEffect(() => {
    if (juriTaxBaseListResult.isSuccess) {
      setJuriTaxBaseListData(juriTaxBaseListResult.data?.data);
    }
    if (juriTaxBaseListResult.isError) {
      setJuriTaxBaseListData([]);
    }
  }, [juriTaxBaseListResult.isLoading]);

  useEffect(() => {
    if (multiCountyJuriListResult.isSuccess) {
      setEnableCountyJurisdictionList(multiCountyJuriListResult.data?.data);
    }
    if (multiCountyJuriListResult.isError) {
      setEnableCountyJurisdictionList([]);
    }
  }, [multiCountyJuriListResult.isLoading]);

  useEffect(() => {
    if (editJurisdictionQuery.isSuccess) {
      const {
        mailing_zip_five,
        mailing_zip_four,
        physical_zip_five,
        physical_zip_four,
        read_only_comment,
        display_oncert_comment,
        state_id,
        ...othersData
      } = editJurisdictionQuery.data?.data[0];
      var newArr = {
        ...othersData,
        mailing_zip_five: `${mailing_zip_five}${
          mailing_zip_four ? "-" + mailing_zip_four : ""
        }`,
        physical_zip_five: `${physical_zip_five}${
          physical_zip_four ? "-" + physical_zip_four : ""
        }`,
        state_id: state_id,
      };
      setRead_only_comment(read_only_comment);
      setDisplay_oncert_comment(display_oncert_comment);
      setValues(newArr);
    }
    if (editJurisdictionQuery.isError) {
      setGetError(
        editJurisdictionQuery.error?.data?.error ||
          editJurisdictionQuery.error?.data?.message ||
          editJurisdictionQuery.error.data ||
          editJurisdictionQuery.error.error ||
          editJurisdictionQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editJurisdictionQuery.isFetching]);

  //useEffect for edit refecth
  useEffect(() => {
    if (editId) {
      editTaxCollectorQuery.refetch();
    }
    setValues(initialValues);
  }, [afterClose]);

  //useEffect for searchJurisdiction
  useEffect(() => {
    if (juriSearchApi[1].error) {
      setPopError(
        juriSearchApi[1].error?.data?.error ||
          juriSearchApi[1].error?.data?.message ||
          juriSearchApi[1].error.data ||
          juriSearchApi[1].error.error ||
          juriSearchApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [juriSearchApi[1].isLoading]);

  //useEffect for auto TaxColector data fill
  useEffect(() => {
    if (autoTaxCollectorData[1].isSuccess) {
      //import data fetch and used
      setValues({ ...values, ...autoTaxCollectorData[1].data?.data[0] });
    }
    if (autoTaxCollectorData[1].error) {
      setGetError(
        autoTaxCollectorData[1].error?.data?.error ||
          autoTaxCollectorData[1].error?.data?.message ||
          autoTaxCollectorData[1].error?.data ||
          autoTaxCollectorData[1].error.error ||
          autoTaxCollectorData[1].error.data?.msg.sqlMessage
      );
    }
  }, [autoTaxCollectorData[1].isLoading]);

  //useEffet for  add taxcollector
  useEffect(() => {
    if (addNewTaxCollector[1].isSuccess) {
      closeModal();
      setOpen(false);
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewTaxCollector[1].data?.message,
      });
    }
    if (addNewTaxCollector[1].error) {
      //here created for in loop for catch server error
      setGetError(
        addNewTaxCollector[1].error?.data?.error ||
          addNewTaxCollector[1].error?.data?.message ||
          addNewTaxCollector[1].error.error ||
          addNewTaxCollector[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewTaxCollector[1].isLoading]);

  useEffect(() => {
    if (updateTaxCollectorData[1].isSuccess) {
      closeModal();
      setOpen(false);
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateTaxCollectorData[1].data?.message,
      });
    }
    if (updateTaxCollectorData[1].error) {
      setGetError(
        updateTaxCollectorData[1].error?.data?.error ||
          updateTaxCollectorData[1].error?.data?.message ||
          updateTaxCollectorData[1].error.error ||
          updateTaxCollectorData[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateTaxCollectorData[1].isLoading]);

  const inputs = [
    {
      id: 1,
      name: "state_id",
      type: "select",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      value: values?.state_id || "",
      options: stateData,
      required: true,
      column: "3",
      tab: false,
    },
    {
      id: 5456,

      name: "county_id",

      type: "select",

      column: "3",

      value: values.county_id || "",

      placeholder: "Select County Name",

      errorMessage: "Please select county!",

      label: "County",

      options: countyOptions,

      required: true,

      tab: false,
    },
    {
      id: 2,
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      errorMessage: "Please enter name!",
      label: "Name",
      required: true,
      column: "3",
      tab: false,
    },
    // {
    //   id: 3,
    //   name: "mail_to",
    //   type: "email",
    //   placeholder: "Enter Email",
    //   errorMessage: "Please enter valid email!",
    //   label: "Email To",
    //   column: "3",
    //   tab: false,
    // },

    {
      id: 5,
      name: "inst_per_year",
      value: values.inst_per_year || "",
      type: "text",
      placeholder: "Enter Installment/yr",
      errorMessage: "Please enter installment/yr!",
      label: "Installment/yr",
      column: "3",
      maxLength: 3,
      minLength: 1,
      tab: false,
    },

    {
      id: 7,
      name: "due_date",
      type: "date",
      value: values.due_date || false,
      placeholder: "DD/MM/YY",
      errorMessage: "Please select due date!",
      label: "Due Date",
      column: "3",
      required: true,
      tab: false,
    },
    // {
    //   id: 1540,
    //   name: "phone_number",
    //   type: "number",
    //   column: "3",
    //   _id: "tax_phone_number",
    //   placeholder: "Enter Phone Number",
    //   errorMessage: "Please enter 10 digits phone number!",
    //   label: "Phone Number",
    // },

    {
      id: 10,
      name: "website",
      type: "text",
      placeholder: "Enter Website",
      errorMessage: "Please enter website!",
      label: "Website",
      column: "3",
      tab: false,
    },
    {
      id: 11,
      name: "current_year_bill",
      type: "text",
      maxLength: 4,
      minLength: 4,
      placeholder: "Enter Current Year Bill",
      errorMessage: "Please enter 4 digits current year bill!",
      label: "Current Year Bill",
      column: "3",
      required: true,
      tab: false,
    },
    {
      id: 1152,
      name: "payment_plan",
      type: "text",
      maxLength: 4,
      minLength: 4,
      placeholder: "Enter Payment Plan",
      label: "Payment Plan",
      column: "3",
      tab: false,
    },
    {
      id: 12,
      name: "extractor_name",
      type: "text",
      placeholder: "Enter Extractor Name",
      errorMessage: "Please enter extractor name!",
      label: "Extractor Name",
      column: "3",
      tab: false,
    },
    {
      id: 16235,
      name: "account_number",
      type: "text",
      placeholder: "Enter Account #",
      errorMessage: "Please enter account #!",
      label: "Account #",
      column: "3",
      tab: false,
    },
    {
      id: 17,
      name: "is_active",
      checked: values.is_active || "",
      type: "switch",
      column: "1",
      label: "Active",
      required: true,
      margin: "3",
      tab: false,
    },
    {
      id: 15,
      name: "is_third_party",
      checked: values.is_third_party || "",
      type: "switch",
      column: "2",
      label: "Is Third Party",
      required: true,
      margin: "3",
      tab: false,
      // tooltip: "Third party will be enable for bill year and payee",
    },

    {
      id: 1556,
      name: "allow_previous_bills",
      checked: values.allow_previous_bills || "",
      type: "checkbox",
      column: `3 ${!values.is_third_party && "d-none"}`,
      label: "Previous Year As Current Year Bill",
      required: true,
      margin: "3",
      tab: false,
    },
    {
      id: 16,
      name: "is_physical",
      checked: values.is_physical || "",
      type: "switch",
      column: "3",
      label: "Display Physical Address On Cert",
      required: true,
      margin: `${!values.is_third_party ? "3" : "3"}`,
      tab: false,
      tooltip:
        "By Default mailing address will be displayed on the Certificate",
    },

    // {
    //   id: 10,
    //   name: "mailing_first_name",
    //   value: values?.mailing_first_name || "",
    //   type: "text",
    //   placeholder: "Enter First Name",
    //   label: "First Name",
    //   errorMessage: "Please enter first name",
    //   column: "3",
    //   address_type: "mailing",
    //   tab: true,
    // },
    // {
    //   id: 105656,
    //   name: "mailing_middle_name",
    //   value: values?.mailing_middle_name || "",
    //   type: "text",
    //   placeholder: "Enter Middle Name",
    //   label: "Middle Name",
    //   errorMessage: "Please enter middle name",
    //   column: "3",
    //   address_type: "mailing",
    //   tab: true,
    // },
    // {
    //   id: 105656,
    //   name: "mailing_last_name",
    //   value: values?.mailing_last_name || "",
    //   type: "text",
    //   placeholder: "Enter Last Name",
    //   label: "Last Name",
    //   errorMessage: "Please enter last name",
    //   column: "3",
    //   address_type: "mailing",
    //   tab: true,
    // },
    // {
    //   id: 100,
    //   name: "mailing_contact_position",
    //   value: values?.mailing_contact_position || "",
    //   type: "text",
    //   placeholder: "Enter Contact Position",
    //   errorMessage: "Please enter contact position",
    //   label: "Contact Position",
    //   column: "3",
    //   address_type: "mailing",
    //   tab: true,
    // },
    {
      id: 13,
      name: "mailing_address_one",
      value: values?.mailing_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 14,
      name: "mailing_address_two",
      value: values?.mailing_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 15,
      name: "mailing_city",
      value: values?.mailing_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 156564,
      name: "mailing_state_id",
      type: "select",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      value: values?.mailing_state_id || "",
      options: stateData,
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 16,
      name: "mailing_zip_five",
      value: values?.mailing_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 1451,
      name: "mailing_phone_number",
      type: "number",
      value: values.mailing_phone_number,
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 12,
      name: "mailing_fax",
      type: "text",
      value: values.mailing_fax || "",
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "mailing",
      tab: true,
    },
    {
      id: 11,
      name: "mailing_email",
      type: "email",
      placeholder: "Enter Email",
      value: values.mailing_email || "",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 4545452,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Same As Physical Address ",
      margin: "3",
      address_type: "mailing",
      tab: true,
    },
    //physical
    //physical
    // {
    //   id: 454510,
    //   name: "physical_first_name",
    //   value: values?.physical_first_name || "",
    //   errorMessage: "Please enter first name!",
    //   type: "text",
    //   placeholder: "Enter First Name",
    //   label: "First Name",
    //   column: "3",
    //   address_type: "physical",
    //   tab: true,

    //   // required: is_mailing_address === 0 ? false : true,
    // },
    // {
    //   id: 450,
    //   name: "physical_middle_name",
    //   value: values?.physical_middle_name || "",
    //   errorMessage: "Please enter middle name!",
    //   type: "text",
    //   placeholder: "Enter Middle Name",
    //   label: "Middle Name",
    //   column: "3",
    //   address_type: "physical",
    //   tab: true,

    //   // required: is_mailing_address === 0 ? false : true,
    // },
    // {
    //   id: 450545,
    //   name: "physical_last_name",
    //   value: values?.physical_last_name || "",
    //   errorMessage: "Please enter last name!",
    //   type: "text",
    //   placeholder: "Enter Last Name",
    //   label: "Last Name",
    //   column: "3",
    //   address_type: "physical",
    //   tab: true,

    //   // required: is_mailing_address === 0 ? false : true,
    // },
    // {
    //   id: 110,
    //   name: "physical_contact_position",
    //   value: values?.physical_contact_position || "",
    //   type: "text",
    //   placeholder: "Enter Contact Position",
    //   errorMessage: "Please enter contact position",
    //   label: "Contact Position",
    //   column: "3",
    //   address_type: "physical",
    //   tab: true,

    //   // required: is_mailing_address === 0 ? false : true,
    // },
    {
      id: 13,
      name: "physical_address_one",
      value: values?.physical_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 14,
      name: "physical_address_two",
      value: values?.physical_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "physical",
      tab: true,
    },
    {
      id: 15,
      name: "physical_city",
      value: values?.physical_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 1565878564,
      name: "physical_state_id",
      type: "select",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      value: values?.physical_state_id || "",
      options: stateData,
      column: "3",
      address_type: "physical",
      tab: true,
    },

    {
      id: 16,
      name: "physical_zip_five",
      value: values?.physical_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 114548,
      name: "physical_phone_number",
      type: "number",
      value: values.physical_phone_number || "",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 1284512,
      name: "physical_fax",
      type: "text",
      value: values?.physical_fax || "",
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 11845,
      name: "physical_email",
      type: "email",
      value: values.physical_email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "physical",
      tab: true,

      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 454454556,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Same As Physical Address ",
      margin: "3",
      address_type: "physical",
      tab: true,
    },
  ];

  const TabOnChange = (key) => {
    setAddressTabKey(key);
  };

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
    if (property === "state_id") {
      if (value) {
        getCountyList({ state_id: { state_id: value }, token: token });
        // callling list multijuri county base on state id
      } else {
        setCountyOptions([]);
      }
    }
  };

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.phone_number?.length !== 12 && values.phone_number?.length > 0) {
      setGetError("Please enter valid phone number");
    } else if (
      values.mailing_phone_number?.length !== 12 &&
      values.mailing_phone_number?.length > 0
    ) {
      setGetError("Please enter valid mailing phone number");
    } else if (
      values.physical_phone_number?.length !== 12 &&
      values.physical_phone_number?.length > 0
    ) {
      setGetError("Please enter valid physical phone number");
    } else {
      // if edit id not exists then add taxcollector
      const mailing_zip_five = values.mailing_zip_five?.split("-");
      const physical_zip_five = values.physical_zip_five?.split("-");
      removeBlank(values);
      if (!editId) {
        addTaxCollector({
          data: {
            ...values,
            phone_number: values.phone_number,
            inst_per_year: values.inst_per_year && Number(values.inst_per_year),
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            physical_zip_four:
              physical_zip_five && (physical_zip_five[1] || null),
            read_only_comment,
            display_oncert_comment,
          },
          token: token,
        });
      }
      if (editId) {
        updateTaxCollector({
          data: {
            ...values,
            inst_per_year:
              values.inst_per_year && parseFloat(values.inst_per_year),
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            physical_zip_four:
              physical_zip_five && (physical_zip_five[1] || null),
            read_only_comment,
            display_oncert_comment,
          },
          id: editId,
          token: token,
        });
      }
    }
  };
  //useEfect for Refecth edit details
  useEffect(() => {
    if (editId) {
      editTaxCollectorQuery.refetch();
    }

    //all alert messages cleared!
    clearStatus();
  }, [afterClose, editId]);

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (name === "current_year_bill" || name === "inst_per_year") {
      setValues({
        ...values,
        [name]: value.replace(/[^0-9.]/gi, ""),
      });
    } else if (name === "mailing_fax" || name === "physical_fax") {
      setValues({
        ...values,
        [name]: value.replace(/[^0-9]/gi, ""),
      });
    } else if (name === "physical_zip_five") {
      const physicalvalue1 = value.replace(/[^0-9.]/g, "");
      var physicalfoo = physicalvalue1.split("-").join("");
      if (physicalfoo.length > 0) {
        physicalfoo = physicalfoo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({
        ...values,
        physical_zip_five: physicalfoo,
      });
    } else if (name === "mailing_zip_five") {
      const value1 = value.replace(/[^0-9.]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, mailing_zip_five: foo });
    }

    //claser status values
    clearStatus();
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editTaxCollectorQuery.refetch();
    }
    clearStatus();
    setRead_only_comment("");
    setDisplay_oncert_comment("");
  };

  const appCheckboxHandler = (property, value) => {
    clearStatus();

    const {
      mailing_fax,
      mailing_contact_position,
      mailing_phone_number,
      mailing_email,
      mailing_address_one,
      mailing_address_two,
      mailing_state_id,
      mailing_city,
      mailing_zip_five,
    } = values;

    if (value) {
      setValues({
        ...values,
        physical_phone_number: mailing_phone_number,
        physical_email: mailing_email,
        physical_address_one: mailing_address_one,
        physical_address_two: mailing_address_two,
        physical_state_id: mailing_state_id,
        physical_city: mailing_city,
        physical_zip_five: mailing_zip_five,
        physical_contact_position: mailing_contact_position,
        physical_fax: mailing_fax,
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    } else {
      setValues({
        ...values,
        physical_phone_number: "",
        physical_email: "",
        physical_address_one: "",
        physical_address_two: "",
        physical_state_id: "",
        physical_city: "",
        physical_zip_five: "",
        physical_zip_four: "",
        physical_contact_position: "",
        physical_fax: "",
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    }
  };
  return (
    <div className="position-relative mytab">
      <div className="border-bottom border-2 d-flex" id="tax_error">
        <div className="col-11">
          <h5>{editId ? "Edit Tax Collector" : "Add Tax Collector"}</h5>
        </div>
        {/* //popupSarch modal  */}
        {/* {!editId && (
          <AppPopup
            error={popError}
            closePop={(value) => {
              !value && setPopError(false);
            }}
            afterClose={afterClose}
            getId={(e) => autoUpdateDetailsTaxCollector({ id: e, token })}
            list={juriSearchApi[1].isSuccess && juriSearchApi[1].data?.data}
            isLoading={juriSearchApi[1].isLoading}
            onSearch={(e) => {
              searchJurisdiction({ value: { jurisdiction_name: e }, token });
            }}
            placeholder="Search Jurisdiction"
          />
        )} */}
      </div>
      {/* //alert message  */}

      {getError && (
        <div className="mt-3">
          <Alert message={getError} type="error" showIcon />{" "}
        </div>
      )}

      <form onSubmit={handleSubmit} className="tax-tab">
        {(addNewTaxCollector[1].isLoading ||
          updateTaxCollectorData[1].isLoading ||
          editTaxCollectorQuery.isFetching ||
          countyList2[1].isLoading) && <AppSpinner />}
        <div
          className={`${editTaxCollectorQuery.isFetching ? "invisible" : ""} `}
        >
          <Row>
            <div
              className={`col-12 col-lg-${
                editId && values.is_third_party ? "6" : editId ? "9" : "12"
              }`}
            >
              <div className="row">
                {inputs.map((input) => (
                  <>
                    {input.type === "checkbox" && !input.tab && (
                      <AppCheckbox
                        {...input}
                        column={
                          editId && values.is_third_party
                            ? 6
                            : editId
                            ? 4
                            : input.column
                        }
                        handleChange={appCheckboxHandler}
                      />
                    )}

                    {(input.type === "text" ||
                      input.type === "email" ||
                      input.type === "date") &&
                      input.type !== "number" &&
                      input.tab === false && (
                        <FormInput
                          key={input.id}
                          defaultSet={afterClose}
                          {...input}
                          column={
                            editId && values.is_third_party
                              ? 6
                              : editId
                              ? 4
                              : input.column
                          }
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}

                    {input.type === "select" &&
                      input.name === "county_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          column={
                            editId && values.is_third_party
                              ? 6
                              : editId
                              ? 4
                              : input.column
                          }
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select County</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.county_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {/* //appSelcet for tax collector type name  */}
                    {input.type === "select" &&
                      input.name === "tax_collector_type_id" &&
                      input.tab === false && (
                        <AppSelect
                          column={
                            editId && values.is_third_party
                              ? 6
                              : editId
                              ? 4
                              : input.column
                          }
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={input.options.map((ele) => {
                            return (
                              <option key={ele.id} value={ele.id}>
                                {ele.name}
                              </option>
                            );
                          })}
                        />
                      )}
                    {/* //appSelcet for tax_collector  */}

                    {input.type === "select" &&
                      input.name === "state_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          column={
                            editId && values.is_third_party
                              ? 6
                              : editId
                              ? 4
                              : input.column
                          }
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {/* //textarea  */}
                    {input.type === "textarea" && input.tab === false && (
                      <AppTextArea
                        key={input.id}
                        value={values[input.name]}
                        {...input}
                        style={{ minHeight: "50px" }}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    )}
                    {input.type === "switch" && input.tab === false && (
                      <AppSwitch
                        key={input.id}
                        value={values[input.name]}
                        onChange={appSwitchHanler}
                        {...input}
                        column={
                          editId && values.is_third_party
                            ? 6
                            : editId
                            ? 4
                            : input.column
                        }
                      />
                    )}
                  </>
                ))}
              </div>
            </div>
            {editId && (
              <div className="col-12 col-lg-3">
                <label htmlFor="tax" className="mt-3  heading-5 text-dark">
                  County/Jurisdiction
                </label>
                {!multiCountyJuriListResult.isLoading &&
                enableCountyJurisdictionList[0]?.children?.length !== 0 ? (
                  <div className="mt-2">
                    <AppMultiSelect
                      placeholder={"Search County/Jurisdiction"}
                      //default state
                      checkable={false}
                      errorMessage={"County/Jurisdiction"}
                      defaultSet={afterClose}
                      treeData={enableCountyJurisdictionList}
                      //initial values acceteps only ["5","6","7"] format
                      initialVal={editId ? [] : []}
                      onSelectVal={() => {}}
                    />
                  </div>
                ) : (
                  <div className="my-2">
                    <Empty
                      description={<span>County/Jurisdiction Not Found</span>}
                    />
                  </div>
                )}
              </div>
            )}
            {editId && values.is_third_party && (
              <div className="col-12 col-lg-3">
                <MultiCheckList
                  placeholder="Third Party Tax Collector"
                  checkbox={!editId}
                  heading={"Third Party Tax Collector"}
                  afterClose={afterClose}
                  getSelectVal={(value) => {
                    // setValues({ ...values, active_hoa_list: value });
                  }}
                  plainOptions={juriTaxBaseListData}
                  // defaultCheckedList={values.hoa_mapped_list}
                />
              </div>
            )}
          </Row>
          {!countyJurisdictionApi[1].isLoading && (
            <div className="mt-3 ">
              <Tabs
                className="w-100"
                defaultActiveKey={String(addressTabKey)}
                onChange={TabOnChange}
                type="card"
              >
                <TabPane tab="Mailing" key="1">
                  <div className="row">
                    {inputs.map((input) => (
                      <>
                        {(input.type === "text" || input.type === "email") &&
                          input.tab === true &&
                          input?.address_type === "mailing" && (
                            <AppInput
                              key={input.id}
                              defaultSet={afterClose}
                              {...input}
                              onChange={onChange}
                            />
                          )}
                        {input.type === "number" &&
                          input.tab === true &&
                          input?.address_type === "mailing" && (
                            <div className="col-12 col-md-3">
                              <Row className="mt-3">
                                <label className="ms-1 heading-5 text-dark ">
                                  {input.label}
                                </label>
                              </Row>
                              <Input
                                className="input-field mt-0 pb-2 w-100 heading-5"
                                maxLength={14}
                                minLength={14}
                                placeholder={input.placeholder}
                                defaultCountry="US"
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                value={values[input.name]}
                                onChange={(value) => {
                                  setValues({
                                    ...values,
                                    [input.name]: value,
                                    is_mailing_address_flag: false,
                                  });
                                  setGetError(false);
                                }}
                              />
                            </div>
                          )}
                        {/* //appSelcet for state name  */}

                        {input.type === "select" &&
                          input.name === "mailing_state_id" &&
                          input.tab === true &&
                          input?.address_type === "mailing" && (
                            <AppSelect
                              key={input.id}
                              {...input}
                              onChange={selectHandler}
                              options={
                                <>
                                  <option value={""}>Select State</option>

                                  {input.options.map((ele) => {
                                    return (
                                      <option key={ele.id} value={ele.id}>
                                        {ele.state_name}
                                      </option>
                                    );
                                  })}
                                </>
                              }
                            />
                          )}
                        {input.type === "checkbox" &&
                          !editId &&
                          input.tab === true &&
                          input?.address_type === "mailing" && (
                            <AppCheckbox
                              {...input}
                              handleChange={appCheckboxHandler}
                            />
                          )}
                        {input.type === "switch" &&
                          input.tab === true &&
                          input?.address_type === "mailing" && (
                            <AppSwitch
                              key={input.id}
                              value={values[input.name]}
                              onChange={appSwitchHanler}
                              {...input}
                            />
                          )}
                      </>
                    ))}
                  </div>
                </TabPane>
                {/* //physical address  */}
                <TabPane tab="Physical" key="2">
                  <div className="row">
                    {inputs.map((input) => (
                      <>
                        {(input.type === "text" || input.type === "email") &&
                          input.tab === true &&
                          input?.address_type === "physical" && (
                            <AppInput
                              key={input.id}
                              defaultSet={afterClose}
                              {...input}
                              // value={values[input.name]}

                              onChange={onChange}
                            />
                          )}
                        {input.type === "number" &&
                          input.tab === true &&
                          input?.address_type === "physical" && (
                            <div className="col-12 col-md-3">
                              <Row className="mt-3">
                                <label className="ms-1 heading-5 text-dark ">
                                  {input.label}
                                </label>
                              </Row>
                              <Input
                                className="input-field mt-0 pb-2 w-100 heading-5"
                                maxLength={14}
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                minLength={14}
                                placeholder={input.placeholder}
                                defaultCountry="US"
                                value={values[input.name]}
                                onChange={(value) => {
                                  setValues({
                                    ...values,
                                    [input.name]: value,
                                    is_mailing_address_flag: false,
                                  });
                                  setGetError(false);
                                }}
                              />
                            </div>
                          )}
                        {/* //appSelcet for state name  */}

                        {input.type === "select" &&
                          input.name === "physical_state_id" &&
                          input.tab === true &&
                          input?.address_type === "physical" && (
                            <AppSelect
                              key={input.id}
                              {...input}
                              onChange={selectHandler}
                              options={
                                <>
                                  <option value={""}>Select State</option>
                                  {input.options.map((ele) => {
                                    return (
                                      <option key={ele.id} value={ele.id}>
                                        {ele.state_name}
                                      </option>
                                    );
                                  })}
                                </>
                              }
                            />
                          )}

                        {input.type === "switch" &&
                          input.tab === true &&
                          input?.address_type === "physical" && (
                            <AppSwitch
                              key={input.id}
                              value={values[input.name]}
                              onChange={appSwitchHanler}
                              {...input}
                            />
                          )}
                        {input.type === "checkbox" &&
                          !editId &&
                          input.tab === true &&
                          input?.address_type === "physical" && (
                            <AppCheckbox
                              {...input}
                              handleChange={appCheckboxHandler}
                            />
                          )}
                      </>
                    ))}
                  </div>
                </TabPane>
                <TabPane tab="Bill Cycle" key="3">
                  {!editTaxCollectorQuery.isFetching &&
                    (editId ? !countyList2[1].isLoading : true) && (
                      <div className="row ">
                        <div className="col-12">
                          <MultiBillCycle
                            afterClose={afterClose}
                            addFormVal={(value) => {
                              if (!editId) {
                                values.tax_collector_bill_cycle = value;
                              }
                            }}
                            //add new
                            whileEdit={(value) => {
                              if (editId) {
                                setValues({
                                  ...values,
                                  add_tax_collector_bill_cycle: [
                                    ...values?.add_tax_collector_bill_cycle,
                                    value,
                                  ],
                                });
                              }
                            }}
                            //update
                            updateContact={(value) => {
                              if (editId) {
                                if (
                                  values.edit_tax_collector_bill_cycle?.length >
                                    0 &&
                                  values.edit_tax_collector_bill_cycle?.find(
                                    (val) => val["id"] === value.id
                                  )
                                ) {
                                  values.edit_tax_collector_bill_cycle.forEach(
                                    (item) => {
                                      if (item.id === value.id) {
                                        item.bill_cycle = value.bill_cycle;
                                      }
                                    }
                                  );
                                } else {
                                  setValues({
                                    ...values,
                                    edit_tax_collector_bill_cycle: [
                                      ...values?.edit_tax_collector_bill_cycle,
                                      value,
                                    ],
                                  });
                                }
                              }
                            }}
                            //delete
                            deleteContact={(value) => {
                              if (editId) {
                                if (value) {
                                  setValues({
                                    ...values,
                                    delete_tax_collector_bill_cycle: [
                                      ...values?.delete_tax_collector_bill_cycle,
                                      String(value.id),
                                    ],
                                  });
                                } else {
                                  setValues({
                                    ...values,
                                    add_tax_collector_bill_cycle:
                                      values.add_tax_collector_bill_cycle?.filter(
                                        (e) => {
                                          return e.temp_id !== value.temp_id;
                                        }
                                      ),
                                  });
                                }
                              }
                            }}
                            initailValArr={
                              editId ? values?.bill_cycle_list : []
                            }
                          />
                        </div>
                      </div>
                    )}
                </TabPane>
                <TabPane tab="Comments" key="4">
                  <div className="row editor">
                    <div className="col-12 col-lg-6 px-2 ">
                      {taxDisplayCertList.isSuccess && (
                        <div className="row align-items-center">
                          <div className="col-12 col-lg-3">
                            <h6>Display On Cert</h6>
                          </div>
                          <div className="col-12 col-lg-9">
                            <AppSelect
                              afterClose={afterClose}
                              value={values.selDisplay}
                              classStyle="border w-100 p-1 my-2"
                              onChange={(_, value) => {
                                setDisplay_oncert_comment(
                                  display_oncert_comment + value
                                );
                                setValues({ ...values, selDisplay: value });
                              }}
                              options={
                                <>
                                  <option value={""}>
                                    Select Display On Cert
                                  </option>
                                  {taxDisplayCertList?.data?.data?.map(
                                    ({ title, comments, id }) => {
                                      return (
                                        <option key={id} value={comments}>
                                          {title}
                                        </option>
                                      );
                                    }
                                  )}
                                </>
                              }
                            />
                          </div>
                        </div>
                      )}

                      <AppTextEditor
                        value={display_oncert_comment}
                        onChange={(value) => {
                          setDisplay_oncert_comment(value);
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-6 px-2 ">
                      {taxReadOnlyList.isSuccess && (
                        <div className="row align-items-center">
                          <div className="col-12 col-lg-3">
                            <h6>Read Only</h6>
                          </div>
                          <div className="col-12 col-lg-9">
                            <AppSelect
                              afterClose={afterClose}
                              value={values.selReadOnly}
                              classStyle="border w-100 p-1 my-2"
                              onChange={(_, value) => {
                                setRead_only_comment(read_only_comment + value);
                                setValues({ ...values, selReadOnly: value });
                              }}
                              options={
                                <>
                                  <option value={""}>Select Read Only</option>
                                  {taxReadOnlyList?.data?.data?.map(
                                    ({ title, comments, id }) => {
                                      return (
                                        <option key={id} value={comments}>
                                          {title}
                                        </option>
                                      );
                                    }
                                  )}
                                </>
                              }
                            />
                          </div>
                        </div>
                      )}
                      <AppTextEditor
                        value={read_only_comment}
                        onChange={(value) => {
                          setRead_only_comment(value);
                        }}
                      />
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
          <div className="d-flex justify-content-center pb-3 pt-2">
            <div className="me-md-2 ">
              <AppButton
                onClick={handleReset}
                title={"CLEAR"}
                variant={"secondary-md"}
              />
            </div>

            <AppButton title={"SUBMIT"} variant={"primary-md"} />
          </div>
        </div>
      </form>
    </div>
  );
};
