import React, { memo } from "react";
import AppTextEditor from "../../../../../Components/AppTextEditor/AppTextEditor";

export default function HOAComments({
  mode,
  value,
  onChange,
  options = [],
  title = "",
}) {
  return (
    <>
      <div className="row my-2 ">
        <div className="col-12">
          <div className="d-flex alig-items-center justify-content-between">
            <h6>{title}</h6>
            <select
              className="w-50 border rounded-3 px-2 fs-6"
              onChange={(e) => {
                onChange(value + e.target.value);
              }}
            >
              <option value={""}>Select {title}</option>

              {options?.map(({ title, comments, id }) => {
                return (
                  <option key={id} value={comments}>
                    {title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <AppTextEditor value={value || ""} onChange={onChange} />
    </>
  );
}
