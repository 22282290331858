import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const supportbranch = createApi({
  reducerPath: "supportbranch",
  tagTypes: ["supportbranch"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    supportBranchList: builder.query({
      query: (token) => ({
        url: `/support-branch-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["supportbranch"],
    }),
    updateSupportBranch: builder.mutation({
      query: (data) => ({
        url: `/update-support-branch/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["supportbranch"],
    }),
    updateSupportBranchStatus: builder.mutation({
      query: (data) => ({
        url: `/update-support-branch-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["supportbranch"],
    }),
  }),
});

export const {
  useSupportBranchListQuery,
  useUpdateSupportBranchMutation,
  useUpdateSupportBranchStatusMutation,
} = supportbranch;
